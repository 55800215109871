import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import { MaintenanceLogEntryModelState } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModelState';

export type MaintenanceLogEntryModelUuid = string;

export interface MaintenanceLogEntryPersistenceModel extends PersistenceModel {
	Client: string;
	Facility: string;
	Device?: string;
	DeviceName?: string;
	Reporter: string;
	ReporterName: string;
	Solver?: string;
	SolverName?: string;
	State: string;
	IncidentTitle: string;
	IncidentCause: string;
	IncidentSolution?: string;
	IncidentDateStart: number;
	IncidentDateEnd?: number;
}

export interface MaintenanceLogEntryStoreModel extends StoreModel {
	Client: string;
	Facility: string;
	Device?: string;
	DeviceName?: string;
	Reporter: string;
	ReporterName: string;
	Solver?: string;
	SolverName?: string;
	State: string;
	IncidentTitle: string;
	IncidentCause: string;
	IncidentSolution?: string;
	IncidentDateStart: number;
	IncidentDateEnd?: number;
}

export interface MaintenanceLogEntryViewModel extends ViewModel {
	Client: string;
	Facility: string;
	Device?: string;
	DeviceName?: string;
	Reporter: string;
	ReporterName: string;
	Solver?: string;
	SolverName?: string;
	State: MaintenanceLogEntryModelState;
	IncidentTitle: string;
	IncidentCause: string;
	IncidentSolution?: string;
	IncidentDateStart: Date;
	IncidentDateEnd?: Date;
}

export interface CreatableMaintenanceLogEntryViewModel extends CreatableViewModel {
	Client: string;
	Facility: string;
	Device?: string;
	DeviceName?: string;
	Reporter: string;
	ReporterName: string;
	IncidentTitle: string;
	IncidentCause: string;
	IncidentDateStart: Date;
}

// eslint-disable-next-line max-len
export class MaintenanceLogEntryModelConverter extends ModelConverter<MaintenanceLogEntryPersistenceModel, MaintenanceLogEntryStoreModel, MaintenanceLogEntryViewModel, CreatableMaintenanceLogEntryViewModel> {

	public fromStoreModel(storeModel?: MaintenanceLogEntryStoreModel): this {
		super.fromStoreModel(storeModel, (sModel, model) => {
			model.IncidentDateStart = Math.floor(sModel.IncidentDateStart / 1000);
			if (Object.prototype.hasOwnProperty.call(sModel, 'IncidentDateEnd') && sModel.IncidentDateEnd !== null) {
				model.IncidentDateEnd = Math.floor(sModel.IncidentDateEnd / 1000);
			}
		});

		return this;
	}

	public fromViewModel(viewModel?: MaintenanceLogEntryViewModel): this {
		super.fromViewModel(viewModel, (vModel, model) => {
			model.IncidentDateStart = Math.floor(vModel.IncidentDateStart.getTime() / 1000);
			if (Object.prototype.hasOwnProperty.call(vModel, 'IncidentDateEnd') && vModel.IncidentDateEnd !== null) {
				model.IncidentDateEnd = Math.floor(vModel.IncidentDateEnd.getTime() / 1000);
			}
		});

		return this;
	}

	public fromCreatableViewModel(creatableViewModel?: CreatableMaintenanceLogEntryViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			model.Solver = null;
			model.SolverName = null;
			model.State = MaintenanceLogEntryModelState.ONGOING;
			model.IncidentSolution = null;
			model.IncidentDateEnd = null;
		});
	}

	public toViewModel(): MaintenanceLogEntryViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.State = model?.State as MaintenanceLogEntryModelState ?? null;
			vModel.IncidentDateStart = new Date(model.IncidentDateStart * 1000);
			if (Object.prototype.hasOwnProperty.call(model, 'IncidentDateEnd') && model.IncidentDateEnd !== null) {
				vModel.IncidentDateEnd = new Date(model.IncidentDateEnd * 1000);
			}
		});
	}

	public toStoreModel(): MaintenanceLogEntryStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.IncidentDateStart = model.IncidentDateStart * 1000;
			if (Object.prototype.hasOwnProperty.call(model, 'IncidentDateEnd') && model.IncidentDateEnd !== null) {
				sModel.IncidentDateEnd = model.IncidentDateEnd * 1000;
			}
		});
	}

}
