import { CreatableViewModel, Model, ModelSyncState, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { Guid } from 'lib/guid/Guid';

export type ModelConverterClosure<From extends Model, To extends Model> = (fromModel: Readonly<From>, toModel: To) => void;
export type CreatableModelEnricherClosure<CVModel extends CreatableViewModel> = (model: CVModel) => void;

export class ModelConverter<PModel extends PersistenceModel, SModel extends StoreModel, VModel extends ViewModel, CVModel extends CreatableViewModel> {

	private temp?: Model = null;
	protected modelSyncState: ModelSyncState = ModelSyncState.PRISTINE;

	public fromPersistenceModel(persistenceModel?: PModel, converter?: ModelConverterClosure<PModel, Model>): this {
		if ((persistenceModel ?? null) === null) {
			this.temp = null;
			return this;
		}
		this.temp = { ...persistenceModel } as Model;
		this.executeConverter(persistenceModel, this.temp, converter);
		this.modelSyncState = ModelSyncState.PRISTINE;
		return this;
	}

	public fromStoreModel(storeModel?: SModel, converter?: ModelConverterClosure<SModel, Model>): this {
		if ((storeModel ?? null) === null) {
			this.temp = null;
			return this;
		}
		this.temp = { ...storeModel } as Model;
		this.modelSyncState = storeModel.ModelSyncState as ModelSyncState;
		if (Object.prototype.hasOwnProperty.call(this.temp, 'CreatedAt')) {
			this.temp.CreatedAt = Math.floor(parseInt(this.temp.CreatedAt, 10) / 1000);
		}
		if (Object.prototype.hasOwnProperty.call(this.temp, 'UpdatedAt')) {
			this.temp.UpdatedAt = Math.floor(parseInt(this.temp.UpdatedAt, 10) / 1000);
		}
		this.executeConverter(storeModel, this.temp, converter);
		return this;
	}

	public fromViewModel(viewModel?: VModel, converter?: ModelConverterClosure<VModel, Model>): this {
		if ((viewModel ?? null) === null) {
			this.temp = null;
			return this;
		}
		this.temp = { ...viewModel } as Model;
		this.modelSyncState = viewModel.ModelSyncState;
		if (Object.prototype.hasOwnProperty.call(this.temp, 'CreatedAt')) {
			this.temp.CreatedAt = Math.floor(this.temp.CreatedAt.getTime() / 1000);
		}
		if (Object.prototype.hasOwnProperty.call(this.temp, 'UpdatedAt')) {
			this.temp.UpdatedAt = Math.floor(this.temp.UpdatedAt.getTime() / 1000);
		}
		this.executeConverter(viewModel, this.temp, converter);
		return this;
	}

	public fromCreatableViewModel(creatableViewModel?: CVModel, enricher?: CreatableModelEnricherClosure<CVModel>): this {
		if ((creatableViewModel ?? null) === null) {
			this.temp = null;
			return this;
		}
		this.executeEnricher(creatableViewModel, enricher);
		const viewModel = {
			...creatableViewModel,
			Uuid: Guid.generate(),
			ModelSyncState: ModelSyncState.CREATED,
			CreatedAt: new Date(),
			UpdatedAt: new Date()
		} as unknown as VModel;
		this.fromViewModel(viewModel);
		return this;
	}

	public toPersistenceModel(): PModel | null {
		if (this.temp === null) {
			return null;
		}
		return this.temp as PModel;
	}

	public toStoreModel(converter?: ModelConverterClosure<Model, SModel>): SModel | null {
		if (this.temp === null) {
			return null;
		}
		const storeModel = { ...this.temp } as SModel;
		storeModel.ModelSyncState = this.modelSyncState as string;
		if (Object.prototype.hasOwnProperty.call(this.temp, 'CreatedAt')) {
			storeModel.CreatedAt = this.temp.CreatedAt * 1000;
		}
		if (Object.prototype.hasOwnProperty.call(this.temp, 'UpdatedAt')) {
			storeModel.UpdatedAt = this.temp.UpdatedAt * 1000;
		}
		this.executeConverter(this.temp, storeModel, converter);
		return storeModel;
	}

	public toViewModel(converter?: ModelConverterClosure<Model, VModel>): VModel | null {
		if (this.temp === null) {
			return null;
		}
		const viewModel = { ...this.temp } as VModel;
		viewModel.ModelSyncState = this.modelSyncState;
		if (Object.prototype.hasOwnProperty.call(this.temp, 'CreatedAt')) {
			viewModel.CreatedAt = new Date(this.temp.CreatedAt * 1000);
		}
		if (Object.prototype.hasOwnProperty.call(this.temp, 'UpdatedAt')) {
			viewModel.UpdatedAt = new Date(this.temp.UpdatedAt * 1000);
		}
		this.executeConverter(this.temp, viewModel, converter);
		return viewModel;
	}

	private executeConverter<From extends Model, To extends Model>(fromModel: From, toModel: To, converter?: ModelConverterClosure<From, To>): void {
		if ((converter ?? null) === null) {
			return;
		}
		converter(fromModel, toModel);
	}

	private executeEnricher<Model extends CreatableViewModel>(model: Model, enricher?: CreatableModelEnricherClosure<Model>): void {
		if ((enricher ?? null) === null) {
			return;
		}
		enricher(model);
	}

}
