import { Route as RouteComponent, Switch } from 'react-router-dom';

import { Route } from 'router/Route';
import { ScrollToTop } from 'router/ScrollToTop';

import { ClearancesView } from 'services/device/presentation/view/ClearancesView';
import { ClearanceView } from 'services/device/presentation/view/ClearanceView';
import { DevicesView } from 'services/device/presentation/view/DevicesView';
import { DeviceView } from 'services/device/presentation/view/DeviceView';
import { SequenceView } from 'services/device/presentation/view/SequenceView';
import { DocumentsView } from 'services/documents/presentation/view/DocumentsView';
import { FolderDetailView } from 'services/documents/presentation/view/FolderDetailView';
import { MaintenanceLogEntryView } from 'services/maintenance-log/presentation/view/MaintenanceLogEntryView';
import { MaintenanceLogView } from 'services/maintenance-log/presentation/view/MaintenanceLogView';

import { DashboardView } from 'presentation/view/DashboardView';
import { PageErrorView } from 'presentation/view/PageErrorView';
import { MembershipView } from 'services/core/presentation/view/MembershipView';
import { ReportView } from 'services/report/presentation/view/ReportView';
import { ReportsView } from 'services/report/presentation/view/ReportsView';
import { GeneratorView } from 'services/nuclide/presentation/view/GeneratorView';
import { NuclideManagementView } from 'services/nuclide/presentation/view/NuclideManagementView';
import { CyclotronProductView } from 'services/nuclide/presentation/view/CyclotronProductView';
import { ContainerView } from 'services/nuclide/presentation/view/ContainerView';
import { SequencePeriodContextProvider } from '../services/device/presentation/ui/sequence-records/context/SequencePeriodContextProvider';
import { MeasurementGoalsView } from '../services/device/presentation/view/MeasurementGoalsView';
import { WasteManagementReportView } from '../services/waste-management-report/presentation/view/WasteManagementReportView';

export const RouterConfig = (): JSX.Element => {

	return (
		<>
			<ScrollToTop />
			<Switch>
				<RouteComponent exact path="/">
					<DashboardView />
				</RouteComponent>
				<RouteComponent exact path={Route.DASHBOARD}>
					<DashboardView />
				</RouteComponent>
				<RouteComponent exact path={Route.DEVICES}>
					<DevicesView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.DEVICES}/:deviceUuid`}>
					<DeviceView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.DEVICES}/:deviceUuid${Route.SEQUENCES}`}>
					<DeviceView />
				</RouteComponent>
				<RouteComponent path={`${Route.DEVICES}/:deviceUuid${Route.SEQUENCES}/:sequenceUuid`}>
					<SequencePeriodContextProvider>
						<SequenceView />
					</SequencePeriodContextProvider>
				</RouteComponent>
				<RouteComponent exact path={Route.CLEARANCES}>
					<ClearancesView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.CLEARANCES}/:deviceUuid`}>
					<ClearanceView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.CLEARANCES}/:deviceUuid${Route.MEASUREMENT_GOALS}`}>
					<ClearanceView />
				</RouteComponent>
				<RouteComponent path={`${Route.CLEARANCES}/:deviceUuid${Route.MEASUREMENT_GOALS}/:sequenceUuid`}>
					<MeasurementGoalsView />
				</RouteComponent>
				<RouteComponent exact path={Route.MAINTENANCE_LOG}>
					<MaintenanceLogView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.MAINTENANCE_LOG_ENTRY}/:maintenanceLogEntryUuid`}>
					<MaintenanceLogEntryView />
				</RouteComponent>
				<RouteComponent exact path={Route.DOCUMENTS}>
					<DocumentsView />
				</RouteComponent>
				<RouteComponent path={`${Route.DOCUMENTS}/:parentFolderUuid`}>
					<FolderDetailView />
				</RouteComponent>
				<RouteComponent exact path={Route.REPORTS}>
					<ReportsView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.REPORTS}/:reportUuid`}>
					<ReportView />
				</RouteComponent>
				<RouteComponent exact path={Route.ACTORS}>
					<MembershipView />
				</RouteComponent>
				<RouteComponent exact path={Route.NUCLIDE_MANAGEMENT}>
					<NuclideManagementView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.NUCLIDE_MANAGEMENT}${Route.GENERATORS}`}>
					<NuclideManagementView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.NUCLIDE_MANAGEMENT}${Route.GENERATORS}/:generatorUuid`}>
					<GeneratorView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.NUCLIDE_MANAGEMENT}${Route.CYCLOTRON_PRODUCTS}`}>
					<NuclideManagementView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.NUCLIDE_MANAGEMENT}${Route.CYCLOTRON_PRODUCTS}/:cyclotronProductUuid`}>
					<CyclotronProductView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.NUCLIDE_MANAGEMENT}${Route.WASTE_MANAGEMENT}`}>
					<NuclideManagementView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.NUCLIDE_MANAGEMENT}${Route.WASTE_REPORTS}`}>
					<NuclideManagementView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.NUCLIDE_MANAGEMENT}${Route.WASTE_REPORTS}/:wasteManagementReportUuid`}>
					<WasteManagementReportView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.NUCLIDE_MANAGEMENT}${Route.WASTE_MANAGEMENT}${Route.CONTAINERS}`}>
					<NuclideManagementView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.NUCLIDE_MANAGEMENT}${Route.WASTE_MANAGEMENT}${Route.CONTAINERS}/:containerUuid`}>
					<ContainerView />
				</RouteComponent>
				<RouteComponent path='*'>
					<PageErrorView />
				</RouteComponent>
			</Switch>
		</>
	);

};
