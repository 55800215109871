import { L10nContext } from 'context/L10nContext';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';

import { Label } from 'presentation/ui/partials/input/label/Label';
import { Textarea } from 'presentation/ui/partials/input/textarea/Textarea';
import { useContext } from 'react';

import { CommentInput } from 'services/device/domain/business/common/Input';

export interface CommentInputProps {
	input: CommentInput;
}

export const CommentInputRenderer = (props: CommentInputProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const { input } = props;

	let label = l10nContext.translate(input.getLabelKey());
	let notification: JSX.Element | null = null;
	if (!input.isOptional()) {
		label += '*';
		notification = (
			<NotificationBar
				message={l10nContext.translate('view.device.modal.action.createRecord.commentMessageRequired')}
				level={NotificationLevel.LEVEL_INFO}
			/>
		);
	} else {
		notification = (
			<NotificationBar
				message={l10nContext.translate('view.device.modal.action.createRecord.commentMessageOptional')}
				level={NotificationLevel.LEVEL_INFO}
			/>
		);
	}

	return (
		<>
			{notification}
			<Label label={label}>
				<Textarea
					name={input.getName()}
					defaultValue={input.getSingle() ?? ''}
					required={!input.isOptional()}
					onChange={(event) => {
						input.setSingle(event.target.value);
					}}
				/>
			</Label>
		</>
	);

};
