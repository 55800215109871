import { memo, useCallback } from 'react';

import { ButtonGhost, IconPosition } from 'presentation/ui/partials/button/button-ghost/ButtonGhost';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './card-item-single-control.scss';

export interface CardItemSingleControlProps {
	/**
	 * Id of controlled card
	 */
	cardId: string;
	/**
	 * (Optional) Click handler
	 */
	onClick?: (action: CardItemControlActionType) => void;
	/**
	 * (Optional) Edit action state
	 */
	actionEdit?: CardItemControlState;
	/**
	 * (Optional) Details action state
	 */
	actionDetails?: CardItemControlState;
	/**
	 * (Optional) Inverted state
	 */
	inverted?: boolean;
}

/**
 * A card item single control component
 */
export const CardItemSingleControl = memo((props: CardItemSingleControlProps): JSX.Element => {
	const { cardId, onClick, actionEdit, actionDetails, inverted } = props;

	const onClickHandler = useCallback((action: string) => {
		if (!onClick) {
			return;
		}

		onClick({
			type: action,
			cardId
		});
	}, [onClick, cardId]);

	const buttonWeight = !inverted ? ButtonIconWeight.SECONDARY : ButtonIconWeight.PRIMARY;

	const buttonEdit = actionEdit ?
		<ButtonIcon
			weight={buttonWeight}
			icon={IconIdentifier.EDIT}
			shape={ButtonIconShape.ROUND}
			disabled={actionEdit === CardItemControlState.DISABLED}
			onClick={() => onClickHandler('edit')}
		/> :
		null;

	const buttonDetails = actionDetails ?
		<ButtonGhost
			buttonText="Details"
			icon={IconIdentifier.ARROW_RIGHT}
			iconPosition={IconPosition.RIGHT}
			disabled={actionDetails === CardItemControlState.DISABLED}
			onClick={() => onClickHandler('details')}
		/> :
		null;

	return (
		<div className="card-item-single-control">
			{buttonEdit ?? buttonDetails}
		</div>
	);
});
