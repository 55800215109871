import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { ExternalReportSequenceCharts } from 'services/device/presentation/ui/sequence-chart/ExternalReportSequenceCharts';
import { ExternalReportSequenceTable } from 'services/device/presentation/ui/sequence-table/ExternalReportSequenceTable';
import { ReportRecordViewModel } from '../../../../report/domain/model/ReportRecordModel';
import { fetchExternalReportRecordsBySequence, selectExternalReportRecordByUuid } from '../../../store/externalReportRecordSlice';

export interface ExternalReportSequenceRecordsProps {
	reportUuid: string,
	device: DeviceViewModel,
	sequence: SequenceViewModel,
	isReportView?: boolean,
	onShowComment: (record: ReportRecordViewModel) => void,
	onAttachments: (record: ReportRecordViewModel) => void,
	onEdit?: (record: ReportRecordViewModel) => void,
	onDelete?: (record: ReportRecordViewModel) => void
}

export const ExternalReportSequenceRecords = (props: ExternalReportSequenceRecordsProps): JSX.Element | null => {
	const { reportUuid, isReportView, onShowComment, onAttachments, onEdit, onDelete } = props;
	const deviceViewModel = props.device;
	const sequenceViewModel = props.sequence;
	const sequenceUuid = sequenceViewModel.Uuid;

	const [highlightedRecord, setHighlightedRecord] = useState<ReportRecordViewModel>(null);

	const location = useLocation();
	// Consume the dispatch object
	const dispatch = useDispatch();

	// Read records from the server
	useEffect(() => {
		dispatch(fetchExternalReportRecordsBySequence({ sequenceUuid, reportUuid }));
	}, [dispatch, sequenceUuid, reportUuid]);

	const highlightedRecordUuid = location.hash.startsWith('#') ? location.hash.substr(1) : location.hash;
	const initialHighlightedRecordViewModel = useSelector(selectExternalReportRecordByUuid(sequenceViewModel, highlightedRecordUuid));

	if (initialHighlightedRecordViewModel !== null && highlightedRecord === null) {
		setHighlightedRecord(initialHighlightedRecordViewModel);
	}

	return (
		<>
			<ExternalReportSequenceCharts
				reportUuid={reportUuid}
				sequence={sequenceViewModel}
				showOverlay={true}
				onDetails={setHighlightedRecord}
			/>
			<ExternalReportSequenceTable
				device={deviceViewModel}
				reportUuid={reportUuid}
				sequence={sequenceViewModel}
				isReportView={isReportView}
				highlightedRecord={highlightedRecord}
				onShowComment={onShowComment}
				onAttachments={onAttachments}
				onEdit={onEdit}
				onDelete={onDelete}
			/>
		</>
	);

};
