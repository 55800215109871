import { FunctionComponent } from 'react';

import './modal-dialogue-section-column-list-wrapper.scss';


export const ModalDialogueSectionColumnListWrapper: FunctionComponent = (props): JSX.Element => {
	const { children } = props;

	return (
		<section className='modal-dialogue-section-column-list-wrapper'>
			{children}
		</section>
	);

};