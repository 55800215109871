export enum DeviceViewModalType {
	ARCHIVE = 'ARCHIVE',
	CREATE = 'CREATE',
	DELETE = 'DELETE',
	EDIT = 'EDIT',
	PENDING = 'PENDING',
	UNARCHIVE = 'UNARCHIVE',
	CREATE_SEQUENCE = 'CREATE_SEQUENCE',
	CREATE_RECORD = 'CREATE_RECORD',
	CREATE_MAINTENANCE_LOG = 'CREATE_MAINTENANCE_LOG',
	RESOLVE_MAINTENANCE_LOG = 'RESOLVE_MAINTENANCE_LOG',
	CREATE_DOCUMENT = 'CREATE_DOCUMENT',
	DELETE_DOCUMENT = 'DELETE_DOCUMENT',
	ARCHIVE_DOCUMENT = 'ARCHIVE_DOCUMENT',
	UNARCHIVE_DOCUMENT = 'UNARCHIVE_DOCUMENT',
	DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
	EDIT_DOCUMENT_NAME = 'EDIT_DOCUMENT_NAME',
	EDIT_CLEARANCE = 'EDIT_CLEARANCE',
	CREATE_MEASUREMENT_GOAL = 'CREATE_MESUREMENT_GOAL'
}
