export enum NuclideHalfLifePerYear {

	CESIUM_137_CS = 3.00E+01,

	COBALT_58_CO = 1.94113620807666E-01,

	ERBIUM_169_ER = 2.57357973990418E-02,

	FLUORINE_18_F = 2.08570993991939E-04,

	GALLIUM_68_GA = 1.28717012700586E-04,

	GERMANIUM_68_GE = 7.425E-01,

	INDIUM_111_IN = 7.66598220396988E-03,

	IODINE_123_I = 1.50581793292266E-03,

	IODINE_131_I = 2.19028062970568E-02,

	LUTETIUM_177_LU = 1.80698151950719E-02,

	MOLYBDENUM_99_MO = 7.52908966461328E-03,

	NITROGEN_13_N = 1.90128526884174E-05,

	RHENIUM_186_RE = 1.02669404517454E-02,

	TECHNETIUM_99M_TC = 6.84462696783025E-04,

	YTTRIUM_90_Y = 6.95870408396076E-03
}
