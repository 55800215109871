import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import React, { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { DateTimePicker } from '../../../../../../presentation/ui/compositions/date-time-picker/DateTimePicker';

export interface ContainerDisposeModalStep1Props {

	containerDisposeModel: { [key: string]: any };
	/**
	 * Button next action
	 */
	onClickNext: () => void;
	/**
	 * Invalid inputs
	 */
	invalidInputs?: string[];
	/**
	 * Change generator props
	 */
	onChangeContainerDisposeProp: (prop: string, value: Date) => void;
}

export const ContainerDisposeFormStep1 = (props: ContainerDisposeModalStep1Props): JSX.Element => {
	const { containerDisposeModel, invalidInputs = [], onChangeContainerDisposeProp, onClickNext } = props;

	// Consume the contexts
	const l10nContext = useContext(L10nContext);

	// Notification for invalid input fields
	const checkPlural = invalidInputs.length > 1 ? l10nContext.translate('common.modal.notificationErrorPlural', ' dürfen nicht leer sein') : l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein');
	let notification = null;
	if (invalidInputs.length > 0) {
		const invalidNotificationMessage = invalidInputs.join(l10nContext.translate('common.text.and', ' und '))
			+ checkPlural;
		notification = (
			<NotificationBar
				level={NotificationLevel.LEVEL_ERROR}
				message={invalidNotificationMessage}
			/>
		);
	}

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<NotificationBars>
				{notification}
			</NotificationBars>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('containers.disposedDateTime', 'Entsorgt am')}*`}>
					<DateTimePicker
						name="disposedDatetime"
						defaultValue={containerDisposeModel?.DisposedDatetime ?? new Date()}
						onChange={(event) => onChangeContainerDisposeProp('DisposedDatetime', event)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
