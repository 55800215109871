import { OutputWidget } from 'services/device/domain/business/common/widget/Widget';
import { OutputWidgetSelection } from 'services/device/presentation/ui/widget/widget-renderer/OutputWidgetSelection';

import './output-widget-renderer.scss';

export interface OutputWidgetProps {
	widget: OutputWidget;
}

export const OutputWidgetRenderer = (props: OutputWidgetProps): JSX.Element => {
	const { widget } = props;

	const outputNodes: Array<JSX.Element> = [];

	for (const outputField of widget.getOutputs()) {
		outputField.selectOutput(
			new OutputWidgetSelection((element): void => {
				outputNodes.push(element);
			})
		);
	}

	return (
		<ul className="output-widget">
			{outputNodes}
		</ul>
	);

};
