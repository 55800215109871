import { useContext, useState } from 'react';

import { useDispatch } from 'react-redux';

import { updateGenerator } from 'services/nuclide/store/generatorSlice';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { AuthContext } from 'services/core/context/AuthContext';
import { GeneratorModelState } from 'services/nuclide/domain/model/GeneratorModelState';
import { DebugConsole } from '../../../../../../lib/debug/DebugConsole';
import { calculateCurrentActivity } from '../../../../domain/model/isotopes/NuclideIsotopeDefinition';
import { GeneratorDispatchFormStep1 } from './GeneratorDispatchFormStep1';
import { GeneratorDispatchFormStep2 } from './GeneratorDispatchFormStep2';

export interface GeneratorDispatchFormProps {
	generator?: GeneratorViewModel;
}

/**
 * The generator update dispatch form
 */
export const GeneratorDispatchForm = (props: GeneratorDispatchFormProps): JSX.Element => {
	const { generator } = props;

	// Consume the context
	const authContext = useContext(AuthContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const updatedGenerator: GeneratorViewModel = generator;

	const generatorTypeIsotope = generator.Isotope;

	const requiredInputs = ['DispatchDatetime'];
	const currentActivity = calculateCurrentActivity(generator.CalibrationDatetime, generator.NominalActivity, generatorTypeIsotope);

	updatedGenerator.DispatchedBy = authContext.getActor().Uuid;
	updatedGenerator.DispatchDatetime = new Date();

	const onChangeGeneratorProp = (prop: string, value: any) => {
		updatedGenerator[prop] = value;
	};

	DebugConsole.log('updatedGenerator', updatedGenerator);

	const handleUpdate = (): void => {
		updatedGenerator.State = GeneratorModelState.DISPATCHED;
		updatedGenerator.DispatchedActivity = currentActivity;
		dispatch(updateGenerator(updatedGenerator));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newGeneratorModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedGenerator?.[requiredInput] ?? null) === null
				|| updatedGenerator?.[requiredInput] === ''
				|| String(updatedGenerator.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newGeneratorModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newGeneratorModelValid);
	};

	const showModalStep = showStep1 === true ?
		<GeneratorDispatchFormStep1
			generator={updatedGenerator}
			onClickNext={goToStep2}
			onChangeGeneratorProp={onChangeGeneratorProp}
			invalidInputs={invalidInputs}
		/> :
		<GeneratorDispatchFormStep2
			generator={updatedGenerator}
			onClickPrevious={goToStep1}
			onClickConfirm={handleUpdate}
		/>;

	return (
		<>
			{showModalStep}
		</>
	);
};
