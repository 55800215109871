import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { ReportViewModel } from 'services/report/domain/model/ReportModel';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ReportUpdateFailedNotification } from './ReportUpdateFailedNotification';
import { ReportUpdateSuccessNotification } from './ReportUpdateSuccessNotification';
import { ReportUpdateForm } from '../report-update-form/ReportUpdateForm';

export interface ReportUpdateModalProps {
	report: ReportViewModel
	onDismiss?: () => void;
}

export const ReportUpdateModal = (props: ReportUpdateModalProps): JSX.Element => {
	const { report, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const reportActionStatus = useTypedSelector(state => state.reports.actionStatus);

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (reportActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent = <ReportUpdateForm report={report} />;
				break;

			case AsyncReducerStatus.UPDATED:
				modalContent = <ReportUpdateSuccessNotification onClickConfirm={onDismiss} />;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <ReportUpdateFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.reports.modal.action.update.caption', 'Bericht bearbeiten')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
