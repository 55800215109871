import { CreatableViewModel } from 'lib/domain/model/Model';
import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	RoleModelConverter as ModelConverter,
	RolePersistenceModel as PersistenceModel,
	RoleStoreModel as StoreModel,
	RoleViewModel as ViewModel
} from 'services/core/domain/model/RoleModel';

export class RoleHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'core/api/v1';
	protected collectionControllerUri = 'roles';
	protected entityControllerUri: string = null;

	protected responseCollectionKey = 'RoleModelCollection';
	protected responseEntityKey: string = null;

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
