import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Tag, TagType } from 'presentation/ui/partials/tag/Tag';

import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { getSequenceDue } from 'services/device/domain/business/util/SequenceUtil';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { SequenceModelState } from 'services/device/domain/model/SequenceModelState';

import './card-item-sequence-header.scss';

export interface CardItemSequenceHeaderProps {
	sequence: SequenceViewModel,
	isReportView?: boolean
}

/**
 * A card item operational log header component
 */
export const CardItemSequenceHeader = (props: CardItemSequenceHeaderProps): JSX.Element => {
	const { sequence, isReportView } = props;

	const sequenceDue = getSequenceDue(sequence);

	const l10nContext = useContext(L10nContext);

	const renderTag = (): JSX.Element | null => {
		if (sequence.State === SequenceModelState.ARCHIVED) {
			return (
				<Tag
					label={l10nContext.translate('common.tags.archived', 'Archiviert')}
					type={TagType.INFO}
					icon={IconIdentifier.ARCHIVE}
				/>
			);
		}
		if (sequenceDue) {
			return (
				<Tag
					label={l10nContext.translate('sequence.due')}
					type={TagType.INFO}
					icon={IconIdentifier.ALERT}
				/>
			);
		}
		return null;
	};

	return (
		<div className="card-item-sequence-header">

			<div className="card-item-sequence-header__content">
				<span className="card-item-sequence-header__title-wrapper">
					<h1 className="card-item-sequence-header__title">
						{sequence.Name}
					</h1>
				</span>
				<p className="card-item-sequence-header__subtitle">
					{l10nContext.translate(getSequenceTypeDefinition(sequence.Type).getLabelKey())}
				</p>
			</div>
			{!isReportView ?? renderTag()}
		</div>
	);
};
