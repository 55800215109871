import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';
import { SVGAsset } from 'presentation/ui/partials/SVGAsset';

import './illustration.scss';

export interface IlluProps {
	/**
	 * Name of the illustration
	 */
	name: IllustrationIdentifier;
}

/**
 * The illustration component
 */
export const Illustration = ({ name }: IlluProps): JSX.Element => (
	<div className='illustration'>
		<SVGAsset name={name} />
	</div>
);
