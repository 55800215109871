import { L10nContext } from 'context/L10nContext';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import React, { ChangeEvent, useContext } from 'react';
import { selectInWastemanagementEluates } from 'services/nuclide/store/eluateSlice';
import { Guid } from '../../../../../../lib/guid/Guid';
import {
	CardNuclideListItem
} from '../../../../../../presentation/ui/components/cards/card-nuclide/card-nuclide-list-item/CardNuclideListItem';
import { ExpandableSlimBody } from '../../../../../../presentation/ui/compositions/expandable-slim/expandable-slim-body/ExpandableSlimBody';
import {
	ExpandableSlimHeader
} from '../../../../../../presentation/ui/compositions/expandable-slim/expandable-slim-header/ExpandableSlimHeader';
import { ExpandableSlim } from '../../../../../../presentation/ui/compositions/expandable-slim/ExpandableSlim';
import { CardCollectionLayout } from '../../../../../../presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { CardItemControlActionType } from '../../../../../../presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { Checkbox } from '../../../../../../presentation/ui/partials/input/checkbox/Checkbox';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';
import { AuthContext } from '../../../../../core/context/AuthContext';
import { ClientContext } from '../../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../../core/context/FacilityContext';
import { Permission } from '../../../../../core/lib/auth/AuthService';
import { CyclotronProductViewModel } from '../../../../domain/model/CyclotronProductModel';
import { EluatePayloadType } from '../../../../domain/model/EluateModel';
import { GeneratorEluateIsotope } from '../../../../domain/model/isotopes/GeneratorEluateIsotope';
import { GeneratorTypeIsotope } from '../../../../domain/model/isotopes/GeneratorTypeIsotope';
import { getNuclideIsotopeDefinition } from '../../../../domain/model/isotopes/NuclideIsotopeDefinition';
import { selectContainers } from '../../../../store/containerSlice';
import { selectInWastemanagementCyclotronProducts } from '../../../../store/cyclotronProductSlice';
import { selectGenerators } from '../../../../store/generatorSlice';

export interface SingleNuclideCollectionProps {
	onActionClick?: (actionPayload: CardItemControlActionType, item: EluatePayloadType | CyclotronProductViewModel) => void,
	selectedNuclides: Array<string>,
	checkNuclide: (uuid: string) => void,
	onHandleSelectAllNuclids: (
		nuclide: string,
		filteredNuclides: Array<EluatePayloadType| CyclotronProductViewModel>,
		e: ChangeEvent<HTMLInputElement>) => void;
	onHandleCheckAllNuclides: (
		nuclideLabel: string,
		filteredNuclides: Array<EluatePayloadType | CyclotronProductViewModel>
	) => boolean;
}

export const SingleNuclideCollection = (props: SingleNuclideCollectionProps): JSX.Element => {

	const { selectedNuclides, checkNuclide, onActionClick, onHandleSelectAllNuclids, onHandleCheckAllNuclides } = props;

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	const containers = useTypedSelector(selectContainers(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementCyclotronProducts = useTypedSelector(selectInWastemanagementCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementEluates = useTypedSelector(selectInWastemanagementEluates());

	const generators = useTypedSelector(selectGenerators(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	inWastemanagementEluates.forEach(eluate => {

		const inWastemanagementEluateGenerator = generators.find((generator) => {
			return generator.Uuid === eluate.Generator;
		});
		if (inWastemanagementEluateGenerator.Isotope === GeneratorTypeIsotope.MOLYBDENUM_99_MO) {
			eluate.Isotope = GeneratorEluateIsotope.TECHNETIUM_99M_TC;
		} else {
			eluate.Isotope = GeneratorEluateIsotope.GALLIUM_68_GA;
		}
	});

	const filteredEluatesAndCyclotronProducts: Array<EluatePayloadType | CyclotronProductViewModel> =
		[].concat(inWastemanagementEluates, inWastemanagementCyclotronProducts);

	let containerNuclideIds: string[] = [];
	containers.map((container) => {
		containerNuclideIds = containerNuclideIds.concat(container.CyclotroneProductIds);
		containerNuclideIds = containerNuclideIds.concat(container.GeneratorEluatIds);
		return containerNuclideIds;
	});

	const filteredArray = filteredEluatesAndCyclotronProducts.filter(value => !containerNuclideIds.includes(value.Uuid));

	const nuclides: string[] = [];

	if (filteredArray.length > 0) {
		// eslint-disable-next-line array-callback-return
		filteredArray.map((item) => {
			if (!nuclides.includes(item.Isotope)) {
				nuclides.push(item.Isotope);
			}
		});
	}

	const renderNuclidesByIsotope = () => {
		if (filteredArray.length === 0) {
			return <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.nuclides', 'Es sind keine Nuklide ungebunden')} />;
		}

		return (
			nuclides.sort().map((nuclide) => {

				return (
					<CardCollectionLayout key={Guid.generate()}>
						<ExpandableSlim mode="slim" expanded>
							<ExpandableSlimHeader
								caption={l10nContext.translate(getNuclideIsotopeDefinition(nuclide).getLabelKey())}
								isSlimMode={true}
							/>
							<ExpandableSlimBody>
								<CardCollectionLayout>
									<Checkbox
										labelName={l10nContext.translate('view.wastemanagement.selectAllNuclides', 'Alle Nuklide auswählen')}
										checked={onHandleCheckAllNuclides(nuclide, filteredArray)}
										onChange={(e) => onHandleSelectAllNuclids(nuclide, filteredArray, e )}
										isReportSelection
										disabled={!authContext.hasPermission(Permission.WASTE_MANAGEMENT_UPDATE)}
									/>
									<CardNuclideListItem
										nuclide={nuclide}
										nuclides={filteredArray}
										checkNuclide={checkNuclide}
										selectedNuclides={selectedNuclides}
										onActionClick={(payload, item) => onActionClick(payload, item)}
									/>
								</CardCollectionLayout>

							</ExpandableSlimBody>
						</ExpandableSlim>
					</CardCollectionLayout>
				);

			})
		);
	};

	return (
		<ExpandableSlim mode='slim' firstElement>
			{renderNuclidesByIsotope()}
		</ExpandableSlim>
	);
};
