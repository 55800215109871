import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';

import {
	WasteManagementReportGeneratorModelConverter as ModelConverter,
	WasteManagementReportGeneratorPersistenceModel as PersistenceModel,
	WasteManagementReportGeneratorStoreModel as StoreModel,
	WasteManagementReportGeneratorViewModel as ViewModel,
	CreatableWasteManagementReportGeneratorViewModel as CreatableViewModel
} from 'services/waste-management-report/domain/models/WasteManagementReportGeneratorModel';
import { ExternalWasteReportHttpPersistence } from '../../../lib/persistence/http/ExternalWasteReportHttpPersistence';

export class ExternalWasteManagementReportGeneratorHttpPersistence
	extends ExternalWasteReportHttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'generator/waste-management-report-api/v1';
	protected collectionControllerUri = 'generators';
	protected entityControllerUri = 'generator';

	protected responseCollectionKey = 'GeneratorModelCollection';
	protected responseEntityKey = 'GeneratorModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
