import { PrivateLocalStorageKey } from 'lib/browser-storage/PrivateLocalStorageKey';
import { LocalStorage } from 'lib/browser-storage/LocalStorage';

export class PrivateLocalStorage extends LocalStorage<PrivateLocalStorageKey> {

	private static instance: PrivateLocalStorage = null;

	private readonly actorUuid: string;

	// eslint-disable-next-line no-useless-constructor
	private constructor(actorUuid: string) {
		super();
		this.actorUuid = actorUuid;
	}

	public static get(actorUuid: string): PrivateLocalStorage {
		if (this.instance === null) {
			this.instance = new PrivateLocalStorage(actorUuid);
		}
		return this.instance;
	}

	protected buildKey(key: PrivateLocalStorageKey): string {
		return 'private_' + this.actorUuid + '_' + (key as string);
	}

}
