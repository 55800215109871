import { L10nContext } from 'context/L10nContext';
import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { GeneratorDispatchForm } from '../generator-dispatch-form/GeneratorDispatchForm';
import { GeneratorDispatchFailedNotification } from './GeneratorDispatchFailedNotification';
import { GeneratorDispatchSuccessNotification } from './GeneratorDispatchSuccessNotification';

interface GeneratorDispatchModalProps {
	/**
	 * Generator
	 */
	generator: GeneratorViewModel;
	/**
	 * onDismiss
	 */
	onDismiss: () => void;
}

/**
 * The generator update dispatch modal
 */
export const GeneratorDispatchModal = (props: GeneratorDispatchModalProps): JSX.Element => {
	const { generator, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const generatorActionStatus = useTypedSelector(state => state.generators.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (generatorActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <GeneratorDispatchForm
					generator={generator}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modal =
					<GeneratorDispatchSuccessNotification
						onClickConfirm={onDismiss}
						generatorUpdated={generator}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal = 
					<GeneratorDispatchFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;

	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.generators.modal.action.dispatch.caption', 'Abgang erfassen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
