import { useContext, useRef } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { FolderViewModel } from 'services/documents/domain/model/FolderModel';
import { FolderMoveForm } from 'services/documents/presentation/ui/folder-action/folder-move-form/FolderMoveForm';
import { FolderMoveFailedNotification } from 'services/documents/presentation/ui/folder-action/folder-move-modal/FolderMoveFailedNotification';
import { FolderMoveSuccessNotification } from 'services/documents/presentation/ui/folder-action/folder-move-modal/FolderMoveSuccessNotification';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface FolderMoveModalProps {
	folder: FolderViewModel;
	onDismiss?: () => void;
}

export const FolderMoveModal = (props: FolderMoveModalProps): JSX.Element => {
	const { folder, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const targetFolder = useRef<FolderViewModel>(folder);

	const folderActionStatus = useTypedSelector(state => state.folders.actionStatus);

	const setTargetFolder = (selectedFolder: FolderViewModel) => {
		targetFolder.current = selectedFolder;
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (folderActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent = <FolderMoveForm
					folder={folder}
					onChange={setTargetFolder}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modalContent = <FolderMoveSuccessNotification
					sourceFolder={folder}
					targetFolder={targetFolder.current}
					onClickConfirm={onDismiss}
				/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <FolderMoveFailedNotification onDismiss={onDismiss} />;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.documents.modal.action.move.caption', 'Ordner verschieben')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
