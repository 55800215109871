import { FunctionComponent, useCallback } from 'react';

import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './card-sequence-list-item.scss';

type CardSequenceListItemButton = {
	disabled: boolean;
	onClick: (action: CardItemControlActionType) => void;
};

export interface CardSequenceListItemProps {
	/**
	 * (Optional) Card Id
	 */
	cardId?: string;
	/**
	 * (Optional) Disabled state
	 */
	disabled?: boolean;
	/**
	 * (Optional) onClick-handler
	 */
	onClick?: (action: CardItemControlActionType) => void;
	/**
	 * (Optional) Button props
	 */
	button?: CardSequenceListItemButton;
	/**
	 * (Optional) Archived state
	 */
	archived?: boolean;
}

/**
 * The sequence list item card component
 */
export const CardSequenceListItem: FunctionComponent<CardSequenceListItemProps> = (props): JSX.Element => {
	const { cardId, onClick, disabled, button, children, archived = false } = props;

	const onCardClickHandler = useCallback(() => {
		if (!onClick || disabled) return;

		onClick({
			type: 'show',
			cardId
		});
	}, [onClick, cardId, disabled]);

	const onButtonClickHandler = () => {
		if (!button.onClick) return;

		button.onClick({
			type: 'add',
			cardId
		});
	};

	const buttonEl = button ? 
		<ButtonIcon
			icon={IconIdentifier.ADD}
			shape={ButtonIconShape.RECTANGLE}
			weight={ButtonIconWeight.PRIMARY}
			disabled={button?.disabled ?? false}
			onClick={onButtonClickHandler}
		/> : null;

	const componentDisabledStyle = disabled ?
		' card-sequence-list-item--disabled' :
		'';

	const componentArchivedStyle = archived ?
		' card-sequence-list-item--archived' :
		'';

	return (
		<div className={`card-sequence-list-item${componentDisabledStyle}${componentArchivedStyle}`}>
			<div
				className='card-sequence-list-item__content'
				onClick={onCardClickHandler}
				role='button'
				tabIndex={0}
			>
				{children}
			</div>

			{buttonEl}
		</div>
	);
};
