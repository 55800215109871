import { Optional } from '../misc/Optional';
import { Value } from './Value';

export class List<Payload> implements Value<Payload> {
	private readonly values: ReadonlyArray<Payload>;

	constructor(values: ReadonlyArray<Payload>) {
		this.values = values;
	}

	public isRepeatable(): boolean {
		return true;
	}

	public get(): ReadonlyArray<Payload> {
		return this.values;
	}

	public getSingle(): Optional<Payload> {
		throw new Error("Array value is repeatable, can't return single value");
	}
}
