import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	ContainerDisposeModelConverter as ModelConverter,
	ContainerDisposePersistenceModel as PersistenceModel,
	ContainerDisposeStoreModel as StoreModel,
	ContainerDisposeViewModel as ViewModel,
	CreatableContainerDisposeViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/ContainerDisposeModel';

export class ContainerDisposeHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'waste-management/api/v1';
	protected collectionControllerUri = 'container-disposes';
	protected entityControllerUri = 'container-dispose';

	protected responseCollectionKey = 'ContainerDisposeModelCollection';
	protected responseEntityKey = 'ContainerDisposeModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
