import { createContext } from 'react';

import { FacilityModel } from 'services/core/lib/auth/AuthService';

interface FacilityContextValue {
	selectedFacilityUuid: string;

	select(facilityUuid: string): void;

	deselect(): void;

	selectedFacility(): FacilityModel | null;

	availableFacility(facilityUuid: string): FacilityModel | null;

	availableFacilities(): Array<FacilityModel>;
}

export const FacilityContext = createContext<FacilityContextValue>({
	selectedFacilityUuid: null,

	select: (): void => {
	},

	deselect: (): void => {
	},

	selectedFacility: (): FacilityModel | null => {
		return null;
	},

	availableFacility: (): FacilityModel | null => {
		return null;
	},

	availableFacilities: (): Array<FacilityModel> => {
		return [];
	}

});
