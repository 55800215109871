import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';
import { memo, useContext } from 'react';
import { useTypedSelector } from '../../../../../store/common/TypedSelector';
import {
	getEluateMeasurementUnitDefinition,
	getGeneratorMeasurementUnitDefinition
} from '../../../../nuclide/domain/model/NuclideMeasurementUnitDefinition';
import { NuclideModelActivityUnit } from '../../../../nuclide/domain/model/NuclideModelActivityUnit';
import { selectExternalWasteManagementReportEluatesByGenerator } from '../../../../nuclide/store/externalWasteManagementEluateReportSlice';
import { WasteManagementReportGeneratorViewModel } from '../../../domain/models/WasteManagementReportGeneratorModel';

import './external-waste-management-report-generator-detail.scss';

export interface ExternalWasteManagementReportGeneratorDetailProps {
	generator: WasteManagementReportGeneratorViewModel,
	isDetail: boolean
}

/**
 * A card item Container detail attributes component
 */
export const ExternalWasteManagementReportGeneratorDetail = memo((props: ExternalWasteManagementReportGeneratorDetailProps): JSX.Element => {
	const { generator, isDetail } = props;

	const l10nContext = useContext(L10nContext);

	const eluates = useTypedSelector(selectExternalWasteManagementReportEluatesByGenerator(generator.Uuid));

	const checkEmptyValue = (val: string): string => {
		return val !== '' && val !== null ? val : '-';
	};

	const KILO_BECQUEREL: number = 1000;
	const MEGA_BECQUEREL = KILO_BECQUEREL * 1000;
	const GIGA_BECQUEREL = MEGA_BECQUEREL * 1000;

	let sumAllEluateActivities = 0;
	eluates.map((eluate) => {
		if (eluate.ActivityUnit === NuclideModelActivityUnit.KILO_BEQUEREL) {
			sumAllEluateActivities += eluate.Activity * KILO_BECQUEREL;
		}
		if (eluate.ActivityUnit === NuclideModelActivityUnit.MEGA_BEQUEREL) {
			sumAllEluateActivities += eluate.Activity * MEGA_BECQUEREL;
		}
		if (eluate.ActivityUnit === NuclideModelActivityUnit.GIGA_BEQUEREL) {
			sumAllEluateActivities += eluate.Activity * GIGA_BECQUEREL;
		}
		return sumAllEluateActivities;
	});

	const eluateActivities = l10nContext.formatNumber(sumAllEluateActivities / 1000000) + ' MBq';

	const deliveryDateFormatted = l10nContext.formatDate(generator?.DeliveryDate);
	const calibrationDatetimeFormatted = l10nContext.formatDateTime(generator?.CalibrationDatetime);
	const dispatchDateFormatted = l10nContext.formatDateTime(generator?.DispatchDatetime) == null ? '-' : l10nContext.formatDateTime(generator?.DispatchDatetime);
	const generatorActivityUnit = l10nContext.translate(getGeneratorMeasurementUnitDefinition(generator.ActivityUnit).getLabelKey());
	const dispatchActivity = generator.DispatchedActivity === null ? '-' : checkEmptyValue(generator.DispatchedActivity + ' ' + generatorActivityUnit);


	const translations = {
		generatorName: { label: l10nContext.translate('externalWasteManagementView.generators.generatorName', 'Name'), value: generator.Name },
		deliveryDate: { label: l10nContext.translate('externalWasteManagementView.generators.deliveryDate', 'Datum Zugang'), value: deliveryDateFormatted },
		serialnumber: { label: l10nContext.translate('externalWasteManagementView.generators.serialnumber', 'Seriennummer'), value: generator.SerialNumber },
		manufacturer: { label: l10nContext.translate('externalWasteManagementView.generators.manufacturer', 'Lieferant'), value: generator.Manufacturer },
		nominalActivity: { label: l10nContext.translate('externalWasteManagementView.generators.nominalActivity', 'Nominalaktivität'), value: checkEmptyValue(generator.NominalActivity + ' ' + generatorActivityUnit) },
		calibrationDatetime: { label: l10nContext.translate('externalWasteManagementView.generators.calibrationDatetime', 'Kalibrierzeitpunkt'), value: checkEmptyValue(calibrationDatetimeFormatted) },
		dispatchDate: { label: l10nContext.translate('externalWasteManagementView.generators.dispatchDate', 'Datum Rücksendung'), value: checkEmptyValue(dispatchDateFormatted) },
		dispatchActivity: { label: l10nContext.translate('externalWasteManagementView.generators.dispatchActivity', 'Aktivität bei Rücksendung'), value: dispatchActivity },
	};

	const attributes = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const styleClass = 'attribute';

			return (
				<label
					key={Guid.generate()}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value }</p>
				</label>
			);
		});
	};

	const listOfEluates = () => {
		return eluates.map((eluate) => {
			return (
				<p key={eluate.Uuid}>
					{ l10nContext.translate('view.singleNuclides.eluateFrom', 'Eluat vom ')
						+ l10nContext.formatDateTime(eluate.ExtractionDatetime)
						+ ', '
						+ l10nContext.translate('view.singleNuclides.activity', 'Aktivität')
						+ ' '
						+ l10nContext.formatNumber(eluate.Activity)
						+ ' '
						+ l10nContext.translate(getEluateMeasurementUnitDefinition(eluate.ActivityUnit).getLabelKey()) }
				</p>
			);
		});
	};

	return (
		<div className='external-waste-management-report-generator-detail'>
			<div className='external-waste-management-report-generator-detail__attributes'>
				{attributes()}
				{ isDetail ?
					<label
						key={Guid.generate()}
						className='attribute'
					>
						<strong>{l10nContext.translate('externalWasteManagementView.generators.sumActivitiesEluates', 'Summe der Aktivitäten aller Eluate')}:&nbsp;</strong>
						<p>{eluateActivities }</p>
					</label> : null }
			</div>
			<div className='external-waste-management-report-generator-detail__eluates'>
				{ isDetail ? listOfEluates() : null}
			</div>
		</div>
	);
});
