/* eslint-disable react/static-property-placement, max-classes-per-file */
import { Field } from '../field/Field';
import { Widget, InputWidget, WidgetSelection } from '../widget/Widget';
import { AbstractFieldSet } from '../field-set/AbstractFieldSet';
import { Input } from '../Input';
import { Instructions } from '../misc/Instructions';
import { Optional } from '../misc/Optional';
import { Step } from '../misc/Step';
import { Valuation, worst } from '../Valuation';
import { Violation } from '../violation/Violation';
import { ViolationError } from '../violation/ViolationError';
import { Interaction } from './Interaction';
import { Environment } from '../misc/Environment';
import { StoreData, ViewData } from '../Data';

const WIDGET_SELECTION: WidgetSelection<Array<Input<any>>> = new (class implements WidgetSelection<Array<Input<any>>> {
	onInputWidget(widget: InputWidget): Array<Input<any>> {
		return [...widget.getInputs()];
	}

	onMessageWidget(): Array<Input<any>> {
		return [];
	}

	onOutputWidget(): Array<Input<any>> {
		return [];
	}

	onSummaryWidget(): Array<Input<any>> {
		return [];
	}
})();

export abstract class AbstractInteraction<Context, Fields, FieldSet extends AbstractFieldSet<Fields>, Result> implements Interaction<Result> {
	private readonly fieldSet: FieldSet;

	private readonly context: Context;

	private readonly steps: ReadonlyArray<Step<Context, Fields>>;

	private readonly instructions: Instructions<Context>;

	private readonly stepNumber: number;

	private readonly enteredFields: ReadonlyArray<Field<any, any>>;

	public constructor(context: Context, environment: Environment, fieldSet: FieldSet, steps: ReadonlyArray<Step<Context, Fields>>, stepNumber: number) {
		this.fieldSet = fieldSet;
		this.context = context;
		this.steps = steps;
		this.instructions = steps[stepNumber].instructions(fieldSet.getRawFields());
		this.stepNumber = stepNumber;
		if (this.instructions.calculations) {
			this.instructions.calculations.forEach((field) => {
				field.preCalculateOrEnter(this.context, environment);
				field.calculate(this.context);
				field.postCalculateOrEnter(this.context);
			});
		}
		this.enteredFields = this.getEnteredFields(this.instructions.widgets, fieldSet.getFieldsByName());
		this.enteredFields.forEach((field) => {
			field.preCalculateOrEnter(this.context, environment);
		});
	}

	public getWidgets(): ReadonlyArray<Widget> {
		return this.instructions.widgets;
	}

	private getEnteredFields(widgets: ReadonlyArray<Widget>, fieldsByName: ReadonlyMap<string, Field<any, any>>): ReadonlyArray<Field<any, any>> {
		const fields: Array<Field<any, any>> = [];
		const allInputs: Array<Array<Input<any>>> = widgets.map((widget) => widget.selectWidget(WIDGET_SELECTION));
		allInputs.forEach((inputs) =>
			inputs.forEach((input) => {
				const field: Optional<Field<any, any>> = fieldsByName.get(input.getName()) ?? null;
				if (field !== null) {
					fields.push(field);
				}
			})
		);
		return fields;
	}

	public isFirst(): boolean {
		return this.stepNumber === 0;
	}

	public isLast(): boolean {
		return this.stepNumber === this.steps.length - 1;
	}

	public previous(): this {
		if (this.isFirst()) {
			throw new Error('This interaction has no predecessor');
		}
		return this.createSibling(this.fieldSet.clone(), this.steps, this.stepNumber - 1);
	}

	public next(): this {
		if (this.isLast()) {
			throw new Error('This interaction has no successor');
		}
		this.doPostEnterAndValidate();
		return this.createSibling(this.fieldSet.clone(), this.steps, this.stepNumber + 1);
	}

	public validate(): ReadonlyArray<Violation> {
		let violations: Array<Violation> = [];
		this.enteredFields.forEach((field) => {
			violations = violations.concat(field.validate());
		});
		if (violations.length === 0 && this.instructions.validations) {
			this.instructions.validations.forEach((validation) => {
				const result: Optional<boolean> = validation.condition(this.context).getSingle();
				if (result !== null && result === false) {
					violations.push(validation.violation);
				}
			});
		}
		return violations;
	}

	public finish(): Result {
		if (!this.isLast()) {
			throw new Error('This interaction has no result');
		}
		this.doPostEnterAndValidate();
		return this.createResult(this.fieldSet.clone(), this.getValuation());
	}

	private doPostEnterAndValidate() {
		this.enteredFields.forEach((field) => {
			field.postCalculateOrEnter(this.context);
		});
		const violations: ReadonlyArray<Violation> = this.validate();
		if (violations.length !== 0) {
			throw new ViolationError(violations);
		}
	}

	private getValuation(): Valuation {
		return worst(this.fieldSet.getAllFields().map((field) => field.getValuation()));
	}

	protected getFieldSet(): FieldSet {
		return this.fieldSet;
	}

	protected abstract createSibling(fieldSet: FieldSet, steps: ReadonlyArray<Step<Context, Fields>>, newStepNumber: number): this;

	protected abstract createResult(fieldSet: FieldSet, valuation: Valuation): Result;

	public abstract toStoreData(): StoreData;

	public abstract toViewData(): ViewData;
}
