import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';

import { deleteMembership } from 'services/core/store/membershipSlice';
import { MembershipViewModel } from 'services/core/domain/model/MembershipModel';
import { MembershipDeleteFailedNotification } from './MembershipDeleteFailedNotification';
import { MembershipDeleteForm } from '../membership-delete-form/MembershipDeleteForm';

export interface MembershipDeleteModalProps {
	membership: MembershipViewModel;
	onDismiss?: () => void;
}

export const MembershipDeleteModal = (props: MembershipDeleteModalProps): JSX.Element => {
	const { membership, onDismiss } = props;

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const membershipActionStatus = useTypedSelector(state => state.memberships.actionStatus);

	const dispatch = useDispatch();

	const handleDelete = (): void => {
		dispatch(deleteMembership({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			viewModel: membership
		}));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (membershipActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<MembershipDeleteForm
						membership={membership}
						onDelete={handleDelete}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <MembershipDeleteFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
