import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';

export interface MaintenanceLogModalCommentSuccessProps {
	onClick: () => void
}

export const MaintenanceLogCommentModalSuccess = (props: MaintenanceLogModalCommentSuccessProps): JSX.Element => {
	const { onClick } = props;

	const l10nContext = useContext(L10nContext);
	const history = useHistory();

	const successBtnTranslation = () => {
		let translation;

		if (history.location.pathname.includes(Route.DEVICES)) {
			translation = l10nContext.translate('view.device.modal.action.createMaintenance.btnSuccess', 'Weiter zur Geräte Detailansicht');
		} else if (history.location.pathname.includes(Route.DASHBOARD)) {
			translation = l10nContext.translate('view.dashboard.modal.btnSuccess', 'Weiter zum Dashboard');
		} else {
			translation = l10nContext.translate('view.maintenances.modal.action.edit.btnSuccess', 'Weiter zur Betriebsbuch-Eintrag Detailansicht');
		}

		return translation;
	};

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.maintenances.modal.action.edit.successMessage', 'Der Eintrag wurde erfolgreich aktualisiert')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={successBtnTranslation()}
						onClick={onClick}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
