import { Optional } from 'services/device/domain/business/common/misc/Optional';
import { NuclideModelActivityUnit } from './NuclideModelActivityUnit';

export class NuclideMeasurementUnitDefinition {

	private readonly measurementUnit: NuclideModelActivityUnit;

	private readonly labelKey: string;

	constructor(measurementUnit: NuclideModelActivityUnit, labelKey: string) {
		this.measurementUnit = measurementUnit;
		this.labelKey = labelKey;
	}

	public getMeasurementUnit(): NuclideModelActivityUnit {
		return this.measurementUnit;
	}

	public getLabelKey(): string {
		return this.labelKey;
	}

	public toString = (): string => {
		return this.labelKey.toString();
	};

}

export const KILO_BEQUEREL: NuclideMeasurementUnitDefinition = new NuclideMeasurementUnitDefinition(
	NuclideModelActivityUnit.KILO_BEQUEREL,
	'nuclides.measurementUnits.kbq'
);

export const MEGA_BEQUEREL: NuclideMeasurementUnitDefinition = new NuclideMeasurementUnitDefinition(
	NuclideModelActivityUnit.MEGA_BEQUEREL,
	'nuclides.measurementUnits.mbq'
);

export const GIGA_BEQUEREL: NuclideMeasurementUnitDefinition = new NuclideMeasurementUnitDefinition(
	NuclideModelActivityUnit.GIGA_BEQUEREL,
	'nuclides.measurementUnits.gbq'
);

export function getCyclotronProductMeasurementUnitDefinition(measurementUnit: NuclideModelActivityUnit | string):
NuclideMeasurementUnitDefinition {
	const cyclotronProductMeasurementUnitDefinition: Optional<NuclideMeasurementUnitDefinition> =
	getCyclotronProductMeasurementUnitDefinitions().find((definition) => definition.getMeasurementUnit() === measurementUnit) ?? null;
	if (NuclideMeasurementUnitDefinition === null) {
		throw new Error('Missing measurement unit definition: ' + measurementUnit);
	}
	return cyclotronProductMeasurementUnitDefinition;
}

export function getGeneratorMeasurementUnitDefinition(measurementUnit: NuclideModelActivityUnit | string):
NuclideMeasurementUnitDefinition {
	const generatorMeasurementUnitDefinition: Optional<NuclideMeasurementUnitDefinition> =
	getGeneratorMeasurementUnitDefinitions().find((definition) => definition.getMeasurementUnit() === measurementUnit) ?? null;
	if (NuclideMeasurementUnitDefinition === null) {
		throw new Error('Missing measurement unit definition: ' + measurementUnit);
	}
	return generatorMeasurementUnitDefinition;
}

export function getEluateMeasurementUnitDefinition(measurementUnit: NuclideModelActivityUnit | string):
NuclideMeasurementUnitDefinition {
	const eluateMeasurementUnitDefinition: Optional<NuclideMeasurementUnitDefinition> =
	getEluateMeasurementUnitDefinitions().find((definition) => definition.getMeasurementUnit() === measurementUnit) ?? null;
	if (NuclideMeasurementUnitDefinition === null) {
		throw new Error('Missing measurement unit definition: ' + measurementUnit);
	}
	return eluateMeasurementUnitDefinition;
}

export function getCyclotronProductMeasurementUnitDefinitions(): ReadonlyArray<NuclideMeasurementUnitDefinition> {
	return [
		KILO_BEQUEREL,
		MEGA_BEQUEREL,
		GIGA_BEQUEREL
	];
}

export function getGeneratorMeasurementUnitDefinitions(): ReadonlyArray<NuclideMeasurementUnitDefinition> {
	return [
		MEGA_BEQUEREL,
		GIGA_BEQUEREL
	];
}

export function getEluateMeasurementUnitDefinitions(): ReadonlyArray<NuclideMeasurementUnitDefinition> {
	return [
		MEGA_BEQUEREL,
		GIGA_BEQUEREL
	];
}

export function getAllNuclideMeasurementUnits(): ReadonlyArray<NuclideModelActivityUnit> {
	return [
		NuclideModelActivityUnit.KILO_BEQUEREL,
		NuclideModelActivityUnit.MEGA_BEQUEREL,
		NuclideModelActivityUnit.GIGA_BEQUEREL,
	];
}
