import { DeviceTypeDefinition, getAllDeviceTypes, getDeviceTypeDefinition, getDeviceTypeDefinitions } from '../inventory/DeviceTypeDefinition';
import { getProductGroupDefinitions, ProductGroupDefinition } from '../inventory/ProductGroupDefinition';
import { ProductGroup } from '../inventory/ProductGroup';
import { getAllSequenceTypes, getSequenceTypeDefinition, getSequenceTypeDefinitions, SequenceTypeDefinition } from '../inventory/SequenceTypeDefinition';
import { contains } from './misc/Util';
import { DeviceType } from '../inventory/DeviceType';
import { SequenceType } from '../inventory/SequenceType';

export class Inventory {
	private readonly productGroups: ReadonlyArray<ProductGroup>;

	private readonly deviceTypes: ReadonlyArray<DeviceType>;

	private readonly sequenceTypes: ReadonlyArray<SequenceType>;

	constructor(productGroups: ReadonlyArray<ProductGroup>, testMode: boolean) {
		this.productGroups = [...productGroups];
		this.deviceTypes = getAllDeviceTypes().filter((deviceType) => this.isInventoriedDeviceType(deviceType));
		this.sequenceTypes = getAllSequenceTypes()
			.filter((sequenceType) => testMode || !getSequenceTypeDefinition(sequenceType).isDemonstration())
			.filter((sequenceType) => this.isInventoriedSequenceType(sequenceType));
	}

	private isInventoriedDeviceType(deviceType: DeviceType): boolean {
		return getDeviceTypeDefinition(deviceType)
			.getProductGroups()
			.some((productGroup) => contains(this.productGroups, productGroup));
	}

	private isInventoriedSequenceType(sequenceType: SequenceType): boolean {
		return getSequenceTypeDefinition(sequenceType)
			.getProductGroups()
			.some((productGroup) => contains(this.productGroups, productGroup));
	}

	public getProductGroups(): ReadonlyArray<ProductGroup> {
		return this.productGroups;
	}

	public getProductGroupDefinitions(): ReadonlyArray<ProductGroupDefinition> {
		return getProductGroupDefinitions(this.productGroups);
	}

	public getDeviceTypes(): ReadonlyArray<DeviceType> {
		return this.deviceTypes;
	}

	public getDeviceTypeDefinitions(): ReadonlyArray<DeviceTypeDefinition> {
		return getDeviceTypeDefinitions(this.deviceTypes);
	}

	public getSequenceTypes(deviceType: DeviceType): ReadonlyArray<SequenceType> {
		return this.getSequenceTypeDefinitions(getDeviceTypeDefinition(deviceType)).map((sequenceTypeDefinition) => sequenceTypeDefinition.getSequenceType());
	}

	public getSequenceTypeDefinitions(deviceTypeDefinition: DeviceTypeDefinition): ReadonlyArray<SequenceTypeDefinition> {
		const deviceType = deviceTypeDefinition.getDeviceType();
		return getSequenceTypeDefinitions(this.sequenceTypes).filter((sequenceTypeDefinition) => contains(sequenceTypeDefinition.getDeviceTypes(), deviceType));
	}
}
