import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { useContext } from 'react';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';
import { deleteEluate } from 'services/nuclide/store/eluateSlice';
import { EluateDeleteForm } from '../eluate-delete-form/EluateDeleteForm';
import { EluateDeleteFailedNotification } from './EluateDeleteFailedNotification';


export interface EluateDeleteModalProps {
	eluate: EluateViewModel
	onDismiss?: () => void;
}

export const EluateDeleteModal = (props: EluateDeleteModalProps): JSX.Element => {
	const { eluate, onDismiss } = props;

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const eluateActionStatus = useTypedSelector(state => state.eluates.actionStatus);

	const dispatch = useDispatch();

	const handleDelete = (): void => {
		eluate.Client = clientContext.selectedClientUuid;
		eluate.Facility = facilityContext.selectedFacilityUuid;
		dispatch(deleteEluate(eluate));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (eluateActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<EluateDeleteForm
						eluate={eluate}
						onDelete={handleDelete}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <EluateDeleteFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
