import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

export interface ContainerDisposePersistenceModel extends PersistenceModel {
	Client: string,
	Facility: string,
	Container: string,
	DisposedDatetime: number,
	DisposedBy: string,
	DisposedByName: string
}

export interface ContainerDisposeStoreModel extends StoreModel {
	Client: string,
	Facility: string,
	Container: string,
	DisposedDatetime: number,
	DisposedBy: string,
	DisposedByName: string
}

export interface ContainerDisposeViewModel extends ViewModel {
	Client: string,
	Facility: string,
	Container: string,
	DisposedDatetime: Date,
	DisposedBy: string,
	DisposedByName: string
}

export interface CreatableContainerDisposeViewModel extends CreatableViewModel {
	Client: string,
	Facility: string,
	Container: string,
	DisposedDatetime: Date,
	DisposedBy: string,
	DisposedByName: string
}

export class ContainerDisposeModelConverter
	extends ModelConverter<ContainerDisposePersistenceModel, ContainerDisposeStoreModel, ContainerDisposeViewModel, CreatableContainerDisposeViewModel> {

	fromStoreModel(storeModel?: ContainerDisposeStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			model.DisposedDatetime = Math.floor(sModel.DisposedDatetime / 1000);
		});
	}

	fromViewModel(viewModel?: ContainerDisposeViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.DisposedDatetime = Math.floor(vModel.DisposedDatetime.getTime() / 1000);
		});
	}

	toViewModel(): ContainerDisposeViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.DisposedDatetime = new Date(model.DisposedDatetime * 1000);
		});
	}

	toStoreModel(): ContainerDisposeStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.DisposedDatetime = model.DisposedDatetime * 1000;
		});
	}

}
