import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';
import { DeviceViewModalType } from 'services/device/presentation/view/DeviceViewModalType';
import { User } from 'services/device/presentation/view/UserModel';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

interface DeviceActionButtonsProps {
	device: DeviceViewModel;
	user: User;
	onClick: (modalType: DeviceViewModalType) => void;
}

export const DeviceActionButtons = (props: DeviceActionButtonsProps): JSX.Element => {
	const { device, user, onClick } = props;

	const l10nContext = useContext(L10nContext);

	const archiveButton =
		<ButtonSecondary
			buttonText={
				device.State === DeviceModelState.ACTIVE ?
					l10nContext.translate('common.button.archive', 'Archivieren') :
					l10nContext.translate('common.button.unArchive', 'Archivierung aufheben')
			}
			icon={IconIdentifier.ARCHIVE}
			onClick={() => {
				device.State === DeviceModelState.ACTIVE ?
					onClick(DeviceViewModalType.ARCHIVE) :
					onClick(DeviceViewModalType.UNARCHIVE);
			}}
			disabled={!user.permission.archive}
		/>;

	const deleteButton =
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.delete', 'Löschen')}
			icon={IconIdentifier.TRASH}
			status={ButtonSecondaryStatus.ATTENTION}
			disabled={!user.permission.delete}
			onClick={() => onClick(DeviceViewModalType.DELETE)}
		/>;

	return (
		<ViewLayoutSection>
			<ColumnLayout>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
					{archiveButton}
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					{deleteButton}
				</ColumnLayoutSection>
			</ColumnLayout>
		</ViewLayoutSection>
	);
};
