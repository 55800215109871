import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';

import {
	CyclotronProductModelConverter as ModelConverter,
	CyclotronProductPersistenceModel as PersistenceModel,
	CyclotronProductStoreModel as StoreModel,
	CyclotronProductViewModel as ViewModel,
	CreatableCyclotronProductViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/CyclotronProductModel';
import { CyclotronProductHttpPersistence as HttpPersistence } from 'services/nuclide/persistence/CyclotronProductHttpPersistence';

export class CyclotronProductPersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {

	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

}
