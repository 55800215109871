import { Timeout } from 'lib/timeout/Timeout';

import { JsonWebToken } from 'services/core/lib/auth/AuthService';
import { PropellerError } from '../../../../../lib/persistence/http/error/PropellerError';
import { ExternalApiConnector, ExternalAuthenticationConnectorResult } from './ExternalApiConnector';

interface AuthenticationPropellerResponse {
	readonly JsonWebToken?: JsonWebToken;
}

interface AuthenticationTestPropellerResponse {
	readonly Result?: string;
}

interface AuthenticationFileDownloadTokenPropellerResponse {
	FileDownloadToken?: string;
}

export class ExternalHttpApiConnector implements ExternalApiConnector {

	/* eslint-disable prefer-template */
	private static authEndpoint: string = process.env.REACT_APP_API_BASE_URL + 'report/report-api/v1/authenticate/report/';
	private static testEndpoint: string = process.env.REACT_APP_API_BASE_URL + 'report/report-api/v1/authenticate/test/';
	private static fileDownloadTokenEndpoint: string = process.env.REACT_APP_API_BASE_URL + '/report/report-api/v1/authenticate/file-download-token/';

	/* eslint-enable prefer-template */

	public async authenticate(reportUuid: string, password: string): Promise<ExternalAuthenticationConnectorResult> {

		const request = new Request(
			ExternalHttpApiConnector.authEndpoint,
			{
				method: 'put',
				cache: 'no-cache',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					ReportUuid: reportUuid,
					Password: password
				})
			}
		);

		const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error());
		const parsedBody = await response.json() as AuthenticationPropellerResponse;
		let jsonWebToken = parsedBody?.JsonWebToken ?? null as JsonWebToken;
		if (jsonWebToken !== null) {
			jsonWebToken = {
				...jsonWebToken,
				ValidUntil: jsonWebToken.ValidUntil * 1000
			};
		}

		return {
			JsonWebToken: jsonWebToken
		} as ExternalAuthenticationConnectorResult;
	}

	public async test(reportUuid:string, jsonWebToken?: JsonWebToken): Promise<boolean> {
		jsonWebToken = jsonWebToken ?? null;
		if (jsonWebToken === null) {
			return false;
		}
		const request = new Request(
			ExternalHttpApiConnector.testEndpoint.replace('{REPORT_UUID}', reportUuid),
			{
				method: 'get',
				cache: 'no-cache',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + jsonWebToken?.Token
				}
			}
		);

		let parsedBody: AuthenticationTestPropellerResponse;
		try {
			const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error());
			parsedBody = await response.json() as AuthenticationTestPropellerResponse;
		} catch (e) {
			return false;
		}

		return parsedBody?.Result === 'ok';
	}

	public async fileDownloadToken(jsonWebToken?: JsonWebToken): Promise<string> {
		jsonWebToken = jsonWebToken ?? null;
		if (jsonWebToken === null) {
			throw new PropellerError('JSON_WEB_TOKEN_MISSING', '401');
		}
		const request = new Request(
			ExternalHttpApiConnector.fileDownloadTokenEndpoint,
			{
				method: 'get',
				cache: 'no-cache',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + jsonWebToken?.Token
				}
			}
		);

		let parsedBody: AuthenticationFileDownloadTokenPropellerResponse;
		try {
			const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error());
			parsedBody = await response.json() as AuthenticationFileDownloadTokenPropellerResponse;
		} catch (e) {
			throw new PropellerError('FILE_DOWNLOAD_TOKEN_RESPONSE_INVALID');
		}

		return parsedBody?.FileDownloadToken;
	}

}
