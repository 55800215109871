import { Valuation } from '../Valuation';
import { DeviceType } from '../../inventory/DeviceType';
import { SequenceType } from '../../inventory/SequenceType';
import { Output } from '../Output';
import { RecordFieldSet } from '../field-set/RecordFieldSet';
import { RecordStoreData, RecordViewData } from './RecordData';
import { FieldDescription } from '../description/FieldDescription';
import { Optional } from '../misc/Optional';

export class Record {
	private readonly deviceType: DeviceType;

	private readonly sequenceType: SequenceType;

	private readonly recordFieldSet: RecordFieldSet<any>;

	private readonly valuation: Valuation;

	constructor(deviceType: DeviceType, sequenceType: SequenceType, recordFieldSet: RecordFieldSet<any>, valuation: Valuation) {
		this.deviceType = deviceType;
		this.sequenceType = sequenceType;
		this.recordFieldSet = recordFieldSet;
		this.valuation = valuation;
	}

	public getDeviceType(): DeviceType {
		return this.deviceType;
	}

	public getSequenceType(): SequenceType {
		return this.sequenceType;
	}

	public getOutput(description: FieldDescription): Optional<Output<any>> {
		return this.recordFieldSet.getOutput(description.name);
	}

	public getFieldSet(): RecordFieldSet<any> {
		return this.recordFieldSet.clone();
	}

	public toValuation(): Valuation {
		return this.valuation;
	}

	public toStoreData(): RecordStoreData {
		return this.recordFieldSet.toStoreData(true);
	}

	public toViewData(): RecordViewData {
		return this.recordFieldSet.toViewData(this.deviceType, this.sequenceType, true);
	}
}
