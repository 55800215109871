import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { BooleanInput } from 'services/device/domain/business/common/Input';

import { Label } from 'presentation/ui/partials/input/label/Label';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';

export interface BooleanInputProps {
	input: BooleanInput;
}

// TODO: Replace with a radio group input
export const BooleanInputRenderer = (props: BooleanInputProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const { input } = props;

	let label = l10nContext.translate(input.getLabelKey());
	if (input.getDescription()?.mandatory === true) {
		label += '*';
	}

	const options = [
		{
			label: l10nContext.translate('common.inputs.boolean.true'),
			value: 'true',
			checked: input.getSingle() === true
		},
		{
			label: l10nContext.translate('common.inputs.boolean.false'),
			value: 'false',
			checked: input.getSingle() !== true
		}
	] as Array<SelectOption>;

	input.setSingle(input.getSingle() === true);

	return (
		<Label label={label}>
			<Select
				options={options}
				onchange={(selectedValue) => {
					input.setSingle(selectedValue === 'true');
				}}
			/>
		</Label>
	);

};
