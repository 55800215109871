import { L10nContext } from 'context/L10nContext';
import { ButtonToggleSet } from 'presentation/ui/components/button/ButtonToggleSet';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { useContext, useState } from 'react';
import { useTypedSelector } from '../../../../store/common/TypedSelector';
import { ClientContext } from '../../../core/context/ClientContext';
import { FacilityContext } from '../../../core/context/FacilityContext';
import { selectDecayContainers } from '../../store/containerSlice';
import { DispatchedContainersView } from './DispatchedContainersView';
import { OpenContainersView } from './OpenContainersView';
import { DecayContainersView } from './DecayContainersView';
import { ApprovedContainersView } from './ApprovedContainersView';
import { SingleNuclidesView } from './SingleNuclidesView';
import { SingleNuclidesViewProvider } from './SingleNuclidesViewProvider';

/**
 * The waste management view
 */

export const WasteManagementView = (): JSX.Element => {

	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const [toggleState, setToggleState] = useState<string>('1');

	const decayContainers = useTypedSelector(selectDecayContainers(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	let reachedReleaseDateCount = 0;
	decayContainers.forEach((container) => {
		if (l10nContext.formatDate(container.ReleaseDateTime) < l10nContext.formatDate(new Date())) {
			reachedReleaseDateCount += 1;
		}
		return reachedReleaseDateCount;
	});

	const showTabContent = (): JSX.Element => {

		let tabContent: JSX.Element;

		switch (toggleState) {
			case '1':
				tabContent = <SingleNuclidesViewProvider><SingleNuclidesView /></SingleNuclidesViewProvider>;
				break;
			case '2':
				tabContent =  <OpenContainersView />;
				break;
			case '3':
				tabContent =  <DecayContainersView />;
				break;
			case '4':
				tabContent =  <ApprovedContainersView />;
				break;
			case '5':
				tabContent =  <DispatchedContainersView />;
				break;
			default:
				tabContent =   <SingleNuclidesView />;
				break;
		}
		return tabContent;
	};

	return (
		<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
			<ViewLayout>
				<ViewLayoutSection>
					<div style={{ marginTop: '40px', marginBottom: '40px' }}>
						<ButtonToggleSet
							onClick={(id) => {
								setToggleState(id);
							}}
							buttons={[
								{
									id: '1',
									buttonText: l10nContext.translate('view.wastemanagement.toggleSet.singleNuclides', 'Nuklide'),
									isActive: toggleState === '1',

								},
								{
									id: '2',
									buttonText: l10nContext.translate('view.wastemanagement.toggleSet.openContainers', 'Offene Gebinde'),
									isActive: toggleState === '2'
								},
								{
									id: '3',
									buttonText: l10nContext.translate('view.wastemanagement.toggleSet.decayingContainers', 'Abklingende Gebinde'),
									isActive: toggleState === '3',
									badge: reachedReleaseDateCount
								},
								{
									id: '4',
									buttonText: l10nContext.translate('view.wastemanagement.toggleSet.approvedContainers', 'Freigegebene Gebinde'),
									isActive: toggleState === '4'
								},
								{
									id: '5',
									buttonText: l10nContext.translate('view.wastemanagement.toggleSet.dispatchedDiscardedContainers', 'Entsorgte/abgegangene Gebinde'),
									isActive: toggleState === '5'
								}

							]}
							deSelectAll={false}
						/>

					</div>

					{showTabContent()}
				</ViewLayoutSection>
			</ViewLayout>
		</MainLayoutSection>
	);
};
