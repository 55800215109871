import { FunctionComponent } from 'react';

import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './site-title.scss';

export type SiteTitleButton = {
	icon: IconIdentifier,
	buttonText: string,
	disabled: boolean,
	onClick: (title: string) => void;
};

export interface SiteTitleProps {
	/**
	 * Site Tille
	 */
	title: string;
	/**
	 * (Optional) Button props
	 */
	button?: SiteTitleButton;
	/**
	 * (Optional) Background color
	 */
	isTransparent?: boolean
}

/**
 * Site Title Component
 */
export const SiteTitle: FunctionComponent<SiteTitleProps> = (props): JSX.Element => {
	const { title, button, isTransparent, children } = props;

	const buttonEl = button ?
		<ButtonPrimary
			icon={button.icon}
			disabled={button.disabled}
			buttonText={button.buttonText}
			onClick={() => button.onClick(title)}
		/> :
		null;

	const siteTitleClassName = `site-title${isTransparent ? ' site-title--is-transparent' : ''}`;

	return (
		<div className={siteTitleClassName}>
			<div className='site-title__header'>
				<span className='site-title__title'>{title}</span>
				{buttonEl}
			</div>
			{children}
		</div>
	);
};
