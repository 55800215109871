/**
 * The order directive declaration to define model collection sorting
 */
export enum ModelQueryOrderDirection {
	ASC = 'ASC',
	DESC = 'DESC'
}

export enum ModelQueryOrderSortAlgo {
	ALPHANUMERIC = 'ALPHANUMERIC',
	NUMERIC = 'NUMERIC'
}

export interface ModelQueryOrderDirectiveInterface<PersistenceModel> {
	order(left: PersistenceModel, right: PersistenceModel): number;
}

export class ModelQueryOrderDirective<PersistenceModel extends Record<string, any>> implements ModelQueryOrderDirectiveInterface<PersistenceModel> {

	private readonly key: string;
	private readonly direction: ModelQueryOrderDirection;
	private readonly directionLeftResult: number = 1;
	private readonly directionRightResult: number = -1;
	private readonly sortAlgo: ModelQueryOrderSortAlgo;

	constructor(
		key: string,
		direction: ModelQueryOrderDirection = ModelQueryOrderDirection.ASC,
		sortAlgo: ModelQueryOrderSortAlgo = ModelQueryOrderSortAlgo.ALPHANUMERIC
	) {
		this.key = key;
		this.direction = direction;
		this.sortAlgo = sortAlgo;
		if (direction === ModelQueryOrderDirection.DESC) {
			this.directionLeftResult = -1;
			this.directionRightResult = 1;
		}
	}

	public getKey(): string {
		return this.key;
	}

	public getDirection(): ModelQueryOrderDirection {
		return this.direction;
	}

	public order(left: PersistenceModel, right: PersistenceModel): number {
		if (
			!Object.prototype.hasOwnProperty.call(left, this.key)
			|| !Object.prototype.hasOwnProperty.call(right, this.key)
		) {
			throw new Error('Invalid order key ' + this.key);
		}

		let leftValue: string | number = left[this.key];
		let rightValue: string | number = right[this.key];

		switch (this.sortAlgo) {
			case ModelQueryOrderSortAlgo.NUMERIC:
				leftValue = parseFloat(leftValue as string);
				rightValue = parseFloat(rightValue as string);
				break;
			default:
				leftValue = String(leftValue);
				rightValue = String(rightValue);
				break;
		}

		if (leftValue > rightValue) {
			return this.directionLeftResult;
		}
		if (leftValue < rightValue) {
			return this.directionRightResult;
		}
		return 0;
	}

}
