import { UnitFieldDescription } from 'services/device/domain/business/common/description/FieldDescription';
import { Sequence } from 'services/device/domain/business/common/sequence/Sequence';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { Unit } from 'services/device/domain/business/inventory/Unit';
import { getUnitDefinition } from 'services/device/domain/business/inventory/UnitDefinition';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

export class UnitUtil {

	private sequenceViewModel: SequenceViewModel;
	private sequence: Sequence;

	constructor(sequenceViewModel: SequenceViewModel) {
		this.sequenceViewModel = sequenceViewModel;
		const sequenceTypeDfinition = getSequenceTypeDefinition(sequenceViewModel.Type);
		this.sequence = sequenceTypeDfinition.restoreSequenceFromViewData(sequenceViewModel.SequenceConfiguration);
	}

	public getUnitFromFieldDescription(unitFieldDescription?: UnitFieldDescription): Unit | null {
		if ((unitFieldDescription ?? null) === null) {
			return null;
		}
		return this.sequence.getOutput(unitFieldDescription).getSingle() ?? null;
	}

	public getDisplayUnitFromFieldDescription(unitFieldDescription?: UnitFieldDescription): string {
		const unit = this.getUnitFromFieldDescription(unitFieldDescription);
		if (unit === null) {
			return '';
		}
		return getUnitDefinition(unit).getSymbol() ?? '';
	}

}
