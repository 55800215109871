import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateReport } from 'services/report/store/reportSlice';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';

import { ReportViewModel } from 'services/report/domain/model/ReportModel';
import { ReportUpdateFormStep1 } from './ReportUpdateFormStep1';
import { ReportUpdateFormStep2 } from './ReportUpdateFormStep2';

export interface ReportUpdateFormProps {
	report?: ReportViewModel;
}

export const ReportUpdateForm = (props: ReportUpdateFormProps): JSX.Element => {
	const { report } = props;

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const [showStep2, setShowStep2] = useState<boolean>(false);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const dispatch = useDispatch();

	const updatedReport: ReportViewModel = report;
	const requiredInputs = ['Name', 'PeriodDateStart', 'PeriodDateEnd', 'ValidUntil'];

	const handleUpdate = (): void => {
		dispatch(updateReport({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			viewModel: updatedReport
		}));
	};

	const onChangeReportProp = (prop: string, value: string | Date) => {
		updatedReport[prop] = value;
	};

	const gotoStep1 = () => {
		setShowStep2(false);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newReportModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedReport?.[requiredInput] ?? null) === null
				|| updatedReport?.[requiredInput] === ''
				|| String(updatedReport.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newReportModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep2(newReportModelValid);
	};

	const formStep = !showStep2 ?
		<ReportUpdateFormStep1
			report={updatedReport}
			onClickNext={goToStep2}
			onChangeReportProp={onChangeReportProp}
			invalidInputs={invalidInputs}
		/> :
		<ReportUpdateFormStep2
			report={updatedReport}
			onClickBack={gotoStep1}
			onClickNext={handleUpdate}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
