import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';
import { selectCyclotronProducts } from '../../../../../nuclide/store/cyclotronProductSlice';

import { WasteManagementReportViewModel } from '../../../../domain/models/WasteManagementReportModel';
import { updateWasteManagementReport } from '../../../../store/wasteManagementReportSlice';
import { WasteManagementCyclotronProductReportUpdateFormStep1 } from './WasteManagementCyclotronProductReportUpdateFormStep1';
import { WasteManagementCyclotronProductReportUpdateFormStep2 } from './WasteManagementCyclotronProductReportUpdateFormStep2';

export interface WasteManagementCyclotronProductReportUpdateFormProps {
	report?: WasteManagementReportViewModel;
}

export const WasteManagementCyclotronProductReportUpdateForm = (props: WasteManagementCyclotronProductReportUpdateFormProps): JSX.Element => {
	const { report } = props;

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const [showStep2, setShowStep2] = useState<boolean>(false);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const dispatch = useDispatch();

	const requiredInputs = ['Name', 'PeriodDateStart', 'PeriodDateEnd', 'ValidUntil'];

	const updatedReport: WasteManagementReportViewModel = report;

	const cyclotronProducts = useTypedSelector(selectCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const onChangeReportProp = (prop: string, value: any) => {
		updatedReport[prop] = value;
	};

	const reportPeriodDateStartFormatted = new Date(updatedReport.PeriodDateStart);
	const reportPeriodDateEndFormatted = new Date(updatedReport.PeriodDateEnd);

	const filteredCyclotronProductsUuids: string[] = [];
	if (cyclotronProducts.length > 0) {
		cyclotronProducts.filter((cyclotronProduct) => {
			const cyclotronProductDeliveryDateFormatted = new Date(cyclotronProduct?.DeliveryDate);
			if (reportPeriodDateStartFormatted < cyclotronProductDeliveryDateFormatted &&
				reportPeriodDateEndFormatted > cyclotronProductDeliveryDateFormatted) {
				filteredCyclotronProductsUuids.push(cyclotronProduct.Uuid);
			}
			return filteredCyclotronProductsUuids;
		});
	}

	const handleUpdate = (): void => {
		dispatch(updateWasteManagementReport({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			viewModel: {
				...updatedReport,
				WasteManagementReportCyclotronProducts: filteredCyclotronProductsUuids
			}
		}));
	};

	const gotoStep1 = () => {
		setShowStep2(false);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newReportModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedReport?.[requiredInput] ?? null) === null
				|| updatedReport?.[requiredInput] === ''
				|| String(updatedReport.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newReportModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep2(newReportModelValid);
	};

	const formStep = !showStep2 ?
		<WasteManagementCyclotronProductReportUpdateFormStep1
			report={updatedReport}
			onClickNext={goToStep2}
			onChangeReportProp={onChangeReportProp}
			invalidInputs={invalidInputs}
		/> :
		<WasteManagementCyclotronProductReportUpdateFormStep2
			report={updatedReport}
			onClickBack={gotoStep1}
			onClickNext={handleUpdate}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
