import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { DatePicker } from 'presentation/ui/partials/input/date-picker/DatePicker';
import { DateTimePicker } from 'presentation/ui/compositions/date-time-picker/DateTimePicker';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';
import { CyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';
import { getCyclotronProductIsotopeDefinitions } from 'services/nuclide/domain/model/isotopes/NuclideIsotopeDefinition';
import { getCyclotronProductMeasurementUnitDefinitions } from 'services/nuclide/domain/model/NuclideMeasurementUnitDefinition';

export interface CyclotronProductUpdateModalStep1Props {
	/**
	 * Cyclotron product
	 */
	cyclotronProduct: CyclotronProductViewModel;
	/**
	 * Button next action
	 */
	onClickNext: () => void;
	/**
	 * Invalid inputs
	 */
	invalidInputs?: string[];
	/**
	 * Change cyclotron prosuct props
	 */
	onChangeCyclotronProductProp: (prop: string, value: string | Date | number) => void;
}

/**
 * The cyclotron products update modal step 1
 */
export const CyclotronProductUpdateFormStep1 = (props: CyclotronProductUpdateModalStep1Props): JSX.Element => {
	const { cyclotronProduct, invalidInputs = [], onChangeCyclotronProductProp, onClickNext } = props;

	// Consume the contexts
	const l10nContext = useContext(L10nContext);

	// Notification for invalid input fields
	const checkPlural = invalidInputs.length > 1 ? l10nContext.translate('common.modal.notificationErrorPlural', ' dürfen nicht leer sein') : l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein');
	let notification = null;
	if (invalidInputs.length > 0) {
		const invalidNotificationMessage = invalidInputs.join(l10nContext.translate('common.text.and', ' und '))
			+ checkPlural;
		notification = (
			<NotificationBar
				level={NotificationLevel.LEVEL_ERROR}
				message={invalidNotificationMessage}
			/>
		);
	}

	const isotopeDefinitions = getCyclotronProductIsotopeDefinitions();
	const isotopes = isotopeDefinitions.map((isotopeDefinition): SelectOption => {

		return {
			label: l10nContext.translate(isotopeDefinition.getLabelKey()),
			value: isotopeDefinition.getIsotope(),
			checked: isotopeDefinition.getIsotope().toString() === cyclotronProduct?.Isotope ?? false,
		};
	});

	const cyclotronProductMeasurementUnitDefinitions = getCyclotronProductMeasurementUnitDefinitions();
	const cyclotronProductMeasurementUnits = cyclotronProductMeasurementUnitDefinitions.map((cyclotronProductMeasurementUnitDefinition): SelectOption => {

		return {
			label: l10nContext.translate(cyclotronProductMeasurementUnitDefinition.getLabelKey()),
			value: cyclotronProductMeasurementUnitDefinition.getMeasurementUnit(),
			checked: cyclotronProductMeasurementUnitDefinition.getMeasurementUnit() === cyclotronProduct?.ActivityUnit ?? false,
		};
	});

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<NotificationBars>
				{notification}
			</NotificationBars>
			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.name', 'Name')}*`}
				>
					<InputText
						type="text"
						defaultValue={cyclotronProduct?.Name ?? ''}
						onChange={(event) => onChangeCyclotronProductProp('Name', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.manufacturer', 'Hersteller')}*`}
				>
					<InputText
						type="text"
						defaultValue={cyclotronProduct?.Manufacturer ?? ''}
						onChange={(event) => onChangeCyclotronProductProp('Manufacturer', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.batchNumber', 'Chargennummer')}*`}
				>
					<InputText
						type="text"
						defaultValue={cyclotronProduct?.BatchNumber ?? ''}
						onChange={(event) => onChangeCyclotronProductProp('BatchNumber', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.isotope', 'Isotop')}*`}
				>
					<Select
						options={isotopes}
						onchange={(optionValue: string) => onChangeCyclotronProductProp('Isotope', optionValue)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.radiopharmaceutical', 'Radiopharmakon')}*`}
				>
					<InputText
						type="text"
						defaultValue={cyclotronProduct?.Radiopharmaceutical ?? ''}
						onChange={(event) => onChangeCyclotronProductProp('Radiopharmaceutical', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('cyclotronProducts.deliveryDate', 'Lieferdatum')}*`}>
					<DatePicker
						defaultValue={(cyclotronProduct?.DeliveryDate) ?? new Date()}
						onChange={(event) => onChangeCyclotronProductProp('DeliveryDate', event.target.valueAsDate)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.nominalActivity', 'Nominalaktivität')}*`}
				>
					<InputText
						type='number'
						defaultValue={cyclotronProduct?.NominalActivity ?? ''}
						onChange={(event) => onChangeCyclotronProductProp('NominalActivity', Number(event.target.value))}
						step='any'
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.activityUnit', 'Einheit')}*`}
				>
					<Select
						options={cyclotronProductMeasurementUnits}
						onchange={(optionValue: string) => onChangeCyclotronProductProp('ActivityUnit', optionValue)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('cyclotronProducts.calibrationDateTime', 'Kalibrierzeitpunkt')}*`}>
					<DateTimePicker
						name='calibrationDatetime'
						defaultValue={cyclotronProduct?.CalibrationDatetime ?? new Date()}
						onChange={(event) => onChangeCyclotronProductProp('CalibrationDatetime', event)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
