import { MailAddressOutput } from 'services/device/domain/business/common/Output';

export interface MailAddressesOutputValueProps {
	output: MailAddressOutput;
}

export const MailAddressesOutputValueRenderer = (props: MailAddressesOutputValueProps): JSX.Element => {
	const { output } = props;

	return (
		<span>
			{output.get()?.join(', ') ?? ''}
		</span>
	);

};
