import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';

import {
	DeviceModelConverter as ModelConverter,
	DevicePersistenceModel as PersistenceModel,
	DeviceStoreModel as StoreModel,
	DeviceViewModel as ViewModel,
	CreatableDeviceViewModel as CreatableViewModel
} from 'services/device/domain/model/DeviceModel';
import { DeviceHttpPersistence as HttpPersistence } from 'services/device/persistence/DeviceHttpPersistence';

export class DevicePersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {

	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

}
