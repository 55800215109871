import { FunctionComponent } from 'react';

import './card-sequence-detail.scss';

/**
 * The sequence detail card component
 */
export const CardSequenceDetail: FunctionComponent = (props): JSX.Element => {
	const { children } = props;

	return (
		<div className='card-sequence-detail'>
			<div className='card-sequence-detail__content'>
				{children}
			</div>
		</div>
	);
};
