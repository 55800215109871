import './logo-header.scss';

/**
 * Logo header partial
 */
export const LogoHeader = (): JSX.Element => {

	return (
		<div className='logo-header' />
	);
};
