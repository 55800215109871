import React, { RefObject, useContext, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { CheckboxesLayout } from '../../../../../../presentation/ui/layouts/checkboxes-layout/CheckboxesLayout';
import { Radiobutton } from '../../../../../../presentation/ui/partials/input/radiobutton/Radiobutton';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';
import { ClientContext } from '../../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../../core/context/FacilityContext';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { selectOpenContainers } from '../../../../store/containerSlice';

export interface ContainerNuclidesAssignModalStep1Props {

	updatedContainer: RefObject<ContainerViewModel>,
	onClickNext: () => void;
	onChangeContainerProps?: (value: any) => void;
}

export const ContainerNuclidesAssignFormStep1 = (props: ContainerNuclidesAssignModalStep1Props): JSX.Element => {
	const { updatedContainer, onChangeContainerProps, onClickNext } = props;

	// Consume the contexts
	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const [proceedable, setProceedable] = useState<boolean>((updatedContainer.current?.Uuid ?? null) !== null);

	const openContainers = useTypedSelector(selectOpenContainers(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const chooseContainer = openContainers.map((container) => {
		return (
			<Radiobutton
				key={container.Uuid}
				labelName={container.Name}
				id={container.Uuid}
				defaultChecked={updatedContainer.current.Uuid === container.Uuid}
				name='containerNuclidesAssignment'
				value={container?.Uuid ?? ''}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>  {
					const choosedContainer = openContainers.find((containerModel) => {return containerModel.Uuid === event.target.value;});
					onChangeContainerProps(choosedContainer);
					setProceedable(choosedContainer !== null);
				}}
			/>
		);
	});

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('view.singleNuclides.modal.assignNuclidesToContainers.selectOpenContainer', 'Offenes Gebinde auswählen')}*`}
				>
					<CheckboxesLayout>
						{chooseContainer}
					</CheckboxesLayout>

				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
							disabled={!proceedable}
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
