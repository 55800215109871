import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogEntryModelState } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModelState';
import { updateMaintenanceLogEntry } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { MaintenanceLogResolveFormStep1 } from './MaintenanceLogResolveFormStep1';
import { MaintenanceLogResolveFormStep2 } from './MaintenanceLogResolveFormStep2';

export interface MaintenanceLogModalCommentFormProps {
	maintenanceLogEntry: MaintenanceLogEntryViewModel,
}

export const MaintenanceLogResolveForm = (props: MaintenanceLogModalCommentFormProps): JSX.Element => {
	const { maintenanceLogEntry } = props;

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const authContext = useContext(AuthContext);

	const dispatch = useDispatch();

	const [modalStep, setmodalStep] = useState<number>(1);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const requiredInputs = [''];

	const updatedMaintenance = useRef<MaintenanceLogEntryViewModel>({
		IncidentDateEnd: new Date()
	} as MaintenanceLogEntryViewModel);

	const handleUpdate = (): void => {
		updatedMaintenance.current.Client = clientContext.selectedClientUuid;
		updatedMaintenance.current.Facility = facilityContext.selectedFacilityUuid;
		updatedMaintenance.current.Device = maintenanceLogEntry.Device;
		updatedMaintenance.current.DeviceName = maintenanceLogEntry.DeviceName;
		updatedMaintenance.current.Reporter = maintenanceLogEntry.Reporter;
		updatedMaintenance.current.ReporterName = maintenanceLogEntry.ReporterName;
		updatedMaintenance.current.Solver = authContext.getActor().Uuid;
		updatedMaintenance.current.State = MaintenanceLogEntryModelState.SOLVED;
		updatedMaintenance.current.IncidentTitle = maintenanceLogEntry.IncidentTitle;
		updatedMaintenance.current.IncidentCause = maintenanceLogEntry.IncidentCause;
		updatedMaintenance.current.IncidentSolution = maintenanceLogEntry.IncidentSolution;
		updatedMaintenance.current.IncidentDateStart = maintenanceLogEntry.IncidentDateStart;
		updatedMaintenance.current.Uuid = maintenanceLogEntry.Uuid;

		dispatch(updateMaintenanceLogEntry(updatedMaintenance.current));
	};

	const onChangeMaintenanceLogEntryProp = (prop: string, value: string | Date) => {
		updatedMaintenance.current[prop] = value;
	};

	const previousStep = () => {
		setmodalStep(modalStep - 1);
		setInvalidInputs([]);
	};

	const nextStep = () => {
		const invalidInputFields = requiredInputs
			.map((requiredInput) => {
				return updatedMaintenance.current[requiredInput]?.trim() !== '' ?
					null :
					requiredInput;

			}).filter((invalidInput: string | null) => invalidInput !== null);

		invalidInputFields.length ?
			setInvalidInputs(invalidInputFields) :
			setmodalStep(modalStep + 1);

	};

	const formStep = () => {
		let currentStep: JSX.Element = null;

		switch (modalStep) {
			case 1:
				currentStep =
					<MaintenanceLogResolveFormStep1
						maintenanceLogEntry={updatedMaintenance.current}
						onClickNext={nextStep}
						onChange={onChangeMaintenanceLogEntryProp}
						invalidInputs={invalidInputs}
					/>;
				break;
			case 2:
				currentStep =
					<MaintenanceLogResolveFormStep2
						onClickPrevious={previousStep}
						onClickConfirm={handleUpdate}
						maintenanceLogEntry={updatedMaintenance.current}
					/>;
				break;
		}

		return currentStep;
	};

	return (
		<>
			{formStep()}
		</>
	);
};
