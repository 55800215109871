import './paragraph-element.scss';

export interface ParagraphElementProps {
	/**
	 * text
	 */
	text: string;
}

/**
 * A text component
 */
export const ParagraphElement = ({ text }: ParagraphElementProps): JSX.Element => (
	<span className='paragraph-element'>
		{text}
	</span>
);
