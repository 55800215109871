import { FunctionComponent } from 'react';

import 'presentation/ui/components/cards/card-report/card-report-list/card-report-list.scss';

export interface CardReportListProps {
	/**
	 * (Optional) Archived state
	 */
	archived?: boolean;
}

/**
 * The card device list card component
 */
export const CardReportList: FunctionComponent<CardReportListProps> = (props): JSX.Element => {
	const { archived, children } = props;

	const componentStyle = `card-report-list${archived ? ' card-report-list--archived' : ''}`;

	return (
		<div className={componentStyle}>
			<div className='card-report-list__content'>
				{children}
			</div>
		</div>
	);
};
