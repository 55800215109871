import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { SVGAsset } from 'presentation/ui/partials/SVGAsset';

import './icon.scss';

export enum IconColor {
	ACCENT_ERROR_20 = 'accent-error-20',
	ACCENT_ERROR_100 = 'accent-error-100',
	ACCENT_SUCCESS_110 = 'accent-success-110',
	PRIMARY_10 = 'primary-10',
	PRIMARY_30 = 'primary-30',
	PRIMARY_50 = 'primary-50',
	PRIMARY_100 = 'primary-100',
	PRIMARY_110 = 'primary-110',
	TEXT_50 = 'text-50',
	TEXT_100 = 'text-100',
	WHITE = 'white'
}
export interface IconProps extends React.ComponentPropsWithoutRef<'div'> {
	/**
	 * Name of the icon
	 */
	name: IconIdentifier;
	/**
	 * (Optional) Color of the icon
	 */
	color?: IconColor;
}

/**
 * The icon component
 */
export const Icon = (props: IconProps): JSX.Element => {
	const { name, color, className, ...restProps } = props;

	return (
		<div
			className={`icon ${color ?? ''} ${className ?? ''}`}
			{...restProps}
		>
			<SVGAsset name={name} />
		</div>
	);
};
