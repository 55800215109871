import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { DatePicker } from 'presentation/ui/partials/input/date-picker/DatePicker';


export interface WasteManagementGeneratorReportUpdateFormStep1Props {
	/**
	 * Report
	 */
	report: { [key: string]: any };
	/**
	 * Button next action
	 */
	onClickNext: () => void;
	/**
	 * Invalid inputs
	 */
	invalidInputs?: string[];
	/**
	 * Change report props
	 */
	onChangeReportProp: (prop: string, value: string | Date) => void;
}

/**
 * The report create modal step 2
 */
export const WasteManagementGeneratorReportUpdateFormStep1 = (props: WasteManagementGeneratorReportUpdateFormStep1Props): JSX.Element => {
	const { report, onClickNext, invalidInputs = [], onChangeReportProp } = props;

	const l10nContext = useContext(L10nContext);

	const notification = !invalidInputs.length ?
		null :
		<NotificationBar
			level={NotificationLevel.LEVEL_ERROR}
			message={`${invalidInputs.join(` ${l10nContext.translate('common.text.and', 'und')} `)} ${l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein')}`}
		/>;

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<NotificationBars>
				{notification}
			</NotificationBars>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('reports.name', 'Name')}*`}>
					<InputText
						type="text"
						defaultValue={report.Name ?? ''}
						onChange={(event) => onChangeReportProp('Name', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('reports.periodDateStart', 'Startdatum')}*`}>
					<DatePicker
						defaultValue={report?.PeriodDateStart ?? new Date()}
						onChange={(event) => onChangeReportProp('PeriodDateStart', event.target.valueAsDate)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('reports.periodDateEnd', 'Enddatum')}*`}>
					<DatePicker
						defaultValue={report?.PeriodDateEnd ?? new Date()}
						onChange={(event) => onChangeReportProp('PeriodDateEnd', event.target.valueAsDate)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('reports.validUntil', 'Gültig bis')}*`}>
					<DatePicker
						defaultValue={report?.ValidUntil ?? new Date()}
						onChange={(event) => onChangeReportProp('ValidUntil', event.target.valueAsDate)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('reports.password', 'Passwort')}
				>
					<InputText
						type="text"
						defaultValue=''
						onChange={(event) => onChangeReportProp('Password', event.target.value)}
						minLength={6}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
