/* eslint-disable max-classes-per-file */
import { Optional } from '../common/misc/Optional';
import { Unit } from './Unit';

export class UnitDefinition {

	private readonly unit: Unit;

	private readonly labelKey: string;

	private readonly symbol: string;

	private readonly deprecated: boolean;

	constructor(unit: Unit, labelKey: string, symbol: string, deprecated: boolean) {
		this.unit = unit;
		this.labelKey = labelKey;
		this.symbol = symbol;
		this.deprecated = deprecated;
	}

	public getUnit(): Unit {
		return this.unit;
	}

	public getLabelKey(): string {
		return this.labelKey;
	}

	public getSymbol(): string {
		return this.symbol;
	}

	public isDeprecated(): boolean {
		return this.deprecated;
	}

	public toString = (): string => {
		return this.unit.toString();
	}

}

export const BECQUEREL: UnitDefinition = new UnitDefinition(
	Unit.BECQUEREL,
	'inventory.unit.becquerel',
	'Bq',
	false
);

export const BECQUEREL_PER_SQUARE_CENTIMETER: UnitDefinition = new UnitDefinition(
	Unit.BECQUEREL_PER_SQUARE_CENTIMETER,
	'inventory.unit.becquerelPerSquareCentimeter',
	'Bq/cm²',
	false
);

export const CENTI_GRAY_PER_SQUARE_CENTIMETER: UnitDefinition = new UnitDefinition(
	Unit.CENTI_GRAY_PER_SQUARE_CENTIMETER,
	'inventory.unit.centiGrayPerSquareCentimeter',
	'cGy×cm²',
	false
);

export const GRAY_PER_SQUARE_CENTIMETER: UnitDefinition = new UnitDefinition(
	Unit.GRAY_PER_SQUARE_CENTIMETER,
	'inventory.unit.grayPerSquareCentimeter',
	'Gy×cm²',
	false
);

export const CENTIMETER: UnitDefinition = new UnitDefinition(
	Unit.CENTIMETER,
	'inventory.unit.centimeter',
	'cm',
	false
);

export const COUNT: UnitDefinition = new UnitDefinition(
	Unit.COUNT,
	'inventory.unit.count',
	'cts',
	false
);

export const ABSOLUTE_COUNT: UnitDefinition = new UnitDefinition(
	Unit.ABSOLUTE_COUNT,
	'inventory.unit.absoluteCount',
	'cts',
	false
);

export const ABSOLUTE_KILO_COUNT: UnitDefinition = new UnitDefinition(
	Unit.ABSOLUTE_KILO_COUNT,
	'inventory.unit.absoluteKiloCount',
	'kcts',
	false
);

export const COUNTS_PER_SECOND: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_SECOND,
	'inventory.unit.countsPerSecond',
	'cps',
	false
);

export const COUNTS_PER_10_SECONDS: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_10_SECONDS,
	'inventory.unit.countsPer10Seconds',
	'cts/10sec',
	false
);

export const COUNTS_PER_25_SECONDS: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_25_SECONDS,
	'inventory.unit.countsPer25Seconds',
	'cts/25sec',
	true
);

export const COUNTS_PER_MINUTE: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_MINUTE,
	'inventory.unit.countsPerMinute',
	'cpm',
	false
);

export const COUNTS_PER_THREE_MINUTES: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_THREE_MINUTES,
	'inventory.unit.countsPerThreeMinutes',
	'cts/3min',
	true
);

export const COUNTS_PER_5_MINUTES: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_5_MINUTES,
	'inventory.unit.countsPer5Minutes',
	'cts/5min',
	true
);

export const COUNTS_PER_SECOND_PER_KILO_BECQUEREL: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_SECOND_PER_KILO_BECQUEREL,
	'inventory.unit.countsPerSecondPerKiloBecquerel',
	'cps/kBq',
	false
);

export const COUNTS_PER_SECOND_PER_MEGA_BECQUEREL: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_SECOND_PER_MEGA_BECQUEREL,
	'inventory.unit.countsPerSecondPerMegaBecquerel',
	'cps/MBq',
	false
);

export const COUNTS_PER_SECOND_PER_MEGA_BECQUEREL_PER_CUBIC_CENTIMETER: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_SECOND_PER_MEGA_BECQUEREL_PER_CUBIC_CENTIMETER,
	'inventory.unit.countsPerSecondPerMegaBecquerelPerCubicCentimeter',
	'cps/MBq×cm²',
	false
);

export const COUNTS_PER_MINUTE_PER_MICRO_CURIE: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_MINUTE_PER_MICRO_CURIE,
	'inventory.unit.countsPerMinutePerMicroCurie',
	'cpm/µCi',
	false
);

export const COUNTS_PER_MINUTE_PER_KILO_BECQUEREL: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_MINUTE_PER_KILO_BECQUEREL,
	'inventory.unit.countsPerMinutePerKiloBecquerel',
	'cpm/kBq',
	false
);

export const COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL: UnitDefinition = new UnitDefinition(
	Unit.COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL,
	'inventory.unit.countsPerMinutePerMegaBecquerel',
	'cpm/MBq',
	false
);

export const KILO_BECQUEREL: UnitDefinition = new UnitDefinition(
	Unit.KILO_BECQUEREL,
	'inventory.unit.kiloBecquerel',
	'kBq',
	false
);

export const KILO_ELECTRONS_VOLT: UnitDefinition = new UnitDefinition(
	Unit.KILO_ELECTRONS_VOLT,
	'inventory.unit.kiloElectronsVolt',
	'keV',
	false
);

export const KILO_COUNTS_PER_MINUTE: UnitDefinition = new UnitDefinition(
	Unit.KILO_COUNTS_PER_MINUTE,
	'inventory.unit.kiloCountsPerMinute',
	'kcpm',
	false
);

export const KILO_COUNTS_PER_THREE_MINUTES: UnitDefinition = new UnitDefinition(
	Unit.KILO_COUNTS_PER_THREE_MINUTES,
	'inventory.unit.kiloCountsPerThreeMinutes',
	'kcts/3min',
	true
);

export const KILO_COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL: UnitDefinition = new UnitDefinition(
	Unit.KILO_COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL,
	'inventory.unit.kiloCountsPerMinutePerMegaBecquerel',
	'kcpm/kBq',
	false
);

export const KILO_COUNTS_PER_SECOND: UnitDefinition = new UnitDefinition(
	Unit.KILO_COUNTS_PER_SECOND,
	'inventory.unit.kiloCountsPerSecond',
	'kcps',
	false
);

export const MEGA_BECQUEREL: UnitDefinition = new UnitDefinition(
	Unit.MEGA_BECQUEREL,
	'inventory.unit.megaBecquerel',
	'MBq',
	false
);

export const MICRO_GRAY_PER_SQUARE_METER: UnitDefinition = new UnitDefinition(
	Unit.MICRO_GRAY_PER_SQUARE_METER,
	'inventory.unit.microGrayPerSquareMeter',
	'µGy×m²',
	false
);

export const MICROSIEVERT: UnitDefinition = new UnitDefinition(
	Unit.MICROSIEVERT,
	'inventory.unit.microsievert',
	'µSv',
	false
);

export const MICRO_SIEVERT_PER_HOUR: UnitDefinition = new UnitDefinition(
	Unit.MICRO_SIEVERT_PER_HOUR,
	'inventory.unit.microSievertPerHour',
	'µSv',
	false
);

export const MILLI_GRAY: UnitDefinition = new UnitDefinition(
	Unit.MILLI_GRAY,
	'inventory.unit.milliGray',
	'mGy',
	false
);

export const MILLI_GRAY_PER_SQUARE_CENTIMETER: UnitDefinition = new UnitDefinition(
	Unit.MILLI_GRAY_PER_SQUARE_CENTIMETER,
	'inventory.unit.milliGrayPerSquareCentimeter',
	'mGy×cm²',
	false
);

export const MOL: UnitDefinition = new UnitDefinition(
	Unit.MOL,
	'inventory.unit.mol',
	'mol',
	false
);

export const NANOCURIE: UnitDefinition = new UnitDefinition(
	Unit.NANOCURIE,
	'inventory.unit.nanocurie',
	'nCi',
	false
);

export const PICO_SECOND: UnitDefinition = new UnitDefinition(
	Unit.PICO_SECOND,
	'inventory.unit.picoSecond',
	'ps',
	false
);

export const PIXEL: UnitDefinition = new UnitDefinition(
	Unit.PIXEL,
	'inventory.unit.pixel',
	'px',
	false
);

export const PERCENT: UnitDefinition = new UnitDefinition(
	Unit.PERCENT,
	'inventory.unit.percent',
	'%',
	false
);

export const PERCENT_TC: UnitDefinition = new UnitDefinition(
	Unit.PERCENT_TC,
	'inventory.unit.percentTc',
	'%Tc',
	true
);

export const PERCENT_TC_X: UnitDefinition = new UnitDefinition(
	Unit.PERCENT_TC_X,
	'inventory.unit.percentTcX',
	'%Tc-x',
	true
);

export const SIGMA: UnitDefinition = new UnitDefinition(
	Unit.SIGMA,
	'inventory.unit.sigma',
	'Sigma',
	false
);

export function getUnitDefinition(unit: Unit): UnitDefinition {
	const unitDefinition: Optional<UnitDefinition> = getAllUnitDefinitions().find((definition) => definition.getUnit() === unit) ?? null;
	if (unitDefinition === null) {
		throw new Error('Missing unit definition: ' + unit);
	}
	return unitDefinition;
}

export function getUnitDefinitions(units: ReadonlyArray<Unit>): ReadonlyArray<UnitDefinition> {
	return units.map((unit) => getUnitDefinition(unit));
}

export function getAllUnitDefinitions(): ReadonlyArray<UnitDefinition> {
	return [
		BECQUEREL,
		BECQUEREL_PER_SQUARE_CENTIMETER,
		CENTI_GRAY_PER_SQUARE_CENTIMETER,
		GRAY_PER_SQUARE_CENTIMETER,
		CENTIMETER,
		COUNT,
		ABSOLUTE_COUNT,
		ABSOLUTE_KILO_COUNT,
		COUNTS_PER_SECOND,
		COUNTS_PER_10_SECONDS,
		COUNTS_PER_25_SECONDS,
		COUNTS_PER_MINUTE,
		COUNTS_PER_THREE_MINUTES,
		COUNTS_PER_5_MINUTES,
		COUNTS_PER_SECOND_PER_KILO_BECQUEREL,
		COUNTS_PER_SECOND_PER_MEGA_BECQUEREL,
		COUNTS_PER_SECOND_PER_MEGA_BECQUEREL_PER_CUBIC_CENTIMETER,
		COUNTS_PER_MINUTE_PER_MICRO_CURIE,
		COUNTS_PER_MINUTE_PER_KILO_BECQUEREL,
		COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL,
		KILO_BECQUEREL,
		KILO_ELECTRONS_VOLT,
		KILO_COUNTS_PER_MINUTE,
		KILO_COUNTS_PER_THREE_MINUTES,
		KILO_COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL,
		KILO_COUNTS_PER_SECOND,
		MEGA_BECQUEREL,
		MICRO_GRAY_PER_SQUARE_METER,
		MICROSIEVERT,
		MICRO_SIEVERT_PER_HOUR,
		MILLI_GRAY,
		MILLI_GRAY_PER_SQUARE_CENTIMETER,
		MOL,
		NANOCURIE,
		PICO_SECOND,
		PIXEL,
		PERCENT,
		PERCENT_TC,
		PERCENT_TC_X,
		SIGMA
	];
}

export function getAllUnits(): ReadonlyArray<Unit> {
	return [
		Unit.BECQUEREL,
		Unit.BECQUEREL_PER_SQUARE_CENTIMETER,
		Unit.CENTI_GRAY_PER_SQUARE_CENTIMETER,
		Unit.GRAY_PER_SQUARE_CENTIMETER,
		Unit.CENTIMETER,
		Unit.COUNT,
		Unit.ABSOLUTE_COUNT,
		Unit.ABSOLUTE_KILO_COUNT,
		Unit.COUNTS_PER_SECOND,
		Unit.COUNTS_PER_10_SECONDS,
		Unit.COUNTS_PER_25_SECONDS,
		Unit.COUNTS_PER_MINUTE,
		Unit.COUNTS_PER_THREE_MINUTES,
		Unit.COUNTS_PER_5_MINUTES,
		Unit.COUNTS_PER_SECOND_PER_KILO_BECQUEREL,
		Unit.COUNTS_PER_SECOND_PER_MEGA_BECQUEREL,
		Unit.COUNTS_PER_SECOND_PER_MEGA_BECQUEREL_PER_CUBIC_CENTIMETER,
		Unit.COUNTS_PER_MINUTE_PER_MICRO_CURIE,
		Unit.COUNTS_PER_MINUTE_PER_KILO_BECQUEREL,
		Unit.COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL,
		Unit.KILO_BECQUEREL,
		Unit.KILO_ELECTRONS_VOLT,
		Unit.KILO_COUNTS_PER_MINUTE,
		Unit.KILO_COUNTS_PER_THREE_MINUTES,
		Unit.KILO_COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL,
		Unit.KILO_COUNTS_PER_SECOND,
		Unit.MEGA_BECQUEREL,
		Unit.MICRO_GRAY_PER_SQUARE_METER,
		Unit.MICROSIEVERT,
		Unit.MICRO_SIEVERT_PER_HOUR,
		Unit.MILLI_GRAY,
		Unit.MILLI_GRAY_PER_SQUARE_CENTIMETER,
		Unit.MOL,
		Unit.NANOCURIE,
		Unit.PICO_SECOND,
		Unit.PIXEL,
		Unit.PERCENT,
		Unit.PERCENT_TC,
		Unit.PERCENT_TC_X,
		Unit.SIGMA
	];
}