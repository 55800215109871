import { FunctionComponent } from 'react';

import { ClassName } from 'lib/class-name/ClassName';

import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';

export interface FormLayoutSectionProps extends React.ComponentPropsWithoutRef<'fieldset'> {
	align?: FormLayoutSectionAlign;
}

export const FormLayoutSection: FunctionComponent<FormLayoutSectionProps> = (props): JSX.Element => {
	const { align, children } = props;

	const modifier = align ?
		`form-layout-section--${ClassName.fromEnumValue(align)}` :
		'';

	return (
		<fieldset className={`form-layout-section ${modifier}`}>
			{children}
		</fieldset>
	);

};
