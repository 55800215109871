import { L10nContext } from 'context/L10nContext';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';

import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { ExpandableSlimBody } from 'presentation/ui/compositions/expandable-slim/expandable-slim-body/ExpandableSlimBody';
import { ExpandableSlimHeader } from 'presentation/ui/compositions/expandable-slim/expandable-slim-header/ExpandableSlimHeader';
import { ExpandableSlim } from 'presentation/ui/compositions/expandable-slim/ExpandableSlim';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { mapTypeToIlluIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifierMapper';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';

import { Permission, permissionGroupReport } from 'services/core/lib/auth/AuthService';
import { SimpleViewTitle } from 'services/core/presentation/ui/simple-view-title/SimpleViewTitle';
import { selectFilteredClearances, selectFilteredDevices } from 'services/device/store/devicesSlice';

import { ReportDocumentViewModel } from 'services/report/domain/model/ReportDocumentModel';
import { ReportViewModel } from 'services/report/domain/model/ReportModel';
import { ReportSequenceViewModel } from 'services/report/domain/model/ReportSequenceModel';
import { fetchReports, resetActionStatus, selectReportByUuid, updateReport } from 'services/report/store/reportSlice';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { Guid } from '../../../../lib/guid/Guid';
import { ButtonUserFeedbackLayout } from '../../../../presentation/ui/layouts/button-user-feedback-layout/ButtonUserFeedbackLayout';
import { getDeviceTypeDefinition } from '../../../device/domain/business/inventory/DeviceTypeDefinition';
import { fetchDocumentsByFacility, selectFacilityDocuments } from '../../../documents/store/documentSlice';
import { ReportDeleteModal } from '../ui/report-action/report-delete-modal/ReportDeleteModal';
import { ReportUpdateModal } from '../ui/report-action/report-update-modal/ReportUpdateModal';
import { ReportCardDevice } from '../ui/report-card-device/ReportCardDevice';
import { ReportCard } from '../ui/report-card/ReportCard';
import { ReportFacilityDocumentsSelection } from '../ui/report-facility-documents-selection/ReportFacilityDocumentsSelection';
import { ReportViewModalType } from './ReportViewModalType';

interface ReportViewParams {
	reportUuid: string;
}

export const ReportView = (): JSX.Element => {

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	const dispatch = useDispatch();

	// Local state
	const [modalType, setModalType] = useState<ReportViewModalType>(null);

	// Unwrap the required report uuid from the route
	const params = useParams<ReportViewParams>();
	const reportUuid = params?.reportUuid ?? null;
	if (reportUuid === null) {
		throw new Error('Report id is missing');
	}

	// Read the report from the state store
	const report = useSelector(selectReportByUuid(reportUuid));

	// Handle permissions
	if (!authContext.hasAnyPermission(permissionGroupReport)) {
		throw new Error('Permission denied');
	}

	// Use a custom selector function to provide type information for the state defined in the slice
	const reportStoreFetchStatus = useTypedSelector(state => state.reports.fetchStatus);
	const reportStoreActionStatus = useTypedSelector(state => state.reports.actionStatus);
	const reportStoreLastActionError = useTypedSelector(state => state.reports.lastActionError);

	// Read reports from the server - reports only online available
	useEffect(() => {
		if (reportStoreFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchReports({
				clientUuid: clientContext.selectedClientUuid,
				facilityUuid: facilityContext.selectedFacilityUuid
			}));
		}
	});

	const documentStoreFetchStatus = useTypedSelector(state => state.documents.fetchStatus);

	useEffect(() => {
		dispatch(fetchDocumentsByFacility({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}, [clientContext.selectedClientUuid, dispatch, facilityContext.selectedFacilityUuid]);

	const documentsByFacility = useTypedSelector(selectFacilityDocuments(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const reportSequences = useRef<Array<ReportSequenceViewModel>>(report?.ReportSequences ?? []);
	const reportDocuments = useRef<Array<ReportDocumentViewModel>>(report?.ReportDocuments ?? []);

	const [isApplySelectionButtonDisabled, setIsApplySelectionButtonDisabled] = useState<boolean>(false);

	const handleReportDocumentProps = (reportDocumentModel: ReportDocumentViewModel, reportDocumentChecked: boolean) => {
		dispatch(resetActionStatus());

		if (reportDocumentChecked) {
			reportDocuments.current = reportDocuments.current.filter((document) => {
				return document.Document !== reportDocumentModel.Document;
			});
			reportDocuments.current.push(reportDocumentModel);
			setIsApplySelectionButtonDisabled(true);
		} else {
			reportDocuments.current = reportDocuments.current.filter((document) => {
				return document.Document !== reportDocumentModel.Document;
			});
			setIsApplySelectionButtonDisabled(true);
		}
	};

	const handleReportSequenceProps = (reportSequenceModel: ReportSequenceViewModel, reportSequenceChecked: boolean, reportSequenceDateChange: boolean) => {
		dispatch(resetActionStatus());

		if (reportSequenceChecked) {
			reportSequences.current = reportSequences.current?.filter((sequence) => {
				return sequence.Sequence !== reportSequenceModel.Sequence;
			});
			reportSequences.current?.push(reportSequenceModel);
			setIsApplySelectionButtonDisabled(true);
		} else if (reportSequenceDateChange) {
			reportSequences.current = reportSequences.current?.filter((sequence) => {
				return sequence.Sequence !== reportSequenceModel.Sequence;
			});
			reportSequences.current?.push(reportSequenceModel);
			setIsApplySelectionButtonDisabled(true);
		} else {
			reportSequences.current = reportSequences.current?.filter((sequence) => {
				return sequence.Sequence !== reportSequenceModel.Sequence;
			});
			setIsApplySelectionButtonDisabled(true);
		}
	};

	// Read devices from the state store
	const devices = useSelector(selectFilteredDevices(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		true,
		false
	));

	// Read the clearance logs from the state store
	const clearances = useSelector(selectFilteredClearances(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		true,
		false
	));

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permissionUpdate: authContext.hasPermission(Permission.REPORT_UPDATE),
		permissionDelete: authContext.hasPermission(Permission.REPORT_DELETE)
	};

	if (reportStoreFetchStatus === AsyncFetchStatus.IDLE || reportStoreFetchStatus === AsyncFetchStatus.PENDING) {
		return (
			<LoadingSpinner />
		);
	}

	if (report === null) {
		throw new Error('Report not found');
	}

	const renderNotification = (): Array<JSX.Element> => {
		const notifications = [];

		if (reportStoreActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					key={Guid.generate()}
					message={reportStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		return notifications;
	};

	const onModalDismiss = () => {
		setModalType(null);
	};

	const onModalTriggerClick = (type: ReportViewModalType) => {
		dispatch(resetActionStatus());

		setModalType(type);
	};

	const modal = (): JSX.Element => {
		let reportModal: JSX.Element = null;

		switch (modalType) {
			case ReportViewModalType.EDIT:
				reportModal =
					<ReportUpdateModal
						report={report}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ReportViewModalType.DELETE:
				reportModal =
					<ReportDeleteModal
						report={report}
						onDismiss={onModalDismiss}
					/>;
				break;
		}

		return reportModal;
	};

	const handleClickSubmit = (): void => {

		const updatedReportViewModel: ReportViewModel = {
			Name: report.Name,
			PeriodDateStart: report.PeriodDateStart,
			PeriodDateEnd: report.PeriodDateEnd,
			ValidUntil: report.ValidUntil,
			ReportSequences: reportSequences.current,
			ReportDocuments: reportDocuments.current,
			ModelSyncState: report.ModelSyncState,
			CreatedAt: report.CreatedAt,
			UpdatedAt: report.UpdatedAt,
			Uuid: report.Uuid
		};
		dispatch(updateReport({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,

			viewModel: updatedReportViewModel
		}));
		setIsApplySelectionButtonDisabled(false);
	};

	const reportCard =
		<ReportCard
			report={report}
			userPermissionEdit={user.permissionUpdate}
			onClick={onModalTriggerClick}
			isOnlyView={false}
		/>;

	const renderDevices = (): Array<JSX.Element> | JSX.Element => {

		if (devices.length === 0) {
			return <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.reportDevices', 'Keine Geräte vorhanden')} />;
		}

		return (
			<>
				{
					devices.map((device) => {
						const deviceTypeDefinition = getDeviceTypeDefinition(device.Type);
						return (
							<ExpandableSlim key={device.Uuid} expanded={false}>
								<ExpandableSlimHeader
									caption={device.Name}
									subtitle={l10nContext.translate(deviceTypeDefinition.getLabelKey())}
									illustration={mapTypeToIlluIdentifier(device?.Type)}
								/>
								<ExpandableSlimBody>
									<CardCollectionLayout>
										<ReportCardDevice
											key={device.Uuid}
											device={device}
											report={report}
											onHandleReportSequenceProps={handleReportSequenceProps}
											onHandleReportDocumentProps={handleReportDocumentProps}
										/>
									</CardCollectionLayout>
								</ExpandableSlimBody>
							</ExpandableSlim>
						);
					})
				}
			</>
		);
	};

	const renderClearances = (): Array<JSX.Element> | JSX.Element => {

		if (clearances.length === 0) {
			return <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.reportClearances', 'Keine Freimessungen vorhanden')} />;
		}

		return (
			<>
				{
					clearances.map((clearence) => {
						const deviceTypeDefinition = getDeviceTypeDefinition(clearence.Type);
						return (
							<ExpandableSlim key={clearence.Uuid} expanded={false}>
								<ExpandableSlimHeader
									caption={clearence.Name}
									subtitle={l10nContext.translate(deviceTypeDefinition.getLabelKey())}
									illustration={mapTypeToIlluIdentifier(clearence?.Type)}
								/>
								<ExpandableSlimBody>
									<CardCollectionLayout>
										<ReportCardDevice
											key={clearence.Uuid}
											device={clearence}
											report={report}
											onHandleReportSequenceProps={handleReportSequenceProps}
											onHandleReportDocumentProps={handleReportDocumentProps}
										/>
									</CardCollectionLayout>
								</ExpandableSlimBody>
							</ExpandableSlim>
						);
					})
				}
			</>
		);
	};

	const userFeedback = () => {
		if (reportStoreActionStatus === AsyncReducerStatus.UPDATED) {
			return (
				<span className="report-view-user-feedback--positive">
					{l10nContext.translate('common.button.selectionAppliedPositiveFeedback', 'Ihre Auswahl wurde erfolgreich übernommen.')}
				</span>
			);
		}
		if (reportStoreActionStatus === AsyncReducerStatus.FAILED) {
			return (
				<span className="report-view-user-feedback--negative">
					{l10nContext.translate('common.button.selectionAppliedNegativeFeedback', 'Fehler: Ihre Auswahl wurde nicht übernommen.')}
				</span>
			);
		}
		return null;
	};

	const renderReportFacilityDocumentsSelection = () => {
		if (documentStoreFetchStatus === AsyncFetchStatus.SUCCESS) {
			return (
				<ReportFacilityDocumentsSelection
					report={report}
					documentsByFacility={documentsByFacility}
					onHandleReportDocumentProps={handleReportDocumentProps}
				/>
			);
		}
		return null;
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs
						mapURLFragments={[[reportUuid, report?.Name]]}
					/>
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection
				section={MainLayoutSectionSection.SECTION_MAIN}
			>
				<ViewLayout>
					<ViewLayoutSection>
						<NotificationBars>
							{renderNotification()}
						</NotificationBars>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{modal()}
						{reportCard}
					</ViewLayoutSection>

					<ViewLayoutSection>
						<SimpleViewTitle
							label={l10nContext.translate('view.reports.reportDevicesCollection', 'Geräte und Messreihen')}
							isReportView
						/>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{renderDevices()}
					</ViewLayoutSection>

					<ViewLayoutSection>
						<SimpleViewTitle
							label={l10nContext.translate('view.reports.reportClearancesCollection', 'Freimessungen und Messziele')}
							isReportView
						/>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{renderClearances()}
					</ViewLayoutSection>

					<ViewLayoutSection>
						<SimpleViewTitle
							label={l10nContext.translate('view.reports.reportDocumentFacilityCollection', 'Dokumente zum Standort')}
							isReportView
						/>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{renderReportFacilityDocumentsSelection()}
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>

							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
								<ButtonSecondary
									disabled={!user.permissionDelete}
									buttonText={l10nContext.translate('common.button.deleteReport', 'Bericht löschen')}
									icon={IconIdentifier.TRASH}
									status={ButtonSecondaryStatus.ATTENTION}
									onClick={() => onModalTriggerClick(ReportViewModalType.DELETE)}
								/>
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								<ButtonUserFeedbackLayout>
									<ButtonPrimary
										disabled={!isApplySelectionButtonDisabled || !user.permissionUpdate}
										icon={IconIdentifier.ARROW_RIGHT}
										buttonText={l10nContext.translate('common.button.selectionApplied', 'Auswahl übernehmen')}
										onClick={handleClickSubmit}
									/>
									{userFeedback()}
								</ButtonUserFeedbackLayout>

							</ColumnLayoutSection>
						</ColumnLayout>
					</ViewLayoutSection>

				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>

		</MainLayout>
	);
};
