import { L10nContext } from 'context/L10nContext';

import { useKeyPress } from 'presentation/hooks';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutHeader } from 'presentation/ui/layouts/form-layout/form-layout-header/FormLayoutHeader';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutModifier } from 'presentation/ui/layouts/form-layout/FormLayoutModifier';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { useContext } from 'react';

import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { Textarea, TextareaStatus } from '../../../../../../presentation/ui/partials/input/textarea/Textarea';

export interface MaintenanceLogCommentFormStep2Props {
	/**
	 * Maintenance log entry
	 */
	maintenanceLogEntry: MaintenanceLogEntryViewModel;
	/**
	 * Button back action
	 */
	onClickPrevious: () => void;
	/**
	 * Button confirm
	 */
	onClickConfirm: () => void;
}

export const MaintenanceLogCommentFormStep2 = (props: MaintenanceLogCommentFormStep2Props): JSX.Element => {
	const { maintenanceLogEntry, onClickPrevious, onClickConfirm } = props;

	const l10nContext = useContext(L10nContext);

	useKeyPress('Enter', onClickConfirm);

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			modifier={FormLayoutModifier.BLUE_BACKGROUND}
			onSubmit={onClickConfirm}
		>
			<FormLayoutHeader
				text={l10nContext.translate('common.modal.checkDetails', 'Überprüfen Sie bitte Ihre Angaben')}
				icon={IconIdentifier.WARNING}
			/>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('view.maintenances.modal.action.edit.resolve', 'Maßnahme')}`}
					thin
				>
					<Textarea
						defaultValue={maintenanceLogEntry.IncidentSolution ?? ''}
						status={TextareaStatus.READONLY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.back', 'Zurück')}
							onClick={onClickPrevious}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('view.maintenances.modal.action.edit.caption', 'Betriebsbucheintrag beheben')}
							type='submit'
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
