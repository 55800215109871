import React, { useContext, useEffect, useRef } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { useDispatch, useSelector } from 'react-redux';
import { RoleViewModel } from 'services/core/domain/model/RoleModel';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { fetchRoles, selectRoles } from 'services/core/store/roleSlice';
import { useTypedSelector } from 'store/common/TypedSelector';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { Checkbox } from 'presentation/ui/partials/input/checkbox/Checkbox';
import { CheckboxesLayout } from 'presentation/ui/layouts/checkboxes-layout/CheckboxesLayout';

export interface MembershipCreateModalStep2Props {
	/**
	 * Membership
	 */
	membership: { [key: string]: { [key: string]: any } };
	/**
	 * Button back action
	 */
	onClickPrevious: () => void;
	/**
	 * Button next action
	 */
	onClickNext: () => void;
	/**
	 * Change membership props
	 */
	onChangeRoleSelection: (roles: Array<RoleViewModel>) => void;
}

/**
 * The membership create modal step 2 component
 */
export const MembershipCreateFormStep2 = (props: MembershipCreateModalStep2Props): JSX.Element => {
	const { membership, onClickPrevious, onClickNext, onChangeRoleSelection } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Use a custom selector function to provide type information for the state defined in the slice
	const storeFetchStatus = useTypedSelector(state => state.roles.fetchStatus);

	// Read the roles from the state store
	const roles = useSelector(selectRoles());

	const selectedRoles = useRef<Array<RoleViewModel>>([]);

	if (membership.Roles) {
		const initialSelectedRoles = membership.Roles as Array<RoleViewModel>;
		selectedRoles.current = initialSelectedRoles;
	}

	useEffect(() => {
		if (storeFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchRoles({ clientUuid: clientContext.selectedClientUuid, facilityUuid: facilityContext.selectedFacilityUuid }));
		}
	});

	if (storeFetchStatus === AsyncFetchStatus.INITIAL_PENDIG || storeFetchStatus === AsyncFetchStatus.PENDING) {
		return (
			<LoadingSpinner />
		);
	}

	const handleAddRole = (roleUuid: string): void => {
		const roleModel = roles.find((value) => {
			return value.Uuid === roleUuid;
		});
		if ((roleModel ?? null) === null) {
			return;
		}
		selectedRoles.current.push(roleModel);
		onChangeRoleSelection(selectedRoles.current);
	};

	const handleRemoveRole = (roleUuid: string): void => {
		const roleModel = roles.find((value) => {
			return value.Uuid === roleUuid;
		});
		if ((roleModel ?? null) === null) {
			return;
		}
		selectedRoles.current = selectedRoles.current.filter((selectedRole): boolean => {
			return selectedRole.Uuid !== roleModel.Uuid;
		});
		onChangeRoleSelection(selectedRoles.current);
	};

	const chooseRoles = roles.map((role) => {

		return (
			<Checkbox
				key={role.Uuid}
				labelName={role.Name}
				id={role.Uuid}
				defaultChecked={membership.Roles?.map((r: { Name: string }) => r.Name).includes(role.Name)}
				value={role.Uuid ?? ''}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					if (event.target.checked) {
						handleAddRole(event.target.value);
					} else {
						handleRemoveRole(event.target.value);
					}
				}}
			/>
		);
	});

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			onSubmit={onClickNext}
		>
			<FormLayoutSection>
				<Label
					label={l10nContext.translate('memberships.memberships', 'Rolle(n)')}
				>
					<CheckboxesLayout>
						{chooseRoles}
					</CheckboxesLayout>
				</Label>

			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.back', 'Zurück')}
							onClick={onClickPrevious}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
