import { L10nContext } from 'context/L10nContext';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { DebugConsole } from '../../../../../../lib/debug/DebugConsole';
import { toFilesystemSafe } from '../../../../../../lib/to-filesystem-safe/ToFilesystemSafe';
import { AuthContext } from '../../../../../core/context/AuthContext';
import { NumberFieldDescription } from '../../../../domain/business/common/description/FieldDescription';
import { FieldType } from '../../../../domain/business/common/field/FieldType';
import { ValuedString } from '../../../../domain/business/common/misc/ValuedString';
import { Record } from '../../../../domain/business/common/record/Record';
import { getSequenceTypeDefinition } from '../../../../domain/business/inventory/SequenceTypeDefinition';
import { UnitUtil } from '../../../../domain/business/util/UnitUtil';
import { RecordViewModel } from '../../../../domain/model/RecordModel';
import { selectRecordsBySequenceWithPeriod } from '../../../../store/recordSlice';
import { StringOutputListSelection } from '../../widget/widget-renderer/StringOutputListSelection';
import { CsvDataType } from './CsvDataType';
import { SequenceDownloadModalContent } from './SequenceDownloadModalContent';

export interface SequenceDownloadModalProps {
	sequence: SequenceViewModel;
	onDismiss?: () => void;
}

export const SequenceDownloadModal = (props: SequenceDownloadModalProps): JSX.Element => {
	const { sequence, onDismiss } = props;
	const sequenceViewModel = props.sequence;

	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);
	const userUuid = authContext.getActor().Uuid;

	const localSequencePeriod = JSON.parse(localStorage.getItem(`private_${userUuid}_sequencePeriodSelection`));
	const dateStart = new Date(localSequencePeriod.dateStart);
	const dateEnd = new Date(localSequencePeriod.dateEnd);

	const records = useSelector(selectRecordsBySequenceWithPeriod(sequenceViewModel, dateStart, dateEnd, false));

	// Prepare the sequence type definition
	const sequenceTypeDefinition = getSequenceTypeDefinition(sequenceViewModel.Type);
	const tableDescriptions = sequenceTypeDefinition.getTableDescriptions();
	if (tableDescriptions.length === 0) {
		return null;
	}
	const tableDescription = tableDescriptions[0];

	const valueDescription = tableDescription.value as NumberFieldDescription;
	const deviationDescription = tableDescription.deviation as NumberFieldDescription;
	const expectedValueDescription = tableDescription.expectedValue as NumberFieldDescription;

	const valueNumberOfDecimals = valueDescription?.numberOfDecimals ?? 2;
	const expectedValueNumberOfDecimals = expectedValueDescription?.numberOfDecimals ?? 2;
	const deviationNumberOfDecimals = deviationDescription?.numberOfDecimals ?? 2;

	const lowerSoftThresholdDescription = tableDescription.lowerReactionValue as NumberFieldDescription;
	const lowerHardThresholdDescription = tableDescription.lowerToleranceValue as NumberFieldDescription;

	const unitUtil = new UnitUtil(sequenceViewModel);
	const valueDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(valueDescription?.unit);
	const deviationDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(deviationDescription?.unit);
	const expectedValueDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(expectedValueDescription?.unit);
	const softThresholdDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(lowerSoftThresholdDescription?.unit);
	const hardThresholdDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(lowerHardThresholdDescription?.unit);

	const hasDeviation = (tableDescription.deviation ?? null) !== null;
	const hasExpectedValue = (tableDescription.expectedValue ?? null) !== null;
	const hasSoftThreshold = (tableDescription.lowerReactionValue ?? null) !== null || (tableDescription.upperReactionValue ?? null) !== null;
	const hasHardThreshold = (tableDescription.lowerToleranceValue ?? null) !== null || (tableDescription.upperToleranceValue ?? null) !== null;

	const csvRecords: string[][] = [];
	const csvHeadings: string[] = [];

	const renderCSVHeadings = () => {

		csvHeadings.push(l10nContext.translate(tableDescription?.value?.labelKey) + ' ' + valueDisplayUnit);
		csvHeadings.push(l10nContext.translate('sequence.chart.overlay.comment', 'Kommentar'));

		if (hasDeviation) {
			csvHeadings.push(l10nContext.translate(tableDescription?.deviation?.labelKey) + ' ' + deviationDisplayUnit + ' / %');
		}
		if (hasExpectedValue) {
			csvHeadings.push(l10nContext.translate(tableDescription?.expectedValue?.labelKey) + ' ' + expectedValueDisplayUnit);
		}
		if (hasSoftThreshold) {
			csvHeadings.push(l10nContext.translate('sequence.chart.sequenceLabel.softThreshold.label', 'Reaktionsschwelle') + ' ' + softThresholdDisplayUnit);
		}
		if (hasHardThreshold) {
			csvHeadings.push(l10nContext.translate('sequence.chart.sequenceLabel.hardThreshold.label', 'Toleranzgrenze') + ' ' + hardThresholdDisplayUnit);
		}

		const renderAdditionalColumns = (): void => {
			const additionalColumns = tableDescription?.additionalColumns ?? null;

			if (additionalColumns === null || additionalColumns.length === 0) {
				return null;
			}
			for (const additionalColumn of additionalColumns) {
				csvHeadings.push(l10nContext.translate(additionalColumn.labelKey));
			}

			return null;
		};

		renderAdditionalColumns();

		csvHeadings.push(
			l10nContext.translate('sequence.table.header.recordedAt', 'Zeitpunkt'),
			l10nContext.translate('sequence.table.header.recordedBy', 'Erfasser')
		);
	};

	renderCSVHeadings();

	const renderCSVRecordData = (): void => {
		records.map((recordViewModel) => {

			let record: Record;

			try {
				record = sequenceTypeDefinition.restoreRecordFromViewData(recordViewModel.RecordConfiguration);
			} catch (e) {
				DebugConsole.error(e);
				return null;
			}

			const output = record.getOutput(valueDescription);

			let formattedValue: string;
			switch (output.getDescription().type) {
				case FieldType.NUMBER:
					formattedValue = l10nContext.formatNumber(output.getSingle() as number, valueNumberOfDecimals);
					break;
				case FieldType.BOOLEAN:
					formattedValue = l10nContext.formatBoolean(output.getSingle() as boolean);
					break;
				case FieldType.DATE_TIME:
					formattedValue = l10nContext.formatDateTime(output.getSingle() as Date);
					break;
				case FieldType.VALUED_STRING:
					formattedValue = (output.getSingle() as ValuedString).value;
					break;
				default:
					formattedValue = output.getSingle() as string;
					break;
			}

			const comment = recordViewModel.RecordConfiguration.values.comment ?? null;

			const recordData: string[] = [];
			recordData.push(formattedValue, comment);

			const renderDeviation = (recordEntry: Record): void => {
				if (!hasDeviation) {
					return null;
				}

				const expectedValue = recordEntry.getOutput(expectedValueDescription).getSingle() as number;

				let formattedAbsoluteDeviation: string = null;
				let formattedRelativeDeviation: string = null;
				if (deviationDescription !== null) {
					const deviationValue = recordEntry.getOutput(deviationDescription).getSingle() as number;
					formattedAbsoluteDeviation = l10nContext.formatNumber(deviationValue, deviationNumberOfDecimals);
					if (deviationValue > 0) {
						formattedAbsoluteDeviation = '+' + formattedAbsoluteDeviation;
					}
					const relativeDeviation = (deviationValue / expectedValue) * 100;
					formattedRelativeDeviation = l10nContext.formatNumber(relativeDeviation, 2);
					if (deviationValue > 0) {
						formattedRelativeDeviation = '+' + formattedRelativeDeviation;
					}
				}

				const relativeDeviation = expectedValue !== 0 ? formattedRelativeDeviation + '%' : null;
				recordData.push(formattedAbsoluteDeviation + ' / ' + relativeDeviation);

				return null;
			};

			renderDeviation(record);

			const renderExpectedValue = (recordEntry: Record): void => {
				if (!hasExpectedValue) {
					return null;
				}

				let formattedExpectedValue: string = null;
				if (expectedValueDescription !== null) {
					const expectedValue = recordEntry.getOutput(expectedValueDescription).getSingle() as number;
					formattedExpectedValue = l10nContext.formatNumber(expectedValue, expectedValueNumberOfDecimals);
				}

				recordData.push(formattedExpectedValue);
				return null;
			};

			renderExpectedValue(record);

			const renderThresholdValue = (recordEntry: Record, fieldDescription: NumberFieldDescription) => {
				const thresholdValue = recordEntry.getOutput(fieldDescription).getSingle() as number;
				const numberOfDecimals = fieldDescription?.numberOfDecimals ?? 2;
				return l10nContext.formatNumber(thresholdValue, numberOfDecimals);
			};

			const renderSoftThreshold = (recordEntry: Record): void => {
				if (!hasSoftThreshold) {
					return null;
				}
				const formattedThresholds = [];
				if ((tableDescription?.lowerReactionValue ?? null) !== null) {
					formattedThresholds.push(renderThresholdValue(recordEntry, tableDescription.lowerReactionValue));
				}
				if ((tableDescription?.upperReactionValue ?? null) !== null) {
					formattedThresholds.push(renderThresholdValue(recordEntry, tableDescription.upperReactionValue));
				}
				if (formattedThresholds.length === 0) {
					return null;
				}

				const connectedThresholdString = formattedThresholds.join(' / ');

				recordData.push(connectedThresholdString);
				return null;
			};

			renderSoftThreshold(record);

			// eslint-disable-next-line consistent-return
			const renderHardThreshold = (recordEntry: Record): void => {
				if (!hasHardThreshold) {
					return null;
				}
				const formattedThresholds = [];
				if ((tableDescription?.lowerToleranceValue ?? null) !== null) {
					formattedThresholds.push(renderThresholdValue(recordEntry, tableDescription.lowerToleranceValue));
				}
				if ((tableDescription?.upperToleranceValue ?? null) !== null) {
					formattedThresholds.push(renderThresholdValue(recordEntry, tableDescription.upperToleranceValue));
				}
				if (formattedThresholds.length === 0) {
					return null;
				}

				const connectedThresholdString = formattedThresholds.join(' / ');

				recordData.push(connectedThresholdString);
			};

			renderHardThreshold(record);

			const renderAdditionalColumns = (recordEntry: Record): void => {
				const additionalColumns = tableDescription?.additionalColumns ?? null;

				if (additionalColumns === null || additionalColumns.length === 0) {
					return null;
				}

				for (const additionalColumn of additionalColumns) {
					recordEntry.getOutput(additionalColumn).selectOutput(
						new StringOutputListSelection((element): void => {
							recordData.push(element);
						})
					);
				}
				return null;
			};

			renderAdditionalColumns(record);

			recordData.push(l10nContext.formatDate(recordViewModel.RecordedAt) + ' ' + l10nContext.formatTime(recordViewModel.RecordedAt));

			const renderRecordedBy = (recordEntry: RecordViewModel): void => {
				if (recordEntry.Supplemented) {
					recordData.push(
						l10nContext.translate('sequence.table.body.supplementedByPrefix') +
						' ' +
						recordEntry.RecordedByName +
						l10nContext.translate('sequence.table.body.supplementedDatePrefix') +
						l10nContext.formatDateTime(recordEntry.CreatedAt)
					);
				}
				if ((recordEntry?.UpdatedByName ?? null) !== null) {
					recordData.push(
						l10nContext.translate('sequence.table.body.recordedByPrefix') +
						' ' +
						recordEntry.RecordedByName +
						' / ' +
						l10nContext.translate('sequence.table.body.updatedByPrefix') +
						' ' +
						recordEntry.UpdatedByName +
						' ' +
						l10nContext.translate('sequence.table.body.supplementedDatePrefix') +
						' ' +
						l10nContext.formatDateTime(recordEntry.UpdatedAt)
					);
				} else {
					recordData.push(
						l10nContext.translate('sequence.table.body.recordedByPrefix') +
						' ' +
						recordEntry.RecordedByName);
				}
			};

			renderRecordedBy(recordViewModel);

			csvRecords.push(recordData);
			return null;
		});
	};

	renderCSVRecordData();

	const fileName = toFilesystemSafe(sequence.Name);

	const csvData: CsvDataType = {
		data: csvRecords,
		headings: csvHeadings,
		filename: fileName
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.sequence.modal.action.download.caption', 'Messreihe herunterladen')}
				captionIcon={IconIdentifier.DOWNLOAD}
			/>
			<ModalDialogueSection>
				<SequenceDownloadModalContent
					data={csvData}
				/>
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
