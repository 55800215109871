import { L10nContext } from 'context/L10nContext';
import { CardCyclotronProductListItem } from 'presentation/ui/components/cards/card-cyclotron-product/card-cyclotron-product-list-item/CardCyclotronProductListItem';
import { CardCyclotronProductListItemHeader } from 'presentation/ui/partials/card/card-item-header/card-cyclotron-product-list-item-header/CardCyclotronProductListItemHeader';
import { CardItemState } from 'presentation/ui/partials/card/card-item-state/CardItemState';
import { useContext } from 'react';
import { getCyclotronProductIsotopeDefinition } from 'services/nuclide/domain/model/isotopes/NuclideIsotopeDefinition';
import { CyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';
import { CyclotronProductModelState } from 'services/nuclide/domain/model/CyclotronProductModelState';

export interface CyclotronProductCollectionItemProps {
	/**
	 * Cyclotron product
	 */
	cyclotronProduct: Readonly<CyclotronProductViewModel>;
	/**
	 * Permission add eluate
	 */
	permissionAddEluate?: boolean;
	/**
	 * onClick card handler
	 */
	onClick: () => void;
}

export const CyclotronProductCollectionItem = (props: CyclotronProductCollectionItemProps): JSX.Element => {
	const { cyclotronProduct, onClick } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	const formatedDeliveryDate = l10nContext.translate('cyclotronProducts.deliveredAt', 'Geliefert am ') + l10nContext.formatDateTime(cyclotronProduct.DeliveryDate);

	return (
		<CardCyclotronProductListItem
			key={cyclotronProduct.Uuid}
			cardId='myCardCyclotronProductListItemActiveCardId'
			onClick={onClick}
			completelyApplied={
				cyclotronProduct.State === CyclotronProductModelState.COMPLETELY_APPLIED ||
				cyclotronProduct.State === CyclotronProductModelState.IN_WASTE_MANAGEMENT
			}
		>
			<CardCyclotronProductListItemHeader
				title={cyclotronProduct.Name}
				subtitle={l10nContext.translate(getCyclotronProductIsotopeDefinition(cyclotronProduct.Isotope).getLabelKey())}
				state={cyclotronProduct.State}
			/>
			<CardItemState
				date={formatedDeliveryDate}
			/>
		</CardCyclotronProductListItem>
	);
};
