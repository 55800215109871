import { Optional } from '../misc/Optional';
import { Value } from './Value';

export class Fallback<Payload> implements Value<Payload> {
	private readonly result: Optional<Payload>;

	public constructor(value1: Value<Payload>, value2: Value<Payload>) {
		this.result = calculate(value1.getSingle(), value2.getSingle());
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<Payload> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<Payload> {
		return this.result;
	}
}

function calculate<Payload>(value1: Optional<Payload>, value2: Optional<Payload>): Optional<Payload> {
	if (value1 !== null) {
		return value1;
	}
	return value2;
}
