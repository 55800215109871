import { ComponentPropsWithoutRef, useEffect, useState } from 'react';

import { ExpandableSlimContext } from 'presentation/ui/compositions/expandable-slim/expandable-slim-context/ExpandableSlimContext';

import './expandable-slim.scss';

export interface ExpandableSlimProps extends ComponentPropsWithoutRef<'article'> {
	expanded?: boolean;
	mode?: string;
	firstElement?: boolean;
}

/**
 * TODO: Refactor the mode attribute to an enum set or a boolean attribute called slim
 * @param props
 * @constructor
 */
export const ExpandableSlim = (props: ExpandableSlimProps): JSX.Element => {

	const { expanded, mode, firstElement, ...restProps } = props;

	const [expandedState, setExpandedState] = useState(expanded);

	const expandedCssClasss = (expandedState && !mode ? 'expandable-slim--expanded' : '') ||
		(expandedState && mode ? `expandable-slim-mode--${mode}--expanded` : '');

	useEffect(() => {
		setExpandedState(expanded);
	}, [expanded]);

	return (
		<article
			className={`${!mode ? 'expandable-slim' : ''} ${mode ? 'expandable-slim-mode--' + mode : ''} ${expandedCssClasss} ${firstElement ? 'firstElement' : ''}`}
			{...restProps}
		>
			<ExpandableSlimContext.Provider value={{ expanded: expandedState, setExpanded: setExpandedState }}>
				{props.children}
			</ExpandableSlimContext.Provider>
		</article>
	);
};
