import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';

import {
	MaintenanceLogEntryModelConverter as ModelConverter,
	MaintenanceLogEntryPersistenceModel as PersistenceModel,
	MaintenanceLogEntryStoreModel as StoreModel,
	MaintenanceLogEntryViewModel as ViewModel,
	CreatableMaintenanceLogEntryViewModel as CreatableViewModel
} from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { ExternalHttpPersistence } from '../../../lib/persistence/http/ExternalHttpPersistence';

export class ExternalReportMaintenanceLogEntryHttpPersistence extends ExternalHttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = '/maintenance-log/report-api/v1';
	protected collectionControllerUri = 'maintenance-log-entries';
	protected entityControllerUri = 'maintenance-log-entry';

	protected responseCollectionKey = 'MaintenanceLogEntryModelCollection';
	protected responseEntityKey = 'MaintenanceLogEntryModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
