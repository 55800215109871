import { L10nContext } from 'context/L10nContext';

import { CardDocument } from 'presentation/ui/components/cards/card-document/card-document/CardDocument';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import {
	CardItemFolderControls,
	CardItemFolderControlType,
} from 'presentation/ui/partials/card/card-item-controls/card-item-folder-controls/CardItemFolderControls';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';
import { BreadcrumbFragment, Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Route } from 'router/Route';
import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Permission, permissionGroupDevice } from 'services/core/lib/auth/AuthService';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { FolderModelState } from 'services/documents/domain/model/FolderModelState';
import { DocumentModalType } from 'services/documents/domain/type/DocumentsModalType';
import { DocumentCollection } from 'services/documents/presentation/ui/card-collections/document-collection/DocumentCollection';
import { FolderCollection } from 'services/documents/presentation/ui/card-collections/folder-collection/FolderCollection';
import { DocumentAddModal } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddModal';
import { FolderAddModal } from 'services/documents/presentation/ui/folder-action/folder-add-modal/FolderAddModal';
import { FolderArchiveModal } from 'services/documents/presentation/ui/folder-action/folder-archive-modal/FolderArchiveModal';
import { FolderDeleteModal } from 'services/documents/presentation/ui/folder-action/folder-delete-modal/FolderDeleteModal';
import { FolderEditModal } from 'services/documents/presentation/ui/folder-action/folder-edit-modal/FolderEditModal';
import { FolderMoveModal } from 'services/documents/presentation/ui/folder-action/folder-move-modal/FolderMoveModal';
import { FolderUnarchiveModal } from 'services/documents/presentation/ui/folder-action/folder-unarchive-modal/FolderUnarchiveModal';
import {
	fetchDocumentsByFolder,
	resetActionStatus as resetDocumentActionStatus,
	selectFilteredDocumentsByFolder
} from 'services/documents/store/documentSlice';
import {
	fetchFolders,
	resetActionStatus as resetFolderActionStatus,
	selectFilteredFoldersByParentFolder,
	selectFolderByUuid,
	selectParentFolders,
	selectRootFolder,
} from 'services/documents/store/folderSlice';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { NotificationBar } from '../../../../presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from '../../../../presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from '../../../../presentation/ui/components/notification-bars/NotificationBars';
import { AsyncFetchStatus } from '../../../../store/common/AsyncFetchStatus';

interface FolderDetailViewParams {
	parentFolderUuid: string;
}

export const FolderDetailView = (): JSX.Element => {
	// Unwrap the required log entry uuid from the route
	const params = useParams<FolderDetailViewParams>();
	const currentFolderUuid = params?.parentFolderUuid ?? null;

	const [modalType, setModalType] = useState<DocumentModalType>(null);

	const [foldersFilterState, setFoldersFilterState] = useState<{ [key: string]: boolean }>({
		active: true,
		archived: false
	});

	const [documentsFilterState, setDocumentsFilterState] = useState<{ [key: string]: boolean }>({
		active: true,
		archived: false
	});

	const authContext = useContext(AuthContext);
	if (!authContext.hasAnyPermission(permissionGroupDevice)) {
		throw new Error('Permission denied');
	}

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	const history = useHistory();

	const dispatch = useDispatch();

	// fetch documents
	const documentStoreFetchStatus = useTypedSelector(state => state.documents.fetchStatus);
	const documentStoreActionStatus = useTypedSelector(state => state.documents.actionStatus);
	const documentStoreLastActionError = useTypedSelector(state => state.documents.lastActionError);
	const documentStorePending = documentStoreFetchStatus === AsyncFetchStatus.INITIAL || documentStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const documentStoreFailed = documentStoreFetchStatus === AsyncFetchStatus.FAILED;

	// fetch folders
	const folderStoreFetchStatus = useTypedSelector(state => state.folders.fetchStatus);
	const folderStoreActionStatus = useTypedSelector(state => state.folders.actionStatus);
	const folderStoreLastActionError = useTypedSelector(state => state.folders.lastActionError);
	const folderStorePending = folderStoreFetchStatus === AsyncFetchStatus.INITIAL || folderStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const folderStoreFailed = folderStoreFetchStatus === AsyncFetchStatus.FAILED;

	useEffect(() => {
		if (folderStoreFetchStatus === AsyncFetchStatus.INITIAL || folderStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG) {
			dispatch(fetchFolders({
				clientUuid: clientContext.selectedClientUuid,
				facilityUuid: facilityContext.selectedFacilityUuid
			}));
		}
	});

	const currentFolderModelsSelector = () => {
		if (currentFolderUuid === null) {
			return selectRootFolder(clientContext.selectedClientUuid, facilityContext.selectedFacilityUuid);
		}
		return selectFolderByUuid(currentFolderUuid);
	};

	const currentFolderModel = useSelector(currentFolderModelsSelector());

	useEffect(() => {
		if (folderStoreActionStatus === AsyncReducerStatus.DELETED) {
			dispatch(resetFolderActionStatus());
			history.push(Route.DOCUMENTS);
		}
	}, [folderStoreActionStatus, dispatch, history, currentFolderModel]);

	useEffect(() => {
		dispatch(fetchDocumentsByFolder({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			documentfFolderUuid: currentFolderModel?.Uuid
		}));
		// eslint-disable-next-line max-len
	}, [clientContext.selectedClientUuid, currentFolderModel?.Uuid, currentFolderUuid, dispatch, facilityContext.selectedFacilityUuid, folderStoreFailed, folderStoreFetchStatus]);

	const documentModels = useSelector(
		selectFilteredDocumentsByFolder(
			clientContext.selectedClientUuid,
			facilityContext.selectedFacilityUuid,
			currentFolderModel?.Uuid,
			documentsFilterState.active,
			documentsFilterState.archived
		)
	);

	const childFolderModels = useSelector(
		selectFilteredFoldersByParentFolder(
			clientContext.selectedClientUuid,
			facilityContext.selectedFacilityUuid,
			currentFolderModel?.Uuid,
			foldersFilterState.active,
			foldersFilterState.archived
		)
	);

	const parentFolders = useSelector(
		selectParentFolders(currentFolderModel)
	);

	// Show up a loading indicator while the folder fetching is pending
	if (folderStoreActionStatus === AsyncReducerStatus.DELETE_PENDING || folderStoreActionStatus === AsyncReducerStatus.DELETED) {
		return (
			<LoadingSpinner />
		);
	}

	if (
		currentFolderModel === null && folderStoreFetchStatus === AsyncFetchStatus.SUCCESS
		|| currentFolderModel === null && folderStoreFetchStatus === AsyncFetchStatus.FAILED
	) {
		throw new Error('Folder not found');
	}

	const handleFoldersFiltering = (id: string, state: boolean) => {
		setFoldersFilterState({
			...foldersFilterState,
			[id]: state
		});
	};

	const handleDocumentsFiltering = (id: string, state: boolean) => {
		setDocumentsFilterState({
			...documentsFilterState,
			[id]: state
		});
	};

	const onModalDismiss = (): void => {
		setModalType(null);
	};

	const onModalTriggerClick = (type: DocumentModalType) => {
		dispatch(resetDocumentActionStatus());
		dispatch(resetFolderActionStatus());

		setModalType(type);
	};

	const onCardControlsClick = (payload: CardItemControlActionType) => {
		switch (payload.type) {
			case CardItemFolderControlType.MOVE:
				onModalTriggerClick(DocumentModalType.MOVE_FOLDER);
				break;

			case CardItemFolderControlType.EDIT:
				onModalTriggerClick(DocumentModalType.EDIT_FOLDER_NAME);
				break;
		}
	};

	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permission: {
			delete: authContext.hasPermission(Permission.DEVICE_DELETE),
			archive: authContext.hasPermission(Permission.DEVICE_UPDATE_STATE),
			addFolder: authContext.hasPermission(Permission.DOCUMENT_FOLDER_CREATE),
			addDocument: authContext.hasPermission(Permission.DOCUMENT_CREATE)
		}
	};

	const archiveButton =
		<ButtonSecondary
			buttonText={
				currentFolderModel?.State === FolderModelState.ACTIVE ?
					l10nContext.translate('common.button.archive', 'Archivieren') :
					l10nContext.translate('common.button.unArchive', 'Archivierung aufheben')
			}
			icon={IconIdentifier.ARCHIVE}
			onClick={() => {
				currentFolderModel?.State === FolderModelState.ACTIVE ?
					onModalTriggerClick(DocumentModalType.ARCHIVE) :
					onModalTriggerClick(DocumentModalType.UNARCHIVE);
			}}
			disabled={!user.permission.archive}
		/>;

	const deleteButton =
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.delete', 'Löschen')}
			icon={IconIdentifier.TRASH}
			status={ButtonSecondaryStatus.ATTENTION}
			disabled={!user.permission.delete}
			onClick={() => onModalTriggerClick(DocumentModalType.DELETE)}
		/>;

	const modal = (): JSX.Element => {
		let folderModal: JSX.Element = null;

		switch (modalType) {
			case DocumentModalType.ADD_DOCUMENT:
				folderModal =
					<DocumentAddModal
						onDismiss={onModalDismiss}
						documentScope={DocumentModelScope.DOCUMENT_FOLDER}
						documentScopeReference={currentFolderModel?.Uuid}
					/>;
				break;

			case DocumentModalType.ADD_FOLDER:
				folderModal =
					<FolderAddModal
						onDismiss={onModalDismiss}
						parentFolder={currentFolderModel}
					/>;
				break;

			case DocumentModalType.ARCHIVE:
				folderModal =
					<FolderArchiveModal
						folder={currentFolderModel}
						onDismiss={onModalDismiss}
					/>;
				break;

			case DocumentModalType.DELETE:
				folderModal =
					<FolderDeleteModal
						folder={currentFolderModel}
						onDismiss={onModalDismiss}
					/>;
				break;

			case DocumentModalType.EDIT_FOLDER_NAME:
				folderModal =
					<FolderEditModal
						folder={currentFolderModel}
						onDismiss={onModalDismiss}
					/>;
				break;

			case DocumentModalType.MOVE_FOLDER:
				folderModal =
					<FolderMoveModal
						folder={currentFolderModel}
						onDismiss={onModalDismiss}
					/>;
				break;

			case DocumentModalType.UNARCHIVE:
				folderModal =
					<FolderUnarchiveModal
						folder={currentFolderModel}
						onDismiss={onModalDismiss}
					/>;
				break;
		}

		return folderModal;
	};

	const renderBreadcrumbFragments = (): BreadcrumbFragment[] => {
		const rootFolder = [
			{
				label: l10nContext.translate('view.documents.title', 'Dokumente'),
				url: Route.DOCUMENTS
			} as BreadcrumbFragment
		];

		const currentFolder = [
			{
				label: currentFolderModel?.Name,
				url: `${Route.DOCUMENTS}/${currentFolderModel?.Uuid}`
			} as BreadcrumbFragment
		];

		if (parentFolders?.length <= 1) {
			return [
				...rootFolder,
				...currentFolder
			];
		}

		const documentParentFolders =
			parentFolders
				.filter(folder => folder.Name !== user.location)
				.map(folder => (
					{
						label: folder.Name,
						url: `${Route.DOCUMENTS}/${folder.Uuid}`
					} as BreadcrumbFragment
				))
				.reverse();

		return [
			...rootFolder,
			...documentParentFolders,
			...currentFolder,
		];
	};

	const renderNotifications = (): Array<JSX.Element> => {
		const notifications = [];

		// Handle failed action
		if (documentStoreActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					message={documentStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (documentStoreFailed) {
			notifications.push(
				<NotificationBar
					message={documentStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (folderStoreActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					message={folderStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (folderStoreFailed) {
			notifications.push(
				<NotificationBar
					message={folderStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		return notifications;
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs fragments={renderBreadcrumbFragments()} />

					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{modal()}

						<CardDocument cardId={currentFolderModel?.Uuid}>
							<CardItemHeader
								title={currentFolderModel?.Name}
								illustration={IllustrationIdentifier.ORDNER_PLACE}
								archived={currentFolderModel?.State === FolderModelState.ARCHIVED}
							/>
							<CardItemFolderControls
								cardId={currentFolderModel?.Name}
								actionMove={CardItemControlState.ENABLED}
								actionEdit={CardItemControlState.ENABLED}
								onClick={onCardControlsClick}
							/>
						</CardDocument>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<FolderCollection
							folders={childFolderModels}
							fetchStatus={folderStorePending}
							onAddClick={
								user.permission.addFolder ?
									() => onModalTriggerClick(DocumentModalType.ADD_FOLDER) :
									null
							}
							onFilterClick={handleFoldersFiltering}
						/>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<DocumentCollection
							documents={documentModels}
							fetchStatus={documentStorePending}
							onAddClick={
								user.permission.addDocument ?
									() => onModalTriggerClick(DocumentModalType.ADD_DOCUMENT) :
									null
							}
							onFilterClick={handleDocumentsFiltering}
						/>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ColumnLayout>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>

								{archiveButton}

							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>

								{deleteButton}

							</ColumnLayoutSection>
						</ColumnLayout>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
