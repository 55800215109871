import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { DatePicker } from 'presentation/ui/partials/input/date-picker/DatePicker';
import { CreatableWasteManagementReportViewModel } from '../../../../domain/models/WasteManagementReportModel';

export interface WasteManagementGeneratorReportCreateFormStep1Props {
	/**
	 * Report
	 */
	report: CreatableWasteManagementReportViewModel;
	/**
	 * Button next action
	 */
	onClickNext: () => void;
	/**
	 * Invalid inputs
	 */
	invalidInputs?: string[];
	/**
	 * Change report props
	 */
	onChangeReportProp: (prop: string, value: string | Date) => void;
}

/**
 * The report create modal step 1
 */
export const WasteManagementGeneratorReportCreateFormStep1 = (props: WasteManagementGeneratorReportCreateFormStep1Props): JSX.Element => {
	const { report, invalidInputs = [], onChangeReportProp, onClickNext } = props;

	// Consume context
	const l10nContext = useContext(L10nContext);

	// Notification for invalid input fields
	const checkPlural = invalidInputs.length > 1 ? l10nContext.translate('common.modal.notificationErrorPlural', ' dürfen nicht leer sein') : l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein');
	let notification = null;
	if (invalidInputs.length > 0) {
		const invalidNotificationMessage = invalidInputs.join(l10nContext.translate('common.text.and', ' und '))
			+ checkPlural;
		notification = (
			<NotificationBar
				level={NotificationLevel.LEVEL_ERROR}
				message={invalidNotificationMessage}
			/>
		);
	}

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<NotificationBars>
				{notification}
			</NotificationBars>
			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('reports.name', 'Name')}*`}
				>
					<InputText
						type="text"
						defaultValue={report?.Name ?? ''}
						onChange={(event) => onChangeReportProp('Name', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('reports.periodDateStart', 'Startdatum')}*`}>
					<DatePicker
						defaultValue={report?.PeriodDateStart}
						onChange={(event) => onChangeReportProp('PeriodDateStart', event.target.valueAsDate)}
						required
						// max={report?.PeriodDateEnd}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('reports.periodDateEnd', 'Enddatum')}*`}>
					<DatePicker
						defaultValue={report?.PeriodDateEnd}
						onChange={(event) => onChangeReportProp('PeriodDateEnd', event.target.valueAsDate)}
						required
						// max={report?.PeriodDateStart}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('reports.validUntil', 'Gültig bis')}*`}>
					<DatePicker
						defaultValue={report?.ValidUntil}
						onChange={(event) => onChangeReportProp('ValidUntil', event.target.valueAsDate)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('reports.password', 'Passwort')}*`}
				>
					<InputText
						type="text"
						defaultValue={report?.Password ?? ''}
						onChange={(event) => onChangeReportProp('Password', event.target.value)}
						minLength={6}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
