import { CommentOutput } from 'services/device/domain/business/common/Output';
import { LabelRenderer } from 'services/device/presentation/ui/widget/label-renderer/LabelRenderer';
import { CommentOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/CommentOutputValueRenderer';

export interface CommentOutputProps {
	output: CommentOutput;
}

export const CommentOutputRenderer = (props: CommentOutputProps): JSX.Element => {
	const { output } = props;

	return (
		<li key={output.getName()}>
			<strong>
				<LabelRenderer output={output} />
			</strong>
			<p>
				<CommentOutputValueRenderer output={output} />
			</p>
		</li>
	);

};
