import { Literal } from "../value/Literal";
import { Value } from "../value/Value";

export const BA_133: Value<number> = new Literal<number>(92043.0);

export const CO_57: Value<number> = new Literal<number>(6484.2102);

export const CS_137: Value<number> = new Literal<number>(263856.6);

export const F_9: Value<number> = new Literal<number>(1.8333);

export const I_129: Value<number> = new Literal<number>(140256000000);

export const SR_90: Value<number> = new Literal<number>(182157.48);

export const TC_99_M: Value<number> = new Literal<number>(6.006);
