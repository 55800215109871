import { L10nContext } from 'context/L10nContext';
import { DatePicker } from 'presentation/ui/partials/input/date-picker/DatePicker';
import React, { useContext } from 'react';
import { SequencePeriodContext } from 'services/device/presentation/ui/sequence-records/context/SequencePeriodContext';
import { AuthContext } from '../../../../core/context/AuthContext';

import './sequence-period-selection.scss';

export const SequencePeriodSelection = (): JSX.Element | null => {

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);
	const sequencePeriodContext = useContext(SequencePeriodContext);
	const userUuid = authContext.getActor().Uuid;

	const setSequencePeriodToLocalStorage = (dateStart?: Date, dateEnd?: Date): void => {
		const sequencePeriodSelectionDates = JSON.stringify({
			dateStart,
			dateEnd
		});

		localStorage.setItem(`private_${userUuid}_sequencePeriodSelection`, sequencePeriodSelectionDates);
	};

	const handleDateStartChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const dateStart = new Date((event.target.valueAsDate)?.setHours(0, 0, 0));
		sequencePeriodContext.setDateStart(dateStart);
		setSequencePeriodToLocalStorage(dateStart, sequencePeriodContext.dateEnd);
	};

	const handleDateEndChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const dateEnd = new Date((event.target.valueAsDate)?.setHours(23, 59, 59));
		sequencePeriodContext.setDateEnd(dateEnd);
		setSequencePeriodToLocalStorage(sequencePeriodContext.dateStart, dateEnd);
	};

	return (
		<div className="sequence-period-selection">

			<div className="sequence-period-selection__input">
				<label className="sequence-period-selection__input__label">
					<span>{l10nContext.translate('sequence.periodSelection.periodFrom', 'Zeitraum von')}</span>
					<DatePicker
						defaultValue={sequencePeriodContext.dateStart}
						onChange={(event) => {
							handleDateStartChange(event);
						}}
						max={sequencePeriodContext.dateEnd}
					/>
				</label>
			</div>

			<div className="sequence-period-selection__input">
				<label className="sequence-period-selection__input__label">
					<span>{l10nContext.translate('sequence.periodSelection.periodTo', 'bis')}</span>
					<DatePicker
						defaultValue={sequencePeriodContext.dateEnd}
						onChange={(event) => {
							handleDateEndChange(event);
						}}
						min={sequencePeriodContext.dateStart}
					/>
				</label>
			</div>

		</div>
	);
};
