import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { ExternalHttpPersistence } from 'lib/persistence/http/ExternalHttpPersistence';

import {
	ExternalReportModelConverter as ModelConverter,
	ExternalReportPersistenceModel as PersistenceModel,
	ExternalReportStoreModel as StoreModel,
	ExternalReportViewModel as ViewModel,
	ExternalCreatableReportViewModel as CreatableViewModel
} from 'services/report/domain/model/ExternalReportModel';

export class ExternalReportHttpPersistence extends ExternalHttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'report/report-api/v1';
	protected collectionControllerUri = 'reports';
	protected entityControllerUri = 'report';

	protected responseCollectionKey = 'ReportModelCollection';
	protected responseEntityKey = 'ReportModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
