import SVGSpritesheet from 'assets/svgsprite.svg';

export interface SVGAssetProps {
	name: string;
}

export const SVGAsset = ({ name }: SVGAssetProps): JSX.Element => (
	<svg>
		<use href={`${SVGSpritesheet}#${name}`} />
	</svg>
);
