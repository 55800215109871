import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { CreatableMaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogEntryModelState } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModelState';
import { createMaintenanceLogEntry } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { MaintenanceLogCreateFormStep1 } from './MaintenanceLogCreateFormStep1';
import { MaintenanceLogCreateFormStep2 } from './MaintenanceLogCreateFormStep2';

export interface MaintenanceLogCreateFormProps {
	device?: DeviceViewModel
}

export const MaintenanceLogCreateForm = (props: MaintenanceLogCreateFormProps): JSX.Element => {
	const { device } = props;

	const [modalStep, setmodalStep] = useState<number>(1);

	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const authContext = useContext(AuthContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	const newMaintenanceLogEntryViewModel = useRef<CreatableMaintenanceLogEntryViewModel>({
		IncidentDateStart: new Date()
	} as CreatableMaintenanceLogEntryViewModel);

	const requiredInputs = ['IncidentTitle', 'IncidentCause'];

	const onChangeMaintenanceLogEntryProp = (prop: string, value: any) => {
		newMaintenanceLogEntryViewModel.current[prop] = value;
	};

	const handleCreate = (): void => {

		newMaintenanceLogEntryViewModel.current.Client = clientContext.selectedClientUuid;
		newMaintenanceLogEntryViewModel.current.Facility = facilityContext.selectedFacilityUuid;
		newMaintenanceLogEntryViewModel.current.Reporter = authContext.getActor().Uuid;
		newMaintenanceLogEntryViewModel.current.ReporterName = authContext.getActor().Realname;
		newMaintenanceLogEntryViewModel.current.State = MaintenanceLogEntryModelState.ONGOING;
		newMaintenanceLogEntryViewModel.current.Device = device?.Uuid ?? null;

		dispatch(createMaintenanceLogEntry(newMaintenanceLogEntryViewModel.current));
	};


	const previousStep = () => {
		setmodalStep(modalStep - 1);
		setInvalidInputs([]);
	};

	const nextStep = () => {
		let newMaintenanceLogEntryModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(newMaintenanceLogEntryViewModel.current?.[requiredInput] ?? null) === null
				|| newMaintenanceLogEntryViewModel.current?.[requiredInput] === ''
				|| String(newMaintenanceLogEntryViewModel.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newMaintenanceLogEntryModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		if (newMaintenanceLogEntryModelValid) {
			setmodalStep(modalStep + 1);
		} else {
			setmodalStep(modalStep);
		}
	};

	const formStep = () => {
		let currentStep: JSX.Element = null;

		switch (modalStep) {
			case 1:
				currentStep =
					<MaintenanceLogCreateFormStep1
						maintenanceLogEntry={newMaintenanceLogEntryViewModel.current}
						onClickNext={nextStep}
						onChange={onChangeMaintenanceLogEntryProp}
						invalidInputs={invalidInputs}
					/>;
				break;

			case 2:
				currentStep =
					<MaintenanceLogCreateFormStep2
						onClickPrevious={previousStep}
						onClickConfirm={handleCreate}
						maintenanceLogEntry={newMaintenanceLogEntryViewModel.current}
					/>;
				break;
		}

		return currentStep;
	};


	return (
		<>
			{formStep()}
		</>
	);
};
