import { L10nContext } from 'context/L10nContext';

import { Guid } from 'lib/guid/Guid';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Interaction } from 'services/device/domain/business/common/interaction/Interaction';
import { Record } from 'services/device/domain/business/common/record/Record';
import { Violation } from 'services/device/domain/business/common/violation/Violation';
import { getCreateRecordInteraction } from 'services/device/domain/business/util/SequenceUtil';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { WidgetsRenderer } from 'services/device/presentation/ui/widget/WidgetsRenderer';
import { createRecord } from 'services/device/store/recordSlice';

export interface RecordCreateFormProps {
	sequence: SequenceViewModel;
	showAddRecord?: boolean;
	setShowAddRecord?: (arg: boolean) => void;
	onError: (error: Error) => void;
}

/**
 * The sequence create form
 */
export const RecordCreateForm = (props: RecordCreateFormProps): JSX.Element => {

	const { sequence, showAddRecord, setShowAddRecord, onError } = props;

	// Consume the context
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	if (showAddRecord) {
		setShowAddRecord(false);
	}

	// Create state for the sequence interactions
	const [recordInteraction, setRecordInteraction] = useState<Interaction<Record>>(null);
	const [validationViolations, setValidationViolations] = useState<ReadonlyArray<Violation>>([]);

	// Create an interaction if device is available and no interaction was already created
	useEffect(() => {
		const prepareInteraction = async (): Promise<void> => {
			setRecordInteraction(getCreateRecordInteraction(sequence));
		};
		if (recordInteraction === null) {
			void prepareInteraction();
		}
	}, [clientContext, sequence, recordInteraction]);

	const handlePrev = (): void => {
		try {
			setValidationViolations([]);
			setRecordInteraction(recordInteraction.previous());
		} catch (error) {
			onError(error);
		}
	};

	const handleNext = (): void => {
		try {
			const validationErrors = recordInteraction.validate();
			if (validationErrors.length > 0) {
				setValidationViolations(validationErrors);
			} else {
				setValidationViolations([]);
				setRecordInteraction(recordInteraction.next());
			}
		} catch (error) {
			onError(error);
		}
	};

	const handleSave = (): void => {
		const validationErrors = recordInteraction.validate();
		if (validationErrors.length > 0) {
			setValidationViolations(validationErrors);
		} else {
			setValidationViolations([]);
			try {
				const record = recordInteraction.finish();
				const recordViewData = record.toViewData();
				dispatch(createRecord({
					Client: clientContext.selectedClientUuid,
					Facility: facilityContext.selectedFacilityUuid,
					Device: sequence.Device,
					DeviceType: sequence.DeviceType,
					Sequence: sequence.Uuid,
					SequenceType: sequence.Type,
					RecordedByName: authContext.getActor()?.Realname,
					RecordConfiguration: recordViewData
				}));
			} catch (error) {
				onError(error);
			}
		}
	};

	const handleFormSubmit = (): void => {
		if (recordInteraction === null) {
			return;
		}
		if (recordInteraction.isLast()) {
			handleSave();
		} else {
			handleNext();
		}
	};

	const renderValidationViolations = (): Array<JSX.Element> => {
		return validationViolations.map<JSX.Element>((violation) => {
			return (
				<NotificationBar
					key={violation.getMessageKey()}
					message={l10nContext.translate(violation.getMessageKey())}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		});
	};

	const renderRecordCreateInteraction = (): JSX.Element => {
		if (recordInteraction === null) {
			return null;
		}
		return (
			<WidgetsRenderer key="widgets" widgets={recordInteraction.getWidgets()} sequenceViewModel={sequence} />
		);
	};

	const renderRecordCreateButtons = (): JSX.Element => {
		if (recordInteraction === null) {
			return null;
		}

		let leftButton = null;
		if (!recordInteraction.isFirst()) {
			leftButton = (
				<ButtonSecondary
					buttonText={l10nContext.translate('common.button.back', 'Zurück')}
					onClick={handlePrev}
				/>
			);
		}

		let rightButton;
		if (recordInteraction.isLast()) {
			rightButton = (
				<ButtonPrimary
					buttonText={l10nContext.translate('common.button.save', 'Speichern')}
					type="submit"
				/>
			);
		} else {
			rightButton = (
				<ButtonPrimary
					buttonText={l10nContext.translate('common.button.next', 'Weiter')}
					type="submit"
				/>
			);
		}

		return (
			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						{leftButton}
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						{rightButton}
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		);
	};

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} key={Guid.generate()} onSubmit={handleFormSubmit}>
			{validationViolations.length !== 0 ?
				<NotificationBars>
					{renderValidationViolations()}
				</NotificationBars> : null}
			{renderRecordCreateInteraction()}
			{renderRecordCreateButtons()}
		</FormLayout>
	);
};
