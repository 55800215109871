import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { DateTimePicker } from 'presentation/ui/compositions/date-time-picker/DateTimePicker';
import { InputText } from '../../../../../../presentation/ui/partials/input/input-text/InputText';

export interface GeneratorDispatchModalStep1Props {
	/**
	 * Generator
	 */
	generator: GeneratorViewModel;
	/**
	 * Button next action
	 */
	onClickNext: () => void;
	/**
	 * Invalid inputs
	 */
	invalidInputs?: string[];
	/**
	 * Change generator props
	 */
	onChangeGeneratorProp: (prop: string, value: Date | number ) => void;
}

/**
 * The generator create modal step 1
 */
export const GeneratorDispatchFormStep1 = (props: GeneratorDispatchModalStep1Props): JSX.Element => {
	const { generator, invalidInputs = [], onChangeGeneratorProp, onClickNext } = props;

	// Consume the contexts
	const l10nContext = useContext(L10nContext);

	// Notification for invalid input fields
	const checkPlural = invalidInputs.length > 1 ? l10nContext.translate('common.modal.notificationErrorPlural', ' dürfen nicht leer sein') : l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein');
	let notification = null;
	if (invalidInputs.length > 0) {
		const invalidNotificationMessage = invalidInputs.join(l10nContext.translate('common.text.and', ' und '))
			+ checkPlural;
		notification = (
			<NotificationBar
				level={NotificationLevel.LEVEL_ERROR}
				message={invalidNotificationMessage}
			/>
		);
	}

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>

			<NotificationBars>
				<NotificationBar
					message={l10nContext.translate('dispatchedDoseRateHint', 'Die Dosisleistung darf bei Abgang 5µSV/h nicht überschreiten')}
					level={NotificationLevel.LEVEL_NOTIFICATION}
				/>
			</NotificationBars>

			<NotificationBars>
				{notification}
			</NotificationBars>



			<FormLayoutSection>
				<Label label={`${l10nContext.translate('generators.dispatchDate', 'Datum Abgang')}*`}>
					<DateTimePicker
						name='dispatchDatetime'
						defaultValue={(generator?.DispatchDatetime) ?? new Date()}
						onChange={(event) => onChangeGeneratorProp('DispatchDatetime', event)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.dispatchedSurfaceContaminationWithUnit', 'Oberflächenkontamination Abgang in Bq/cm²')}`}
				>
					<InputText
						type='number'
						defaultValue={generator?.DispatchedSurfaceContamination ?? ''}
						onChange={(event) => onChangeGeneratorProp('DispatchedSurfaceContamination', Number(event.target.value))}
						step='any'
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.dispatchedDoseRateWithUnit', 'Dosisleistung Abgang in µSV/h')}`}
				>
					<InputText
						type='number'
						defaultValue={generator?.DispatchedDoseRate ?? ''}
						onChange={(event) => onChangeGeneratorProp('DispatchedDoseRate', Number(event.target.value))}
						step='any'
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
