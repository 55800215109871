import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { WasteManagementReportViewModel } from '../../../../domain/models/WasteManagementReportModel';
import { WasteManagementGeneratorReportUpdateForm } from '../waste-management-generator-report-update-form/WasteManagementGeneratorReportUpdateForm';
import { WasteManagementGeneratorReportUpdateFailedNotification } from './WasteManagementGeneratorReportUpdateFailedNotification';
import { WasteManagementGeneratorReportUpdateSuccessNotification } from './WasteManagementGeneratorReportUpdateSuccessNotification';

export interface WasteManagementReportUpdateModalProps {
	report: WasteManagementReportViewModel
	onDismiss?: () => void;
}

export const WasteManagementGeneratorReportUpdateModal = (props: WasteManagementReportUpdateModalProps): JSX.Element => {
	const { report, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const reportActionStatus = useTypedSelector(state => state.wasteManagementReports.actionStatus);

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (reportActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent = <WasteManagementGeneratorReportUpdateForm report={report} />;
				break;

			case AsyncReducerStatus.UPDATED:
				modalContent = <WasteManagementGeneratorReportUpdateSuccessNotification onClickConfirm={onDismiss} />;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <WasteManagementGeneratorReportUpdateFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.wastemanagementReports.modal.action.update.caption', 'Jahresmeldung bearbeiten')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
