import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	ContainerDispatchModelConverter as ModelConverter,
	ContainerDispatchPersistenceModel as PersistenceModel,
	ContainerDispatchStoreModel as StoreModel,
	ContainerDispatchViewModel as ViewModel,
	CreatableContainerDispatchViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/ContainerDispatchModel';

export class ContainerDispatchHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'waste-management/api/v1';
	protected collectionControllerUri = 'container-dispatches';
	protected entityControllerUri = 'container-dispatch';

	protected responseCollectionKey = 'ContainerDispatchModelCollection';
	protected responseEntityKey = 'ContainerDispatchModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
