export type ValueWriteConversion<From, To> = (value: From) => To;
export type ValueReadConversion<From, To> = (value: From) => To;

export abstract class LocalStorage<StoreKey extends string> {

	read<T, StorageT = T>(key: StoreKey, conversion: ValueReadConversion<StorageT, T> = null): T | null {
		const value = localStorage.getItem(this.buildKey(key));
		let data = JSON.parse(value);
		if (conversion !== null) {
			data = conversion(data);
		}
		return data as T ?? null;
	}

	write<T, StorageT = T>(key: StoreKey, value: T, conversion: ValueWriteConversion<T, StorageT> = null): void {
		let data;
		if (conversion !== null) {
			data = conversion(value);
		} else {
			data = value;
		}
		localStorage.setItem(this.buildKey(key), JSON.stringify(data));
	}

	remove(key: StoreKey): void {
		localStorage.removeItem(this.buildKey(key));
	}

	clear(): void {
		localStorage.clear();
	}

	protected abstract buildKey(key: StoreKey): string;

}
