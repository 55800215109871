import { FunctionComponent } from 'react';

import './card-numeric-batch-collection.scss';

/**
 * The numeric batch card collection component
 */
export const CardNumericBatchCollection: FunctionComponent = ({ children }): JSX.Element => (
	<div className='card-numeric-batch-collection'>
		{children}
	</div>
);
