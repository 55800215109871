import { SequenceFieldSet } from '../field-set/SequenceFieldSet';
import { ContextFactory } from '../misc/ContextFactory';
import { Environment } from '../misc/Environment';
import { Step } from '../misc/Step';
import { Sequence } from '../sequence/Sequence';
import { SequenceStoreData, SequenceViewData } from '../sequence/SequenceData';
import { Valuation } from '../Valuation';
import { AbstractInteraction } from './AbstractInteraction';

export class SequenceInteraction<Context, Fields, FieldSet extends SequenceFieldSet<Fields>> extends AbstractInteraction<Context, Fields, FieldSet, Sequence> {
	private readonly environment: Environment;

	private readonly contextFactory: ContextFactory<Context, Fields>;

	public constructor(
		environment: Environment,
		contextFactory: ContextFactory<Context, Fields>,
		fieldSet: FieldSet,
		steps: ReadonlyArray<Step<Context, Fields>>,
		step: number = 0
	) {
		super(contextFactory(environment, fieldSet.getRawFields()), environment, fieldSet, steps, step);
		this.environment = environment;
		this.contextFactory = contextFactory;
	}

	protected createSibling(fieldSet: FieldSet, steps: ReadonlyArray<Step<Context, Fields>>, newStep: number): this {
		return new SequenceInteraction<Context, Fields, FieldSet>(
			this.environment,
			this.contextFactory,
			fieldSet,
			steps,
			newStep
		) as this;
	}

	protected createResult(fieldSet: FieldSet, valuation: Valuation): Sequence {
		return new Sequence(this.environment.deviceType, this.environment.sequenceType, fieldSet);
	}

	public toStoreData(): SequenceStoreData {
		return this.getFieldSet().toStoreData(false);
	}

	public toViewData(): SequenceViewData {
		return this.getFieldSet().toViewData(this.environment.deviceType, this.environment.sequenceType, false);
	}
}
