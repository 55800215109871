import { Guid } from 'lib/guid/Guid';
import { useContext } from 'react';

import { ClassName } from 'lib/class-name/ClassName';

import { L10nContext } from 'context/L10nContext';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ChartDescription } from 'services/device/domain/business/common/description/ChartDescription';
import { NumberFieldDescription } from 'services/device/domain/business/common/description/FieldDescription';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { UnitUtil } from 'services/device/domain/business/util/UnitUtil';

import './sequence-chart-overlay.scss';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { ReportRecordViewModel } from '../../../../../report/domain/model/ReportRecordModel';

export interface ExternalReportSequenceChartOverlayProps {
	sequence: SequenceViewModel;
	record: ReportRecordViewModel;
	chartDescription: ChartDescription;
	onDismiss: () => void;
	onDetails: (record: ReportRecordViewModel) => void;
}

export const ExternalReportSequenceChartOverlay = (props: ExternalReportSequenceChartOverlayProps): JSX.Element => {

	const { chartDescription, onDismiss, onDetails } = props;
	const sequenceViewModel = props.sequence;
	const recordViewModel = props.record;

	// Setup l10n properties
	const l10nContext = useContext(L10nContext);

	// Prepare the sequence type defninition and the high level record
	const sequenceTypeDfinition = getSequenceTypeDefinition(sequenceViewModel.Type);
	const record = sequenceTypeDfinition.restoreRecordFromViewData(recordViewModel.RecordConfiguration);

	const unitUtil = new UnitUtil(sequenceViewModel);
	const valueDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(chartDescription.value?.unit);
	const expectedValueDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(chartDescription.expectedValue?.unit);
	const deviationValueDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(chartDescription.deviation?.unit);

	const value = record.getOutput(chartDescription.value).getSingle() as number;
	const valueNumberOfDeciamsl = chartDescription?.value?.numberOfDecimals ?? 2;
	const formattedValue = l10nContext.formatNumber(value, valueNumberOfDeciamsl);

	let formattedExpectedValue: string = null;
	if (chartDescription.expectedValue !== null) {
		const expectedValue = record.getOutput(chartDescription.expectedValue).getSingle() as number;
		const numberOfDecimals = chartDescription?.expectedValue?.numberOfDecimals ?? 2;
		formattedExpectedValue = l10nContext.formatNumber(expectedValue, numberOfDecimals);
	}

	let formattedAbsoluteDeviation: string = null;
	let formattedRelativeDeviation: string = null;
	if (chartDescription.deviation !== null) {
		const deviationValue = record.getOutput(chartDescription.deviation).getSingle() as number;
		const numberOfDecimals = chartDescription?.deviation?.numberOfDecimals ?? 2;
		formattedAbsoluteDeviation = l10nContext.formatNumber(deviationValue, numberOfDecimals);
		if (deviationValue > 0) {
			formattedAbsoluteDeviation = '+' + formattedAbsoluteDeviation;
		}

		const relativeDeviation = (deviationValue / value) * 100;
		formattedRelativeDeviation = l10nContext.formatNumber(relativeDeviation, 2);
		if (deviationValue > 0) {
			formattedRelativeDeviation = '+' + formattedRelativeDeviation;
		}
	}

	const renderThresholdValue = (fieldDescription: NumberFieldDescription): string => {
		const thresholdValue = record.getOutput(fieldDescription).getSingle() as number;
		const numberOfDecimals = fieldDescription?.numberOfDecimals ?? 2;
		const formattedThresholdValue = l10nContext.formatNumber(thresholdValue, numberOfDecimals);
		const thresholdDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(fieldDescription?.unit);
		return formattedThresholdValue + ' ' + thresholdDisplayUnit;
	};

	const renderSoftThreshold = (): JSX.Element | null => {
		const label = l10nContext.translate('sequence.chart.sequenceLabel.softThreshold.shortLabel', 'RS');
		const formattedThresholds = [];
		if ((chartDescription?.lowerReactionValue ?? null) !== null) {
			formattedThresholds.push(renderThresholdValue(chartDescription.lowerReactionValue));
		}
		if ((chartDescription?.upperReactionValue ?? null) !== null) {
			formattedThresholds.push(renderThresholdValue(chartDescription.upperReactionValue));
		}
		if (formattedThresholds.length === 0) {
			return null;
		}
		const formattedThresholdElements = formattedThresholds.map((formattedThreshold: string): JSX.Element => {
			return (<li key={Guid.generate()}>{formattedThreshold}</li>);
		});
		return (
			<div className="sequence-chart-overlay__property">
				<label className="sequence-chart-overlay__property__label">{label}</label>
				<ul className="sequence-chart-overlay__property__value sequence-chart-overlay__property__value--soft-threshold">
					{formattedThresholdElements}
				</ul>
			</div>
		);
	};

	const renderHardThreshold = (): JSX.Element | null => {
		const label = l10nContext.translate('sequence.chart.sequenceLabel.hardThreshold.shortLabel', 'RS');
		const formattedThresholds = [];
		if ((chartDescription?.lowerToleranceValue ?? null) !== null) {
			formattedThresholds.push(renderThresholdValue(chartDescription.lowerToleranceValue));
		}
		if ((chartDescription?.upperToleranceValue ?? null) !== null) {
			formattedThresholds.push(renderThresholdValue(chartDescription.upperToleranceValue));
		}
		if (formattedThresholds.length === 0) {
			return null;
		}
		const formattedThresholdElements = formattedThresholds.map((formattedThreshold: string): JSX.Element => {
			return (<li key={Guid.generate()}>{formattedThreshold}</li>);
		});
		return (
			<div className="sequence-chart-overlay__property">
				<label className="sequence-chart-overlay__property__label">{label}</label>
				<ul className="sequence-chart-overlay__property__value sequence-chart-overlay__property__value--hard-threshold">
					{formattedThresholdElements}
				</ul>
			</div>
		);
	};

	const renderComment = (): JSX.Element | null => {
		const comment = recordViewModel.RecordConfiguration.values.comment ?? null;
		if (comment === null || comment.trim() === '') {
			return null;
		}
		return (
			<div className="sequence-chart-overlay__property sequence-chart-overlay__property--full-width">
				<label className="sequence-chart-overlay__property__label">
					{l10nContext.translate(chartDescription.comment.labelKey)}
				</label>
				<span className="sequence-chart-overlay__property__value">
					{recordViewModel.RecordConfiguration.values.comment}
				</span>
			</div>
		);
	};

	/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
	return (
		<div className="sequence-chart-overlay">
			<div className="sequence-chart-overlay__trigger">
				<ButtonIcon
					icon={IconIdentifier.TABLE}
					weight={ButtonIconWeight.GHOST}
					shape={ButtonIconShape.ROUND}
					onClick={(event) => {
						event.stopPropagation();
						onDetails(recordViewModel);
					}}
				/>
			</div>
			<div
				className="sequence-chart-overlay__properties"
				role="dialog"
				onClick={(event) => {
					event.stopPropagation();
					onDismiss();
				}}
			>
				<div className="sequence-chart-overlay__property">
					<label className="sequence-chart-overlay__property__label">
						{l10nContext.translate(chartDescription.value.labelKey)}
					</label>
					<span className={`sequence-chart-overlay__property__value sequence-chart-overlay__property__value--record-${ClassName.fromEnumValue(record.toValuation())}`}>
						{formattedValue} {valueDisplayUnit}
					</span>
				</div>
				<div className="sequence-chart-overlay__property">
					<label className="sequence-chart-overlay__property__label">
						{l10nContext.translate(chartDescription.deviation.labelKey)}
					</label>
					<span className="sequence-chart-overlay__property__value">
						{formattedAbsoluteDeviation} {deviationValueDisplayUnit}
						<br />
						<small>
							({formattedRelativeDeviation}%)
						</small>
					</span>
				</div>
				<div className="sequence-chart-overlay__property">
					<label className="sequence-chart-overlay__property__label">
						{l10nContext.translate(chartDescription.expectedValue.labelKey)}
					</label>
					<span className="sequence-chart-overlay__property__value">{formattedExpectedValue} {expectedValueDisplayUnit}</span>
				</div>
				{renderSoftThreshold()}
				{renderHardThreshold()}
				<div className="sequence-chart-overlay__property">
					<label className="sequence-chart-overlay__property__label">
						{l10nContext.translate('sequence.chart.overlay.recordedAt', 'Zeitpunkt')}
					</label>
					<span className="sequence-chart-overlay__property__value">
						{l10nContext.formatDate(recordViewModel.RecordedAt)}
						<br />
						{l10nContext.formatTime(recordViewModel.RecordedAt)}
					</span>
				</div>
				{renderComment()}
			</div>
		</div>
	);

};
