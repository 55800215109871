import { useContext } from 'react';

import { AuthContext } from 'services/core/context/AuthContext';
import { FacilityContext } from 'services/core/context/FacilityContext';

import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';

export const MaintenanceLogTopbar = (): JSX.Element => {
	const authContext = useContext(AuthContext);
	const facilityContext = useContext(FacilityContext);

	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name
	};

	return (
		<TopbarLayoutSection>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
				<NavigationSecondary>
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</ColumnLayout>
		</TopbarLayoutSection>
	);
};
