import { FunctionComponent, useCallback } from 'react';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';

import './card-cyclotron-product-list-item.scss';

export interface CardCyclotronProductListItemProps {
	/**
	 * (Optional) Card Id
	 */
	cardId?: string;
	/**
	 * (Optional) Disabled state
	 */
	disabled?: boolean;
	/**
	 * (Optional) onClick-handler
	 */
	onClick?: (action: CardItemControlActionType) => void;
	/**
	 * (Optional) CompletelyApplied
	 */
	completelyApplied?: boolean;
}

/**
 * The cyclotron product list item card component
 */
export const CardCyclotronProductListItem: FunctionComponent<CardCyclotronProductListItemProps> = (props): JSX.Element => {
	const { cardId, onClick, disabled, children, completelyApplied = false } = props;
	
	const onCardClickHandler = useCallback(() => {
		if (!onClick || disabled) return;

		onClick({
			type: 'show',
			cardId
		});
	}, [onClick, cardId, disabled]);

	const componentDisabledStyle = disabled ?
		' card-cyclotron-product-list-item--disabled' :
		'';

	const componentCompletelyAppliedStyle = completelyApplied ?
		' card-cyclotron-product-list-item--completely-applied' :
		'';

	return (
		<div className={`card-cyclotron-product-list-item${componentDisabledStyle}${componentCompletelyAppliedStyle}`}>
			<div
				className='card-cyclotron-product-list-item__content'
				onClick={onCardClickHandler}
				role='button'
				tabIndex={0}
			>
				{children}
			</div>
		</div>
	);
};
