import { L10nContext } from 'context/L10nContext';
import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectCreatedCyclotronProduct } from 'services/nuclide/store/cyclotronProductSlice';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { CyclotronProductCreateForm } from '../cyclotron-product-create-form/CyclotronProductCreateForm';
import { CyclotronProductCreateFailedNotification } from './CyclotronProductCreateFailedNotification';
import { CyclotronProductCreateSuccessNotification } from './CyclotronProductCreateSuccessNotification';

interface CyclotronProductCreateModalProps {
	/**
	 * onDismiss
	 */
	onDismiss: () => void;
}

/**
 * The cyclotron product create modal
 */
export const CyclotronProductCreateModal = (props: CyclotronProductCreateModalProps): JSX.Element => {
	const { onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const createdCyclotronProduct = useSelector(selectCreatedCyclotronProduct());
	const cyclotronProductActionStatus = useTypedSelector(state => state.cyclotronProducts.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (cyclotronProductActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <CyclotronProductCreateForm />;
				break;

			case AsyncReducerStatus.CREATED:
				modal =
					<CyclotronProductCreateSuccessNotification
						onClickConfirm={onDismiss}
						cyclotronProductCreated={createdCyclotronProduct}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<CyclotronProductCreateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;

	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.cyclotronProducts.modal.action.create.caption', 'Radionuklid hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
