import { useCallback } from 'react';

import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { Icon, IconColor } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import { CardItemActionType } from '../CardItemActionTypes';

import './card-item-controls.scss';

export interface CardItemControlsProps {
	cardId: string,
	onClick?: (action: CardItemControlActionType) => void,
	actionEdit? : CardItemControlState,
	actionMove?: CardItemControlState,
	actionArchive?: CardItemControlState,
	actionDelete?: CardItemControlState,
	disabled?: boolean,
	onClickToActiveState?: () => void,
	actionToActiveState?: CardItemControlState
}

/**
 * A card item controls component
 */
export const CardItemControls = (props: CardItemControlsProps): JSX.Element => {
	const {
		cardId,
		onClick,
		actionEdit,
		actionMove,
		actionArchive,
		actionDelete,
		actionToActiveState,
		disabled = false
	} = props;

	const createIcon = (name: IconIdentifier, state: CardItemControlState, action: string) => {
		let stateClass = '';

		if (disabled) {
			stateClass = 'control-icon--disabled';
		} else if (state) {
			stateClass = `control-icon--${state.toLowerCase()}`;
		}

		return (
			<Icon
				name={name}
				color={IconColor.PRIMARY_50}
				className={stateClass}
				onClick={() => onClickHandler(action)}
			/>
		);
	};

	const onClickHandler = useCallback((action: string) => {
		if (!onClick) return;

		onClick({
			type: action,
			cardId
		});
	}, [onClick, cardId]);

	const editAction = actionEdit ?
		createIcon(IconIdentifier.EDIT, actionEdit, CardItemActionType.CHANGE) :
		null;

	const moveAction = actionMove ?
		createIcon(IconIdentifier.MOVE, actionMove, CardItemActionType.MOVE) :
		null;

	const archiveAction = actionArchive ?
		createIcon(IconIdentifier.ARCHIVE, actionArchive, CardItemActionType.ARCHIVE) :
		null;

	const deleteAction = actionDelete ?
		createIcon(IconIdentifier.TRASH, actionDelete, CardItemActionType.DELETE) :
		null;

	const toActiveStateAction = actionToActiveState ?
		createIcon(IconIdentifier.IN_WASTE_BACK, actionDelete, CardItemActionType.TO_ACTIVE_STATE) :
		null;

	return (
		<div className='card-item-controls'>
			{editAction}
			{moveAction}
			{archiveAction}
			{deleteAction}
			{toActiveStateAction}
		</div>
	);
};
