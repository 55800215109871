import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import { Badge, BadgeColor } from '../../badge/Badge';

import './button-ghost.scss';

export enum IconPosition {
	LEFT = 'left',
	RIGHT = 'right'
}

export interface ButtonGhostProps extends React.ComponentPropsWithoutRef<'button'> {
	/**
	 * Button text
	 */
	buttonText: string;
	badge?: string;
	/**
	 * Icon
	 */
	icon?: IconIdentifier;
	/**
	 * Icon position
	 */
	iconPosition: IconPosition;
}

export const ButtonGhost = (props: ButtonGhostProps): JSX.Element => {
	const { buttonText, badge = null, icon, iconPosition, ...restProps } = props;
	const buttontext = <span className="button-ghost__text">{buttonText}</span>;

	const buttonBadge = badge !== null ?
		<Badge
			label={badge}
			color={BadgeColor.RED}
		/> :
		null;

	const buttonContent = iconPosition === IconPosition.LEFT ?
		<>
			<Icon name={icon} />
			{buttontext}
			{buttonBadge}
		</>
		:
		<>
			{buttontext}
			<Icon name={icon} />
			{buttonBadge}
		</>;

	return (
		<div className="button-ghost">
			<button
				className={`button-ghost__button ${icon} ${iconPosition}`}
				type="button"
				{...restProps}
			>
				{buttonContent}
			</button>
		</div>
	);
};
