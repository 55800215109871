import { Component, Context, ErrorInfo, ReactNode } from 'react';

import { L10nContext } from 'context/L10nContext';

import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import { LoginView } from 'services/core/presentation/view/LoginView';

import { Error } from 'presentation/ui/components/error/Error';

interface ErrorState {
	error: Error | PropellerError;
}

export class AuthErrorBoundary extends Component<{ children: ReactNode }, ErrorState> {

	// eslint-disable-next-line react/static-property-placement
	static contextType: Context<any> = L10nContext;

	constructor(props: any) {
		super(props);
		this.state = { error: null };
	}

	static getDerivedStateFromError(error: Error | PropellerError): ErrorState {
		// Update state so the next render will show the fallback UI.
		return { error };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
	}

	render(): JSX.Element | ReactNode {
		if (this.state.error !== null) {
			if ((this.state.error as PropellerError)?.code === '401' || (this.state.error as PropellerError)?.code === '402') {
				return (<LoginView />);
			}
			return (<Error error={this.state.error} />);
		}
		return this.props.children;
	}

}
