import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { L10nContext } from '../../../../context/L10nContext';
import { Guid } from '../../../../lib/guid/Guid';
import { NavigationSecondary } from '../../../../presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { NotificationBar } from '../../../../presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from '../../../../presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from '../../../../presentation/ui/components/notification-bars/NotificationBars';
import { Footer } from '../../../../presentation/ui/compositions/footer/Footer';
import { ColumnLayoutSection } from '../../../../presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from '../../../../presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from '../../../../presentation/ui/layouts/column-layout/ColumnLayout';
import { MainLayoutSection } from '../../../../presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from '../../../../presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from '../../../../presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from '../../../../presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from '../../../../presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from '../../../../presentation/ui/layouts/view-layout/ViewLayout';
import { ButtonSecondary, ButtonSecondaryStatus } from '../../../../presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from '../../../../presentation/ui/partials/icon/IconIdentifier';
import { Breadcrumbs } from '../../../../presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from '../../../../presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import { AsyncReducerStatus } from '../../../../store/common/AsyncReducerStatus';
import { useTypedSelector } from '../../../../store/common/TypedSelector';
import { AuthContext } from '../../../core/context/AuthContext';
import { FacilityContext } from '../../../core/context/FacilityContext';
import { Permission, permissionGroupWasteManagement } from '../../../core/lib/auth/AuthService';

import { resetActionStatus, selectWasteManagementReportByUuid } from '../../store/wasteManagementReportSlice';
import {
	WasteManagementCyclotronProductReportUpdateModal
} from '../ui/waste-management-report-action/waste-management-cyclotron-product-report-update-modal/WasteManagementCyclotronProductReportUpdateModal';
import {
	WasteManagementGeneratorReportUpdateModal
} from '../ui/waste-management-report-action/waste-management-generator-report-update-modal/WasteManagementGeneratorReportUpdateModal';
import {
	WasteManagementReportDeleteModal
} from '../ui/waste-management-report-action/waste-management-report-delete-modal/WasteManagementReportDeleteModal';
import { WasteManagementReportCard } from '../ui/waste-management-report-card/WasteManagementReportCard';
import { WasteManagementReportViewModalType } from './WasteManagementReportViewModalType';

interface WasteManagementReportViewParams {
	wasteManagementReportUuid: string;
}

export const WasteManagementReportView = ():JSX.Element => {

	const authContext = useContext(AuthContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	const dispatch = useDispatch();

	// Local state
	const [modalType, setModalType] = useState<WasteManagementReportViewModalType>(null);

	// Unwrap the required report uuid from the route
	const params = useParams<WasteManagementReportViewParams>();
	const reportUuid = params?.wasteManagementReportUuid ?? null;
	if (reportUuid === null) {
		throw new Error('Report id is missing');
	}

	const report = useTypedSelector(selectWasteManagementReportByUuid(reportUuid));

	if (!authContext.hasAnyPermission(permissionGroupWasteManagement)) {
		throw new Error('Permission denied');
	}

	const storeActionStatus = useTypedSelector(state => state.wasteManagementReports.actionStatus);
	const storeLastActionError = useTypedSelector(state => state.wasteManagementReports.lastActionError);

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permissionUpdate: authContext.hasPermission(Permission.WASTE_MANAGEMENT_REPORT_UPDATE),
		permissionDelete: authContext.hasPermission(Permission.WASTE_MANAGEMENT_REPORT_DELETE)
	};

	const onModalDismiss = () => {
		setModalType(null);
	};

	const renderNotification = (): Array<JSX.Element> => {
		const notifications = [];

		if (storeActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					key={Guid.generate()}
					message={storeLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		return notifications;
	};

	const modal = (): JSX.Element => {
		let reportModal: JSX.Element = null;

		switch (modalType) {
			case WasteManagementReportViewModalType.EDIT_GENERATOR_REPORT:
				reportModal =
					<WasteManagementGeneratorReportUpdateModal
						report={report}
						onDismiss={onModalDismiss}
					/>;
				break;

			case WasteManagementReportViewModalType.EDIT_CYCLOTRON_PRODUCT_REPORT:
				reportModal =
					<WasteManagementCyclotronProductReportUpdateModal
						report={report}
						onDismiss={onModalDismiss}
					/>;
				break;

			case WasteManagementReportViewModalType.DELETE:
				reportModal =
					<WasteManagementReportDeleteModal
						report={report}
						onDismiss={onModalDismiss}
					/>;
				break;
		}

		return reportModal;
	};

	const onModalTriggerClick = (type: WasteManagementReportViewModalType) => {
		dispatch(resetActionStatus());

		setModalType(type);
	};

	const reportCard =
		<WasteManagementReportCard
			report={report}
			userPermissionEdit={user.permissionUpdate}
			onClick={onModalTriggerClick}
			isOnlyView={false}
		/>;

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs
						mapURLFragments={[[reportUuid, report?.Name]]}
					/>
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection
				section={MainLayoutSectionSection.SECTION_MAIN}
			>
				<ViewLayout>
					<ViewLayoutSection>
						<NotificationBars>
							{renderNotification()}
						</NotificationBars>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{modal()}
						{reportCard}
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
								<ButtonSecondary
									buttonText={l10nContext.translate('common.button.deleteWasteReport', 'Jahresmeldung löschen')}
									icon={IconIdentifier.TRASH}
									status={ButtonSecondaryStatus.ATTENTION}
									onClick={() => onModalTriggerClick(WasteManagementReportViewModalType.DELETE)}
									disabled={!user.permissionDelete}
								/>
							</ColumnLayoutSection>
						</ColumnLayout>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);


};
