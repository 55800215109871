import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';
import { EluateModelState } from './EluateModelState';
import { GeneratorEluateIsotope } from './isotopes/GeneratorEluateIsotope';
import { NuclideModelActivityUnit } from './NuclideModelActivityUnit';

export interface EluatePersistenceModel extends PersistenceModel {
	Generator: string;
	State: string;
	Supplemented: boolean;
	ExtractionDatetime: number;
	Activity: number;
	ActivityUnit: string;
	ExtractedBy: string;
	ExtractedByName: string;
}

export interface EluateStoreModel extends StoreModel {
	Generator: string;
	State: string;
	Supplemented: boolean;
	ExtractionDatetime: number;
	Activity: number;
	ActivityUnit: string;
	ExtractedBy: string;
	ExtractedByName: string;
}

export interface EluateViewModel extends ViewModel {
	Generator: string;
	State: EluateModelState;
	Supplemented: boolean;
	ExtractionDatetime: Date;
	Activity: number;
	ActivityUnit: NuclideModelActivityUnit;
	ExtractedBy: string;
	ExtractedByName: string;
}

export interface CreatableEluateViewModel extends CreatableViewModel {
	Generator: string;
	State: EluateModelState;
	ExtractionDatetime: Date;
	Activity: number;
	ActivityUnit: NuclideModelActivityUnit;
	ExtractedBy: string;
}

// TODO: Create only one Eluate Payload type_ Gallium  Technetium
export interface EluateTechnetiumPayloadType extends EluateViewModel {
	Isotope: GeneratorEluateIsotope.TECHNETIUM_99M_TC
}
export interface EluateGalliumPayloadType extends EluateViewModel {
	Isotope: GeneratorEluateIsotope.GALLIUM_68_GA
}

export type EluatePayloadType = EluateTechnetiumPayloadType | EluateGalliumPayloadType;



export class EluateModelConverter
	extends ModelConverter<EluatePersistenceModel, EluateStoreModel, EluateViewModel, CreatableEluateViewModel> {

	fromStoreModel(storeModel?: EluateStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			model.ExtractionDatetime = Math.floor(sModel.ExtractionDatetime / 1000);
		});
	}

	fromViewModel(viewModel?: EluateViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.ExtractionDatetime = Math.floor(vModel.ExtractionDatetime.getTime() / 1000);
			model.ActivityUnit = vModel?.ActivityUnit as string ?? null;
			model.State = vModel?.State as string ?? null;
		});
	}

	fromCreatableViewModel(creatableViewModel?: CreatableEluateViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			// Supplemented will be changed by the persistence
			model.Supplemented = false;
			model.State = EluateModelState.ACTIVE;
		});
	}

	toStoreModel(): EluateStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.ExtractionDatetime = model.ExtractionDatetime * 1000;
		});
	}

	toViewModel(): EluateViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.ExtractionDatetime = new Date(model.ExtractionDatetime * 1000);
			vModel.ActivityUnit = model?.ActivityUnit as NuclideModelActivityUnit ?? null;
			vModel.State = model?.State as EluateModelState ?? null;
		});
	}
}
