import { createContext } from 'react';

import { JsonWebToken } from 'services/core/lib/auth/AuthService';

interface ExternalAuthContextValue {
	authenticate(password: string): Promise<boolean>;

	jsonWebToken(): JsonWebToken | null;
}

export const ExternalAuthContext = createContext<ExternalAuthContextValue>({
	authenticate: async (): Promise<boolean> => {
		return false;
	},

	jsonWebToken(): JsonWebToken | null {
		return null;
	}
});
