import { Field } from '../field/Field';
import { DeviceType } from '../../inventory/DeviceType';
import { SequenceType } from '../../inventory/SequenceType';
import { Viewables, Storable, StoreData, Viewable, ViewData } from '../Data';
import { Input } from '../Input';
import { Optional } from '../misc/Optional';
import { Output } from '../Output';
import { Valuation, worst } from '../Valuation';

export abstract class AbstractFieldSet<Fields> {
	private readonly fieldsFactory: () => Fields;

	private readonly fields: Fields;

	private readonly valueKey: Optional<string>;

	protected constructor(fieldsFactory: () => Fields, valueKey: Optional<string>) {
		this.fieldsFactory = fieldsFactory;
		this.fields = fieldsFactory();
		this.valueKey = valueKey;
	}

	public getRawFields(): Fields {
		return this.fields;
	}

	public getFieldNames(): ReadonlyArray<string> {
		return Object.getOwnPropertyNames(this.fields);
	}

	public getAllFields(): ReadonlyArray<Field<any, any>> {
		return Object.values(this.fields as Object);
	}

	public getFieldsByName(): ReadonlyMap<string, Field<any, any>> {
		const map: Map<string, Field<any, any>> = new Map();
		this.getAllFields().forEach((field) => {
			map.set(field.getName(), field);
		});
		return map;
	}

	public getField(name: string): Optional<Field<any, any>> {
		const fieldDescriptor: PropertyDescriptor | undefined = Object.getOwnPropertyDescriptor(this.fields, name);
		return fieldDescriptor !== undefined ? (fieldDescriptor.value as Field<any, any>) : null;
	}

	public getInput(name: string): Optional<Input<any>> {
		return this.getField(name);
	}

	public getOutput(name: string): Optional<Output<any>> {
		return this.getField(name);
	}

	public toStoreData(strict: boolean): StoreData {
		const fields: ReadonlyArray<Field<any, any>> = this.getAllFields();
		const values: Record<string, any> = {};
		fields.forEach((field: Field<any, any>) => {
			const name: string = field.getName();
			const storable: Storable = field.toStorable(strict);
			if (field.isStored() && storable !== null) {
				values[name] = storable;
			}
		});
		return values;
	}

	public fromStoreData(storeData: StoreData): this {
		const fields: ReadonlyArray<Field<any, any>> = this.getAllFields();
		fields.forEach((field: Field<any, any>) => {
			const name: string = field.getName();
			const storable: Storable = storeData[name] ?? null;
			if (storable !== undefined) {
				field.fromStoreable(storable);
			}
		});
		return this;
	}

	public toViewData(deviceType: DeviceType, sequenceType: SequenceType, strict: boolean): ViewData {
		const fields: ReadonlyArray<Field<any, any>> = this.getAllFields();
		const values: Viewables<Viewable> = {};
		const valuations: Viewables<Valuation> = {};
		const flippedExtras: Viewables<Viewables<Viewable>> = {};
		fields.forEach((field: Field<any, any>) => {
			const name: string = field.getName();
			const viewable: { value: Viewable; valuation: Valuation; extras: Viewables<Viewable> } = field.toViewable(strict);
			if (viewable.value !== null) {
				values[name] = viewable.value;
				if (viewable.valuation !== Valuation.UNKNOWN) {
					valuations[name] = viewable.valuation;
				}
				if (Object.values(viewable.extras).filter((value) => value !== undefined && value !== null).length !== 0) {
					flippedExtras[name] = viewable.extras;
				}
			}
		});
		const value: Viewable = this.valueKey ? values[this.valueKey] ?? null : null;
		const valuation: Valuation = worst(Object.values(valuations));
		const extras: Viewables<Viewables<Viewable>> = this.flipExtras(flippedExtras);
		return { deviceType, sequenceType, values, value, valuations, valuation, extras };
	}

	public fromViewData(viewData: ViewData): this {
		const fields: ReadonlyArray<Field<any, any>> = this.getAllFields();
		const flippedExtras: Viewables<Viewables<Viewable>> = this.flipExtras(viewData.extras);
		fields.forEach((field: Field<any, any>) => {
			const name: string = field.getName();
			const value: Viewable = viewData.values[name] ?? null;
			const valuation: Valuation = viewData.valuations[name] ?? Valuation.UNKNOWN;
			const extras: Viewables<Viewable> = flippedExtras[name] ?? {};
			if (value !== undefined) {
				field.fromViewable(value, valuation, extras);
			}
		});
		return this;
	}

	public clone(): this {
		const clone: this = this.createClone(this.fieldsFactory, this.valueKey);
		clone.getAllFields().forEach((fieldClone) => {
			const field = this.getField(fieldClone.getName());
			if (field !== null) {
				fieldClone.copy(field);
			}
		});
		return clone;
	}

	protected abstract createClone(fieldsFactory: () => Fields, valueKey: Optional<string>): this;

	private flipExtras(extras: Viewables<Viewables<Viewable>>): Viewables<Viewables<Viewable>> {
		const flippedExtras: Viewables<Viewables<Viewable>> = {};
		Object.entries(extras).forEach(([outerKey, outerValue]) => {
			Object.entries(outerValue).forEach(([innerKey, innerValue]) => {
				if (!flippedExtras[innerKey]) {
					flippedExtras[innerKey] = {};
				}
				flippedExtras[innerKey][outerKey] = innerValue;
			});
		});
		return flippedExtras;
	}
}
