import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { RecordViewModel } from 'services/device/domain/model/RecordModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { SequenceCharts } from 'services/device/presentation/ui/sequence-chart/SequenceCharts';
import { SequencePeriodSelection } from 'services/device/presentation/ui/sequence-period-selection/SequencePeriodSelection';
import { SequencePeriodContextProvider } from 'services/device/presentation/ui/sequence-records/context/SequencePeriodContextProvider';
import { SequenceTable } from 'services/device/presentation/ui/sequence-table/SequenceTable';
import { fetchRecordsBySequence, selectRecordByUuid } from 'services/device/store/recordSlice';
import { ClientContext } from '../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../core/context/FacilityContext';

export interface SequenceRecordsProps {
	device: DeviceViewModel,
	sequence: SequenceViewModel,
	isReportView?: boolean,
	onShowComment: (record: RecordViewModel) => void,
	onAttachments: (record: RecordViewModel) => void,
	onEdit?: (record: RecordViewModel) => void,
	onDelete?: (record: RecordViewModel) => void
}

export const SequenceRecords = (props: SequenceRecordsProps): JSX.Element | null => {
	const { isReportView, onShowComment, onAttachments, onEdit, onDelete } = props;
	const deviceViewModel = props.device;
	const sequenceViewModel = props.sequence;

	const [highlightedRecord, setHighlightedRecord] = useState<RecordViewModel>(null);

	const location = useLocation();
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Read records from the server
	useEffect(() => {
		dispatch(fetchRecordsBySequence({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			sequenceUuid: props.sequence.Uuid
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.sequence.Uuid]);

	const highlightedRecordUuid = location.hash.startsWith('#') ? location.hash.substr(1) : location.hash;
	const initialHighlightedRecordViewModel = useSelector(selectRecordByUuid(sequenceViewModel, highlightedRecordUuid));

	if (initialHighlightedRecordViewModel !== null && highlightedRecord === null) {
		setHighlightedRecord(initialHighlightedRecordViewModel);
	}

	return (
		<SequencePeriodContextProvider>
			{!isReportView ? <SequencePeriodSelection /> : null}

			<SequenceCharts
				sequence={sequenceViewModel}
				showOverlay={true}
				onDetails={setHighlightedRecord}
			/>
			<SequenceTable
				device={deviceViewModel}
				sequence={sequenceViewModel}
				isReportView={isReportView}
				highlightedRecord={highlightedRecord}
				onShowComment={onShowComment}
				onAttachments={onAttachments}
				onEdit={onEdit}
				onDelete={onDelete}
			/>
		</SequencePeriodContextProvider>
	);

};
