import { useContext, useState } from 'react';

import { useDispatch } from 'react-redux';
import { AuthContext } from '../../../../../core/context/AuthContext';

import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { ContainerModelState } from '../../../../domain/model/ContainerModelState';
import { updateContainer } from '../../../../store/containerSlice';
import { ContainerReleaseFormStep1 } from './ContainerReleaseFormStep1';
import { ContainerReleaseFormStep2 } from './ContainerReleaseFormStep2';

export interface ContainerReleaseFormProps {
	container?: ContainerViewModel;
}

export const ContainerReleaseForm = (props: ContainerReleaseFormProps): JSX.Element => {
	const { container } = props;

	// Consume the dispatch object
	const dispatch = useDispatch();

	const authContext = useContext(AuthContext);

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const updatedContainer: ContainerViewModel = container;

	const requiredInputs = ['IsReleasedByRadiationProtectionOfficer'];

	const onChangeContainerProp = (prop: string, value: boolean) => {
		updatedContainer[prop] = value;
	};

	const handleUpdate = (): void => {
		container.ReleasedByRadiationProtectionOfficer = authContext.getActor().Uuid;
		container.ReleasedByRadiationProtectionOfficerName = authContext.getActor().Realname;
		container.ReleasedByRadiationProtectionOfficerDateTime = new Date();
		container.State = ContainerModelState.RELEASE_DATE_REACHED;
		dispatch(updateContainer(updatedContainer));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newContainerModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedContainer?.[requiredInput] ?? null) === null
				|| updatedContainer?.[requiredInput] === ''
				|| String(updatedContainer.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newContainerModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newContainerModelValid);
	};

	const showCreateModalStep = showStep1 === true ?
		<ContainerReleaseFormStep1
			container={updatedContainer}
			onClickNext={goToStep2}
			invalidInputs={invalidInputs}
			onChangeContainerProp={onChangeContainerProp}
		/> :
		<ContainerReleaseFormStep2
			container={updatedContainer}
			onClickPrevious={goToStep1}
			onClickNext={handleUpdate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
