import { CardContainerDetail } from 'presentation/ui/components/cards/card-container/card-container-detail/CardContainerDetail';
import {
	CardItemContainerAttributes
} from 'presentation/ui/partials/card/card-item-attributes/card-item-container-attributes/CardItemContainerAttributes';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemContainerHeader } from 'presentation/ui/partials/card/card-item-header/card-item-container-header/CardItemContainerHeader';
import { useContext } from 'react';
import { ContainerViewModel } from 'services/nuclide/domain/model/ContainerModel';
import { L10nContext } from '../../../../../context/L10nContext';
import {
	CardItemContainerDispatchAttributes
} from '../../../../../presentation/ui/partials/card/card-item-attributes/card-item-container-dispatch-attributes/CardItemContainerDispatchAttributes';
import {
	CardItemContainerDisposeAttributes
} from '../../../../../presentation/ui/partials/card/card-item-attributes/card-item-container-dispose-attributes/CardItemContainerDisposeAttributes';
import {
	CardItemContainerReleaseMeasurementAttributes
} from '../../../../../presentation/ui/partials/card/card-item-attributes/card-item-container-release-measurement-attributes/CardItemContainerReleaseMeasurementAttributes';
import { useTypedSelector } from '../../../../../store/common/TypedSelector';
import { ClientContext } from '../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../core/context/FacilityContext';
import { ContainerModelState } from '../../../domain/model/ContainerModelState';
import { CyclotronProductViewModel } from '../../../domain/model/CyclotronProductModel';
import { EluatePayloadType } from '../../../domain/model/EluateModel';
import { GeneratorEluateIsotope } from '../../../domain/model/isotopes/GeneratorEluateIsotope';
import { GeneratorTypeIsotope } from '../../../domain/model/isotopes/GeneratorTypeIsotope';
import { getNuclideIsotopeDefinition } from '../../../domain/model/isotopes/NuclideIsotopeDefinition';
import { selectInWastemanagementCyclotronProducts } from '../../../store/cyclotronProductSlice';
import { selectInWastemanagementEluates } from '../../../store/eluateSlice';
import { selectGenerators } from '../../../store/generatorSlice';
import { ContainerViewModalType } from '../../view/ContainerViewModalType';

interface ContainerCardProps {
	container: ContainerViewModel;
	userPermissionEdit: boolean,
	onClick: (modalType: ContainerViewModalType) => void;
}

export const ContainerCard = (props: ContainerCardProps): JSX.Element => {
	const { container, userPermissionEdit, onClick } = props;

	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const inWastemanagementCyclotronProducts = useTypedSelector(selectInWastemanagementCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementEluates = useTypedSelector(selectInWastemanagementEluates());

	const generators = useTypedSelector(selectGenerators(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	inWastemanagementEluates.forEach(eluate => {

		const inWastemanagementEluateGenerator = generators.find((generator) => {
			return generator.Uuid === eluate.Generator;
		});
		if (inWastemanagementEluateGenerator.Isotope === GeneratorTypeIsotope.MOLYBDENUM_99_MO) {
			eluate.Isotope = GeneratorEluateIsotope.TECHNETIUM_99M_TC;
		} else {
			eluate.Isotope = GeneratorEluateIsotope.GALLIUM_68_GA;
		}
	});

	const eluatesAndCyclotronProducts: Array<EluatePayloadType | CyclotronProductViewModel> =
		[].concat(inWastemanagementEluates, inWastemanagementCyclotronProducts);

	let containerNuclideIds: string[] = [];
	containerNuclideIds = [].concat(container?.GeneratorEluatIds, container?.CyclotroneProductIds);

	const containerNuclides = eluatesAndCyclotronProducts.filter(nuclideModel => containerNuclideIds.includes(nuclideModel.Uuid));

	const subtitleContainerNuclides: string[] = [];
	containerNuclides.map((nuclide) => {
		if (!subtitleContainerNuclides.includes(l10nContext.translate(getNuclideIsotopeDefinition(nuclide.Isotope).getLabelKey()))) {
			subtitleContainerNuclides.push(l10nContext.translate(getNuclideIsotopeDefinition(nuclide.Isotope).getLabelKey()));
		}
		return subtitleContainerNuclides.sort();
	});

	const showContainerDispatchAttributes = container?.DispatchId !== null ? <CardItemContainerDispatchAttributes container={container} /> : null;

	const showContainerReleaseMeasurementAttributes = container?.State !== ContainerModelState.OPEN ?
		<CardItemContainerReleaseMeasurementAttributes container={container} /> : null;

	const showContainerDisposeAttributes = container?.DisposeId !== null ? <CardItemContainerDisposeAttributes container={container} /> : null;

	return (
		<CardContainerDetail>
			<CardItemContainerHeader
				title={container?.Name}
				subtitle={subtitleContainerNuclides.join(', ')}
				state={container?.State}
			/>
			<CardItemSingleControl
				cardId={container?.Uuid}
				actionEdit={userPermissionEdit ?
					CardItemControlState.ENABLED :
					CardItemControlState.DISABLED}
				onClick={() => onClick(ContainerViewModalType.EDIT)}
			/>
			<CardItemContainerAttributes
				container={container}
			/>
			{ showContainerDispatchAttributes }
			{ showContainerReleaseMeasurementAttributes }
			{ showContainerDisposeAttributes }
		</CardContainerDetail>
	);
};
