import './notification-bars.scss';

export const NotificationBars = (props: any): JSX.Element => {

	return (
		<div className="notification-bars">
			{props.children}
		</div>
	);

};
