import { ComponentPropsWithoutRef } from 'react';

import './checkboxes-layout.scss';

export type CheckboxesLayoutProps = ComponentPropsWithoutRef<'div'>;

export const CheckboxesLayout = (props: CheckboxesLayoutProps): JSX.Element => {

	return (
		<div className='checkboxes-layout'>
			{props.children}
		</div>
	);
};
