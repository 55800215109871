import { TextOutput } from 'services/device/domain/business/common/Output';
import { LabelRenderer } from 'services/device/presentation/ui/widget/label-renderer/LabelRenderer';
import { TextOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/TextOutputValueRenderer';

export interface TextOutputProps {
	output: TextOutput;
}

export const TextOutputRenderer = (props: TextOutputProps): JSX.Element => {
	const { output } = props;

	return (
		<li key={output.getName()}>
			<strong>
				<LabelRenderer output={output} />
			</strong>
			<p>
				<TextOutputValueRenderer output={output} />
			</p>
		</li>
	);

};
