import { L10nContext } from 'context/L10nContext';
import { CardMaintenanceLogList } from 'presentation/ui/components/cards/card-maintenance-log/card-maintenance-log-list/CardMaintenanceLogList';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';

import { CardItemState } from 'presentation/ui/partials/card/card-item-state/CardItemState';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { TagType } from 'presentation/ui/partials/tag/Tag';
import { useContext } from 'react';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogEntryModelState } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModelState';

export interface MaintenanceLogCollectionItemProps {
	device: Readonly<DeviceViewModel>;
	maintenanceLogEntry: Readonly<MaintenanceLogEntryViewModel>;
	onEdit: () => void;
}

export const MaintenanceLogCollectionItem = (props: MaintenanceLogCollectionItemProps): JSX.Element => {
	const { device, maintenanceLogEntry, onEdit } = props;

	const l10nContext = useContext(L10nContext);

	const maintenanceLogEntryStatus = {
		label: maintenanceLogEntry.State === MaintenanceLogEntryModelState.ONGOING ? 'offen' : 'abgeschlossen',
		type: maintenanceLogEntry.State === MaintenanceLogEntryModelState.ONGOING ? TagType.NEGATIVE : TagType.POSITIVE,
		icon: IconIdentifier.EDIT
	};

	const formatedIncidentStart = l10nContext.formatDateTime(maintenanceLogEntry.IncidentDateStart);

	return (
		<CardMaintenanceLogList
			key={maintenanceLogEntry.Uuid}
			cardId="myCardMaintenanceLogEntryListItemActiveCardId"
			button={{
				disabled: device.State === DeviceModelState.ARCHIVED,
				onClick: onEdit,
				icon: IconIdentifier.EDIT
			}}
		>
			<CardItemHeader
				title={maintenanceLogEntry.IncidentTitle}
			/>
			<CardItemState
				date={formatedIncidentStart}
				tag={maintenanceLogEntryStatus}
			/>
		</CardMaintenanceLogList>
	);
};
