import { Optional } from '../misc/Optional';
import { Value } from './Value';

export class Quotient implements Value<number> {
	private readonly result: Optional<number>;

	public constructor(value1: Value<number>, value2: Value<number>) {
		this.result = calculate(value1.getSingle(), value2.getSingle());
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<number> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<number> {
		return this.result;
	}
}

function calculate(value1: Optional<number>, value2: Optional<number>): Optional<number> {
	if (value1 !== null && value2 !== null && value2 !== 0) {
		return value1 / value2;
	}
	return null;
}
