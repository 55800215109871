import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';
import { ValuedStringOutput } from 'services/device/domain/business/common/Output';
import { Valuation } from 'services/device/domain/business/common/Valuation';

export interface ValuedStringOutputValueRendererProps {
	output: ValuedStringOutput;
}

export const ValuedStringOutputValueRenderer = (props: ValuedStringOutputValueRendererProps): JSX.Element => {
	const { output } = props;

	const l10Context = useContext(L10nContext);

	const acceptableOptions = [];
	for (const item of output.get()) {
		if (item.valuation === Valuation.ACCEPTABLE)
			acceptableOptions.push(item.value);
	}

	const unacceptableOptions = [];
	for (const item of output.get()) {
		if (item.valuation === Valuation.UNACCEPTABLE)
			unacceptableOptions.push(item.value);
	}

	const repeatableOutput = l10Context.translate('common.inputs.options.acceptable', 'gültig') + ': ' + acceptableOptions.join(', ') + '; ' + l10Context.translate('common.inputs.options.unacceptable', 'ungültig') + ': ' + unacceptableOptions.join(', ');

	return (
		<span>
			{output.isRepeatable() ? repeatableOutput : output.getSingle()?.value}
		</span>
	);

};
