import { Optional } from '../misc/Optional';
import { Valuation } from '../Valuation';
import { Value } from './Value';

export class BooleanValuation implements Value<Valuation> {
	private readonly result: Optional<Valuation>;

	public constructor(value: Value<boolean>) {
		this.result = calculate(value.getSingle());
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<Valuation> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<Valuation> {
		return this.result;
	}
}

function calculate(value: Optional<boolean>): Optional<Valuation> {
	if (value !== null) {
		return value === true ? Valuation.ACCEPTABLE : Valuation.UNACCEPTABLE;
	}
	return null;
}
