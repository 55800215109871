import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { EluateModelState } from '../../../../domain/model/EluateModelState';
import { updateEluate } from '../../../../store/eluateSlice';
import { EluateToWasteForm } from '../eluate-to-waste-form/EluateToWasteForm';
import { EluateToWasteFailedNotification } from './EluateToWasteFailedNotification';

export interface EluateToWasteModalProps {
	eluate: EluateViewModel,
	onDismiss?: () => void;
}

export const EluateToWasteModal = (props: EluateToWasteModalProps): JSX.Element => {
	const { eluate, onDismiss } = props;

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const eluateActionStatus = useTypedSelector(state => state.eluates.actionStatus);

	const dispatch = useDispatch();

	const handleToWaste = (): void => {

		eluate.State = EluateModelState.IN_WASTE_MANAGEMENT;
		eluate.Client = clientContext.selectedClientUuid;
		eluate.Facility = facilityContext.selectedFacilityUuid;
		dispatch(updateEluate(eluate));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (eluateActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<EluateToWasteForm
						eluate={eluate}
						onToWaste={handleToWaste}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <EluateToWasteFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
