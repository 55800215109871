import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutHeader } from 'presentation/ui/layouts/form-layout/form-layout-header/FormLayoutHeader';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutModifier } from 'presentation/ui/layouts/form-layout/FormLayoutModifier';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { InputText, InputTextStatus } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { CreatableContainerViewModel } from '../../../../domain/model/ContainerModel';

export interface ContainerCreateFormStep2Props {
	container: CreatableContainerViewModel;
	/**
	 * Button back action
	 */
	onClickPrevious: () => void;
	/**
	 * Button confirm
	 */
	onClickConfirm?: () => void;
}

export const ContainerCreateFormStep2 = (props: ContainerCreateFormStep2Props): JSX.Element => {
	const { container, onClickPrevious, onClickConfirm } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			modifier={FormLayoutModifier.BLUE_BACKGROUND}
			onSubmit={onClickConfirm}
		>
			<FormLayoutHeader
				text={l10nContext.translate('common.modal.checkDetails', 'Überprüfen Sie bitte Ihre Angaben')}
				icon={IconIdentifier.WARNING}
			/>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('containers.name', 'Name')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={container?.Name ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.back', 'Zurück')}
							onClick={onClickPrevious}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('view.containers.modal.action.create.caption', 'Gebinde hinzufügen')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
