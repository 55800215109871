import { LookupTable } from '../misc/LookupTable';
import { Optional } from '../misc/Optional';
import { Null } from './Null';
import { Value } from './Value';

export class Lookup<From, To> implements Value<To> {
	private readonly result: Value<To>;

	public constructor(lookupTable: LookupTable<From, To>, keyValue: Value<From>) {
		this.result = calculate(lookupTable, keyValue.getSingle());
	}

	public isRepeatable(): boolean {
		return this.result.isRepeatable();
	}

	public get(): ReadonlyArray<To> {
		return this.result.get();
	}

	public getSingle(): Optional<To> {
		return this.result.getSingle();
	}
}
function calculate<From, To>(lookupTable: LookupTable<From, To>, keyValue: Optional<From>): Value<To> {
	if (keyValue !== null) {
		return lookupTable.lookup(keyValue);
	}
	return new Null<To>();
}
