import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';

import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import {
	DocumentModelConverter as ModelConverter,
	DocumentStoreModel as StoreModel,
	DocumentViewModel as ViewModel,
} from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentPersistence as Persistence } from 'services/documents/persistence/DocumentPersistence';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { DocumentHttpPersistence as HttpPersistence } from '../persistence/DocumentHttpPersistence';

// Declare a document state type
export interface DocumentsByDevicesState {
	documentsByDevices: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	documentsByDevices: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as DocumentsByDevicesState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the

export const fetchDocumentsByDevice = createAsyncThunk(
	'documentsByDevices/fetchByDevice',
	async (params: { clientUuid: string; facilityUuid: string; deviceUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollectionByDevice(params.deviceUuid);
	},
	{
		condition: (params, { getState }): boolean => {
			// Silently abort the action
			const { documentsByDevices } = getState() as RootState;
			const documentOfDevice = documentsByDevices.documentsByDevices.find((document)=>{
				return document.ScopeReference === params.deviceUuid;
			});
			return documentOfDevice === undefined;
		}
	}
);

export const resetDocumentsByDevice = createAsyncThunk(
	'documentsByDevices/reset',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Sliently abort the action
			const { documentsCollectionByDevices } = getState() as RootState;
			return checkFetchStatus(documentsCollectionByDevices.fetchStatus);
		}
	}
);

export const resetAllDocuments = createAsyncThunk(
	'allDocuments/reset',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Sliently abort the action
			const { documentsCollectionByDevices } = getState() as RootState;
			return checkFetchStatus(documentsCollectionByDevices.fetchStatus);
		}
	}
);

// Slice definition
export const documentsByDevicesSlice = createSlice({
	name: 'documentsByDevices',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creates slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchDocumentsByDevice.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchDocumentsByDevice.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.documentsByDevices = [...state.documentsByDevices, ...action.payload];
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchDocumentsByDevice.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(resetDocumentsByDevice.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(resetDocumentsByDevice.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.documentsByDevices = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(resetDocumentsByDevice.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

export const { resetState, resetActionStatus } = documentsByDevicesSlice.actions;

// Export the reducer as default
export default documentsByDevicesSlice.reducer;
// Selector functions to be used with useSelector or useTypedSelector to read from the stat

export const selectDocumentsByDevice = (clientUuid: string, facilityUuid: string, deviceUuid: string): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.documentsByDevices.documentsByDevices.filter((storeModel: StoreModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.Scope === DocumentModelScope.DEVICE
				&& storeModel.ScopeReference === deviceUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel: StoreModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel: any) => {
			return viewModel !== null;
		});
	};
};
