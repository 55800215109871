import { Guid } from 'lib/guid/Guid';

import {
	InputWidget,
	MessageWidget,
	OutputWidget,
	SummaryWidget,
	WidgetSelection
} from 'services/device/domain/business/common/widget/Widget';
import { InputWidgetRenderer } from 'services/device/presentation/ui/widget/widget-renderer/InputWidgetRenderer';
import { MessageWidgetRenderer } from 'services/device/presentation/ui/widget/widget-renderer/MessageWidgetRenderer';
import { OutputWidgetRenderer } from 'services/device/presentation/ui/widget/widget-renderer/OutputWidgetRenderer';
import { SummaryWidgetRenderer } from 'services/device/presentation/ui/widget/widget-renderer/SummaryWidgetRenderer';
import { Sequence } from '../../../domain/business/common/sequence/Sequence';

export class WidgetsSelection implements WidgetSelection<void> {

	private readonly onElement: (element: JSX.Element) => void;
	private readonly sequence?: Sequence;

	public constructor(onElement: (element: JSX.Element) => void, sequence?: Sequence) {
		this.onElement = onElement;
		this.sequence = sequence;
	}

	public onInputWidget(widget: InputWidget): void {
		this.onElement(
			<InputWidgetRenderer widget={widget} sequence={this.sequence} key={Guid.generate()} />
		);
	}

	onMessageWidget(widget: MessageWidget): void {
		this.onElement(
			<MessageWidgetRenderer widget={widget} key={Guid.generate()} />
		);
	}

	onOutputWidget(widget: OutputWidget): void {
		this.onElement(
			<OutputWidgetRenderer widget={widget} key={Guid.generate()} />
		);
	}

	onSummaryWidget(widget: SummaryWidget): void {
		this.onElement(
			<SummaryWidgetRenderer widget={widget} key={Guid.generate()} />
		);
	}

}
