import { useContext, useState } from 'react';

import { SequencePeriodContext } from 'services/device/presentation/ui/sequence-records/context/SequencePeriodContext';
import { AuthContext } from '../../../../../core/context/AuthContext';

export const SequencePeriodContextProvider = (props: any): JSX.Element => {

	const authContext = useContext(AuthContext);
	const userUuid = authContext.getActor().Uuid;

	 let startDate = null;
	 let endDate = null;

	const sequencePeriodSelection = localStorage.getItem(`private_${userUuid}_sequencePeriodSelection`);
	if (sequencePeriodSelection) {
		const sequencePeriodSelectionJson = JSON.parse(sequencePeriodSelection);
		startDate = new Date(sequencePeriodSelectionJson.dateStart);
		endDate = new Date(sequencePeriodSelectionJson.dateEnd);
	}

	const defaultDateStart = startDate ?? new Date();
	if (startDate) {
		defaultDateStart.setDate(defaultDateStart.getDate());
	} else {
		defaultDateStart.setDate(defaultDateStart.getDate() - 30);
	}

	const defaultDateEnd = endDate ?? new Date();
	if (endDate) {
		defaultDateEnd.setDate(defaultDateEnd.getDate());
	} else {
		defaultDateEnd.setDate(defaultDateEnd.getDate() + 1);
	}

	const [dateStart, setDateStart] = useState<Date>(defaultDateStart);
	const [dateEnd, setDateEnd] = useState<Date>(defaultDateEnd);

	return (
		<SequencePeriodContext.Provider value={{ dateStart, dateEnd, setDateStart, setDateEnd }}>
			{props.children}
		</SequencePeriodContext.Provider>
	);

};
