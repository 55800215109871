import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';
import { SequenceCreateSuccessNotification } from 'services/device/presentation/ui/sequence-action/sequence-create-modal/SequenceCreateSuccessNotification';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { SequenceCreateForm } from 'services/device/presentation/ui/sequence-action/sequence-create-form/SequenceCreateForm';
import { SequenceCreateFailedNotification } from 'services/device/presentation/ui/sequence-action/sequence-create-modal/SequenceCreateFailedNotification';
import { selectCreatedSequence } from 'services/device/store/sequenceSlice';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

interface SequenceCreateModalProps {
	device: DeviceViewModel;
	onDismiss: () => void;
}

/**
 * The device create modal
 */
export const SequenceCreateModal = (props: SequenceCreateModalProps): JSX.Element => {
	const { device, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use state to hold error objects
	const [caughtError, setCaughtError] = useState<Error>(null);

	// Use selector to provide information from store
	const createdSequence = useSelector(selectCreatedSequence());
	const sequenceActionStatus = useTypedSelector(state => state.sequences.actionStatus);

	const renderModal = () => {
		if (caughtError !== null) {
			return (
				<SequenceCreateFailedNotification
					onClickConfirm={onDismiss}
				/>
			);
		}

		let modal: JSX.Element = null;

		switch (sequenceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <SequenceCreateForm
					device={device}
					onError={(error) => {
						setCaughtError(error);
					}}
				/>;
				break;

			case AsyncReducerStatus.CREATED:
				modal =
					<SequenceCreateSuccessNotification
						onClickConfirm={onDismiss}
						sequenceCreated={createdSequence}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<SequenceCreateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.device.modal.action.createSequence.caption', 'Messreihe hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
