import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import {
	SequenceModelConverter as ModelConverter,
	SequencePersistenceModel as PersistenceModel,
	SequenceStoreModel as StoreModel,
	SequenceViewModel as ViewModel,
	CreatableSequenceViewModel as CreatableViewModel
} from 'services/device/domain/model/SequenceModel';
import { ExternalHttpPersistence } from '../../../lib/persistence/http/ExternalHttpPersistence';


export class ExternalReportSequenceHttpPersistence extends ExternalHttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'device/report-api/v1';
	protected collectionControllerUri = 'sequences';
	protected entityControllerUri = 'sequence';

	protected responseCollectionKey = 'SequenceModelCollection';
	protected responseEntityKey = 'SequenceModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
