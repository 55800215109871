import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

import {
	ModalDialogueCaptionModifier,
	ModalDialogueHeader
} from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogueSectionName } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSectionName';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface SequenceCloneFormProps {
	sequence: SequenceViewModel;
	onUpdate: () => void;
	onDismiss: () => void;
}

export const SequenceCloneForm = (props: SequenceCloneFormProps): JSX.Element => {
	const { sequence, onUpdate, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const modalMessage = {
		first: l10nContext.translate('view.sequence.modal.action.clone.message.first', 'Wollen Sie die Messreihe'),
		last: l10nContext.translate('view.sequence.modal.action.clone.message.last', 'wirklich duplizieren?')
	};

	return (
		<>
			<ModalDialogueHeader
				captionText={l10nContext.translate('common.modal.captionAttention', 'Achtung')}
				captionIcon={IconIdentifier.ALERT}
				captionModifier={ModalDialogueCaptionModifier.SMALL_CAPTION}
			/>
			<ModalDialogueSection>
				<>
					{modalMessage.first} <b>{sequence.Name}</b> {modalMessage.last}
				</>
			</ModalDialogueSection>

			<ModalDialogueSection section={ModalDialogueSectionName.SECTION_FOOTER}>
				<ColumnLayout>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.abort', 'Abbrechen')}
							onClick={onDismiss}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.modal.cloneConfirm', 'Ja, duplizieren')}
							icon={IconIdentifier.DUPLICATE}
							onClick={onUpdate}
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</ModalDialogueSection>
		</>
	);
};
