import { Guid } from 'lib/guid/Guid';
import { ChangeEvent, ComponentPropsWithoutRef, MouseEvent, useContext, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { InputFileCard } from 'presentation/ui/partials/input/input-file/input-file-card/InputFileCard';

import './input-file.scss';

export interface InputFileProps extends ComponentPropsWithoutRef<'input'> {
	/**
	 * (Optional) onChange-handler
	 */
	onchange?: (files: Array<File>) => void;
	/**
	 * (Optional) cached files
	 */
	cachedFiles?: Array<File>;
	/**
	 * (Optional) prop for summarized view of uploaded files
	 */
	summarized?: boolean;
}

/**
 * A nearby native input file component.
 * You can add all additional attributes you know from native HTML input and input file element,
 * like f.e. disabled, onChange-handler, accept, files etc.
 */
export const InputFile = (props: InputFileProps): JSX.Element => {
	const { onchange, cachedFiles = [], summarized = false, ...restProps } = props;

	const [files, setFiles] = useState<Array<File>>(cachedFiles);

	const l10nContext = useContext(L10nContext);

	const translations = {
		'placeholder': l10nContext.translate('common.inputs.inputFile.placeholder', 'Laden Sie mehrere Dateien hoch'),
		'buttonLabel': l10nContext.translate('common.inputs.inputFile.buttonLabel', 'Bitte wählen'),
	};

	const disabledCssClass = props.disabled ? 'disabled' : '';

	const onChange = (event: ChangeEvent) => {
		const inputElement = (event.target as HTMLInputElement);
		const selectedFile = inputElement.files[0];

		if (selectedFile) {
			const appendeFiles = [...files, selectedFile];
			setFiles(appendeFiles);
			onchange(appendeFiles);
		}

		// Reset input file value, so you can choose the same file again
		inputElement.value = null;
	};

	const deleteFile = (file: File) => {
		const reducedFiles = files.filter((exisitingFile) => {
			return file !== exisitingFile;
		});

		setFiles(reducedFiles);
		onchange(reducedFiles);
	};

	const uploadedFiles = files.map((file) => {
		return (
			<InputFileCard
				key={Guid.generate()}
				fileName={file.name}
				onClick={(event: MouseEvent) => {
					event.preventDefault();

					if (!props.disabled) deleteFile(file);
				}}
			/>
		);
	});

	return (
		<div className={`input-file ${disabledCssClass} ${summarized ? 'summarized' : ''}`}>
			<div className="input-file__input-wrapper">
				<span className="input-file__label">
					{translations.placeholder}
				</span>

				<input
					type="file"
					onChange={onChange}
					{...restProps}
				/>

				<ButtonSecondary buttonText={translations.buttonLabel} />
			</div>

			{uploadedFiles}
		</div>
	);
};
