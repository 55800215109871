import React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../../../presentation/ui/components/loading-spinner/LoadingSpinner';
import { NavigationSecondary } from '../../../../presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { MainLayoutSection } from '../../../../presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from '../../../../presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from '../../../../presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from '../../../../presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from '../../../../presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from '../../../../presentation/ui/layouts/view-layout/ViewLayout';
import { Breadcrumbs } from '../../../../presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { AsyncFetchStatus } from '../../../../store/common/AsyncFetchStatus';
import { useTypedSelector } from '../../../../store/common/TypedSelector';
import { selectExternalWasteManagementReport } from '../../store/externalWasteManagementReportSlice';
import {
	ExternalWasteManagementReportCyclotronProductCard
} from '../ui/external-waste-management-report-cyclotron-product-card/ExternalWasteManagementReportCyclotronProductCard';
import {
	ExternalWasteManagementReportGeneratorsCard
} from '../ui/external-waste-management-report-generators-card/ExternalWasteManagementReportGeneratorsCard';
import { WasteManagementReportCard } from '../ui/waste-management-report-card/WasteManagementReportCard';

interface ReportViewParams {
	wasteManagamentReportUuid: string;
}

export const ExternalWasteManagementReportView = (): JSX.Element => {

	const params = useParams<ReportViewParams>();

	const reportUuid = params?.wasteManagamentReportUuid ?? null;
	if (reportUuid === null) {
		throw new Error('Report id is missing');
	}

	const storeFetchStatus = useTypedSelector(state => state.externalWasteManagementReport.fetchStatus);

	const externalWasteManagementReport = useTypedSelector(selectExternalWasteManagementReport(reportUuid));

	if (storeFetchStatus === AsyncFetchStatus.PENDING || storeFetchStatus === AsyncFetchStatus.INITIAL_PENDIG) {
		return (
			<LoadingSpinner />
		);
	}

	const reportCard =
		<WasteManagementReportCard
			report={externalWasteManagementReport}
			isOnlyView={true}
		/>;

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs mapURLFragments={[[externalWasteManagementReport?.Uuid, externalWasteManagementReport?.Name]]} />
				</NavigationSecondary>
			</TopbarLayoutSection>
			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						{reportCard}
					</ViewLayoutSection>
					<ViewLayoutSection>
						{externalWasteManagementReport.WasteManagementReportGenerators.length > 0 ?
							<ExternalWasteManagementReportGeneratorsCard /> :
							<ExternalWasteManagementReportCyclotronProductCard />}
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>
		</MainLayout>
	);
};
