export enum Valuation {
	UNKNOWN = 'UNKNOWN',

	UNDEFINED = 'UNDEFINED',

	ACCEPTABLE = 'ACCEPTABLE',

	NOTICEABLE = 'NOTICEABLE',

	UNACCEPTABLE = 'UNACCEPTABLE'
}

export function worst(valuations: ReadonlyArray<Valuation>): Valuation {
	let worstOrdinal: number = toOrdinal(Valuation.UNKNOWN);
	valuations.forEach((valuation) => {
		const ordinal: number = Object.keys(Valuation).indexOf(valuation);
		if (ordinal > worstOrdinal) {
			worstOrdinal = ordinal;
		}
	});
	return fromOrdinal(worstOrdinal);
}

export function isBetter(valuation1: Valuation, valuation2: Valuation): boolean {
	return toOrdinal(valuation1) < toOrdinal(valuation2);
}

export function isWorse(valuation1: Valuation, valuation2: Valuation): boolean {
	return toOrdinal(valuation1) > toOrdinal(valuation2);
}

function toOrdinal(valuation: Valuation): number {
	return Object.keys(Valuation).indexOf(valuation);
}

function fromOrdinal(ordinal: number): Valuation {
	return Object.values(Valuation)[ordinal];
}
