import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogEntry } from 'services/maintenance-log/presentation/ui/maintenance-log-entry/MaintenanceLogEntry';
import { selectFilteredFacilityMaintenanceLogEntries } from 'services/maintenance-log/store/maintenanceLogEntrySlice';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { Badge, BadgeColor } from 'presentation/ui/partials/badge/Badge';
import { TextLink } from 'presentation/ui/partials/text-link/TextLink';
import { DashboardViewModalType } from 'presentation/view/DashboardViewModalType';

interface MaintenanceLogCollectionProps {
	onClick: (modalType: DashboardViewModalType, logEntry: MaintenanceLogEntryViewModel) => void;
}

export const MaintenanceLogCollection = (props: MaintenanceLogCollectionProps): JSX.Element => {
	const { onClick } = props;

	// Consume the contexts
	const facilityContext = useContext(FacilityContext);
	const clientContext = useContext(ClientContext);
	const l10nContext = useContext(L10nContext);

	// Read the maintenance logs from the state store
	const maintenanceLogEntries = useSelector(selectFilteredFacilityMaintenanceLogEntries(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		true,
		false
	));

	const renderMaintenanceLogEntries = (): JSX.Element => {
		if (maintenanceLogEntries.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.maintenanceLog', 'kein Eintrag')} />
			);
		}

		return (
			<>
				{
					maintenanceLogEntries.map((maintenanceLogEntry) => (
						<MaintenanceLogEntry
							key={maintenanceLogEntry.Uuid}
							maintenanceLogEntry={maintenanceLogEntry}
							onSelect={(selectedEntry) => onClick(DashboardViewModalType.RESOLVE_MAINTENANCE_LOG, selectedEntry)}
						/>
					))
				}
			</>
		);
	};

	const count = maintenanceLogEntries?.length === 0 ?
		'' :
		<Badge
			label={l10nContext.formatNumber(maintenanceLogEntries.length, 0)}
			color={BadgeColor.PRIMARY}
		/>;

	return (
		<Expandable> 
			<ExpandableHeader caption={l10nContext.translate('view.dashboard.headline.maintenanceLog', 'Betriebsbuch-Einträge')}>
				<TextLink
					text={l10nContext.translate('common.textlink.all', 'alle')}
					target="/maintenance-log"
				/>

				{count}
			</ExpandableHeader>
			<ExpandableBody>
				<CardCollectionLayout>
					{renderMaintenanceLogEntries()}
				</CardCollectionLayout>
			</ExpandableBody>
		</Expandable>
	);
};
