import { ComponentPropsWithoutRef } from 'react';

export type ExpandableSlimBodyProps = ComponentPropsWithoutRef<'main'>;

export const ExpandableSlimBody = (props: ExpandableSlimBodyProps): JSX.Element => {

	const { ...restProps } = props;

	return (
		<main className='expandable-slim__body' {...restProps}>
			{props.children}
		</main>
	);

};
