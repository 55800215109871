import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { updateGenerator } from 'services/nuclide/store/generatorSlice';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { GeneratorUpdateFormStep1 } from './GeneratorUpdateFormStep1';
import { GeneratorUpdateFormStep2 } from './GeneratorUpdateFormStep2';

export interface GeneratorUpdateFormProps {
	generator?: GeneratorViewModel;
}

/**
 * The generator update form
 */
export const GeneratorUpdateForm = (props: GeneratorUpdateFormProps): JSX.Element => {
	const { generator } = props; 

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const updatedGenerator: GeneratorViewModel = generator;

	const requiredInputs = ['Name', 'NominalActivity', 'BatchNumber', 'Manufacturer', 'SerialNumber', 'DeliveryDate', 'CalibrationDatetime'];

	const onChangeGeneratorProp = (prop: string, value: any) => {
		updatedGenerator[prop] = value;
	};

	const handleUpdate = (): void => {
		dispatch(updateGenerator(updatedGenerator));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newGeneratorModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedGenerator?.[requiredInput] ?? null) === null
				|| updatedGenerator?.[requiredInput] === ''
				|| String(updatedGenerator.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newGeneratorModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newGeneratorModelValid);
	};

	const showUpdateModalStep = showStep1 === true ?
		<GeneratorUpdateFormStep1
			generator={updatedGenerator}
			onClickNext={goToStep2}
			onChangeGeneratorProp={onChangeGeneratorProp}
			invalidInputs={invalidInputs}
		/> :
		<GeneratorUpdateFormStep2
			generator={updatedGenerator}
			onClickPrevious={goToStep1}
			onClickConfirm={handleUpdate}
		/>;

	return (
		<>
			{showUpdateModalStep}
		</>
	);
};
