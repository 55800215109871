import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { CreatableFolderViewModel, FolderViewModel } from 'services/documents/domain/model/FolderModel';
import { FolderAddFormStep1 } from 'services/documents/presentation/ui/folder-action/folder-add-form/FolderAddFormStep1';
import { FolderAddFormStep2 } from 'services/documents/presentation/ui/folder-action/folder-add-form/FolderAddFormStep2';
import { createFolder } from 'services/documents/store/folderSlice';

export interface FolderAddFormProps {
	onChange: (folder: string) => void;
	parentFolder: FolderViewModel;
}

export const FolderAddForm = (props: FolderAddFormProps): JSX.Element => {
	const { onChange, parentFolder } = props;

	const [showStep2, setShowStep2] = useState<boolean>(false);
	const [invalidStatus, setInvalidStatus] = useState<boolean>(false);

	const newFolderName = useRef<string>('');

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const dispatch = useDispatch();

	const handleAddAction = (): void => {
		dispatch(createFolder({
			Client: clientContext.selectedClientUuid,
			Facility: facilityContext.selectedFacilityUuid,
			Parent: parentFolder?.Uuid,
			Name: newFolderName.current
		} as CreatableFolderViewModel));
	};

	const onUserInput = (input: string) => {
		newFolderName.current = input;
	};

	const gotoStep1 = () => {
		setShowStep2(false);
	};

	const gotoStep2 = () => {
		if (newFolderName.current.trim() !== '') {
			onChange(newFolderName.current);
			setShowStep2(true);
		} else {
			setInvalidStatus(true);
		}
	};

	const formStep = !showStep2 ?
		<FolderAddFormStep1
			folder={newFolderName.current}
			onChange={onUserInput}
			onClickNext={gotoStep2}
			invalid={invalidStatus}
		/> :
		<FolderAddFormStep2
			folder={newFolderName.current}
			onClickBack={gotoStep1}
			onClickNext={handleAddAction}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
