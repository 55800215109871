import './textarea.scss';

export enum TextareaStatus {
	ERROR = 'ERROR',
	READONLY = 'READONLY'
}

export interface TextareaProps extends React.ComponentPropsWithoutRef<'textarea'> {
	/**
	 * Status of Textarea
	 */
	status?: TextareaStatus;
	/**
	 * (Optional) error message
	 */
	errorMessage?: string;
}

/**
 * A nearby native textarea component.
 * You can add all additional attributes you know from native HTML textarea element,
 * like f.e. maxlength, disabled, onChange-handler etc.
 */
export const Textarea = (props: TextareaProps): JSX.Element => {
	const { status, errorMessage, ...restProps } = props;

	const stateClass = status ?
		`textarea--${status.toLowerCase()}` :
		'';

	const errorMsg = errorMessage ?
		<span className='textarea__error-message'>
			{errorMessage}
		</span> :
		null;

	return (
		<div className={`textarea ${stateClass}${props.disabled ? ' textarea--disabled' : ''}`}>
			<div className='textarea__wrapper'>
				<textarea
					{...restProps}
				/>
			</div>
			{errorMsg}
		</div>
	);
};
