import { Optional } from '../misc/Optional';
import { Value } from './Value';

export class Percent implements Value<number> {
	private readonly result: Optional<number>;

	public constructor(part: Value<number>, total: Value<number>) {
		this.result = calculate(part.getSingle(), total.getSingle());
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<number> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<number> {
		return this.result;
	}
}

function calculate(part: Optional<number>, total: Optional<number>): Optional<number> {
	if (part !== null && total !== null && total !== 0) {
		return (part / total) * 100;
	}
	return null;
}
