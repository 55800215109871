import { useEffect } from 'react';

import { useTypedSelector } from 'store/common/TypedSelector';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { useDispatch } from 'react-redux';
import { updateDocument } from 'services/documents/store/documentSlice';
import { DocumentModelState } from 'services/documents/domain/model/DocumentModelState';
import { DocumentUnarchiveFailedNotification } from './DocumentUnarchiveFailedNotification';
import { DocumentUnarchiveForm } from '../document-unarchive-form/DocumentUnarchiveForm';


export interface DocumentUnarchiveModalProps {
	document: DocumentViewModel
	onDismiss?: () => void;
}

export const DocumentUnarchiveModal = (props: DocumentUnarchiveModalProps): JSX.Element => {
	const { document, onDismiss } = props;

	const dispatch = useDispatch();

	const documentsActionStatus = useTypedSelector(state => state.documents.actionStatus);

	useEffect(() => {
		if (documentsActionStatus === AsyncReducerStatus.UPDATED) {
			onDismiss();
		}
	}, [documentsActionStatus, onDismiss]);

	const handleUpdate = (): void => {
		dispatch(updateDocument({
			...document,
			...{
				State: DocumentModelState.ACTIVE
			}
		}));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (documentsActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<DocumentUnarchiveForm
						document={document}
						onUpdate={handleUpdate}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <DocumentUnarchiveFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
