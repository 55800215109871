import { TooltipItem } from 'services/device/presentation/ui/sequence-chart/SequenceChartOptions';

export class SequenceChartTooltipLabelFormatterBuilder {

	public static build(
		locale: string,
		valuePrecision: number,
		valueDisplayUnit: string
	): (tooltipItem: TooltipItem, data: any) => string {

		return (tooltipItem: TooltipItem, data: any): string => {
			const value: number = tooltipItem.yLabel;
			let formattedValue: string;
			try {
				formattedValue = value.toLocaleString(locale, {
					minimumFractionDigits: valuePrecision,
					maximumFractionDigits: valuePrecision
				});
			} catch (e) {
				const factor = Math.pow(10, valuePrecision);
				formattedValue = String(Math.round(tooltipItem.yLabel * factor) / factor);
			}
			return data.datasets[tooltipItem.datasetIndex].label + ': ' + formattedValue + ' ' + valueDisplayUnit;
		};
	}

}
