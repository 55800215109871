export interface SequenceChartDatasetRecord {
	x: Date;
	y: number;
}

export interface SequenceChartDataset {
	label: string;
	borderColor: string;
	borderWidth: number;
	borderDash: Array<number>;
	backgroundColor: string;
	lineTension: number;
	pointBackgroundColor: string;
	pointBorderColor: string;
	pointBorderWidth: number;
	pointRadius: number;
	pointStyle: string;
	pointHoverBackgroundColor: string;
	pointHoverBorderColor: string;
	pointHoverBorderWidth: number;
	pointHoverRadius: number;
	pointHitRadius: number;
	data: Array<SequenceChartDatasetRecord>;
	fill: string | boolean;
}

export interface SequenceChartBand {
	min: SequenceChartDataset;
	max: SequenceChartDataset;
}

export const SequenceChartDatasetPrototype: SequenceChartDataset = {
	label: '',
	borderColor: '#000000',
	borderWidth: 2,
	borderDash: [],
	backgroundColor: null,
	lineTension: 0,
	pointBackgroundColor: '#ffffff',
	pointBorderColor: '#000000',
	pointBorderWidth: 2,
	pointRadius: 5,
	pointStyle: 'circle',
	pointHoverBackgroundColor: '#ffffff',
	pointHoverBorderColor: '#000000',
	pointHoverBorderWidth: 3,
	pointHoverRadius: 8,
	pointHitRadius: 8,
	data: [] as Array<SequenceChartDatasetRecord>,
	fill: false
};

export const createRecordDataset = (label: string): SequenceChartDataset => {
	const recordDataset = createDataset(label, '#174b81');
	recordDataset.pointBorderWidth = 2;
	recordDataset.pointRadius = 5;
	recordDataset.pointHoverBorderWidth = 3;
	recordDataset.pointHoverRadius = 8;
	return recordDataset;
};

export const createBestValueDataset = (label: string): SequenceChartDataset => {
	const bestValueDataset = createDataset(label, '#04744a99');
	bestValueDataset.pointRadius = 0;
	bestValueDataset.pointHoverBorderWidth = 0;
	bestValueDataset.pointHoverRadius = 0;
	bestValueDataset.borderDash = [2, 4];
	return bestValueDataset;
};

export const createSoftThresholdBand = (labelMin: string, labelMax: string): SequenceChartBand => {
	const thresholdDatasetMin = createThresholdDataset(labelMin, '#fad202');
	thresholdDatasetMin.fill = '+2';
	thresholdDatasetMin.backgroundColor = '#fad20233';
	const thresholdDatasetMax = createThresholdDataset(labelMax, '#fad202');
	thresholdDatasetMax.fill = '+2';
	thresholdDatasetMax.backgroundColor = '#fad20233';
	return {
		min: thresholdDatasetMin,
		max: thresholdDatasetMax
	};
};

export const createHardThresholdBand = (labelMin: string, labelMax: string): SequenceChartBand => {
	const thresholdDatasetMin = createThresholdDataset(labelMin, '#ea1601');
	thresholdDatasetMin.fill = 'start';
	thresholdDatasetMin.backgroundColor = '#ea160133';
	const thresholdDatasetMax = createThresholdDataset(labelMax, '#ea1601');
	thresholdDatasetMax.fill = 'end';
	thresholdDatasetMax.backgroundColor = '#ea160133';
	return {
		min: thresholdDatasetMin,
		max: thresholdDatasetMax
	};
};

export const hideDataset = (dataset: SequenceChartDataset):void => {
	dataset.borderWidth = 0;
	dataset.borderColor = 'rgba(0,0,0,0)';
	dataset.pointRadius = 0;
	dataset.pointHoverBorderWidth = 0;
	dataset.pointHoverRadius = 0;
	dataset.fill = false;
};

const createThresholdDataset = (label: string, color: string): SequenceChartDataset => {
	const thresholdDataset = createDataset(label, color);
	thresholdDataset.pointBackgroundColor = color;
	thresholdDataset.pointBorderWidth = 0;
	thresholdDataset.pointRadius = 3;
	thresholdDataset.pointHoverBorderWidth = 2;
	thresholdDataset.pointHoverRadius = 4;
	return thresholdDataset;
};

const createDataset = (label: string, color: string): SequenceChartDataset => {
	const dataset = { ...SequenceChartDatasetPrototype };
	dataset.data = [];
	dataset.label = label;
	dataset.borderColor = color;
	dataset.pointBorderColor = color;
	dataset.pointHoverBorderColor = color;
	return dataset;
};
