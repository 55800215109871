import { L10nContext } from 'context/L10nContext';

import { useKeyPress } from 'presentation/hooks';
import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { RecordViewModel } from 'services/device/domain/model/RecordModel';

import { DeviceType } from '../../../../domain/business/inventory/DeviceType';
import { SequenceViewModel } from '../../../../domain/model/SequenceModel';
import { RecordCreateModalLocation } from './RecordCreateModalLocation';

export interface RecordCreateSuccessNotificationProps {
	/**
	 * Created record
	 */
	recordCreated?: RecordViewModel | null;
	/**
	 * Location of calling record create modal
	 */
	location: RecordCreateModalLocation;
	/**
	 * Sequence
	 */
	sequence: SequenceViewModel;
	showAddRecord: boolean;
	/**
	 * Handle click dismiss
	 */
	onDismiss: () => void;
	/**
	 * Handle click add documents to record
	 */
	onClickAddDocuments: () => void;
	/**
	 * Handle click add another record
	 */
	onClickAddAnotherRecord: () => void;
}

export const RecordCreateSuccessNotification = (props: RecordCreateSuccessNotificationProps): JSX.Element => {
	const {  recordCreated, location, onDismiss, onClickAddDocuments, onClickAddAnotherRecord } = props;

	const l10nContext = useContext(L10nContext);
	const history = useHistory();

	useKeyPress('Enter', () => {
		onDismiss();
	});

	/**
	 * Records can be created in different places: dashboard, device detail view, sequence detail view, clearance detail view and measurement goals detail view
	 * We always have a link to the sequence/measurement goal detail view and the sequence/measurement goal overview
	 * For the dashboard we also need to adjust the button text
	 * Because of all these different button texts and links two switch cases were created; a prop for the location where the record create modal is imported as well
	 */

	const rightButton = (): JSX.Element => {
		let button: JSX.Element = null;

		switch (location) {
			case RecordCreateModalLocation.DASHBOARD:
				button =
					<ButtonPrimary
						buttonText={l10nContext.translate('view.dashboard.modal.btnSuccess', 'Weiter zum Dashboard')}
						onClick={() => onDismiss()}
					/>;
				break;

			case RecordCreateModalLocation.DEVICES:
				button =
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnSuccess', 'Weiter zur Messreihen-Übersicht')}
						onClick={() => onDismiss()}
					/>;
				break;

			case RecordCreateModalLocation.CLEARANCES:
				button =
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnSuccessMeasurementGoalsOverview', 'Weiter zur Messziel-Übersicht')}
						onClick={() => onDismiss()}
					/>;
				break;

			case RecordCreateModalLocation.SEQUENCES:
				button =
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnSuccess', 'Weiter zur Messreihen-Übersicht')}
						onClick={() => history.push(`${Route.DEVICES}/${recordCreated.Device}${Route.SEQUENCES}`)}
					/>;
				break;

			case RecordCreateModalLocation.MEASUREMENT_GOALS:

				button =
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnSuccessMeasurementGoalsOverview', 'Weiter zur Messziel-Übersicht')}
						onClick={() => history.push(`${Route.CLEARANCES}/${recordCreated.Device}${Route.MEASUREMENT_GOALS}`)}
					/>;
				break;
		}

		return button;
	};

	const leftButton = (): JSX.Element => {
		let button: JSX.Element = null;

		switch (location) {
			case RecordCreateModalLocation.DASHBOARD:
				button = recordCreated.DeviceType !== DeviceType.CLEARANCE ?
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnDeviceDetails', 'Weiter zu den Messreihendetails')}
						onClick={() => history.push(`${Route.DEVICES}/${recordCreated.Device}${Route.SEQUENCES}/${recordCreated.Sequence}`)}
					/> :
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnSuccessMeasurementGoalsDetails', 'Weiter zu den Messziel-Details')}
						onClick={() => history.push(`${Route.CLEARANCES}/${recordCreated.Device}${Route.MEASUREMENT_GOALS}/${recordCreated.Sequence}`)}
					/>;
				break;

			case RecordCreateModalLocation.DEVICES:
				button =
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnDeviceDetails', 'Weiter zu den Messreihendetails')}
						onClick={() => history.push(`${Route.DEVICES}/${recordCreated.Device}${Route.SEQUENCES}/${recordCreated.Sequence}`)}
					/>;
				break;

			case RecordCreateModalLocation.CLEARANCES:
				button =
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnSuccessMeasurementGoalsDetails', 'Weiter zu den Messziel-Details')}
						onClick={() => history.push(`${Route.CLEARANCES}/${recordCreated.Device}${Route.MEASUREMENT_GOALS}/${recordCreated.Sequence}`)}
					/>;
				break;

			case RecordCreateModalLocation.SEQUENCES:
				button =
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnDeviceDetails', 'Weiter zu den Messreihendetails')}
						onClick={() => onDismiss()}
					/>;
				break;

			case RecordCreateModalLocation.MEASUREMENT_GOALS:
				button =
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnSuccessMeasurementGoalsDetails', 'Weiter zu den Messziel-Details')}
						onClick={() => onDismiss()}
					/>;
				break;
		}

		return button;
	};

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.device.modal.action.createRecord.successMessage', 'Die Messreihe wurde erfolgreich angelegt')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonSecondary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnAddDocuments', 'Dokumente hinzufügen')}
						onClick={onClickAddDocuments}
					/>
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonSecondary
						buttonText={l10nContext.translate('view.device.modal.action.createRecord.btnAddAnotherRecord', 'Weiteren Messwert hinzufügen')}
						onClick={onClickAddAnotherRecord}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					{leftButton()}
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					{rightButton()}
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
