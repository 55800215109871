export enum Route {
	DASHBOARD = '/dashboard',
	DEVICES = '/devices',
	DOCUMENTS = '/documents',
	HOME = '/',
	REPORTS = '/reports',
	REPORT = '/report',
	EXTERNAL_REPORT = '/external-report',
	EXTERNAL_REPORT_DEVICE = '/external-report-device',
	EXTERNAL_REPORT_SEQUENCE = '/external-report-sequence',
	DEVELOP = '/dev',
	DEVELOPE = '/deve',
	CLEARANCES = '/clearances',
	SEQUENCES = '/sequences',
	MEASUREMENT_GOALS = '/measurement-goals',
	MAINTENANCE_LOG = '/maintenance-log',
	MAINTENANCE_LOG_ENTRY = '/maintenance-log',
	ACTORS = '/users',
	NUCLIDE_MANAGEMENT = '/nuclide-management',
	GENERATORS = '/generators',
	CYCLOTRON_PRODUCTS = '/cyclotron-products',
	WASTE_MANAGEMENT = '/waste-management',
	WASTE_REPORTS = '/waste-reports',
	CONTAINERS = '/containers',
	EXTERNAL_WASTE_MANAGEMENT_REPORT = '/external-waste-management-report'
}
