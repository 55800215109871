import { memo, useContext } from 'react';
import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';
import { useDispatch } from 'react-redux';
import { ContainerViewModel } from 'services/nuclide/domain/model/ContainerModel';

import './card-item-container-dispose-attributes.scss';
import { ClientContext } from '../../../../../../services/core/context/ClientContext';
import { FacilityContext } from '../../../../../../services/core/context/FacilityContext';
import {
	fetchContainerDispose,
	selectContainerDisposeByUuid
} from '../../../../../../services/nuclide/store/containerDisposeSlice';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';

export interface CardItemContainerDisposeAttributesProps {
	/**
	 * Container
	 */
	container: ContainerViewModel;
}

export const CardItemContainerDisposeAttributes = memo((props: CardItemContainerDisposeAttributesProps): JSX.Element => {

	const { container } = props;

	const dispatch = useDispatch();

	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const disposeUuid = container?.DisposeId;

	dispatch(fetchContainerDispose({
		clientUuid: clientContext.selectedClientUuid,
		facilityUuid: facilityContext.selectedFacilityUuid,
		disposeUuid
	}));

	const containerDisposeModel = useTypedSelector(selectContainerDisposeByUuid(disposeUuid));

	const disposedDateTimeFormatted = l10nContext.formatDate(containerDisposeModel?.DisposedDatetime);

	const checkEmptyValue = (val: string): string => {
		return val !== '' && val !== null ? val : '-';
	};

	const translations = {

		disposedDateTime: { label: l10nContext.translate('containers.disposedDateTime', 'Entsorgt am'), value: disposedDateTimeFormatted },
		disposedByName: { label: l10nContext.translate('containers.disposedByName', 'Entsorgt durch'), value: checkEmptyValue(containerDisposeModel?.DisposedByName) }
	};

	const attributes = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const styleClass = 'attribute';

			return (
				<label
					key={Guid.generate()}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value }</p>
				</label>
			);
		});
	};

	return (
		<div className='card-item-container-dispose-attributes'>
			<ul className='card-item-container-dispose-attributes__attribute'>
				{attributes()}
			</ul>
		</div>
	);
});
