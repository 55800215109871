import './simple-view-title.scss';

export interface SimpleViewTitleProps {
	/**
	 * Label
	 */
	label: string;
	/**
	 * Margin for Report View
	 */
	isReportView?: boolean
}

/**
 * Login title partial
 */
export const SimpleViewTitle = (props: SimpleViewTitleProps): JSX.Element => {
	const { label, isReportView } = props;

	return (
		<div className={`simple-view-title ${isReportView ? 'simple-view-title--is-report-view' : ''}`}>
			{label}
		</div>
	);
};
