import { FunctionComponent } from 'react';

import { CardItemTagType } from 'presentation/ui/partials/card/CardItemTagType';
import { Icon, IconColor } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Tag } from 'presentation/ui/partials/tag/Tag';

import './card-item-state.scss';

export interface CardItemStateProps {
	/**
	 * Date
	 */
	date?: string;
	/**
	 * (Optional) Tag
	 */
	tag?: CardItemTagType

}

/**
 * A card item state component
 */
export const CardItemState: FunctionComponent<CardItemStateProps> = (props): JSX.Element => {
	const { date, tag, children } = props;

	const tagElement = tag ?
		<Tag
			label={tag.label}
			type={tag.type}
		/> :
		null;

	const showDate = date ?
		<span className='card-item-state__date'>
			<Icon
				name={IconIdentifier.CALENDER}
				color={IconColor.TEXT_50}
			/>
			<p className='card-item-state__date-label'>
				{date}
			</p>
		</span> :
		null;

	return (
		<div className={date ? 'card-item-state' : 'card-item-state--without-date'}>
			{showDate}
			{children ?? tagElement}
		</div>
	);
};
