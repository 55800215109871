import { memo, useContext } from 'react';
import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';
import { ContainerViewModel } from 'services/nuclide/domain/model/ContainerModel';

import './card-item-container-release-attributes.scss';

export interface CardItemContainerReleaseMeasurementAttributesProps {
	/**
	 * Container
	 */
	container: ContainerViewModel;
}

/**
 * A card item Container detail attributes component
 */
export const CardItemContainerReleaseMeasurementAttributes = memo((props: CardItemContainerReleaseMeasurementAttributesProps): JSX.Element => {
	const { container } = props;

	const l10nContext = useContext(L10nContext);

	const measurementContaminationDateTimeFormatted = l10nContext.formatDateTime(container?.MeasurementContaminationDateTime);
	const releasedByRadiationProtectionOfficerDateTimeFormatted = l10nContext.formatDateTime(container?.ReleasedByRadiationProtectionOfficerDateTime);
	const isRelasedByRadiationProtectionOfficer = container?.IsReleasedByRadiationProtectionOfficer ?
		l10nContext.translate('common.inputs.boolean.true', 'Ja') :
		l10nContext.translate('common.inputs.boolean.false', 'Nein');
	const measurementContaminationFormatted = container?.MeasurementContamination !== null ? l10nContext.formatNumber(container?.MeasurementContamination) + ' Bq/cm²' : '-';

	const checkEmptyValue = (val: string): string => {
		return val !== '' && val !== null ? val : '-';
	};

	const translations = {

		measurementContaminationDateTime: { label: l10nContext.translate('containers.measurementContaminationDateTime', 'Dosis-Leistungsmessung am'), value: checkEmptyValue(measurementContaminationDateTimeFormatted) },
		measurementContaminationByName: { label: l10nContext.translate('containers.measurementContaminationByName', 'Dosis-Leistungsmessung durch'), value: checkEmptyValue(container?.MeasurementContaminationByName) },
		measurementContamination: { label: l10nContext.translate('containers.measuredContamination', 'Gemessene Oberflächenkontamination'), value: checkEmptyValue(measurementContaminationFormatted) },
		isReleasedByRadiationProtectionOfficer: { label: l10nContext.translate('containers.isReleasedByRadiationProtectionOfficer', 'Freigabe durch SSB?'), value: checkEmptyValue(isRelasedByRadiationProtectionOfficer) },
		releasedByRadiationProtectionOfficerDateTime: { label: l10nContext.translate('containers.releasedByRadiationProtectionOfficerDateTime', 'Datum der Freigabe durch SSB'), value: checkEmptyValue(releasedByRadiationProtectionOfficerDateTimeFormatted) },
		releasedByRadiationProtectionOfficerName: { label: l10nContext.translate('containers.releasedByRadiationProtectionOfficerName', 'Name des SSB'), value: checkEmptyValue(container?.ReleasedByRadiationProtectionOfficerName) },
	};

	const attributes = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const styleClass = 'attribute';

			return (
				<label
					key={Guid.generate()}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value }</p>
				</label>
			);
		});
	};

	return (
		<div className='card-item-container-release-measurement-attributes'>
			<ul className='card-item-container-release-measurement-attributes__attribute'>
				{attributes()}
			</ul>
		</div>
	);
});
