import { L10nContext } from 'context/L10nContext';

import { ButtonToggleSet } from 'presentation/ui/components/button/ButtonToggleSet';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { SiteTitle } from 'presentation/ui/components/site-title/SiteTitle';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Permission, permissionGroupDevice } from 'services/core/lib/auth/AuthService';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentModalType } from 'services/documents/domain/type/DocumentsModalType';
import { DocumentAddModal } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddModal';
import { FolderAddModal } from 'services/documents/presentation/ui/folder-action/folder-add-modal/FolderAddModal';
import {
	fetchDocumentsByFolder,
	resetActionStatus as resetDocumentActionStatus,
	selectFilteredDocumentsByFolder
} from 'services/documents/store/documentSlice';
import {
	fetchFolders,
	resetActionStatus as resetFolderActionStatus,
	selectFilteredFoldersByParentFolder,
	selectRootFolder
} from 'services/documents/store/folderSlice';
import { NotificationBar } from '../../../../presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from '../../../../presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from '../../../../presentation/ui/components/notification-bars/NotificationBars';
import { AsyncFetchStatus } from '../../../../store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from '../../../../store/common/AsyncReducerStatus';
import { useTypedSelector } from '../../../../store/common/TypedSelector';
import { DocumentCollection } from '../ui/card-collections/document-collection/DocumentCollection';
import { FolderCollection } from '../ui/card-collections/folder-collection/FolderCollection';
import { DocumentsByDevices } from './DocumentsByDevices';

export enum Scope {
	LOCATION = 'location',
	DEVICE = 'device'
}

export const DocumentsView = (): JSX.Element => {
	const [modalType, setModalType] = useState<DocumentModalType>(null);
	const [scope, setScope] = useState<string>(Scope.LOCATION);

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	const dispatch = useDispatch();

	// document status
	const documentStoreFetchStatus = useTypedSelector(state => state.documents.fetchStatus);
	const documentStoreActionStatus = useTypedSelector(state => state.documents.actionStatus);
	const documentStoreLastActionError = useTypedSelector(state => state.documents.lastActionError);
	const documentStorePending = documentStoreFetchStatus === AsyncFetchStatus.INITIAL || documentStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const documentStoreFailed = documentStoreFetchStatus === AsyncFetchStatus.FAILED;

	// folder status
	const folderStoreFetchStatus = useTypedSelector(state => state.folders.fetchStatus);
	const folderStoreActionStatus = useTypedSelector(state => state.folders.actionStatus);
	const folderStoreLastActionError = useTypedSelector(state => state.folders.lastActionError);
	const folderStorePending = folderStoreFetchStatus === AsyncFetchStatus.INITIAL || folderStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const folderStoreFailed = folderStoreFetchStatus === AsyncFetchStatus.FAILED;

	// fetch folders
	useEffect(() => {
		dispatch(fetchFolders({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}, [clientContext.selectedClientUuid, dispatch, facilityContext.selectedFacilityUuid]);

	const [foldersFilterState, setFoldersFilterState] = useState<{ [key: string]: boolean }>({
		active: true,
		archived: false
	});

	const [documentsFilterState, setDocumentsFilterState] = useState<{ [key: string]: boolean }>({
		active: true,
		archived: false
	});

	const authContext = useContext(AuthContext);
	if (!authContext.hasAnyPermission(permissionGroupDevice)) {
		throw new Error('Permission denied');
	}

	const currentFolderModel = useSelector(
		selectRootFolder(
			clientContext.selectedClientUuid,
			facilityContext.selectedFacilityUuid
		)
	);

	useEffect(() => {
		dispatch(fetchDocumentsByFolder({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			documentfFolderUuid: currentFolderModel?.Uuid
		}));
	}, [clientContext.selectedClientUuid, currentFolderModel?.Uuid, dispatch, facilityContext.selectedFacilityUuid]);

	const documentModels = useSelector(
		selectFilteredDocumentsByFolder(
			clientContext.selectedClientUuid,
			facilityContext.selectedFacilityUuid,
			currentFolderModel?.Uuid,
			documentsFilterState.active,
			documentsFilterState.archived
		)
	);

	const childFolderModels = useSelector(
		selectFilteredFoldersByParentFolder(
			clientContext.selectedClientUuid,
			facilityContext.selectedFacilityUuid,
			currentFolderModel?.Uuid,
			foldersFilterState.active,
			foldersFilterState.archived
		)
	);

	const handleFoldersFiltering = (id: string, state: boolean) => {
		setFoldersFilterState({
			...foldersFilterState,
			[id]: state
		});
	};

	const handleDocumentsFiltering = (id: string, state: boolean) => {
		setDocumentsFilterState({
			...documentsFilterState,
			[id]: state
		});
	};



	const onModalDismiss = (): void => {
		setModalType(null);
	};

	const onModalTriggerClick = (type: DocumentModalType) => {
		dispatch(resetDocumentActionStatus());
		dispatch(resetFolderActionStatus());

		setModalType(type);
	};

	const toggleCollections = (toggle: string) => {
		setScope(toggle);
	};

	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permission: {
			addFolder: authContext.hasPermission(Permission.DOCUMENT_FOLDER_CREATE),
			addDocument: authContext.hasPermission(Permission.DOCUMENT_CREATE)
		}
	};

	const modal = (): JSX.Element => {
		let folderModal: JSX.Element = null;

		switch (modalType) {
			case DocumentModalType.ADD_FOLDER:
				folderModal =
					<FolderAddModal
						onDismiss={onModalDismiss}
						parentFolder={currentFolderModel}
					/>;
				break;

			case DocumentModalType.ADD_DOCUMENT:
				folderModal =
					<DocumentAddModal
						onDismiss={onModalDismiss}
						documentScope={DocumentModelScope.DOCUMENT_FOLDER}
						documentScopeReference={currentFolderModel?.Uuid}
					/>;
				break;
		}

		return folderModal;
	};

	const toggleButtons =
		<ButtonToggleSet
			buttons={[
				{
					buttonText: l10nContext.translate('view.documents.toggle.toggle01', 'Standort'),
					icon: IconIdentifier.LOCATION,
					id: Scope.LOCATION,
					isActive: true
				},
				{
					buttonText: l10nContext.translate('view.documents.toggle.toggle02', 'Geräte'),
					icon: IconIdentifier.DEVICE,
					id: Scope.DEVICE,
					isActive: false
				},
			]}
			onClick={toggleCollections}
			deSelectAll={false}
		/>;

	const cardCollections = scope === Scope.LOCATION ?
		<>
			<ViewLayoutSection>
				<FolderCollection
					folders={childFolderModels}
					fetchStatus={folderStorePending}
					onAddClick={
						user.permission.addFolder ?
							() => onModalTriggerClick(DocumentModalType.ADD_FOLDER) :
							null
					}
					onFilterClick={handleFoldersFiltering}
				/>
			</ViewLayoutSection>

			<ViewLayoutSection>
				<DocumentCollection
					documents={documentModels}
					fetchStatus={documentStorePending}
					onAddClick={
						user.permission.addDocument ?
							() => onModalTriggerClick(DocumentModalType.ADD_DOCUMENT) :
							null
					}
					onFilterClick={handleDocumentsFiltering}
				/>
			</ViewLayoutSection>
		</> :
		<DocumentsByDevices />;

	const renderNotifications = (): Array<JSX.Element> => {
		const notifications = [];

		// Handle failed action
		if (documentStoreActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					message={documentStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (documentStoreFailed) {
			notifications.push(
				<NotificationBar
					message={documentStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (folderStoreActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					message={folderStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (folderStoreFailed) {
			notifications.push(
				<NotificationBar
					message={folderStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		return notifications;
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{modal()}

						<SiteTitle title={l10nContext.translate('view.documents.title', 'Dokumente')}>
							{toggleButtons}
						</SiteTitle>
					</ViewLayoutSection>

					{cardCollections}

				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
