import { L10nContext } from 'context/L10nContext';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { ExpandableSlimBody } from 'presentation/ui/compositions/expandable-slim/expandable-slim-body/ExpandableSlimBody';
import { ExpandableSlimHeader } from 'presentation/ui/compositions/expandable-slim/expandable-slim-header/ExpandableSlimHeader';
import { ExpandableSlim } from 'presentation/ui/compositions/expandable-slim/ExpandableSlim';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { fetchSequences, selectSequencesByDevice } from 'services/device/store/sequenceSlice';
import { ReportViewModel } from 'services/report/domain/model/ReportModel';
import { ReportSequenceViewModel } from 'services/report/domain/model/ReportSequenceModel';
import { ModelSyncState } from '../../../../../lib/domain/model/Model';
import { Checkbox } from '../../../../../presentation/ui/partials/input/checkbox/Checkbox';
import { AsyncFetchStatus } from '../../../../../store/common/AsyncFetchStatus';
import { useTypedSelector } from '../../../../../store/common/TypedSelector';
import { AuthContext } from '../../../../core/context/AuthContext';
import { ClientContext } from '../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../core/context/FacilityContext';
import { Permission } from '../../../../core/lib/auth/AuthService';
import { DeviceType } from '../../../../device/domain/business/inventory/DeviceType';
import { SequenceViewModel } from '../../../../device/domain/model/SequenceModel';
import { DocumentViewModel } from '../../../../documents/domain/model/DocumentModel';
import { fetchDocumentsByDevice, selectDocumentsByDevice } from '../../../../documents/store/documentsByDevicesSlice';
import { ReportDocumentViewModel } from '../../../domain/model/ReportDocumentModel';

import { ReportDocumentEntry } from '../report-document-entry/ReportDocumentEntry';
import { ReportSequenceEntry } from '../report-sequence-entry/ReportSequenceEntry';

import { ReportDeviceSequencesType, ReportDocumentsType } from './ReportCardDeviceProps';

import './report-card-device.scss';

export interface ReportCardDeviceProps {
	device: Readonly<DeviceViewModel>,
	report: ReportViewModel
	onHandleReportSequenceProps?: (reportSequenceModel: ReportSequenceViewModel, reportSequenceChecked: boolean, reportSequenceDateChange: boolean) => void;
	onHandleReportDocumentProps?: (reportDocumentModel: ReportDocumentViewModel, reportDocumentChecked: boolean) => void;
}

export const ReportCardDevice = (props: ReportCardDeviceProps): JSX.Element => {
	const { device, report, onHandleReportSequenceProps, onHandleReportDocumentProps } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const sequenceStoreFetchStatus = useTypedSelector(state => state.sequences.fetchStatus);

	const dispatch = useDispatch();

	// Read reports from the server - reports only online available
	useEffect(() => {
		if (sequenceStoreFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchSequences({
				clientUuid: clientContext.selectedClientUuid,
				facilityUuid: facilityContext.selectedFacilityUuid
			}));
		}
	});

	// Read sequences from the state store
	const sequencesOfDevice = useSelector(selectSequencesByDevice(
		device
	));

	useEffect(() => {
		dispatch(fetchDocumentsByDevice({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			deviceUuid: device.Uuid
		}));
	}, [clientContext.selectedClientUuid, device.Uuid, dispatch, facilityContext.selectedFacilityUuid]);

	const initialReportDeviceSequencesArray = sequencesOfDevice.map((sequence: SequenceViewModel) => {

		const excistingReportSequence = report?.ReportSequences.find((reportSequence) => {
			return sequence.Uuid === reportSequence.Sequence;
		});

		const reportDeviceSequencesModel = {
			isSequenceSelected: !!excistingReportSequence,
			PeriodDateStart: excistingReportSequence ? excistingReportSequence.PeriodDateStart : report?.PeriodDateStart,
			PeriodDateEnd: excistingReportSequence ? excistingReportSequence.PeriodDateEnd : report?.PeriodDateEnd,
			Sequence: sequence
		};
		return reportDeviceSequencesModel;
	});

	const documentsByDevice = useTypedSelector(selectDocumentsByDevice(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		device.Uuid
	));

	const initialReportDeviceDocumentsArray = documentsByDevice.map((document: DocumentViewModel) => {

		const excistingReportDocument = report?.ReportDocuments.find((reportDocument) => {
			return document.Uuid === reportDocument.Document;
		});

		const reportDeviceDocumentsModel = {
			isDocumentSelected: !!excistingReportDocument,
			Document: document
		};
		return reportDeviceDocumentsModel;
	});

	const [reportDeviceSequences, setReportDeviceSequences] = useState<Array<ReportDeviceSequencesType>>(initialReportDeviceSequencesArray);
	const [reportDeviceDocuments, setReportDeviceDocuments] = useState<Array<ReportDocumentsType>>(initialReportDeviceDocumentsArray);

	const isSelectAllSequencesChecked = !!reportDeviceSequences.find((sequence) => sequence.isSequenceSelected === false );
	const isSelectAllDocumentsChecked = !!reportDeviceDocuments.find((document) => document.isDocumentSelected === false );

	const handleSequenceSelect = (uuid: string) => {
		const updatedReportDeviceSequences = [...reportDeviceSequences];
		const findsequence = reportDeviceSequences.find(sequence => sequence.Sequence.Uuid === uuid);
		findsequence.isSequenceSelected = !findsequence.isSequenceSelected;

		setReportDeviceSequences(updatedReportDeviceSequences);

		const reportSequenceViewModel: ReportSequenceViewModel = {
			Report: report.Uuid,
			Sequence: findsequence.Sequence.Uuid,
			CreatedAt: report.UpdatedAt,
			UpdatedAt: new Date(),
			ModelSyncState: ModelSyncState.PRISTINE,
			PeriodDateStart: findsequence.PeriodDateStart,
			PeriodDateEnd: findsequence.PeriodDateEnd,
			Uuid: null
		};

		onHandleReportSequenceProps(reportSequenceViewModel, findsequence.isSequenceSelected, false);
	};

	const handleDocumentSelect = (uuid: string) => {
		const updatedReportDeviceDocuments = [...reportDeviceDocuments];
		const findDocument = reportDeviceDocuments.find(document => document.Document.Uuid === uuid);
		findDocument.isDocumentSelected = !findDocument.isDocumentSelected;

		setReportDeviceDocuments(updatedReportDeviceDocuments);

		const reportDocumentViewModel: ReportDocumentViewModel = {
			Report: report.Uuid,
			Document: findDocument.Document.Uuid,
			CreatedAt: report.UpdatedAt,
			UpdatedAt: new Date(),
			ModelSyncState: ModelSyncState.PRISTINE,
			Uuid: null
		};

		onHandleReportDocumentProps(reportDocumentViewModel, findDocument.isDocumentSelected);
	};

	const handleAllDocumentsChecked = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked === true) {
			const updatedReportDeviceDocuments = [...reportDeviceDocuments];
			updatedReportDeviceDocuments.forEach((document: ReportDocumentsType) => {
				document.isDocumentSelected = true;
			});
			setReportDeviceDocuments(updatedReportDeviceDocuments);

			updatedReportDeviceDocuments.forEach((document: ReportDocumentsType) => {
				const reportDocumentViewModel: ReportDocumentViewModel = {
					Report: report.Uuid,
					Document: document.Document.Uuid,
					CreatedAt: report.UpdatedAt,
					UpdatedAt: new Date(),
					ModelSyncState: ModelSyncState.PRISTINE,
					Uuid: null
				};
				onHandleReportDocumentProps(reportDocumentViewModel, true);
			});
		}
		if (e.target.checked === false) {
			const updatedReportDeviceDocuments = [...reportDeviceDocuments];
			updatedReportDeviceDocuments.forEach((document: ReportDocumentsType) => {
				document.isDocumentSelected = false;
			});

			setReportDeviceDocuments(updatedReportDeviceDocuments);

			updatedReportDeviceDocuments.forEach((document: ReportDocumentsType) => {
				const reportDocumentViewModel: ReportDocumentViewModel = {
					Report: report.Uuid,
					Document: document.Document.Uuid,
					CreatedAt: report.UpdatedAt,
					UpdatedAt: new Date(),
					ModelSyncState: ModelSyncState.PRISTINE,
					Uuid: null
				};
				onHandleReportDocumentProps(reportDocumentViewModel, false);
			});
		}
	};

	const handleAllSequencesChecked = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked === true) {
			const updatedReportDeviceSequences = [...reportDeviceSequences];
			updatedReportDeviceSequences.forEach((sequence: ReportDeviceSequencesType) => {
				sequence.isSequenceSelected = true;
			});
			setReportDeviceSequences(updatedReportDeviceSequences);

			updatedReportDeviceSequences.forEach((sequence: ReportDeviceSequencesType) => {
				const reportSequenceViewModel: ReportSequenceViewModel = {
					Report: report.Uuid,
					Sequence: sequence.Sequence.Uuid,
					CreatedAt: report.UpdatedAt,
					UpdatedAt: new Date(),
					ModelSyncState: ModelSyncState.PRISTINE,
					PeriodDateStart: sequence.PeriodDateStart,
					PeriodDateEnd: sequence.PeriodDateEnd,
					Uuid: null
				};
				onHandleReportSequenceProps(reportSequenceViewModel, true, false);
			});
		}
		if (e.target.checked === false) {
			const updatedReportDeviceSequences = [...reportDeviceSequences];
			updatedReportDeviceSequences.forEach((sequence: ReportDeviceSequencesType) => {
				sequence.isSequenceSelected = false;
			});

			setReportDeviceSequences(updatedReportDeviceSequences);

			updatedReportDeviceSequences.forEach((sequence: ReportDeviceSequencesType) => {
				const reportSequenceViewModel: ReportSequenceViewModel = {
					Report: report.Uuid,
					Sequence: sequence.Sequence.Uuid,
					CreatedAt: report.UpdatedAt,
					UpdatedAt: new Date(),
					ModelSyncState: ModelSyncState.PRISTINE,
					PeriodDateStart: sequence.PeriodDateStart,
					PeriodDateEnd: sequence.PeriodDateEnd,
					Uuid: null
				};
				onHandleReportSequenceProps(reportSequenceViewModel, false, false);
			});
		}
	};

	const handleSequenceStartDateChange = (uuid: string, date: Date) => {
		const updatedReportDeviceSequences = [...reportDeviceSequences];
		const findsequence = reportDeviceSequences.find(sequence => sequence.Sequence.Uuid === uuid);
		findsequence.PeriodDateStart = date;

		setReportDeviceSequences(updatedReportDeviceSequences);

		const reportSequenceViewModel: ReportSequenceViewModel = {
			Report: report.Uuid,
			Sequence: findsequence.Sequence.Uuid,
			CreatedAt: report.UpdatedAt,
			UpdatedAt: new Date(),
			ModelSyncState: ModelSyncState.PRISTINE,
			PeriodDateStart: findsequence.PeriodDateStart,
			PeriodDateEnd: findsequence.PeriodDateEnd,
			Uuid: null
		};

		onHandleReportSequenceProps(reportSequenceViewModel, false, true);
	};

	const handleSequenceEndDateChange = (uuid: string, date: Date) => {
		const updatedReportDeviceSequences = [...reportDeviceSequences];
		const findsequence = reportDeviceSequences.find(sequence => sequence.Sequence.Uuid === uuid);
		findsequence.PeriodDateEnd = date;

		setReportDeviceSequences(updatedReportDeviceSequences);

		const reportSequenceViewModel: ReportSequenceViewModel = {
			Report: report.Uuid,
			Sequence: findsequence.Sequence.Uuid,
			CreatedAt: report.UpdatedAt,
			UpdatedAt: new Date(),
			ModelSyncState: ModelSyncState.PRISTINE,
			PeriodDateStart: findsequence.PeriodDateStart,
			PeriodDateEnd: findsequence.PeriodDateEnd,
			Uuid: null
		};

		onHandleReportSequenceProps(reportSequenceViewModel, false, true);
	};

	const renderSequences = (): Array<JSX.Element> | JSX.Element => {
		if (reportDeviceSequences.length > 0) {
			return (
				reportDeviceSequences.map((sequence: any): JSX.Element => {
					return (
						<ReportSequenceEntry
							key={sequence.Sequence.Uuid}
							sequenceStartDate={sequence.PeriodDateStart}
							sequenceEndDate={sequence.PeriodDateEnd}
							sequence={sequence.Sequence}
							isSequenceSelected={sequence.isSequenceSelected}
							report={report}
							checkSequence={handleSequenceSelect}
							onSequenceStartDateChange={handleSequenceStartDateChange}
							onSequenceEndDateChange={handleSequenceEndDateChange}
						/>
					);
				})
			);
		}
		return <CardEmpty message={device.Type === DeviceType.CLEARANCE ?
			l10nContext.translate('common.cards.emptye', 'Keine Messziele verfügbar') :
			l10nContext.translate('common.cards.emptye', 'Keine Messreihen verfügbar')}
		/>;
	};

	const renderDocuments = (): Array<JSX.Element> | JSX.Element => {
		if (reportDeviceDocuments.length > 0) {
			return (
				reportDeviceDocuments.map((document): JSX.Element => {
					return (
						<ReportDocumentEntry
							key={document.Document.Uuid}
							documentEntry={document.Document}
							checkDocument={handleDocumentSelect}
							isDocumentSelected={document.isDocumentSelected}
						/>
					);
				})
			);
		}
		return <CardEmpty message={l10nContext.translate('common.cards.emptyDefaul', 'Keine Dokumente verfügbar')} />;
	};

	return (
		<div className="report-card-device-with-sequences">
			<div className="report-card-device-with-sequences__content">

				<CardCollectionLayout>
					<ExpandableSlim expanded={reportDeviceSequences.length !== 0} mode="slim">
						<ExpandableSlimHeader
							caption={device.Type === DeviceType.CLEARANCE ?
								l10nContext.translate('view.device.headline.seque', 'Messziele') :
								l10nContext.translate('view.device.headline.sequences', 'Messreihen')}
							isSlimMode={true}
						/>
						<ExpandableSlimBody>
							<CardCollectionLayout>
								{ reportDeviceSequences.length !== 0 &&
									<Checkbox
										labelName={device.Type === DeviceType.CLEARANCE ?
											l10nContext.translate('view.reports.reportDevicesC', 'Alle Messziele auswählen') :
											l10nContext.translate('view.reports.reportDevicesC', 'Alle Messreihen auswählen')}
										checked={!isSelectAllSequencesChecked}
										onChange={handleAllSequencesChecked}
										isReportSelection
										disabled={!authContext.hasPermission(Permission.REPORT_UPDATE)}
									/>}
								{renderSequences()}
							</CardCollectionLayout>
						</ExpandableSlimBody>
					</ExpandableSlim>
				</CardCollectionLayout>

				<CardCollectionLayout>
					<ExpandableSlim expanded={documentsByDevice.length !== 0} mode="slim">
						<ExpandableSlimHeader
							caption={l10nContext.translate('view.device.head', 'Dokumente')}
							isSlimMode={true}
						/>
						<ExpandableSlimBody>
							<CardCollectionLayout>
								{documentsByDevice.length !== 0 &&
									<Checkbox
										labelName={l10nContext.translate('view.reports.reportDevicesC', 'Alle Dokumente auswählen')}
										checked={!isSelectAllDocumentsChecked}
										onChange={handleAllDocumentsChecked}
										isReportSelection
										disabled={!authContext.hasPermission(Permission.REPORT_UPDATE)}
									/>}
								{renderDocuments()}
							</CardCollectionLayout>
						</ExpandableSlimBody>
					</ExpandableSlim>
				</CardCollectionLayout>
			</div>
		</div>
	);
};
