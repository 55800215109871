import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from '../../../../lib/domain/model/Model';
import { ModelConverter } from '../../../../lib/domain/model/ModelConverter';
import { CyclotronProductModelState } from '../../../nuclide/domain/model/CyclotronProductModelState';
import { CyclotronProductIsotope } from '../../../nuclide/domain/model/isotopes/CyclotronProductIsotope';
import { NuclideModelActivityUnit } from '../../../nuclide/domain/model/NuclideModelActivityUnit';

export interface WasteManagementReportCyclotronProductPesistenceModel extends PersistenceModel {
	Name: string;
	State: string;
	Isotope: string;
	DeliveryDate: number;
	NominalActivity: number;
	ActivityUnit: string;
	Uuid: string;
}

export interface WasteManagementReportCyclotronProductStoreModel extends StoreModel {
	Name: string;
	State: string;
	Isotope: string;
	DeliveryDate: number;
	NominalActivity: number;
	ActivityUnit: string;
	Uuid: string;
}

export interface WasteManagementReportCyclotronProductViewModel extends ViewModel {
	Name: string;
	State: CyclotronProductModelState;
	Isotope: CyclotronProductIsotope;
	DeliveryDate: Date;
	NominalActivity: number;
	ActivityUnit: NuclideModelActivityUnit;
	Uuid: string;
}

export interface CreatableWasteManagementReportCyclotronProductViewModel extends CreatableViewModel {
	Name: string;
	State: CyclotronProductModelState;
	Isotope: CyclotronProductIsotope;
	DeliveryDate: Date;
	NominalActivity: number;
	ActivityUnit: NuclideModelActivityUnit;
	Uuid: string;
}

export class WasteManagementReportCyclotronProductModelConverter
	extends ModelConverter<WasteManagementReportCyclotronProductPesistenceModel,
	WasteManagementReportCyclotronProductStoreModel,
	WasteManagementReportCyclotronProductViewModel,
	CreatableWasteManagementReportCyclotronProductViewModel> {
	fromStoreModel(storeModel?: WasteManagementReportCyclotronProductStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			model.DeliveryDate = Math.floor(sModel.DeliveryDate / 1000);
		});
	}

	fromViewModel(viewModel?: WasteManagementReportCyclotronProductViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.DeliveryDate = Math.floor(vModel.DeliveryDate.getTime() / 1000);
			model.State = vModel?.State as string ?? null;
			model.Isotope = vModel?.Isotope as string ?? null;
			model.ActivityUnit = vModel?.ActivityUnit as string ?? null;
		});
	}

	toStoreModel(): WasteManagementReportCyclotronProductStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.DeliveryDate = model.DeliveryDate * 1000;
		});
	}

	toViewModel(): WasteManagementReportCyclotronProductViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.DeliveryDate = new Date(model.DeliveryDate * 1000);
			vModel.State = model?.State as CyclotronProductModelState ?? null;
			vModel.Isotope = model?.Isotope as CyclotronProductIsotope ?? null;
			vModel.ActivityUnit = model?.ActivityUnit as NuclideModelActivityUnit ?? null;
		});
	}
}
