import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';
import { FieldDescription } from 'services/device/domain/business/common/description/FieldDescription';

export interface TableCellLabelRendererProps {
	fieldDescriptions: ReadonlyArray<FieldDescription>;
}

export const TableCellLabelRenderer = (props: TableCellLabelRendererProps): JSX.Element => {
	const { fieldDescriptions } = props;

	const l10nContext = useContext(L10nContext);

	const outputNodes: Array<JSX.Element> = [];

	for (const fieldDescription of fieldDescriptions) {
		outputNodes.push(
			<th key={fieldDescription.name} className="sequence-table-head__row__cell sequence-table-head__row__cell--major">
				<span className="sequence-table-head__row__cell__value">
					{l10nContext.translate(fieldDescription.labelKey)}
				</span>
			</th>
		);
	}

	return (
		<>
			{outputNodes}
		</>
	);

};
