import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import {
	ActorModelConverter,
	ActorPersistenceModel,
	ActorStoreModel,
	ActorViewModel,
	CreatableActorViewModel
} from 'services/core/domain/model/ActorModel';
import {
	RoleModelConverter,
	RolePersistenceModel,
	RoleStoreModel,
	RoleViewModel
} from 'services/core/domain/model/RoleModel';

export interface MembershipPersistenceModel extends PersistenceModel {
	Uuid: string;
	Actor: ActorPersistenceModel;
	Roles: Array<RolePersistenceModel>;
}

export interface MembershipStoreModel extends StoreModel {
	Uuid: string;
	Actor: ActorStoreModel;
	Roles: Array<RoleStoreModel>;
}

export interface MembershipViewModel extends ViewModel {
	Uuid: string;
	Actor: ActorViewModel;
	Roles: Array<RoleViewModel>;
}

export interface CreatableMembershipViewModel extends CreatableViewModel {
	Uuid: string;
	Actor: CreatableActorViewModel;
	Roles: Array<RoleViewModel>;
}

export class MembershipModelConverter
	extends ModelConverter<MembershipPersistenceModel, MembershipStoreModel, MembershipViewModel, CreatableMembershipViewModel> {

	fromStoreModel(storeModel?: MembershipStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			if (Object.prototype.hasOwnProperty.call(sModel, 'Actor') && sModel.Actor !== null) {
				model.Actor = new ActorModelConverter().fromStoreModel(sModel.Actor).toPersistenceModel();
			}
			if (Object.prototype.hasOwnProperty.call(sModel, 'Roles') && sModel.Roles !== null) {
				model.Roles = sModel.Roles.map((role) => {
					return new RoleModelConverter().fromStoreModel(role).toPersistenceModel();
				});
			}
		});
	}

	fromViewModel(viewModel?: MembershipViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			if (Object.prototype.hasOwnProperty.call(vModel, 'Actor') && vModel.Actor !== null) {
				model.Actor = new ActorModelConverter().fromViewModel(vModel.Actor).toPersistenceModel();
			}
			if (Object.prototype.hasOwnProperty.call(vModel, 'Roles') && vModel.Roles !== null) {
				model.Roles = vModel.Roles.map((role) => {
					return new RoleModelConverter().fromViewModel(role).toPersistenceModel();
				});
			}
		});
	}

	toStoreModel(): MembershipStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			if (Object.prototype.hasOwnProperty.call(model, 'Actor') && model.Actor !== null) {
				sModel.Actor = new ActorModelConverter().fromPersistenceModel(model.Actor).toStoreModel();
			}
			if (Object.prototype.hasOwnProperty.call(model, 'Roles') && model.Roles !== null) {
				sModel.Roles = model.Roles.map((role: RolePersistenceModel) => {
					return new RoleModelConverter().fromPersistenceModel(role).toStoreModel();
				});
			}
		});
	}

	toViewModel(): MembershipViewModel | null {
		return super.toViewModel((model, vModel) => {
			if (Object.prototype.hasOwnProperty.call(model, 'Actor') && model.Actor !== null) {
				vModel.Actor = new ActorModelConverter().fromPersistenceModel(model.Actor).toViewModel();
			}
			if (Object.prototype.hasOwnProperty.call(model, 'Roles') && model.Roles !== null) {
				vModel.Roles = model.Roles.map((role: RolePersistenceModel) => {
					return new RoleModelConverter().fromPersistenceModel(role).toViewModel();
				});
			}
		});
	}

}
