import './loading-spinner-empty-card.scss';

export const LoadingSpinnerEmptyCard = (): JSX.Element => (
	<div className="loading-spinner-empty-card">
		<div className="loading-spinner__orbit-wrapper">
			<div className="loading-spinner__orbit" />
			<div className="loading-spinner__orbit" />
			<div className="loading-spinner__orbit" />
		</div>
	</div>
);
