import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { DatePicker } from 'presentation/ui/partials/input/date-picker/DatePicker';
import { DateTimePicker } from 'presentation/ui/compositions/date-time-picker/DateTimePicker';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';
import { getGeneratorMeasurementUnitDefinitions } from 'services/nuclide/domain/model/NuclideMeasurementUnitDefinition';

export interface GeneratorUpdateModalStep1Props {
	/**
	 * Generator
	 */
	generator: { [key: string]: any };
	/**
	 * Button next action
	 */
	onClickNext: () => void;
	/**
	 * Invalid inputs
	 */
	invalidInputs?: string[];
	/**
	 * Change generator props
	 */
	onChangeGeneratorProp: (prop: string, value: string | Date | number) => void;
}

/**
 * The generator update modal step 1
 */
export const GeneratorUpdateFormStep1 = (props: GeneratorUpdateModalStep1Props): JSX.Element => {
	const { generator, invalidInputs = [], onChangeGeneratorProp, onClickNext } = props;

	// Consume the contexts
	const l10nContext = useContext(L10nContext);

	// Notification for invalid input fields
	const checkPlural = invalidInputs.length > 1 ? l10nContext.translate('common.modal.notificationErrorPlural', ' dürfen nicht leer sein') : l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein');
	let notification = null;
	if (invalidInputs.length > 0) {
		const invalidNotificationMessage = invalidInputs.join(l10nContext.translate('common.text.and', ' und '))
			+ checkPlural;
		notification = (
			<NotificationBar
				level={NotificationLevel.LEVEL_ERROR}
				message={invalidNotificationMessage}
			/>
		);
	}

	const generatorMeasurementUnitDefinitions = getGeneratorMeasurementUnitDefinitions();
	const generatorMeasurementUnits = generatorMeasurementUnitDefinitions.map((generatorMeasurementUnitDefinition): SelectOption => {

		return {
			label: l10nContext.translate(generatorMeasurementUnitDefinition.getLabelKey()),
			value: generatorMeasurementUnitDefinition.getMeasurementUnit(),
			checked: generatorMeasurementUnitDefinition.getMeasurementUnit() === generator?.ActivityUnit ?? false,
		};
	});

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<NotificationBars>
				{notification}
			</NotificationBars>
			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.name', 'Name')}*`}
				>
					<InputText
						type="text"
						defaultValue={generator?.Name ?? ''}
						onChange={(event) => onChangeGeneratorProp('Name', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.manufacturer', 'Hersteller')}*`}
				>
					<InputText
						type="text"
						defaultValue={generator?.Manufacturer ?? ''}
						onChange={(event) => onChangeGeneratorProp('Manufacturer', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.batchNumber', 'Chargennummer')}*`}
				>
					<InputText
						type="text"
						defaultValue={generator?.BatchNumber ?? ''}
						onChange={(event) => onChangeGeneratorProp('BatchNumber', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.serialNumber', 'Seriennummer')}*`}
				>
					<InputText
						type="text"
						defaultValue={generator?.SerialNumber ?? ''}
						onChange={(event) => onChangeGeneratorProp('SerialNumber', event.target.value)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('generators.deliveryDate', 'Lieferdatum')}*`}>
					<DatePicker
						defaultValue={(generator?.DeliveryDate) ?? new Date()}
						onChange={(event) => onChangeGeneratorProp('DeliveryDate', event.target.valueAsDate)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.nominalActivity', 'Nominalaktivität')}*`}
				>
					<InputText
						type='number'
						defaultValue={generator?.NominalActivity ?? ''}
						onChange={(event) => onChangeGeneratorProp('NominalActivity', Number(event.target.value))}
						step='any'
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.unit', 'Einheit')}*`}
				>
					<Select
						options={generatorMeasurementUnits}
						onchange={(optionValue: string) => onChangeGeneratorProp('ActivityUnit', optionValue)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('generators.calibrationDateTime', 'Kalibrierzeitpunkt')}*`}>
					<DateTimePicker
						name='calibrationDatetime'
						defaultValue={generator?.CalibrationDatetime ?? new Date()}
						onChange={(event) => onChangeGeneratorProp('CalibrationDatetime', event)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
