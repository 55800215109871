import { useOnClickOutside } from 'presentation/hooks/click-outside';
import { ButtonGhost, IconPosition } from 'presentation/ui/partials/button/button-ghost/ButtonGhost';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { FunctionComponent, useCallback, useContext, useRef, useState } from 'react';
import { AuthContext } from '../../../../../../services/core/context/AuthContext';
import { Permission } from '../../../../../../services/core/lib/auth/AuthService';

import './card-item-folder-controls.scss';

export enum CardItemFolderControlType {
	DETAILS = 'DETAILS',
	EDIT = 'EDIT',
	MOVE = 'MOVE'
}

export interface CardItemFolderControlsProps {
	/**
	 * Id of controlled card
	 */
	cardId: string;
	/**
	 * (Optional) Click handler
	 */
	onClick?: (action: CardItemControlActionType) => void;
	/**
	 * (Optional) Move entries action state
	 */
	actionMove?: CardItemControlState;
	/**
	 * (Optional) Edit action state
	 */
	actionEdit?: CardItemControlState;
	/**
	* (Optional) Details action state
	*/
	actionDetails?: CardItemControlState;
}

/**
 * A card item controls component
 */
export const CardItemFolderControls: FunctionComponent<CardItemFolderControlsProps> = (props): JSX.Element => {
	const { cardId, onClick, actionMove, actionEdit, actionDetails, children } = props;

	const authContext = useContext(AuthContext);

	const [selectOpenState, setSelectOpenState] = useState<boolean>(false);

	const selectInstance = useRef(null);
	const selectToggleTrigger = useRef(null);

	useOnClickOutside(selectInstance, 'icon-move', () => {
		setSelectOpenState(false);
	});

	const onClickHandler = useCallback((action: string) => {
		if (!onClick) return;

		onClick({
			type: action,
			cardId
		});
	}, [onClick, cardId]);

	const onToggleSelect = () => {
		setSelectOpenState(!selectOpenState);
	};

	const buttonList = actionMove ?
		<ButtonIcon
			weight={ButtonIconWeight.SECONDARY}
			icon={IconIdentifier.MOVE}
			shape={ButtonIconShape.ROUND}
			disabled={actionMove === CardItemControlState.DISABLED || !authContext.hasPermission(Permission.DOCUMENT_FOLDER_UPDATE)}
			onClick={() => {
				selectInstance.current ? onToggleSelect() : onClickHandler(CardItemFolderControlType.MOVE);
			}}
		/> :
		null;

	selectToggleTrigger.current = buttonList;

	const buttonEdit = actionEdit ?
		<ButtonIcon
			weight={ButtonIconWeight.SECONDARY}
			icon={IconIdentifier.EDIT}
			shape={ButtonIconShape.ROUND}
			disabled={actionEdit === CardItemControlState.DISABLED || !authContext.hasPermission(Permission.DOCUMENT_FOLDER_UPDATE)}
			onClick={() => onClickHandler(CardItemFolderControlType.EDIT)}
		/> :
		null;

	const buttonDetails = actionDetails && !actionMove && !actionEdit ?
		<ButtonGhost
			buttonText='Details'
			icon={IconIdentifier.ARROW_RIGHT}
			iconPosition={IconPosition.RIGHT}
			disabled={actionEdit === CardItemControlState.DISABLED}
			onClick={() => onClickHandler(CardItemFolderControlType.DETAILS)}
		/> :
		null;

	const select = children ?
		<div
			ref={selectInstance}
			className={`card-item-folder-controls__select${selectOpenState ? ' select--open' : ''} `}
			onClick={() => setSelectOpenState(!selectOpenState)}
			role='button'
			tabIndex={0}
		>
			{children}
		</div> :
		null;

	return (
		<div className='card-item-folder-controls'>
			{buttonList}
			{buttonEdit}
			{buttonDetails}

			{select}
		</div>
	);
};
