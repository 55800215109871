import { ComponentPropsWithoutRef } from 'react';

import { ClassName } from 'lib/class-name/ClassName';

import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';

export interface ColumnLayoutSectionProps extends ComponentPropsWithoutRef<'section'> {
	mode: ColumnLayoutSectionMode;
}

export const ColumnLayoutSection = (props: ColumnLayoutSectionProps): JSX.Element => {

	return (
		<section className={`column-layout-section column-layout-section--${ClassName.fromEnumValue(String(props.mode))}`}>
			{props.children}
		</section>
	);

};
