import { DatePicker } from 'presentation/ui/partials/input/date-picker/DatePicker';
import { TimePicker } from 'presentation/ui/partials/input/time-picker/TimePicker';
import { ChangeEvent } from 'react';

import './date-time-picker.scss';

export interface DateTimePickerProps {
	name: string;
	required: boolean;
	/**
	 * (Predefined) date
	 */
	defaultValue?: Date | null;
	/**
	 * (Optional) Max value
	 */
	max?:Date;
	/**
	 * (Optional) onChange-handler
	 */
	onChange?: (date: Date) => void;
}

/**
 * A wrapper for DatePicker and TimePicker component
 */
export const DateTimePicker = (props: DateTimePickerProps): JSX.Element => {
	const { name, required = false, defaultValue = null, max, onChange } = props;

	const selectedDate = {
		date: defaultValue,
		time: [
			defaultValue?.getHours() ?? null,
			defaultValue?.getMinutes() ?? null
		]
	};

	const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
		selectedDate.date = event.target.valueAsDate;

		handleChange();
	};

	const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
		const selectedTime = event.target.value.split(':');

		selectedDate.time = [
			Number(selectedTime[0]),
			Number(selectedTime[1])
		];

		handleChange();
	};

	const handleChange = () => {
		if (!onChange) {
			return;
		}

		onChange(new Date(
			selectedDate.date.getFullYear(),
			selectedDate.date.getMonth(),
			selectedDate.date.getDate(),
			selectedDate.time[0],
			selectedDate.time[1]
		));
	};

	return (
		<div className="date-time-picker">
			<DatePicker
				name={`${name}_date`}
				defaultValue={defaultValue}
				required={required}
				onChange={handleDateChange}
				max={max}
			/>
			<TimePicker
				name={`${name}_time`}
				defaultValue={defaultValue}
				required={required}
				onChange={handleTimeChange}
			/>
		</div>
	);
};
