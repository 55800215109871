import { FieldDescription } from 'services/device/domain/business/common/description/FieldDescription';
import { Record } from 'services/device/domain/business/common/record/Record';
import { OutputListSelection } from 'services/device/presentation/ui/widget/widget-renderer/OutputListSelection';

export interface TableCellValueRendererProps {
	record: Record;
	fieldDescriptions: ReadonlyArray<FieldDescription>;
}

export const TableCellValueRenderer = (props: TableCellValueRendererProps): JSX.Element => {
	const { record, fieldDescriptions } = props;

	const outputNodes: Array<JSX.Element> = [];

	for (const fieldDescription of fieldDescriptions) {
		record.getOutput(fieldDescription).selectOutput(
			new OutputListSelection((element): void => {
				outputNodes.push(
					<td key={fieldDescription.name} className="sequence-table__body__row__cell">
						{element}
					</td>
				);
			})
		);
	}

	return (
		<>
			{outputNodes}
		</>
	);

};
