import { L10nContext } from 'context/L10nContext';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from 'services/core/context/AuthContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Guid } from '../../../../lib/guid/Guid';
import { CardEmpty } from '../../../../presentation/ui/components/cards/card-empty/CardEmpty';
import { CardNuclideListItem } from '../../../../presentation/ui/components/cards/card-nuclide/card-nuclide-list-item/CardNuclideListItem';
import { ExpandableSlimBody } from '../../../../presentation/ui/compositions/expandable-slim/expandable-slim-body/ExpandableSlimBody';
import { ExpandableSlimHeader } from '../../../../presentation/ui/compositions/expandable-slim/expandable-slim-header/ExpandableSlimHeader';
import { ExpandableSlim } from '../../../../presentation/ui/compositions/expandable-slim/ExpandableSlim';
import { CardCollectionLayout } from '../../../../presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { Separator } from '../../../../presentation/ui/partials/separator/Separator';
import { Route } from '../../../../router/Route';
import { AsyncReducerStatus } from '../../../../store/common/AsyncReducerStatus';
import { useTypedSelector } from '../../../../store/common/TypedSelector';
import { ClientContext } from '../../../core/context/ClientContext';
import { Permission, permissionGroupWasteManagement } from '../../../core/lib/auth/AuthService';
import { ContainerViewModel } from '../../domain/model/ContainerModel';
import { ContainerModelState } from '../../domain/model/ContainerModelState';
import { CyclotronProductViewModel } from '../../domain/model/CyclotronProductModel';
import { EluatePayloadType, EluateViewModel } from '../../domain/model/EluateModel';
import { GeneratorEluateIsotope } from '../../domain/model/isotopes/GeneratorEluateIsotope';
import { GeneratorTypeIsotope } from '../../domain/model/isotopes/GeneratorTypeIsotope';
import { getNuclideIsotopeDefinition } from '../../domain/model/isotopes/NuclideIsotopeDefinition';
import { resetActionStatus as resetContainerActionStatus, selectContainerByUuid } from '../../store/containerSlice';
import {
	resetActionStatus as resetCyclotronProductActionStatus,
	selectInWastemanagementCyclotronProducts
} from '../../store/cyclotronProductSlice';
import { resetActionStatus as resetEluateActionStatus, selectInWastemanagementEluates } from '../../store/eluateSlice';
import { selectGenerators } from '../../store/generatorSlice';
import {
	ContainerContaminationMeasurementModal
} from '../ui/container-action/container-contamination-measurement-modal/ContainerContaminationMeasurementModal';
import { ContainerDeleteModal } from '../ui/container-action/container-delete-modal/ContainerDeleteModal';
import { ContainerDispatchModal } from '../ui/container-action/container-dispatch-modal/ContainerDispatchModal';
import { ContainerDisposeModal } from '../ui/container-action/container-dispose-modal/ContainerDisposeModal';
import { ContainerReleaseModal } from '../ui/container-action/container-release-modal/ContainerReleaseModal';
import { ContainerSelectMeasurementModal } from '../ui/container-action/container-select-measurement-modal/ContainerSelectMeasurementModal';
import { ContainerUpdateModal } from '../ui/container-action/container-update-modal/ContainerUpdateModal';
import { ContainerCard } from '../ui/container-card/ContainerCard';
import {
	CyclotronProductToSingleNuclidesModal
} from '../ui/cyclotron-product-action/cyclotron-product-to-single-nuclides-modal/CyclotronProductToSingleNuclidesModal';
import { EluateToSingleNuclidesModal } from '../ui/eluate-action/eluate-to-single-nuclides-modal/EluateToSingleNuclidesModal';
import { useController } from './ContainerViewController';
import { ContainerViewModalType } from './ContainerViewModalType';
import { CyclotronProductViewModalType } from './CyclotronProductViewModalType';
import { GeneratorViewModalType } from './GeneratorViewModalType';

interface ContainerViewParams {
	containerUuid: string,
}

export const ContainerView = (): JSX.Element => {

	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Unwrap the required Container uuid from the route
	const params = useParams<ContainerViewParams>();
	const containerUuid = params?.containerUuid ?? null;
	if (containerUuid === null) {
		throw new Error('Container id is missing');
	}

	// Consume the history object
	const history = useHistory();
	const controller = useController();

	// Handle permissions
	if (!authContext.hasAnyPermission(permissionGroupWasteManagement)) {
		throw new Error('Permission denied');
	}

	// Local state
	const [modalType, setModalType] = useState<ContainerViewModalType | GeneratorViewModalType | CyclotronProductViewModalType>(null);
	const [modalPayload, setModalPayload] = useState<ContainerViewModel | EluatePayloadType | CyclotronProductViewModel>(null);

	// Use a custom selector function to provide type information for the state defined in the slice
	const containerActionStatus = useTypedSelector(state => state.containers.actionStatus);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Handle close modal for success and failure
	const handleDismiss = () => {
		setModalType(null);
	};

	useEffect(() => {
		if (controller.containerActionStatus === AsyncReducerStatus.DELETED) {
			dispatch(resetContainerActionStatus());
			history.replace(Route.NUCLIDE_MANAGEMENT + '+' + Route.WASTE_MANAGEMENT);
		}
	}, [controller.containerActionStatus, dispatch, history]);

	const container = useSelector(selectContainerByUuid(containerUuid));

	const inWastemanagementCyclotronProducts = useSelector(selectInWastemanagementCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementEluates = useTypedSelector(selectInWastemanagementEluates());

	const generators = useTypedSelector(selectGenerators(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	inWastemanagementEluates.forEach(eluate => {

		const inWastemanagementEluateGenerator = generators.find((generator) => {
			return generator.Uuid === eluate.Generator;
		});
		if (inWastemanagementEluateGenerator.Isotope === GeneratorTypeIsotope.MOLYBDENUM_99_MO) {
			eluate.Isotope = GeneratorEluateIsotope.TECHNETIUM_99M_TC;
		} else {
			eluate.Isotope = GeneratorEluateIsotope.GALLIUM_68_GA;
		}
	});

	const inWastemanagementNuclides: Array<EluatePayloadType | CyclotronProductViewModel> =
		[].concat(inWastemanagementCyclotronProducts, inWastemanagementEluates);

	const containerNuclidesIds = container?.CyclotroneProductIds.concat(container.GeneratorEluatIds);

	const containerNuclides = inWastemanagementNuclides.filter(item => containerNuclidesIds?.includes(item.Uuid));

	const nuclides: string[] = [];
	if (containerNuclides.length > 0) {
		// eslint-disable-next-line array-callback-return
		containerNuclides.map((item) => {
			if (!nuclides.includes(item.Isotope)) {
				nuclides.push(item.Isotope);
			}
		});
	}

	const renderNuclidesByIsotope = () => {
		if (containerNuclides.length === 0) {
			return <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.containerNuclides', 'Keine Nuklide im Gebinde')} />;
		}

		return (
			nuclides.sort().map((nuclide) => {

				return (
					<CardCollectionLayout key={Guid.generate()}>
						<ExpandableSlim mode="slim" expanded>
							<ExpandableSlimHeader
								caption={l10nContext.translate(getNuclideIsotopeDefinition(nuclide).getLabelKey())}
								isSlimMode={true}
							/>
							<ExpandableSlimBody>
								<CardCollectionLayout>
									<CardNuclideListItem
										nuclide={nuclide}
										nuclides={containerNuclides}
										container={container}
										onActionClick={(payload, item) => handleModalClick(payloadType(item), item)}
									/>
								</CardCollectionLayout>

							</ExpandableSlimBody>
						</ExpandableSlim>
					</CardCollectionLayout>
				);

			})
		);
	};

	useEffect(() => {
		if (containerActionStatus === AsyncReducerStatus.DELETED) {
			dispatch(resetContainerActionStatus());
			history.replace(Route.NUCLIDE_MANAGEMENT + Route.WASTE_MANAGEMENT);
		}
	}, [container, containerActionStatus, dispatch, history]);

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permission: {
			update: authContext.hasPermission(Permission.WASTE_MANAGEMENT_UPDATE),
			delete: authContext.hasPermission(Permission.WASTE_MANAGEMENT_DELETE)
		}
	};

	function isCyclotronProductPayloadType(obj: any): obj is CyclotronProductViewModel {
		return !!(obj as CyclotronProductViewModel).Radiopharmaceutical;
	}

	function payloadType(obj: any): GeneratorViewModalType | CyclotronProductViewModalType {
		if (isCyclotronProductPayloadType(obj)) {
			return CyclotronProductViewModalType.TO_ACTIVE_STATE;
		}
		return GeneratorViewModalType.TO_ACTIVE_STATE_ELUATE;
	}

	const handleModalClick = (
		type: ContainerViewModalType | CyclotronProductViewModalType | GeneratorViewModalType,
		payload: ContainerViewModel | EluatePayloadType | CyclotronProductViewModel = null
	) => {
		dispatch(resetContainerActionStatus());
		dispatch(resetCyclotronProductActionStatus());
		dispatch(resetEluateActionStatus());

		setModalType(type);
		setModalPayload(payload);
	};

	const showModal = (): JSX.Element => {
		let modal: JSX.Element = null;

		if (modalType === ContainerViewModalType.EDIT) {
			modal =
				<ContainerUpdateModal
					container={modalPayload as ContainerViewModel}
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === ContainerViewModalType.MEASURE) {
			modal =
				<ContainerSelectMeasurementModal
					container={modalPayload as ContainerViewModel}
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === ContainerViewModalType.CONTAMINATION_MEASUREMENT) {
			modal =
				<ContainerContaminationMeasurementModal
					container={modalPayload as ContainerViewModel}
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === ContainerViewModalType.DISPATCH) {
			modal =
				<ContainerDispatchModal
					container={modalPayload as ContainerViewModel}
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === ContainerViewModalType.RELEASE) {
			modal =
				<ContainerReleaseModal
					container={modalPayload as ContainerViewModel}
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === ContainerViewModalType.DISPOSE) {
			modal =
				<ContainerDisposeModal
					container={modalPayload as ContainerViewModel}
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === ContainerViewModalType.DELETE) {
			modal =
				<ContainerDeleteModal
					container={modalPayload as ContainerViewModel}
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === GeneratorViewModalType.TO_ACTIVE_STATE_ELUATE) {
			modal =
				<EluateToSingleNuclidesModal
					container={container}
					eluate={modalPayload as EluateViewModel}
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === CyclotronProductViewModalType.TO_ACTIVE_STATE) {
			modal =
				<CyclotronProductToSingleNuclidesModal
					container={container}
					cyclotronProduct={modalPayload as CyclotronProductViewModel}
					onDismiss={handleDismiss}
				/>;
		}

		return modal;
	};

	const containerCard =
		<ContainerCard
			container={container}
			userPermissionEdit={authContext.hasPermission(Permission.WASTE_MANAGEMENT_UPDATE)}
			onClick={() => handleModalClick(ContainerViewModalType.EDIT, container)}
		/>;

	const measureContainerButton = container?.State === ContainerModelState.OPEN ?
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.measureContainer', 'Gebinde berechnen')}
			icon={IconIdentifier.DISPATCH}
			onClick={() => handleModalClick(ContainerViewModalType.MEASURE, container)}
			disabled={!authContext.hasPermission(Permission.WASTE_MANAGEMENT_UPDATE)}
		/> : null;

	const dispatchButton = container?.State === ContainerModelState.DECAY && !container?.MeasurementContaminationDateTime ?
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.dispatch', 'Abgang erfassen')}
			icon={IconIdentifier.DISPATCH}
			onClick={() => handleModalClick(ContainerViewModalType.DISPATCH, container)}
			disabled={!authContext.hasPermission(Permission.CONTAINER_DISPATCH_CREATE)}
		/> : null;

	const contaminationMeasurementButton = container?.State === ContainerModelState.DECAY && !container?.MeasurementContaminationDateTime ?
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.contaminationMeasurement', 'Dosis-Leistungsmessung')}
			icon={IconIdentifier.DISPATCH}
			onClick={() => handleModalClick(ContainerViewModalType.CONTAMINATION_MEASUREMENT, container)}
			disabled={container.ReleaseDateTime > new Date() || !authContext.hasPermission(Permission.WASTE_MANAGEMENT_UPDATE)}
		/> : null;

	const containerReleaseButton = container?.State === ContainerModelState.DECAY && container?.MeasurementContaminationDateTime ?
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.containerRelease', 'Freigabe durch SSB')}
			icon={IconIdentifier.DISPATCH}
			onClick={() => handleModalClick(ContainerViewModalType.RELEASE, container)}
			disabled={!authContext.hasPermission(Permission.WASTE_MANAGEMENT_UPDATE)}
		/> : null;

	const containerDisposeButton = container?.State === ContainerModelState.RELEASE_DATE_REACHED ?
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.containerDispose', 'Gebinde entsorgen')}
			icon={IconIdentifier.DISPATCH}
			onClick={() => handleModalClick(ContainerViewModalType.DISPOSE, container)}
			disabled={!authContext.hasPermission(Permission.CONTAINER_DISPATCH_CREATE)}
		/> : null;

	const deleteButton = container?.State === ContainerModelState.DECAY && user.permission.delete ?
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.delete', 'Löschen')}
			icon={IconIdentifier.TRASH}
			status={ButtonSecondaryStatus.ATTENTION}
			disabled={!authContext.hasPermission(Permission.WASTE_MANAGEMENT_DELETE)}
			onClick={() => handleModalClick(ContainerViewModalType.DELETE, container)}
		/> : null;

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs
						mapURLFragments={[[containerUuid, container?.Uuid]]}
					/>
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection
				section={MainLayoutSectionSection.SECTION_MAIN}
			>
				<ViewLayout>

					<ViewLayoutSection>
						{showModal()}
						{containerCard}
					</ViewLayoutSection>

					<ViewLayoutSection>
						{renderNuclidesByIsotope()}
					</ViewLayoutSection>

					<ViewLayoutSection>
						<Separator />
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ColumnLayout>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{measureContainerButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{contaminationMeasurementButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{containerReleaseButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
								{containerDisposeButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
								{dispatchButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{deleteButton}
							</ColumnLayoutSection>
						</ColumnLayout>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
