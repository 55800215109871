import { L10nContext } from 'context/L10nContext';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { GeneratorModelState } from 'services/nuclide/domain/model/GeneratorModelState';
import { getGeneratorMeasurementUnitDefinition } from 'services/nuclide/domain/model/NuclideMeasurementUnitDefinition';

import 'services/nuclide/presentation/ui/generator-table/generator-table-body/generator-table-body.scss';
import { selectEluatesByGenerator } from 'services/nuclide/store/eluateSlice';
import { EluateModelState } from '../../../../domain/model/EluateModelState';

export interface GeneratorTableBodyProps {
	/**
	 * Generator
	 */
	generator?: GeneratorViewModel,
	eluatesDate: string,
	onEdit?: (eluate: EluateViewModel) => void,
	onToWastemanagement?: (eluate: EluateViewModel) => void,
	onDelete?: (eluate: EluateViewModel) => void
}

export const GeneratorTableBody = (props: GeneratorTableBodyProps): JSX.Element | null => {
	const { generator, eluatesDate, onEdit, onToWastemanagement, onDelete } = props;

	// Consume the contexts
	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);

	const eluates = useSelector(selectEluatesByGenerator(generator));

	const renderActionCell = (eluate: EluateViewModel): JSX.Element | null => {
		if (
			!authContext.hasPermission(Permission.GENERATOR_ELUAT_UPDATE)
			&& !authContext.hasPermission(Permission.GENERATOR_ELUAT_DELETE)
		) {
			return null;
		}

		const editButton = (): JSX.Element | null => {
			if (!authContext.hasPermission(Permission.GENERATOR_ELUAT_UPDATE)) {
				return null;
			}
			return (
				<ButtonIcon
					icon={IconIdentifier.EDIT}
					weight={ButtonIconWeight.GHOST}
					shape={ButtonIconShape.ROUND}
					onClick={(event) => {
						event.stopPropagation();
						onEdit(eluate);
					}}
					disabled={
						generator.State !== GeneratorModelState.ACTIVE || eluate.State !== EluateModelState.ACTIVE
					}
				/>
			);
		};

		const deleteButton = (): JSX.Element | null => {
			if (!authContext.hasPermission(Permission.GENERATOR_ELUAT_DELETE)) {
				return null;
			}
			return (
				<ButtonIcon
					icon={IconIdentifier.TRASH}
					weight={ButtonIconWeight.GHOST}
					shape={ButtonIconShape.ROUND}
					onClick={(event) => {
						event.stopPropagation();
						onDelete(eluate);
					}}
					disabled={
						generator.State !== GeneratorModelState.ACTIVE || eluate.State !== EluateModelState.ACTIVE
					}
				/>
			);
		};

		const toWastemanagementButton = (): JSX.Element | null => {
			if (!authContext.hasPermission(Permission.GENERATOR_ELUAT_UPDATE)) {
				return null;
			}
			return (
				<ButtonIcon
					icon={IconIdentifier.IN_WASTE}
					weight={ButtonIconWeight.GHOST}
					shape={ButtonIconShape.ROUND}
					onClick={(event) => {
						event.stopPropagation();
						onToWastemanagement(eluate);
					}}
					disabled={
						generator.State !== GeneratorModelState.ACTIVE || eluate.State !== EluateModelState.ACTIVE
					}
				/>
			);
		};

		return (
			<td className='generator-table-body__row__cell generator-table-body__row__cell--sticky-end'>
				<span className='generator-table-body__row__cell__actions'>
					{editButton()}
					{deleteButton()}
					{toWastemanagementButton()}
				</span>
			</td>
		);
	};

	const rows = eluates.map((eluate: EluateViewModel): JSX.Element => {
		const formattedExtractionDatetime = l10nContext.formatDateTime(eluate.ExtractionDatetime);

		const activityUnit = l10nContext.translate(getGeneratorMeasurementUnitDefinition(eluate.ActivityUnit).getLabelKey());

		if (eluatesDate === l10nContext.formatDate(eluate.ExtractionDatetime) && eluate.State === EluateModelState.ACTIVE) {
			return (
				<tr
					key={eluate.Uuid}
					className='generator-table-body__row'
				>
					<td className='generator-table-body__row__cell generator-table-body__row__cell--sticky-start'>
						<div>
							<span>
								{l10nContext.formatNumber(eluate.Activity)}{' ' + activityUnit}
							</span>
						</div>
					</td>
					<td className='generator-table-body__row__cell'>
						{formattedExtractionDatetime}
					</td>
					<td className='generator-table-body__row__cell'>
						{eluate.ExtractedByName}
					</td>
					{renderActionCell(eluate)}
				</tr>
			);
		}
		return null;
	});

	return (
		<tbody className='generator-table-body'>
			{rows}
		</tbody>
	);
};
