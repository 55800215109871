import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutHeader } from 'presentation/ui/layouts/form-layout/form-layout-header/FormLayoutHeader';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutModifier } from 'presentation/ui/layouts/form-layout/FormLayoutModifier';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { InputText, InputTextStatus } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { CreatableMembershipViewModel } from 'services/core/domain/model/MembershipModel';
import { Checkbox } from 'presentation/ui/partials/input/checkbox/Checkbox';
import { CheckboxesLayout } from 'presentation/ui/layouts/checkboxes-layout/CheckboxesLayout';

export interface MembershipCreateModalStep3Props {
	/**
	 * Membership
	 */
	membership: CreatableMembershipViewModel;
	/**
	 * Button back action
	 */
	onClickPrevious: () => void;
	/**
	 * Button confirm
	 */
	onClickConfirm: () => void;
}

/**
 * The membership create modal step 3 component
 */
export const MembershipCreateFormStep3 = (props: MembershipCreateModalStep3Props): JSX.Element => {
	const { membership, onClickPrevious, onClickConfirm } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	const showChoosedRoles = membership.Roles.map((role) => {
		return (

			<Checkbox
				key={role.Uuid}
				labelName={role.Name}
				disabled
				isSummary
			/>
		);
	});

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			modifier={FormLayoutModifier.BLUE_BACKGROUND}
			onSubmit={onClickConfirm}
		>
			<FormLayoutHeader
				text={l10nContext.translate('common.modal.checkDetails', 'Überprüfen Sie bitte Ihre Angaben')}
				icon={IconIdentifier.WARNING}
			/>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('memberships.realName', 'Name')}*`}
					thin
				>
					<InputText
						type='text'
						value={membership.Actor?.Realname}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('memberships.password', 'Passwort')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={membership.Actor?.Password ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('memberships.email', 'E-Mail')}
					thin
				>
					<InputText
						type='text'
						defaultValue={membership.Actor?.MailAddress ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('memberships.userName', 'Benutzername')}
					thin
				>
					<InputText
						type='text'
						value={membership.Actor?.Username ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('memberships.memberships', 'Rolle(n)')}
					thin
				>
					<CheckboxesLayout>
						{membership.Roles ? showChoosedRoles : null}
					</CheckboxesLayout>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.back', 'Zurück')}
							onClick={onClickPrevious}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('view.memberships.modal.action.add.caption', 'Nutzer hinzufügen')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
