import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from '../../../../lib/domain/model/Model';
import { ModelConverter } from '../../../../lib/domain/model/ModelConverter';
import { EluateModelState } from '../../../nuclide/domain/model/EluateModelState';
import { NuclideModelActivityUnit } from '../../../nuclide/domain/model/NuclideModelActivityUnit';

export interface WasteManagementReportEluatePersistenceModel extends PersistenceModel {
	Generator: string;
	State: string;
	ExtractionDatetime: number;
	Activity: number;
	ActivityUnit: string;
	Uuid: string;
}

export interface WasteManagementReportEluateStoreModel extends StoreModel {
	Generator: string;
	State: string;
	ExtractionDatetime: number;
	Activity: number;
	ActivityUnit: string;
	Uuid: string;
}

export interface WasteManagementReportEluateViewModel extends ViewModel {
	Generator: string;
	State: EluateModelState;
	ExtractionDatetime: Date;
	Activity: number;
	ActivityUnit: NuclideModelActivityUnit;
	Uuid: string;
}

export interface CreatableWasteManagementReportEluateViewModel extends CreatableViewModel {
	Generator: string;
	State: EluateModelState;
	ExtractionDatetime: Date;
	Activity: number;
	ActivityUnit: NuclideModelActivityUnit;
	Uuid: string;
}

export class WasteManagementReportEluateModelConverter
	extends ModelConverter<WasteManagementReportEluatePersistenceModel,
	WasteManagementReportEluateStoreModel,
	WasteManagementReportEluateViewModel,
	CreatableWasteManagementReportEluateViewModel> {
	fromStoreModel(storeModel?: WasteManagementReportEluateStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			model.ExtractionDatetime = Math.floor(sModel.ExtractionDatetime / 1000);
		});
	}

	fromViewModel(viewModel?: WasteManagementReportEluateViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.ExtractionDatetime = Math.floor(vModel.ExtractionDatetime.getTime() / 1000);
			model.State = vModel?.State as string ?? null;
			model.ActivityUnit = vModel?.ActivityUnit as string ?? null;
		});
	}

	fromCreatableViewModel(creatableViewModel?: CreatableWasteManagementReportEluateViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			model.Generator = null;
			model.State = EluateModelState.ACTIVE;
			model.ExtractionDatetime = null;
			model.Activity = null;
		});
	}

	toStoreModel(): WasteManagementReportEluateStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.ExtractionDatetime = model.ExtractionDatetime * 1000;
		});
	}

	toViewModel(): WasteManagementReportEluateViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.ExtractionDatetime = new Date(model.ExtractionDatetime * 1000);
			vModel.State = model?.State as EluateModelState ?? null;
			vModel.ActivityUnit = model?.ActivityUnit as NuclideModelActivityUnit ?? null;
		});
	}
}

