import { useTypedSelector } from 'store/common/TypedSelector';

// eslint-disable-next-line
export const useController = () => {
	const containerActionStatus = useTypedSelector(state => state.containers.actionStatus);
	const storeActionStatus = useTypedSelector(state => state.containers.actionStatus);
	const storeLastActionError = useTypedSelector(state => state.containers.lastActionError);

	return {
		containerActionStatus,

		storeActionStatus,
		storeLastActionError
	};
};
