import { L10nContext } from 'context/L10nContext';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SimpleViewTitle } from 'services/core/presentation/ui/simple-view-title/SimpleViewTitle';
import { ExternalWasteManagementReportAuthContext } from '../../../context/ExternalWasteManagementReportAuthContext';

import './login.scss';

interface ReportViewParams {
	reportUuid: string;
}

export const ExternalWasteManagementReportLogin = (): JSX.Element => {

	const authContext = useContext(ExternalWasteManagementReportAuthContext);
	const l10nContext = useContext(L10nContext);

	const [password, setPassword] = useState<string>('');
	const [notification, setNotification] = useState<string>('');

	// Unwrap the required report uuid from the route
	const params = useParams<ReportViewParams>();

	const reportUuid = params.reportUuid ?? null;

	if (reportUuid === null) {
		throw new Error('Report id is missing');
	}

	const handleAuthentication = async (): Promise<void> => {

		const loginSuccess = await authContext.authenticate(password);
		if (!loginSuccess) {
			setNotification(l10nContext.translate('view.login.notificationError', 'Bitte überprüfen Sie Ihre Login-Daten'));
		}
	};

	const notificationBar = notification ?
		<NotificationBar
			message={notification}
			level={NotificationLevel.LEVEL_ERROR}
		/> :
		null;

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_VERTICAL}
			onSubmit={handleAuthentication}
		>
			<FormLayoutSection>
				<NotificationBars>
					{notificationBar}
				</NotificationBars>
				<SimpleViewTitle
					label={l10nContext.translate('view.login.title', 'Einloggen')}
				/>
			</FormLayoutSection>
			<FormLayoutSection>
				<Label label={l10nContext.translate('view.login.password', 'Passwort')}>
					<InputText
						type="password"
						value={password}
						onChange={(event) => setPassword(event.target.value)}
						required
						id={password}
					/>

				</Label>
			</FormLayoutSection>
			<FormLayoutSection>
				<ButtonPrimary
					buttonText={l10nContext.translate('view.login.title', 'Einloggen')}
					type="submit"
					name="login-button"
				/>
			</FormLayoutSection>
		</FormLayout>
	);
};
