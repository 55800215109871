import { useContext, useRef } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { DocumentEditFailedNotification } from './DocumentEditFailedNotification';
import { DocumentEditSuccessNotification } from './DocumentEditSuccessNotification';
import { DocumentEditForm } from '../document-edit-form/DocumentEditForm';


export interface DocumentEditModalProps {
	document: DocumentViewModel;
	onDismiss?: () => void;
}

export const DocumentEditModal = (props: DocumentEditModalProps): JSX.Element => {
	const { document, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const newDocumentName = useRef<string>('');

	const documentActionStatus = useTypedSelector(state => state.documents.actionStatus);

	const setNewDocumentName = (documentName: string) => {
		newDocumentName.current = documentName;
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (documentActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent = <DocumentEditForm
					document={document}
					onChange={setNewDocumentName}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modalContent = <DocumentEditSuccessNotification
					documentName={newDocumentName.current}
					onClickConfirm={onDismiss}
				/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <DocumentEditFailedNotification onDismiss={onDismiss} />;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.documents.modal.action.editDocument.caption', 'Dokument umbenennen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
