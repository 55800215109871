import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import {
	ReportRecordModelConverter as ModelConverter,
	ReportRecordStoreModel as StoreModel,
	ReportRecordViewModel as ViewModel,
} from 'services/report/domain/model/ReportRecordModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelSyncState } from '../../../lib/domain/model/Model';
import { checkFetchStatus, checkFetchStatusPending } from '../../../store/common/AsyncFetchStatus.util';
import { ExternalReportRecordHttpPersistence } from '../../report/persistence/ExternalReportRecordHttpPersistence';
import { ViolationError } from '../domain/business/common/violation/ViolationError';

// Declare a device state type
export interface ExternalReportRecordState {
	externalReportRecords: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	externalReportRecords: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ExternalReportRecordState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
export const fetchExternalReportRecords = createAsyncThunk(
	'external-report-records/fetch',
	async (params: { reportUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new ExternalReportRecordHttpPersistence(params.reportUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { externalReportRecords } = getState() as RootState;
			return checkFetchStatus(externalReportRecords.fetchStatus);
		}
	}
);

export const fetchExternalReportRecordsBySequence = createAsyncThunk(
	'external-report-records/fetchBySequence',
	async (params: { sequenceUuid: string, reportUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new ExternalReportRecordHttpPersistence(params.reportUuid);
		return persistence.fetchCollectionBySequence(params.sequenceUuid);
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { externalReportRecords } = getState() as RootState;
			return !checkFetchStatusPending(externalReportRecords.fetchStatus);
		}
	}
);

// Slice definition
export const externalReportRecordSlice = createSlice({
	name: 'external-report-records',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchExternalReportRecords.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalReportRecords.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.externalReportRecords = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalReportRecords.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(fetchExternalReportRecordsBySequence.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalReportRecordsBySequence.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.externalReportRecords = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalReportRecordsBySequence.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

// Export the reducer as default
export const { resetState, resetActionStatus } = externalReportRecordSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectExternalReportRecordsBySequence = (
	sequenceViewModel: SequenceViewModel,
	excludeReplacedRecords = true
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		if ((sequenceViewModel ?? null) === null) {
			return [];
		}
		const storeModels = rootState?.externalReportRecords?.externalReportRecords.filter((storeModel): boolean => {
			// noinspection RedundantIfStatementJS
			if (
				storeModel?.Sequence !== sequenceViewModel.Uuid
				|| excludeReplacedRecords && storeModel.Replaced
			) {
				return false;
			}
			return true;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModelFromSequence(sequenceViewModel);
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalReportRecordsBySequenceWithPeriod = (
	sequenceViewModel: SequenceViewModel,
	dateStart?: Date,
	dateEnd?: Date,
	excludeReplacedRecords = true
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	dateStart = dateStart ?? null;
	dateEnd = dateEnd ?? null;
	if (dateStart === null && dateEnd === null) {
		return selectExternalReportRecordsBySequence(sequenceViewModel, excludeReplacedRecords);
	}
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		if ((sequenceViewModel ?? null) === null) {
			return [];
		}
		const dateStartTimestamp = dateStart?.getTime() ?? null;
		const dateEndTimestamp = dateEnd?.getTime() ?? null;
		const storeModels = rootState.externalReportRecords.externalReportRecords.filter((storeModel): boolean => {
			if (
				storeModel?.Sequence !== sequenceViewModel.Uuid
				|| excludeReplacedRecords && storeModel.Replaced
			) {
				return false;
			}
			// noinspection RedundantIfStatementJS
			if (
				dateStartTimestamp !== null && storeModel.RecordedAt < dateStartTimestamp
				|| dateEndTimestamp !== null && storeModel.RecordedAt > dateEndTimestamp
			) {
				return false;
			}
			return true;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModelFromSequence(sequenceViewModel);
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalReportRecordByUuid = (sequenceViewModel: SequenceViewModel, uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.externalReportRecords.externalReportRecords.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModelFromSequence(sequenceViewModel);
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectExternalReportInitialRecordBySequence = (
	sequenceViewModel: SequenceViewModel,
	excludeReplacedRecords = true
): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		if ((sequenceViewModel ?? null) === null) {
			return null;
		}
		const storeModels = rootState.externalReportRecords.externalReportRecords.filter((storeModel): boolean => {
			return storeModel?.Sequence === sequenceViewModel.Uuid
				&& storeModel.ModelSyncState !== ModelSyncState.DELETED;
		}) ?? [];

		let viewModels = storeModels.map((storeModel): ViewModel | null => {
			if (excludeReplacedRecords && storeModel.Replaced) {
				return null;
			}
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModelFromSequence(sequenceViewModel);
			} catch (error) {
				if (error instanceof ViolationError) {
					DebugConsole.debug(error.getViolations());
				}
				return null;
			}
		});
		viewModels = viewModels.filter((viewModel) => {
			return viewModel !== null;
		});

		return viewModels.length > 0 ? viewModels[viewModels.length - 1] : null;
	};
};
