import { FunctionComponent } from 'react';

import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import 'presentation/ui/components/cards/card-document/card-document/card-document.scss';

type CardDocumentButton = {
	icon: IconIdentifier,
	disabled: boolean;
	onClick: (cardId: string) => void;
};

export interface CardDocumentProps {
	/**
	 * Id of controlled card
	 */
	cardId: string;
	/**
	 * (Optional) Button props
	 */
	button?: CardDocumentButton;
}

/**
 * The document card component
 */
export const CardDocument: FunctionComponent<CardDocumentProps> = (props): JSX.Element => {
	const { button, cardId, children } = props;

	const buttonEl = button ?
		<ButtonIcon
			icon={button.icon}
			shape={ButtonIconShape.RECTANGLE}
			weight={ButtonIconWeight.PRIMARY}
			disabled={button.disabled}
			onClick={() => button.onClick(cardId)}
		/> :
		null;

	return (
		<div className='card-document'>
			<div className='card-document__content'>
				{children}
			</div>

			{buttonEl}
		</div>
	);
};
