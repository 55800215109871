import { L10nContext } from 'context/L10nContext';
import { CardSequenceDetail } from 'presentation/ui/components/cards/card-sequence/card-sequence-detail/CardSequenceDetail';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemSequenceHeader } from 'presentation/ui/partials/card/card-item-header/card-item-sequence-header/CardItemSequenceHeader';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SimpleViewSubtitle } from 'services/core/presentation/ui/simple-view-subtitle/SimpleViewSubtitle';
import { ExternalReportSequenceRecords } from 'services/device/presentation/ui/sequence-records/ExternalReportSequenceRecords';
import {
	CardItemExternalReportSequenceAttributes
} from '../../../../presentation/ui/partials/card/card-item-attributes/card-item-external-report-sequence-attributes/CardItemExternalReportSequenceAttributes';
import { RecordCommentModal } from '../../../device/presentation/ui/record-action/record-comment-modal/RecordCommentModal';
import { selectExternalReportDeviceByUuid } from '../../../device/store/externalReportDevicesSlice';
import { resetActionStatus as resetRecordActionStatus } from '../../../device/store/externalReportRecordSlice';
import { resetActionStatus, selectExternalReportSequenceByUuid } from '../../../device/store/externalReportSequenceSlice';
import { ReportRecordViewModel } from '../../domain/model/ReportRecordModel';
import { selectExternalReport } from '../../store/externalReportSlice';
import { ReportRecordDocumentsModal } from '../ui/report-record-documents-modal/ReportRecordDocumentsModal';

interface Params {
	reportUuid: string,
	deviceUuid: string,
	sequenceUuid: string
}

enum ModalType {
	RECORD_COMMENT = 'RECORD_COMMENT',
	RECORD_DOCUMENTS = 'RECORD_DOCUMENTS'
}

export const ReportSequenceView = (): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const dispatch = useDispatch();

	// Unwrap the required report uuid from the route
	const params = useParams<Params>();
	const reportUuid = params?.reportUuid ?? null;
	if (reportUuid === null) {
		throw new Error('Report id is missing');
	}

	const deviceUuid = params?.deviceUuid ?? null;
	if (deviceUuid === null) {
		throw new Error('Device id is missing');
	}

	const sequenceUuid = params?.sequenceUuid ?? null;
	if (sequenceUuid === null) {
		throw new Error('Sequence id is missing');
	}

	const externalReport = useSelector(selectExternalReport(reportUuid));
	const device = useSelector(selectExternalReportDeviceByUuid(deviceUuid));
	const sequence = useSelector(selectExternalReportSequenceByUuid(sequenceUuid));

	const [modalType, setModalType] = useState<ModalType>(null);
	const [modalPayload, setModalPayload] = useState<any>(null);

	if (device === null || sequence === null) {
		// Show an error if the device is not available although the devices were fetched from the persistence
		throw new Error('Sequence not found');
	}

	const reportSequence = externalReport?.ReportSequences.find((shownSequence) => {
		return shownSequence.Sequence === sequence.Uuid;
	});

	const sequencePeriodDateStartFormatted = l10nContext.formatDate(reportSequence?.PeriodDateStart);
	const sequencePeriodDateEndFormatted = l10nContext.formatDate(reportSequence?.PeriodDateEnd);

	const sequencePeriod =
		`${l10nContext.translate('sequence.periodSelection.periodFrom', 'Zeitraum vom ')}
		${sequencePeriodDateStartFormatted}
		${l10nContext.translate('sequence.periodSelection.periodTo', 'bis')}
		${sequencePeriodDateEndFormatted}`;

	const onModalDismiss = (): void => {
		setModalType(null);
	};

	const onModalTriggerClick = (type: ModalType, payload: any = null): void => {
		dispatch(resetActionStatus());
		dispatch(resetRecordActionStatus());
		setModalType(type);
		setModalPayload(payload);
	};

	const modal = (): JSX.Element => {
		let renderModel: JSX.Element = null;

		switch (modalType) {
			case ModalType.RECORD_COMMENT:
				renderModel =
					<RecordCommentModal
						record={modalPayload as ReportRecordViewModel}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ModalType.RECORD_DOCUMENTS:
				renderModel =
					<ReportRecordDocumentsModal
						record={modalPayload as ReportRecordViewModel}
						onDismiss={onModalDismiss}
					/>;
				break;
		}
		return renderModel;
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs mapURLFragments={[[reportUuid, externalReport?.Name], [deviceUuid, device?.Name], [sequenceUuid, sequence?.Name]]} />
				</NavigationSecondary>
			</TopbarLayoutSection>
			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						<CardSequenceDetail>
							<CardItemSequenceHeader
								sequence={sequence}
							/>
							<CardItemSingleControl
								cardId={sequence.Uuid}
							/>
							<CardItemExternalReportSequenceAttributes sequence={sequence} reportUuid={reportUuid} />
						</CardSequenceDetail>
					</ViewLayoutSection>
					<ViewLayoutSection>
						<SimpleViewSubtitle label={sequencePeriod} />
						<Expandable expanded={true} firstElement>
							<ExpandableHeader caption={l10nContext.translate('view.sequence.headline.records', 'Messungen')} />
							<ExpandableBody>
								{modal()}
								<ExternalReportSequenceRecords
									reportUuid={reportUuid}
									device={device}
									sequence={sequence}
									isReportView
									onShowComment={(record) => {
										onModalTriggerClick(ModalType.RECORD_COMMENT, record);
									}}
									onAttachments={(record) => {
										onModalTriggerClick(ModalType.RECORD_DOCUMENTS, record);
									}}
								/>
							</ExpandableBody>
						</Expandable>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>
		</MainLayout>
	);
};
