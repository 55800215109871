import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import { FolderModelState } from 'services/documents/domain/model/FolderModelState';

export interface FolderPersistenceModel extends PersistenceModel {
	Client: string;
	Facility: string;
	Parent?: string;
	State: string;
	Name: string;
}

export interface FolderStoreModel extends StoreModel {
	Client: string;
	Facility: string;
	Parent?: string;
	State: string;
	Name: string;
}

export interface FolderViewModel extends ViewModel {
	Client: string;
	Facility: string;
	Parent?: string;
	State: FolderModelState;
	Name: string;
}

export interface CreatableFolderViewModel extends CreatableViewModel {
	Client: string;
	Facility: string;
	Parent: string;
	Name: string;
}

export class FolderModelConverter extends ModelConverter<FolderPersistenceModel, FolderStoreModel, FolderViewModel, CreatableFolderViewModel> {

	public fromCreatableViewModel(creatableViewModel?: CreatableFolderViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			model.State = FolderModelState.ACTIVE;
		});
	}

	public toStoreModel(): FolderStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.State = model?.State as string ?? null;
		});
	}

	public toViewModel(): FolderViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.State = model?.State as FolderModelState ?? null;
		});
	}

}
