import { UrlOutput } from 'services/device/domain/business/common/Output';

export interface UrlOutputValueProps {
	output: UrlOutput;
}

export const UrlOutputValueRenderer = (props: UrlOutputValueProps): JSX.Element => {
	const { output } = props;

	return (
		<span>
			{output.getSingle() ?? ''}
		</span>
	);

};
