import { Output } from '../Output';

export class Violation {
	private readonly fields: ReadonlyArray<Output<any>>;

	private readonly messageKey: string;

	constructor(fields: ReadonlyArray<Output<any>>, messageKey: string) {
		this.fields = fields;
		this.messageKey = messageKey;
	}

	public getOutputs(): ReadonlyArray<Output<any>> {
		return this.fields;
	}

	public getMessageKey(): string {
		return this.messageKey;
	}
}
