import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';
import { IntervalOutput } from 'services/device/domain/business/common/Output';
import { Interval } from 'services/device/domain/business/inventory/Interval';
import { getIntervalDefinition } from 'services/device/domain/business/inventory/IntervalDefinition';

export interface IntervalOutputValueProps {
	output: IntervalOutput;
}

export const IntervalOutputValueRenderer = (props: IntervalOutputValueProps): JSX.Element => {
	const l10nCOntext = useContext(L10nContext);
	const { output } = props;

	const interval = output.getSingle() ?? Interval.NONE;
	const intervalLabelKey = getIntervalDefinition(interval).getLabelKey();

	return (
		<span>
			{l10nCOntext.translate(intervalLabelKey, String(interval))}
		</span>
	);

};
