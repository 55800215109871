import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	CreatableCyclotronProductViewModel as CreatableViewModel,
	CyclotronProductModelConverter as ModelConverter,
	CyclotronProductStoreModel as StoreModel,
	CyclotronProductViewModel as ViewModel
} from 'services/nuclide/domain/model/CyclotronProductModel';
import { CyclotronProductModelState } from 'services/nuclide/domain/model/CyclotronProductModelState';
import { CyclotronProductPersistence as Persistence } from 'services/nuclide/persistence/CyclotronProductPersistence';

import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { ModelQueryOrderClosureBuilder } from 'lib/persistence/idb/query/ModelQueryOrderClosureBuilder';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import { DebugConsole } from 'lib/debug/DebugConsole';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { CyclotronProductHttpPersistence as HttpPersistence } from '../persistence/CyclotronProductHttpPersistence';

// Declare a cyclotron product state type
export interface CyclotronProductState {
	cyclotronProducts: Array<StoreModel>;
	createdCyclotronProduct: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	cyclotronProducts: [] as Array<StoreModel>,
	createdCyclotronProduct: null,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as CyclotronProductState;

// Implementation of the async actions
export const fetchCyclotronProducts = createAsyncThunk(
	'cyclotron-products/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Silently abort the action
			const { cyclotronProducts } = getState() as RootState;
			return checkFetchStatus(cyclotronProducts.fetchStatus);
		}
	}
);

export const resetCyclotronProducts = createAsyncThunk(
	'cyclotron-products/reset',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { cyclotronProducts } = getState() as RootState;
			return checkFetchStatus(cyclotronProducts.fetchStatus);
		}
	}
);

export const createCyclotronProduct = createAsyncThunk(
	'cyclotron-product/create',
	async (viewModel: CreatableViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.createEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { cyclotronProducts } = getState() as RootState;
			if (cyclotronProducts.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const updateCyclotronProduct = createAsyncThunk(
	'cyclotron-product/update',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.updateEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { cyclotronProducts } = getState() as RootState;
			if (cyclotronProducts.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteCyclotronProduct = createAsyncThunk(
	'cyclotron-product/delete',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.deleteEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {

			// Abort the action with an error
			const { cyclotronProducts } = getState() as RootState;
			if (cyclotronProducts.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const cyclotronProductSlice = createSlice({
	name: 'cyclotronProducts',
	initialState,
	// Regular syncronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	extraReducers: {
		[String(fetchCyclotronProducts.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchCyclotronProducts.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.cyclotronProducts = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchCyclotronProducts.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(resetCyclotronProducts.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(resetCyclotronProducts.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.cyclotronProducts = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(resetCyclotronProducts.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(createCyclotronProduct.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdCyclotronProduct = null;
		},
		[String(createCyclotronProduct.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.cyclotronProducts.push(action.payload);
			state.cyclotronProducts = state.cyclotronProducts.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.createdCyclotronProduct = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createCyclotronProduct.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(updateCyclotronProduct.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateCyclotronProduct.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.cyclotronProducts.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.cyclotronProducts[index] = action.payload;
			}
			state.cyclotronProducts = state.cyclotronProducts.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateCyclotronProduct.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(deleteCyclotronProduct.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteCyclotronProduct.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.cyclotronProducts.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.cyclotronProducts.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteCyclotronProduct.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		}
	}
});

export const { resetState, resetActionStatus } = cyclotronProductSlice.actions;

// Export the reducer as default
export default cyclotronProductSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectCyclotronProducts = (clientUuid: string, facilityUuid: string): (rootSTate: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.cyclotronProducts.cyclotronProducts.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectCyclotronProductByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.cyclotronProducts.cyclotronProducts.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedCyclotronProduct = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.cyclotronProducts.createdCyclotronProduct;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectActiveAndCompleteAppliedFilteredCyclotronProducts = (
	clientUuid: string,
	facilityUuid: string,
	includeActive: boolean,
	includeCompletelyApplied: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeActive && includeCompletelyApplied  || !includeActive && !includeCompletelyApplied ) {

		const filterArray: Array<string> = [CyclotronProductModelState.ACTIVE, CyclotronProductModelState.COMPLETELY_APPLIED];

		return (rootState: RootState): ReadonlyArray<ViewModel> => {
			let storeModels: Array<StoreModel> = [];
			filterArray.forEach((cyclotronProductFilter) => {
				const filteredRootStore = rootState.cyclotronProducts.cyclotronProducts.filter((storeModel): boolean => {
					return storeModel.Client === clientUuid
						&& storeModel.Facility === facilityUuid
						&& storeModel.State === String(cyclotronProductFilter);
				}) ?? [];
				storeModels = storeModels.concat(filteredRootStore);
			});
			const viewModels = storeModels.map((storeModel): ViewModel | null => {
				try {
					return new ModelConverter().fromStoreModel(storeModel).toViewModel();
				} catch (error) {
					DebugConsole.error(error);
					return null;
				}
			});
			return viewModels.filter((viewModel) => {
				return viewModel !== null;
			});
		};
	}

	const filterModelState = includeActive ? CyclotronProductModelState.ACTIVE : CyclotronProductModelState.COMPLETELY_APPLIED;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.cyclotronProducts.cyclotronProducts.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectInWastemanagementCyclotronProducts = (
	clientUuid: string,
	facilityUuid: string
): (rootState: RootState) => ReadonlyArray<ViewModel> => {

	const filterModelState = CyclotronProductModelState.IN_WASTE_MANAGEMENT;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.cyclotronProducts.cyclotronProducts.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

// Multiple options filter
// export const selectWasteManagedFilteredCyclotronProducts = (
// 	clientUuid: string,
// 	facilityUuid: string,
// 	includeWasteManaged: boolean,
// 	includeDecay: boolean,
// 	includeDispatched: boolean,
// 	includeDisposed: boolean
// ): (rootState: RootState) => ReadonlyArray<ViewModel> => {
// 	if ((includeWasteManaged && includeDecay && includeDispatched && includeDisposed) ||
// 		(!includeWasteManaged && !includeDecay && !includeDispatched && !includeDisposed)) {

// 		const filterArray: Array<string> = [
// 			CyclotronProductModelState.WASTE_MANAGED,
// 			CyclotronProductModelState.DECAY,
// 			CyclotronProductModelState.DISPATCHED,
// 			CyclotronProductModelState.DISPOSED ];

// 		return (rootState: RootState): ReadonlyArray<ViewModel> => {
// 			let storeModels: Array<StoreModel> = [];
// 			filterArray.forEach((cyclotronProductFilter) => {
// 				const filteredRootStore = rootState.cyclotronProducts.cyclotronProducts.filter((storeModel): boolean => {
// 					return storeModel.Client === clientUuid
// 						&& storeModel.Facility === facilityUuid
// 						&& storeModel.State === String(cyclotronProductFilter);
// 				}) ?? [];
// 				storeModels = storeModels.concat(filteredRootStore);
// 			});
// 			const viewModels = storeModels.map((storeModel): ViewModel | null => {
// 				try {
// 					return new ModelConverter().fromStoreModel(storeModel).toViewModel();
// 				} catch (error) {
// 					DebugConsole.error(error);
// 					return null;
// 				}
// 			});
// 			return viewModels.filter((viewModel) => {
// 				return viewModel !== null;
// 			});
// 		};
// 	}

// 	const filterArray: Array<string> = [];
// 	if (includeWasteManaged) {
// 		filterArray.push(String(CyclotronProductModelState.WASTE_MANAGED));
// 	} if (includeDecay) {
// 		filterArray.push(String(CyclotronProductModelState.DECAY));
// 	} if (includeDispatched) {
// 		filterArray.push(String(CyclotronProductModelState.DISPATCHED));
// 	} if (includeDisposed) {
// 		filterArray.push(String(CyclotronProductModelState.DISPOSED));
// 	}

// 	return (rootState: RootState): ReadonlyArray<ViewModel> => {
// 		let storeModels: Array<StoreModel> = [];
// 		filterArray.forEach((cyclotronProductFilter) => {
// 			const filteredRootStore = rootState.cyclotronProducts.cyclotronProducts.filter((storeModel): boolean => {
// 				return storeModel.Client === clientUuid
// 					&& storeModel.Facility === facilityUuid
// 					&& storeModel.State === String(cyclotronProductFilter);
// 			}) ?? [];
// 			storeModels = storeModels.concat(filteredRootStore);
// 		});
// 		const viewModels = storeModels.map((storeModel): ViewModel | null => {
// 			try {
// 				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
// 			} catch (error) {
// 				DebugConsole.error(error);
// 				return null;
// 			}
// 		});
// 		return viewModels.filter((viewModel) => {
// 			return viewModel !== null;
// 		});
// 	};
// };


