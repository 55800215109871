import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { useContext } from 'react';
import { useTypedSelector } from '../../../../store/common/TypedSelector';
import { ClientContext } from '../../../core/context/ClientContext';
import { FacilityContext } from '../../../core/context/FacilityContext';
import { ContainerViewModel } from '../../domain/model/ContainerModel';
import { GeneratorEluateIsotope } from '../../domain/model/isotopes/GeneratorEluateIsotope';
import { GeneratorTypeIsotope } from '../../domain/model/isotopes/GeneratorTypeIsotope';
import { selectDecayContainers } from '../../store/containerSlice';
import { selectInWastemanagementCyclotronProducts } from '../../store/cyclotronProductSlice';
import { selectInWastemanagementEluates } from '../../store/eluateSlice';
import { selectGenerators } from '../../store/generatorSlice';
import { ContainerCollection } from '../ui/card-collections/container-collection/ContainerCollection';
import { ContainersByNuclide } from './ContainersByNuclideType';

export const DecayContainersView = (): JSX.Element => {

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const decayContainers = useTypedSelector(selectDecayContainers(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementCyclotronProducts = useTypedSelector(selectInWastemanagementCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementEluates = useTypedSelector(selectInWastemanagementEluates());

	const generators = useTypedSelector(selectGenerators(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	inWastemanagementEluates.forEach(eluate => {

		const inWastemanagementEluateGenerator = generators.find((generator) => {
			return generator.Uuid === eluate.Generator;
		});
		if (inWastemanagementEluateGenerator.Isotope === GeneratorTypeIsotope.MOLYBDENUM_99_MO) {
			eluate.Isotope = GeneratorEluateIsotope.TECHNETIUM_99M_TC;
		} else {
			eluate.Isotope = GeneratorEluateIsotope.GALLIUM_68_GA;
		}
	});

	const containersByNuclides: Array<ContainersByNuclide> = [];
	let containersMixedNuclideIds: string[] = [];

	decayContainers.forEach((decayContainer: ContainerViewModel) => {

		if (decayContainer.CyclotroneProductIds.length === 0 && decayContainer.GeneratorEluatIds.length !== 0 ) {
			const decayContainerEluates = inWastemanagementEluates.filter(value => decayContainer.GeneratorEluatIds.includes(value.Uuid));
			const checkEluates = decayContainerEluates.every((item) => {
				return item.Isotope === decayContainerEluates[0].Isotope;
			});

			if (checkEluates) {
				const existingEluateContainerByNuclide = containersByNuclides.find((e) =>
					e.Nuclide === decayContainerEluates[0].Isotope
				);
				if (existingEluateContainerByNuclide) {
					existingEluateContainerByNuclide.Containers.push(decayContainer);
				} else {
					const newEluateContainerByNuclide: ContainersByNuclide = {
						Nuclide: decayContainerEluates[0].Isotope,
						Containers: []
					};
					newEluateContainerByNuclide.Containers.push(decayContainer);
					containersByNuclides.push(newEluateContainerByNuclide);
				}
			} else {
				const existingMixedContainerByNuclides = containersByNuclides.find((e) => e.Nuclide === 'GemischterContainer');
				if (existingMixedContainerByNuclides) {
					existingMixedContainerByNuclides.Containers.push(decayContainer);
				} else {
					const newMixedContainerByNuclide: ContainersByNuclide = {
						Nuclide: 'GemischterContainer',
						Containers: []
					};
					newMixedContainerByNuclide.Containers.push(decayContainer);
					containersByNuclides.push(newMixedContainerByNuclide);
				}
			}
		}

		if (decayContainer.CyclotroneProductIds.length !== 0 && decayContainer.GeneratorEluatIds.length === 0) {

			const decayContainerCyclotronProducts = inWastemanagementCyclotronProducts.filter(
				value => decayContainer.CyclotroneProductIds.includes(value.Uuid));
			const checkCyclotronProducts = decayContainerCyclotronProducts.every((item) => {
				return item.Isotope === decayContainerCyclotronProducts[0].Isotope;
			});
			if (checkCyclotronProducts) {
				const existingCyclotronProductContainerByNuclide = containersByNuclides.find((e) => e.Nuclide === decayContainerCyclotronProducts[0].Isotope);

				if (existingCyclotronProductContainerByNuclide) {
					existingCyclotronProductContainerByNuclide.Containers.push(decayContainer);
				} else {
					const newCyclotronProductContainerByNuclide: ContainersByNuclide = {
						Nuclide: decayContainerCyclotronProducts[0].Isotope,
						Containers: []
					};
					newCyclotronProductContainerByNuclide.Containers.push(decayContainer);
					containersByNuclides.push(newCyclotronProductContainerByNuclide);
				}
			} else {
				const existingMixedContainerByNuclides = containersByNuclides.find((e) => e.Nuclide === 'GemischterContainer');
				if (existingMixedContainerByNuclides) {
					existingMixedContainerByNuclides.Containers.push(decayContainer);
				} else {
					const newMixedContainerByNuclide: ContainersByNuclide = {
						Nuclide: 'GemischterContainer',
						Containers: []
					};
					newMixedContainerByNuclide.Containers.push(decayContainer);
					containersByNuclides.push(newMixedContainerByNuclide);
				}
			}
		}

		if (decayContainer.CyclotroneProductIds.length !== 0 && decayContainer.GeneratorEluatIds.length !== 0) {
			containersMixedNuclideIds = containersMixedNuclideIds.concat(decayContainer.GeneratorEluatIds, decayContainer.CyclotroneProductIds);
			const existingMixedContainerByNuclides = containersByNuclides.find((e) => e.Nuclide === 'GemischterContainer');
			if (existingMixedContainerByNuclides) {
				existingMixedContainerByNuclides.Containers.push(decayContainer);
			} else {
				const newMixedContainerByNuclide: ContainersByNuclide = {
					Nuclide: 'GemischterContainer',
					Containers: []
				};
				newMixedContainerByNuclide.Containers.push(decayContainer);
				containersByNuclides.push(newMixedContainerByNuclide);
			}
		}
	});

	return (
		<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
			<ViewLayout>
				<ViewLayoutSection>
					<ContainerCollection containersByNuclides={containersByNuclides} />
				</ViewLayoutSection>
			</ViewLayout>
		</MainLayoutSection>
	);
};
