import { ComponentPropsWithoutRef, useEffect, useState } from 'react';

import { ExpandableContext } from 'presentation/ui/compositions/expandable/expandable-context/ExpandableContext';

import './expandable.scss';

export interface ExpandableProps extends ComponentPropsWithoutRef<'article'> {
	expanded?: boolean;
	mode?: string;
	firstElement?: boolean;
}

/**
 * TODO: Refactor the mode attribute to an enum set or a boolean attribute called slim
 * @param props
 * @constructor
 */
export const Expandable = (props: ExpandableProps): JSX.Element => {

	const { expanded, mode, firstElement, ...restProps } = props;

	const [expandedState, setExpandedState] = useState(expanded);

	const expandedCssClasss = (expandedState && !mode ? 'expandable--expanded' : '') ||
		(expandedState && mode ? `expandable-mode--${mode}--expanded` : '');

	useEffect(() => {
		setExpandedState(expanded);
	}, [expanded]);

	return (
		<article
			className={`${!mode ? 'expandable' : ''} ${mode ? 'expandable-mode--' + mode : ''} ${expandedCssClasss} ${firstElement ? 'firstElement' : ''}`}
			{...restProps}
		>
			<ExpandableContext.Provider value={{ expanded: expandedState, setExpanded: setExpandedState }}>
				{props.children}
			</ExpandableContext.Provider>
		</article>
	);
};
