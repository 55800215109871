import { memo } from 'react';

import { ButtonSecondary, ButtonSecondaryHeight, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';

import './card-item-attributes-action.scss';

export interface CardItemAttributesActionProps {
	/**
	 * Title
	 */
	title: string
	/**
	 * Button label
	 */
	buttonLabel?: string
	/**
	 * (Optional) Button action
	*/
	onClick?: () => void;
	/**
	 * (Optional) Disabled state
	*/
	disabled?: boolean;
}

/**
 * A card item attributes action partial
 */
export const CardItemAttributesAction = memo((props: CardItemAttributesActionProps): JSX.Element => {
	const { title, buttonLabel, onClick, disabled } = props;

	const onClickHandler = () => {
		if (!onClick) return;
		onClick();
	};

	const button = buttonLabel ?
		<ButtonSecondary
			buttonText={buttonLabel}
			height={ButtonSecondaryHeight.SMALL}
			status={ButtonSecondaryStatus.NORMAL}
			onClick={onClickHandler}
			disabled={disabled}
		/> :
		null;

	return (
		<div className='card-item-attributes-action'>
			<p className='card-item-attributes-action__title'>
				{title}
			</p>
			{button}
		</div>
	);
});
