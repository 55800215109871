import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { useDispatch } from 'react-redux';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { SequenceDeleteForm } from 'services/device/presentation/ui/sequence-action/sequence-delete-form/SequenceDeleteForm';
import { SequenceDeleteFailedNotification } from 'services/device/presentation/ui/sequence-action/sequence-delete-modal/SequenceDeleteFailedNotification';
import { deleteSequence } from 'services/device/store/sequenceSlice';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

export interface SequenceDeleteModalProps {
	sequence: SequenceViewModel;
	onDismiss?: () => void;
}

export const SequenceDeleteModal = (props: SequenceDeleteModalProps): JSX.Element => {
	const { sequence, onDismiss } = props;

	const deviceActionStatus = useTypedSelector(state => state.sequences.actionStatus);

	const dispatch = useDispatch();

	const handleDelete = (): void => {
		dispatch(deleteSequence(sequence));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (deviceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<SequenceDeleteForm
						sequence={sequence}
						onDelete={handleDelete}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <SequenceDeleteFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
