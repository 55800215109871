import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';

export interface GeneratorCreateSuccessNotificationProps {
	/**
	 * Handle click confirm
	 */
	onClickConfirm: () => void;
	/**
	 * Created generator
	 */
	generatorCreated?: GeneratorViewModel;
}

export const GeneratorCreateSuccessNotification = (props: GeneratorCreateSuccessNotificationProps): JSX.Element => {
	const { onClickConfirm, generatorCreated } = props;

	// Consume context
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.generators.modal.action.create.successMessage', 'Der Generator wurde erfolgreich angelegt')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.generators.modal.btnGeneratorDetails', 'Weiter zu den Generatordetails')}
						onClick={() => { history.push(Route.NUCLIDE_MANAGEMENT + Route.GENERATORS + '/' + generatorCreated.Uuid); }}
					/>
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.generators.modal.btnGeneratorOverview', 'Weiter zur Generatoren-Übersicht')}
						onClick={onClickConfirm}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
