import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';

import { ModelQueryOrderClosureBuilder } from 'lib/persistence/idb/query/ModelQueryOrderClosureBuilder';

import { DeviceUuid, DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';
import {
	SequenceModelConverter as ModelConverter,
	SequenceStoreModel as StoreModel,
	SequenceViewModel as ViewModel,
	CreatableSequenceViewModel as CreatableViewModel
} from 'services/device/domain/model/SequenceModel';
import { SequencePersistence as Persistence } from 'services/device/persistence/SequencePersistence';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { SequenceMemory } from '../domain/model/SequenceMemory';
import { SequenceHttpPersistence as HttpPersistence } from '../persistence/SequenceHttpPersistence';

// Declare a sequence state type
export interface SeqeunceState {
	sequences: Array<StoreModel>;
	createdSequence: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	sequences: [] as Array<StoreModel>,
	createdSequence: null,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as SeqeunceState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the

export const fetchSequences = createAsyncThunk(
	'sequences/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { sequences } = getState() as RootState;
			return checkFetchStatus(sequences.fetchStatus);
		}
	}
);

export const resetSequences = createAsyncThunk(
	'sequences/reset',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { sequences } = getState() as RootState;
			return checkFetchStatus(sequences.fetchStatus);
		}
	}
);

export const updateSequence = createAsyncThunk(
	'sequence/update',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.updateEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { sequences } = getState() as RootState;
			if (sequences.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const createSequence = createAsyncThunk(
	'sequence/create',
	async (creatableViewModel: CreatableViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(creatableViewModel.Client, creatableViewModel.Facility);
		return persistence.createEntity(creatableViewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { sequences } = getState() as RootState;
			if (sequences.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteSequence = createAsyncThunk(
	'sequence/delete',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.deleteEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			if (!params.Deletable) {
				throw new Error('Sequence not deletable');
			}

			// Abort the action with an error
			const { sequences } = getState() as RootState;
			if (sequences.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const regenerateSequenceMemory = createAsyncThunk(
	'sequence/regenrateMemory',
	(params: { sequenceUuid: string, memory: SequenceMemory }): { sequenceUuid: string, memory: SequenceMemory } => {
		return params;
	}
);

// Slice definition
export const sequencesSlice = createSlice({
	name: 'sequences',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s create slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchSequences.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchSequences.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.sequences = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchSequences.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(resetSequences.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(resetSequences.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.sequences = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(resetSequences.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(updateSequence.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateSequence.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.sequences.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.sequences[index] = action.payload;
			}
			state.sequences = state.sequences.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateSequence.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error;
		},
		[String(createSequence.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdSequence = null;
		},
		[String(createSequence.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.sequences.push(action.payload);
			state.sequences = state.sequences.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.createdSequence = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createSequence.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error;
		},
		[String(deleteSequence.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteSequence.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.sequences.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.sequences.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteSequence.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error;
		},
		[String(regenerateSequenceMemory.pending)]: (state) => {
		},
		[String(regenerateSequenceMemory.fulfilled)]: (state, action: PayloadAction<{ sequenceUuid: string, memory: SequenceMemory }>) => {
			const index = state.sequences.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.sequenceUuid;
			}) ?? null;
			if (index !== null) {
				state.sequences[index].SequenceMemory = action.payload.memory;
			}
		},
		[String(regenerateSequenceMemory.rejected)]: (state, action) => {
		}
	}
});

// Export the reducer as default
export const { resetState, resetActionStatus } = sequencesSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectSequences = (clientUuid: string, facilityUuid: string): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.sequences.sequences.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid && storeModel.Facility === facilityUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectSequencesByDevice = (deviceViewModel: DeviceViewModel): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		if ((deviceViewModel ?? null) === null) {
			return [];
		}
		const storeModels = rootState.sequences.sequences.filter((storeModel): boolean => {
			return storeModel?.Device === deviceViewModel.Uuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectActiveSequencesByDevices = (
	deviceViewModels: ReadonlyArray<DeviceViewModel>
): (rootState: RootState) => ReadonlyMap<DeviceUuid, Array<ViewModel>> => {
	return (rootState: RootState): ReadonlyMap<DeviceUuid, Array<ViewModel>> => {
		const deviceViewModelUuids = deviceViewModels.map<DeviceUuid>((device) => {
			return device.Uuid;
		});
		const storeModels = rootState.sequences.sequences.filter((storeModel): boolean => {
			return deviceViewModelUuids.includes(storeModel?.Device)
				&& storeModel.State === DeviceModelState.ACTIVE;
		}) ?? [];

		const resultMap: Map<DeviceUuid, Array<ViewModel>> = new Map();

		for (const storeModel of storeModels) {
			try {
				const viewModel = new ModelConverter().fromStoreModel(storeModel).toViewModel();
				if (!resultMap.has(viewModel.Device)) {
					resultMap.set(viewModel.Device, [viewModel]);
				} else {
					resultMap.get(viewModel.Device).push(viewModel);
				}
			} catch (error) {
				DebugConsole.error(error);
			}
		}

		return resultMap;
	};
};

export const selectFilteredSequencesByDevice = (
	deviceViewModel: DeviceViewModel,
	includeActive: boolean,
	includeArchived: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeActive && includeArchived || !includeActive && !includeArchived) {
		return selectSequencesByDevice(deviceViewModel);
	}

	const filterModelState = includeActive ? DeviceModelState.ACTIVE : DeviceModelState.ARCHIVED;

	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		if ((deviceViewModel ?? null) === null) {
			return [];
		}
		const storeModels = rootState.sequences.sequences.filter((storeModel): boolean => {
			return storeModel.Device === deviceViewModel.Uuid
				&& storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectSequenceByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.sequences.sequences.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			return null;
		}
	};
};

export const selectCreatedSequence = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.sequences.createdSequence;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};
