import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { Guid } from 'lib/guid/Guid';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Permission, permissionGroupDevice } from 'services/core/lib/auth/AuthService';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';
import { resetActionStatus, selectFilteredClearances } from 'services/device/store/devicesSlice';

import { CardDeviceList } from 'presentation/ui/components/cards/card-device/card-device-list/CardDeviceList';
import { FilterChipSet } from 'presentation/ui/components/filter/filter-chip-set/FilterChipSet';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { SiteTitle } from 'presentation/ui/components/site-title/SiteTitle';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import { ClearanceCreateModal } from 'services/device/presentation/ui/clearance-action/clearance-create-modal/ClearanceCreateModal';
import { CardItemDeviceState } from 'presentation/ui/partials/card/card-item-device-state/CardItemDeviceState';

/**
 * The clearances view
 */
export const ClearancesView = (): JSX.Element => {

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Use a custom selector function to provide type information for the state defined in the slice
	const storeActionStatus = useTypedSelector(state => state.devices.actionStatus);
	const storeLastActionError = useTypedSelector(state => state.devices.lastActionError);

	// Local state
	const [devicesIncludeActive, setDevicesIncludeActive] = useState<boolean>(true);
	const [devicesIncludeArchived, setDevicesIncludeArchived] = useState<boolean>(false);
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

	// Read the devices from the state store
	const devices = useSelector(selectFilteredClearances(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		devicesIncludeActive,
		devicesIncludeArchived
	));

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permissionCreate: authContext.hasPermission(Permission.DEVICE_CREATE)
	};

	// Handle close modal for success and failure
	const handleDismiss = () => {
		setShowCreateModal(false);
	};

	// Handle permissions
	if (!authContext.hasAnyPermission(permissionGroupDevice)) {
		throw new Error('Permission denied');
	}

	const renderNotifications = (): Array<JSX.Element> => {

		const notifications = [];
		// Handle failed action
		if (storeActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar key={Guid.generate()} message={storeLastActionError.message} level={NotificationLevel.LEVEL_ERROR} />);
		}
		return notifications;
	};

	const renderDevices = (): Array<JSX.Element> => {
		return devices.map<JSX.Element>((deviceModel) => {
			return (
				<CardDeviceList
					key={deviceModel.Uuid}
					archived={deviceModel.State === DeviceModelState.ARCHIVED}
				>
					<CardItemHeader
						title={deviceModel.Name}
						subtitle={deviceModel.Identifier}
						archived={deviceModel.State === DeviceModelState.ARCHIVED}
					/>
					<CardItemSingleControl
						cardId={deviceModel.Uuid}
						actionDetails={CardItemControlState.ENABLED}
						onClick={() => history.push(`${Route.CLEARANCES}/${deviceModel.Uuid}`)}
					/>
					<CardItemDeviceState
						device={deviceModel}
					/>
				</CardDeviceList>
			);
		});
	};

	const showModal = showCreateModal ?
		<ClearanceCreateModal onDismiss={handleDismiss} /> :
		null;

	const handleModalClick = () => {
		dispatch(resetActionStatus());
		setShowCreateModal(true);
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs />
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>
					<ViewLayoutSection>
						<SiteTitle
							title={l10nContext.translate('view.clearances.header.title', 'Freimessungen')}
							button={{
								buttonText: l10nContext.translate('view.clearances.header.buttonCreate', 'Freimessung hinzufügen'),
								disabled: !user.permissionCreate,
								icon: IconIdentifier.ADD,
								onClick: () => handleModalClick()
							}}
						/>
					</ViewLayoutSection>

					<CardCollectionLayout>
						<FilterChipSet
							label={l10nContext.translate('common.filter.status', 'Status')}
							chips={[
								{
									label: l10nContext.translate('common.filter.active', 'aktiv'),
									active: devicesIncludeActive,
									onClick: (id, state) => {
										setDevicesIncludeActive(state);
									}
								},
								{
									label: l10nContext.translate('common.filter.archived', 'archiviert'),
									active: devicesIncludeArchived,
									onClick: (id, state) => {
										setDevicesIncludeArchived(state);
									}
								},
							]}
						/>
						{showModal}
						{renderDevices()}
					</CardCollectionLayout>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
