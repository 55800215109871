import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { ModelQueryOrderDirection, ModelQueryOrderSortAlgo } from 'lib/persistence/idb/query/ModelQueryOrder';

import { ModelQueryOrderClosureBuilder } from 'lib/persistence/idb/query/ModelQueryOrderClosureBuilder';
import { DeviceUuid, DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';

import {
	CreatableMaintenanceLogEntryViewModel as CreatableViewModel,
	MaintenanceLogEntryModelConverter as ModelConverter,
	MaintenanceLogEntryStoreModel as StoreModel,
	MaintenanceLogEntryViewModel as ViewModel
} from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogEntryModelState } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModelState';
import { MaintenanceLogEntryPersistence as Persistence } from 'services/maintenance-log/persistence/MaintenanceLogEntryPersistence';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { MaintenanceLogHttpPersistence as HttpPersistence } from '../persistence/MaintenanceLogHttpPersistence';

// Declare a device state type
export interface MaintenanceLogEntryState {
	maintenanceLogEntries: Array<StoreModel>;
	createdMaintenanceLogEntry: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	maintenanceLogEntries: [] as Array<StoreModel>,
	createdMaintenanceLogEntry: null,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as MaintenanceLogEntryState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
export const fetchMaintenanceLogEntries = createAsyncThunk(
	'maintenance-log-entries/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { maintenanceLogEntries } = getState() as RootState;
			return checkFetchStatus(maintenanceLogEntries.fetchStatus);
		}
	}
);

export const resetMaintenanceLogEntries = createAsyncThunk(
	'maintenance-log-entries/reset',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { maintenanceLogEntries } = getState() as RootState;
			return checkFetchStatus(maintenanceLogEntries.fetchStatus);
		}
	}
);

export const updateMaintenanceLogEntry = createAsyncThunk(
	'maintenance-log-entry/update',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.updateEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { maintenanceLogEntries } = getState() as RootState;
			if (maintenanceLogEntries.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const createMaintenanceLogEntry = createAsyncThunk(
	'maintenance-log-entry/create',
	async (viewModel: CreatableViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.createEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { maintenanceLogEntries } = getState() as RootState;
			if (maintenanceLogEntries.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteMaintenanceLogEntry = createAsyncThunk(
	'maintenance-log-entry/delete',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.deleteEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { maintenanceLogEntries } = getState() as RootState;
			if (maintenanceLogEntries.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const maintenanceLogEntrySlice = createSlice({
	name: 'maintenanceLogEntries',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchMaintenanceLogEntries.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchMaintenanceLogEntries.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.maintenanceLogEntries = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchMaintenanceLogEntries.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(resetMaintenanceLogEntries.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(resetMaintenanceLogEntries.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.maintenanceLogEntries = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(resetMaintenanceLogEntries.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(updateMaintenanceLogEntry.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateMaintenanceLogEntry.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.maintenanceLogEntries.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.maintenanceLogEntries[index] = action.payload;
			}
			state.maintenanceLogEntries = state.maintenanceLogEntries.sort(
				new ModelQueryOrderClosureBuilder<StoreModel>().build('CreatedAt', ModelQueryOrderDirection.DESC, ModelQueryOrderSortAlgo.NUMERIC)
			);
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateMaintenanceLogEntry.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(createMaintenanceLogEntry.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdMaintenanceLogEntry = null;
		},
		[String(createMaintenanceLogEntry.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.maintenanceLogEntries.push(action.payload);
			state.maintenanceLogEntries = state.maintenanceLogEntries.sort(
				new ModelQueryOrderClosureBuilder<StoreModel>().build('CreatedAt', ModelQueryOrderDirection.DESC, ModelQueryOrderSortAlgo.NUMERIC)
			);
			state.createdMaintenanceLogEntry = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createMaintenanceLogEntry.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(deleteMaintenanceLogEntry.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteMaintenanceLogEntry.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.maintenanceLogEntries.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.maintenanceLogEntries.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteMaintenanceLogEntry.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		}
	}
});

export const { resetState, resetActionStatus } = maintenanceLogEntrySlice.actions;

// Export the reducer as default
export default maintenanceLogEntrySlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectMaintenanceLogEntries = (clientUuid: string, facilityUuid: string): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.maintenanceLogEntries.maintenanceLogEntries.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid && storeModel.Facility === facilityUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectMaintenanceLogEntryByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.maintenanceLogEntries.maintenanceLogEntries.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedMaintenanceLogEntry = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.maintenanceLogEntries.createdMaintenanceLogEntry;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectFilteredMaintenanceLogEntries = (
	clientUuid: string,
	facilityUuid: string,
	includeOngoing: boolean,
	includeSolved: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeOngoing && includeSolved || !includeOngoing && !includeSolved) {
		return selectMaintenanceLogEntries(clientUuid, facilityUuid);
	}
	const filterModelState = includeOngoing ? MaintenanceLogEntryModelState.ONGOING : MaintenanceLogEntryModelState.SOLVED;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.maintenanceLogEntries.maintenanceLogEntries.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectFacilityMaintenanceLogEntries = (
	clientUuid: string,
	facilityUuid: string
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.maintenanceLogEntries.maintenanceLogEntries.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& (
					storeModel.Device === ''
					|| storeModel.Device === null
				);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectFilteredFacilityMaintenanceLogEntries = (
	clientUuid: string,
	facilityUuid: string,
	includeOngoing: boolean,
	includeSolved: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeOngoing && includeSolved || !includeOngoing && !includeSolved) {
		return selectFacilityMaintenanceLogEntries(clientUuid, facilityUuid);
	}
	const filterModelState = includeOngoing ? MaintenanceLogEntryModelState.ONGOING : MaintenanceLogEntryModelState.SOLVED;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.maintenanceLogEntries.maintenanceLogEntries.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& (
					storeModel.Device === ''
					|| storeModel.Device === null
				)
				&& storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectDeviceMaintenanceLogEntries = (
	clientUuid: string,
	facilityUuid: string
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.maintenanceLogEntries.maintenanceLogEntries.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.Device !== ''
				&& storeModel.Device !== null;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectFilteredDeviceMaintenanceLogEntries = (
	clientUuid: string,
	facilityUuid: string,
	includeOngoing: boolean,
	includeSolved: boolean,
	includeActiveDevices: boolean,
	includeArchivedDevices: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		let storeModels = rootState.maintenanceLogEntries.maintenanceLogEntries.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.Device !== ''
				&& storeModel.Device !== null
				&& (
					includeSolved && storeModel.State === MaintenanceLogEntryModelState.SOLVED
					|| includeOngoing && storeModel.State === MaintenanceLogEntryModelState.ONGOING
				);
		}) ?? [];

		if (!includeActiveDevices || !includeArchivedDevices) {
			storeModels = filterMaintenanceLogEntriesByDevice(rootState, storeModels, includeActiveDevices, includeArchivedDevices);
		}

		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectMaintenanceLogEntriesByDevice = (
	deviceViewModel: DeviceViewModel
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		if (deviceViewModel === null) {
			return [];
		}
		const storeModels = rootState.maintenanceLogEntries.maintenanceLogEntries.filter((storeModel): boolean => {
			return storeModel.Client === deviceViewModel.Client
				&& storeModel.Facility === deviceViewModel.Facility
				&& storeModel.Device === deviceViewModel.Uuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectOpenMaintenanceLogEntriesByDevices = (
	deviceViewModels: ReadonlyArray<DeviceViewModel>
): (rootState: RootState) => ReadonlyMap<DeviceUuid, Array<ViewModel>> => {
	const deviceViewModelUuids = deviceViewModels.map<DeviceUuid>((device) => {
		return device.Uuid;
	});
	return (rootState: RootState): ReadonlyMap<DeviceUuid, Array<ViewModel>> => {
		const storeModels = rootState.maintenanceLogEntries.maintenanceLogEntries.filter((storeModel): boolean => {
			return deviceViewModelUuids.includes(storeModel?.Device)
				&& storeModel.State === MaintenanceLogEntryModelState.ONGOING as string;
		}) ?? [];

		const resultMap: Map<DeviceUuid, Array<ViewModel>> = new Map();

		for (const storeModel of storeModels) {
			try {
				const viewModel = new ModelConverter().fromStoreModel(storeModel).toViewModel();
				if (!resultMap.has(viewModel.Device)) {
					resultMap.set(viewModel.Device, [viewModel]);
				} else {
					resultMap.get(viewModel.Device).push(viewModel);
				}
			} catch (error) {
				DebugConsole.error(error);
			}
		}

		return resultMap;
	};
};

export const selectFilteredMaintenanceLogEntriesByDevice = (
	deviceViewModel: DeviceViewModel,
	includeOngoing: boolean,
	includeSolved: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeOngoing && includeSolved || !includeOngoing && !includeSolved) {
		return selectMaintenanceLogEntriesByDevice(deviceViewModel);
	}
	const filterModelState = includeOngoing ? MaintenanceLogEntryModelState.ONGOING : MaintenanceLogEntryModelState.SOLVED;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		if (deviceViewModel === null) {
			return [];
		}
		const storeModels = rootState.maintenanceLogEntries.maintenanceLogEntries.filter((storeModel): boolean => {
			return storeModel.Client === deviceViewModel.Client
				&& storeModel.Facility === deviceViewModel.Facility
				&& storeModel.Device === deviceViewModel.Uuid
				&& storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

const filterMaintenanceLogEntriesByDevice = (
	rootState: RootState,
	storeModels: ReadonlyArray<StoreModel>,
	includeActiveDevices: boolean,
	includeArchivedDevices: boolean
): Array<StoreModel> => {
	const devicesStateMap: Map<DeviceUuid, DeviceModelState> = new Map();
	for (const device of rootState.devices.devices) {
		devicesStateMap.set(device.Uuid, device.State as DeviceModelState);
	}

	return storeModels.filter((storeModel): boolean => {
		return (includeActiveDevices && devicesStateMap.get(storeModel.Device) === DeviceModelState.ACTIVE)
			|| (includeArchivedDevices && devicesStateMap.get(storeModel.Device) === DeviceModelState.ARCHIVED);
	});
};
