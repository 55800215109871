import { L10nContext } from 'context/L10nContext';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext } from 'react';
import { MessageLevel } from 'services/device/domain/business/common/message/MessageLevel';
import { MessageWidget } from 'services/device/domain/business/common/widget/Widget';

export interface MessageWidgetProps {
	widget: MessageWidget;
}

export const MessageWidgetRenderer = (props: MessageWidgetProps): JSX.Element => {
	const { widget } = props;

	const l10nContext = useContext(L10nContext);

	const messageNodes: Array<JSX.Element> = [];

	for (const summaryMessage of widget.getMessages()) {
		let notificationLevel: NotificationLevel;
		switch (summaryMessage.getLevel()) {
			case MessageLevel.NOTICE:
				notificationLevel = NotificationLevel.LEVEL_INFO;
				break;
			case MessageLevel.INFO:
				notificationLevel = NotificationLevel.LEVEL_NOTIFICATION;
				break;
		}
		messageNodes.push(
			<NotificationBar
				message={l10nContext.translate(summaryMessage.getMessageKey())}
				level={notificationLevel}
				key={summaryMessage.getMessageKey()}
			/>
		);
	}

	return (
		<NotificationBars>
			{messageNodes}
		</NotificationBars>
	);

};
