import { UnitOutput } from 'services/device/domain/business/common/Output';
import { getUnitDefinition } from 'services/device/domain/business/inventory/UnitDefinition';

export interface UnitOutputValueProps {
	output: UnitOutput;
}

export const UnitOutputValueRenderer = (props: UnitOutputValueProps): JSX.Element => {
	const { output } = props;

	const unit = output.getSingle() ?? null;
	const value = unit !== null ? getUnitDefinition(unit).getSymbol() : '';

	return (
		<span>
			{value ?? ''}
		</span>
	);

};
