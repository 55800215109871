import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './button-primary.scss';

export interface ButtonPrimaryProps extends React.ComponentPropsWithoutRef<'button'> {
	/**
	* Button text
	*/
	buttonText: string;
	/**
	 * (Optional) Icon
	 */
	icon?: IconIdentifier;
}

export const ButtonPrimary = (props: ButtonPrimaryProps): JSX.Element => {
	const { buttonText, icon, ...restProps } = props;
	const iconElement = icon ? <Icon name={icon} /> : null;

	return (
		<div className='button-primary'>
			<button
				className={`button-primary__button ${icon ? 'hasIcon' : ''}`}
				type='button'
				{...restProps}
			>
				{iconElement}
				<span className='button-primary__text'>{buttonText}</span>
			</button>
		</div>
	);
};
