import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface FormLayoutHeaderProps {
	/**
	* Text
	*/
	text: string;
	/**
	 * (Optional) icon
	 */
	icon?: IconIdentifier
}

export const FormLayoutHeader = (props: FormLayoutHeaderProps): JSX.Element => {
	const { text, icon } = props;

	const iconElement = icon ?
		<Icon name={icon} /> :
		null;

	return (
		<div className='form-layout__header'>
			{iconElement}
			{text}
		</div>
	);
};
