import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { WasteManagementReportViewModel } from '../../../../domain/models/WasteManagementReportModel';


export interface WasteManagementGeneratorReportCreateSuccessNotificationProps {
	onClickConfirm: () => void;
	wasteManagementReportCreated?: WasteManagementReportViewModel | null;
}

export const WasteManagementGeneratorReportCreateSuccessNotification = (props: WasteManagementGeneratorReportCreateSuccessNotificationProps): JSX.Element => {
	const { onClickConfirm, wasteManagementReportCreated } = props;

	const l10nContext = useContext(L10nContext);
	const history = useHistory();

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.wastemanagementReports.modal.action.create.successMessage', 'Die Meldung wurde erfolgreich angelegt')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.wastemanagementReports.modal.action.create.btnReportDetails', 'Weiter zu den Details der Meldung')}
						onClick={() => { history.push(`${Route.NUCLIDE_MANAGEMENT}${Route.WASTE_REPORTS}/${wasteManagementReportCreated.Uuid}`); }}
					/>
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.wastemanagementReports.modal.action.create.btnReportOverview', 'Weiter zur Übersicht der Jahresmeldungen')}
						onClick={onClickConfirm}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
