import { createContext } from 'react';

import { ActorModel, JsonWebToken, Permission } from 'services/core/lib/auth/AuthService';

interface AuthContextValue {
	latestActorUser(): string | null;

	latestActorUsers(): Array<string>;

	hasActor(): boolean;

	hasPermission(permission: Permission): boolean;

	hasAnyPermission(permissions: Array<Permission>): boolean;

	getActor(): ActorModel | null;

	authenticate(user: string, password: string): Promise<boolean>;

	invalidate(): Promise<void>;

	jsonWebToken(): JsonWebToken | null;
}

export const AuthContext = createContext<AuthContextValue>({
	latestActorUser(): string | null {
		return null;
	},

	latestActorUsers(): Array<string> {
		return [];
	},

	hasActor(): boolean {
		return false;
	},

	hasPermission(): boolean {
		return false;
	},

	hasAnyPermission(): boolean {
		return false;
	},

	getActor(): ActorModel | null {
		return null;
	},

	authenticate: async (): Promise<boolean> => {
		return false;
	},

	invalidate: async (): Promise<void> => {
	},

	jsonWebToken(): JsonWebToken | null {
		return null;
	}
});
