import { useEffect, useRef, useState } from 'react';

import './chip.scss';

export interface ChipProps {
	/**
	 * Label
	 */
	label: string;
	/**
	 * (Optional) id
	 */
	id?: string;
	/**
	 * (Optional) active state
	 */
	active?: boolean;
	/**
	 * (Optional) onClick-handler
	 */
	onClick?: (id: string, state: boolean) => void;
}

/**
 * The chip partial
 */
export const Chip = (props: ChipProps): JSX.Element => {
	const { label, id = label, active = false, onClick } = props;

	const [activeState, setActiveState] = useState(active);
	const userAction = useRef(false);

	useEffect(() => {
		if (userAction.current && onClick) {
			userAction.current = false;

			onClick(id, activeState);
		}
	}, [onClick, activeState, id]);

	const onClickHandler = () => {
		userAction.current = true;

		setActiveState(!activeState);
	};

	return (
		<div
			className={`chip ${activeState ? 'chip--active' : ''}`}
			onClick={onClickHandler}
			role='button'
			tabIndex={0}
		>
			<span className='chip__content'>
				{label}
			</span>
		</div>
	);
};
