import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';
import { useTypedSelector } from '../../../../../store/common/TypedSelector';
import {
	selectExternalWasteManagementReportCyclotronProducts
} from '../../../../nuclide/store/externalWasteManagementReportCyclotronProductSlice';
import { CyclotronProductsByNuclide } from '../../view/CyclotronProductsByNuclideType';
import {
	ExternalWasteManagementReportCyclotronProductAnnualReport
} from '../card-external-waste-management-report-cyclotron-product-annual-report/ExternalWasteManagementReportCyclotronProductAnnualReport';

import './external-waste-management-report-cyclotron-product-card.scss';
import {
	ExternalWasteManagementReportCyclotronProductDetail
} from '../card-external-waste-management-report-cyclotron-product-detail/ExternalWasteManagementReportCyclotronProductDetail';

export const ExternalWasteManagementReportCyclotronProductCard = (): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const externalWasteManagementReportCyclotronProducts = useTypedSelector(selectExternalWasteManagementReportCyclotronProducts());

	const cyclotronProductsByNuclides: Array<CyclotronProductsByNuclide> = [];
	if (externalWasteManagementReportCyclotronProducts.length > 0) {
		externalWasteManagementReportCyclotronProducts.forEach((cyclotronProduct) => {
			const existingInCyclotronProductsByNuclides = cyclotronProductsByNuclides.find((element) => {
				return cyclotronProduct.Isotope === element.Nuclide;
			});
			if (!existingInCyclotronProductsByNuclides) {
				const newCyclotronProductByNuclideItem: CyclotronProductsByNuclide = {
					Nuclide: cyclotronProduct.Isotope,
					CyclotronProducts: []
				};
				newCyclotronProductByNuclideItem.CyclotronProducts.push(cyclotronProduct);
				cyclotronProductsByNuclides.push(newCyclotronProductByNuclideItem);
			} else {
				const existingItemInCyclotronProductsByNuclide = cyclotronProductsByNuclides.find((element) => {
					return cyclotronProduct.Isotope === element.Nuclide;
				});
				existingItemInCyclotronProductsByNuclide.CyclotronProducts.push(cyclotronProduct);
			}
		});
	}

	const renderCyclotronProductAnnualReportInformation =
		<ExternalWasteManagementReportCyclotronProductAnnualReport
			cyclotronProductsByNuclides={cyclotronProductsByNuclides}
		/>;

	const renderCyclotronProductDetailInformation: JSX.Element =
		<ExternalWasteManagementReportCyclotronProductDetail
			cyclotronProductsByNuclides={cyclotronProductsByNuclides}
		/>;

	return (
		<article className='external-waste-management-report-cyclotron-products-card'>

			<section className='external-waste-management-report-cyclotron-products-card__section'>
				<header className='external-waste-management-report-cyclotron-products-card__section__header'>
					{l10nContext.translate('externalWasteManagementView.cyclotronProducts.annualTotalReport', 'Jahresgesamtmeldung')}
				</header>
				{renderCyclotronProductAnnualReportInformation}
			</section>

			<section>
				<header className='external-waste-management-report-cyclotron-products-card__section__header'>
					{l10nContext.translate('externalWasteManagementView.generators.detailReport', 'Detailmeldung')}
				</header>
				{ renderCyclotronProductDetailInformation }
			</section>

		</article>
	);
};
