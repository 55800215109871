import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ClearanceUpdateForm } from 'services/device/presentation/ui/clearance-action/clearance-update-form/ClearanceUpdateForm';
import { ClearanceUpdateSuccessNotification } from 'services/device/presentation/ui/clearance-action/clearance-update-modal/ClearanceUpdateSuccessNotification';
import { ClearanceUpdateFailedNotification } from 'services/device/presentation/ui/clearance-action/clearance-update-modal/ClearanceUpdateFailedNotification';

export interface ClearanceUpdateModalProps {
	device: DeviceViewModel
	onDismiss?: () => void;
}

export const ClearanceUpdateModal = (props: ClearanceUpdateModalProps): JSX.Element => {
	const { device, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const deviceActionStatus = useTypedSelector(state => state.devices.actionStatus);

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (deviceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent = <ClearanceUpdateForm device={device} />;
				break;

			case AsyncReducerStatus.UPDATED:
				modalContent = <ClearanceUpdateSuccessNotification onClickConfirm={onDismiss} />;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <ClearanceUpdateFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.clearances.modal.update.title', 'Freimessung bearbeiten')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
