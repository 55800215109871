import { ComponentPropsWithoutRef } from 'react';

import './button-user-feedback-layout.scss';

export type ButtonUserFeedbackLayoutProps = ComponentPropsWithoutRef<'div'>;

export const ButtonUserFeedbackLayout = (props: ButtonUserFeedbackLayoutProps): JSX.Element => {

	return (
		<div className='button-user-feedback-layout'>
			{props.children}
		</div>
	);

};
