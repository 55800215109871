import { DeviceType } from '../../inventory/DeviceType';
import { SequenceType } from '../../inventory/SequenceType';
import { Output } from '../Output';
import { SequenceFieldSet } from '../field-set/SequenceFieldSet';
import { SequenceStoreData, SequenceViewData } from './SequenceData';
import { FieldDescription } from '../description/FieldDescription';
import { Optional } from '../misc/Optional';

export class Sequence {
	private readonly deviceType: DeviceType;

	private readonly sequenceType: SequenceType;

	private readonly sequenceFieldSet: SequenceFieldSet<any>;

	constructor(deviceType: DeviceType, sequenceType: SequenceType, sequenceFieldSet: SequenceFieldSet<any>) {
		this.deviceType = deviceType;
		this.sequenceType = sequenceType;
		this.sequenceFieldSet = sequenceFieldSet;
	}

	public getDeviceType(): DeviceType {
		return this.deviceType;
	}

	public getSequenceType(): SequenceType {
		return this.sequenceType;
	}

	public getOutput(description: FieldDescription): Optional<Output<any>> {
		return this.sequenceFieldSet.getOutput(description.name);
	}

	public getFieldSet(): SequenceFieldSet<any> {
		return this.sequenceFieldSet.clone();
	}

	public toStoreData(): SequenceStoreData {
		return this.sequenceFieldSet.toStoreData(true);
	}

	public toViewData(): SequenceViewData {
		return this.sequenceFieldSet.toViewData(this.deviceType, this.sequenceType, true);
	}


}
