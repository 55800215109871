import './simple-view-subtitle.scss';

export interface SimpleViewSubtitleProps {
	/**
	 * Label
	 */
	label: string;
}

/**
 * Report simple subtitle
 */
export const SimpleViewSubtitle = (props: SimpleViewSubtitleProps): JSX.Element => {
	const { label } = props;

	return (
		<div className='simple-view-subtitle'>
			{label}
		</div>
	);
};
