import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	CreatableEluateViewModel as CreatableViewModel,
	EluateModelConverter as ModelConverter,
	EluateStoreModel as StoreModel,
	EluateViewModel as ViewModel
} from 'services/nuclide/domain/model/EluateModel';
import { EluatePersistence as Persistence } from 'services/nuclide/persistence/EluatePersistence';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { ModelQueryOrderClosureBuilder } from 'lib/persistence/idb/query/ModelQueryOrderClosureBuilder';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import { DebugConsole } from 'lib/debug/DebugConsole';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { EluateModelState } from '../domain/model/EluateModelState';
import { GeneratorViewModel } from '../domain/model/GeneratorModel';
import { EluateHttpPersistence as HttpPersistence } from '../persistence/EluateHttpPersistence';

// Declare a eluate state type
export interface EluateState {
	eluates: Array<StoreModel>;
	createdEluate: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	eluates: [] as Array<StoreModel>,
	createdEluate: null,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as EluateState;

// Implementation of the async actions
export const fetchEluates = createAsyncThunk(
	'eluates/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Silently abort the action
			const { eluates } = getState() as RootState;
			return checkFetchStatus(eluates.fetchStatus);
		}
	}
);

export const resetEluates = createAsyncThunk(
	'eluates/reset',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Silently abort the action
			const { eluates } = getState() as RootState;
			return checkFetchStatus(eluates.fetchStatus);
		}
	}
);

export const createEluate = createAsyncThunk(
	'eluate/create',
	async (viewModel: CreatableViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.createEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { eluates } = getState() as RootState;
			if (eluates.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const updateEluate = createAsyncThunk(
	'eluate/update',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.updateEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { eluates } = getState() as RootState;
			if (eluates.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteEluate = createAsyncThunk(
	'eluate/delete',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.deleteEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {

			// Abort the action with an error
			const { eluates } = getState() as RootState;
			if (eluates.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const eluateSlice = createSlice({
	name: 'eluates',
	initialState,
	// Regular syncronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	extraReducers: {
		[String(fetchEluates.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchEluates.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.eluates = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchEluates.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(resetEluates.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(resetEluates.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.eluates = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(resetEluates.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(createEluate.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdEluate = null;
		},
		[String(createEluate.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.eluates.push(action.payload);
			state.eluates = state.eluates.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('ExtractionDatetime'));
			state.createdEluate = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createEluate.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(updateEluate.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateEluate.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.eluates.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.eluates[index] = action.payload;
			}
			state.eluates = state.eluates.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('ExtractionDatetime'));
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateEluate.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(deleteEluate.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteEluate.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.eluates.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.eluates.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteEluate.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		}
	}
});

export const { resetState, resetActionStatus } = eluateSlice.actions;

// Export the reducer as default
export default eluateSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectEluates = (): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.eluates.eluates;
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectEluatesByGenerator = (generatorViewModel: GeneratorViewModel): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		if ((generatorViewModel ?? null) === null) {
			return [];
		}
		const storeModels = rootState.eluates.eluates.filter((storeModel): boolean => {
			return storeModel?.Generator === generatorViewModel.Uuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectEluatesByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.eluates.eluates.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedEluate = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.eluates.createdEluate;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectInWastemanagementEluates = (): (rootState: RootState) => ReadonlyArray<ViewModel> => {

	const filterModelState = EluateModelState.IN_WASTE_MANAGEMENT;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.eluates.eluates.filter((storeModel): boolean => {
			return storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};
