import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';
import { DeviceType } from 'services/device/domain/business/inventory/DeviceType';

export const mapTypeToIlluIdentifier = (deviceType: DeviceType): IllustrationIdentifier => {
	let illustrationIdentifier: IllustrationIdentifier;

	switch (deviceType) {
		case DeviceType.ACTIVIMETER:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_ACTIVIMETER;
			break;

		case DeviceType.PROBE_TESTERS:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_BOHRLOCH;
			break;

		case DeviceType.GAMMA_CAMERA_PLANAR:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_GAMKAMERA_PLANAR;
			break;
		
		case DeviceType.X_RAY_DIGITAL:
		case DeviceType.X_RAY_IMAGE_PLATE:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_X_RAY;
			break;

		case DeviceType.GAMMA_CAMERA_FOR_SPECT:
		case DeviceType.SPECT_CT:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_GAMKAMERA_SPECT;
			break;

		case DeviceType.SURGICAL_PROBE:
		case DeviceType.CLEARANCE:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_OP_SONDE;
			break;

		case DeviceType.PICTURE_REPRODUCTION:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_MONITOR;
			break;

		case DeviceType.GENERIC:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_GENERIC;
			break;

		case DeviceType.MRT:
		case DeviceType.CT:
		case DeviceType.PET_CT_PHILIPS_GEMINI:
		case DeviceType.PET_CT_PHILIPS_VEREOS:
		case DeviceType.PET_CT_SIEMENS_GENERIC:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_PET_CT;
			break;

		case DeviceType.RADIOCHEMICAL_PURITY:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_RADIOCHEMISCHE_REINHEIT;
			break;

		default:
			illustrationIdentifier = IllustrationIdentifier.DEVICE_ACTIVIMETER;
	}

	return illustrationIdentifier;
};
