import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { Label } from 'presentation/ui/partials/input/label/Label';
import { ValuedStringInput } from 'services/device/domain/business/common/Input';
import { StringFieldDescription } from 'services/device/domain/business/common/description/FieldDescription';
import { Sequence } from 'services/device/domain/business/common/sequence/Sequence';

import {
	ValuedStringRepeatableInputRenderer
} from 'services/device/presentation/ui/widget/input-renderer/ValuedStringRepetableInputRenderer';
import { ValuedStringSingleInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/ValuedStringSingleInputRenderer';

export interface ValuedStringInputProps {
	input: ValuedStringInput;
	sequence?: Sequence;
}

export const ValuedStringInputRenderer = (props: ValuedStringInputProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const { input, sequence } = props;

	let label: string;
	const preferredLabel: StringFieldDescription | null = input.getDescription().preferredLabel;
	if ((sequence ?? null) !== null && preferredLabel !== null) {
		label = sequence.getOutput(preferredLabel).getSingle() as string;
	} else {
		label = l10nContext.translate(input.getLabelKey());
	}

	const renderInput = (): JSX.Element => {
		if (input.isRepeatable()) {
			return (
				<ValuedStringRepeatableInputRenderer input={input} />
			);
		}
		return (
			<ValuedStringSingleInputRenderer input={input} />
		);
	};

	return (
		<Label label={label}>
			{renderInput()}
		</Label>
	);

};
