import { useContext, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { AuthContext } from '../../../../../core/context/AuthContext';
import { ClientContext } from '../../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../../core/context/FacilityContext';
import { ContainerDispatchViewModel } from '../../../../domain/model/ContainerDispatchModel';

import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { ContainerModelState } from '../../../../domain/model/ContainerModelState';
import { createContainerDispatch } from '../../../../store/containerDispatchSlice';
import { updateContainer } from '../../../../store/containerSlice';
import { ContainerDispatchFormStep1 } from './ContainerDispatchFormStep1';
import { ContainerDispatchFormStep2 } from './ContainerDispatchFormStep2';

export interface ContainerDispatchFormProps {
	container?: ContainerViewModel;
}

export const ContainerDispatchForm = (props: ContainerDispatchFormProps): JSX.Element => {
	const { container } = props;

	// Consume the dispatch object
	const dispatch = useDispatch();

	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const containerDispatchModel = useRef<ContainerDispatchViewModel>({} as ContainerDispatchViewModel);

	const updatedContainer: ContainerViewModel = container;

	const requiredInputs = ['DispatchedDatetime', 'DispatchedActivity'];

	const handleChangeContainerDispatchProp = (prop: string, value: any) => {
		containerDispatchModel.current[prop] = value;
	};

	if ((containerDispatchModel.current?.DispatchedDatetime ?? null) === null) {
		containerDispatchModel.current.DispatchedDatetime = new Date();
	}

	const handleUpdate = (): void => {

		containerDispatchModel.current.Client = clientContext.selectedClient().Uuid;
		containerDispatchModel.current.Facility = facilityContext.selectedFacility().Uuid;
		containerDispatchModel.current.Container = container.Uuid;
		containerDispatchModel.current.DispatchedBy = authContext.getActor().Uuid;

		updatedContainer.State = ContainerModelState.DISPATCHED;

		dispatch(createContainerDispatch(containerDispatchModel.current));
		dispatch(updateContainer(updatedContainer));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newContainerDispatchModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(containerDispatchModel.current?.[requiredInput] ?? null) === null
				|| containerDispatchModel?.current[requiredInput] === ''
				|| String(containerDispatchModel.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newContainerDispatchModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newContainerDispatchModelValid);
	};

	const showUpdateModalStep = showStep1 === true ?
		<ContainerDispatchFormStep1
			containerDispatchModel={containerDispatchModel.current}
			onClickNext={goToStep2}
			onChangeContainerDispatchProp={handleChangeContainerDispatchProp}
			invalidInputs={invalidInputs}
		/> :
		<ContainerDispatchFormStep2
			containerDispatchModel={containerDispatchModel.current}
			onClickPrevious={goToStep1}
			onClickConfirm={handleUpdate}
		/>;

	return (
		<>
			{showUpdateModalStep}
		</>
	);
};
