import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { ModelQueryOrderClosureBuilder } from 'lib/persistence/idb/query/ModelQueryOrderClosureBuilder';
import {
	ReportDocumentModelConverter,
	ReportDocumentStoreModel,
	ReportDocumentViewModel
} from 'services/report/domain/model/ReportDocumentModel';
import {
	ReportSequenceModelConverter,
	ReportSequenceStoreModel,
	ReportSequenceViewModel
} from 'services/report/domain/model/ReportSequenceModel';
import { ReportDocumentHttpPersistence } from 'services/report/persistence/ReportDocumentHttpPersistence';
import { ReportSequenceHttpPersistence } from 'services/report/persistence/ReportSequenceHttpPersistence';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';

import { ReportHttpPersistence as HttpPersistence } from 'services/report/persistence/ReportHttpPersistence';
import {
	CreatableReportViewModel as CreatableViewModel,
	ReportModelConverter as ModelConverter,
	ReportStoreModel as StoreModel,
	ReportViewModel as ViewModel,
} from 'services/report/domain/model/ReportModel';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';

// Declare a report state type
export interface ReportState {
	reports: Array<StoreModel>;
	createdReport: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	reports: [] as Array<StoreModel>,
	createdReport: null,
	fetchStatus: AsyncFetchStatus.IDLE,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ReportState;

export const fetchReports = createAsyncThunk(
	'reports/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Sliently abort the action
			const { reports } = getState() as RootState;
			return checkFetchStatus(reports.fetchStatus);
		}
	}
);

export const updateReport = createAsyncThunk(
	'report/update',
	async (params: { clientUuid: string; facilityUuid: string, viewModel: ViewModel }): Promise<StoreModel> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.update(new ModelConverter().fromViewModel(params.viewModel).toStoreModel());
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { reports } = getState() as RootState;
			if (reports.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const createReport = createAsyncThunk(
	'report/create',
	async (params: { clientUuid: string; facilityUuid: string, viewModel: CreatableViewModel }): Promise<StoreModel> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.create(new ModelConverter().fromCreatableViewModel(params.viewModel).toStoreModel());
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { reports } = getState() as RootState;
			if (reports.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteReport = createAsyncThunk(
	'report/delete',
	async (params: { clientUuid: string; facilityUuid: string, viewModel: ViewModel }): Promise<StoreModel> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.delete(new ModelConverter().fromViewModel(params.viewModel).toStoreModel());
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { reports } = getState() as RootState;
			if (reports.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const removeReportDocument = createAsyncThunk(
	'report/remove-report-document',
	async (params: { clientUuid: string; facilityUuid: string, reportDocumentViewModel: ReportDocumentViewModel }): Promise<ReportDocumentStoreModel> => {
		const persistence = new ReportDocumentHttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.delete(new ReportDocumentModelConverter().fromViewModel(params.reportDocumentViewModel).toStoreModel());
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { reports } = getState() as RootState;
			if (reports.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const removeReportSequence = createAsyncThunk(
	'report/remove-report-sequence',
	async (params: { clientUuid: string; facilityUuid: string, reportSequenceViewModel: ReportSequenceViewModel }): Promise<ReportSequenceStoreModel> => {
		const persistence = new ReportSequenceHttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.delete(new ReportSequenceModelConverter().fromViewModel(params.reportSequenceViewModel).toStoreModel());
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { reports } = getState() as RootState;
			if (reports.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const reportSlice = createSlice({
	name: 'reports',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchReports.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchReports.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.reports = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchReports.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(updateReport.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateReport.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.reports.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.reports[index] = action.payload;
			}
			state.reports = state.reports.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateReport.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(createReport.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdReport = null;
		},
		[String(createReport.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.reports.push(action.payload);
			state.reports = state.reports.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.createdReport = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createReport.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(deleteReport.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteReport.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.reports.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.reports.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteReport.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		},
		[String(removeReportDocument.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(removeReportDocument.fulfilled)]: (state, action: PayloadAction<ReportDocumentStoreModel>) => {
			const index = state.reports.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Report;
			}) ?? null;
			if (index !== null) {
				const report = { ...state.reports[index] } as StoreModel;
				const documentIndex = report.ReportDocuments.findIndex((entry): boolean => {
					return entry.Uuid === action.payload.Uuid;
				}) ?? null;
				if (documentIndex !== null) {
					report.ReportDocuments.splice(index, 1);
				}
				state.reports[index] = report;
			}
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(removeReportDocument.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		},
		[String(removeReportSequence.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(removeReportSequence.fulfilled)]: (state, action: PayloadAction<ReportSequenceStoreModel>) => {
			const index = state.reports.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Report;
			}) ?? null;
			if (index !== null) {
				const report = { ...state.reports[index] } as StoreModel;
				const sequenceIndex = report.ReportSequences.findIndex((entry): boolean => {
					return entry.Uuid === action.payload.Uuid;
				}) ?? null;
				if (sequenceIndex !== null) {
					report.ReportSequences.splice(index, 1);
				}
				state.reports[index] = report;
			}
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(removeReportSequence.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		}
	}
});

export const { resetState, resetActionStatus } = reportSlice.actions;

// Export the reducer as default
export default reportSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectReports = (): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const viewModels = rootState.reports.reports.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectReportByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.reports.reports.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedReport = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.reports.createdReport;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};
