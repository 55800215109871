import { SummaryWidget } from 'services/device/domain/business/common/widget/Widget';
import { SummaryWidgetSelection } from 'services/device/presentation/ui/widget/widget-renderer/SummaryWidgetSelection';

import './summary-widget-renderer.scss';

export interface SummaryWidgetProps {
	widget: SummaryWidget;
}

export const SummaryWidgetRenderer = (props: SummaryWidgetProps): JSX.Element => {
	const { widget } = props;

	const summaryNodes: Array<JSX.Element> = [];

	for (const summaryField of widget.getOutputs()) {
		summaryField.selectOutput(
			new SummaryWidgetSelection((element): void => {
				summaryNodes.push(element);
			})
		);
	}

	return (
		<ul className="summary-widget summary-widget--blue-background">
			{summaryNodes}
		</ul>
	);

};
