import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';


import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { MaintenanceLogCommentForm } from '../maintenance-log-comment-form/MaintenanceLogCommentForm';
import { MaintenanceLogCommentModalFailed } from './MaintenanceLogCommentModalFailed';
import { MaintenanceLogCommentModalSuccess } from './MaintenanceLogCommentModalSuccess';

export interface MaintenanceLogModalCommentProps {
	selectedMaintenancenLogEntry: MaintenanceLogEntryViewModel,
	onDismiss: () => void
}

export const MaintenanceLogCommentModal = (props: MaintenanceLogModalCommentProps): JSX.Element => {
	const { selectedMaintenancenLogEntry, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const maintenanceLogEntriesActionStatus = useTypedSelector(state => state.maintenanceLogEntries.actionStatus);

	const renderModalContent = () => {
		switch (maintenanceLogEntriesActionStatus) {
			case AsyncReducerStatus.IDLE:
				return <MaintenanceLogCommentForm maintenanceLogEntry={selectedMaintenancenLogEntry} />;

			case AsyncReducerStatus.UPDATED:
				return <MaintenanceLogCommentModalSuccess onClick={onDismiss} />;

			case AsyncReducerStatus.FAILED:
				return <MaintenanceLogCommentModalFailed onClick={onDismiss} />;

			default: return null;
		}
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.maintenances.modal.action.edit.caption', 'Betriebsbucheintrag Kommentar bearbeiten')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
