import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { Guid } from '../../../../../../lib/guid/Guid';
import { ExpandableSlimBody } from '../../../../../../presentation/ui/compositions/expandable-slim/expandable-slim-body/ExpandableSlimBody';
import {
	ExpandableSlimHeader
} from '../../../../../../presentation/ui/compositions/expandable-slim/expandable-slim-header/ExpandableSlimHeader';
import { ExpandableSlim } from '../../../../../../presentation/ui/compositions/expandable-slim/ExpandableSlim';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { getNuclideIsotopeDefinition } from '../../../../domain/model/isotopes/NuclideIsotopeDefinition';
import { ContainersByNuclide } from '../../../view/ContainersByNuclideType';
import { ContainerCollectionItem } from './ContainerCollectionItem';

export interface ContainerCollectionProps {
	containersByNuclides?: Array<ContainersByNuclide>
}

export const ContainerCollection = (props: ContainerCollectionProps): JSX.Element => {
	const { containersByNuclides } = props;

	const l10nContext = useContext(L10nContext);

	const renderContainerCards = (containers: Array<ContainerViewModel>): JSX.Element => {

		const usedNuclideMixedContainer = containers.sort((a, b) => {
			return new Date(a.ReleaseDateTime).getTime() - new Date(b.ReleaseDateTime).getTime();
		});

		const cards = usedNuclideMixedContainer.map((container) => {
				 return (
					 <ContainerCollectionItem
						 key={container.Uuid}
						 container={container}
					 />
				 );
		});
		return (
			<>
				{cards}
			</>
		);
	};

	const renderContainersByNuclides = () => {

		if (containersByNuclides?.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.containe', 'Keine offenen Container')} />
			);
		}

		return (
			containersByNuclides?.map((containersNuclide) => {
				return (
					<CardCollectionLayout key={Guid.generate()}>
						<ExpandableSlim mode='slim' expanded>
							<ExpandableSlimHeader
								caption={containersNuclide.Nuclide === 'GemischterContainer' ? 'Gemischter Container' : l10nContext.translate(getNuclideIsotopeDefinition(containersNuclide.Nuclide).getLabelKey())}
								isSlimMode={true}
							/>
							<ExpandableSlimBody>
								<CardCollectionLayout>
									{renderContainerCards(containersNuclide.Containers)}
								</CardCollectionLayout>
							</ExpandableSlimBody>
						</ExpandableSlim
						>
					</CardCollectionLayout>
				);
			})
		);
	};

	return (
		<CardCollectionLayout>
			{renderContainersByNuclides()}
		</CardCollectionLayout>
	);
} ;
