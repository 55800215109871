import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { CardItemTagType } from 'presentation/ui/partials/card/CardItemTagType';
import { Illustration } from 'presentation/ui/partials/illustration/Illustration';
import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';
import { Tag, TagType } from 'presentation/ui/partials/tag/Tag';
import { ContainerModelState } from 'services/nuclide/domain/model/ContainerModelState';

import './card-item-container-header.scss';

export interface CardItemContainerHeaderProps {
	/**
	 * Title
	 */
	title: string;
	/**
	 * (Optional) Sub-Title
	 */
	subtitle?: string | Date;
	/**
	 * (Optional) Illustration
	 */
	illustration?: IllustrationIdentifier
	/**
	 * (Optional) Tag
	 */
	tag?: CardItemTagType
	/**
	 * (Optional) Info-Tag
	 */
	infoTag?: CardItemTagType
	/**
	 * (Optional) Archived tag
	 */
	state?: ContainerModelState;
}

/**
 * A card item header component
 */
export const CardItemContainerHeader = (props: CardItemContainerHeaderProps): JSX.Element => {
	const { title, subtitle, illustration, tag, infoTag, state } = props;

	const l10nContext = useContext(L10nContext);

	const illuElement = illustration ?
		<Illustration name={illustration} /> :
		null;

	const subTitle = subtitle ?
		<p className='card-item-container-header__subtitle'>
			{subtitle}
		</p> :
		null;

	const tagElement = tag ?
		<Tag
			label={tag.label}
			type={tag.type}
		/> :
		null;

	const infoTagElement = infoTag ?
		<Tag
			label={infoTag.label}
			type={infoTag.type}
			icon={infoTag.icon}
		/> :
		null;

	const statusTagElement = (): JSX.Element => {
		const tagLabelOpen = l10nContext.translate('common.tags.open', 'Offen');
		const tagLabelDecay = l10nContext.translate('common.tags.decay', 'Abklingend');
		const tagLabelDispatched = l10nContext.translate('common.tags.dispatched', 'Abgegangen');
		const tagLabelReleased = l10nContext.translate('common.tags.released', 'Freigegeben');
		const tagLabelDisposed = l10nContext.translate('common.tags.diposed', 'Entsorgt');

		if (state === ContainerModelState.OPEN) {
			return (
				<Tag
					label={tagLabelOpen}
					type={TagType.INFO}
				/>
			);
		} if (state === ContainerModelState.DECAY) {
			return (
				<Tag
					label={tagLabelDecay}
					type={TagType.INFO}
				/>
			);
		} if (state === ContainerModelState.DISPATCHED) {
			return (
				<Tag
					label={tagLabelDispatched}
					type={TagType.INFO}
				/>
			);
		} if (state === ContainerModelState.RELEASE_DATE_REACHED) {
			return (
				<Tag
					label={tagLabelReleased}
					type={TagType.INFO}
				/>
			);
		} if (state === ContainerModelState.DISPOSED) {
			return (
				<Tag
					label={tagLabelDisposed}
					type={TagType.INFO}
				/>
			);
		}

		return null;
	};

	return (
		<div className={`card-item-container-header${ !illustration ? ' no-illustration' : ''}`}>
			{illuElement}

			<div className='card-item-container-header__content'>
				<span className='card-item-container-header__title-wrapper'>
					<h1 className='card-item-container-header__title'>
						{title}
					</h1>
					{tagElement}
				</span>

				{subTitle}
			</div>

			{infoTagElement}
			{statusTagElement()}
		</div>
	);
};
