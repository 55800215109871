import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { CardItemTagType } from 'presentation/ui/partials/card/CardItemTagType';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Illustration } from 'presentation/ui/partials/illustration/Illustration';
import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';
import { Tag, TagType } from 'presentation/ui/partials/tag/Tag';

import './card-item-header.scss';

export interface CardItemHeaderProps {
	/**
	 * Title
	 */
	title: string;
	/**
	 * (Optional) Sub-Title
	 */
	subtitle?: string | Date;
	/**
	 * (Optional) Illustration
	 */
	illustration?: IllustrationIdentifier
	/**
	 * (Optional) Tag
	 */
	tag?: CardItemTagType
	/**
	 * (Optional) Archived tag
	 */
	archived?: boolean
}

/**
 * A card item header component
 */
export const CardItemHeader = (props: CardItemHeaderProps): JSX.Element => {
	const { title, subtitle, illustration, tag, archived } = props;

	const l10nContext = useContext(L10nContext);

	const illuElement = illustration ?
		<Illustration name={illustration} /> :
		null;

	const subTitle = subtitle ?
		<p className='card-item-header__subtitle'>
			{subtitle}
		</p> :
		null;

	const tagElement = tag ?
		<Tag
			label={tag.label}
			type={tag.type}
		/> :
		null;

	const archivedTagElement = (): JSX.Element => {
		if (!archived) return null;

		const tagLabel = l10nContext.translate('common.tags.archived', 'Archiviert');

		return (
			<Tag
				label={tagLabel}
				type={TagType.INFO}
				icon={IconIdentifier.ARCHIVE}
			/>
		);
	};

	return (
		<div className={`card-item-header${ !illustration ? ' no-illustration' : ''}`}>
			{illuElement}

			<div className='card-item-header__content'>
				<span className='card-item-header__title-wrapper'>
					<h1 className='card-item-header__title'>
						{title}
					</h1>
					{tagElement}
				</span>

				{subTitle}
			</div>

			{archivedTagElement()}
		</div>
	);
};
