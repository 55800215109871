import React, { ComponentPropsWithRef } from 'react';

import { ClassName } from 'lib/class-name/ClassName';

import { FormLayoutModifier } from 'presentation/ui/layouts/form-layout/FormLayoutModifier';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';

import './form-layout.scss';

export interface FormLayoutProps extends ComponentPropsWithRef<'form'> {
	align: FormLayoutSectionAlign;
	modifier?: FormLayoutModifier;
	onSubmit: () => void;
}

export const FormLayout = (props: FormLayoutProps): JSX.Element => {
	const { align = null, modifier,  onSubmit, ...restProps } = props;

	const alignClass = ` form-layout--${ClassName.fromEnumValue(String(align))}`;

	const modifierClass = modifier ?
		` form-layout--${ClassName.fromEnumValue(String(modifier))}` :
		'';

	return (
		<form
			className={`form-layout${alignClass}${modifierClass}`}
			{...restProps}
			onSubmit={(event) => {
				event.preventDefault();
				onSubmit();
			}}
		>
			{props.children}
		</form>
	);

};
