/* eslint-disable max-classes-per-file */
import { Optional } from '../common/misc/Optional';
import { DeviceType } from './DeviceType';
import { ProductGroup } from './ProductGroup';
import {
	ProductGroupDefinition,
	getProductGroupDefinitions
} from './ProductGroupDefinition';

export class DeviceTypeDefinition {

	private readonly deviceType: DeviceType;

	private readonly labelKey: string;

	private readonly productGroups: ReadonlyArray<ProductGroup>;

	constructor(deviceType: DeviceType, labelKey: string, productGroups: ReadonlyArray<ProductGroup>) {
		this.deviceType = deviceType;
		this.labelKey = labelKey;
		this.productGroups = productGroups;
	}

	public getDeviceType(): DeviceType {
		return this.deviceType;
	}

	public getLabelKey(): string {
		return this.labelKey;
	}

	public getProductGroups(): ReadonlyArray<ProductGroup> {
		return this.productGroups;
	}

	public getProductGroupDefinitions(): ReadonlyArray<ProductGroupDefinition> {
		return getProductGroupDefinitions(this.productGroups);
	}

	public toString = (): string => {
		return this.deviceType.toString();
	}

}

export const ACTIVIMETER: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.ACTIVIMETER,
	'inventory.deviceType.activimeter',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const PROBE_TESTERS: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.PROBE_TESTERS,
	'inventory.deviceType.probeTesters',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const SURGICAL_PROBE: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.SURGICAL_PROBE,
	'inventory.deviceType.surgicalProbe',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const CONTAMINATION_MONITOR: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.CONTAMINATION_MONITOR,
	'inventory.deviceType.contaminationMonitor',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const GAMMA_CAMERA_PLANAR: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.GAMMA_CAMERA_PLANAR,
	'inventory.deviceType.gammaCameraPlanar',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const GAMMA_CAMERA_FOR_SPECT: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.GAMMA_CAMERA_FOR_SPECT,
	'inventory.deviceType.gammaCameraForSpect',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const RADIOCHEMICAL_PURITY: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.RADIOCHEMICAL_PURITY,
	'inventory.deviceType.radiochemicalPurity',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const PET_CT_PHILIPS_VEREOS: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.PET_CT_PHILIPS_VEREOS,
	'inventory.deviceType.petCtPhilipsVereos',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const PET_CT_PHILIPS_GEMINI: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.PET_CT_PHILIPS_GEMINI,
	'inventory.deviceType.petCtPhilipsGemini',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const PET_CT_SIEMENS_GENERIC: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.PET_CT_SIEMENS_GENERIC,
	'inventory.deviceType.petCtSiemensGeneric',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const SPECT_CT: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.SPECT_CT,
	'inventory.deviceType.spectCt',
	[
		ProductGroup.NUCLEAR_MEDICINE
	]
);

export const CT: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.CT,
	'inventory.deviceType.ct',
	[
		ProductGroup.RADIOLOGY
	]
);

export const MRT: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.MRT,
	'inventory.deviceType.mrt',
	[
		ProductGroup.RADIOLOGY
	]
);

export const X_RAY_IMAGE_PLATE: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.X_RAY_IMAGE_PLATE,
	'inventory.deviceType.xRayImagePlate',
	[
		ProductGroup.RADIOLOGY
	]
);

export const X_RAY_DIGITAL: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.X_RAY_DIGITAL,
	'inventory.deviceType.xRayDigital',
	[
		ProductGroup.RADIOLOGY
	]
);

export const PICTURE_REPRODUCTION: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.PICTURE_REPRODUCTION,
	'inventory.deviceType.pictureReproduction',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.RADIOLOGY
	]
);

export const GENERIC: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.GENERIC,
	'inventory.deviceType.generic',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.RADIOLOGY
	]
);

export const CLEARANCE: DeviceTypeDefinition = new DeviceTypeDefinition(
	DeviceType.CLEARANCE,
	'inventory.deviceType.clearance',
	[
		ProductGroup.CLEARANCE
	]
);

export function getDeviceTypeDefinition(deviceType: DeviceType): DeviceTypeDefinition {
	const deviceTypeDefinition: Optional<DeviceTypeDefinition> = getAllDeviceTypeDefinitions().find((definition) => definition.getDeviceType() === deviceType) ?? null;
	if (deviceTypeDefinition === null) {
		throw new Error('Missing device type definition: ' + deviceType);
	}
	return deviceTypeDefinition;
}

export function getDeviceTypeDefinitions(deviceTypes: ReadonlyArray<DeviceType>): ReadonlyArray<DeviceTypeDefinition> {
	return deviceTypes.map((deviceType) => getDeviceTypeDefinition(deviceType));
}

export function getAllDeviceTypeDefinitions(): ReadonlyArray<DeviceTypeDefinition> {
	return [
		ACTIVIMETER,
		PROBE_TESTERS,
		SURGICAL_PROBE,
		CONTAMINATION_MONITOR,
		GAMMA_CAMERA_PLANAR,
		GAMMA_CAMERA_FOR_SPECT,
		RADIOCHEMICAL_PURITY,
		PET_CT_PHILIPS_VEREOS,
		PET_CT_PHILIPS_GEMINI,
		PET_CT_SIEMENS_GENERIC,
		SPECT_CT,
		CT,
		MRT,
		X_RAY_IMAGE_PLATE,
		X_RAY_DIGITAL,
		PICTURE_REPRODUCTION,
		GENERIC,
		CLEARANCE
	];
}

export function getAllDeviceTypes(): ReadonlyArray<DeviceType> {
	return [
		DeviceType.ACTIVIMETER,
		DeviceType.PROBE_TESTERS,
		DeviceType.SURGICAL_PROBE,
		DeviceType.CONTAMINATION_MONITOR,
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.RADIOCHEMICAL_PURITY,
		DeviceType.PET_CT_PHILIPS_VEREOS,
		DeviceType.PET_CT_PHILIPS_GEMINI,
		DeviceType.PET_CT_SIEMENS_GENERIC,
		DeviceType.SPECT_CT,
		DeviceType.CT,
		DeviceType.MRT,
		DeviceType.X_RAY_IMAGE_PLATE,
		DeviceType.X_RAY_DIGITAL,
		DeviceType.PICTURE_REPRODUCTION,
		DeviceType.GENERIC,
		DeviceType.CLEARANCE
	];
}