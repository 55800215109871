import { useEffect } from 'react';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { updateDocument } from 'services/documents/store/documentSlice';
import { useDispatch } from 'react-redux';

import { DocumentModelState } from 'services/documents/domain/model/DocumentModelState';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentArchiveForm } from '../document-archive-form/DocumentArchiveForm';
import { DocumentArchiveFailedNotification } from './DocumentArchiveFailedNotification';

export interface DocumentArchiveModalProps {
	document: DocumentViewModel
	onDismiss?: () => void;
}

export const DocumentArchiveModal = (props: DocumentArchiveModalProps): JSX.Element => {
	const { document, onDismiss } = props;

	const documentsActionStatus = useTypedSelector(state => state.documents.actionStatus);

	const dispatch = useDispatch();

	useEffect(() => {
		if (documentsActionStatus === AsyncReducerStatus.UPDATED) {
			onDismiss();
		}
	}, [documentsActionStatus, onDismiss]);

	if (documentsActionStatus === AsyncReducerStatus.UPDATED) {
		return null;
	}

	const handleArchive = (): void => {
		dispatch(updateDocument({
			...document,
			...{
				State: DocumentModelState.ARCHIVED
			}
		}));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (documentsActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<DocumentArchiveForm
						document={document}
						onUpdate={handleArchive}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <DocumentArchiveFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
