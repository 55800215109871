import { CardItemTagType } from 'presentation/ui/partials/card/CardItemTagType';
import { Illustration } from 'presentation/ui/partials/illustration/Illustration';
import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';
import { Tag } from 'presentation/ui/partials/tag/Tag';

import './card-item-maintenance-log-header.scss';

export interface CardItemMaintenanceLogHeaderProps {
	/**
	 * Title
	 */
	title: string;
	/**
	 * (Optional) Sub-Title
	 */
	subtitle?: string;
	/**
	 * (Optional) Illustration
	 */
	illustration?: IllustrationIdentifier
	/**
	 * (Optional) Tag
	 */
	tag?: CardItemTagType
}

/**
 * A card item operational log header component
 */
export const CardItemMaintenanceLogHeader = (props: CardItemMaintenanceLogHeaderProps): JSX.Element => {
	const { title, subtitle, illustration, tag } = props;

	const illuElement = illustration ?
		<Illustration name={illustration} /> :
		null;

	const subTitle = subtitle ?
		<p className='card-item-maintenance-log-header__subtitle'>
			{subtitle}
		</p> :
		null;

	const tagElement = tag ?
		<Tag
			label={tag.label}
			type={tag.type}
		/> :
		null;

	return (
		<div className='card-item-maintenance-log-header'>
			{illuElement}

			<div className='card-item-maintenance-log-header__content'>
				<span className='card-item-maintenance-log-header__title-wrapper'>
					<h1 className='card-item-maintenance-log-header__title'>
						{title}
					</h1>
				</span>

				{subTitle}
			</div>

			{tagElement}
		</div>
	);
};
