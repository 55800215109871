import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ContainerDisposeModelConverter as ModelConverter, ContainerDisposeStoreModel,
	ContainerDisposeStoreModel as StoreModel,
	ContainerDisposeViewModel as ViewModel,
	CreatableContainerDisposeViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/ContainerDisposeModel';
import { DebugConsole } from '../../../lib/debug/DebugConsole';

import { PropellerError } from '../../../lib/persistence/http/error/PropellerError';
import { ModelQueryOrderClosureBuilder } from '../../../lib/persistence/idb/query/ModelQueryOrderClosureBuilder';
import { AsyncFetchStatus } from '../../../store/common/AsyncFetchStatus';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { AsyncReducerStatus } from '../../../store/common/AsyncReducerStatus';
import { RootState } from '../../../store/store';
import { ContainerDisposeHttpPersistence as HttpPersistence } from '../persistence/ContainerDisposeHttpPersistence';
import { ContainerDisposePersistence as Persistence } from '../persistence/ContainerDisposePersistence';

// Declare a container dispose state type
export interface ContainerDisposeState {
	containerDispose: Array<StoreModel>;
	createdContainerDispose: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState  = {
	containerDispose: [] as Array<StoreModel>,
	createdContainerDispose: null,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ContainerDisposeState;

// Implementation of the async actions

export const fetchContainerDispose = createAsyncThunk(
	'container-dispose/fetch-entity',
	async (params: { clientUuid: string, facilityUuid: string, disposeUuid: string }): Promise<ContainerDisposeStoreModel | null> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetch(params.disposeUuid);
	},
	{
		condition: (params, { getState }): boolean => {
			// Silently abort the action
			const { containerDispose } = getState() as RootState;
			return checkFetchStatus(containerDispose.fetchStatus);
		}
	}
);

export const fetchContainerDisposes = createAsyncThunk(
	'container-dispose/fetch-collection',
	async (params: { clientUuid: string, facilityUuid: string }): Promise<Array<ContainerDisposeStoreModel | null>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Silently abort the action
			const { containerDispose } = getState() as RootState;
			return checkFetchStatus(containerDispose.fetchStatus);
		}
	}
);

export const resetContainerDispose = createAsyncThunk(
	'container-dispose/reset',
	async (params: { clientUuid: string; facilityUuid: string;  }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { containerDispose } = getState() as RootState;
			return checkFetchStatus(containerDispose.fetchStatus);
		}
	}
);

export const createContainerDispose = createAsyncThunk(
	'container-dispose/create',
	async (viewModel: CreatableViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.createEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { containerDispose } = getState() as RootState;
			if (containerDispose.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteContainerDispose = createAsyncThunk(
	'container-dispose/delete',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.deleteEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {

			// Abort the action with an error
			const { containerDispose } = getState() as RootState;
			if (containerDispose.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const containerDisposeSlice = createSlice({
	name: 'containerDispose',
	initialState,
	// Regular syncronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	extraReducers: {
		[String(fetchContainerDispose.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchContainerDispose.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.containerDispose = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchContainerDispose.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(fetchContainerDisposes.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchContainerDisposes.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.containerDispose = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchContainerDisposes.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(resetContainerDispose.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(resetContainerDispose.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.containerDispose = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(resetContainerDispose.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(createContainerDispose.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdContainerDispose = null;
		},
		[String(createContainerDispose.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.containerDispose.push(action.payload);
			state.containerDispose = state.containerDispose.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Container'));
			state.createdContainerDispose = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createContainerDispose.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(deleteContainerDispose.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteContainerDispose.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.containerDispose.findIndex((entry: { Uuid: string; }): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.containerDispose.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteContainerDispose.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		}
	}
});

export const { resetState, resetActionStatus } = containerDisposeSlice.actions;

// Export the reducer as default
export default containerDisposeSlice.reducer;

export const selectContainerDisposeByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.containerDispose.containerDispose.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedContainerDispose = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.containerDispose.createdContainerDispose;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};
