import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DebugConsole } from 'lib/debug/DebugConsole';

import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import {
	WasteManagementReportGeneratorModelConverter as ModelConverter,
	WasteManagementReportGeneratorStoreModel as StoreModel,
	WasteManagementReportGeneratorViewModel as ViewModel
} from '../../waste-management-report/domain/models/WasteManagementReportGeneratorModel';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { ExternalWasteManagementReportGeneratorHttpPersistence }
	from '../persistence/ExternalWasteManagementReportGeneratorHttpPersistence';

// Declare a generator state type
export interface ExternalWasteManagementReportGeneratorState {
	externalWasteManagementReportGenerators: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	externalWasteManagementReportGenerators: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ExternalWasteManagementReportGeneratorState;

// Implementation of the async actions
export const fetchExternalWasteManagementReportGenerators = createAsyncThunk(
	'externalWasteManagementReportGenerators/fetch',
	async (params: { reportUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new ExternalWasteManagementReportGeneratorHttpPersistence(params.reportUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Silently abort the action
			const { externalWasteManagementReportGenerators } = getState() as RootState;
			return checkFetchStatus(externalWasteManagementReportGenerators.fetchStatus);
		}
	}
);

// Slice definition
export const externalWasteManagementReportGeneratorsSlice = createSlice({
	name: 'externalWasteManagementReportGenerators',
	initialState,
	// Regular syncronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	extraReducers: {
		[String(fetchExternalWasteManagementReportGenerators.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalWasteManagementReportGenerators.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.externalWasteManagementReportGenerators = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalWasteManagementReportGenerators.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

export const { resetState, resetActionStatus } = externalWasteManagementReportGeneratorsSlice.actions;

// Export the reducer as default
export default externalWasteManagementReportGeneratorsSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectExternalWasteManagementReportGenerators = (): (rootSTate: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels =
			rootState.externalWasteManagementReportGenerators.externalWasteManagementReportGenerators.filter((storeModel):
			StoreModel => {
				return storeModel;
			}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalWasteManagementReportGeneratorByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.externalWasteManagementReportGenerators.externalWasteManagementReportGenerators.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};
