import { DeviceType } from '../../inventory/DeviceType';
import { SequenceType } from '../../inventory/SequenceType';
import { SequenceFieldSet } from '../field-set/SequenceFieldSet';
import { Interaction } from '../interaction/Interaction';
import { SequenceInteraction } from '../interaction/SequenceInteraction';
import { Action } from '../misc/Action';
import { Environment } from '../misc/Environment';
import { Mode } from '../misc/Mode';
import { Step } from '../misc/Step';
import { Sequence } from './Sequence';
import { SequenceStoreData, SequenceViewData } from './SequenceData';
import { SequenceDefinition } from './SequenceDefinition';

export abstract class AbstractSequenceDefinition<Context, Fields, FieldSet extends SequenceFieldSet<Fields>> implements SequenceDefinition {
	private readonly deviceType: DeviceType;

	public constructor(deviceType: DeviceType) {
		this.deviceType = deviceType;
	}

	public getDeviceType(): DeviceType {
		return this.deviceType;
	}

	public abstract getSequenceType(): SequenceType;

	public createSequence(mode: Mode, dateTime: Date = new Date()): Interaction<Sequence> {
		const fieldSet: FieldSet = this.createSequenceFieldSet();
		return this.createSequenceInteraction(this.toEnvironment(Action.CREATE, mode, dateTime), fieldSet);
	}

	public updateSequence(precursor: Sequence, mode: Mode, dateTime: Date = new Date()): Interaction<Sequence> {
		const fieldSet: FieldSet = this.createSequenceFieldSet().fromViewData(precursor.toViewData());
		return this.createSequenceInteraction(this.toEnvironment(Action.UPDATE, mode, dateTime), fieldSet);
	}

	public restoreSequenceFromStoreData(sequenceStoreData: SequenceStoreData): Sequence {
		const fieldSet: FieldSet = this.createSequenceFieldSet().fromStoreData(sequenceStoreData);
		let interaction: Interaction<Sequence> = this.createSequenceInteraction(this.toEnvironment(Action.RESTORE, Mode.ADMIN, new Date()), fieldSet);
		while (!interaction.isLast()) {
			interaction = interaction.next();
		}
		return interaction.finish();
	}

	public restoreSequenceFromViewData(sequenceViewData: SequenceViewData): Sequence {
		const fieldSet: FieldSet = this.createSequenceFieldSet().fromViewData(sequenceViewData);
		return new Sequence(this.getDeviceType(), this.getSequenceType(), fieldSet);
	}

	private createSequenceInteraction(environment: Environment, fieldSet: FieldSet): Interaction<Sequence> {
		const steps = this.getSteps().filter((step) => step.filter === undefined || step.filter(environment));
		return new SequenceInteraction(
			environment,
			(e: Environment, f: Fields) => this.createContext(e, f),
			fieldSet,
			steps,
			0
		);
	}

	protected abstract getSteps(): ReadonlyArray<Step<Context, Fields>>;

	protected abstract createContext(environment: Environment, sequenceFields: Fields): Context;

	protected abstract createSequenceFieldSet(): FieldSet;

	private toEnvironment(action: Action, mode: Mode, dateTime: Date): Environment {
		return { action, mode, dateTime, deviceType: this.getDeviceType(), sequenceType: this.getSequenceType() };
	}
}
