import { L10nContext } from 'context/L10nContext';
import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { ContainerDisposeForm } from '../container-dispose-form/ContainerDisposeForm';
import { ContainerDisposeFailedNotification } from './ContainerDisposeFailedNotification';
import { ContainerDisposeSuccessNotification } from './ContainerDisposeSuccessNotification';

interface ContainerDisposeModalProps {
	container: ContainerViewModel;
	onDismiss: () => void;
}

export const ContainerDisposeModal = (props: ContainerDisposeModalProps): JSX.Element => {
	const { container, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const containerActionStatus = useTypedSelector(state => state.containers.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (containerActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <ContainerDisposeForm
					container={container}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modal =
					<ContainerDisposeSuccessNotification
						onClickConfirm={onDismiss}
						containerUpdated={container}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<ContainerDisposeFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;

	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.containers.modal.action.dispose.caption', 'Abgang erfassen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
