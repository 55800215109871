import {
	BooleanOutput,
	CommentOutput,
	DateTimeOutput,
	IntervalOutput,
	MailAddressOutput,
	NumberOutput,
	OutputSelection,
	StringOutput,
	TextOutput,
	UnitOutput,
	UrlOutput, ValuedStringOutput,
} from 'services/device/domain/business/common/Output';
import { BooleanOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/BooleanOutputValueRenderer';
import { CommentOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/CommentOutputValueRenderer';
import { DateTimeOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/DateTimeOutputValueRenderer';
import { IntervalOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/IntervalOutputValueRenderer';
import { MailAddressesOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/MailAddressesOutputValueRenderer';
import { NumberOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/NumberOutputValueRenderer';
import { StringOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/StringOutputValueRenderer';
import { TextOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/TextOutputValueRenderer';
import { UnitOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/UnitOutputValueRenderer';
import { UrlOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/UrlOutputValueRenderer';
import { ValuedStringOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/ValuedStringOutputValueRenderer';

export class OutputListSelection implements OutputSelection<void> {

	private readonly onElement: (element: JSX.Element) => void;

	public constructor(onElement: (element: JSX.Element) => void) {
		this.onElement = onElement;
	}

	onUnitOutput(output: UnitOutput): void {
		this.onElement(
			<UnitOutputValueRenderer key={output.getName()} output={output} />
		);
	}

	onDateTimeOutput(output: DateTimeOutput): void {
		this.onElement(
			<DateTimeOutputValueRenderer key={output.getName()} output={output} />
		);
	}

	onStringOutput(output: StringOutput): void {
		this.onElement(
			<StringOutputValueRenderer key={output.getName()} output={output} />
		);
	}

	onNumberOutput(output: NumberOutput): void {
		this.onElement(
			<NumberOutputValueRenderer key={output.getName()} output={output} />
		);
	}

	onBooleanOutput(output: BooleanOutput): void {
		this.onElement(
			<BooleanOutputValueRenderer key={output.getName()} output={output} />
		);
	}

	onMailAddressOutput(output: MailAddressOutput): void {
		this.onElement(
			<MailAddressesOutputValueRenderer key={output.getName()} output={output} />
		);
	}

	onIntervalOutput(output: IntervalOutput): void {
		this.onElement(
			<IntervalOutputValueRenderer key={output.getName()} output={output} />
		);
	}

	onTextOutput(output: TextOutput): void {
		this.onElement(
			<TextOutputValueRenderer key={output.getName()} output={output} />
		);
	}

	onUrlOutput(output: UrlOutput): void {
		this.onElement(
			<UrlOutputValueRenderer key={output.getName()} output={output} />
		);
	}

	onCommentOutput(output: CommentOutput): void {
		this.onElement(
			<CommentOutputValueRenderer key={output.getName()} output={output} />
		);
	}

	onValuedStringOutput(output: ValuedStringOutput): void {
		this.onElement(
			<ValuedStringOutputValueRenderer key={output.getName()} output={output} />
		);
	}

}
