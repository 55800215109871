import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import { RecordViewModel } from 'services/device/domain/model/RecordModel';
import { RecordDocumentsModalContent } from 'services/device/presentation/ui/record-action/record-documents-modal/RecordDocumentsModalContent';

export interface RecordDocumentsModalProps {
	record: RecordViewModel;
	onDismiss?: () => void;
}

export const RecordDocumentsModal = (props: RecordDocumentsModalProps): JSX.Element => {
	const onDismiss = props.onDismiss;
	const recordViewModel = props.record;

	const l10nContext = useContext(L10nContext);

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.documents.modal.action.addDocument.caption', 'Dokument(e) hinzufügen')}
				captionIcon={IconIdentifier.DOC}
			/>
			<ModalDialogueSection>
				<RecordDocumentsModalContent record={recordViewModel} />
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
