/* eslint-disable max-classes-per-file */
import { ChartDescription } from '../../common/description/ChartDescription';
import { DetailsDescription } from '../../common/description/DetailsDescription';
import {
	CommentFieldDescription,
	DateTimeFieldDescription,
	FieldDescription,
	IntervalFieldDescription,
	MailAddressFieldDescription,
	NumberFieldDescription,
	StringFieldDescription,
	TextFieldDescription,
	UnitFieldDescription,
	UrlFieldDescription
} from '../../common/description/FieldDescription';
import { TableDescription } from '../../common/description/TableDescription';
import { MemoryFieldSet } from '../../common/field-set/MemoryFieldSet';
import { RecordFieldSet } from '../../common/field-set/RecordFieldSet';
import { SequenceFieldSet } from '../../common/field-set/SequenceFieldSet';
import {
	CommentField,
	CommentFieldCalculation,
	DateTimeField,
	DateTimeFieldCalculation,
	IntervalField,
	IntervalFieldCalculation,
	MailAddressField,
	MailAddressFieldCalculation,
	NumberField,
	NumberFieldCalculation,
	StringField,
	StringFieldCalculation,
	TextField,
	TextFieldCalculation,
	UnitField,
	UnitFieldCalculation,
	UrlField,
	UrlFieldCalculation
} from '../../common/field/Field';
import { FieldType } from '../../common/field/FieldType';
import { MemoryStoreData } from '../../common/memory/MemoryData';
import { Message } from '../../common/message/Message';
import { MessageLevel } from '../../common/message/MessageLevel';
import { Action } from '../../common/misc/Action';
import { Environment } from '../../common/misc/Environment';
import {
	LookupTable,
	LookupTables
} from '../../common/misc/LookupTable';
import { Mode } from '../../common/misc/Mode';
import { Step } from '../../common/misc/Step';
import { Void } from '../../common/misc/Void';
import { AbstractRecordDefinition } from '../../common/record/AbstractRecordDefinition';
import { Record } from '../../common/record/Record';
import { RecordViewData } from '../../common/record/RecordData';
import { RecordDefinition } from '../../common/record/RecordDefinition';
import { AbstractSequenceDefinition } from '../../common/sequence/AbstractSequenceDefinition';
import { Sequence } from '../../common/sequence/Sequence';
import { SequenceViewData } from '../../common/sequence/SequenceData';
import { SequenceDefinition } from '../../common/sequence/SequenceDefinition';
import { SequenceTypeDefinitionProxy } from '../../common/sequence/SequenceTypeDefinitionProxy';
import { BandValuation } from '../../common/value/BandValuation';
import { Before } from '../../common/value/Before';
import { Difference } from '../../common/value/Difference';
import { Fallback } from '../../common/value/Fallback';
import { IsNoticeable } from '../../common/value/IsNoticeable';
import { List } from '../../common/value/List';
import { Literal } from '../../common/value/Literal';
import { Lookup } from '../../common/value/Lookup';
import { NotEquals } from '../../common/value/NotEquals';
import { Null } from '../../common/value/Null';
import { Percent } from '../../common/value/Percent';
import { Sum } from '../../common/value/Sum';
import { Value } from '../../common/value/Value';
import { WorstValuation } from '../../common/value/WorstValuation';
import { Violation } from '../../common/violation/Violation';
import {
	InputWidget,
	MessageWidget,
	SummaryWidget
} from '../../common/widget/Widget';
import { DeviceType } from '../../inventory/DeviceType';
import { Interval } from '../../inventory/Interval';
import { SequenceType } from '../../inventory/SequenceType';
import { Unit } from '../../inventory/Unit';

type SequenceFieldDescriptions = {
	readonly name: StringFieldDescription;
	readonly ident: StringFieldDescription;
	readonly note: TextFieldDescription;
	readonly instructionUrl: UrlFieldDescription;
	readonly notificationMailAddresses: MailAddressFieldDescription;
	readonly possibleReminderIntervals: IntervalFieldDescription;
	readonly reminderInterval: IntervalFieldDescription;
	readonly possibleUnits: UnitFieldDescription;
	readonly activityUnit: UnitFieldDescription;
	readonly unit: UnitFieldDescription;
};

type MemoryFieldDescriptions = Void;

type RecordFieldDescriptions = {
	readonly recordedAt: DateTimeFieldDescription;
	readonly kitBatchNumber: StringFieldDescription;
	readonly preparedAt: DateTimeFieldDescription;
	readonly generatorIdent: StringFieldDescription;
	readonly methodOneActivityStart: NumberFieldDescription;
	readonly methodOneActivityFront: NumberFieldDescription;
	readonly methodOneValue: NumberFieldDescription;
	readonly methodTwoActivityStart: NumberFieldDescription;
	readonly methodTwoActivityFront: NumberFieldDescription;
	readonly methodTwoValue: NumberFieldDescription;
	readonly expectedValue: NumberFieldDescription;
	readonly lowerToleranceValue: NumberFieldDescription;
	readonly value: NumberFieldDescription;
	readonly deviation: NumberFieldDescription;
	readonly comment: CommentFieldDescription;
};

type SequenceFieldCalculations = {
	readonly name: StringFieldCalculation<SequenceContext>;
	readonly ident: StringFieldCalculation<SequenceContext>;
	readonly note: TextFieldCalculation<SequenceContext>;
	readonly instructionUrl: UrlFieldCalculation<SequenceContext>;
	readonly notificationMailAddresses: MailAddressFieldCalculation<SequenceContext>;
	readonly possibleReminderIntervals: IntervalFieldCalculation<SequenceContext>;
	readonly reminderInterval: IntervalFieldCalculation<SequenceContext>;
	readonly possibleUnits: UnitFieldCalculation<SequenceContext>;
	readonly activityUnit: UnitFieldCalculation<SequenceContext>;
	readonly unit: UnitFieldCalculation<SequenceContext>;
};

type MemoryFieldCalculations = Void;

type RecordFieldCalculations = {
	readonly recordedAt: DateTimeFieldCalculation<RecordContext>;
	readonly kitBatchNumber: StringFieldCalculation<RecordContext>;
	readonly preparedAt: DateTimeFieldCalculation<RecordContext>;
	readonly generatorIdent: StringFieldCalculation<RecordContext>;
	readonly methodOneActivityStart: NumberFieldCalculation<RecordContext>;
	readonly methodOneActivityFront: NumberFieldCalculation<RecordContext>;
	readonly methodOneValue: NumberFieldCalculation<RecordContext>;
	readonly methodTwoActivityStart: NumberFieldCalculation<RecordContext>;
	readonly methodTwoActivityFront: NumberFieldCalculation<RecordContext>;
	readonly methodTwoValue: NumberFieldCalculation<RecordContext>;
	readonly expectedValue: NumberFieldCalculation<RecordContext>;
	readonly lowerToleranceValue: NumberFieldCalculation<RecordContext>;
	readonly value: NumberFieldCalculation<RecordContext>;
	readonly deviation: NumberFieldCalculation<RecordContext>;
	readonly comment: CommentFieldCalculation<RecordContext>;
};

type SequenceFields = {
	readonly name: StringField<SequenceContext>;
	readonly ident: StringField<SequenceContext>;
	readonly note: TextField<SequenceContext>;
	readonly instructionUrl: UrlField<SequenceContext>;
	readonly notificationMailAddresses: MailAddressField<SequenceContext>;
	readonly possibleReminderIntervals: IntervalField<SequenceContext>;
	readonly reminderInterval: IntervalField<SequenceContext>;
	readonly possibleUnits: UnitField<SequenceContext>;
	readonly activityUnit: UnitField<SequenceContext>;
	readonly unit: UnitField<SequenceContext>;
};

type MemoryFields = Void;

type RecordFields = {
	readonly recordedAt: DateTimeField<RecordContext>;
	readonly kitBatchNumber: StringField<RecordContext>;
	readonly preparedAt: DateTimeField<RecordContext>;
	readonly generatorIdent: StringField<RecordContext>;
	readonly methodOneActivityStart: NumberField<RecordContext>;
	readonly methodOneActivityFront: NumberField<RecordContext>;
	readonly methodOneValue: NumberField<RecordContext>;
	readonly methodTwoActivityStart: NumberField<RecordContext>;
	readonly methodTwoActivityFront: NumberField<RecordContext>;
	readonly methodTwoValue: NumberField<RecordContext>;
	readonly expectedValue: NumberField<RecordContext>;
	readonly lowerToleranceValue: NumberField<RecordContext>;
	readonly value: NumberField<RecordContext>;
	readonly deviation: NumberField<RecordContext>;
	readonly comment: CommentField<RecordContext>;
};

type SequenceContext = {
	readonly mode: Value<Mode>;
	readonly dateTime: Value<Date>;
	readonly deviceType: Value<DeviceType>;
	readonly sequenceFields: SequenceFields;
};

type MemoryContext = Void;

type RecordContext = {
	readonly mode: Value<Mode>;
	readonly dateTime: Value<Date>;
	readonly deviceType: Value<DeviceType>;
	readonly sequenceFields: SequenceFields;
	readonly memoryFields: MemoryFields;
	readonly recordFields: RecordFields;
};

const SEQUENCE_FIELD_DESCRIPTIONS: SequenceFieldDescriptions = {
	name: {
		type: FieldType.STRING,
		name: 'name',
		labelKey: 'inventory.field.name',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	ident: {
		type: FieldType.STRING,
		name: 'ident',
		labelKey: 'inventory.field.ident',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	note: {
		type: FieldType.TEXT,
		name: 'note',
		labelKey: 'inventory.field.note',
		repeatable: false,
		mandatory: false,
		valued: false,
		stored: true
	},
	instructionUrl: {
		type: FieldType.URL,
		name: 'instructionUrl',
		labelKey: 'inventory.field.instructionUrl',
		repeatable: false,
		mandatory: false,
		valued: false,
		stored: true
	},
	notificationMailAddresses: {
		type: FieldType.MAIL_ADDRESS,
		name: 'notificationMailAddresses',
		labelKey: 'inventory.field.notificationMailAddresses',
		repeatable: true,
		mandatory: false,
		valued: false,
		stored: true
	},
	possibleReminderIntervals: {
		type: FieldType.INTERVAL,
		name: 'possibleReminderIntervals',
		labelKey: 'inventory.field.possibleReminderIntervals',
		repeatable: true,
		mandatory: true,
		valued: false,
		stored: false
	},
	reminderInterval: {
		type: FieldType.INTERVAL,
		name: 'reminderInterval',
		labelKey: 'inventory.field.reminderInterval',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	possibleUnits: {
		type: FieldType.UNIT,
		name: 'possibleUnits',
		labelKey: 'inventory.field.possibleUnits',
		repeatable: true,
		mandatory: true,
		valued: false,
		stored: false
	},
	activityUnit: {
		type: FieldType.UNIT,
		name: 'activityUnit',
		labelKey: 'inventory.field.activityUnit',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: false
	},
	unit: {
		type: FieldType.UNIT,
		name: 'unit',
		labelKey: 'inventory.field.unit',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	}
};

const MEMORY_FIELD_DESCRIPTIONS: MemoryFieldDescriptions = {};

const RECORD_FIELD_DESCRIPTIONS: RecordFieldDescriptions = {
	recordedAt: {
		type: FieldType.DATE_TIME,
		name: 'recordedAt',
		labelKey: 'inventory.field.recordedAt',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	kitBatchNumber: {
		type: FieldType.STRING,
		name: 'kitBatchNumber',
		labelKey: 'inventory.field.kitBatchNumber',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	preparedAt: {
		type: FieldType.DATE_TIME,
		name: 'preparedAt',
		labelKey: 'inventory.field.preparedAt',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	generatorIdent: {
		type: FieldType.STRING,
		name: 'generatorIdent',
		labelKey: 'inventory.field.generatorIdent',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	methodOneActivityStart: {
		type: FieldType.NUMBER,
		name: 'methodOneActivityStart',
		labelKey: 'inventory.field.methodOneActivityStart',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true,
		numberOfDecimals: 3,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.activityUnit
	},
	methodOneActivityFront: {
		type: FieldType.NUMBER,
		name: 'methodOneActivityFront',
		labelKey: 'inventory.field.methodOneActivityFront',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true,
		numberOfDecimals: 3,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.activityUnit
	},
	methodOneValue: {
		type: FieldType.NUMBER,
		name: 'methodOneValue',
		labelKey: 'inventory.field.methodOneValue',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: false,
		numberOfDecimals: 3,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	methodTwoActivityStart: {
		type: FieldType.NUMBER,
		name: 'methodTwoActivityStart',
		labelKey: 'inventory.field.methodTwoActivityStart',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true,
		numberOfDecimals: 3,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.activityUnit
	},
	methodTwoActivityFront: {
		type: FieldType.NUMBER,
		name: 'methodTwoActivityFront',
		labelKey: 'inventory.field.methodTwoActivityFront',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true,
		numberOfDecimals: 3,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.activityUnit
	},
	methodTwoValue: {
		type: FieldType.NUMBER,
		name: 'methodTwoValue',
		labelKey: 'inventory.field.methodTwoValue',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: false,
		numberOfDecimals: 3,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	expectedValue: {
		type: FieldType.NUMBER,
		name: 'expectedValue',
		labelKey: 'inventory.field.expectedValue',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: false,
		numberOfDecimals: 3,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	lowerToleranceValue: {
		type: FieldType.NUMBER,
		name: 'lowerToleranceValue',
		labelKey: 'inventory.field.lowerToleranceValue',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: false,
		numberOfDecimals: 3,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	value: {
		type: FieldType.NUMBER,
		name: 'value',
		labelKey: 'inventory.field.value',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true,
		numberOfDecimals: 3,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	deviation: {
		type: FieldType.NUMBER,
		name: 'deviation',
		labelKey: 'inventory.field.deviation',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: false,
		numberOfDecimals: 3,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	comment: {
		type: FieldType.COMMENT,
		name: 'comment',
		labelKey: 'inventory.field.comment',
		repeatable: false,
		mandatory: false,
		valued: false,
		stored: true
	}
};

const DETAILS_DESCRIPTION: DetailsDescription = {
	entries: [
		SEQUENCE_FIELD_DESCRIPTIONS.ident,
		SEQUENCE_FIELD_DESCRIPTIONS.note,
		SEQUENCE_FIELD_DESCRIPTIONS.unit,
		SEQUENCE_FIELD_DESCRIPTIONS.reminderInterval,
		SEQUENCE_FIELD_DESCRIPTIONS.notificationMailAddresses
	],
	showInitialValue: false
};

const CHART_DESCRIPTIONS: ReadonlyArray<ChartDescription> = [
	{
		name: 'value',
		labelKey: 'inventory.chart.value',
		value: RECORD_FIELD_DESCRIPTIONS.value,
		deviation: RECORD_FIELD_DESCRIPTIONS.deviation,
		expectedValue: RECORD_FIELD_DESCRIPTIONS.expectedValue,
		lowerToleranceValue: RECORD_FIELD_DESCRIPTIONS.lowerToleranceValue,
		comment: RECORD_FIELD_DESCRIPTIONS.comment,
		upperBoundary: 100,
		lowerBoundary: 0
	}
];

const TABLE_DESCRIPTIONS: ReadonlyArray<TableDescription> = [
	{
		name: 'value',
		labelKey: 'inventory.table.value',
		value: RECORD_FIELD_DESCRIPTIONS.value,
		deviation: RECORD_FIELD_DESCRIPTIONS.deviation,
		expectedValue: RECORD_FIELD_DESCRIPTIONS.expectedValue,
		lowerToleranceValue: RECORD_FIELD_DESCRIPTIONS.lowerToleranceValue,
		additionalColumns: [
			RECORD_FIELD_DESCRIPTIONS.kitBatchNumber,
			RECORD_FIELD_DESCRIPTIONS.preparedAt,
			RECORD_FIELD_DESCRIPTIONS.generatorIdent,
			RECORD_FIELD_DESCRIPTIONS.methodOneActivityStart,
			RECORD_FIELD_DESCRIPTIONS.methodOneActivityFront,
			RECORD_FIELD_DESCRIPTIONS.methodOneValue,
			RECORD_FIELD_DESCRIPTIONS.methodTwoActivityStart,
			RECORD_FIELD_DESCRIPTIONS.methodTwoActivityFront,
			RECORD_FIELD_DESCRIPTIONS.methodTwoValue
		],
		comment: RECORD_FIELD_DESCRIPTIONS.comment
	}
];

const LOOKUP_TABLES: LookupTables = {
	possibleReminderIntervalsByDeviceType: new LookupTable<DeviceType, Interval>(
		[],
		new List<Interval>(
			[
				Interval.NONE,
				Interval.DAILY,
				Interval.WEEKLY,
				Interval.TWO_WEEKLY,
				Interval.MONTHLY,
				Interval.QUARTER_YEARLY,
				Interval.HALF_YEARLY,
				Interval.YEARLY,
				Interval.LATCHED_MONTHLY,
				Interval.LATCHED_QUARTER_YEARLY,
				Interval.LATCHED_HALF_YEARLY,
				Interval.LATCHED_YEARLY
			]
		)
	),
	possibleUnitsByDeviceType: new LookupTable<DeviceType, Unit>(
		[
			[
				DeviceType.RADIOCHEMICAL_PURITY,
				new List<Unit>(
					[
						Unit.PERCENT,
						Unit.PERCENT_TC_X
					]
				)
			]
		],
		new List<Unit>(
			[]
		)
	)
};

const SEQUENCE_FIELD_CALCULATIONS: SequenceFieldCalculations = {
	name: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.name
	},
	ident: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.ident
	},
	note: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.note
	},
	instructionUrl: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.instructionUrl
	},
	notificationMailAddresses: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.notificationMailAddresses
	},
	possibleReminderIntervals: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.possibleReminderIntervals,
		value: (context: SequenceContext) => {
			return new Lookup<DeviceType, Interval>(
				LOOKUP_TABLES.possibleReminderIntervalsByDeviceType,
				context.deviceType
			);
		}
	},
	reminderInterval: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.reminderInterval,
		options: (context: SequenceContext) => {
			return context.sequenceFields.possibleReminderIntervals;
		}
	},
	possibleUnits: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.possibleUnits,
		value: (context: SequenceContext) => {
			return new Lookup<DeviceType, Unit>(
				LOOKUP_TABLES.possibleUnitsByDeviceType,
				context.deviceType
			);
		}
	},
	activityUnit: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.activityUnit,
		value: (context: SequenceContext) => {
			return new Literal<Unit>(
				Unit.MEGA_BECQUEREL
			);
		}
	},
	unit: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.unit,
		options: (context: SequenceContext) => {
			return context.sequenceFields.possibleUnits;
		}
	}
};

const MEMORY_FIELD_CALCULATIONS: MemoryFieldCalculations = {};

const RECORD_FIELD_CALCULATIONS: RecordFieldCalculations = {
	recordedAt: {
		description: RECORD_FIELD_DESCRIPTIONS.recordedAt,
		value: (context: RecordContext) => {
			return new Fallback(
				context.recordFields.recordedAt,
				context.dateTime
			);
		}
	},
	kitBatchNumber: {
		description: RECORD_FIELD_DESCRIPTIONS.kitBatchNumber
	},
	preparedAt: {
		description: RECORD_FIELD_DESCRIPTIONS.preparedAt
	},
	generatorIdent: {
		description: RECORD_FIELD_DESCRIPTIONS.generatorIdent
	},
	methodOneActivityStart: {
		description: RECORD_FIELD_DESCRIPTIONS.methodOneActivityStart
	},
	methodOneActivityFront: {
		description: RECORD_FIELD_DESCRIPTIONS.methodOneActivityFront
	},
	methodOneValue: {
		description: RECORD_FIELD_DESCRIPTIONS.methodOneValue,
		value: (context: RecordContext) => {
			return new Percent(
				context.recordFields.methodOneActivityFront,
				new Sum(
					context.recordFields.methodOneActivityStart,
					context.recordFields.methodOneActivityFront
				)
			);
		},
		valuation: (context: RecordContext) => {
			return new BandValuation(
				new Literal<number>(
					5
				),
				new Null<number>(),
				context.recordFields.methodOneValue,
				new Null<number>(),
				new Null<number>()
			);
		}
	},
	methodTwoActivityStart: {
		description: RECORD_FIELD_DESCRIPTIONS.methodTwoActivityStart
	},
	methodTwoActivityFront: {
		description: RECORD_FIELD_DESCRIPTIONS.methodTwoActivityFront
	},
	methodTwoValue: {
		description: RECORD_FIELD_DESCRIPTIONS.methodTwoValue,
		value: (context: RecordContext) => {
			return new Percent(
				context.recordFields.methodTwoActivityStart,
				new Sum(
					context.recordFields.methodTwoActivityStart,
					context.recordFields.methodTwoActivityFront
				)
			);
		},
		valuation: (context: RecordContext) => {
			return new BandValuation(
				new Literal<number>(
					2
				),
				new Null<number>(),
				context.recordFields.methodTwoValue,
				new Null<number>(),
				new Null<number>()
			);
		}
	},
	expectedValue: {
		description: RECORD_FIELD_DESCRIPTIONS.expectedValue,
		value: (context: RecordContext) => {
			return new Literal<number>(
				100
			);
		}
	},
	lowerToleranceValue: {
		description: RECORD_FIELD_DESCRIPTIONS.lowerToleranceValue,
		value: (context: RecordContext) => {
			return new Literal<number>(
				94
			);
		}
	},
	value: {
		description: RECORD_FIELD_DESCRIPTIONS.value,
		value: (context: RecordContext) => {
			return new Difference(
				context.recordFields.expectedValue,
				new Sum(
					context.recordFields.methodOneValue,
					context.recordFields.methodTwoValue
				)
			);
		},
		valuation: (context: RecordContext) => {
			return new BandValuation(
				new Null<number>(),
				new Null<number>(),
				context.recordFields.value,
				new Null<number>(),
				context.recordFields.lowerToleranceValue
			);
		}
	},
	deviation: {
		description: RECORD_FIELD_DESCRIPTIONS.deviation,
		value: (context: RecordContext) => {
			return new Difference(
				context.recordFields.value,
				context.recordFields.expectedValue
			);
		}
	},
	comment: {
		description: RECORD_FIELD_DESCRIPTIONS.comment,
		mandatory: (context: RecordContext) => {
			return new IsNoticeable(
				new WorstValuation(
					context.recordFields.value
				)
			);
		}
	}
};

const SEQUENCE_STEPS: ReadonlyArray<Step<SequenceContext, SequenceFields>> = [
	{
		instructions: (fields: SequenceFields) => {
			return {
				widgets: [
					new InputWidget(
						[
							fields.name,
							fields.ident,
							fields.note,
							fields.instructionUrl
						]
					)
				]
			};
		}
	},
	{
		filter: (environment: Environment) => {
			return environment.action === Action.CREATE || environment.mode === Mode.ADMIN;
		},
		instructions: (fields: SequenceFields) => {
			return {
				calculations: [
					fields.possibleUnits,
					fields.activityUnit
				],
				widgets: [
					new InputWidget(
						[
							fields.unit
						]
					)
				]
			};
		}
	},
	{
		instructions: (fields: SequenceFields) => {
			return {
				calculations: [
					fields.possibleReminderIntervals
				],
				widgets: [
					new InputWidget(
						[
							fields.notificationMailAddresses,
							fields.reminderInterval
						]
					)
				]
			};
		}
	},
	{
		instructions: (fields: SequenceFields) => {
			return {
				widgets: [
					new SummaryWidget(
						[
							fields.name,
							fields.ident,
							fields.note,
							fields.instructionUrl,
							fields.unit,
							fields.notificationMailAddresses,
							fields.reminderInterval
						]
					)
				]
			};
		}
	}
];

const RECORD_STEPS: ReadonlyArray<Step<RecordContext, RecordFields>> = [
	{
		filter: (environment: Environment) => {
			return environment.mode === Mode.ADMIN;
		},
		instructions: (fields: RecordFields) => {
			return {
				calculations: [
					fields.recordedAt
				],
				widgets: [
					new InputWidget(
						[
							fields.recordedAt
						]
					)
				]
			};
		}
	},
	{
		instructions: (fields: RecordFields) => {
			return {
				calculations: [
					fields.recordedAt,
					fields.expectedValue,
					fields.lowerToleranceValue
				],
				widgets: [
					new MessageWidget(
						[
							new Message(
								MessageLevel.INFO,
								'inventory.message.messageRotopResetActivimeter'
							)
						]
					),
					new InputWidget(
						[
							fields.kitBatchNumber,
							fields.preparedAt,
							fields.generatorIdent
						]
					)
				],
				validations: [
					{
						condition: (context: RecordContext) => {
							return new Before(
								context.recordFields.preparedAt,
								context.recordFields.recordedAt
							);
						},
						violation: new Violation(
							[
								fields.preparedAt
							],
							'inventory.message.preparedAtMustBeBeforeRecordedAt'
						)
					}
				]
			};
		}
	},
	{
		instructions: (fields: RecordFields) => {
			return {
				widgets: [
					new MessageWidget(
						[
							new Message(
								MessageLevel.INFO,
								'inventory.message.messageRpFreePertechnetate'
							)
						]
					),
					new MessageWidget(
						[
							new Message(
								MessageLevel.INFO,
								'inventory.message.messageRotopCodeRed'
							),
							new Message(
								MessageLevel.INFO,
								'inventory.message.messageRotopAgentRed'
							)
						]
					),
					new InputWidget(
						[
							fields.methodOneActivityStart,
							fields.methodOneActivityFront
						]
					)
				],
				validations: [
					{
						condition: (context: RecordContext) => {
							return new NotEquals(
								new Sum(
									context.recordFields.methodOneActivityStart,
									context.recordFields.methodOneActivityFront
								),
								new Literal<number>(
									0
								)
							);
						},
						violation: new Violation(
							[
								fields.methodOneActivityStart,
								fields.methodOneActivityFront
							],
							'inventory.message.sumOfActivityOneStartAndActivityOneFrontMustNotBeZero'
						)
					}
				]
			};
		}
	},
	{
		instructions: (fields: RecordFields) => {
			return {
				widgets: [
					new MessageWidget(
						[
							new Message(
								MessageLevel.INFO,
								'inventory.message.messageRpColloidalImpurities'
							)
						]
					),
					new MessageWidget(
						[
							new Message(
								MessageLevel.INFO,
								'inventory.message.messageRotopCodeBlue'
							),
							new Message(
								MessageLevel.INFO,
								'inventory.message.messageRotopAgentBlue'
							)
						]
					),
					new InputWidget(
						[
							fields.methodTwoActivityStart,
							fields.methodTwoActivityFront
						]
					)
				],
				validations: [
					{
						condition: (context: RecordContext) => {
							return new NotEquals(
								new Sum(
									context.recordFields.methodTwoActivityStart,
									context.recordFields.methodTwoActivityFront
								),
								new Literal<number>(
									0
								)
							);
						},
						violation: new Violation(
							[
								fields.methodTwoActivityStart,
								fields.methodTwoActivityFront
							],
							'inventory.message.sumOfActivityTwoStartAndActivityTwoFrontMustNotBeZero'
						)
					}
				]
			};
		}
	},
	{
		instructions: (fields: RecordFields) => {
			return {
				calculations: [
					fields.methodOneValue,
					fields.methodTwoValue,
					fields.value,
					fields.deviation
				],
				widgets: [
					new SummaryWidget(
						[
							fields.methodOneValue,
							fields.methodTwoValue,
							fields.value,
							fields.lowerToleranceValue
						]
					),
					new InputWidget(
						[
							fields.comment
						]
					)
				]
			};
		}
	}
];

function createSequenceFields(): SequenceFields {
	return {
		name: new StringField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.name
		),
		ident: new StringField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.ident
		),
		note: new TextField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.note
		),
		instructionUrl: new UrlField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.instructionUrl
		),
		notificationMailAddresses: new MailAddressField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.notificationMailAddresses
		),
		possibleReminderIntervals: new IntervalField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.possibleReminderIntervals
		),
		reminderInterval: new IntervalField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.reminderInterval
		),
		possibleUnits: new UnitField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.possibleUnits
		),
		activityUnit: new UnitField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.activityUnit
		),
		unit: new UnitField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.unit
		)
	};
}

function createMemoryFields(): MemoryFields {
	return {};
}

function createRecordFields(): RecordFields {
	return {
		recordedAt: new DateTimeField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.recordedAt
		),
		kitBatchNumber: new StringField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.kitBatchNumber
		),
		preparedAt: new DateTimeField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.preparedAt
		),
		generatorIdent: new StringField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.generatorIdent
		),
		methodOneActivityStart: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.methodOneActivityStart
		),
		methodOneActivityFront: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.methodOneActivityFront
		),
		methodOneValue: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.methodOneValue
		),
		methodTwoActivityStart: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.methodTwoActivityStart
		),
		methodTwoActivityFront: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.methodTwoActivityFront
		),
		methodTwoValue: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.methodTwoValue
		),
		expectedValue: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.expectedValue
		),
		lowerToleranceValue: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.lowerToleranceValue
		),
		value: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.value
		),
		deviation: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.deviation
		),
		comment: new CommentField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.comment
		)
	};
}

class ActualSequenceDefinition extends AbstractSequenceDefinition<SequenceContext, SequenceFields, SequenceFieldSet<SequenceFields>> {
	public getSequenceType(): SequenceType {
		return SequenceType.RP_ROTOP_MAG3_DOUBLE_STRIPE;
	}

	protected getSteps(): ReadonlyArray<Step<SequenceContext, SequenceFields>> {
		return SEQUENCE_STEPS;
	}

	protected createContext(environment: Environment, sequenceFields: SequenceFields): SequenceContext {
		return {
			mode: new Literal<Mode>(environment.mode),
			dateTime: new Literal<Date>(environment.dateTime),
			deviceType: new Literal<DeviceType>(environment.deviceType),
			sequenceFields
		};
	}

	protected createSequenceFieldSet(): SequenceFieldSet<SequenceFields> {
		return new SequenceFieldSet(
			() => {
				return createSequenceFields();
			}
		);
	}

}

class ActualRecordDefinition extends AbstractRecordDefinition<RecordContext, RecordFields, RecordFieldSet<RecordFields>, SequenceFields, MemoryFields> {
	public getSequenceType(): SequenceType {
		return SequenceType.RP_ROTOP_MAG3_DOUBLE_STRIPE;
	}

	protected getSteps(): ReadonlyArray<Step<RecordContext, RecordFields>> {
		return RECORD_STEPS;
	}

	protected createRecordContext(environment: Environment, recordFields: RecordFields): RecordContext {
		return {
			mode: new Literal<Mode>(environment.mode),
			dateTime: new Literal<Date>(environment.dateTime),
			deviceType: new Literal<DeviceType>(environment.deviceType),
			sequenceFields: this.getSequenceFieldSet().getRawFields(),
			memoryFields: this.getMemoryFieldSet().getRawFields(),
			recordFields
		};
	}

	protected createRecordFieldSet(): RecordFieldSet<RecordFields> {
		return new RecordFieldSet(
			() => {
				return createRecordFields();
			},
			RECORD_FIELD_DESCRIPTIONS.value.name
		);
	}

}

export class RpRotopMag3DoubleStripeSequenceTypeDefinitionProxy implements SequenceTypeDefinitionProxy {
	public getValueDescription(): FieldDescription {
		return RECORD_FIELD_DESCRIPTIONS.value;
	}

	public getDetailsDescription(): DetailsDescription {
		return DETAILS_DESCRIPTION;
	}

	public getChartDescriptions(): ReadonlyArray<ChartDescription> {
		return CHART_DESCRIPTIONS;
	}

	public getTableDescriptions(): ReadonlyArray<TableDescription> {
		return TABLE_DESCRIPTIONS;
	}

	public getCommentDescription(): CommentFieldDescription {
		return RECORD_FIELD_DESCRIPTIONS.comment;
	}

	public getSequenceDefinition(deviceType: DeviceType): SequenceDefinition {
		return new ActualSequenceDefinition(deviceType);
	}

	public getRecordDefinition(sequence: Sequence, memoryStoreData: MemoryStoreData): RecordDefinition {
		const memoryFieldSet = this.createMemoryFieldSet().fromStoreData(memoryStoreData);
		return new ActualRecordDefinition(sequence.getDeviceType(), sequence.getFieldSet(), memoryFieldSet);
	}

	private createMemoryFieldSet(): MemoryFieldSet<MemoryFields> {
		return new MemoryFieldSet(
			() => {
				return createMemoryFields();
			}
		);
	}

	public restoreSequenceFromViewData(sequenceViewData: SequenceViewData): Sequence {
		const fields = new SequenceFieldSet(() => createSequenceFields()).fromViewData(sequenceViewData);
		return new Sequence(sequenceViewData.deviceType, SequenceType.RP_ROTOP_MAG3_DOUBLE_STRIPE, fields);
	}

	public restoreRecordFromViewData(recordViewData: RecordViewData): Record {
		const fields = new RecordFieldSet(() => createRecordFields(), this.getValueDescription().name).fromViewData(recordViewData);
		return new Record(recordViewData.deviceType, SequenceType.RP_ROTOP_MAG3_DOUBLE_STRIPE, fields, recordViewData.valuation);
	}

}
