import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';
import { memo, useContext } from 'react';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { getGeneratorMeasurementUnitDefinition } from 'services/nuclide/domain/model/NuclideMeasurementUnitDefinition';
import { GeneratorTypeIsotope } from '../../../../../../services/nuclide/domain/model/isotopes/GeneratorTypeIsotope';
import { calculateCurrentActivity } from '../../../../../../services/nuclide/domain/model/isotopes/NuclideIsotopeDefinition';

import './card-item-generator-attributes.scss';

export interface CardItemGeneratorAttributesProps {
	/**
	 * Generator
	 */
	generator: GeneratorViewModel;
}

/**
 * A card item generator detail attributes component
 */
export const CardItemGeneratorAttributes = memo((props: CardItemGeneratorAttributesProps): JSX.Element => {
	const { generator } = props;

	const l10nContext = useContext(L10nContext);

	const generatorTypeIsotope = generator.Isotope;

	const isotopeFormatted = generator.Isotope === GeneratorTypeIsotope.GERMANIUM_68_GE ? l10nContext.translate('nuclides.isotopes.germaniumType', 'Germanium') : l10nContext.translate('nuclides.isotopes.molybdenumType', 'Molybdän');
	const deliveryDateFormatted = l10nContext.formatDate(generator.DeliveryDate);
	const calibrationDateTimeFormatted = l10nContext.formatDateTime(generator.CalibrationDatetime);
	const activityUnit = l10nContext.translate(getGeneratorMeasurementUnitDefinition(generator.ActivityUnit).getLabelKey());
	const currentActivity = calculateCurrentActivity(generator.CalibrationDatetime, generator.NominalActivity, generatorTypeIsotope);

	const translations = {
		name: { label: l10nContext.translate('generators.name', 'Name'), value: generator.Name },
		isotope: { label: l10nContext.translate('generators.isotope', 'Isotop'), value: isotopeFormatted },
		manufacturer: { label: l10nContext.translate('generators.manufacturer', 'Hersteller'), value: generator.Manufacturer },
		batchNumber: { label: l10nContext.translate('generators.batch', 'Charge'), value: generator.BatchNumber },
		serialNumber: { label: l10nContext.translate('generators.serialNumber', 'Seriennummer'), value: generator.SerialNumber },
		deliveryDate: { label: l10nContext.translate('generators.deliveryDate', 'Lieferdatum'), value: deliveryDateFormatted },
		nominalActivity: { label: l10nContext.translate('generators.nominalActivity', 'Nominalaktivität'), value: l10nContext.formatNumber(generator.NominalActivity) + ' ' + activityUnit },
		calibrationTime: { label: l10nContext.translate('generators.calibrationDateTime', 'Kalibrierzeitpunkt'), value: calibrationDateTimeFormatted },
		acceptedByName: { label: l10nContext.translate('generators.acceptedBy', 'Angenommen von'), value: generator.AcceptedByName },
		currentActivity: { label: l10nContext.translate('generators.currentActivity', 'Aktuelle Aktivität'), value: l10nContext.formatNumber(currentActivity) + ' ' + activityUnit }
	};

	const attributes = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const styleClass = 'attribute';

			return (
				<label
					key={Guid.generate()}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value }</p>
				</label>
			);
		});
	};

	return (
		<div className='card-item-generator-attributes'>
			<ul className='card-item-generator-attributes__attribute'>
				{attributes()}
			</ul>
		</div>
	);
});
