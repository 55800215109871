import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';

import {
	ReportRecordModelConverter as ModelConverter,
	ReportRecordPersistenceModel as PersistenceModel,
	ReportRecordStoreModel as StoreModel,
	ReportRecordViewModel as ViewModel,
	CreatableReportRecordViewModel as CreatableViewModel
} from 'services/report/domain/model/ReportRecordModel';
import { ExternalHttpPersistence } from '../../../lib/persistence/http/ExternalHttpPersistence';

export class ExternalReportRecordHttpPersistence extends ExternalHttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'device/report-api/v1';
	protected collectionControllerUri = 'records';
	protected entityControllerUri = 'record';

	protected responseCollectionKey = 'RecordModelCollection';
	protected responseEntityKey = 'RecordModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

	public async fetchCollectionBySequence(sequenceUuid: string): Promise<Array<StoreModel>> {
		return super.fetchCollection(() => {
			return process.env.REACT_APP_API_BASE_URL + this.serviceApiControllerUri + '/sequence/' + sequenceUuid + '/records';
		});
	}

}
