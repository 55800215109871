import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';

import {
	DocumentModelConverter as ModelConverter,
	DocumentPersistenceModel as PersistenceModel,
	DocumentStoreModel as StoreModel,
	DocumentViewModel as ViewModel,
	CreatableDocumentViewModel as CreatableViewModel
} from 'services/documents/domain/model/DocumentModel';
import { DocumentHttpPersistence as HttpPersistence } from 'services/documents/persistence/DocumentHttpPersistence';

export class DocumentPersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {

	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

}
