import { ClassName } from 'lib/class-name/ClassName';

import './column-layout.scss';

export enum ColumnLayoutMode {
	ALIGN_CENTER = 'ALIGN_CENTER',
	ALIGN_RIGHT = 'ALIGN_RIGHT'
}

export interface ColumnLayoutProps extends React.ComponentPropsWithoutRef<'article'> {
	mode?: ColumnLayoutMode
}

export const ColumnLayout = (props: ColumnLayoutProps): JSX.Element => {
	const modifier = props.mode ?
		`column-layout--mode-${ClassName.fromEnumValue(props.mode)}` :
		'';

	return (
		<article className={`column-layout ${modifier}`}>
			{props.children}
		</article>
	);
};
