import { Valuation } from 'services/device/domain/business/common/Valuation';
import { getSequenceDue, getSequenceValuation } from 'services/device/domain/business/util/SequenceUtil';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogEntryModelState } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModelState';

export const countDueSequences = (sequenceViewModels: ReadonlyArray<SequenceViewModel>): number => {
	let count = 0;

	for (const viewModel of sequenceViewModels) {
		// eslint-disable-next-line no-await-in-loop
		count += getSequenceDue(viewModel) ? 1 : 0;
	}

	return count;
};

export const countNoticeableOrUnacceptableSequences = (sequenceViewModels: ReadonlyArray<SequenceViewModel>): number => {
	let count = 0;

	for (const viewModel of sequenceViewModels) {
		// eslint-disable-next-line no-await-in-loop
		const valuation = getSequenceValuation(viewModel);
		count += valuation === Valuation.NOTICEABLE || valuation === Valuation.UNACCEPTABLE ? 1 : 0;
	}

	return count;
};

export const countNoticeableSequences = async (sequenceViewModels: ReadonlyArray<SequenceViewModel>): Promise<number> => {
	let count = 0;

	for (const viewModel of sequenceViewModels) {
		// eslint-disable-next-line no-await-in-loop
		count += getSequenceValuation(viewModel) === Valuation.NOTICEABLE ? 1 : 0;
	}

	return count;
};

export const countUnacceptableSequences = async (sequenceViewModels: ReadonlyArray<SequenceViewModel>): Promise<number> => {
	let count = 0;

	for (const viewModel of sequenceViewModels) {
		// eslint-disable-next-line no-await-in-loop
		count += getSequenceValuation(viewModel) === Valuation.UNACCEPTABLE ? 1 : 0;
	}

	return count;
};

export const countOngoingMaintenanceLogEntries = (maintenanceLogEntryViewModels: ReadonlyArray<MaintenanceLogEntryViewModel>): number => {
	return maintenanceLogEntryViewModels.reduce<number>((count, viewModel) => {
		return count + (viewModel.State === MaintenanceLogEntryModelState.ONGOING ? 1 : 0);
	}, 0);
};
