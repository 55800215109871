import React, { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutHeader } from 'presentation/ui/layouts/form-layout/form-layout-header/FormLayoutHeader';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutModifier } from 'presentation/ui/layouts/form-layout/FormLayoutModifier';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { InputText, InputTextStatus } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';

export interface ContainerFactorMeasurementFormStep2Props {
	container: ContainerViewModel;
	radiationFactor: string;
	sumWasteActivity: number;
	currentSpecificActivity: number;
	specificActivity: number;
	halflife: number;
	releaseDateTime: Date
	/**
	 * Button back action
	 */
	onClickPrevious: () => void;
	/**
	 * Button confirm
	 */
	onClickNext?: () => void;
}

export const ContainerFactorMeasurementFormStep2 = (props: ContainerFactorMeasurementFormStep2Props): JSX.Element => {
	const {
		container,
		radiationFactor,
		sumWasteActivity,
		currentSpecificActivity,
		specificActivity,
		halflife,
		releaseDateTime,
		onClickPrevious,
		onClickNext
	} = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	const releaseDateTimeFormatted = l10nContext.formatDateTime(releaseDateTime);
	const measurementDateTimeFormatted = l10nContext.formatDateTime(new Date(container?.MeasurementDate));

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			modifier={FormLayoutModifier.BLUE_BACKGROUND}
			onSubmit={onClickNext}
		>
			<FormLayoutHeader
				text={l10nContext.translate('common.modal.checkDetails', 'Überprüfen Sie bitte Ihre Angaben')}
				icon={IconIdentifier.WARNING}
			/>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('containers.measurementDateTime', 'Messzeitpunkt')}*`}>
					<InputText
						type='text'
						value={measurementDateTimeFormatted}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('containers.facilityFactor', 'Standortspezifischer Faktor')}
					thin
				>
					<InputText
						type="text"
						defaultValue={radiationFactor}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('containers.sumWasteActivities', 'Summe der Abfallaktivitäten')}
					thin
				>
					<InputText
						type="text"
						defaultValue={l10nContext.formatNumber(sumWasteActivity) + ' Bq'}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('containers.weight', 'Gewicht')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={l10nContext.formatNumber(container?.Weight) + ' g'}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('containers.currentSpecificActivity', 'Aktuelle spezifische Aktivität')}
					thin
				>
					<InputText
						type="text"
						defaultValue={l10nContext.formatNumber(currentSpecificActivity) + ' Bq/g'}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('containers.specificActivity', 'Zu erreichende spezifische Aktivität')}
					thin
				>
					<InputText
						type="text"
						defaultValue={l10nContext.formatNumber(specificActivity) + ' Bq/g'}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('containers.halflife', 'Halbwertszeit')}
					thin
				>
					<InputText
						type="text"
						defaultValue={l10nContext.formatNumber(halflife) + ' Stunden'}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('containers.releaseDateTime', 'Freigabedatum')}`}
					thin
				>
					<InputText
						type='text'
						value={releaseDateTimeFormatted}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.back', 'Zurück')}
							onClick={onClickPrevious}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.save', 'Speichern')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
