export enum CyclotronProductViewModalType {
	EDIT = 'EDIT',
	CREATE = 'CREATE',
	DELETE = 'DELETE',
	TO_WASTE_MODULE = 'TO_WASTE_MODULE',
	TO_ACTIVE_STATE = 'TO_ACTIVE_STATE',
	COMPLETELY_APPLIED = 'COMPLETELY_APPLIED',
	UNDO_COMPLETELY_APPLIED = 'UNDO_COMPLETELY_APPLIED',
	CREATE_DOCUMENT = 'CREATE_DOCUMENT',
	DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
	DELETE_DOCUMENT = 'DELETE_DOCUMENT',
	EDIT_DOCUMENT_NAME = 'EDIT_DOCUMENT_NAME',
	ARCHIVE_DOCUMENT = 'ARCHIVE_DOCUMENT',
	UNARCHIVE_DOCUMENT = 'UNARCHIVE_DOCUMENT'
}
