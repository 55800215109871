import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { useKeyPress } from 'presentation/hooks';
import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { selectDeviceByUuid } from 'services/device/store/devicesSlice';
import { useSelector } from 'react-redux';
import { selectFolderByUuid } from 'services/documents/store/folderSlice';
import { selectMaintenanceLogEntryByUuid } from 'services/maintenance-log/store/maintenanceLogEntrySlice';

export interface DocumentAddSuccessNotificationProps {
	files: File[];
	documentScope: DocumentModelScope;
	documentScopeReference: string;
	onClickConfirm: () => void;
}

export const DocumentAddSuccessNotification = (props: DocumentAddSuccessNotificationProps): JSX.Element => {
	const { files, documentScope, documentScopeReference, onClickConfirm } = props;

	const l10nContext = useContext(L10nContext);

	const device = useSelector(selectDeviceByUuid(documentScopeReference));
	const folder = useSelector(selectFolderByUuid(documentScopeReference));
	const maintenanceLogEntry = useSelector(selectMaintenanceLogEntryByUuid(documentScopeReference));


	useKeyPress('Enter', onClickConfirm);

	let middleModalMessage = '';
	let scopeName = '';
	if (documentScope === DocumentModelScope.DEVICE) {
		middleModalMessage = l10nContext.translate('view.documents.modal.action.addDocument.successMessage.middleDevice', 'wurde(n) dem Gerät');
		scopeName = device?.Name;
	} if (documentScope === DocumentModelScope.DOCUMENT_FOLDER) {
		middleModalMessage = l10nContext.translate('view.documents.modal.action.addDocument.successMessage.middleDocument', 'wurde(n) dem Ordner');
		scopeName = folder?.Name;
	} if (documentScope === DocumentModelScope.MAINTENANCE_LOG_ENTRY) {
		middleModalMessage = l10nContext.translate('view.documents.modal.action.addDocument.successMessage.middleMaintenanceLog', 'wurde(n) dem Betriebsbuch-Eintrag');
		scopeName = maintenanceLogEntry?.IncidentTitle;
	} if (documentScope === DocumentModelScope.RECORD) {
		middleModalMessage = l10nContext.translate('view.documents.modal.action.addDocument.successMessage.middleRecord', 'wurde(n) der Messung');
		scopeName = ' ';
	}


	const modalMessage = {
		first: l10nContext.translate('view.documents.modal.action.addDocument.successMessage.first', 'Dokument(e)'),
		middle: middleModalMessage,
		last: l10nContext.translate('view.documents.modal.action.addDocument.successMessage.last', 'hinzugefügt')
	};

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={modalMessage.first + ' ' + files.map(file => file.name).join(', ') + ' ' + modalMessage.middle + ' ' + scopeName + ' ' + modalMessage.last}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>

			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.addDocument.btnSuccess', 'Weiter zur Übersicht')}
						onClick={onClickConfirm}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};

