import { DeviceType } from '../../inventory/DeviceType';
import { SequenceType } from '../../inventory/SequenceType';
import { MemoryFieldSet } from '../field-set/MemoryFieldSet';
import { RecordFieldSet } from '../field-set/RecordFieldSet';
import { SequenceFieldSet } from '../field-set/SequenceFieldSet';
import { Interaction } from '../interaction/Interaction';
import { RecordInteraction } from '../interaction/RecordInteraction';
import { Action } from '../misc/Action';
import { Environment } from '../misc/Environment';
import { Mode } from '../misc/Mode';
import { Step } from '../misc/Step';
import { Record } from './Record';
import { RecordStoreData, RecordViewData } from './RecordData';
import { RecordDefinition } from './RecordDefinition';

export abstract class AbstractRecordDefinition<Context, Fields, FieldSet extends RecordFieldSet<Fields>, SequenceFields, MemoryFields>
	implements RecordDefinition
{
	private readonly deviceType: DeviceType;

	private readonly sequenceFieldSet: SequenceFieldSet<SequenceFields>;

	private readonly memoryFieldSet: MemoryFieldSet<MemoryFields>;

	public constructor(deviceType: DeviceType, sequenceFieldSet: SequenceFieldSet<SequenceFields>, memoryFieldSet: MemoryFieldSet<MemoryFields>) {
		this.deviceType = deviceType;
		this.sequenceFieldSet = sequenceFieldSet;
		this.memoryFieldSet = memoryFieldSet;
	}

	public getDeviceType(): DeviceType {
		return this.deviceType;
	}

	public abstract getSequenceType(): SequenceType;

	protected getSequenceFieldSet(): SequenceFieldSet<SequenceFields> {
		return this.sequenceFieldSet;
	}

	protected getMemoryFieldSet(): MemoryFieldSet<MemoryFields> {
		return this.memoryFieldSet;
	}

	public createRecord(mode: Mode, dateTime: Date = new Date()): Interaction<Record> {
		const fieldSet: FieldSet = this.createRecordFieldSet();
		return this.createRecordInteraction(this.toEnvironment(Action.CREATE, mode, dateTime), fieldSet);
	}

	public updateRecord(precursor: Record, mode: Mode, dateTime: Date = new Date()): Interaction<Record> {
		const fieldSet: FieldSet = this.createRecordFieldSet().fromViewData(precursor.toViewData());
		return this.createRecordInteraction(this.toEnvironment(Action.UPDATE, mode, dateTime), fieldSet);
	}

	public restoreRecordFromStoreData(recordStoreData: RecordStoreData): Record {
		const fieldSet: FieldSet = this.createRecordFieldSet().fromStoreData(recordStoreData);
		let interaction: Interaction<Record> = this.createRecordInteraction(this.toEnvironment(Action.RESTORE, Mode.ADMIN, new Date()), fieldSet);
		while (!interaction.isLast()) {
			interaction = interaction.next();
		}
		return interaction.finish();
	}

	public restoreRecordFromViewData(recordViewData: RecordViewData): Record {
		const fieldSet: FieldSet = this.createRecordFieldSet().fromViewData(recordViewData);
		return new Record(this.getDeviceType(), this.getSequenceType(), fieldSet, recordViewData.valuation);
	}

	private createRecordInteraction(environment: Environment, fieldSet: FieldSet): Interaction<Record> {
		const steps = this.getSteps().filter((step) => step.filter === undefined || step.filter(environment));
		return new RecordInteraction(environment, (e: Environment, f: Fields) => this.createRecordContext(e, f), fieldSet, steps, 0);
	}

	protected abstract getSteps(): ReadonlyArray<Step<Context, Fields>>;

	protected abstract createRecordContext(environment: Environment, recordFields: Fields): Context;

	protected abstract createRecordFieldSet(): FieldSet;

	private toEnvironment(action: Action, mode: Mode, dateTime: Date): Environment {
		return { action, mode, dateTime, deviceType: this.getDeviceType(), sequenceType: this.getSequenceType() };
	}
}
