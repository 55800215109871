import { L10nContext } from 'context/L10nContext';
import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { CyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { CyclotronProductUpdateForm } from '../cyclotron-product-update-form/CyclotronProductUpdateForm';
import { CyclotronProductUpdateFailedNotification } from './CyclotronProductUpdateFailedNotification';
import { CyclotronProductUpdateSuccessNotification } from './CyclotronProductUpdateSuccessNotification';


interface CyclotronProductUpdateModalProps {
	/**
	 * Cyclotron product
	 */
	cyclotronProduct: CyclotronProductViewModel;
	/**
	 * onDismiss
	 */
	onDismiss: () => void;
}

/**
 * The cyclotron product update modal
 */
export const CyclotronProductUpdateModal = (props: CyclotronProductUpdateModalProps): JSX.Element => {
	const { cyclotronProduct, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const cyclotronProductActionStatus = useTypedSelector(state => state.cyclotronProducts.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (cyclotronProductActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <CyclotronProductUpdateForm
					cyclotronProduct={cyclotronProduct}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modal =
					<CyclotronProductUpdateSuccessNotification
						onClickConfirm={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<CyclotronProductUpdateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;

	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.cyclotronProducts.modal.action.update.caption', 'Radionuklid bearbeiten')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
