import { L10nContext } from 'context/L10nContext';

import { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
	countDueSequences,
	countNoticeableOrUnacceptableSequences,
	countOngoingMaintenanceLogEntries,
} from 'services/device/domain/business/util/DeviceUtil';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { selectSequencesByDevice } from 'services/device/store/sequenceSlice';
import { selectMaintenanceLogEntriesByDevice } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { Badge } from '../../badge/Badge';

import './card-item-device-state.scss';

export interface CardItemDeviceStateProps {
	device: DeviceViewModel;
}

/**
 * A card item device state component
 */
export const CardItemDeviceState = (props: CardItemDeviceStateProps): JSX.Element | null => {
	const { device } = props;

	const l10nContext = useContext(L10nContext);

	// Read the sequences from the state store
	const sequences = useSelector(selectSequencesByDevice(device));
	const deviceSequenceInfo = {
		dueSequencesCount: countDueSequences(sequences),
		noticeableSequencesCount: countNoticeableOrUnacceptableSequences(sequences)
	};

	// Read the sequences from the state store
	const maintenanceLogEntries = useSelector(selectMaintenanceLogEntriesByDevice(device));
	const deviceMaintenanceLogEntryCount = countOngoingMaintenanceLogEntries(maintenanceLogEntries);

	const renderEntry = (labelKey: string, count: number ): JSX.Element | null => {
		const countDisplay = count > 0 ? l10nContext.formatNumber(count, 0) : l10nContext.formatNumber(0, 0);

		if (count === 0) {
			labelKey += '.none';
		} else if (count === 1) {
			labelKey += '.one';
		} else {
			labelKey += '.some';
		}
		const label = l10nContext.translate(labelKey);

		return (
			<li
				key={labelKey}
				className="card-item-device-state__entry"
			>
				<Badge
					label={countDisplay}
				/>
				<p className="state-entry__value">
					<span className="state-entry__value__label">{label}</span>
				</p>
			</li>
		);
	};

	const renderOptionalEntry = (labelKey: string, count: number): JSX.Element => {
		if (count === null || count === 0) {
			return null;
		}
		return renderEntry(labelKey, count);
	};

	return (
		<div className="card-item-device-state">
			<ul className="card-item-device-state__entries-list">
				{renderEntry('devices.sequence.state.dueCountLabel', deviceSequenceInfo?.dueSequencesCount)}
				{renderEntry('devices.sequence.state.unacceptableCountLabel', deviceSequenceInfo?.noticeableSequencesCount)}
				{renderOptionalEntry('devices.sequence.state.ongoingMaintenanceLogEntryCountLabel', deviceMaintenanceLogEntryCount)}
			</ul>
		</div>
	);
};
