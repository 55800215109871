import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	MembershipModelConverter as ModelConverter,
	MembershipPersistenceModel as PersistenceModel,
	MembershipStoreModel as StoreModel,
	MembershipViewModel as ViewModel,
	CreatableMembershipViewModel as CreatableViewModel
} from 'services/core/domain/model/MembershipModel';

export class MembershipHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'core/api/v1';
	protected collectionControllerUri = 'memberships';
	protected entityControllerUri = 'membership';

	protected responseCollectionKey = 'MembershipModelCollection';
	protected responseEntityKey = 'MembershipModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
