import { Valuation } from 'services/device/domain/business/common/Valuation';
import { getSequenceDue, getSequenceValuation } from 'services/device/domain/business/util/SequenceUtil';
import { DeviceUuid, DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';

export const countDevicesWithOpenMaintenanceLogEntries = (
	devices: ReadonlyArray<DeviceViewModel>,
	maintenanceLogEntryMap?: ReadonlyMap<DeviceUuid, Array<MaintenanceLogEntryViewModel>>
): number => {
	return (getDeviceUuidsWithOpenMaintenanceLogEntries(devices, maintenanceLogEntryMap)).length;
};

export const countDueDevices = (
	devices: ReadonlyArray<DeviceViewModel>,
	sequencesMap: ReadonlyMap<DeviceUuid, Array<SequenceViewModel>>
): number => {
	return (getDueDeviceUuids(devices, sequencesMap)).length;
};

export const countNoticableDevices = (
	devices: ReadonlyArray<DeviceViewModel>,
	sequencesMap: ReadonlyMap<DeviceUuid, Array<SequenceViewModel>>
): number => {
	return (getNoticableDeviceUuids(devices, sequencesMap)).length;
};

export const countCombinedNoticableDevices = (
	devices: ReadonlyArray<DeviceViewModel>,
	sequencesMap: ReadonlyMap<DeviceUuid, Array<SequenceViewModel>>,
	maintenanceLogEntryMap?: ReadonlyMap<DeviceUuid, Array<MaintenanceLogEntryViewModel>>
): number => {
	let maintenanceLogEntryDeviceUuids: Array<DeviceUuid> = [];
	if ((maintenanceLogEntryMap ?? null) !== null) {
		maintenanceLogEntryDeviceUuids = getDeviceUuidsWithOpenMaintenanceLogEntries(devices, maintenanceLogEntryMap);
	}
	const dueDeviceUuids = getDueDeviceUuids(devices, sequencesMap);
	const noticableDeviceUuids = getNoticableDeviceUuids(devices, sequencesMap);

	const combinedDeviceUuids = [
		...maintenanceLogEntryDeviceUuids,
		...dueDeviceUuids,
		...noticableDeviceUuids
	].filter((deviceUuid, index, deviceUuids) => {
		return deviceUuids.indexOf(deviceUuid) === index;
	});

	return combinedDeviceUuids.length;
};

const getDeviceUuidsWithOpenMaintenanceLogEntries = (
	devices: ReadonlyArray<DeviceViewModel>,
	maintenanceLogEntryMap?: ReadonlyMap<DeviceUuid, Array<MaintenanceLogEntryViewModel>>
): Array<DeviceUuid> => {
	maintenanceLogEntryMap = maintenanceLogEntryMap ?? null;
	const countableDevices: Array<DeviceUuid> = [];
	for (const device of devices) {
		if (
			maintenanceLogEntryMap !== null
			&& !countableDevices.includes(device.Uuid)
			&& maintenanceLogEntryMap.has(device.Uuid)
		) {
			countableDevices.push(device.Uuid);
		}
	}
	return countableDevices;
};

const getDueDeviceUuids = (
	devices: ReadonlyArray<DeviceViewModel>,
	sequencesMap: ReadonlyMap<DeviceUuid, Array<SequenceViewModel>>
): Array<DeviceUuid> => {
	const countableDevices: Array<DeviceUuid> = [];
	for (const device of devices) {
		if (sequencesMap.has(device.Uuid)) {
			for (const sequence of sequencesMap.get(device.Uuid)) {
				// eslint-disable-next-line no-await-in-loop
				const due = getSequenceDue(sequence);
				if (
					!countableDevices.includes(device.Uuid)
					&& due
				) {
					countableDevices.push(device.Uuid);
					break;
				}
			}
		}
	}
	return countableDevices;
};

const getNoticableDeviceUuids = (
	devices: ReadonlyArray<DeviceViewModel>,
	sequencesMap: ReadonlyMap<DeviceUuid, Array<SequenceViewModel>>
): Array<DeviceUuid> => {
	const countableDevices: Array<DeviceUuid> = [];
	for (const device of devices) {
		if (sequencesMap.has(device.Uuid)) {
			for (const sequence of sequencesMap.get(device.Uuid)) {
				// eslint-disable-next-line no-await-in-loop
				const valuation = getSequenceValuation(sequence);
				if (
					!countableDevices.includes(device.Uuid)
					&& (valuation === Valuation.NOTICEABLE || valuation === Valuation.UNACCEPTABLE)) {
					countableDevices.push(device.Uuid);
					break;
				}
			}
		}
	}
	return countableDevices;
};

