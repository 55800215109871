import { MouseEvent, useContext } from 'react';

import { Icon, IconColor } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';
import { Illustration } from 'presentation/ui/partials/illustration/Illustration';
import { ExpandableSlimContext } from '../expandable-slim-context/ExpandableSlimContext';

export interface ExpandableSlimHeaderProps {
	caption: string,
	subtitle?: string,
	children?: any,
	isSlimMode?: boolean,
	illustration?: IllustrationIdentifier,
	showExpandIcon?: boolean
}

export const ExpandableSlimHeader = (props: ExpandableSlimHeaderProps): JSX.Element => {

	const expandableSlimContext = useContext(ExpandableSlimContext);

	const handleToggle = (event: MouseEvent): void => {
		event.stopPropagation();
		expandableSlimContext.setExpanded(!expandableSlimContext.expanded);
	};
	const renderIllustration = props.illustration ?
		<Illustration name={props.illustration} /> :
		null;

	const renderSubTitle = props.subtitle ?
		<p className='card-item-header__subtitle'>
			{props.subtitle}
		</p> :
		null;

	return (
		<header className='expandable-slim__header'>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
			<section role='dialog' className='expandable-slim__header__caption' onClick={handleToggle}>
				{renderIllustration}
				{ props.isSlimMode ? <Icon name={IconIdentifier.EXPAND} color={IconColor.PRIMARY_50} className='expandable-slim__header__caption__icon' /> : null }
				<article className='expandable-slim__header__caption__text-wrapper'>
					<h1 className='expandable-slim__header__caption__text-wrapper__title'>{props.caption}</h1>
					<span className='expandable-slim__header__caption__text-wrapper__suptitle'>{renderSubTitle}</span>
				</article>
			</section>
			<section className='expandable-slim__header__actions'>
				{ expandableSlimContext.expanded === true ? props.children : null }
			</section>
		</header>
	);
};
