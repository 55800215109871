import React, { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutHeader } from 'presentation/ui/layouts/form-layout/form-layout-header/FormLayoutHeader';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutModifier } from 'presentation/ui/layouts/form-layout/FormLayoutModifier';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { CheckboxesLayout } from '../../../../../../presentation/ui/layouts/checkboxes-layout/CheckboxesLayout';
import { Checkbox } from '../../../../../../presentation/ui/partials/input/checkbox/Checkbox';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';

export interface ContainerReleaseFormStep2Props {
	container: ContainerViewModel;
	/**
	 * Button back action
	 */
	onClickPrevious: () => void;
	/**
	 * Button confirm
	 */
	onClickNext?: () => void;
}

export const ContainerReleaseFormStep2 = (props: ContainerReleaseFormStep2Props): JSX.Element => {
	const {
		container,
		onClickPrevious,
		onClickNext
	} = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			modifier={FormLayoutModifier.BLUE_BACKGROUND}
			onSubmit={onClickNext}
		>
			<FormLayoutHeader
				text={l10nContext.translate('common.modal.checkDetails', 'Überprüfen Sie bitte Ihre Angaben')}
				icon={IconIdentifier.WARNING}
			/>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('containers.checkboxLabelName', 'Gebinde freigeben?')}*`}
					thin
				>
					<CheckboxesLayout>
						<Checkbox
							key={1}
							labelName={container?.IsReleasedByRadiationProtectionOfficer === true ?
								l10nContext.translate('common.inputs.boolean.true', 'Ja') :
								l10nContext.translate('common.inputs.boolean.false', 'Nein')}
							disabled
							isSummary
						/>
					</CheckboxesLayout>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.back', 'Zurück')}
							onClick={onClickPrevious}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('view.containers.modal.action.release.caption', 'Gebinde freigeben')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
