/* eslint-disable max-classes-per-file */
import { Optional } from '../common/misc/Optional';
import { Interval } from './Interval';

export class IntervalDefinition {

	private readonly interval: Interval;

	private readonly labelKey: string;

	constructor(interval: Interval, labelKey: string) {
		this.interval = interval;
		this.labelKey = labelKey;
	}

	public getInterval(): Interval {
		return this.interval;
	}

	public getLabelKey(): string {
		return this.labelKey;
	}

	public toString = (): string => {
		return this.interval.toString();
	}

}

export const NONE: IntervalDefinition = new IntervalDefinition(
	Interval.NONE,
	'inventory.interval.none'
);

export const DAILY: IntervalDefinition = new IntervalDefinition(
	Interval.DAILY,
	'inventory.interval.daily'
);

export const WEEKLY: IntervalDefinition = new IntervalDefinition(
	Interval.WEEKLY,
	'inventory.interval.weekly'
);

export const TWO_WEEKLY: IntervalDefinition = new IntervalDefinition(
	Interval.TWO_WEEKLY,
	'inventory.interval.twoWeekly'
);

export const MONTHLY: IntervalDefinition = new IntervalDefinition(
	Interval.MONTHLY,
	'inventory.interval.monthly'
);

export const QUARTER_YEARLY: IntervalDefinition = new IntervalDefinition(
	Interval.QUARTER_YEARLY,
	'inventory.interval.quarterYearly'
);

export const HALF_YEARLY: IntervalDefinition = new IntervalDefinition(
	Interval.HALF_YEARLY,
	'inventory.interval.halfYearly'
);

export const YEARLY: IntervalDefinition = new IntervalDefinition(
	Interval.YEARLY,
	'inventory.interval.yearly'
);

export const LATCHED_MONTHLY: IntervalDefinition = new IntervalDefinition(
	Interval.LATCHED_MONTHLY,
	'inventory.interval.latchedMonthly'
);

export const LATCHED_QUARTER_YEARLY: IntervalDefinition = new IntervalDefinition(
	Interval.LATCHED_QUARTER_YEARLY,
	'inventory.interval.latchedQuarterYearly'
);

export const LATCHED_HALF_YEARLY: IntervalDefinition = new IntervalDefinition(
	Interval.LATCHED_HALF_YEARLY,
	'inventory.interval.latchedHalfYearly'
);

export const LATCHED_YEARLY: IntervalDefinition = new IntervalDefinition(
	Interval.LATCHED_YEARLY,
	'inventory.interval.latchedYearly'
);

export function getIntervalDefinition(interval: Interval): IntervalDefinition {
	const intervalDefinition: Optional<IntervalDefinition> = getAllIntervalDefinitions().find((definition) => definition.getInterval() === interval) ?? null;
	if (intervalDefinition === null) {
		throw new Error('Missing interval definition: ' + interval);
	}
	return intervalDefinition;
}

export function getIntervalDefinitions(intervals: ReadonlyArray<Interval>): ReadonlyArray<IntervalDefinition> {
	return intervals.map((interval) => getIntervalDefinition(interval));
}

export function getAllIntervalDefinitions(): ReadonlyArray<IntervalDefinition> {
	return [
		NONE,
		DAILY,
		WEEKLY,
		TWO_WEEKLY,
		MONTHLY,
		QUARTER_YEARLY,
		HALF_YEARLY,
		YEARLY,
		LATCHED_MONTHLY,
		LATCHED_QUARTER_YEARLY,
		LATCHED_HALF_YEARLY,
		LATCHED_YEARLY
	];
}

export function getAllIntervals(): ReadonlyArray<Interval> {
	return [
		Interval.NONE,
		Interval.DAILY,
		Interval.WEEKLY,
		Interval.TWO_WEEKLY,
		Interval.MONTHLY,
		Interval.QUARTER_YEARLY,
		Interval.HALF_YEARLY,
		Interval.YEARLY,
		Interval.LATCHED_MONTHLY,
		Interval.LATCHED_QUARTER_YEARLY,
		Interval.LATCHED_HALF_YEARLY,
		Interval.LATCHED_YEARLY
	];
}