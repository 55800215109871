/* eslint-disable max-classes-per-file */
import { Input } from '../Input';
import { Message } from '../message/Message';
import { Output } from '../Output';

export interface WidgetSelection<Result> {
	onInputWidget(widget: InputWidget): Result;

	onMessageWidget(widget: MessageWidget): Result;

	onOutputWidget(widget: OutputWidget): Result;

	onSummaryWidget(widget: SummaryWidget): Result;
}

export interface Widget {
	selectWidget<Result>(selection: WidgetSelection<Result>): Result;
}

export class InputWidget implements Widget {
	private readonly inputs: ReadonlyArray<Input<any>>;

	constructor(inputs: ReadonlyArray<Input<any>>) {
		this.inputs = inputs;
	}

	public getInputs(): ReadonlyArray<Input<any>> {
		return this.inputs;
	}

	public selectWidget<Result>(selection: WidgetSelection<Result>): Result {
		return selection.onInputWidget(this);
	}
}

export class MessageWidget implements Widget {
	private readonly messages: ReadonlyArray<Message>;

	constructor(messages: ReadonlyArray<Message>) {
		this.messages = messages;
	}

	public getMessages(): ReadonlyArray<Message> {
		return this.messages;
	}

	public selectWidget<Result>(selection: WidgetSelection<Result>): Result {
		return selection.onMessageWidget(this);
	}
}

export class OutputWidget implements Widget {
	private readonly outputs: ReadonlyArray<Output<any>>;

	constructor(outputs: ReadonlyArray<Output<any>>) {
		this.outputs = outputs;
	}

	public getOutputs(): ReadonlyArray<Output<any>> {
		return this.outputs;
	}

	public selectWidget<Result>(selection: WidgetSelection<Result>): Result {
		return selection.onOutputWidget(this);
	}
}

export class SummaryWidget implements Widget {
	private readonly outputs: ReadonlyArray<Output<any>>;

	constructor(outputs: ReadonlyArray<Output<any>>) {
		this.outputs = outputs;
	}

	public getOutputs(): ReadonlyArray<Output<any>> {
		return this.outputs;
	}

	public selectWidget<Result>(selection: WidgetSelection<Result>): Result {
		return selection.onSummaryWidget(this);
	}
}
