import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';
import { NumberOutput } from 'services/device/domain/business/common/Output';

export interface NumberOutputValueProps {
	output: NumberOutput;
}

export const NumberOutputValueRenderer = (props: NumberOutputValueProps): JSX.Element => {
	const { output } = props;

	const l10nContext = useContext(L10nContext);

	return (
		<span>
			{l10nContext.formatNumber(output.getSingle(), output.getNumberOfDecimals())}
		</span>
	);

};
