import { L10nContext } from 'context/L10nContext';
import { Badge, BadgeColor } from 'presentation/ui/partials/badge/Badge';
import { useEffect, useRef, useState, MouseEvent, useContext } from 'react';

import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './button-toggle.scss';

export interface ButtonToggleProps {
	/**
	 * Button text
	 */
	buttonText: string;
	/**
	 * Icon
	 */
	icon?: IconIdentifier;
	/**
	 * Id
	 */
	id?: string;
	/**
	 * Active state
	 */
	isActive?: boolean;
	/**
	 * Disabled state
	 */
	disabled?: boolean;
	/**
	 * onClick-handler
	 */
	onClick?: (id: string, state: boolean) => void;
	/**
	 * Badge
	 */
	badge?: number;
}

export const ButtonToggle = (props: ButtonToggleProps): JSX.Element => {
	const { buttonText, icon, id = buttonText, isActive, disabled = false, onClick, badge = null } = props;

	const l10nContext = useContext(L10nContext);

	const [activeState, setActiveState] = useState<boolean>();
	const userAction = useRef(false);

	useEffect(() => {
		setActiveState(isActive);
	}, [isActive]);

	useEffect(() => {
		if (userAction.current && onClick) {
			userAction.current = false;

			onClick(id, activeState);
		}
	}, [onClick, activeState, id]);

	const onClickHandler = (event: MouseEvent) => {
		event.stopPropagation();
		event.preventDefault();

		userAction.current = true;

		setActiveState(!activeState);
	};

	const badgeDisplay = badge !== null ?
		<Badge
			label={l10nContext.formatNumber(badge, 0)}
			color={BadgeColor.PRIMARY}
		/>
		:
		null;

	return (
		icon ? 
			<div className="button-toggle">
				<button
					className={`button-toggle__button ${activeState ? 'button-toggle__button--active ' : ''}${icon}`}
					type="button"
					onClick={(event) => onClickHandler(event)}
					disabled={disabled}
				>
					<Icon name={icon} />
					<span className="button-toggle__text">{buttonText}</span>
				</button>
			</div> :
			<div className="button-toggle">
				<button
					className={`button-toggle__button ${activeState ? 'button-toggle__button--active ' : ''}${icon}`}
					type="button"
					onClick={(event) => onClickHandler(event)}
					disabled={disabled}
				>
					{badgeDisplay}
					<span className="button-toggle__text">{buttonText}</span>
				</button>
			</div>
	);
};
