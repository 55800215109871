import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	FolderModelConverter as ModelConverter,
	FolderPersistenceModel as PersistenceModel,
	FolderStoreModel as StoreModel,
	FolderViewModel as ViewModel,
	CreatableFolderViewModel as CreatableViewModel
} from 'services/documents/domain/model/FolderModel';

export class FolderHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'document/api/v1';
	protected collectionControllerUri = 'document-folders';
	protected entityControllerUri = 'document-folder';

	protected responseCollectionKey = 'DocumentFolderModelCollection';
	protected responseEntityKey = 'DocumentFolderModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
