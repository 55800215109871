import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Route } from 'router/Route';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { deleteMaintenanceLogEntry } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogDeleteForm } from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-delete-form/MaintenanceLogDeleteForm';
import { MaintenanceLogDeleteModalFailed } from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-delete-modal/MaintenanceLogDeleteModalFailed';

export interface MaintenanceLogDeleteModalProps {
	maintenanceLogEntry: MaintenanceLogEntryViewModel
	onDismiss?: () => void;
}

export const MaintenanceLogDeleteModal = (props: MaintenanceLogDeleteModalProps): JSX.Element => {
	const { maintenanceLogEntry, onDismiss } = props;

	const maintenenceLogEntryActionStatus = useTypedSelector(state => state.maintenanceLogEntries.actionStatus);

	const history = useHistory();
	const dispatch = useDispatch();

	const handleDelete = (): void => {
		dispatch(deleteMaintenanceLogEntry(maintenanceLogEntry));
		history.push(Route.MAINTENANCE_LOG);
	};

	const renderModalContent = () => {
		switch (maintenenceLogEntryActionStatus) {
			case AsyncReducerStatus.IDLE:
				return <MaintenanceLogDeleteForm
					logEntry={maintenanceLogEntry}
					onDelete={handleDelete}
					onDismiss={onDismiss}
				/>;

			case AsyncReducerStatus.FAILED:
				return <MaintenanceLogDeleteModalFailed
					onDismiss={onDismiss}
				/>;
			default: return null;
		}
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
