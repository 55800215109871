import React, { useContext, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { FilterChipSet } from 'presentation/ui/components/filter/filter-chip-set/FilterChipSet';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';

export interface MaintenanceLogFilterProps {
	toggleState: string,
	onClickOngoing: (state: boolean) => void,
	onClickSolved: (state: boolean) => void,
	onClickActive: (state: boolean) => void,
	onClickArchived: (state: boolean) => void
}

export const MaintenanceLogFilter = (props: MaintenanceLogFilterProps): JSX.Element => {
	const { toggleState, onClickOngoing, onClickSolved, onClickActive, onClickArchived } = props;

	const l10nContext = useContext(L10nContext);

	const [filterOngoing, setFilterOngoing] = useState<boolean>(true);
	const [filterSolved, setFilterSolved] = useState<boolean>(false);
	const [filterActive, setFilterActive] = useState<boolean>(true);
	const [filterArchived, setFilterArchived] = useState<boolean>(false);

	const renderDevicesFilterChipSet = toggleState === '2' &&
		(
			<FilterChipSet
				label={l10nContext.translate('view.maintenances.filter2.headline', 'Gerätestatus')}
				chips={[
					{
						label: l10nContext.translate('common.filter.active', 'aktiv'),
						active: filterActive,
						onClick: (id, state) => {
							setFilterActive(state);
							onClickActive(state);
						}
					},
					{
						label: l10nContext.translate('common.filter.archived', 'archiviert'),
						active: filterArchived,
						onClick: (id, state) => {
							setFilterArchived(state);
							onClickArchived(state);
						}
					},
				]}
			/>
		);

	return (
		<>
			<ColumnLayout>
				<FilterChipSet
					label={l10nContext.translate('view.maintenances.filter1.headline', 'Eintragsstatus')}
					chips={[
						{
							label: l10nContext.translate('common.filter.open', 'offen'),
							active: filterOngoing,
							onClick: (_, state) => {
								setFilterOngoing(state);
								onClickOngoing(state);
							}
						},
						{
							label: l10nContext.translate('common.filter.done', 'abgeschlossen'),
							active: filterSolved,
							onClick: (id, state) => {
								setFilterSolved(state);
								onClickSolved(state);
							}
						},
					]}
				/>
				{renderDevicesFilterChipSet}
			</ColumnLayout>
		</>
	);
};
