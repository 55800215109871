import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';

export interface MeasurementGoalCreateSuccessNotificationProps {
	onClickConfirm: () => void;
	sequenceCreated?: SequenceViewModel | null;
}

export const MeasurementGoalCreateSuccessNotification = (props: MeasurementGoalCreateSuccessNotificationProps): JSX.Element => {
	const { onClickConfirm, sequenceCreated } = props;

	const l10nContext = useContext(L10nContext);
	const history = useHistory();

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.clearances.modal.sequence.create.successMessage', 'Mitarbeiter/Messziel wurde erfolgreich angelegt')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.clearances.modal.sequence.create.btnDetails', 'Weiter zu den Mitarbeiter-/Messzieldetails')}
						onClick={() => {
							history.push(Route.CLEARANCES + '/' + sequenceCreated.Device + Route.MEASUREMENT_GOALS + '/' + sequenceCreated.Uuid);
						}}
					/>
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.clearances.modal.sequence.create.btnSuccess', 'Weiter zur Mitarbeiter/Messziel Detailübersicht')}
						onClick={onClickConfirm}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
