/* eslint-disable max-classes-per-file */

export enum DeviceType {

	ACTIVIMETER = 'ACTIVIMETER',

	PROBE_TESTERS = 'PROBE_TESTERS',

	SURGICAL_PROBE = 'SURGICAL_PROBE',

	CONTAMINATION_MONITOR = 'CONTAMINATION_MONITOR',

	GAMMA_CAMERA_PLANAR = 'GAMMA_CAMERA_PLANAR',

	GAMMA_CAMERA_FOR_SPECT = 'GAMMA_CAMERA_FOR_SPECT',

	RADIOCHEMICAL_PURITY = 'RADIOCHEMICAL_PURITY',

	PET_CT_PHILIPS_VEREOS = 'PET_CT_PHILIPS_VEREOS',

	PET_CT_PHILIPS_GEMINI = 'PET_CT_PHILIPS_GEMINI',

	PET_CT_SIEMENS_GENERIC = 'PET_CT_SIEMENS_GENERIC',

	SPECT_CT = 'SPECT_CT',

	CT = 'CT',

	MRT = 'MRT',

	X_RAY_IMAGE_PLATE = 'X_RAY_IMAGE_PLATE',

	X_RAY_DIGITAL = 'X_RAY_DIGITAL',

	PICTURE_REPRODUCTION = 'PICTURE_REPRODUCTION',

	GENERIC = 'GENERIC',

	CLEARANCE = 'CLEARANCE'

}