import './topbar-layout-section.scss';

export interface TopbarLayoutSectionProps extends React.ComponentPropsWithoutRef<'section'> {
	fluid?: boolean
}

export const TopbarLayoutSection = (props: TopbarLayoutSectionProps): JSX.Element => {
	const { fluid = false } = props;

	const fluidStyle = !fluid ?
		'' :
		' topbar-layout-section--fluid';

	return (
		<section className={`topbar-layout-section${fluidStyle}`}>
			{props.children}
		</section>
	);
};
