import { createContext } from 'react';

import { ClientModel } from 'services/core/lib/auth/AuthService';
import { ProductGroup } from 'services/device/domain/business/inventory/ProductGroup';

interface ClientContextValue {
	selectedClientUuid: string;

	select(clientUuid: string): void;

	deselect(): void;

	selectedClient(): ClientModel | null;

	availableClient(clientUuid: string): ClientModel | null;

	availableClients(): Array<ClientModel>;

	permittedProductGroups(): Array<ProductGroup>;
}

export const ClientContext = createContext<ClientContextValue>({
	selectedClientUuid: null,

	select: (): void => {
	},

	deselect: (): void => {
	},

	selectedClient: (): ClientModel | null => {
		return null;
	},

	availableClient: (): ClientModel | null => {
		return null;
	},

	availableClients: (): Array<ClientModel> => {
		return [];
	},

	permittedProductGroups(): Array<ProductGroup> {
		return [];
	}
});
