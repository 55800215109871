import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { fetchMemberships, resetActionStatus, selectFilteredMemberships } from 'services/core/store/membershipSlice';

import { Guid } from 'lib/guid/Guid';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { MembershipViewModel } from 'services/core/domain/model/MembershipModel';
import { Permission, permissionGroupMembership } from 'services/core/lib/auth/AuthService';

import { CardMembership } from 'presentation/ui/components/cards/card-membership/CardMembership';
import { FilterChipSet } from 'presentation/ui/components/filter/filter-chip-set/FilterChipSet';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { SiteTitle } from 'presentation/ui/components/site-title/SiteTitle';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { CardItemMembershipAttributes } from 'presentation/ui/partials/card/card-item-attributes/card-item-membership-attributes/CardItemMembershipAttributes';
import { CardItemMembershipControls } from 'presentation/ui/partials/card/card-item-controls/card-item-membership-control/CardItemMembershipControls';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import { MembershipCreateModal } from 'services/core/presentation/ui/membership-action/membership-create-modal/MembershipCreateModal';
import { MembershipDeleteModal } from 'services/core/presentation/ui/membership-action/membership-delete-modal/MembershipDeleteModal';
import { MembershipUpdateModal } from 'services/core/presentation/ui/membership-action/membership-update-modal/MembershipUpdateModal';
import { MembershipViewModalType } from 'services/core/presentation/view/MembershipViewModalType';


export enum CardItemMembershipControlType {
	EDIT = 'CHANGE',
	DELETE = 'DELETE'
}

/**
 * The actors view
 */
export const MembershipView = (): JSX.Element => {

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Use a custom selector function to provide type information for the state defined in the slice
	const storeFetchStatus = useTypedSelector(state => state.memberships.fetchStatus);
	const storeActionStatus = useTypedSelector(state => state.memberships.actionStatus);
	const storeLastActionError = useTypedSelector(state => state.memberships.lastActionError);

	// Local state
	const [membershipsIncludesActive, setMembershipsIncludesActive] = useState<boolean>(true);
	const [membershipsIncludesInactive, setMembershipsIncludesInactive] = useState<boolean>(false);

	const [modalType, setModalType] = useState<MembershipViewModalType>(null);
	const [modalPayload, setModalPayload] = useState<MembershipViewModel>(null);

	// Read the memberships from the state store
	const memberships = useSelector(
		selectFilteredMemberships(
			membershipsIncludesActive,
			membershipsIncludesInactive
		)
	);

	useEffect(() => {
		if (storeFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchMemberships({
				clientUuid: clientContext.selectedClientUuid,
				facilityUuid: facilityContext.selectedFacilityUuid
			}));
		}
	});

	if (storeFetchStatus === AsyncFetchStatus.PENDING || storeFetchStatus === AsyncFetchStatus.INITIAL_PENDIG) {
		return (
			<LoadingSpinner />
		);
	}

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permissionCreate: authContext.hasPermission(Permission.MEMBERSHIP_CREATE),
		permissionUpdate: authContext.hasPermission(Permission.MEMBERSHIP_UPDATE),
		permissionDelete: authContext.hasPermission(Permission.MEMBERSHIP_DELETE)
	};

	// Handle permissions
	if (!authContext.hasAnyPermission(permissionGroupMembership)) {
		throw new Error('Permission denied');
	}

	const handleModalDismiss = () => {
		setModalType(null);
		setModalPayload(null);
	};

	const renderNotifications = (): Array<JSX.Element> => {

		const notifications = [];
		// Handle failed action
		if (storeActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar key={Guid.generate()} message={storeLastActionError.message} level={NotificationLevel.LEVEL_ERROR} />);
		}
		return notifications;
	};

	if (modalType !== null && storeActionStatus === AsyncReducerStatus.DELETED) {
		handleModalDismiss();
	}

	const modal = (): JSX.Element => {
		let membershipModal: JSX.Element = null;

		switch (modalType) {
			case MembershipViewModalType.CREATE:
				membershipModal =
					<MembershipCreateModal
						onDismiss={handleModalDismiss}
					/>;
				break;

			case MembershipViewModalType.DELETE:
				membershipModal =
					<MembershipDeleteModal
						onDismiss={handleModalDismiss}
						membership={modalPayload as MembershipViewModel}
					/>;
				break;

			case MembershipViewModalType.EDIT:
				membershipModal =
					<MembershipUpdateModal
						onDismiss={handleModalDismiss}
						membership={modalPayload as MembershipViewModel}
					/>;
				break;
		}

		return membershipModal;
	};

	const handleModalTriggerClick = (type: MembershipViewModalType, payload?: MembershipViewModel) => {
		dispatch(resetActionStatus());

		setModalType(type);
		setModalPayload(payload);
	};

	const handleCardControlsClick = (payload: CardItemControlActionType, membership: MembershipViewModel) => {
		switch (payload.type) {
			case CardItemMembershipControlType.EDIT:
				handleModalTriggerClick(MembershipViewModalType.EDIT, membership);
				break;

			case CardItemMembershipControlType.DELETE:
				handleModalTriggerClick(MembershipViewModalType.DELETE, membership);
				break;
		}
	};

	const renderActors = (): Array<JSX.Element> => {
		return memberships.map<JSX.Element>((membership) => {
			const membershipDeletable = user.permissionDelete && membership.Actor.Uuid !== authContext.getActor().Uuid;
			const membershipEditable = user.permissionUpdate && membership.Actor.Uuid !== authContext.getActor().Uuid;
			return (
				<CardMembership
					key={membership.Uuid}
					cardId={Guid.generate()}
				>
					<CardItemHeader
						title={membership.Actor.MailAddress ?? membership.Actor.Username}
					/>
					<CardItemMembershipControls
						cardId={Guid.generate()}
						actionDelete={membershipDeletable ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
						actionChangeMembership={membershipEditable ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
						onClick={(payload) => {
							handleCardControlsClick(payload, membership);
						}}
					/>
					<CardItemMembershipAttributes
						email={membership.Actor.MailAddress}
						userName={membership.Actor.Username}
						realName={membership.Actor.Realname}
						roles={membership.Roles}
					/>
				</CardMembership>
			);
		});
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs />
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>
					<ViewLayoutSection>
						<SiteTitle
							title={l10nContext.translate('view.memberships.title', 'Benutzer')}
							button={{
								buttonText: l10nContext.translate('view.memberships.modal.action.add.caption', 'Zugriff gewähren'),
								disabled: !user.permissionCreate,
								icon: IconIdentifier.ADD,
								onClick: () => handleModalTriggerClick(MembershipViewModalType.CREATE)
							}}
						/>
					</ViewLayoutSection>

					<CardCollectionLayout>
						<FilterChipSet
							label={l10nContext.translate('common.filter.status', 'Status')}
							chips={[
								{
									label: l10nContext.translate('common.filter.active', 'aktiv'),
									active: membershipsIncludesActive,
									onClick: (_id, state) => {
										setMembershipsIncludesActive(state);
									}
								},
								{
									label: l10nContext.translate('common.filter.inactive', 'inaktiv'),
									active: membershipsIncludesInactive,
									onClick: (_id, state) => {
										setMembershipsIncludesInactive(state);
									}
								},
							]}
						/>
						{modal()}
						{renderActors()}
					</CardCollectionLayout>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>

		</MainLayout>
	);
};
