import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import {
	ModalDialogueCaptionModifier,
	ModalDialogueHeader
} from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogueSectionName } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSectionName';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { CyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';

export interface CyclotronProductToActiveStateFormProps {
	/**
	 * Cyclotron product
	 */
	cyclotronProduct: CyclotronProductViewModel;
	/**
	 * Handle update
	 */
	onToActiveState: () => void;
	onDismiss: () => void;
}

export const CyclotronProductToActiveStateForm = (props: CyclotronProductToActiveStateFormProps): JSX.Element => {
	const { cyclotronProduct, onToActiveState, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	const deliveryDatetimeFormatted = l10nContext.formatDate(cyclotronProduct.DeliveryDate);

	const modalMessage = {
		first: l10nContext.translate('view.cyclotronProducts.modal.action.toActiveState.message.first', 'Wollen Sie das Radionuklid vom '),
		last: l10nContext.translate('view.cyclotronProducts.modal.action.toActiveState.message.last', 'wirklich in den aktiven Status setzen? Es befindet sich dann nicht mehr im Abfall-Modul')
	};

	return (
		<>
			<ModalDialogueHeader
				captionText={l10nContext.translate('common.modal.captionAttention', 'Achtung')}
				captionIcon={IconIdentifier.ALERT}
				captionModifier={ModalDialogueCaptionModifier.SMALL_CAPTION}
			/>
			<ModalDialogueSection>
				<>
					{modalMessage.first} <b>{deliveryDatetimeFormatted}</b> {modalMessage.last}
				</>
			</ModalDialogueSection>

			<ModalDialogueSection section={ModalDialogueSectionName.SECTION_FOOTER}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.abort', 'Abbrechen')}
							onClick={onDismiss}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonSecondary
							buttonText={l10nContext.translate('view.cyclotronProducts.modal.action.toActiveState.setActiveConfirm', 'Ja, in den aktiven Status setzen')}
							status={ButtonSecondaryStatus.ATTENTION}
							icon={IconIdentifier.DECAY}
							onClick={onToActiveState}
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</ModalDialogueSection>
		</>
	);
};
