import { FunctionComponent } from 'react';

import './card-device-detail.scss';

export interface CardDeviceDetailProps {
	/**
	 * (Optional) Archived state
	 */
	archived?: boolean;
}

/**
 * The card device detail card component
 */
export const CardDeviceDetail: FunctionComponent<CardDeviceDetailProps> = (props): JSX.Element => {
	const { archived, children } = props;

	const componentStyle = `card-device-detail${archived ? ' card-device-detail--archived' : ''}`;

	return (
		<div className={componentStyle}>
			<div className='card-device-detail__content'>
				{children}
			</div>
		</div>
	);
};
