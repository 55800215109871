import { useEffect, useRef, useState } from 'react';

import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import { Link } from 'react-router-dom';

import './navigation-element-primary.scss';

export interface NavigationElementPrimaryProps {
	/**
	 * Icon
	 */
	icon: IconIdentifier;
	/**
	 * Label
	 */
	label: string;
	/**
	 * (Optional) Active state
	 */
	isActive?: boolean;
	/**
	 * (Optional) Id
	 */
	id?: string;
	/**
	 * (Optional) Open state
	 */
	isOpen?: boolean;
	/**
	 * (Optional) Disabled state
	 */
	isDisabled?: boolean;
	/**
	 * Link
	 */
	link: string;
	/**
	 * (Optional) onClick
	 */
	onClick?: (id: string, state: boolean) => void;
}

/**
 *  The navigation element primary partial
 */

export const NavigationElementPrimary = (props: NavigationElementPrimaryProps): JSX.Element => {
	const { icon, label, id = label, isActive = false, isOpen = false, isDisabled = false, link, onClick } = props;

	const iconElement = <Icon name={icon} />;
	const labelElement = <div className='nav-element-primary__label'>{label}</div>;
	const activeElement = isActive === true ? <div className='nav-element-primary__active-element' /> : null;
	const navigationContent = isOpen === true ?
		<>
			{iconElement}
			{labelElement}
			{activeElement}
		</>
		:
		<>
			{iconElement}
			{activeElement}
		</>;

	const [activeState, setActiveState] = useState(isActive);
	const initialRender = useRef(true);

	useEffect(() => {
		if (!initialRender.current && onClick) {
			onClick(id, activeState);
		} else {
			initialRender.current = false;
		}
	}, [onClick, activeState, id]);

	const handleClick = () => {
		setActiveState(!activeState);
	};

	return (
		<Link
			to={link}
			className={`nav-element-primary-link ${!isDisabled ? '' : ' nav-element-primary-link--disabled'}`}
		>
			<div
				className={`nav-element-primary ${!isActive ? '' : 'nav-element-primary--active'}${!isOpen ? '' : ' nav-element-primary--open'}${!isDisabled ? '' : ' nav-element-primary--disabled'}`}
				onClick={handleClick}
				role='button'
				title={label}
				tabIndex={0}
			>
				{navigationContent}
			</div>
		</Link>
	);
};
