import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewLayoutSection } from '../../../../presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ClientContext } from '../../../core/context/ClientContext';
import { FacilityContext } from '../../../core/context/FacilityContext';
import { fetchAllDocuments, selectFilteredDeviceDocuments } from '../../store/documentsCollectionByDevicesSlice';
import { DocumentCollection } from '../ui/card-collections/document-collection/DocumentCollection';

export const DocumentsByDevices = (): JSX.Element => {

	const [deviceDocumentsFilterState, setDeviceDocumentsFilterState] = useState<{ [key: string]: boolean }>({
		active: true,
		archived: false
	});

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const dispatch = useDispatch();

	 // fetch documents
	useEffect(() => {
		dispatch(fetchAllDocuments({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}, [clientContext.selectedClientUuid, dispatch, facilityContext.selectedFacilityUuid]);

	const deviceDocumentModels = useSelector(
		selectFilteredDeviceDocuments(
			clientContext.selectedClientUuid,
			facilityContext.selectedFacilityUuid,
			deviceDocumentsFilterState.active,
			deviceDocumentsFilterState.archived
		)
	);

	const handleDeviceDocumentsFiltering = (id: string, state: boolean) => {
		setDeviceDocumentsFilterState({
			...deviceDocumentsFilterState,
			[id]: state
		});
	};

	// DebugConsole.log('deviceDocumentModels', deviceDocumentModels);

	return (
		<ViewLayoutSection>
			<DocumentCollection
				documents={deviceDocumentModels}
				onFilterClick={handleDeviceDocumentsFiltering}
			/>
		</ViewLayoutSection>
	);
};
