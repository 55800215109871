import { useContext, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';
import { AuthContext } from '../../../../../core/context/AuthContext';
import { ClientContext } from '../../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../../core/context/FacilityContext';

import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { ContainerModelState } from '../../../../domain/model/ContainerModelState';
import { CyclotronProductViewModel } from '../../../../domain/model/CyclotronProductModel';
import { GeneratorEluateIsotope } from '../../../../domain/model/isotopes/GeneratorEluateIsotope';
import { GeneratorTypeIsotope } from '../../../../domain/model/isotopes/GeneratorTypeIsotope';
import { NuclideIsotope } from '../../../../domain/model/isotopes/NuclideIsotope';
import { NuclideSurfaceContamination } from '../../../../domain/model/isotopes/NuclideSurfaceContamination';
import { updateContainer } from '../../../../store/containerSlice';
import { selectInWastemanagementCyclotronProducts } from '../../../../store/cyclotronProductSlice';
import { selectInWastemanagementEluates } from '../../../../store/eluateSlice';
import { selectGenerators } from '../../../../store/generatorSlice';
import { EluatePayloadType } from '../../../view/SingleNuclidesView';
import { ContainerContaminationMeasurementFormStep1 } from './ContainerContaminationMeasurementFormStep1';
import { ContainerContaminationMeasurementFormStep2 } from './ContainerContaminationMeasurementFormStep2';

export interface ContainerContaminationMeasurementFormProps {
	container?: ContainerViewModel;
}

export interface NuclideContamination {
	Nuclide: NuclideIsotope,
	SurfaceContamination: number
}

export const ContainerContaminationMeasurementForm = (props: ContainerContaminationMeasurementFormProps): JSX.Element => {
	const { container } = props;

	// Consume the dispatch object
	const dispatch = useDispatch();

	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const updatedContainer = useRef<ContainerViewModel>({} as ContainerViewModel);

	const inWastemanagementCyclotronProducts = useTypedSelector(selectInWastemanagementCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementEluates = useTypedSelector(selectInWastemanagementEluates());

	const generators = useTypedSelector(selectGenerators(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	inWastemanagementEluates.forEach(eluate => {

		const inWastemanagementEluateGenerator = generators.find((generator) => {
			return generator.Uuid === eluate.Generator;
		});
		if (inWastemanagementEluateGenerator.Isotope === GeneratorTypeIsotope.MOLYBDENUM_99_MO) {
			eluate.Isotope = GeneratorEluateIsotope.TECHNETIUM_99M_TC;
		} else {
			eluate.Isotope = GeneratorEluateIsotope.GALLIUM_68_GA;
		}
	});

	const inWastemanagementNuclides: Array<EluatePayloadType | CyclotronProductViewModel> =
		[].concat(inWastemanagementEluates, inWastemanagementCyclotronProducts);

	let containerNuclideIds: string[] = [];
	containerNuclideIds = [].concat(container.GeneratorEluatIds, container.CyclotroneProductIds);

	const containerNuclides = inWastemanagementNuclides.filter(nuclideModel => containerNuclideIds.includes(nuclideModel.Uuid));

	let surfaceContamination: any;
	const checkContainerNuclides = containerNuclides.every((nuclide) => {
		return nuclide.Isotope === containerNuclides[0].Isotope;
	});

	if (checkContainerNuclides && containerNuclides[0].Isotope === GeneratorEluateIsotope.TECHNETIUM_99M_TC) {
		surfaceContamination = NuclideSurfaceContamination.TECHNETIUM_99M_TC;
	} if (checkContainerNuclides && containerNuclides[0].Isotope !== GeneratorEluateIsotope.TECHNETIUM_99M_TC) {
		surfaceContamination = NuclideSurfaceContamination[containerNuclides[0].Isotope];
	}  if (!checkContainerNuclides) {
		const nuclidesContaminations: NuclideContamination[] = [];
		containerNuclides.map((item) => {
			const nuclideSurfaceContaminationObject: NuclideContamination = {
				Nuclide: item.Isotope,
				SurfaceContamination: NuclideSurfaceContamination[item.Isotope]
			};
			nuclidesContaminations.push(nuclideSurfaceContaminationObject);
			const usedNuclideMixedContainer = nuclidesContaminations.sort((a, b) => a.SurfaceContamination - b.SurfaceContamination)[0].Nuclide;
			surfaceContamination = NuclideSurfaceContamination[usedNuclideMixedContainer];
			return surfaceContamination;
		});
	}

	const requiredInputs = ['MeasurementContamination'];

	const onChangeContainerProp = (prop: string, value: number | Date) => {
		updatedContainer.current[prop] = value;
	};

	if ((updatedContainer.current?.MeasurementContaminationDateTime ?? null) === null) {
		updatedContainer.current.MeasurementContaminationDateTime = new Date();
	}

	const handleUpdate = (): void => {
		updatedContainer.current.Client = clientContext.selectedClientUuid;
		updatedContainer.current.Facility = facilityContext.selectedFacilityUuid;
		updatedContainer.current.Uuid = container.Uuid;
		updatedContainer.current.State = ContainerModelState.DECAY;
		updatedContainer.current.MeasurementContaminationBy = authContext.getActor().Uuid;
		dispatch(updateContainer(updatedContainer.current));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newContainerModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedContainer?.current[requiredInput] ?? null) === null
				|| updatedContainer?.current[requiredInput] === ''
				|| String(updatedContainer.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newContainerModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newContainerModelValid);
	};

	const showCreateModalStep = showStep1 === true ?
		<ContainerContaminationMeasurementFormStep1
			container={updatedContainer.current}
			onClickNext={goToStep2}
			invalidInputs={invalidInputs}
			onChangeContainerProp={onChangeContainerProp}
		/> :
		<ContainerContaminationMeasurementFormStep2
			container={updatedContainer.current}
			surfaceContamination={surfaceContamination}
			currentSurfaceContamination={updatedContainer.current.MeasurementContamination}
			onClickPrevious={goToStep1}
			onClickNext={handleUpdate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
