import { Timeout } from 'lib/timeout/Timeout';
import { JsonWebToken } from '../AuthService';
import {
	ExternalWasteManagementReportApiConnector,
	ExternalWasteManagementReportAuthenticationConnectorResult
} from './ExternalWasteManagementReportApiConnector';

interface AuthenticationPropellerResponse {
	readonly JsonWebToken?: JsonWebToken;
}

interface AuthenticationTestPropellerResponse {
	readonly Result?: string;
}

export class ExternalWasteManagementReportHttpApiConnector implements ExternalWasteManagementReportApiConnector {

	private static authEndpoint: string = process.env.REACT_APP_API_BASE_URL + 'waste-management-report/report-api/v1/authenticate/report';
	private static testEndpoint: string = process.env.REACT_APP_API_BASE_URL + 'waste-management-report/report-api/v1/authenticate/test/';

	public async authenticate(reportUuid: string, password: string): Promise<ExternalWasteManagementReportAuthenticationConnectorResult> {

		const request = new Request(
			ExternalWasteManagementReportHttpApiConnector.authEndpoint,
			{
				method: 'put',
				cache: 'no-cache',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					WasteManagementReportUuid: reportUuid,
					Password: password
				})
			}
		);

		const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error());
		const parsedBody = await response.json() as AuthenticationPropellerResponse;
		let jsonWebToken = parsedBody?.JsonWebToken ?? null as JsonWebToken;
		if (jsonWebToken !== null) {
			jsonWebToken = {
				...jsonWebToken,
				ValidUntil: jsonWebToken.ValidUntil * 1000
			};
		}

		return {
			JsonWebToken: jsonWebToken
		} as ExternalWasteManagementReportAuthenticationConnectorResult;
	}

	public async test(reportUuid: string, jsonWebToken?: JsonWebToken): Promise<boolean> {
		jsonWebToken = jsonWebToken ?? null;
		if (jsonWebToken === null) {
			return false;
		}
		const request = new Request(
			ExternalWasteManagementReportHttpApiConnector.testEndpoint.replace('{WASTE_MANAGEMENT_REPORT_UUID}', reportUuid),
			{
				method: 'get',
				cache: 'no-cache',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + jsonWebToken?.Token
				}
			}
		);

		let parsedBody: AuthenticationTestPropellerResponse;
		try {
			const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error());
			parsedBody = await response.json() as AuthenticationTestPropellerResponse;
		} catch (e) {
			return false;
		}

		return parsedBody?.Result === 'ok';
	}
}
