import { memo, useContext } from 'react';
import { RoleViewModel } from 'services/core/domain/model/RoleModel';
import { L10nContext } from 'context/L10nContext';

import './card-item-membership-attributes.scss';
import { Guid } from 'lib/guid/Guid';

export interface CardItemMembershipAttributesProps {
	/**
	 * (Optional) email
	 */
	email?: string
	/**
	 * (Optional) username
	 */
	userName?: string
	/**
	 * (Optional) realname
	 */
	realName?: string
	/**
	 * Membership(s)
	 */
	roles?: Array<RoleViewModel>
}

/**
 * A card item membership detail attributes component
 */
export const CardItemMembershipAttributes = memo((props: CardItemMembershipAttributesProps): JSX.Element => {
	const { email, userName, realName, roles } = props;

	const l10nContext = useContext(L10nContext);

	const emptyValuePlaceholder = '-';

	const checkEmptyValue = (val: string ): string => {
		return val !== '' && val !== null ? val : emptyValuePlaceholder;
	};

	const showRoles = roles.length === 0 ? emptyValuePlaceholder :
		roles.map((role, index) => {

			let roleName: string;
			if (index < (roles.length - 1)) {
				roleName = (role.Name) + ', ';
			} else {
				roleName = role.Name.toString();
			}
			return (
				<span key={role.Uuid}>
					{roleName}
				</span>
			);
		});

	const translations = {
		email: { label: l10nContext.translate('memberships.email', 'E-Mail-Adresse'), value: checkEmptyValue(email) },
		userName: { label: l10nContext.translate('memberships.userName', 'Benutzername'), value: checkEmptyValue(userName) },
		realName: { label: l10nContext.translate('memberships.realName', 'Name'), value: checkEmptyValue(realName) },
		roles: { label: l10nContext.translate('memberships.memberships', 'Rolle(n)'), value: showRoles },
	};

	const attributes = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const styleClass = 'attribute';

			return (
				<label
					key={Guid.generate()}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value ?? emptyValuePlaceholder}</p>
				</label>
			);
		});
	};

	return (
		<div className='card-item-membership-attributes'>
			<ul className='card-item-membership-attributes__attribute'>
				{attributes()}
			</ul>
		</div>
	);
});
