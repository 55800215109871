import { PrivateLocalStorage } from '../../../../lib/browser-storage/PrivateLocalStorage';
import { PrivateLocalStorageKey } from '../../../../lib/browser-storage/PrivateLocalStorageKey';
import { ExternalHttpApiConnector } from './api-connector/ExternalHttpApiConnector';

import { JsonWebToken } from './AuthService';

export class ExternalAuthService {

	private apiConnector: ExternalHttpApiConnector;

	private storeKey: string;

	private jsonWebToken?: JsonWebToken = null;

	public constructor(storeKey: string) {
		this.storeKey = storeKey;
		this.apiConnector = new ExternalHttpApiConnector();
	}

	public getJsonWebToken(): JsonWebToken | null {
		if (this.jsonWebToken === null) {
			this.jsonWebToken = PrivateLocalStorage.get(this.storeKey).read<JsonWebToken>(PrivateLocalStorageKey.REPORT_JSON_WEB_TOKEN);
			if (this.jsonWebToken === null || this.jsonWebToken.ValidUntil < Date.now()) {
				return null;
			}
		}
		return this.jsonWebToken;
	}

	public async authenticate(reportUuid: string, password: string): Promise<JsonWebToken | null> {
		try {
			const apiConnectorResult = await this.apiConnector.authenticate(reportUuid, password);
			this.setJsonWebToken(apiConnectorResult.JsonWebToken);
		} catch (e) {
			this.setJsonWebToken(null);
			throw e;
		}
		return this.getJsonWebToken();
	}

	public async test(reportUuid: string): Promise<boolean> {
		try {
			// eslint-disable-next-line @typescript-eslint/return-await
			return this.apiConnector.test(reportUuid, this.getJsonWebToken());
		} catch (e) {
			return false;
		}
	}

	public async require(reportUuid: string): Promise<void> {
		await this.apiConnector.test(reportUuid, this.getJsonWebToken());
	}

	private setJsonWebToken(jsonWebToken?: JsonWebToken): void {
		this.jsonWebToken = jsonWebToken ?? null;
		if (this.jsonWebToken === null) {
			PrivateLocalStorage.get(this.storeKey).remove(PrivateLocalStorageKey.REPORT_JSON_WEB_TOKEN);
		} else {
			PrivateLocalStorage.get(this.storeKey).write<JsonWebToken>(PrivateLocalStorageKey.REPORT_JSON_WEB_TOKEN, this.jsonWebToken);
		}
	}

}
