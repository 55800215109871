import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { Guid } from 'lib/guid/Guid';

import { Interaction } from 'services/device/domain/business/common/interaction/Interaction';
import { Sequence } from 'services/device/domain/business/common/sequence/Sequence';
import { Violation } from 'services/device/domain/business/common/violation/Violation';
import { getUpdateSequenceInteraction } from 'services/device/domain/business/util/SequenceUtil';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { WidgetsRenderer } from 'services/device/presentation/ui/widget/WidgetsRenderer';
import { updateSequence } from 'services/device/store/sequenceSlice';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';

export interface SequenceUpdateFormProps {
	sequence: SequenceViewModel;
	onError: (error: Error) => void;
}

/**
 * The sequence create form
 */
export const SequenceUpdateForm = (props: SequenceUpdateFormProps): JSX.Element => {

	const { sequence, onError } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Create state for the sequence interactions
	const [sequenceInteraction, setSequenceInteraction] = useState<Interaction<Sequence>>(null);
	const [validationViolations, setValidationViolations] = useState<ReadonlyArray<Violation>>([]);

	// Create an interaction if device is available and no interaction was already created
	useEffect(() => {
		const prepareInteraction = async (): Promise<void> => {
			// Select a random device
			setSequenceInteraction(getUpdateSequenceInteraction(sequence));
		};
		if (sequenceInteraction === null) {
			void prepareInteraction();
		}
	}, [sequence, sequenceInteraction]);

	const handlePrev = (): void => {
		try {
			setValidationViolations([]);
			setSequenceInteraction(sequenceInteraction.previous());
		} catch (error) {
			onError(error);
		}
	};

	const handleNext = (): void => {
		try {
			const validationErrors = sequenceInteraction.validate();
			if (validationErrors.length > 0) {
				setValidationViolations(validationErrors);
			} else {
				setValidationViolations([]);
				setSequenceInteraction(sequenceInteraction.next());
			}
		} catch (error) {
			onError(error);
		}
	};

	const handleSave = (): void => {
		const validationErrors = sequenceInteraction.validate();
		if (validationErrors.length > 0) {
			setValidationViolations(validationErrors);
		} else {
			setValidationViolations([]);
			try {
				const updatedSequence = sequenceInteraction.finish();
				const sequenceViewData = updatedSequence.toViewData();

				const sequenceViewModel = { ...sequence };
				sequenceViewModel.Name = sequenceViewData.values.name;
				sequenceViewModel.NotificationMailAddresses = sequenceViewData.values.notificationMailAddresses;
				sequenceViewModel.SequenceConfiguration = sequenceViewData;

				dispatch(updateSequence(sequenceViewModel));
			} catch (error) {
				onError(error);
			}
		}
	};

	const handleFormSubmit = (): void => {
		if (sequenceInteraction === null) {
			return;
		}
		if (sequenceInteraction.isLast()) {
			handleSave();
		} else {
			handleNext();
		}
	};

	const renderValidationViolations = (): Array<JSX.Element> => {
		return validationViolations.map<JSX.Element>((violation) => {
			return (
				<NotificationBar
					key={violation.getMessageKey()}
					message={l10nContext.translate(violation.getMessageKey())}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		});
	};

	const renderSequenceCreateInteraction = (): JSX.Element => {
		if (sequenceInteraction === null) {
			return null;
		}
		return (
			<WidgetsRenderer key="widgets" widgets={sequenceInteraction.getWidgets()} />
		);
	};

	const renderSequenceUpdateButtons = (): JSX.Element => {
		if (sequenceInteraction === null) {
			return null;
		}

		let leftButton = null;
		if (!sequenceInteraction.isFirst()) {
			leftButton = (
				<ButtonSecondary
					buttonText={l10nContext.translate('common.button.back', 'Zurück')}
					onClick={handlePrev}
				/>
			);
		}

		let rightButton;
		if (sequenceInteraction.isLast()) {
			rightButton = (
				<ButtonPrimary
					buttonText={l10nContext.translate('common.button.save', 'Speichern')}
					type="submit"
				/>
			);
		} else {
			rightButton = (
				<ButtonPrimary
					buttonText={l10nContext.translate('commpn.button.next', 'Weiter')}
					type="submit"
				/>
			);
		}

		return (
			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						{leftButton}
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						{rightButton}
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		);
	};

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} key={Guid.generate()} onSubmit={handleFormSubmit}>
			<NotificationBars>
				{renderValidationViolations()}
			</NotificationBars>
			{renderSequenceCreateInteraction()}
			{renderSequenceUpdateButtons()}
		</FormLayout>
	);
};
