import { CommentOutput } from 'services/device/domain/business/common/Output';

export interface CommentOutputValueProps {
	output: CommentOutput;
}

export const CommentOutputValueRenderer = (props: CommentOutputValueProps): JSX.Element => {
	const { output } = props;

	return (
		<span>
			{output.getSingle() ?? ''}
		</span>
	);

};
