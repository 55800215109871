import { NetworkContext } from 'context/NetworkContext';

import { CardDocumentAction } from 'presentation/ui/components/cards/card-document/CardDocumentActions';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Route } from 'router/Route';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Permission, permissionGroupDevice } from 'services/core/lib/auth/AuthService';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { ClearanceUpdateModal } from 'services/device/presentation/ui/clearance-action/clearance-update-modal/ClearanceUpdateModal';
import { ClearanceCard } from 'services/device/presentation/ui/clearance-card/ClearanceCard';
import { DeviceActionButtons } from 'services/device/presentation/ui/device-action-buttons/DeviceActionButtons';
import { DeviceArchiveModal } from 'services/device/presentation/ui/device-action/device-archive-modal/DeviceArchiveModal';
import { DeviceDeleteModal } from 'services/device/presentation/ui/device-action/device-delete-modal/DeviceDeleteModal';
import { DeviceUnarchiveModal } from 'services/device/presentation/ui/device-action/device-unarchive-modal/DeviceUnarchiveModal';
import { MeasurementGoalCreateModal } from 'services/device/presentation/ui/measurement-goal-action/measurement-goal-create-modal/MeasurementGoalCreateModal';
import { RecordCreateModal } from 'services/device/presentation/ui/record-action/record-create-modal/RecordCreateModal';
import { useController } from 'services/device/presentation/view/DeviceViewController';
import { User } from 'services/device/presentation/view/UserModel';
import { resetActionStatus, selectDeviceByUuid } from 'services/device/store/devicesSlice';
import { resetActionStatus as resetRecordActionStatus } from 'services/device/store/recordSlice';
import { resetActionStatus as resetSequenceActionStatus } from 'services/device/store/sequenceSlice';
import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentAddModal } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddModal';
import { DocumentArchiveModal } from 'services/documents/presentation/ui/document-action/document-archive-modal/DocumentArchiveModal';
import { DocumentDeleteModal } from 'services/documents/presentation/ui/document-action/document-delete-modal/DocumentDeleteModal';
import { DocumentDownloadModal } from 'services/documents/presentation/ui/document-action/document-download-modal/DocumentDownloadModal';

import { DocumentEditModal } from 'services/documents/presentation/ui/document-action/document-edit-modal/DocumentEditModal';
import { DocumentUnarchiveModal } from 'services/documents/presentation/ui/document-action/document-unarchive-modal/DocumentUnarchiveModal';
import {
	fetchDocumentsByDevice,
	resetActionStatus as resetDocumentActionStatus,
	selectFilteredDocumentsByDevice
} from 'services/documents/store/documentSlice';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { AsyncFetchStatus } from '../../../../store/common/AsyncFetchStatus';
import { useTypedSelector } from '../../../../store/common/TypedSelector';
import { ClearanceSequenceCollection } from '../ui/card-collections/clearance-sequence-collection/ClearanceSequenceCollection';
import { DocumentsCollection } from '../ui/card-collections/documents-collection/DocumentsCollection';
import { RecordCreateModalLocation } from '../ui/record-action/record-create-modal/RecordCreateModalLocation';
import { DeviceViewModalType } from './DeviceViewModalType';

interface ClearanceViewParams {
	deviceUuid: string;
}

export const ClearanceView = (): JSX.Element => {
	const [modalType, setModalType] = useState<DeviceViewModalType>(null);
	const [modalPayload, setModalPayload] = useState<SequenceViewModel | DocumentViewModel>(null);
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const [documentFilter, setDocumentFilter] = useState<{ [key: string]: boolean }>({
		active: true,
		archived: false
	});

	const controller = useController();

	// Unwrap the required device uuid from the route
	const params = useParams<ClearanceViewParams>();
	const deviceUuid = params?.deviceUuid ?? null;
	if (deviceUuid === null) {
		throw new Error('Device id is missing');
	}

	// Handle permissions
	const authContext = useContext(AuthContext);
	if (!authContext.hasAnyPermission(permissionGroupDevice)) {
		throw new Error('Permission denied');
	}

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const networkContext = useContext(NetworkContext);

	// Consume the history object
	const history = useHistory();

	const dispatch = useDispatch();

	// fetch documents
	const documentStoreFetchStatus = useTypedSelector(state => state.documents.fetchStatus);
	const documentStorePending = documentStoreFetchStatus === AsyncFetchStatus.INITIAL || documentStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const documentStoreLastActionError = useTypedSelector(state => state.documents.lastActionError);
	const documentStoreFailed = documentStoreFetchStatus === AsyncFetchStatus.FAILED;

	// Read the device from the state store
	const device = useSelector(selectDeviceByUuid(deviceUuid));
	const documents = useSelector(selectFilteredDocumentsByDevice(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		deviceUuid,
		documentFilter.active,
		documentFilter.archived
	));

	useEffect(() => {
		if (controller.deviceActionStatus === AsyncReducerStatus.DELETED) {
			dispatch(resetActionStatus());
			history.replace(Route.CLEARANCES);
		}
	}, [controller.deviceActionStatus, dispatch, history]);

	useEffect(() => {
		dispatch(fetchDocumentsByDevice({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			deviceUuid
		}));
	}, [clientContext.selectedClientUuid, deviceUuid, dispatch, facilityContext.selectedFacilityUuid]);

	// Show up a loading indicator while the devices fetching is pending
	if (
		controller.deviceActionStatus === AsyncReducerStatus.DELETE_PENDING
		|| controller.deviceActionStatus === AsyncReducerStatus.DELETED
	) {
		return (
			<LoadingSpinner />
		);
	}

	if (device === null) {
		// Show up an error if the device is not available although the devices were fetched from the persistence
		throw new Error('Device not found');
	}

	const onModalDismiss = () => {
		setModalType(null);
	};

	const onModalTriggerClick = (type: DeviceViewModalType, payload: SequenceViewModel = null) => {
		dispatch(resetActionStatus());
		dispatch(resetSequenceActionStatus());
		dispatch(resetRecordActionStatus());
		dispatch(resetDocumentActionStatus());

		setModalType(type);
		setModalPayload(payload);
	};

	const handleDocumentCollectionFiltering = (id: string, state: boolean) => {
		setDocumentFilter({
			...documentFilter,
			[id]: state
		});
	};

	const user: User = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permission: {
			edit: authContext.hasPermission(Permission.DEVICE_UPDATE) && device.State === DeviceModelState.ACTIVE,
			delete: authContext.hasPermission(Permission.DEVICE_DELETE) && device.Deletable,
			archive: authContext.hasPermission(Permission.DEVICE_UPDATE_STATE)
		}
	};

	const renderNotifications = (): Array<JSX.Element> => {
		const notifications = [];

		// Handle failed action
		if (controller.storeActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					message={controller.storeLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (documentStoreFailed) {
			notifications.push(
				<NotificationBar
					message={documentStoreLastActionError?.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		return notifications;
	};

	const modal = (): JSX.Element => {
		let deviceModal: JSX.Element = null;

		switch (modalType) {
			case DeviceViewModalType.DELETE:
				deviceModal =
					<DeviceDeleteModal
						device={device}
						onDismiss={onModalDismiss}
					/>;
				break;

			case DeviceViewModalType.ARCHIVE:
				deviceModal =
					<DeviceArchiveModal
						device={device}
						onDismiss={onModalDismiss}
					/>;
				break;

			case DeviceViewModalType.UNARCHIVE:
				deviceModal =
					<DeviceUnarchiveModal
						device={device}
						onDismiss={onModalDismiss}
					/>;
				break;

			case DeviceViewModalType.CREATE_RECORD:
				deviceModal =
					<RecordCreateModal
						sequence={modalPayload as SequenceViewModel}
						location={RecordCreateModalLocation.CLEARANCES}
						onDismiss={onModalDismiss}
					/>;
				break;
			case DeviceViewModalType.EDIT_CLEARANCE:
				deviceModal =
					<ClearanceUpdateModal
						device={device}
						onDismiss={onModalDismiss}
					/>;
				break;
			case DeviceViewModalType.CREATE_MEASUREMENT_GOAL:
				deviceModal =
					<MeasurementGoalCreateModal
						device={device}
						onDismiss={onModalDismiss}
					/>;
				break;
			case DeviceViewModalType.CREATE_DOCUMENT:
				deviceModal =
					<DocumentAddModal
						onDismiss={onModalDismiss}
						documentScope={DocumentModelScope.DEVICE}
						documentScopeReference={device.Uuid}
					/>;
				break;
			case DeviceViewModalType.ARCHIVE_DOCUMENT:
				deviceModal =
					<DocumentArchiveModal
						onDismiss={onModalDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
			case DeviceViewModalType.UNARCHIVE_DOCUMENT:
				deviceModal =
					<DocumentUnarchiveModal
						onDismiss={onModalDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
			case DeviceViewModalType.DOWNLOAD_DOCUMENT:
				deviceModal =
					<DocumentDownloadModal
						onDismiss={onModalDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
			case DeviceViewModalType.DELETE_DOCUMENT:
				deviceModal =
					<DocumentDeleteModal
						onDismiss={onModalDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;

			case DeviceViewModalType.EDIT_DOCUMENT_NAME:
				deviceModal =
					<DocumentEditModal
						onDismiss={onModalDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
		}

		return deviceModal;
	};

	const deviceCard =
		<ClearanceCard
			device={device}
			user={user}
			onClick={onModalTriggerClick}
		/>;

	const deviceActionButtons =
		<DeviceActionButtons
			device={device}
			user={user}
			onClick={onModalTriggerClick}
		/>;

	const renderDocuments = () => {
		return (
			<DocumentsCollection
				documents={documents}
				fetchStatus={documentStorePending}
				onAddDocument={() => onModalTriggerClick(DeviceViewModalType.CREATE_DOCUMENT)}
				addDocumentEnabled={device.State !== DeviceModelState.ARCHIVED &&
					networkContext.online &&
					authContext.hasPermission(Permission.DOCUMENT_CREATE)}
				onAction={(payload, document) => {
					switch (payload.type) {
						case CardDocumentAction.ARCHIVE:
							return onModalTriggerClick(DeviceViewModalType.ARCHIVE_DOCUMENT, document);
						case CardDocumentAction.UNARCHIVE:
							return onModalTriggerClick(DeviceViewModalType.UNARCHIVE_DOCUMENT, document);
						case CardDocumentAction.DELETE:
							return onModalTriggerClick(DeviceViewModalType.DELETE_DOCUMENT, document);
						case CardDocumentAction.DOWNLOAD:
							return onModalTriggerClick(DeviceViewModalType.DOWNLOAD_DOCUMENT, document);
						case CardDocumentAction.CHANGE:
							return onModalTriggerClick(DeviceViewModalType.EDIT_DOCUMENT_NAME, document);
						default:
							return null;
					}
				}}
				onFilterClick={handleDocumentCollectionFiltering}
			/>
		);
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs mapURLFragments={[[deviceUuid, device.Name]]} />
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>

					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>

					<ViewLayoutSection>

						{modal()}

						{deviceCard}

					</ViewLayoutSection>

					<ViewLayoutSection>
						<ClearanceSequenceCollection
							device={device}
							onAddSequenceClick={() => onModalTriggerClick(DeviceViewModalType.CREATE_MEASUREMENT_GOAL)}
							onAddRecordClick={(sequence) => onModalTriggerClick(DeviceViewModalType.CREATE_RECORD, sequence)}
						/>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{renderDocuments()}
					</ViewLayoutSection>

					{deviceActionButtons}

				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
