import { useEffect, useState } from 'react';

export const useKeyPress = (targetKey: string, handler?: () => void): boolean => {
	const [keyPressed, setKeyPressed] = useState<boolean>(false);

	const keyDownHandler = (event: KeyboardEvent) => {
		if (event.key === targetKey) {
			// Textareas should keep the default Enter-Key behavior
			const textAreaHasFocus = document.activeElement.tagName.toLowerCase() === 'textarea';

			if (textAreaHasFocus) return;

			event.preventDefault();

			setKeyPressed(true);

			if (handler) handler();
		}
	};

	const keyUpHandler = (event: KeyboardEvent) => {
	 	if (event.key === targetKey) {
			event.preventDefault();

			setKeyPressed(false);
	  	}
	};

	useEffect(() => {
		document.addEventListener('keydown', keyDownHandler);
		document.addEventListener('keyup', keyUpHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
			document.removeEventListener('keyup', keyUpHandler);

		};
	});

	return keyPressed;
};
