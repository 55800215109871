import './separator.scss';

/**
 * The separator partial
 */

export const Separator = (): JSX.Element => {

	return (
		<div className='separator' />
	);
};
