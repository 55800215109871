import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { CreatableDeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { createDevice } from 'services/device/store/devicesSlice';
import { DeviceType } from 'services/device/domain/business/inventory/DeviceType';
import { ClearanceCreateFormStep1 } from 'services/device/presentation/ui/clearance-action/clearance-create-form/ClearanceCreateFormStep1';
import { ClearanceCreateFormStep2 } from 'services/device/presentation/ui/clearance-action/clearance-create-form/ClearanceCreateFormStep2';

/**
 * The device create form
 */
export const ClearanceCreateForm = (): JSX.Element => {

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const newDeviceViewModel = useRef<CreatableDeviceViewModel>({} as CreatableDeviceViewModel);

	const onChangeDeviceProp = (prop: string, value: any) => {
		newDeviceViewModel.current[prop] = value;
	};

	const handleCreate = (): void => {
		newDeviceViewModel.current.Client = clientContext.selectedClientUuid;
		newDeviceViewModel.current.Facility = facilityContext.selectedFacilityUuid;
		newDeviceViewModel.current.Type = DeviceType.CLEARANCE;

		dispatch(createDevice(newDeviceViewModel.current));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const requiredInputs = ['Name'];

	const goToStep2 = () => {
		let newDeviceModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(newDeviceViewModel.current?.[requiredInput] ?? null) === null
				|| String(newDeviceViewModel.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newDeviceModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newDeviceModelValid);
	};

	const showCreateModalStep = showStep1 === true ?
		<ClearanceCreateFormStep1
			device={newDeviceViewModel.current}
			onClickNext={goToStep2}
			onChangeDeviceProp={onChangeDeviceProp}
			invalidInputs={invalidInputs}
		/> :
		<ClearanceCreateFormStep2
			device={newDeviceViewModel.current}
			onClickPrevious={goToStep1}
			onClickConfirm={handleCreate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
