import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';

import {
	SequenceModelConverter as ModelConverter,
	SequenceStoreModel as StoreModel,
	SequenceViewModel as ViewModel,
} from 'services/device/domain/model/SequenceModel';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { ExternalReportSequenceHttpPersistence } from '../../report/persistence/ExternalReportSequenceHttpPersistence';

// Declare a sequence state type
export interface ExternalReportSequenceState {
	externalReportSequences: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	externalReportSequences: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ExternalReportSequenceState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the

export const fetchExternalReportSequences = createAsyncThunk(
	'external-report-sequences/fetch',
	async (params: { reportUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new ExternalReportSequenceHttpPersistence(params.reportUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { externalReportSequences } = getState() as RootState;
			return checkFetchStatus(externalReportSequences.fetchStatus);
		}
	}
);

// Slice definition
export const externalReportSequenceSlice = createSlice({
	name: 'external-report-sequences',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchExternalReportSequences.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalReportSequences.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.externalReportSequences = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalReportSequences.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
	}
});

export const { resetState, resetActionStatus } = externalReportSequenceSlice.actions;

// Export the reducer as default
export default externalReportSequenceSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectExternalReportSequencesByDevice = (deviceUuid: string): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.externalReportSequences.externalReportSequences.filter((storeModel): boolean => {
			return storeModel.Device === deviceUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalReportSequenceByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.externalReportSequences.externalReportSequences.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			return null;
		}
	};
};
