import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { Error } from 'presentation/ui/components/error/Error';
import React, { useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { fetchDevices } from 'services/device/store/devicesSlice';
import { fetchSequences } from 'services/device/store/sequenceSlice';
import { fetchMaintenanceLogEntries } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { fetchCyclotronProducts } from 'services/nuclide/store/cyclotronProductSlice';
import { fetchEluates } from 'services/nuclide/store/eluateSlice';
import { fetchGenerators } from 'services/nuclide/store/generatorSlice';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { fetchContainerDispatches } from '../../../../services/nuclide/store/containerDispatchSlice';
import { fetchContainerDisposes } from '../../../../services/nuclide/store/containerDisposeSlice';
import { fetchContainers } from '../../../../services/nuclide/store/containerSlice';
import { fetchWasteManagementReports } from '../../../../services/waste-management-report/store/wasteManagementReportSlice';
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner';

export const StoreWarmup = (props: any): JSX.Element => {

	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const deviceStoreFetchStatus = useTypedSelector(state => state.devices.fetchStatus);
	const deviceStoreLastFetchError = useTypedSelector(state => state.devices.lastFetchError);
	const deviceStorePending = deviceStoreFetchStatus === AsyncFetchStatus.INITIAL || deviceStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const deviceStoreFailed = deviceStoreFetchStatus === AsyncFetchStatus.FAILED;

	const sequenceStoreFetchStatus = useTypedSelector(state => state.sequences.fetchStatus);
	const sequenceStoreLastFetchError = useTypedSelector(state => state.sequences.lastFetchError);
	const sequenceStorePending = sequenceStoreFetchStatus === AsyncFetchStatus.INITIAL || sequenceStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const sequenceStoreFailed = sequenceStoreFetchStatus === AsyncFetchStatus.FAILED;

	const maintenanceLogEntryStoreFetchStatus = useTypedSelector(state => state.maintenanceLogEntries.fetchStatus);
	const maintenanceLogEntryStoreLastFetchError = useTypedSelector(state => state.maintenanceLogEntries.lastFetchError);
	const maintenanceLogEntryStorePending = maintenanceLogEntryStoreFetchStatus === AsyncFetchStatus.INITIAL
		|| maintenanceLogEntryStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const maintenanceLogEntryStoreFailed = maintenanceLogEntryStoreFetchStatus === AsyncFetchStatus.FAILED;

	const generatorStoreFetchStatus = useTypedSelector(state => state.generators.fetchStatus);
	const generatorStoreLastFetchError = useTypedSelector(state => state.generators.lastFetchError);
	const generatorStorePending = generatorStoreFetchStatus === AsyncFetchStatus.INITIAL || generatorStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const generatorStoreFailed = generatorStoreFetchStatus === AsyncFetchStatus.FAILED;

	const eluateStoreFetchStatus = useTypedSelector(state => state.eluates.fetchStatus);
	const eluateStoreLastFetchError = useTypedSelector(state => state.eluates.lastFetchError);
	const eluateStorePending = eluateStoreFetchStatus === AsyncFetchStatus.INITIAL || eluateStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const eluateStoreFailed = eluateStoreFetchStatus === AsyncFetchStatus.FAILED;

	const cyclotronProductStoreFetchStatus = useTypedSelector(state => state.cyclotronProducts.fetchStatus);
	const cyclotronProductStoreLastFetchError = useTypedSelector(state => state.cyclotronProducts.lastFetchError);
	const cyclotronProductStorePending = cyclotronProductStoreFetchStatus === AsyncFetchStatus.INITIAL
		|| cyclotronProductStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const cyclotronProductStoreFailed = cyclotronProductStoreFetchStatus === AsyncFetchStatus.FAILED;

	const containerStoreFetchStatus = useTypedSelector(state => state.containers.fetchStatus);
	const containerStoreLastFetchError = useTypedSelector(state => state.containers.lastFetchError);
	const containerStorePending = containerStoreFetchStatus === AsyncFetchStatus.INITIAL
		|| containerStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const containerStoreFailed = containerStoreFetchStatus === AsyncFetchStatus.FAILED;

	const containerDisposeStoreFetchStatus = useTypedSelector(state => state.containerDispose.fetchStatus);
	const containerDisposeStoreLastFetchError = useTypedSelector(state => state.containerDispose.lastFetchError);
	const containerDisposeStorePending = containerDisposeStoreFetchStatus === AsyncFetchStatus.INITIAL
		|| containerDisposeStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const containerDisposeStoreFailed = containerDisposeStoreFetchStatus === AsyncFetchStatus.FAILED;

	const containerDispatchStoreFetchStatus = useTypedSelector(state => state.containerDispatch.fetchStatus);
	const containerDispatchStoreLastFetchError = useTypedSelector(state => state.containerDispatch.lastFetchError);
	const containerDispatchStorePending = containerDispatchStoreFetchStatus === AsyncFetchStatus.INITIAL
		|| containerDispatchStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const containerDispatchStoreFailed = containerDispatchStoreFetchStatus === AsyncFetchStatus.FAILED;

	const wasteManagementReportStoreFetchStatus = useTypedSelector(state => state.wasteManagementReports.fetchStatus);
	const wasteManagementReportStoreLastFetchError = useTypedSelector(state => state.wasteManagementReports.lastFetchError);
	const wasteManagementReportStorePending = wasteManagementReportStoreFetchStatus === AsyncFetchStatus.INITIAL
		|| wasteManagementReportStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;

	const pending = deviceStorePending
		|| sequenceStorePending
		|| maintenanceLogEntryStorePending
		|| generatorStorePending
		|| eluateStorePending
		|| cyclotronProductStorePending
		|| containerStorePending
		|| containerDisposeStorePending
		|| containerDispatchStorePending
		|| wasteManagementReportStorePending;

	const failed = deviceStoreFailed
		|| sequenceStoreFailed
		|| maintenanceLogEntryStoreFailed
		|| generatorStoreFailed
		|| eluateStoreFailed
		|| cyclotronProductStoreFailed
		|| containerStoreFailed
		|| containerDisposeStoreFailed
		|| containerDispatchStoreFailed;

	const startedFetchDeviceStore = useRef<boolean>(false);
	const startedFetchMaintenanceLogEntryStore = useRef<boolean>(false);
	const startedFetchSequenceStore = useRef<boolean>(false);
	const startedFetchGeneratorStore = useRef<boolean>(false);
	const startedFetchEluatStore = useRef<boolean>(false);
	const startedFetchCyclotroneProductStore = useRef<boolean>(false);
	const startedFetchContainerStore = useRef<boolean>(false);
	const startedFetchContainerDispose = useRef<boolean>(false);
	const startedFetchContainerDispatch = useRef<boolean>(false);
	const startedFetchWasteManagementReport = useRef<boolean>(false);

	const dispatch = useDispatch();

	if (!startedFetchDeviceStore.current && deviceStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchDeviceStore.current = true;
		dispatch(fetchDevices({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}

	if (!startedFetchSequenceStore.current && sequenceStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchSequenceStore.current = true;
		dispatch(fetchSequences({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}

	if (!startedFetchMaintenanceLogEntryStore.current && maintenanceLogEntryStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchMaintenanceLogEntryStore.current = true;
		dispatch(fetchMaintenanceLogEntries({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}

	if (!startedFetchGeneratorStore.current && generatorStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchGeneratorStore.current = true;
		dispatch(fetchGenerators({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}

	if (!startedFetchEluatStore.current && eluateStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchEluatStore.current = true;
		dispatch(fetchEluates({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}

	if (!startedFetchCyclotroneProductStore.current && cyclotronProductStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchCyclotroneProductStore.current = true;
		dispatch(fetchCyclotronProducts({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}

	if (!startedFetchContainerStore.current && containerStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchContainerStore.current = true;
		dispatch(fetchContainers({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}

	if (!startedFetchContainerDispose.current && containerDisposeStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchContainerDispose.current = true;
		dispatch(fetchContainerDisposes({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}

	if (!startedFetchContainerDispatch.current && containerDispatchStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchContainerDispatch.current = true;
		dispatch(fetchContainerDispatches({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}

	if (!startedFetchWasteManagementReport.current && wasteManagementReportStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchWasteManagementReport.current = true;
		dispatch(fetchWasteManagementReports({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	}

	if (pending) {
		return (
			<LoadingSpinner />
		);
	}

	if (failed) {
		const errorCode = (deviceStoreLastFetchError as PropellerError)?.code ?? null;
		const authRequired = errorCode === '401' || errorCode === '402';
		if (authRequired) {
			void authContext.invalidate();
		}

		const errors = [
			deviceStoreLastFetchError,
			sequenceStoreLastFetchError,
			maintenanceLogEntryStoreLastFetchError,
			generatorStoreLastFetchError,
			eluateStoreLastFetchError,
			cyclotronProductStoreLastFetchError,
			containerStoreLastFetchError,
			containerDisposeStoreLastFetchError,
			containerDispatchStoreLastFetchError,
			wasteManagementReportStoreLastFetchError
		].filter((error: Error | null): boolean => {
			return error !== null;
		});

		const messages = errors.map((error: Error): string => {
			return error.message;
		});

		return (
			<div>
				<Error error={messages} />
			</div>
		);
	}

	return (
		<>
			{props.children}
		</>
	);

};
