import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';

export interface ContainerContaminationMeasureSuccessNotificationProps {
	onClickConfirm: () => void;
	containerUpdated?: ContainerViewModel;
}

export const ContainerContaminationMeasurementSuccessNotification = (props: ContainerContaminationMeasureSuccessNotificationProps): JSX.Element => {
	const { onClickConfirm } = props;

	// Consume context
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.containers.modal.action.contaminationMeasurement.successMessage', 'Das Gebinde wurde erfolgreich gemessen')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.containers.modal.btnContainerDetails', 'Weiter zu den Gebindedetails')}
						onClick={onClickConfirm}
					/>
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.containers.modal.btnContainerOverview', 'Weiter zur Gebinde-Übersicht')}
						onClick={() => { history.push(Route.NUCLIDE_MANAGEMENT + Route.WASTE_MANAGEMENT);}}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
