import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import {
	MaintenanceLogResolveForm,
} from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-resolve-form/MaintenanceLogResolveForm';
import {
	MaintenanceLogResolveModalFailed,
} from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-resolve-modal/MaintenanceLogResolveModalFailed';
import {
	MaintenanceLogResolveModalSuccess,
} from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-resolve-modal/MaintenanceLogResolveModalSuccess';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface MaintenanceLogModalResolveProps {
	selectedMaintenanceLogEntry?: MaintenanceLogEntryViewModel,
	onDismiss: () => void
}

export const MaintenanceLogResolveModal = (props: MaintenanceLogModalResolveProps): JSX.Element => {
	const { selectedMaintenanceLogEntry, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const maintenanceLogEntriesActionStatus = useTypedSelector(state => state.maintenanceLogEntries.actionStatus);

	const renderModalContent = () => {
		switch (maintenanceLogEntriesActionStatus) {
			case AsyncReducerStatus.IDLE:
				return <MaintenanceLogResolveForm maintenanceLogEntry={selectedMaintenanceLogEntry} />;

			case AsyncReducerStatus.UPDATED:
				return <MaintenanceLogResolveModalSuccess onClick={onDismiss} />;

			case AsyncReducerStatus.FAILED:
				return <MaintenanceLogResolveModalFailed onClick={onDismiss} />;

			default: return null;
		}
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.maintenances.modal.action.resolve.caption', 'Betriebsbucheintrag beheben')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
