import React, { useContext, useState } from 'react';

import { Permission } from 'services/core/lib/auth/AuthService';
import { AuthContext } from 'services/core/context/AuthContext';

import { L10nContext } from 'context/L10nContext';

import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ButtonToggleSet } from 'presentation/ui/components/button/ButtonToggleSet';
import { SiteTitle, SiteTitleButton } from 'presentation/ui/components/site-title/SiteTitle';

export interface MaintenanceLogHeaderProps {
	onToggle: (id: string) => void
	onCreate: () => void
}

export const MaintenanceLogHeader = (props: MaintenanceLogHeaderProps): JSX.Element => {
	const { onToggle, onCreate } = props;

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	const [toggleState, setToggleState] = useState<string>('1');

	const renderMaintenanceLogEntryAddButton = (): SiteTitleButton => {
		if (toggleState === '1') {
			return {
				icon: IconIdentifier.ADD,
				disabled: !authContext.hasPermission(Permission.MAINTENANCE_LOG_ENTRY_CREATE),
				buttonText: l10nContext.translate('view.maintenances.modal.action.create.caption', 'Eintrag hinzufügen'),
				onClick: () => {
					onCreate();
				}
			};
		}
		return null;
	};

	return (
		<>
			<SiteTitle
				title={l10nContext.translate('view.maintenances.title', 'Betriebsbuch-Einträge')}
				button={renderMaintenanceLogEntryAddButton()}
			>
				<ButtonToggleSet
					onClick={(id) => {
						setToggleState(id);
						onToggle(id);
					}}
					buttons={[
						{
							id: '1',
							buttonText: l10nContext.translate('view.maintenances.toggle1', 'Standort'),
							icon: IconIdentifier.LOCATION,
							isActive: toggleState === '1',

						},
						{
							id: '2',
							buttonText: l10nContext.translate('view.maintenances.toggle2', 'Geräte'),
							icon: IconIdentifier.DEVICE,
							isActive: toggleState === '2'
						},
					]}
					deSelectAll={false}
				/>
			</SiteTitle>
		</>
	);
};
