import { createContext } from 'react';

interface SequencePeriodContextValue {
	dateStart: Date,
	dateEnd: Date,
	setDateStart: (date: Date) => void,
	setDateEnd: (date: Date) => void
}

// noinspection JSUnusedLocalSymbols
export const SequencePeriodContext = createContext<SequencePeriodContextValue>({
	dateStart: new Date(),
	dateEnd: new Date(),
	setDateStart: (date: Date): void => {
	},
	setDateEnd: (date: Date): void => {
	} // TODO: Check functionality of context
});
