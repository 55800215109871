import { L10nContext } from 'context/L10nContext';

import { useKeyPress } from 'presentation/hooks';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { DateTimePicker } from 'presentation/ui/compositions/date-time-picker/DateTimePicker';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { Textarea, TextareaStatus } from 'presentation/ui/partials/input/textarea/Textarea';
import { useContext } from 'react';

import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';

export interface MaintenanceLogResolveFormStep1Props {
	maintenanceLogEntry: MaintenanceLogEntryViewModel;
	onClickNext: () => void;
	onChange?: (identifier: string, value: string | Date) => void;
	invalidInputs?: string[];
}

export const MaintenanceLogResolveFormStep1 = (props: MaintenanceLogResolveFormStep1Props): JSX.Element => {
	const { maintenanceLogEntry, onClickNext, invalidInputs = [], onChange } = props;

	const l10nContext = useContext(L10nContext);

	useKeyPress('Enter', onClickNext);

	const notification = !invalidInputs.length ?
		null :
		<NotificationBar
			level={NotificationLevel.LEVEL_ERROR}
			message={`${invalidInputs.join(` ${l10nContext.translate('common.text.and', 'und')} `)} ${l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein')}`}
		/>;

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			onSubmit={onClickNext}
		>
			<NotificationBars>
				{notification}
			</NotificationBars>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('view.maintenances.modal.action.resolve.resolveHint', 'Achtung')}`}>
					<Textarea
						defaultValue={l10nContext.translate('view.maintenances.modal.action.resolve.resolveHintText',
							'Mit dem Setzen eines Datums wird der Betriebsbucheintrag behoben. ' +
							'Es ist dann nicht mehr möglich das Kommentarfeld zu editieren.')}
						status={TextareaStatus.READONLY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={l10nContext.translate('view.maintenances.modal.action.edit.resolveDate', 'Datum der Behebung')}>
					<DateTimePicker
						name='incidentDateEnd'
						defaultValue={maintenanceLogEntry?.IncidentDateEnd ?? new Date()}
						onChange={(event) => onChange('IncidentDateEnd', event)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type='submit'
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
