/* eslint-disable max-classes-per-file */

export enum Unit {

	BECQUEREL = 'BECQUEREL',

	BECQUEREL_PER_SQUARE_CENTIMETER = 'BECQUEREL_PER_SQUARE_CENTIMETER',

	CENTI_GRAY_PER_SQUARE_CENTIMETER = 'CENTI_GRAY_PER_SQUARE_CENTIMETER',

	GRAY_PER_SQUARE_CENTIMETER = 'GRAY_PER_SQUARE_CENTIMETER',

	CENTIMETER = 'CENTIMETER',

	COUNT = 'COUNT',

	ABSOLUTE_COUNT = 'ABSOLUTE_COUNT',

	ABSOLUTE_KILO_COUNT = 'ABSOLUTE_KILO_COUNT',

	COUNTS_PER_SECOND = 'COUNTS_PER_SECOND',

	COUNTS_PER_10_SECONDS = 'COUNTS_PER_10_SECONDS',

	COUNTS_PER_25_SECONDS = 'COUNTS_PER_25_SECONDS',

	COUNTS_PER_MINUTE = 'COUNTS_PER_MINUTE',

	COUNTS_PER_THREE_MINUTES = 'COUNTS_PER_THREE_MINUTES',

	COUNTS_PER_5_MINUTES = 'COUNTS_PER_5_MINUTES',

	COUNTS_PER_SECOND_PER_KILO_BECQUEREL = 'COUNTS_PER_SECOND_PER_KILO_BECQUEREL',

	COUNTS_PER_SECOND_PER_MEGA_BECQUEREL = 'COUNTS_PER_SECOND_PER_MEGA_BECQUEREL',

	COUNTS_PER_SECOND_PER_MEGA_BECQUEREL_PER_CUBIC_CENTIMETER = 'COUNTS_PER_SECOND_PER_MEGA_BECQUEREL_PER_CUBIC_CENTIMETER',

	COUNTS_PER_MINUTE_PER_MICRO_CURIE = 'COUNTS_PER_MINUTE_PER_MICRO_CURIE',

	COUNTS_PER_MINUTE_PER_KILO_BECQUEREL = 'COUNTS_PER_MINUTE_PER_KILO_BECQUEREL',

	COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL = 'COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL',

	KILO_BECQUEREL = 'KILO_BECQUEREL',

	KILO_ELECTRONS_VOLT = 'KILO_ELECTRONS_VOLT',

	KILO_COUNTS_PER_MINUTE = 'KILO_COUNTS_PER_MINUTE',

	KILO_COUNTS_PER_THREE_MINUTES = 'KILO_COUNTS_PER_THREE_MINUTES',

	KILO_COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL = 'KILO_COUNTS_PER_MINUTE_PER_MEGA_BECQUEREL',

	KILO_COUNTS_PER_SECOND = 'KILO_COUNTS_PER_SECOND',

	MEGA_BECQUEREL = 'MEGA_BECQUEREL',

	MICRO_GRAY_PER_SQUARE_METER = 'MICRO_GRAY_PER_SQUARE_METER',

	MICROSIEVERT = 'MICROSIEVERT',

	MICRO_SIEVERT_PER_HOUR = 'MICRO_SIEVERT_PER_HOUR',

	MILLI_GRAY = 'MILLI_GRAY',

	MILLI_GRAY_PER_SQUARE_CENTIMETER = 'MILLI_GRAY_PER_SQUARE_CENTIMETER',

	MOL = 'MOL',

	NANOCURIE = 'NANOCURIE',

	PICO_SECOND = 'PICO_SECOND',

	PIXEL = 'PIXEL',

	PERCENT = 'PERCENT',

	PERCENT_TC = 'PERCENT_TC',

	PERCENT_TC_X = 'PERCENT_TC_X',

	SIGMA = 'SIGMA'

}