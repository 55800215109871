export enum CardItemActionType {
	ACTIVE = 'ACTIVE',
	ARCHIVE = 'ARCHIVE',
	CHANGE = 'CHANGE',
	DELETE = 'DELETE',
	DOWNLOAD = 'DOWNLOAD',
	MOVE = 'MOVE',
	UNARCHIVE = 'UNARCHIVE',
	TO_ACTIVE_STATE = 'TO_ACTIVE_STATE'
}
