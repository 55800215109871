import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';
import { DateTimeOutput } from 'services/device/domain/business/common/Output';

export interface DateTimeOutputValueProps {
	output: DateTimeOutput;
}

export const DateTimeOutputValueRenderer = (props: DateTimeOutputValueProps): JSX.Element => {
	const { output } = props;

	const l10nContext = useContext(L10nContext);

	return (
		<span>
			{l10nContext.formatDateTime(output.getSingle())}
		</span>
	);

};
