import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { useKeyPress } from 'presentation/hooks';
import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';

export interface SequenceUpdateSuccessNotificationProps {
	onClickConfirm: () => void;
}

export const SequenceUpdateSuccessNotification = (props: SequenceUpdateSuccessNotificationProps): JSX.Element => {
	const { onClickConfirm } = props;

	const l10nContext = useContext(L10nContext);

	useKeyPress('Enter', onClickConfirm);

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.sequence.modal.action.edit.successMessage', 'Die Messreihe wurde erfolgreich geändert')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.sequence.modal.action.edit.btnDeviceDetails', 'Weiter zu den Messreihendetails')}
						onClick={onClickConfirm}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
