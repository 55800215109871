import { L10nContext } from 'context/L10nContext';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { useContext } from 'react';

export interface MembershipCreateModalStep1Props {
	/**
	 * Membership
	 */
	membership: { [key: string]: { [key: string]: string } };
	/**
	 * Button next action
	 */
	onClickNext: () => void;
	/**
	 * Invalid inputs
	 */
	invalidInputs?: string[];
	/**
	 * Change membership props
	 */
	onChangeActorData: (prop: string, value: unknown) => void;
}

/**
 * The membership create modal step 1
 */
export const MembershipCreateFormStep1 = (props: MembershipCreateModalStep1Props): JSX.Element => {
	const { membership, invalidInputs = [], onClickNext, onChangeActorData } = props;

	// Consume the contexts
	const l10nContext = useContext(L10nContext);

	// Notification for invalid input fields
	const invalidInputsWithoutMailOrUser = invalidInputs.filter(invalidInput => invalidInput !== 'E-Mail-Adresse oder Benutzername');

	const checkPlural = invalidInputsWithoutMailOrUser.length > 1 ?
		l10nContext.translate('common.modal.notificationErrorPlural', ' dürfen nicht leer sein') :
		l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein');
	const invalidNotificationMessage = invalidInputsWithoutMailOrUser.join(l10nContext.translate('common.text.and', ' und '))
		+ checkPlural;
	const invalidNotificationMessageMailOrUser = l10nContext.translate('common.modal.notificationMailOrUser', 'Bitte füllen Sie E-Mail-Adresse oder Benutzername aus');

	let notification = null;
	if (invalidInputs.length > 0) {

		const notificationMessage = invalidInputsWithoutMailOrUser.length !== 0 ?
			<NotificationBar
				level={NotificationLevel.LEVEL_ERROR}
				message={invalidNotificationMessage}
			/> : null;

		if (invalidInputs.includes('E-Mail-Adresse oder Benutzername')) {
			notification = (
				<>
					<NotificationBar
						level={NotificationLevel.LEVEL_ERROR}
						message={invalidNotificationMessageMailOrUser}
					/>
					{notificationMessage}

				</>
			);
		}

		if (!invalidInputs.includes('E-Mail-Adresse oder Benutzername')) {
			notification = notificationMessage;
		}
	}

	const membershipActor = membership.Actor;
	const handleAddActorData = (prop: string, value: string) => {
		onChangeActorData(prop, value);
	};

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<NotificationBars>
				<NotificationBar message={l10nContext.translate('view.memberships.modal.action.add.notification', 'Falls der Benutzername oder die E-Mail-Adresse bereits existiert, wird dem entsprechenden Nutzer ein Zugriff für den Standort hinzugefügt. Name und Passwort werden in diesem Fall nicht durch die neu eingegebenen Daten überschrieben.')} level={NotificationLevel.LEVEL_NOTIFICATION} />
				{notification}
			</NotificationBars>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('memberships.email', 'E-Mail')}
				>
					<InputText
						type="email"
						defaultValue={membershipActor?.MailAddress ?? ''}
						onChange={(event) => handleAddActorData('MailAddress', event.target.value)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('memberships.userName', 'Benutzername')}
				>
					<InputText
						type="text"
						minLength={6}
						defaultValue={membershipActor?.Username ?? ''}
						onChange={(event) => handleAddActorData('Username', event.target.value)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('memberships.realName', 'Name')}*`}
				>
					<InputText
						type="text"
						defaultValue={membershipActor?.Realname ?? ''}
						onChange={(event) => handleAddActorData('Realname', event.target.value)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('memberships.password', 'Passwort')}*`}
				>
					<InputText
						type="text"
						defaultValue={membershipActor?.Password ?? ''}
						onChange={(event) => handleAddActorData('Password', event.target.value)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
