import { ProductGroup } from 'services/device/domain/business/inventory/ProductGroup';
import { Inventory } from '../common/Inventory';

export class InventoryFactory {

	public static createInvetory(productGroups: Array<ProductGroup>): Inventory {
		const debugMode = process.env.REACT_DEBUG_SEQUENCES === '1';
		return new Inventory(productGroups, debugMode);
	}

}
