import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	EluateModelConverter as ModelConverter,
	EluatePersistenceModel as PersistenceModel,
	EluateStoreModel as StoreModel,
	EluateViewModel as ViewModel,
	CreatableEluateViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/EluateModel';

export class EluateHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'generator/api/v1';
	protected collectionControllerUri = 'generator-eluats';
	protected entityControllerUri = 'generator-eluat';

	protected responseCollectionKey = 'GeneratorEluatModelCollection';
	protected responseEntityKey = 'GeneratorEluatModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
