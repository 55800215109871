import { L10nContext } from 'context/L10nContext';
import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { EluateCreateForm } from '../eluate-create-form/EluateCreateForm';
import { EluateCreateFailedNotification } from './EluateCreateFailedNotification';
import { EluateCreateSuccessNotification } from './EluateCreateSuccessNotification';


interface EluateCreateModalProps {
	/**
	 * Generator
	 */
	generator: Readonly<GeneratorViewModel>
	/**
	 * onDismiss
	 */
	onDismiss: () => void;
}

/**
 * The eluate create modal
 */
export const EluateCreateModal = (props: EluateCreateModalProps): JSX.Element => {
	const { generator, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const eluateActionStatus = useTypedSelector(state => state.eluates.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (eluateActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = 
					<EluateCreateForm
						generator={generator}
					/>;
				break;

			case AsyncReducerStatus.CREATED:
				modal =
					<EluateCreateSuccessNotification
						onClickConfirm={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal = 
					<EluateCreateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;

	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.eluates.modal.action.create.caption', 'Eluat-Entnahme hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};