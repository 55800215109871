import { useEffect, useRef, useState } from 'react';

import { ButtonToggle, ButtonToggleProps } from 'presentation/ui/partials/button/button-toggle/ButtonToggle';

import './button-toggle-set.scss';
import { Guid } from 'lib/guid/Guid';

export interface ButtonToggleSetProps {
	/**
	 * Toggle Buttons
	 */
	buttons: ButtonToggleProps[];
	/**
	 * (Optional) property for de-selecting ALL buttons
	 */
	deSelectAll?: boolean;
	/**
	 * onClick
	 */
	onClick?: (id: string, state: boolean) => void;
}

export const ButtonToggleSet = (props: ButtonToggleSetProps): JSX.Element => {
	const { buttons, deSelectAll = true, onClick } = props;

	let preSelectedButton = buttons.filter(button => button.isActive === true)[0] ?? null;
	if (preSelectedButton === null && buttons.length > 0) {
		preSelectedButton = buttons[0];
		preSelectedButton.isActive = true;
	}

	const [activeButton, setActiveButton] = useState(preSelectedButton ?
		{
			id: preSelectedButton.id,
			state: preSelectedButton.isActive
		} :
		null
	);

	const initialRender = useRef(true);

	useEffect(() => {
		if (!initialRender.current && onClick) {
			onClick(activeButton.id, activeButton.state);
		} else {
			initialRender.current = false;
		}
	}, [onClick, activeButton]);

	const handleButtonToggleClick = (id: string, state: boolean) => {
		setActiveButton({
			id,
			state
		});
	};

	const buttonToggleElements = () => buttons.map((button) => {
		const isActiveCondition = activeButton?.id === button.id && activeButton?.state === true;
		const disabledState = button.disabled || (activeButton?.id === button.id && !deSelectAll);

		return (
			<ButtonToggle
				key={button.id?.trim().toLowerCase() ?? Guid.generate()}
				onClick={handleButtonToggleClick}
				isActive={isActiveCondition}
				id={button.id}
				buttonText={button.buttonText}
				icon={button.icon}
				disabled={disabledState}
				badge={button.badge}
			/>
		);
	});

	return (
		<div className="button-toggle-set">
			{buttonToggleElements()}
		</div>
	);
};
