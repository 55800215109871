import { PublicLocalStorage } from 'lib/browser-storage/PublicLocalStorage';
import { PublicLocalStorageKey } from 'lib/browser-storage/PublicLocalStorageKey';

import { ApiConnector, AuthenticationConnectorResult } from 'services/core/lib/auth/api-connector/ApiConnector';
import { JsonWebToken } from 'services/core/lib/auth/AuthService';
import { LocalActorRepository } from 'services/core/lib/auth/local/LocalActorRepository';

export class LocalStorageApiConnector implements ApiConnector {

	public async authenticate(user: string, password: string): Promise<AuthenticationConnectorResult> {
		const actor = await LocalActorRepository.get().getActor(user, password);

		if (actor !== null) {
			let latestActors = PublicLocalStorage.get().read<Array<string>>(PublicLocalStorageKey.LATEST_ACTOR_USERS) ?? [];
			latestActors = latestActors.filter((latestActor): boolean => {
				return latestActor !== user;
			});
			latestActors.unshift(user);
			latestActors = latestActors.slice(0, 4);
			PublicLocalStorage.get().write<Array<string>>(PublicLocalStorageKey.LATEST_ACTOR_USERS, latestActors);
		}

		return {
			ActorModel: actor?.[0] ?? null,
			AvailableClientModelCollection: actor?.[1] ?? [],
			JsonWebToken: null
		} as AuthenticationConnectorResult;
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public async unauthenticate(jsonWebToken?: JsonWebToken): Promise<void> {
	}

	public async test(jsonWebToken?: JsonWebToken): Promise<boolean> {
		return false;
	}

}
