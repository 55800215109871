import { memo, useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import './card-item-device-detail-attributes.scss';

export interface CardItemDeviceDetailAttributesProps {
	/**
	 * (Optional) ident value
	 */
	ident?: string
	/**
	 * (Optional) location value
	 */
	location?: string
	/**
	 * (Optional) serial number
	 */
	serialNumber?: string
	/**
	 * (Optional) location description
	 */
	locationDescription?: string
}

/**
 * A card item device detail attributes component
 */
export const CardItemDeviceDetailAttributes = memo((props: CardItemDeviceDetailAttributesProps): JSX.Element => {
	const { ident, location, serialNumber, locationDescription } = props;

	const l10nContext = useContext(L10nContext);

	const checkEmptyValue = (val: string): string => {
		return val !== '' && val !== null ? val : '-';
	};

	const translations = {
		ident: { label: l10nContext.translate('devices.detail.ident', 'Ident'), value: checkEmptyValue(ident) },
		location: { label: l10nContext.translate('devices.detail.location', 'Standort'), value: checkEmptyValue(location) },
		serialNumber: { label: l10nContext.translate('devices.detail.serialNumber', 'Seriennummer'), value: checkEmptyValue(serialNumber) },
		locationDescription: { label: l10nContext.translate('devices.detail.locationDescription', 'Ortsbeschreibung'), value: checkEmptyValue(locationDescription) }
	};

	const gridItems = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const key = Math.random() * (1000 - 1) + 1;
			const styleClass = 'grid-item';

			return (
				<li
					key={key}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value ?? '-'}</p>
				</li>
			);
		});
	};

	return (
		<div className='card-item-device-detail-attributes'>
			<ul className='card-item-device-detail-attributes__grid'>
				{gridItems()}
			</ul>
		</div>
	);
});
