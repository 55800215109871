import { Optional } from '../misc/Optional';
import { Valuation } from '../Valuation';
import { Value } from './Value';

export class BandValuation implements Value<Valuation> {
	private readonly result: Optional<Valuation>;

	public constructor(
		upperToleranceValue: Value<number>,
		upperReactionValue: Value<number>,
		value: Value<number>,
		lowerReactionValue: Value<number>,
		lowerToleranceValue: Value<number>
	) {
		this.result = calculate(
			upperToleranceValue.getSingle(),
			upperReactionValue.getSingle(),
			value.getSingle(),
			lowerReactionValue.getSingle(),
			lowerToleranceValue.getSingle()
		);
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<Valuation> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<Valuation> {
		return this.result;
	}
}

function calculate(
	upperToleranceValue: Optional<number>,
	upperReactionValue: Optional<number>,
	value: Optional<number>,
	lowerReactionValue: Optional<number>,
	lowerToleranceValue: Optional<number>
): Optional<Valuation> {
	if (value !== null) {
		if ((upperToleranceValue !== null && value > upperToleranceValue) || (lowerToleranceValue !== null && value < lowerToleranceValue)) {
			return Valuation.UNACCEPTABLE;
		}
		if ((upperReactionValue !== null && value > upperReactionValue) || (lowerReactionValue !== null && value < lowerReactionValue)) {
			return Valuation.NOTICEABLE;
		}
		return Valuation.ACCEPTABLE;
	}
	return null;
}
