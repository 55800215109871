import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	ContainerModelConverter as ModelConverter,
	ContainerPersistenceModel as PersistenceModel,
	ContainerStoreModel as StoreModel,
	ContainerViewModel as ViewModel,
	CreatableContainerViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/ContainerModel';

export class ContainerHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'waste-management/api/v1';
	protected collectionControllerUri = 'containers';
	protected entityControllerUri = 'container';

	protected responseCollectionKey = 'ContainerModelCollection';
	protected responseEntityKey = 'ContainerModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
