import { L10nContext } from 'context/L10nContext';
import { CardReportDetail } from 'presentation/ui/components/cards/card-report/card-report-detail/CardReportDetail';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { CardItemReportAttributes } from 'presentation/ui/partials/card/card-item-attributes/card-item-report-attributes/CardItemReportAttributes';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Route } from 'router/Route';
import { resetActionStatus as externalReportResetActionStatus, selectExternalReport } from 'services/report/store/externalReportSlice';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { DebugConsole } from '../../../../lib/debug/DebugConsole';
import { Guid } from '../../../../lib/guid/Guid';
import { CardDeviceList } from '../../../../presentation/ui/components/cards/card-device/card-device-list/CardDeviceList';
import { CardEmpty } from '../../../../presentation/ui/components/cards/card-empty/CardEmpty';
import {
	CardMaintenanceLogDetail
} from '../../../../presentation/ui/components/cards/card-maintenance-log/card-maintenance-log-detail/CardMaintenanceLogDetail';
import { LoadingSpinner } from '../../../../presentation/ui/components/loading-spinner/LoadingSpinner';
import {
	CardItemMaintenanceLogAttributes
} from '../../../../presentation/ui/partials/card/card-item-attributes/card-item-maintenance-log-attributes/CardItemMaintenanceLogAttributes';
import { CardItemSingleControl } from '../../../../presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from '../../../../presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from '../../../../presentation/ui/partials/card/card-item-controls/CardItemControlType';
import {
	CardItemMaintenanceLogHeader
} from '../../../../presentation/ui/partials/card/card-item-header/card-item-maintenance-log-header/CardItemMaintenanceLogHeader';
import { IconIdentifier } from '../../../../presentation/ui/partials/icon/IconIdentifier';
import { IllustrationIdentifier } from '../../../../presentation/ui/partials/illustration/IllustrationIdentifier';
import { mapTypeToIlluIdentifier } from '../../../../presentation/ui/partials/illustration/IllustrationIdentifierMapper';
import { TagType } from '../../../../presentation/ui/partials/tag/Tag';
import { SimpleViewTitle } from '../../../core/presentation/ui/simple-view-title/SimpleViewTitle';
import { DeviceModelState } from '../../../device/domain/model/DeviceModelState';
import { selectExternalReportClearances, selectExternalReportDevices } from '../../../device/store/externalReportDevicesSlice';
import { DocumentViewModel } from '../../../documents/domain/model/DocumentModel';
import { DocumentModelScope } from '../../../documents/domain/model/DocumentModelScope';
import { DocumentModalType } from '../../../documents/domain/type/DocumentsModalType';
import { DocumentEntry } from '../../../documents/presentation/ui/document-entry/DocumentEntry';
import { DocumentModal } from '../../../documents/presentation/ui/document-modal/DocumentModal';
import {
	resetActionStatus as externalReportDocumentResetActionStatus,
	selectExternalReportDocumentsByFacility
} from '../../../documents/store/externalReportDocumentSlice';
import { MaintenanceLogEntryModelState } from '../../../maintenance-log/domain/model/MaintenanceLogEntryModelState';
import { selectExternalReportMaintenanceLogEntriesByFacility } from '../../../maintenance-log/store/externalReportMaintenanceLogEntrySlice';

interface ReportViewParams {
	reportUuid: string;
}

export const ReportDevicesView = (): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const history = useHistory();
	const dispatch = useDispatch();

	const [selectedDocument, setSelectedDocument] = useState<DocumentViewModel>(null);
	const [modalDocumentType, setModalDocumentType] = useState<DocumentModalType>(null);

	// Unwrap the required report uuid from the route
	const params = useParams<ReportViewParams>();

	const reportUuid = params?.reportUuid ?? null;
	if (reportUuid === null) {
		throw new Error('Report id is missing');
	}

	const storeFetchStatus = useTypedSelector(state => state.externalReport.fetchStatus);

	// ToDO: Check if I really need the Uuid; only one external report at a time
	const externalReport = useSelector(selectExternalReport(reportUuid));
	const externalReportDevices = useSelector(selectExternalReportDevices());
	const externalReportClearances = useSelector(selectExternalReportClearances());
	const externalReportDocumentsByFacility = useSelector(selectExternalReportDocumentsByFacility());
	const externalReportMaintenanceLogEntriesByFacility = useSelector(selectExternalReportMaintenanceLogEntriesByFacility());

	if (storeFetchStatus === AsyncFetchStatus.PENDING || storeFetchStatus === AsyncFetchStatus.INITIAL_PENDIG) {
		return (
			<LoadingSpinner />
		);
	}

	const resetModal = (): void => {
		dispatch(externalReportResetActionStatus());
		dispatch(externalReportDocumentResetActionStatus());
		setModalDocumentType(null);
		setSelectedDocument(null);
	};

	const handleDocumentDownload = (_actionType: CardItemControlActionType, documentViewModel: DocumentViewModel): void => {
		resetModal();
		setSelectedDocument(documentViewModel);

		setModalDocumentType(DocumentModalType.EXTERNAL_DOWNLOAD);
	};

	const externalReportPeriodDateStartFormatted = l10nContext.formatDate(externalReport?.PeriodDateStart);
	const externalReportPeriodDateEndFormatted = l10nContext.formatDate(externalReport?.PeriodDateEnd);
	const externalReportValidUntilDateFormatted = l10nContext.formatDate(externalReport?.ValidUntil);

	const reportCard =
		<CardReportDetail>
			<CardItemHeader
				title={externalReport?.Name}
			/>
			<CardItemReportAttributes
				periodDateStart={externalReportPeriodDateStartFormatted}
				periodDateEnd={externalReportPeriodDateEndFormatted}
				validUntil={externalReportValidUntilDateFormatted}
			/>
		</CardReportDetail>;

	const renderDevices = (): Array<JSX.Element> => {
		DebugConsole.log('renderDevices ReportDevicesView.tsx');
		return externalReportDevices?.map<JSX.Element>((device) => {
			return (
				<CardDeviceList
					key={device?.Uuid}
					archived={device.State === DeviceModelState.ARCHIVED}
				>
					<CardItemHeader
						title={device.Name}
						subtitle={device.Identifier}
						illustration={mapTypeToIlluIdentifier(device.Type)}
						archived={device.State === DeviceModelState.ARCHIVED}
					/>
					<CardItemSingleControl
						cardId={device?.Uuid}
						actionDetails={CardItemControlState.ENABLED}
						onClick={() => history.push(`${Route.EXTERNAL_REPORT}/${externalReport?.Uuid}${Route.DEVICES}/${device?.Uuid}${Route.SEQUENCES}`)}
					/>
				</CardDeviceList>
			);
		});
	};

	const renderClearances = (): Array<JSX.Element> => {
		return externalReportClearances.map<JSX.Element>((clearance) => {
			return (
				<CardDeviceList
					key={clearance.Uuid}
					archived={clearance.State === DeviceModelState.ARCHIVED}
				>
					<CardItemHeader
						title={clearance.Name}
						subtitle={clearance.Identifier}
						illustration={mapTypeToIlluIdentifier(clearance.Type)}
						archived={clearance.State === DeviceModelState.ARCHIVED}
					/>
					<CardItemSingleControl
						cardId={clearance.Uuid}
						actionDetails={CardItemControlState.ENABLED}
						onClick={() => history.push(`${Route.EXTERNAL_REPORT}/${externalReport?.Uuid}${Route.CLEARANCES}/${clearance?.Uuid}${Route.MEASUREMENT_GOALS}`)}
					/>
				</CardDeviceList>
			);
		});
	};

	const renderFacilityDocuments = (): JSX.Element => {
		if (externalReportDocumentsByFacility.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.documents', 'Keine Dokumente')} />
			);
		}

		const documentCards = externalReportDocumentsByFacility.map((document) => (

			<DocumentEntry
				key={document.Uuid}
				isReportView
				documentEntry={document}
				onActionClick={(action, downloadDocument) => {
					handleDocumentDownload(action, downloadDocument);
				}}
			/>
		));

		return (
			<>
				{documentCards}
			</>
		);
	};

	const renderMaintenanceLogEntriesFacility = (): Array<JSX.Element> => {
		let cards = [];

		if (externalReportMaintenanceLogEntriesByFacility && externalReportMaintenanceLogEntriesByFacility.length) {
			cards = externalReportMaintenanceLogEntriesByFacility.map((maintenanceLogEntry) => {
				const headerTag = {
					label: maintenanceLogEntry.State === MaintenanceLogEntryModelState.ONGOING ? l10nContext.translate('maintenanceLog.status.open', 'offen') : l10nContext.translate('maintenanceLog.status.resolved', 'abgeschlossen'),
					type: maintenanceLogEntry.State === MaintenanceLogEntryModelState.ONGOING ? TagType.NEGATIVE : TagType.POSITIVE,
					icon: IconIdentifier.EDIT
				};
				return (
					<CardMaintenanceLogDetail key={maintenanceLogEntry.Uuid}>
						<CardItemMaintenanceLogHeader
							title={maintenanceLogEntry.IncidentTitle}
							illustration={IllustrationIdentifier.OPERATING_LOG_GAMKAMERA_PLANAR}
							tag={headerTag}
						/>
						<CardItemMaintenanceLogAttributes
							incidentCause={maintenanceLogEntry.IncidentCause}
							recordDate={maintenanceLogEntry.IncidentDateStart}
							recorder={maintenanceLogEntry.ReporterName}
							solvedDate={maintenanceLogEntry.IncidentDateEnd}
							recorderSolved={maintenanceLogEntry.SolverName}
							activities={maintenanceLogEntry.IncidentSolution}
						/>
					</CardMaintenanceLogDetail>
				);
			});
		} else {
			cards.push(
				<CardEmpty
					key={Guid.generate()}
					message={l10nContext.translate('common.cards.reportsMaintenanceLogEntries', 'Keine Betriebsbucheinträge für diesen Zeitraum vorhanden')}
				/>
			);
		}
		return cards;
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs mapURLFragments={[[externalReport?.Uuid, externalReport?.Name]]} />
				</NavigationSecondary>
			</TopbarLayoutSection>
			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						{reportCard}
					</ViewLayoutSection>

					{externalReportDevices?.length > 0 ?
						<>
							<ViewLayoutSection>
								<SimpleViewTitle
									label={l10nContext.translate('view.reports.reportDevicesCollection', 'Geräte und Messreihen')}
									isReportView
								/>
							</ViewLayoutSection>
							<ViewLayoutSection>
								<CardCollectionLayout>
									{renderDevices()}
								</CardCollectionLayout>
							</ViewLayoutSection>
						</>
						: null}

					{externalReportClearances?.length > 0 ?
						<>
							<ViewLayoutSection>
								<SimpleViewTitle
									label={l10nContext.translate('view.reports.reportClearancesCollection', 'Freimessungen und Messziele')}
									isReportView
								/>
							</ViewLayoutSection>
							<ViewLayoutSection>
								<CardCollectionLayout>
									{renderClearances()}
								</CardCollectionLayout>
							</ViewLayoutSection>
						</>
						: null}

					<ViewLayoutSection>
						<SimpleViewTitle
							label={l10nContext.translate('view.reports.reportDocumentFacilityDocuments', 'Dokumente zum Standort')}
							isReportView
						/>
					</ViewLayoutSection>
					<ViewLayoutSection>
						<CardCollectionLayout>
							<DocumentModal
								modalType={modalDocumentType}
								selectedDocument={selectedDocument}
								onDismiss={resetModal}
								documentScope={DocumentModelScope.DOCUMENT_FOLDER}
							/>
							{renderFacilityDocuments()}
						</CardCollectionLayout>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<SimpleViewTitle
							label={l10nContext.translate('view.reports.reportFacilityMaintenanceLogEntriesFacility', 'Betriebsbucheinträge zum Standort')}
							isReportView
						/>
					</ViewLayoutSection>
					<ViewLayoutSection>
						<CardCollectionLayout>
							{renderMaintenanceLogEntriesFacility()}
						</CardCollectionLayout>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>
		</MainLayout>
	);
};
