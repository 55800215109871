import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';

export const DocumentAddPendingNotification = (): JSX.Element => (
	<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
		<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
			<LoadingSpinner />
		</ColumnLayoutSection>
	</ColumnLayout>
);
