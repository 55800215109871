import { L10nContext } from 'context/L10nContext';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { CSVLink } from 'react-csv';
import { CsvDataType } from './CsvDataType';

export interface SequenceDownloadModalContentProps {
	data: CsvDataType;
}

export const SequenceDownloadModalContent = (props: SequenceDownloadModalContentProps): JSX.Element => {
	const { data } = props;

	const l10nContext = useContext(L10nContext);

	return (
		<>
			<ModalDialogueSection>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<CardCollectionLayout>
							<p>{l10nContext.translate('view.sequence.modal.action.download.message')}</p>
							<CSVLink
								data={data.data}
								headers={data.headings}
								filename={data.filename}
							>
								<ButtonPrimary
									buttonText={l10nContext.translate('common.modal.captionDownload')}
									icon={IconIdentifier.DOWNLOAD}
								/>
							</CSVLink>
						</CardCollectionLayout>
					</ColumnLayoutSection>
				</ColumnLayout>
			</ModalDialogueSection>
		</>
	);
};
