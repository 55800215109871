import { DeviceType } from '../../inventory/DeviceType';
import { SequenceType } from '../../inventory/SequenceType';
import { MemoryStoreData, MemoryViewData } from '../memory/MemoryData';
import { AbstractFieldSet } from './AbstractFieldSet';

export class MemoryFieldSet<Fields> extends AbstractFieldSet<Fields> {
	public constructor(fieldsFactory: () => Fields) {
		super(fieldsFactory, null);
	}

	public toStoreData(strict: boolean): MemoryStoreData {
		return super.toStoreData(strict) as MemoryStoreData;
	}

	public toViewData(deviceType: DeviceType, sequenceType: SequenceType, strict: boolean): MemoryViewData {
		return super.toViewData(deviceType, sequenceType, strict) as MemoryViewData;
	}

	protected createClone(fieldsFactory: () => Fields): this {
		return new MemoryFieldSet(fieldsFactory) as this;
	}
}
