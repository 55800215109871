import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ComponentPropsWithoutRef } from 'react';

import './date-picker.scss';

export enum DatePickerStatus {
	ERROR = 'ERROR',
	READONLY = 'READONLY',
	SUMMARY = 'SUMMARY'
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface DatePickerProps extends ComponentPropsWithoutRef<'input'> {
	/**
	 * Status of choosen date
	 */
	status?: DatePickerStatus;
	/**
	 * (Predefined) date
	 */
	value?: Date;
	/**
	 * Default date
	 */
	defaultValue?: Date | null;
	/**
	 * Max date
	 */
	max?: Date;
	/**
	 * Min date
	 */
	min?: Date;
}

/**
 * A nearby native input date component.
 * You can add all additional attributes you know from native HTML input date element,
 * like f.e. max, min, step, onChange-handler etc.
 */
export const DatePicker = (props: DatePickerProps): JSX.Element => {
	const {
		placeholder = 'TT.MM.JJJJ',
		status,
		value = null,
		defaultValue = null,
		min = null,
		max = null,
		...restProps
	} = props;

	const stateClass = status ?
		`date-picker--${status.toLowerCase()}` : '';

	const formatDateValue = (date: Date | null): string | null => {
		if (date === null) {
			return null;
		}
		return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
	};

	const valueProps: Record<string, string> = {};
	const formattedValue = formatDateValue(value);

	if (formattedValue !== null) {
		valueProps.value = formattedValue;
	} else {
		const formattedDefaultValue = formatDateValue(defaultValue);

		if (formattedDefaultValue !== null) {
			valueProps.defaultValue = formattedDefaultValue;
		}
	}

	const formattedMin = formatDateValue(min);
	if (formattedMin !== null) {
		valueProps.min = formattedMin;
	}

	const formattedMax = formatDateValue(max);
	if (formattedMax !== null) {
		valueProps.max = formattedMax;
	}

	return (
		<div className={`date-picker ${stateClass}${props.disabled ? ' date-picker--disabled' : ''}`}>
			<input
				type="date"
				placeholder={placeholder}
				{...valueProps}
				{...restProps}
			/>

			<Icon name={IconIdentifier.CALENDER} />
		</div>
	);
};
