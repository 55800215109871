import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import { ActorModelState } from 'services/core/domain/model/ActorModelState';
import { ActorModelType } from 'services/core/domain/model/ActorModelType';

export interface ActorPersistenceModel extends PersistenceModel {
	Type: string;
	State: string;
	MailAddress?: string;
	Username?: string;
	Realname: string;
}

export interface ActorStoreModel extends StoreModel {
	Type: string;
	State: string;
	MailAddress?: string;
	Username?: string;
	Realname: string;
}

export interface ActorViewModel extends ViewModel {
	Type: ActorModelType;
	State: ActorModelState;
	MailAddress?: string;
	Username?: string;
	Realname: string;
}

export interface CreatableActorViewModel extends CreatableViewModel {
	MailAddress?: string;
	Username?: string;
	Realname: string;
	Password: string;
}

export class ActorModelConverter extends ModelConverter<ActorPersistenceModel, ActorStoreModel, ActorViewModel, CreatableActorViewModel> {

	public toViewModel(): ActorViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.Type = model?.Type as ActorModelType ?? null;
			vModel.State = model?.State as ActorModelState ?? null;
		});
	}

}
