import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { AuthContext } from 'services/core/context/AuthContext';
import { CreatableCyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';
import { createCyclotronProduct } from 'services/nuclide/store/cyclotronProductSlice';
import { CyclotronProductCreateFormStep1 } from './CyclotronProductCreateFormStep1';
import { CyclotronProductCreateFormStep2 } from './CyclotronProductCreateFormStep2';

/**
 * The cyclotron product create form
 */
export const CyclotronProductCreateForm = (): JSX.Element => {

	// Consume the context
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const newCyclotronProductViewModel = useRef<CreatableCyclotronProductViewModel>({
		CalibrationDatetime: new Date(),
		DeliveryDate: new Date()
	} as CreatableCyclotronProductViewModel);

	const onChangeCyclotronProductProp = (prop: string, value: any) => {
		newCyclotronProductViewModel.current[prop] = value;
	};

	const handleCreate = (): void => {
		newCyclotronProductViewModel.current.Client = clientContext.selectedClientUuid;
		newCyclotronProductViewModel.current.Facility = facilityContext.selectedFacilityUuid;
		newCyclotronProductViewModel.current.AcceptedBy = authContext.getActor().Uuid;
		dispatch(createCyclotronProduct(newCyclotronProductViewModel.current));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const requiredInputs = ['Name', 'Manufacturer', 'BatchNumber', 'Isotope', 'Radiopharmaceutical', 'DeliveryDate', 'NominalActivity', 'ActivityUnit', 'CalibrationDatetime'];

	const goToStep2 = () => {
		let newCyclotronProductModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(newCyclotronProductViewModel.current?.[requiredInput] ?? null) === null
				|| newCyclotronProductViewModel.current?.[requiredInput] === ''
				|| String(newCyclotronProductViewModel.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newCyclotronProductModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newCyclotronProductModelValid);
	};

	const showCreateModalStep = showStep1 === true ?
		<CyclotronProductCreateFormStep1
			cyclotronProduct={newCyclotronProductViewModel.current}
			onClickNext={goToStep2}
			onChangeCyclotronProductProp={onChangeCyclotronProductProp}
			invalidInputs={invalidInputs}
		/> :
		<CyclotronProductCreateFormStep2
			cyclotronProduct={newCyclotronProductViewModel.current}
			onClickPrevious={goToStep1}
			onClickConfirm={handleCreate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
