import { ChangeEvent, useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { Label } from 'presentation/ui/partials/input/label/Label';
import { Textarea } from 'presentation/ui/partials/input/textarea/Textarea';

import { ValuedStringInput } from 'services/device/domain/business/common/Input';
import { ValuedString } from 'services/device/domain/business/common/misc/ValuedString';
import { Valuation } from 'services/device/domain/business/common/Valuation';

import './valued-string-repetable-input-renderer.scss';

export interface ValuedStringRepeatableInputProps {
	input: ValuedStringInput;
}

export const ValuedStringRepeatableInputRenderer = (props: ValuedStringRepeatableInputProps): JSX.Element => {

	const { input } = props;

	const l10nContext = useContext(L10nContext);

	const acceptableValueDisplayStrings = input.get()
		.filter((value): boolean => {
			return value.valuation === Valuation.ACCEPTABLE;
		})
		.map((value): string => {
			return value.value;
		});

	const unacceptableValueDisplayStrings = input.get()
		.filter((value): boolean => {
			return value.valuation === Valuation.UNACCEPTABLE;
		})
		.map((value): string => {
			return value.value;
		});

	const onChangeAcceptable = (event: ChangeEvent<HTMLTextAreaElement>): void => {
		const acceptableValues = getValuedStringsFromString(event.target.value, Valuation.ACCEPTABLE);
		const unacceptableValues = input.get()
			.filter((value): boolean => {
				return value.valuation === Valuation.UNACCEPTABLE;
			});
		onChange(acceptableValues, unacceptableValues);
	};

	const onChangeUnacceptable = (event: ChangeEvent<HTMLTextAreaElement>): void => {
		const acceptableValues = input.get()
			.filter((value): boolean => {
				return value.valuation === Valuation.ACCEPTABLE;
			});
		const unacceptableValues = getValuedStringsFromString(event.target.value, Valuation.UNACCEPTABLE);
		onChange(acceptableValues, unacceptableValues);
	};

	const onChange = (acceptableValues: Array<ValuedString>, unacceptableValues: Array<ValuedString>): void => {
		const valuedStrings = [...acceptableValues, ...unacceptableValues];
		const uniqueValuedStrings: Array<ValuedString> = [];
		const collectedValues: Array<string> = [];
		for (const valuedString of valuedStrings) {
			if (!collectedValues.includes(valuedString.value)) {
				uniqueValuedStrings.push(valuedString);
				collectedValues.push(valuedString.value);
			}
		}
		input.set(uniqueValuedStrings);
	};

	const getValuedStringsFromString = (string: string, valuation: Valuation): Array<ValuedString> => {
		return string
			.split('\n')
			.map((value): string => {
				return value.trim();
			})
			.filter((value): boolean => {
				return value.length > 0;
			})
			.map((value): ValuedString => {
				return { value, valuation } as ValuedString;
			});
	};

	return (
		<div className="valued-string-repetable-input">
			<div className="valued-string-repetable-input__section">
				<Label label={l10nContext.translate('common.inputs.options.acceptable')}>
					<Textarea
						name={input.getName()}
						defaultValue={acceptableValueDisplayStrings.join('\n')}
						required={!input.isOptional()}
						onChange={onChangeAcceptable}
					/>
				</Label>
			</div>
			<div className="valued-string-repetable-input__section">
				<Label label={l10nContext.translate('common.inputs.options.unacceptable')}>
					<Textarea
						name={input.getName()}
						defaultValue={unacceptableValueDisplayStrings.join('\n')}
						required={!input.isOptional()}
						onChange={onChangeUnacceptable}
					/>
				</Label>
			</div>
		</div>
	);

};
