import { ComponentPropsWithoutRef } from 'react';

import './root-layout.scss';

export type RootLayoutProps = ComponentPropsWithoutRef<'article'>;

export const RootLayout = (props: RootLayoutProps): JSX.Element => {

	return (
		<article className='root-layout'>
			{props.children}
		</article>
	);

};
