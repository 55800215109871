/* eslint-disable max-classes-per-file */
import { ChartDescription } from '../../common/description/ChartDescription';
import { DetailsDescription } from '../../common/description/DetailsDescription';
import {
	CommentFieldDescription,
	DateTimeFieldDescription,
	FieldDescription,
	IntervalFieldDescription,
	MailAddressFieldDescription,
	NumberFieldDescription,
	StringFieldDescription,
	TextFieldDescription,
	UnitFieldDescription,
	UrlFieldDescription
} from '../../common/description/FieldDescription';
import { TableDescription } from '../../common/description/TableDescription';
import { MemoryFieldSet } from '../../common/field-set/MemoryFieldSet';
import { RecordFieldSet } from '../../common/field-set/RecordFieldSet';
import { SequenceFieldSet } from '../../common/field-set/SequenceFieldSet';
import {
	CommentField,
	CommentFieldCalculation,
	DateTimeField,
	DateTimeFieldCalculation,
	IntervalField,
	IntervalFieldCalculation,
	MailAddressField,
	MailAddressFieldCalculation,
	NumberField,
	NumberFieldCalculation,
	StringField,
	StringFieldCalculation,
	TextField,
	TextFieldCalculation,
	UnitField,
	UnitFieldCalculation,
	UrlField,
	UrlFieldCalculation
} from '../../common/field/Field';
import { FieldType } from '../../common/field/FieldType';
import { MemoryStoreData } from '../../common/memory/MemoryData';
import { Action } from '../../common/misc/Action';
import { Environment } from '../../common/misc/Environment';
import {
	LookupTable,
	LookupTables
} from '../../common/misc/LookupTable';
import { Mode } from '../../common/misc/Mode';
import { Step } from '../../common/misc/Step';
import { Void } from '../../common/misc/Void';
import { AbstractRecordDefinition } from '../../common/record/AbstractRecordDefinition';
import { Record } from '../../common/record/Record';
import { RecordViewData } from '../../common/record/RecordData';
import { RecordDefinition } from '../../common/record/RecordDefinition';
import { AbstractSequenceDefinition } from '../../common/sequence/AbstractSequenceDefinition';
import { Sequence } from '../../common/sequence/Sequence';
import { SequenceViewData } from '../../common/sequence/SequenceData';
import { SequenceDefinition } from '../../common/sequence/SequenceDefinition';
import { SequenceTypeDefinitionProxy } from '../../common/sequence/SequenceTypeDefinitionProxy';
import { BandValuation } from '../../common/value/BandValuation';
import { Difference } from '../../common/value/Difference';
import { Fallback } from '../../common/value/Fallback';
import { IsNoticeable } from '../../common/value/IsNoticeable';
import { List } from '../../common/value/List';
import { Literal } from '../../common/value/Literal';
import { Lookup } from '../../common/value/Lookup';
import { Null } from '../../common/value/Null';
import { Product } from '../../common/value/Product';
import { Value } from '../../common/value/Value';
import { WorstValuation } from '../../common/value/WorstValuation';
import {
	InputWidget,
	OutputWidget,
	SummaryWidget
} from '../../common/widget/Widget';
import { DeviceType } from '../../inventory/DeviceType';
import { Interval } from '../../inventory/Interval';
import { SequenceType } from '../../inventory/SequenceType';
import { Unit } from '../../inventory/Unit';

type SequenceFieldDescriptions = {
	readonly name: StringFieldDescription;
	readonly ident: StringFieldDescription;
	readonly note: TextFieldDescription;
	readonly instructionUrl: UrlFieldDescription;
	readonly notificationMailAddresses: MailAddressFieldDescription;
	readonly possibleReminderIntervals: IntervalFieldDescription;
	readonly reminderInterval: IntervalFieldDescription;
	readonly possibleUnits: UnitFieldDescription;
	readonly unit: UnitFieldDescription;
	readonly referenceValue: NumberFieldDescription;
	readonly referenceDate: DateTimeFieldDescription;
};

type MemoryFieldDescriptions = Void;

type RecordFieldDescriptions = {
	readonly recordedAt: DateTimeFieldDescription;
	readonly upperToleranceValue: NumberFieldDescription;
	readonly upperReactionValue: NumberFieldDescription;
	readonly expectedValue: NumberFieldDescription;
	readonly value: NumberFieldDescription;
	readonly deviation: NumberFieldDescription;
	readonly comment: CommentFieldDescription;
};

type SequenceFieldCalculations = {
	readonly name: StringFieldCalculation<SequenceContext>;
	readonly ident: StringFieldCalculation<SequenceContext>;
	readonly note: TextFieldCalculation<SequenceContext>;
	readonly instructionUrl: UrlFieldCalculation<SequenceContext>;
	readonly notificationMailAddresses: MailAddressFieldCalculation<SequenceContext>;
	readonly possibleReminderIntervals: IntervalFieldCalculation<SequenceContext>;
	readonly reminderInterval: IntervalFieldCalculation<SequenceContext>;
	readonly possibleUnits: UnitFieldCalculation<SequenceContext>;
	readonly unit: UnitFieldCalculation<SequenceContext>;
	readonly referenceValue: NumberFieldCalculation<SequenceContext>;
	readonly referenceDate: DateTimeFieldCalculation<SequenceContext>;
};

type MemoryFieldCalculations = Void;

type RecordFieldCalculations = {
	readonly recordedAt: DateTimeFieldCalculation<RecordContext>;
	readonly upperToleranceValue: NumberFieldCalculation<RecordContext>;
	readonly upperReactionValue: NumberFieldCalculation<RecordContext>;
	readonly expectedValue: NumberFieldCalculation<RecordContext>;
	readonly value: NumberFieldCalculation<RecordContext>;
	readonly deviation: NumberFieldCalculation<RecordContext>;
	readonly comment: CommentFieldCalculation<RecordContext>;
};

type SequenceFields = {
	readonly name: StringField<SequenceContext>;
	readonly ident: StringField<SequenceContext>;
	readonly note: TextField<SequenceContext>;
	readonly instructionUrl: UrlField<SequenceContext>;
	readonly notificationMailAddresses: MailAddressField<SequenceContext>;
	readonly possibleReminderIntervals: IntervalField<SequenceContext>;
	readonly reminderInterval: IntervalField<SequenceContext>;
	readonly possibleUnits: UnitField<SequenceContext>;
	readonly unit: UnitField<SequenceContext>;
	readonly referenceValue: NumberField<SequenceContext>;
	readonly referenceDate: DateTimeField<SequenceContext>;
};

type MemoryFields = Void;

type RecordFields = {
	readonly recordedAt: DateTimeField<RecordContext>;
	readonly upperToleranceValue: NumberField<RecordContext>;
	readonly upperReactionValue: NumberField<RecordContext>;
	readonly expectedValue: NumberField<RecordContext>;
	readonly value: NumberField<RecordContext>;
	readonly deviation: NumberField<RecordContext>;
	readonly comment: CommentField<RecordContext>;
};

type SequenceContext = {
	readonly mode: Value<Mode>;
	readonly dateTime: Value<Date>;
	readonly deviceType: Value<DeviceType>;
	readonly sequenceFields: SequenceFields;
};

type MemoryContext = Void;

type RecordContext = {
	readonly mode: Value<Mode>;
	readonly dateTime: Value<Date>;
	readonly deviceType: Value<DeviceType>;
	readonly sequenceFields: SequenceFields;
	readonly memoryFields: MemoryFields;
	readonly recordFields: RecordFields;
};

const SEQUENCE_FIELD_DESCRIPTIONS: SequenceFieldDescriptions = {
	name: {
		type: FieldType.STRING,
		name: 'name',
		labelKey: 'inventory.field.name',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	ident: {
		type: FieldType.STRING,
		name: 'ident',
		labelKey: 'inventory.field.ident',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	note: {
		type: FieldType.TEXT,
		name: 'note',
		labelKey: 'inventory.field.note',
		repeatable: false,
		mandatory: false,
		valued: false,
		stored: true
	},
	instructionUrl: {
		type: FieldType.URL,
		name: 'instructionUrl',
		labelKey: 'inventory.field.instructionUrl',
		repeatable: false,
		mandatory: false,
		valued: false,
		stored: true
	},
	notificationMailAddresses: {
		type: FieldType.MAIL_ADDRESS,
		name: 'notificationMailAddresses',
		labelKey: 'inventory.field.notificationMailAddresses',
		repeatable: true,
		mandatory: false,
		valued: false,
		stored: true
	},
	possibleReminderIntervals: {
		type: FieldType.INTERVAL,
		name: 'possibleReminderIntervals',
		labelKey: 'inventory.field.possibleReminderIntervals',
		repeatable: true,
		mandatory: true,
		valued: false,
		stored: false
	},
	reminderInterval: {
		type: FieldType.INTERVAL,
		name: 'reminderInterval',
		labelKey: 'inventory.field.reminderInterval',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	possibleUnits: {
		type: FieldType.UNIT,
		name: 'possibleUnits',
		labelKey: 'inventory.field.possibleUnits',
		repeatable: true,
		mandatory: true,
		valued: false,
		stored: false
	},
	unit: {
		type: FieldType.UNIT,
		name: 'unit',
		labelKey: 'inventory.field.unit',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	referenceValue: {
		type: FieldType.NUMBER,
		name: 'referenceValue',
		labelKey: 'inventory.field.referenceValue',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true,
		numberOfDecimals: 2
	},
	referenceDate: {
		type: FieldType.DATE_TIME,
		name: 'referenceDate',
		labelKey: 'inventory.field.referenceDate',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	}
};

const MEMORY_FIELD_DESCRIPTIONS: MemoryFieldDescriptions = {};

const RECORD_FIELD_DESCRIPTIONS: RecordFieldDescriptions = {
	recordedAt: {
		type: FieldType.DATE_TIME,
		name: 'recordedAt',
		labelKey: 'inventory.field.recordedAt',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true
	},
	upperToleranceValue: {
		type: FieldType.NUMBER,
		name: 'upperToleranceValue',
		labelKey: 'inventory.field.upperToleranceValue',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: false,
		numberOfDecimals: 2,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	upperReactionValue: {
		type: FieldType.NUMBER,
		name: 'upperReactionValue',
		labelKey: 'inventory.field.upperReactionValue',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: false,
		numberOfDecimals: 2,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	expectedValue: {
		type: FieldType.NUMBER,
		name: 'expectedValue',
		labelKey: 'inventory.field.expectedValue',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: false,
		numberOfDecimals: 2,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	value: {
		type: FieldType.NUMBER,
		name: 'value',
		labelKey: 'inventory.field.value',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: true,
		numberOfDecimals: 2,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	deviation: {
		type: FieldType.NUMBER,
		name: 'deviation',
		labelKey: 'inventory.field.deviation',
		repeatable: false,
		mandatory: true,
		valued: false,
		stored: false,
		numberOfDecimals: 2,
		unit: SEQUENCE_FIELD_DESCRIPTIONS.unit
	},
	comment: {
		type: FieldType.COMMENT,
		name: 'comment',
		labelKey: 'inventory.field.comment',
		repeatable: false,
		mandatory: false,
		valued: false,
		stored: true
	}
};

const DETAILS_DESCRIPTION: DetailsDescription = {
	entries: [
		SEQUENCE_FIELD_DESCRIPTIONS.ident,
		SEQUENCE_FIELD_DESCRIPTIONS.note,
		SEQUENCE_FIELD_DESCRIPTIONS.unit,
		SEQUENCE_FIELD_DESCRIPTIONS.referenceValue,
		SEQUENCE_FIELD_DESCRIPTIONS.referenceDate,
		SEQUENCE_FIELD_DESCRIPTIONS.reminderInterval,
		SEQUENCE_FIELD_DESCRIPTIONS.notificationMailAddresses
	],
	showInitialValue: false
};

const CHART_DESCRIPTIONS: ReadonlyArray<ChartDescription> = [
	{
		name: 'value',
		labelKey: 'inventory.chart.value',
		value: RECORD_FIELD_DESCRIPTIONS.value,
		deviation: RECORD_FIELD_DESCRIPTIONS.deviation,
		expectedValue: RECORD_FIELD_DESCRIPTIONS.expectedValue,
		upperToleranceValue: RECORD_FIELD_DESCRIPTIONS.upperToleranceValue,
		upperReactionValue: RECORD_FIELD_DESCRIPTIONS.upperReactionValue,
		comment: RECORD_FIELD_DESCRIPTIONS.comment
	}
];

const TABLE_DESCRIPTIONS: ReadonlyArray<TableDescription> = [
	{
		name: 'value',
		labelKey: 'inventory.table.value',
		value: RECORD_FIELD_DESCRIPTIONS.value,
		deviation: RECORD_FIELD_DESCRIPTIONS.deviation,
		expectedValue: RECORD_FIELD_DESCRIPTIONS.expectedValue,
		upperToleranceValue: RECORD_FIELD_DESCRIPTIONS.upperToleranceValue,
		upperReactionValue: RECORD_FIELD_DESCRIPTIONS.upperReactionValue,
		comment: RECORD_FIELD_DESCRIPTIONS.comment
	}
];

const LOOKUP_TABLES: LookupTables = {
	possibleReminderIntervalsByDeviceType: new LookupTable<DeviceType, Interval>(
		[],
		new List<Interval>(
			[
				Interval.NONE,
				Interval.DAILY,
				Interval.WEEKLY,
				Interval.TWO_WEEKLY,
				Interval.MONTHLY,
				Interval.QUARTER_YEARLY,
				Interval.HALF_YEARLY,
				Interval.LATCHED_MONTHLY,
				Interval.LATCHED_QUARTER_YEARLY,
				Interval.LATCHED_HALF_YEARLY
			]
		)
	),
	possibleUnitsByDeviceType: new LookupTable<DeviceType, Unit>(
		[
			[
				DeviceType.GAMMA_CAMERA_PLANAR,
				new List<Unit>(
					[
						Unit.COUNTS_PER_SECOND,
						Unit.KILO_COUNTS_PER_SECOND,
						Unit.COUNTS_PER_MINUTE,
						Unit.COUNTS_PER_5_MINUTES,
						Unit.ABSOLUTE_COUNT,
						Unit.ABSOLUTE_KILO_COUNT
					]
				)
			],
			[
				DeviceType.GAMMA_CAMERA_FOR_SPECT,
				new List<Unit>(
					[
						Unit.COUNTS_PER_SECOND,
						Unit.KILO_COUNTS_PER_SECOND,
						Unit.COUNTS_PER_MINUTE,
						Unit.COUNTS_PER_THREE_MINUTES,
						Unit.COUNTS_PER_5_MINUTES,
						Unit.KILO_COUNTS_PER_MINUTE,
						Unit.KILO_COUNTS_PER_THREE_MINUTES,
						Unit.ABSOLUTE_COUNT,
						Unit.ABSOLUTE_KILO_COUNT
					]
				)
			],
			[
				DeviceType.SPECT_CT,
				new List<Unit>(
					[
						Unit.COUNTS_PER_SECOND,
						Unit.KILO_COUNTS_PER_SECOND,
						Unit.COUNTS_PER_MINUTE,
						Unit.COUNTS_PER_THREE_MINUTES,
						Unit.COUNTS_PER_5_MINUTES,
						Unit.KILO_COUNTS_PER_MINUTE,
						Unit.KILO_COUNTS_PER_THREE_MINUTES
					]
				)
			]
		],
		new List<Unit>(
			[]
		)
	)
};

const SEQUENCE_FIELD_CALCULATIONS: SequenceFieldCalculations = {
	name: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.name
	},
	ident: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.ident
	},
	note: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.note
	},
	instructionUrl: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.instructionUrl
	},
	notificationMailAddresses: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.notificationMailAddresses
	},
	possibleReminderIntervals: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.possibleReminderIntervals,
		value: (context: SequenceContext) => {
			return new Lookup<DeviceType, Interval>(
				LOOKUP_TABLES.possibleReminderIntervalsByDeviceType,
				context.deviceType
			);
		}
	},
	reminderInterval: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.reminderInterval,
		options: (context: SequenceContext) => {
			return context.sequenceFields.possibleReminderIntervals;
		}
	},
	possibleUnits: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.possibleUnits,
		value: (context: SequenceContext) => {
			return new Lookup<DeviceType, Unit>(
				LOOKUP_TABLES.possibleUnitsByDeviceType,
				context.deviceType
			);
		}
	},
	unit: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.unit,
		options: (context: SequenceContext) => {
			return context.sequenceFields.possibleUnits;
		}
	},
	referenceValue: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.referenceValue
	},
	referenceDate: {
		description: SEQUENCE_FIELD_DESCRIPTIONS.referenceDate,
		value: (context: SequenceContext) => {
			return new Fallback(
				context.sequenceFields.referenceDate,
				context.dateTime
			);
		}
	}
};

const MEMORY_FIELD_CALCULATIONS: MemoryFieldCalculations = {};

const RECORD_FIELD_CALCULATIONS: RecordFieldCalculations = {
	recordedAt: {
		description: RECORD_FIELD_DESCRIPTIONS.recordedAt,
		value: (context: RecordContext) => {
			return new Fallback(
				context.recordFields.recordedAt,
				context.dateTime
			);
		}
	},
	upperToleranceValue: {
		description: RECORD_FIELD_DESCRIPTIONS.upperToleranceValue,
		value: (context: RecordContext) => {
			return new Product(
				new Literal<number>(
					1.5
				),
				context.sequenceFields.referenceValue
			);
		}
	},
	upperReactionValue: {
		description: RECORD_FIELD_DESCRIPTIONS.upperReactionValue,
		value: (context: RecordContext) => {
			return new Product(
				new Literal<number>(
					1.2
				),
				context.sequenceFields.referenceValue
			);
		}
	},
	expectedValue: {
		description: RECORD_FIELD_DESCRIPTIONS.expectedValue,
		value: (context: RecordContext) => {
			return context.sequenceFields.referenceValue;
		}
	},
	value: {
		description: RECORD_FIELD_DESCRIPTIONS.value,
		valuation: (context: RecordContext) => {
			return new BandValuation(
				context.recordFields.upperToleranceValue,
				context.recordFields.upperReactionValue,
				context.recordFields.value,
				new Null<number>(),
				new Null<number>()
			);
		}
	},
	deviation: {
		description: RECORD_FIELD_DESCRIPTIONS.deviation,
		value: (context: RecordContext) => {
			return new Difference(
				context.recordFields.value,
				context.recordFields.expectedValue
			);
		}
	},
	comment: {
		description: RECORD_FIELD_DESCRIPTIONS.comment,
		mandatory: (context: RecordContext) => {
			return new IsNoticeable(
				new WorstValuation(
					context.recordFields.value
				)
			);
		}
	}
};

const SEQUENCE_STEPS: ReadonlyArray<Step<SequenceContext, SequenceFields>> = [
	{
		instructions: (fields: SequenceFields) => {
			return {
				widgets: [
					new InputWidget(
						[
							fields.name,
							fields.ident,
							fields.note,
							fields.instructionUrl
						]
					)
				]
			};
		}
	},
	{
		filter: (environment: Environment) => {
			return environment.action === Action.CREATE || environment.mode === Mode.ADMIN;
		},
		instructions: (fields: SequenceFields) => {
			return {
				calculations: [
					fields.possibleUnits,
					fields.referenceDate
				],
				widgets: [
					new InputWidget(
						[
							fields.unit,
							fields.referenceValue,
							fields.referenceDate
						]
					)
				]
			};
		}
	},
	{
		instructions: (fields: SequenceFields) => {
			return {
				calculations: [
					fields.possibleReminderIntervals
				],
				widgets: [
					new InputWidget(
						[
							fields.notificationMailAddresses,
							fields.reminderInterval
						]
					)
				]
			};
		}
	},
	{
		instructions: (fields: SequenceFields) => {
			return {
				widgets: [
					new SummaryWidget(
						[
							fields.name,
							fields.ident,
							fields.note,
							fields.instructionUrl,
							fields.unit,
							fields.referenceValue,
							fields.referenceDate,
							fields.notificationMailAddresses,
							fields.reminderInterval
						]
					)
				]
			};
		}
	}
];

const RECORD_STEPS: ReadonlyArray<Step<RecordContext, RecordFields>> = [
	{
		filter: (environment: Environment) => {
			return environment.mode === Mode.ADMIN;
		},
		instructions: (fields: RecordFields) => {
			return {
				calculations: [
					fields.recordedAt
				],
				widgets: [
					new InputWidget(
						[
							fields.recordedAt
						]
					)
				]
			};
		}
	},
	{
		instructions: (fields: RecordFields) => {
			return {
				calculations: [
					fields.recordedAt,
					fields.upperToleranceValue,
					fields.upperReactionValue,
					fields.expectedValue
				],
				widgets: [
					new OutputWidget(
						[
							fields.upperToleranceValue,
							fields.upperReactionValue,
							fields.expectedValue
						]
					),
					new InputWidget(
						[
							fields.value
						]
					)
				]
			};
		}
	},
	{
		instructions: (fields: RecordFields) => {
			return {
				calculations: [
					fields.deviation
				],
				widgets: [
					new SummaryWidget(
						[
							fields.upperToleranceValue,
							fields.upperReactionValue,
							fields.value
						]
					),
					new InputWidget(
						[
							fields.comment
						]
					)
				]
			};
		}
	}
];

function createSequenceFields(): SequenceFields {
	return {
		name: new StringField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.name
		),
		ident: new StringField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.ident
		),
		note: new TextField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.note
		),
		instructionUrl: new UrlField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.instructionUrl
		),
		notificationMailAddresses: new MailAddressField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.notificationMailAddresses
		),
		possibleReminderIntervals: new IntervalField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.possibleReminderIntervals
		),
		reminderInterval: new IntervalField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.reminderInterval
		),
		possibleUnits: new UnitField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.possibleUnits
		),
		unit: new UnitField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.unit
		),
		referenceValue: new NumberField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.referenceValue
		),
		referenceDate: new DateTimeField<SequenceContext>(
			SEQUENCE_FIELD_CALCULATIONS.referenceDate
		)
	};
}

function createMemoryFields(): MemoryFields {
	return {};
}

function createRecordFields(): RecordFields {
	return {
		recordedAt: new DateTimeField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.recordedAt
		),
		upperToleranceValue: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.upperToleranceValue
		),
		upperReactionValue: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.upperReactionValue
		),
		expectedValue: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.expectedValue
		),
		value: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.value
		),
		deviation: new NumberField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.deviation
		),
		comment: new CommentField<RecordContext>(
			RECORD_FIELD_CALCULATIONS.comment
		)
	};
}

class ActualSequenceDefinition extends AbstractSequenceDefinition<SequenceContext, SequenceFields, SequenceFieldSet<SequenceFields>> {
	public getSequenceType(): SequenceType {
		return SequenceType.BACKGROUND_COUNT_COBALT;
	}

	protected getSteps(): ReadonlyArray<Step<SequenceContext, SequenceFields>> {
		return SEQUENCE_STEPS;
	}

	protected createContext(environment: Environment, sequenceFields: SequenceFields): SequenceContext {
		return {
			mode: new Literal<Mode>(environment.mode),
			dateTime: new Literal<Date>(environment.dateTime),
			deviceType: new Literal<DeviceType>(environment.deviceType),
			sequenceFields
		};
	}

	protected createSequenceFieldSet(): SequenceFieldSet<SequenceFields> {
		return new SequenceFieldSet(
			() => {
				return createSequenceFields();
			}
		);
	}

}

class ActualRecordDefinition extends AbstractRecordDefinition<RecordContext, RecordFields, RecordFieldSet<RecordFields>, SequenceFields, MemoryFields> {
	public getSequenceType(): SequenceType {
		return SequenceType.BACKGROUND_COUNT_COBALT;
	}

	protected getSteps(): ReadonlyArray<Step<RecordContext, RecordFields>> {
		return RECORD_STEPS;
	}

	protected createRecordContext(environment: Environment, recordFields: RecordFields): RecordContext {
		return {
			mode: new Literal<Mode>(environment.mode),
			dateTime: new Literal<Date>(environment.dateTime),
			deviceType: new Literal<DeviceType>(environment.deviceType),
			sequenceFields: this.getSequenceFieldSet().getRawFields(),
			memoryFields: this.getMemoryFieldSet().getRawFields(),
			recordFields
		};
	}

	protected createRecordFieldSet(): RecordFieldSet<RecordFields> {
		return new RecordFieldSet(
			() => {
				return createRecordFields();
			},
			RECORD_FIELD_DESCRIPTIONS.value.name
		);
	}

}

export class BackgroundCountCobaltSequenceTypeDefinitionProxy implements SequenceTypeDefinitionProxy {
	public getValueDescription(): FieldDescription {
		return RECORD_FIELD_DESCRIPTIONS.value;
	}

	public getDetailsDescription(): DetailsDescription {
		return DETAILS_DESCRIPTION;
	}

	public getChartDescriptions(): ReadonlyArray<ChartDescription> {
		return CHART_DESCRIPTIONS;
	}

	public getTableDescriptions(): ReadonlyArray<TableDescription> {
		return TABLE_DESCRIPTIONS;
	}

	public getCommentDescription(): CommentFieldDescription {
		return RECORD_FIELD_DESCRIPTIONS.comment;
	}

	public getSequenceDefinition(deviceType: DeviceType): SequenceDefinition {
		return new ActualSequenceDefinition(deviceType);
	}

	public getRecordDefinition(sequence: Sequence, memoryStoreData: MemoryStoreData): RecordDefinition {
		const memoryFieldSet = this.createMemoryFieldSet().fromStoreData(memoryStoreData);
		return new ActualRecordDefinition(sequence.getDeviceType(), sequence.getFieldSet(), memoryFieldSet);
	}

	private createMemoryFieldSet(): MemoryFieldSet<MemoryFields> {
		return new MemoryFieldSet(
			() => {
				return createMemoryFields();
			}
		);
	}

	public restoreSequenceFromViewData(sequenceViewData: SequenceViewData): Sequence {
		const fields = new SequenceFieldSet(() => createSequenceFields()).fromViewData(sequenceViewData);
		return new Sequence(sequenceViewData.deviceType, SequenceType.BACKGROUND_COUNT_COBALT, fields);
	}

	public restoreRecordFromViewData(recordViewData: RecordViewData): Record {
		const fields = new RecordFieldSet(() => createRecordFields(), this.getValueDescription().name).fromViewData(recordViewData);
		return new Record(recordViewData.deviceType, SequenceType.BACKGROUND_COUNT_COBALT, fields, recordViewData.valuation);
	}

}
