import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ClientContext } from 'services/core/context/ClientContext';
import { InventoryFactory } from 'services/device/domain/business/util/InventoryFactory';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';

export interface DeviceCreateModalStep1Props {
	/**
	 * Device
	 */
	device: { [key: string]: string };
	/**
	 * Button next action
	 */
	onClickNext: () => void;
	/**
	 * Invalid inputs
	 */
	invalidInputs?: string[];
	/**
	 * Change device props
	 */
	onChangeDeviceProp: (prop: string, value: string) => void;
}

/**
 * The device create modal step 1
 */
export const DeviceCreateFormStep1 = (props: DeviceCreateModalStep1Props): JSX.Element => {
	const { device, invalidInputs = [], onChangeDeviceProp, onClickNext } = props;

	// Consume the contexts
	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);

	// Get device types
	const productGroups = clientContext.permittedProductGroups();
	const inventory = InventoryFactory.createInvetory(productGroups);
	const deviceTypeDefinitions = inventory.getDeviceTypeDefinitions();
	const deviceTypes = deviceTypeDefinitions.map((deviceTypeDefinition): SelectOption => {
		return {
			label: l10nContext.translate(deviceTypeDefinition.getLabelKey()),
			value: deviceTypeDefinition.getDeviceType(),
			checked: deviceTypeDefinition.getDeviceType() === device?.Type ?? false,
		};
	});

	// Notification for invalid input fields
	const checkPlural = invalidInputs.length > 1 ? l10nContext.translate('common.modal.notificationErrorPlural', ' dürfen nicht leer sein') : l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein');
	let notification = null;
	if (invalidInputs.length > 0) {
		const invalidNotificationMessage = invalidInputs.join(l10nContext.translate('common.text.and', ' und '))
			+ checkPlural;
		notification = (
			<NotificationBar
				level={NotificationLevel.LEVEL_ERROR}
				message={invalidNotificationMessage}
			/>
		);
	}

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<NotificationBars>
				{notification}
			</NotificationBars>
			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('devices.detail.name', 'Name')}*`}
				>
					<InputText
						type="text"
						defaultValue={device?.Name ?? ''}
						onChange={(event) => onChangeDeviceProp('Name', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('devices.detail.type', 'Typ')}*`}
				>
					<Select
						options={deviceTypes}
						onchange={(optionValue: string) => onChangeDeviceProp('Type', optionValue)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('devices.detail.ident', 'Ident')}
				>
					<InputText
						type="text"
						defaultValue={device?.Identifier ?? ''}
						onChange={(event) => onChangeDeviceProp('Identifier', event.target.value)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('devices.detail.serialNumber', 'Seriennummer')}
				>
					<InputText
						type="text"
						defaultValue={device?.SerialNumber ?? ''}
						onChange={(event) => onChangeDeviceProp('SerialNumber', event.target.value)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={l10nContext.translate('devices.detail.locationDescription', 'Ortsbeschreibung')}
				>
					<InputText
						type="text"
						defaultValue={device?.Location ?? ''}
						onChange={(event) => onChangeDeviceProp('Location', event.target.value)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
