import { Guid } from 'lib/guid/Guid';
import { useContext } from 'react';

import 'presentation/ui/components/cards/card-maintenance-log/card-maintenance-log-list/card-maintenance-log-list.scss';
import { Checkbox } from 'presentation/ui/partials/input/checkbox/Checkbox';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

import { ReportViewModel } from 'services/report/domain/model/ReportModel';
import {
	CardReportSequenceList
} from '../../../../../presentation/ui/components/cards/card-report/card-report-sequence-list/CardReportSequenceList';
import { AuthContext } from '../../../../core/context/AuthContext';
import { Permission } from '../../../../core/lib/auth/AuthService';

import './report-sequence-entry.scss';

export interface ReportSequenceEntryProps {
	sequence?: SequenceViewModel,
	isSequenceSelected: boolean,
	sequenceStartDate: Date,
	sequenceEndDate: Date,
	checkSequence: (uuid: string) => void,
	onSequenceStartDateChange: (uuid: string, date: Date) => void,
	onSequenceEndDateChange: (uuid: string, date: Date) => void,
	report: ReportViewModel
}

export const ReportSequenceEntry = (props: ReportSequenceEntryProps): JSX.Element => {
	const {
		sequence,
		sequenceStartDate,
		sequenceEndDate,
		report,
		isSequenceSelected,
		checkSequence,
		onSequenceStartDateChange,
		onSequenceEndDateChange
	} = props;

	const authContext = useContext(AuthContext);

	const showSequenceEntry = () => {

		if (sequence) {
			return (
				<div className='report-sequence-entry'>
					<Checkbox
						id={sequence.Uuid}
						checked={isSequenceSelected}
						onChange={() => checkSequence(sequence.Uuid)}
						disabled={!authContext.hasPermission(Permission.REPORT_UPDATE)}
					/>
					<CardReportSequenceList
						key={Guid.generate()}
						cardId={`${Math.random()}${document}`}
						sequence={sequence}
						report={report}
						sequenceStartDate={sequenceStartDate}
						sequenceEndDate={sequenceEndDate}
						onSequenceStartDateChange={onSequenceStartDateChange}
						onSequenceEndDateChange={onSequenceEndDateChange}
						isSequenceSelected={isSequenceSelected}
					/>
				</div>
			);
		}
		return null;
	};
	return (
		<>
			{showSequenceEntry()}
		</>
	);
};
