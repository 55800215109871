import { Optional } from '../misc/Optional';
import { Valuation } from '../Valuation';
import { Value } from './Value';

export class IsNoticeable implements Value<boolean> {
	private readonly result: Optional<boolean>;

	public constructor(value: Value<Valuation>) {
		this.result = calculate(value.getSingle());
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<boolean> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<boolean> {
		return this.result;
	}
}

function calculate(value: Optional<Valuation>): Optional<boolean> {
	if (value !== null) {
		return value === Valuation.NOTICEABLE ||  value ===	Valuation.UNACCEPTABLE;
	}
	return null;
}
