import { Route as RouteComponent, Switch } from 'react-router-dom';

import { Route } from 'router/Route';
import { ScrollToTop } from 'router/ScrollToTop';

import { ReportDevicesView } from 'services/report/presentation/view/ReportDevicesView';
import { ReportDeviceView } from 'services/report/presentation/view/ReportDeviceView';
import { ReportSequenceView } from 'services/report/presentation/view/ReportSequenceView';
import { ExternalPageErrorView } from '../presentation/view/ExternalPageErrorView';

export const RouterExternalConfig = (): JSX.Element => {

	return (
		<>
			<ScrollToTop />
			<Switch>
				<RouteComponent exact path={`${Route.EXTERNAL_REPORT}/:reportUuid`}>
					<ReportDevicesView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.EXTERNAL_REPORT}/:reportUuid${Route.DEVICES}`}>
					<ReportDevicesView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.EXTERNAL_REPORT}/:reportUuid${Route.DEVICES}/:deviceUuid`}>
					<ReportDeviceView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.EXTERNAL_REPORT}/:reportUuid${Route.DEVICES}/:deviceUuid${Route.SEQUENCES}`}>
					<ReportDeviceView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.EXTERNAL_REPORT}/:reportUuid${Route.DEVICES}/:deviceUuid${Route.SEQUENCES}/:sequenceUuid`}>
					<ReportSequenceView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.EXTERNAL_REPORT}/:reportUuid${Route.CLEARANCES}`}>
					<ReportDevicesView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.EXTERNAL_REPORT}/:reportUuid${Route.CLEARANCES}/:deviceUuid`}>
					<ReportDeviceView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.EXTERNAL_REPORT}/:reportUuid${Route.CLEARANCES}/:deviceUuid${Route.MEASUREMENT_GOALS}`}>
					<ReportDeviceView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.EXTERNAL_REPORT}/:reportUuid${Route.CLEARANCES}/:deviceUuid${Route.MEASUREMENT_GOALS}/:sequenceUuid`}>
					<ReportSequenceView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.EXTERNAL_REPORT}/*`}>
					<ExternalPageErrorView />
				</RouteComponent>
			</Switch>
		</>
	);

};
