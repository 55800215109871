import { ComponentPropsWithoutRef } from 'react';

export type ExpandableBodyProps = ComponentPropsWithoutRef<'main'>;

export const ExpandableBody = (props: ExpandableBodyProps): JSX.Element => {

	const { ...restProps } = props;

	return (
		<main className='expandable__body' {...restProps}>
			{props.children}
		</main>
	);

};
