import { L10nContext } from 'context/L10nContext';

import { Guid } from 'lib/guid/Guid';

import { CardDocument } from 'presentation/ui/components/cards/card-document/card-document/CardDocument';
import { CardItemFileSize } from 'presentation/ui/partials/card/card-item-file-size/CardItemFileSize';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { CardItemState } from 'presentation/ui/partials/card/card-item-state/CardItemState';
import { useContext } from 'react';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';

import { DocumentModelState } from 'services/documents/domain/model/DocumentModelState';
import { ReportDocumentViewModel } from 'services/report/domain/model/ReportDocumentModel';
import { NetworkContext } from '../../../../../context/NetworkContext';
import { CardItemActionType } from '../../../../../presentation/ui/partials/card/card-item-controls/CardItemActionTypes';
import { CardItemControlActionType } from '../../../../../presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { IconIdentifier } from '../../../../../presentation/ui/partials/icon/IconIdentifier';
import { Checkbox } from '../../../../../presentation/ui/partials/input/checkbox/Checkbox';
import { AuthContext } from '../../../../core/context/AuthContext';
import { Permission } from '../../../../core/lib/auth/AuthService';

import './report-document-entry.scss';

export interface ReportDocumentEntryProps {
	documentEntry?: DocumentViewModel,
	reportDocument?: ReportDocumentViewModel,
	isDocumentSelected?: boolean,
	checkDocument?: (uuid: string) => void;
	onActionClick?: (actionPayload: CardItemControlActionType, documentEntry: DocumentViewModel) => void;
}

export const ReportDocumentEntry = (props: ReportDocumentEntryProps): JSX.Element => {

	const { documentEntry, isDocumentSelected, checkDocument, onActionClick } = props;

	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);
	const networkContext = useContext(NetworkContext);

	const onCardItemControlClick = (action: CardItemControlActionType, document: DocumentViewModel) => {
		action.type === CardItemActionType.ARCHIVE && documentEntry.State === DocumentModelState.ARCHIVED ?
			onActionClick({
				...action,
				type: CardItemActionType.UNARCHIVE
			}, document) :
			onActionClick(action, document);
	};

	const formatBytes = (number: number): string => {
		const k = 1024;
		const dm = 0;
		const sizes = ['Bytes', 'KB', 'MB', 'GB'];
		const i = Math.floor(Math.log(number) / Math.log(k));

		return parseFloat((number / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};

	const showDocumentEntry = () => {
		if (documentEntry) {
			const formatedDocumentDate = l10nContext.formatDateTime(documentEntry.CreatedAt);
			const formatedFileExtension = documentEntry.Filename.split('.').pop().toUpperCase() + ' (' + formatBytes(documentEntry.FileInfo.Filesize) + ')';

			const showDownloadButton = onActionClick ?
				<CardDocument
					key={Guid.generate()}
					cardId={`${Math.random()}${document}`}
					button={{
						icon: IconIdentifier.DOWNLOAD,
						disabled: !networkContext.online,
						onClick: () => onCardItemControlClick({
							type: CardItemActionType.DOWNLOAD,
							cardId: ''
						}, documentEntry)
					}}
				>
					<CardItemHeader
						title={documentEntry.Filename}
						archived={documentEntry.State === DocumentModelState.ARCHIVED}
					/>

					<CardItemState date={formatedDocumentDate}>
						<CardItemFileSize label={formatedFileExtension} />
					</CardItemState>
				</CardDocument> :

				<CardDocument
					key={Guid.generate()}
					cardId={`${Math.random()}${document}`}
				>
					<CardItemHeader
						title={documentEntry.Filename}
						archived={documentEntry.State === DocumentModelState.ARCHIVED}
					/>

					<CardItemState date={formatedDocumentDate}>
						<CardItemFileSize label={formatedFileExtension} />
					</CardItemState>
				</CardDocument>;

			return (
				<div className='report-document-entry'>
					<Checkbox
						id={documentEntry.Uuid}
						checked={isDocumentSelected}
						onChange={() => checkDocument(documentEntry.Uuid)}
						isReportSelection
						disabled={!authContext.hasPermission(Permission.REPORT_UPDATE)}
					/>
					{showDownloadButton}
				</div>
			);
		}
		return null;
	};

	return (
		<>
			{showDocumentEntry()}
		</>
	);
};
