import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from '../../../../lib/domain/model/Model';
import { ModelConverter } from '../../../../lib/domain/model/ModelConverter';

export interface WasteManagementReportPersistenceModel extends PersistenceModel {
	Name: string;
	PeriodDateStart: number;
	PeriodDateEnd: number;
	ValidUntil: number;
	Password?: string;
	WasteManagementReportGenerators: Array<string>;
	WasteManagementReportCyclotronProducts: Array<string>;
}

export interface WasteManagementReportStoreModel extends StoreModel {
	Name: string;
	PeriodDateStart: number;
	PeriodDateEnd: number;
	ValidUntil: number;
	Password?: string;
	WasteManagementReportGenerators: Array<string>;
	WasteManagementReportCyclotronProducts: Array<string>;
}

export interface WasteManagementReportViewModel extends ViewModel {
	Name: string;
	PeriodDateStart: Date;
	PeriodDateEnd: Date;
	ValidUntil: Date;
	Password: string;
	WasteManagementReportGenerators: Array<string>;
	WasteManagementReportCyclotronProducts: Array<string>;
}

export interface CreatableWasteManagementReportViewModel extends CreatableViewModel {
	Name: string;
	PeriodDateStart: Date;
	PeriodDateEnd: Date;
	ValidUntil: Date;
	Password: string;
	WasteManagementReportGenerators: Array<string>;
	WasteManagementReportCyclotronProducts: Array<string>;
}

export class WasteManagementReportModelConverter
	extends ModelConverter<WasteManagementReportPersistenceModel,
	WasteManagementReportStoreModel,
	WasteManagementReportViewModel,
	CreatableWasteManagementReportViewModel> {

	fromStoreModel(storeModel?: WasteManagementReportStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			model.PeriodDateStart = Math.floor(sModel.PeriodDateStart / 1000);
			model.PeriodDateEnd = Math.floor(sModel.PeriodDateEnd / 1000);
			model.ValidUntil = Math.floor(sModel.ValidUntil / 1000);
		});
	}

	fromViewModel(viewModel?: WasteManagementReportViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.PeriodDateStart = Math.floor(vModel.PeriodDateStart.getTime() / 1000);
			model.PeriodDateEnd = Math.floor(vModel.PeriodDateEnd.getTime() / 1000);
			model.ValidUntil = Math.floor(vModel.ValidUntil.getTime() / 1000);
		});
	}

	toStoreModel(): WasteManagementReportStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.PeriodDateStart = model.PeriodDateStart * 1000;
			sModel.PeriodDateEnd = model.PeriodDateEnd * 1000;
			sModel.ValidUntil = model.ValidUntil * 1000;
		});
	}

	toViewModel(): WasteManagementReportViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.PeriodDateStart = new Date(model.PeriodDateStart * 1000);
			vModel.PeriodDateEnd = new Date(model.PeriodDateEnd * 1000);
			vModel.ValidUntil = new Date(model.ValidUntil * 1000);
		});
	}
}
