import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';

export interface SequenceCreateSuccessNotificationProps {
	onClickConfirm: () => void;
	sequenceCreated?: SequenceViewModel | null;
}

export const SequenceCreateSuccessNotification = (props: SequenceCreateSuccessNotificationProps): JSX.Element => {
	const { onClickConfirm, sequenceCreated } = props;

	const l10nContext = useContext(L10nContext);
	const history = useHistory();

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.device.modal.action.createSequence.successMessage', 'Die Messreihe wurde erfolgreich angelegt')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createSequence.btnDeviceDetails', 'Weiter zu den Messreihendetails')}
						onClick={() =>
							history.push(`${Route.DEVICES}/${sequenceCreated.Device}${Route.SEQUENCES}/${sequenceCreated.Uuid}`)}
					/>
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.device.modal.action.createSequence.btnSuccess', 'Weiter zur Messreihen-Übersicht')}
						onClick={onClickConfirm}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
