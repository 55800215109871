import { CreatableViewModel, ModelSyncState, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';
import { Guid } from 'lib/guid/Guid';
import { Network } from 'lib/network/Network';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

export abstract class MiddlewarePersistence<PModel extends PersistenceModel,
	SModel extends StoreModel,
	VModel extends ViewModel,
	CVModel extends CreatableViewModel,
	ModelConv extends ModelConverter<PModel, SModel, VModel, CVModel>,
	HttpPers extends HttpPersistence<PModel, SModel, VModel, CVModel>> {
	protected readonly clientUuid: string;
	protected readonly facilityUuid: string;

	constructor(clientUuid: string, facilityUuid: string) {
		this.clientUuid = clientUuid;
		this.facilityUuid = facilityUuid;
	}

	/**
	 * Fetches the collection from the HTTP API, adds newly remote created entities and updates remote updated entities.
	 * Does not change local entities that are newer than the remote ones. Does not delete local entities no longer existant on remote.
	 */
	public async fetchCollection(): Promise<Array<SModel>> {
		const storeModelCollection = await this.getHttpPersistence().fetchCollection();

		return storeModelCollection;
	}

	/**
	 * Fetches the collection from the HTTP API and uses the remote state as the new local state.
	 * All local changes gets lost.
	 */
	public async resetCollection(): Promise<Array<SModel>> {
		if (!await Network.isOnline()) {
			throw new PropellerError('Network connection required', '666');
		}
		// Try HTTP fetch if online
		const storeModelCollection = await this.getHttpPersistence().fetchCollection();

		return storeModelCollection;
	}

	/**
	 * Fetches a single entity from the HTTP API and updates the local entity if newer.
	 */
	public async fetchEntity(uuid: string): Promise<SModel | null> {
		// Try HTTP fetch if online
		const storeModel = await this.getHttpPersistence().fetch(uuid);

		return storeModel;
	}

	/**
	 * Updates a single entity to the HTTP API and updates the local entity from the result.
	 */
	public async updateEntity(viewModel: VModel): Promise<SModel> {
		let storeModel = this.getModelConverter().fromViewModel(viewModel).toStoreModel();
		if (storeModel.ModelSyncState !== ModelSyncState.CREATED) {
			storeModel.ModelSyncState = ModelSyncState.UPDATED;
		}
		storeModel.UpdatedAt = Date.now();


		// Try HTTP if online
		storeModel = await this.getHttpPersistence().update(storeModel);

		return storeModel;
	}

	/**
	 * Creates a single entity on the HTTP API and updates the local entity from the result.
	 */
	public async createEntity(creatableViewModel: CVModel): Promise<SModel> {
		let storeModel = this.getModelConverter().fromCreatableViewModel(creatableViewModel).toStoreModel();
		storeModel.ModelSyncState = ModelSyncState.CREATED;
		storeModel.Uuid = Guid.generate();
		storeModel.CreatedAt = Date.now();
		storeModel.UpdatedAt = Date.now();

		// Try HTTP if online
		storeModel = await this.getHttpPersistence().create(storeModel);

		return storeModel;
	}

	/**
	 * Creates multiple entities on the HTTP API and updates the local entity from the result.
	 */
	public async createEntities(creatableViewModels: Array<CVModel>): Promise<Array<SModel>> {
		const sModels: Array<SModel> = [];
		for (const creatableViewModel of creatableViewModels) {
			// eslint-disable-next-line no-await-in-loop
			sModels.push(await this.createEntity(creatableViewModel));
		}
		return sModels;
	}

	/**
	 * Deletes a single entity on the HTTP API and updates the local entity from the result.
	 */
	public async deleteEntity(viewModel: VModel): Promise<SModel> {
		let storeModel = this.getModelConverter().fromViewModel(viewModel).toStoreModel();
		storeModel.ModelSyncState = ModelSyncState.DELETED;
		storeModel.UpdatedAt = Date.now();


		// Try HTTP if online
		storeModel = await this.getHttpPersistence().delete(storeModel);

		return storeModel;
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected async preCreate(_creatableViewModel: Readonly<CVModel>, _storeModel: SModel, _local: boolean): Promise<void> {
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected async postCreate(_storeModel: Readonly<SModel>, _local: boolean): Promise<void> {
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected async preUpdate(_viewModel: Readonly<VModel>, _storeModel: SModel, _local: boolean): Promise<void> {
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected async postUpdate(_storeModel: Readonly<SModel>, _local: boolean): Promise<void> {
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected async preDelete(_viewModel: Readonly<VModel>, _storeModel: Readonly<SModel>, _local: boolean): Promise<void> {
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected async postDelete(_storeModel: Readonly<SModel>, _local: boolean): Promise<void> {
	}

	protected abstract getHttpPersistence(): HttpPers;

	protected abstract getModelConverter(): ModelConv;

}
