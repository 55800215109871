import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';

import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import {
	DocumentModelConverter as ModelConverter,
	DocumentStoreModel as StoreModel,
	DocumentViewModel as ViewModel,
} from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentPersistence as Persistence } from 'services/documents/persistence/DocumentPersistence';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { DocumentModelState } from '../domain/model/DocumentModelState';
import { DocumentHttpPersistence as HttpPersistence } from '../persistence/DocumentHttpPersistence';
import { selectDeviceDocuments } from './documentSlice';

// Declare a document state type
export interface DocumentsCollectionByDevicesState {
	documentsCollectionByDevices: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	documentsCollectionByDevices: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as DocumentsCollectionByDevicesState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the

export const fetchAllDocuments = createAsyncThunk(
	'allDocuments/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Silently abort the action
			const { documentsCollectionByDevices } = getState() as RootState;
			return checkFetchStatus(documentsCollectionByDevices.fetchStatus);
		}
	}
);

export const resetAllDocuments = createAsyncThunk(
	'allDocuments/reset',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Sliently abort the action
			const { documentsCollectionByDevices } = getState() as RootState;
			return checkFetchStatus(documentsCollectionByDevices.fetchStatus);
		}
	}
);

// Slice definition
export const documentsCollectionByDevicesSlice = createSlice({
	name: 'documentsCollectionByDevices',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creates slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchAllDocuments.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchAllDocuments.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.documentsCollectionByDevices = [...state.documentsCollectionByDevices, ...action.payload];
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchAllDocuments.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(resetAllDocuments.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(resetAllDocuments.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.documentsCollectionByDevices = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(resetAllDocuments.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

export const { resetState, resetActionStatus } = documentsCollectionByDevicesSlice.actions;

// Export the reducer as default
export default documentsCollectionByDevicesSlice.reducer;
// Selector functions to be used with useSelector or useTypedSelector to read from the stat

export const selectFilteredDeviceDocuments = (
	clientUuid: string,
	facilityUuid: string,
	includeActive: boolean,
	includeArchived: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeActive && includeArchived || !includeActive && !includeArchived) {
		return selectDeviceDocuments(clientUuid, facilityUuid);
	}

	const filterModelState = includeActive ? DocumentModelState.ACTIVE : DocumentModelState.ARCHIVED;

	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.documentsCollectionByDevices.documentsCollectionByDevices.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.State === String(filterModelState)
				&& storeModel.Scope === DocumentModelScope.DEVICE as string;
		}) ?? [];

		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};
