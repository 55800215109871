import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Route } from 'router/Route';

import { NetworkContext } from 'context/NetworkContext';

import { FacilityContext } from 'services/core/context/FacilityContext';
import { FacilityModel } from 'services/core/lib/auth/AuthService';
import { AuthServiceBuilder } from 'services/core/lib/auth/AuthServiceBuilder';
import { FacilitySelectView } from 'services/core/presentation/view/FacilitySelectView';
import { resetState as resetDeviceState } from 'services/device/store/devicesSlice';
import { resetState as resetRecordState } from 'services/device/store/recordSlice';
import { resetState as resetSequenceState } from 'services/device/store/sequenceSlice';
import { resetState as resetDocumentState } from 'services/documents/store/documentSlice';
import { resetState as resetFolderState } from 'services/documents/store/folderSlice';
import { resetState as resetMaintenanceLogEntryState } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { resetState as resetRoleState } from 'services/core/store/roleSlice';
import { resetState as resetMembershipState } from 'services/core/store/membershipSlice';
import { resetState as resetReportState } from 'services/report/store/reportSlice';
import { resetState as resetGeneratorState } from 'services/nuclide/store/generatorSlice';
import { resetState as resetEluateState } from 'services/nuclide/store/eluateSlice';
import { resetState as resetCyclotronProductState } from 'services/nuclide/store/cyclotronProductSlice';
import { resetState as resetContainerState } from 'services/nuclide/store/containerSlice';
import { resetState as resetContainerDispatchState } from 'services/nuclide/store/containerDispatchSlice';
import { resetState as resetContainerDisposeState } from 'services/nuclide/store/containerDisposeSlice';
import { resetState as resetWasteManagementReportState } from 'services/waste-management-report/store/wasteManagementReportSlice';

export const FacilityContextProvider = (props: any): JSX.Element => {

	const networkContext = useContext(NetworkContext);
	const authService = AuthServiceBuilder.build(networkContext.online);

	const dispatch = useDispatch();

	const history = useHistory();

	const [selectedFacilityUuid, setSelectedFacilityUuid] = useState<string>(
		authService.getSelectedFacilityModel()?.Uuid ?? null
	);

	const select = (facilityUuid: string): void => {
		if (facilityUuid === selectedFacilityUuid) {
			return;
		}
		const facilityModel = authService.getAvailableFacilityModelByUuid(facilityUuid);
		authService.setSelectedFacilityModel(facilityModel);
		setSelectedFacilityUuid(facilityUuid);
		history.replace(Route.DASHBOARD);
	};

	const deselect = (): void => {
		authService.setSelectedFacilityModel(null);
		setSelectedFacilityUuid(null);
	};

	const selectedFacility = (): FacilityModel | null => {
		return authService.getSelectedFacilityModel();
	};

	const availableFacility = (facilityUuid: string): FacilityModel | null => {
		return authService.getAvailableFacilityModelByUuid(facilityUuid);
	};

	const availableFacilities = (): Array<FacilityModel> => {
		return authService.getSelectedClientModel()?.Facilities ?? [];
	};

	if (selectedFacilityUuid === null) {
		dispatch(resetDeviceState());
		dispatch(resetSequenceState());
		dispatch(resetRecordState());
		dispatch(resetMaintenanceLogEntryState());
		dispatch(resetDocumentState());
		dispatch(resetFolderState());
		dispatch(resetRoleState());
		dispatch(resetMembershipState());
		dispatch(resetReportState());
		dispatch(resetGeneratorState());
		dispatch(resetEluateState());
		dispatch(resetCyclotronProductState());
		dispatch(resetContainerState());
		dispatch(resetContainerDispatchState());
		dispatch(resetContainerDisposeState());
		dispatch(resetWasteManagementReportState());
	}

	if (availableFacilities().length === 1) {
		select(availableFacilities()[0].Uuid);
	}

	const facilityInterceptionView = (
		<FacilitySelectView />
	);

	return (
		<FacilityContext.Provider
			value={{
				selectedFacilityUuid,
				select,
				deselect,
				selectedFacility,
				availableFacility,
				availableFacilities
			}}
		>
			<>
				{selectedFacilityUuid !== null ? props.children : facilityInterceptionView}
			</>
		</FacilityContext.Provider>
	);

};
