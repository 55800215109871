import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateDocument } from 'services/documents/store/documentSlice';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentEditFormStep1 } from './DocumentEditFormStep1';
import { DocumentEditFormStep2 } from './DocumentEditFormStep2';

export interface DocumentEditFormProps {
	document: DocumentViewModel;
	onChange: (input: string) => void;
}

export const DocumentEditForm = (props: DocumentEditFormProps): JSX.Element => {
	const { document, onChange } = props;

	const [showStep2, setShowStep2] = useState<boolean>(false);
	const [invalidStatus, setInvalidStatus] = useState<boolean>(false);

	const newDocumentName = useRef<string>(document.Filename);

	const dispatch = useDispatch();

	const handleSaveAction = (): void => {
		dispatch(updateDocument({
			...document,
			Filename: newDocumentName.current
		}));
	};

	const onUserInput = (input: string) => {
		newDocumentName.current = input;
	};

	const gotoStep1 = () => {
		setShowStep2(false);
	};

	const gotoStep2 = () => {
		if (newDocumentName.current.trim() !== '') {
			onChange(newDocumentName.current);
			setShowStep2(true);
		} else {
			setInvalidStatus(true);
		}
	};

	const formStep = !showStep2 ?
		<DocumentEditFormStep1
			documentName={newDocumentName.current}
			onChange={onUserInput}
			onClickNext={gotoStep2}
			invalid={invalidStatus}
		/> :
		<DocumentEditFormStep2
			documentName={newDocumentName.current}
			onClickBack={gotoStep1}
			onClickNext={handleSaveAction}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
