import { Optional } from '../misc/Optional';
import { ValuedString } from '../misc/ValuedString';
import { Valuation } from '../Valuation';
import { Value } from './Value';

export class GetValuation implements Value<Valuation> {
	private readonly result: Optional<Valuation>;

	public constructor(value: Value<ValuedString>) {
		this.result = calculate(value.getSingle());
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<Valuation> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<Valuation> {
		return this.result;
	}
}

function calculate(value: Optional<ValuedString>): Optional<Valuation> {
	if (value !== null) {
		return value.valuation;
	}
	return null;
}
