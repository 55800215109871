import { MouseEvent, useContext } from 'react';

import { ExpandableContext } from 'presentation/ui/compositions/expandable/expandable-context/ExpandableContext';
import { Icon, IconColor } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface ExpandableHeaderProps {
	caption: string,
	children?: any,
	isReportView?: boolean,
	showExpandIcon?: boolean
}

export const ExpandableHeader = (props: ExpandableHeaderProps): JSX.Element => {

	const { caption, children, isReportView, showExpandIcon = true } = props;

	const expandableContext = useContext(ExpandableContext);

	const handleToggle = (event: MouseEvent): void => {
		event.stopPropagation();
		expandableContext.setExpanded(!expandableContext.expanded);
	};

	return (
		<header className={`expandable__header${isReportView ? 'expandable__header--report-view' : ''}`}>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
			<section role='dialog' className='expandable__header__caption' onClick={handleToggle}>
				{ showExpandIcon ? <Icon name={IconIdentifier.EXPAND} color={IconColor.PRIMARY_50} className='expandable__header__caption__icon' /> : null }
				<h1 className='expandable__header__caption__text'>{caption}</h1>
			</section>

			<section className='expandable__header__actions'>
				{children}
			</section>
		</header>
	);
};
