import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { UnitInput } from 'services/device/domain/business/common/Input';
import { Unit } from 'services/device/domain/business/inventory/Unit';
import { getAllUnits, getUnitDefinition } from 'services/device/domain/business/inventory/UnitDefinition';

import { Label } from 'presentation/ui/partials/input/label/Label';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';

export interface UnitInputProps {
	input: UnitInput;
}

export const UnitInputRenderer = (props: UnitInputProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const { input } = props;

	let label = l10nContext.translate(input.getLabelKey());
	if (input.getDescription()?.mandatory === true) {
		label += '*';
	}

	let inputOptions = input.getOptions();

	if (inputOptions === null || inputOptions.length === 0) {
		inputOptions = getAllUnits();
	}

	const filteredInputOptions = inputOptions.filter((inputOption) => {
		return !getUnitDefinition(inputOption).isDeprecated();
	});

	const options = filteredInputOptions.map<SelectOption>((option) => {

		return {
			label: l10nContext.translate(getUnitDefinition(option).getSymbol()),
			value: String(option),
			checked: input.getSingle() === option
		} as SelectOption;
	});

	return (
		<Label label={label}>
			<Select
				options={options}
				onchange={(selectedUnit) => {
					input.setSingle(selectedUnit as Unit);
				}}
			/>
		</Label>
	);

};
