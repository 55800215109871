import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import './card-greeting.scss';

export interface CardGreetingProps {
	/**
	 * User
	 */
	user?: string;
}

/**
 * The greeting card component
 */
export const CardGreeting = ({ user }: CardGreetingProps): JSX.Element => {
	const l10nContext = useContext(L10nContext);

	const translations = {
		'headline': l10nContext.translate('greeting.sentence', 'Hallo'),
		'subline': l10nContext.translate('greeting.claim', 'Das steht heute an:'),
	};

	const greeting =
		<p className='card-greeting__greeting'>
			{translations.headline} {user ? ` ${user}!` : ''}
		</p>;

	return (
		<div className='card-greeting'>
			{greeting}

			<p className='card-greeting__claim'>
				{translations.subline}
			</p>
		</div>
	);
};
