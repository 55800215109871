import { Value } from '../value/Value';

export type LookupTables = Record<string, LookupTable<any, any>>;

export class LookupTable<From, To> {
	private readonly entries: ReadonlyMap<From, Value<To>>;

	private readonly fallbackEntry: Value<To>;

	public constructor(entries: ReadonlyArray<[From, Value<To>]>, fallbackEntry: Value<To>) {
		this.entries = toMap(entries);
		this.fallbackEntry = fallbackEntry;
	}

	public lookup(key: From): Value<To> {
		return this.entries.get(key) ?? this.fallbackEntry;
	}
}

function toMap<From, To>(entries: ReadonlyArray<[From, To]>): ReadonlyMap<From, To> {
	const map: Map<From, To> = new Map<From, To>();
	entries.forEach(([key, value]) => map.set(key, value));
	return map;
}
