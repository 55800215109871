import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { useContext } from 'react';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { deleteContainer } from '../../../../store/containerSlice';
import { ContainerDeleteForm } from '../container-delete-form/ContainerDeleteForm';
import { ContainerDeleteFailedNotification } from './ContainerDeleteFailedNotification';

export interface ContainerDeleteModalProps {
	container: ContainerViewModel
	onDismiss?: () => void;
}

export const ContainerDeleteModal = (props: ContainerDeleteModalProps): JSX.Element => {
	const { container, onDismiss } = props;

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const containerActionStatus = useTypedSelector(state => state.containers.actionStatus);

	const dispatch = useDispatch();

	const handleDelete = (): void => {
		container.Client = clientContext.selectedClientUuid;
		container.Facility = facilityContext.selectedFacilityUuid;
		dispatch(deleteContainer(container));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (containerActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<ContainerDeleteForm
						container={container}
						onDelete={handleDelete}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <ContainerDeleteFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
