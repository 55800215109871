import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ModalDialogueCaptionModifier, ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogueSectionName } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSectionName';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';

export interface EluateDeleteFormProps {
	eluate: EluateViewModel
	onDelete: () => void;
	onDismiss: () => void;
}

export const EluateDeleteForm = (props: EluateDeleteFormProps): JSX.Element => {
	const { eluate, onDelete, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const extractionDatetimeFormatted = l10nContext.formatDate(eluate.ExtractionDatetime);

	const modalMessage = {
		first: l10nContext.translate('view.eluates.modal.action.delete.message.first', 'Wollen Sie die Eluat-Entnahme vom'),
		last: l10nContext.translate('view.eluates.modal.action.delete.message.last', 'wirklich löschen?')
	};

	return (
		<>
			<ModalDialogueHeader
				captionText={l10nContext.translate('common.modal.captionAttention', 'Achtung')}
				captionIcon={IconIdentifier.ALERT}
				captionModifier={ModalDialogueCaptionModifier.SMALL_CAPTION}
			/>
			<ModalDialogueSection>
				<>
					{modalMessage.first} <b>{extractionDatetimeFormatted}</b> {modalMessage.last}
				</>
			</ModalDialogueSection>

			<ModalDialogueSection section={ModalDialogueSectionName.SECTION_FOOTER}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.abort', 'Abbrechen')}
							onClick={onDismiss}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.modal.deleteConfirm', 'Ja, löschen')}
							status={ButtonSecondaryStatus.ATTENTION}
							icon={IconIdentifier.TRASH}
							onClick={onDelete}
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</ModalDialogueSection>
		</>
	);
};
