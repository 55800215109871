import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { useContext, useEffect, useState } from 'react';

import { L10nContext } from 'context/L10nContext';
import { useDispatch, useSelector } from 'react-redux';

import { ChartDescription } from 'services/device/domain/business/common/description/ChartDescription';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ButtonToggleSet } from 'presentation/ui/components/button/ButtonToggleSet';
import { ButtonToggleProps } from 'presentation/ui/partials/button/button-toggle/ButtonToggle';
import { AsyncFetchStatus } from '../../../../../store/common/AsyncFetchStatus';
import { useTypedSelector } from '../../../../../store/common/TypedSelector';
import { ReportRecordViewModel } from '../../../../report/domain/model/ReportRecordModel';
import {
	fetchExternalReportRecordsBySequence,
	selectExternalReportRecordsBySequence
} from '../../../store/externalReportRecordSlice';
import { ExternalReportSequenceChart } from './ExternalReportSequenceChart';

export interface SequenceChartsProps {
	reportUuid: string;
	sequence: SequenceViewModel;
	showTooltip?: boolean;
	showOverlay?: boolean;
	onSelect?: (record: ReportRecordViewModel) => void;
	onDeselect?: (record: ReportRecordViewModel) => void;
	onDetails?: (record: ReportRecordViewModel) => void;
}

export const ExternalReportSequenceCharts = (props: SequenceChartsProps): JSX.Element | null => {

	const {
		sequence,
		reportUuid,
		onSelect = null,
		onDeselect = null,
		onDetails = null,
		showOverlay = false,
		showTooltip = false
	} = props;

	const l10nContext = useContext(L10nContext);
	// Consume the dispatch object
	const dispatch = useDispatch();

	const sequenceTypeDfinition = getSequenceTypeDefinition(sequence.Type);
	const chartDescriptions = sequenceTypeDfinition.getChartDescriptions();

	// Use a custom selector function to provide type information for the state defined in the slice
	const recordsStoreFetchStatus = useTypedSelector(state => state.externalReportRecords.fetchStatus);

	// Read records from the server
	useEffect(() => {
		if (recordsStoreFetchStatus === AsyncFetchStatus.INITIAL || recordsStoreFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchExternalReportRecordsBySequence({
				sequenceUuid: sequence.Uuid,
				reportUuid: props.reportUuid }));
		}
	});


	const [activeChart, setActiveChart] = useState<ChartDescription>(chartDescriptions?.[0] ?? null);

	// Select the records from the store
	const records = useSelector(selectExternalReportRecordsBySequence(sequence));
	if (records.length === 0) {
		return (
			<CardEmpty message={l10nContext.translate('sequence.chart.emptyState')} />
		);
	}

	if (chartDescriptions.length === 0) {
		return null;
	}

	const selectChart = (chartName: string): void => {
		for (const chartDescription of chartDescriptions) {
			if (chartDescription.name === chartName) {
				setActiveChart(chartDescription);
				return;
			}
		}
	};

	const renderTabbar = (): JSX.Element | null => {
		if (chartDescriptions.length < 2) {

			return null;
		}
		const buttonProperties: Array<ButtonToggleProps> = [];
		for (const chartDescription of chartDescriptions) {
			buttonProperties.push({
				id: chartDescription.name,
				buttonText: l10nContext.translate(chartDescription.labelKey),
				icon: IconIdentifier.CHART
			} as ButtonToggleProps);
		}
		return (
			<ButtonToggleSet
				onClick={(id) => {
					selectChart(id);
				}}
				buttons={buttonProperties}
				deSelectAll={false}
			/>
		);
	};

	const renderCharts = (): JSX.Element => {
		return (
			<>
				{renderTabbar()}
				<ExternalReportSequenceChart
					key={activeChart.name}
					reportUuid={reportUuid}
					sequence={sequence}
					chartDescription={activeChart}
					onSelect={onSelect}
					onDeselect={onDeselect}
					onDetails={onDetails}
					showOverlay={showOverlay}
					showTooltip={showTooltip}
				/>
			</>
		);
	};

	return (
		<div className="sequence-charts">
			{renderCharts()}
		</div>
	);

};
