import { useContext, useState } from 'react';

import { useDispatch } from 'react-redux';

import { AuthContext } from 'services/core/context/AuthContext';
import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';
import { updateEluate } from 'services/nuclide/store/eluateSlice';
import { Permission } from 'services/core/lib/auth/AuthService';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { EluateUpdateFormStep1 } from './EluateUpdateFormStep1';
import { EluateUpdateFormStep2 } from './EluateUpdateFormStep2';

export interface EluateUpdateFormProps {
	/**
	 * Eluate
	 */
	eluate: EluateViewModel;
}

/**
 * The eluate update form
 */
export const EluateUpdateForm = (props: EluateUpdateFormProps): JSX.Element => {

	const { eluate } = props;

	// Consume the context
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const updatedEluate: EluateViewModel = eluate;

	const onChangeEluateProp = (prop: string, value: any) => {
		updatedEluate[prop] = value;
	};

	const handleUpdate = (): void => {
		if ((updatedEluate.ExtractionDatetime ?? null) === null) {
			updatedEluate.ExtractionDatetime = new Date();
		}
		updatedEluate.Client = clientContext.selectedClientUuid;
		updatedEluate.Facility = facilityContext.selectedFacilityUuid;
		dispatch(updateEluate(updatedEluate));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const requiredInputs = ['Activity', 'ActivityUnit'];
	if (authContext.hasPermission(Permission.GENERATOR_ELUAT_SUPPLEMENT)) {
		requiredInputs.push('ExtractionDatetime');
	}

	const goToStep2 = () => {
		let newGeneratorModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedEluate?.[requiredInput] ?? null) === null
				|| updatedEluate?.[requiredInput] === ''
				|| String(updatedEluate.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newGeneratorModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newGeneratorModelValid);
	};

	const showUpdateModalStep = showStep1 === true ?
		<EluateUpdateFormStep1
			eluate={updatedEluate}
			onClickNext={goToStep2}
			onChangeEluateProp={onChangeEluateProp}
			invalidInputs={invalidInputs}
		/> :
		<EluateUpdateFormStep2
			eluate={updatedEluate}
			onClickPrevious={goToStep1}
			onClickConfirm={handleUpdate}
		/>;

	return (
		<>
			{showUpdateModalStep}
		</>
	);
};
