import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';
import { getDeviceTypeDefinition } from 'services/device/domain/business/inventory/DeviceTypeDefinition';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';

import { CardDeviceDetail } from 'presentation/ui/components/cards/card-device/card-device-detail/CardDeviceDetail';
import {
	CardItemDeviceDetailAttributes,
} from 'presentation/ui/partials/card/card-item-attributes/card-item-device-detail-attributes/CardItemDeviceDetailAttributes';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { mapTypeToIlluIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifierMapper';

interface ReportDeviceCardProps {
	device: DeviceViewModel;
	location: string;
}

export const ReportDeviceCard = (props: ReportDeviceCardProps): JSX.Element => {
	const { device, location } = props;

	const l10nContext = useContext(L10nContext);

	const deviceTypeDefinition = getDeviceTypeDefinition(device.Type);

	return (
		<CardDeviceDetail>
			<CardItemHeader
				title={device.Name}
				subtitle={l10nContext.translate(deviceTypeDefinition.getLabelKey())}
				illustration={mapTypeToIlluIdentifier(device?.Type)}
				archived={device.State === DeviceModelState.ARCHIVED}
			/>
			<CardItemDeviceDetailAttributes
				ident={device.Identifier}
				location={location}
				serialNumber={device.SerialNumber}
				locationDescription={device.Location}
			/>
		</CardDeviceDetail>
	);
};
