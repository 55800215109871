import { useContext, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { SimpleViewTitle } from 'services/core/presentation/ui/simple-view-title/SimpleViewTitle';

import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';

import './client-select.scss';

export enum ChangeMode {
	BUTTON_CLICK = 'BUTTON_CLICK',
	SELECTION_CHANGE = 'SELECTION_CHANGE'
}
export interface ClientSelectProps {
	changeMode?: ChangeMode;
}

export const ClientSelect = (props: ClientSelectProps): JSX.Element => {

	const changeMode = props.changeMode ?? ChangeMode.BUTTON_CLICK;

	const clientContext = useContext(ClientContext);
	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	const [selectedClientUuid, setSelectedClientUuid] = useState<string>(clientContext.selectedClientUuid);

	const handleApply = (clientUuid:string): void => {
		clientContext.select(clientUuid);
	};

	const handleChange = (clientUuid: string): void => {
		setSelectedClientUuid(clientUuid);
		if (changeMode === ChangeMode.SELECTION_CHANGE) {
			handleApply(clientUuid);
		}
	};

	const clientSelectOptions = clientContext.availableClients().map((clientModel): SelectOption => {
		return {
			value: clientModel.Uuid,
			label: clientModel.Name,
			checked: selectedClientUuid === clientModel.Uuid
		};
	});

	const renderSelectButton = (): JSX.Element => {
		if (changeMode !== ChangeMode.BUTTON_CLICK) {
			return null;
		}
		return (
			<FormLayoutSection>
				<ButtonPrimary
					buttonText={l10nContext.translate('common.button.confirm', 'Bestätigen')}
					name="select-button"
					type="submit"
				/>
			</FormLayoutSection>
		);
	};

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_VERTICAL}
			onSubmit={() => handleApply(selectedClientUuid)}
		>
			<FormLayoutSection>
				<SimpleViewTitle
					label={authContext.getActor().Realname}
				/>
				<Label label={l10nContext.translate('view.clientFacilitySelect.clientSelect', 'Mandant Auswahl')}>
					<Select
						options={clientSelectOptions}
						onchange={handleChange}
					/>
				</Label>
			</FormLayoutSection>
			{renderSelectButton()}
		</FormLayout>
	);

};
