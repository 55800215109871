import { ComponentPropsWithRef, useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { NumberFieldDescription } from 'services/device/domain/business/common/description/FieldDescription';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { UnitUtil } from 'services/device/domain/business/util/UnitUtil';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

import 'services/device/presentation/ui/sequence-table/sequence-table-head/sequence-table-head.scss';
import { TableCellLabelRenderer } from 'services/device/presentation/ui/widget/widget-renderer/TableCellLabelRenderer';

export interface SequenceTableHeadProps extends ComponentPropsWithRef<any> {
	sequence: SequenceViewModel;
}

export const SequenceTableHead = (props: SequenceTableHeadProps): JSX.Element | null => {

	const sequenceViewModel = props.sequence;

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	// Prepare the sequence type definition
	const sequenceTypeDefinition = getSequenceTypeDefinition(sequenceViewModel.Type);
	const tableDescriptions = sequenceTypeDefinition.getTableDescriptions();
	if (tableDescriptions.length === 0) {
		return null;
	}
	const tableDescription = tableDescriptions[0];

	const valueDescription = tableDescription.value as NumberFieldDescription;
	const deviationDescription = tableDescription.deviation as NumberFieldDescription;
	const expectedValueDescription = tableDescription.expectedValue as NumberFieldDescription;
	const lowerSoftThresholdDescription = tableDescription.lowerReactionValue as NumberFieldDescription;
	const lowerHardThresholdDescription = tableDescription.lowerToleranceValue as NumberFieldDescription;

	const unitUtil = new UnitUtil(sequenceViewModel);
	const valueDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(valueDescription?.unit);
	const devicationDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(deviationDescription?.unit);
	const expectedValueDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(expectedValueDescription?.unit);
	const softThresholdDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(lowerSoftThresholdDescription?.unit);
	const hardThresholdDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(lowerHardThresholdDescription?.unit);

	const hasDeviation = (tableDescription.deviation ?? null) !== null;
	const hasExpectedValue = (tableDescription.expectedValue ?? null) !== null;
	const hasSoftThreshold = (tableDescription.lowerReactionValue ?? null) !== null || (tableDescription.upperReactionValue ?? null) !== null;
	const hasHardThreshold = (tableDescription.lowerToleranceValue ?? null) !== null || (tableDescription.upperToleranceValue ?? null) !== null;

	const renderHeadRowActionCell = (type: 'MAJOR' | 'MINOR'): JSX.Element | null => {
		if (
			!authContext.hasPermission(Permission.RECORD_UPDATE)
			&& !authContext.hasPermission(Permission.RECORD_DELETE)
		) {
			return null;
		}
		return (
			<td className={`sequence-table-head__row__cell sequence-table-head__row__cell--${type.toLowerCase()} sequence-table__row__cell--sticky-end`}>
				&nbsp;
			</td>
		);
	};

	const renderDeviationHeading = (): JSX.Element | null => {
		if (!hasDeviation) {
			return null;
		}
		return (
			<th className="sequence-table-head__row__cell sequence-table-head__row__cell--major sequence-table-head__row__cell--align-right">
				{l10nContext.translate(tableDescription?.deviation?.labelKey)}
			</th>
		);
	};

	const renderDeviationSubHeading = (): JSX.Element | null => {
		if (!hasDeviation) {
			return null;
		}
		return (
			<td className="sequence-table-head__row__cell sequence-table-head__row__cell--minor sequence-table-head__row__cell--align-right">
				{devicationDisplayUnit}
			</td>
		);
	};

	const renderExpectedValueHeading = (): JSX.Element | null => {
		if (!hasExpectedValue) {
			return null;
		}
		return (
			<th className="sequence-table-head__row__cell sequence-table-head__row__cell--major sequence-table-head__row__cell--align-right">
				{l10nContext.translate(tableDescription?.expectedValue?.labelKey)}
			</th>
		);
	};

	const renderExpectedValueSubHeading = (): JSX.Element | null => {
		if (!hasExpectedValue) {
			return null;
		}
		return (
			<td className="sequence-table-head__row__cell sequence-table-head__row__cell--minor sequence-table-head__row__cell--align-right">
				{expectedValueDisplayUnit}
			</td>
		);
	};

	const renderSoftThresholdHeading = (): JSX.Element | null => {
		if (!hasSoftThreshold) {
			return null;
		}
		return (
			<th className="sequence-table-head__row__cell sequence-table-head__row__cell--major sequence-table-head__row__cell--align-right">
				{l10nContext.translate('sequence.table.header.softThreshold', 'RS')}
			</th>
		);
	};

	const renderSoftThresholdSubHeading = (): JSX.Element | null => {
		if (!hasSoftThreshold) {
			return null;
		}
		return (
			<td className="sequence-table-head__row__cell sequence-table-head__row__cell--minor sequence-table-head__row__cell--align-right">
				{softThresholdDisplayUnit}
			</td>
		);
	};

	const renderHardThresholdHeading = (): JSX.Element | null => {
		if (!hasHardThreshold) {
			return null;
		}
		return (
			<th className="sequence-table-head__row__cell sequence-table-head__row__cell--major sequence-table-head__row__cell--align-right">
				{l10nContext.translate('sequence.table.header.hardThreshold', 'TG')}
			</th>
		);
	};

	const renderHardThresholdSubHeading = (): JSX.Element | null => {
		if (!hasHardThreshold) {
			return null;
		}
		return (
			<td className="sequence-table-head__row__cell sequence-table-head__row__cell--minor sequence-table-head__row__cell--align-right">
				{hardThresholdDisplayUnit}
			</td>
		);
	};

	const renderAdditionalHeadings = (): JSX.Element | null => {
		const additionalColumns = tableDescription?.additionalColumns ?? null;
		if (additionalColumns === null || additionalColumns.length === 0) {
			return null;
		}
		return (
			<TableCellLabelRenderer fieldDescriptions={additionalColumns} />
		);
	};

	const renderAdditionalSubHeadings = (): JSX.Element | null => {
		const additionalColumns = tableDescription?.additionalColumns ?? null;
		if (additionalColumns === null || additionalColumns.length === 0) {
			return null;
		}

		const cells: Array<JSX.Element> = [];
		for (const additionalColumn of additionalColumns) {
			cells.push(
				<td key={additionalColumn.name} className="sequence-table-head__row__cell sequence-table-head__row__cell--minor">
					&nbsp;
				</td>
			);
		}
		return (
			<>
				{cells}
			</>
		);
	};

	return (
		<thead className="sequence-table-head">
			<tr className="sequence-table-head__row">
				<th className="sequence-table-head__row__cell sequence-table-head__row__cell--major sequence-table__row__cell--sticky-start">
					{l10nContext.translate(tableDescription.value.labelKey)}
				</th>
				{renderDeviationHeading()}
				{renderExpectedValueHeading()}
				{renderSoftThresholdHeading()}
				{renderHardThresholdHeading()}
				{renderAdditionalHeadings()}
				<th className="sequence-table-head__row__cell sequence-table-head__row__cell--major">
					{l10nContext.translate('sequence.table.header.recordedAt', 'Zeitpunkt')}
				</th>
				<th className="sequence-table-head__row__cell sequence-table-head__row__cell--major">
					{l10nContext.translate('sequence.table.header.recordedBy', 'Erfasser')}
				</th>
				{renderHeadRowActionCell('MAJOR')}
			</tr>
			<tr className="sequence-table-head__row">
				<td className="sequence-table-head__row__cell sequence-table-head__row__cell--minor sequence-table__row__cell--sticky-start">
					{valueDisplayUnit}
				</td>
				{renderDeviationSubHeading()}
				{renderExpectedValueSubHeading()}
				{renderSoftThresholdSubHeading()}
				{renderHardThresholdSubHeading()}
				{renderAdditionalSubHeadings()}
				<td className="sequence-table-head__row__cell sequence-table-head__row__cell--minor">
					&nbsp;
				</td>
				<td className="sequence-table-head__row__cell sequence-table-head__row__cell--minor">
					&nbsp;
				</td>
				{renderHeadRowActionCell('MINOR')}
			</tr>
		</thead>
	);

};
