import { ComponentPropsWithoutRef } from 'react';

import 'presentation/ui/layouts/view-layout/view-layout.scss';

export type ViewLayoutProps = ComponentPropsWithoutRef<'div'>;

export const ViewLayout = (props: ViewLayoutProps): JSX.Element => {

	return (
		<div className={`view-layout ${props.className ?? ''}`}>
			{props.children}
		</div>
	);

};
