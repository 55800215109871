import { memo, useContext } from 'react';
import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';
import { ContainerViewModel } from 'services/nuclide/domain/model/ContainerModel';

import './card-item-container-attributes.scss';

export interface CardItemContainerAttributesProps {
	/**
	 * Container
	 */
	container: ContainerViewModel;
}

/**
 * A card item Container detail attributes component
 */
export const CardItemContainerAttributes = memo((props: CardItemContainerAttributesProps): JSX.Element => {
	const { container } = props;

	const l10nContext = useContext(L10nContext);

	const createdAtDateFormatted = l10nContext.formatDate(container?.CreatedAt);
	const measurementDateFormatted = l10nContext.formatDateTime(container?.MeasurementDate);
	const releaseDateTimeFormatted = l10nContext.formatDateTime(container?.ReleaseDateTime);

	const checkEmptyValue = (val: string): string => {
		return val !== '' && val !== null ? val : '-';
	};

	const showWeight = container?.Weight !== null ? l10nContext.formatNumber(container?.Weight) + ' g' : '-';

	const translations = {

		createdAtDate: { label: l10nContext.translate('containers.createdAtDate', 'Erstellt am'), value: createdAtDateFormatted },
		measurementDate: { label: l10nContext.translate('containers.measurementDate', 'Gemessen am'), value: checkEmptyValue(measurementDateFormatted) },
		measuredByName: { label: l10nContext.translate('containers.measuredByName', 'Gemessen von'), value: checkEmptyValue(container?.MeasuredByName) },
		weight: { label: l10nContext.translate('containers.weight', 'Gewicht'), value: showWeight },
		ReleaseDateTime: { label: l10nContext.translate('containers.releaseDateTime', 'Freigabedatum'), value: checkEmptyValue(releaseDateTimeFormatted) },
	};

	const attributes = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const styleClass = 'attribute';

			return (
				<label
					key={Guid.generate()}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value }</p>
				</label>
			);
		});
	};

	return (
		<div className='card-item-container-attributes'>
			<ul className='card-item-container-attributes__attribute'>
				{attributes()}
			</ul>
		</div>
	);
});
