import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { Guid } from 'lib/guid/Guid';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { permissionGroupMaintenanceLog } from 'services/core/lib/auth/AuthService';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogModalType } from 'services/maintenance-log/domain/type/MaintenanceLogModalModel';
import { MaintenanceLogEntry } from 'services/maintenance-log/presentation/ui/maintenance-log-entry/MaintenanceLogEntry';
import { MaintenanceLogFilter } from 'services/maintenance-log/presentation/ui/maintenance-log-filter/MaintenanceLogFilter';
import { MaintenanceLogHeader } from 'services/maintenance-log/presentation/ui/maintenance-log-header/MaintenanceLogHeader';
import { MaintenanceLogModal } from 'services/maintenance-log/presentation/ui/maintenance-log-modal/MaintenanceLogModal';
import { MaintenanceLogTopbar } from 'services/maintenance-log/presentation/ui/maintenance-log-topbar/MaintenanceLogTopbar';
import {
	resetActionStatus,
	selectFilteredDeviceMaintenanceLogEntries,
	selectFilteredFacilityMaintenanceLogEntries,
} from 'services/maintenance-log/store/maintenanceLogEntrySlice';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { Footer } from '../../../../presentation/ui/compositions/footer/Footer';

export const MaintenanceLogView = (): JSX.Element => {
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// HOOKS
	const dispatch = useDispatch();

	// PERMISSION
	if (!authContext.hasAnyPermission(permissionGroupMaintenanceLog)) {
		throw new Error('Permission denied');
	}

	// STATES
	const [toggleState, setToggleState] = useState<string>('1');
	const [filterOngoing, setFilterOngoing] = useState<boolean>(true);
	const [filterSolved, setFilterSolved] = useState<boolean>(false);
	const [filterActive, setFilterActive] = useState<boolean>(true);
	const [filterArchived, setFilterArchived] = useState<boolean>(false);
	const [selectedMaintenanceLogEntry, setSelectedMaintenanceLogEntry] = useState<MaintenanceLogEntryViewModel>(null);
	const [modalType, setModalType] = useState<MaintenanceLogModalType>(null);

	// DATA
	// Use a custom selector function to provide type information for the state defined in the slice
	const storeActionStatus = useTypedSelector(state => state.maintenanceLogEntries.actionStatus);
	const storeLastActionError = useTypedSelector(state => state.maintenanceLogEntries.lastActionError);

	// Read the devices from the state store
	const getMaintenanceLogEntriesSelector = () => {
		if (toggleState === '2') {
			return selectFilteredDeviceMaintenanceLogEntries(
				clientContext.selectedClientUuid,
				facilityContext.selectedFacilityUuid,
				filterOngoing,
				filterSolved,
				filterActive,
				filterArchived
			);
		}
		return selectFilteredFacilityMaintenanceLogEntries(
			clientContext.selectedClientUuid,
			facilityContext.selectedFacilityUuid,
			filterOngoing,
			filterSolved
		);
	};

	const maintenanceLogEntries = useSelector(getMaintenanceLogEntriesSelector());

	// CALLBACK
	const resetModal = (): void => {
		dispatch(resetActionStatus());
		setModalType(null);
		setSelectedMaintenanceLogEntry(null);
	};

	// RENDER
	const renderMaintenanceLogEntries = (): Array<JSX.Element> | JSX.Element => {
		if (maintenanceLogEntries.length > 0) {
			return maintenanceLogEntries.map((maintenanceLogEntry) => {
				return (
					<MaintenanceLogEntry
						key={maintenanceLogEntry.Uuid}
						maintenanceLogEntry={maintenanceLogEntry}
						onSelect={(selectedEntry) => {
							resetModal();
							setSelectedMaintenanceLogEntry(selectedEntry);
							setModalType(MaintenanceLogModalType.EDIT);
						}}
					/>
				);
			});
		}
		return <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.documents', 'kein Eintrag')} />;
	};

	const renderNotifications = (): Array<JSX.Element> => {
		const notifications = [];
		// Handle failed action
		if (storeActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					key={Guid.generate()}
					message={storeLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}
		return notifications;
	};

	return (
		<MainLayout>

			<MaintenanceLogTopbar />

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>

					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<MaintenanceLogHeader
							onToggle={(id) => setToggleState(id)}
							onCreate={() => {
								resetModal();
								setModalType(MaintenanceLogModalType.CREATE);
							}}
						/>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<CardCollectionLayout>

							<MaintenanceLogModal
								selectedMaintenanceLogEntry={selectedMaintenanceLogEntry}
								modalType={modalType}
								onDismiss={resetModal}
							/>

							<MaintenanceLogFilter
								toggleState={toggleState}
								onClickOngoing={(state) => setFilterOngoing(state)}
								onClickSolved={(state) => setFilterSolved(state)}
								onClickArchived={(state) => setFilterArchived(state)}
								onClickActive={(state) => setFilterActive(state)}
							/>

							{renderMaintenanceLogEntries()}

						</CardCollectionLayout>
					</ViewLayoutSection>

				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);

};
