import { L10nContext } from 'context/L10nContext';

import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Tag, TagType } from 'presentation/ui/partials/tag/Tag';
import { useContext } from 'react';

import './card-sequence-list-item-header.scss';

export interface CardSequenceListItemHeaderProps {
	/**
	 * Title
	 */
	title: string;
	/**
	 * (Optional) Sub-Title
	 */
	subtitle?: string;
	/**
	 * (Optional) Schedule tag label
	 */
	interval?: string;
	due: boolean;
	/**
	 * (Optional) Archived tag
	 */
	archived?: boolean;
}

/**
 * A card sequence list item header component
 */
export const CardSequenceListItemHeader = (props: CardSequenceListItemHeaderProps): JSX.Element => {
	const { title, subtitle, interval, due, archived } = props;

	const l10nContext = useContext(L10nContext);

	const subTitle = subtitle ?
		<p className="card-sequence-list-item-header__subtitle">
			{subtitle}
		</p> :
		null;

	const scheduleIconIdentifier = due ? IconIdentifier.ALERT : IconIdentifier.CHECK;

	const scheduleTag = interval && !archived ?
		<span className="list-item-header__schedule-tag">
			{interval}
			<Icon name={scheduleIconIdentifier} />
		</span>
		:
		null;

	const archivedTagElement = (): JSX.Element => {
		if (!archived) {
			return null;
		}

		const tagLabel = l10nContext.translate('common.tags.archived', 'Archiviert');

		return (
			<Tag
				label={tagLabel}
				type={TagType.INFO}
				icon={IconIdentifier.ARCHIVE}
			/>
		);
	};

	return (
		<div className="card-sequence-list-item-header">
			<div className="card-sequence-list-item-header__content">
				<span className="card-sequence-list-item-header__title-wrapper">
					<h1 className="card-sequence-list-item-header__title">
						{title}
					</h1>
				</span>

				{subTitle}
			</div>

			{scheduleTag}
			{archivedTagElement()}
		</div>
	);
};
