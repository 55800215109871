import { L10nContext } from 'context/L10nContext';

import { Guid } from 'lib/guid/Guid';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { Badge, BadgeColor } from 'presentation/ui/partials/badge/Badge';
import { TextLink } from 'presentation/ui/partials/text-link/TextLink';
import { useContext } from 'react';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentEntryDashboard } from 'services/documents/presentation/ui/document-entry/DocumentEntryDashboard';
import { LoadingSpinnerEmptyCard } from '../../../../ui/components/loading-spinner-empty-card/LoadingSpinnerEmptyCard.';

export interface DashboardDocumentsCollectionProps {
	documents: ReadonlyArray<DocumentViewModel>;
	onAction: (payload: any, document: any) => void;
	fetchStatus?: boolean;
}

export const DashboardDocumentsCollection = (props: DashboardDocumentsCollectionProps): JSX.Element => {
	const { documents, onAction, fetchStatus } = props;

	const l10nContext = useContext(L10nContext);

	const handleActionClick = (payload: any, document: any) => {
		onAction(payload, document);
	};

	const renderDocumentCards = () => {
		let cards;

		if (fetchStatus) {
			return (
				<CardEmpty>
					<LoadingSpinnerEmptyCard />
				</CardEmpty>
			);
		}

		if (documents.length > 0) {
			cards = documents.map((document: DocumentViewModel) => (
				<DocumentEntryDashboard
					key={Guid.generate()}
					documentEntry={document}
					onActionClick={(action) => handleActionClick(action, document)}
				/>
			));
		} else {
			cards = <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.documents', 'kein Dokument')} />;
		}

		return cards;
	};

	const count = documents?.length === 0 ?
		'' :
		<Badge
			label={l10nContext.formatNumber(documents.length, 0)}
			color={BadgeColor.PRIMARY}
		/>;

	return (
		<div className="documents-collection">
			<Expandable>
				<ExpandableHeader caption={l10nContext.translate('view.dashboard.headline.documents', 'letzte Dokumente')}>
					<TextLink
						text={l10nContext.translate('common.textlink.all', 'alle')}
						target="/documents"
					/>

					{count}
				</ExpandableHeader>
				<ExpandableBody>
					<CardCollectionLayout>
						<ExpandableBody>
							<CardCollectionLayout>
								{renderDocumentCards()}
							</CardCollectionLayout>
						</ExpandableBody>
					</CardCollectionLayout>
				</ExpandableBody>
			</Expandable>
		</div>
	);
};
