import { PublicLocalStorage } from 'lib/browser-storage/PublicLocalStorage';
import { PublicLocalStorageKey } from 'lib/browser-storage/PublicLocalStorageKey';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { Timeout } from 'lib/timeout/Timeout';

import { ApiConnector, AuthenticationConnectorResult } from 'services/core/lib/auth/api-connector/ApiConnector';
import { ActorModel, ClientModel, JsonWebToken } from 'services/core/lib/auth/AuthService';
import { LocalActorRepository } from 'services/core/lib/auth/local/LocalActorRepository';

interface AuthenticationPropellerResponse {
	readonly JsonWebToken?: JsonWebToken;
	readonly Actor?: ActorModel;
	readonly Clients?: Array<ClientModel>;
}

interface AuthenticationFileDownloadTokenPropellerResponse {
	FileDownloadToken?: string;
}

interface AuthenticationTestPropellerResponse {
	readonly Result?: string;
}

export class HttpApiConnector implements ApiConnector {

	/* eslint-disable prefer-template */
	private static authEndpoint: string = process.env.REACT_APP_API_BASE_URL + 'core/api/v1/authenticate/actor/';
	private static unauthEndpoint: string = process.env.REACT_APP_API_BASE_URL + 'core/api/v1/authenticate/invalidate/';
	private static fileDownloadTokenEndpoint: string = process.env.REACT_APP_API_BASE_URL + 'core/api/v1/authenticate/file-download-token/';
	private static testEndpoint: string = process.env.REACT_APP_API_BASE_URL + 'core/api/v1/authenticate/test/';

	/* eslint-enable prefer-template */

	public async authenticate(user: string, password: string): Promise<AuthenticationConnectorResult> {
		const request = new Request(
			HttpApiConnector.authEndpoint,
			{
				method: 'put',
				cache: 'no-cache',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					User: user,
					Password: password
				})
			}
		);

		const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error());
		const parsedBody = await response.json() as AuthenticationPropellerResponse;

		const actor = parsedBody?.Actor ?? null as ActorModel;
		const availableClientModelCollection = parsedBody?.Clients ?? [] as Array<ClientModel>;
		let jsonWebToken = parsedBody?.JsonWebToken ?? null as JsonWebToken;
		if (jsonWebToken !== null) {
			jsonWebToken = {
				...jsonWebToken,
				ValidUntil: jsonWebToken.ValidUntil * 1000
			};
		}

		if (actor !== null) {
			let latestActors = PublicLocalStorage.get().read<Array<string>>(PublicLocalStorageKey.LATEST_ACTOR_USERS) ?? [];
			latestActors = latestActors.filter((latestActor): boolean => {
				return latestActor !== user;
			});
			latestActors.unshift(user);
			latestActors = latestActors.slice(0, 4);

			PublicLocalStorage.get().write<Array<string>>(PublicLocalStorageKey.LATEST_ACTOR_USERS, latestActors);

			await LocalActorRepository.get().addActor(actor, password, availableClientModelCollection);
		}

		return {
			ActorModel: actor,
			AvailableClientModelCollection: availableClientModelCollection,
			JsonWebToken: jsonWebToken
		} as AuthenticationConnectorResult;
	}

	public async unauthenticate(jsonWebToken?: JsonWebToken): Promise<void> {
		jsonWebToken = jsonWebToken ?? null;
		if (jsonWebToken !== null) {
			const request = new Request(
				HttpApiConnector.unauthEndpoint,
				{
					method: 'put',
					cache: 'no-cache',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						JsonWebToken: jsonWebToken.Token
					})
				}
			);

			try {
				await Timeout.wrap<Response>(fetch(request), 5000, new Error());
			} catch (e) {// eslint-disable-line no-empty
			}
		}
	}

	public async fileDownloadToken(jsonWebToken?: JsonWebToken): Promise<string> {
		jsonWebToken = jsonWebToken ?? null;
		if (jsonWebToken === null) {
			throw new PropellerError('JSON_WEB_TOKEN_MISSING', '401');
		}
		const request = new Request(
			HttpApiConnector.fileDownloadTokenEndpoint,
			{
				method: 'get',
				cache: 'no-cache',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + jsonWebToken?.Token
				}
			}
		);

		let parsedBody: AuthenticationFileDownloadTokenPropellerResponse;
		try {
			const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error());
			parsedBody = await response.json() as AuthenticationFileDownloadTokenPropellerResponse;
		} catch (e) {
			throw new PropellerError('FILE_DOWNLOAD_TOKEN_RESPONSE_INVALID');
		}

		return parsedBody?.FileDownloadToken;
	}

	public async test(jsonWebToken?: JsonWebToken): Promise<boolean> {
		jsonWebToken = jsonWebToken ?? null;
		if (jsonWebToken === null) {
			return false;
		}
		const request = new Request(
			HttpApiConnector.testEndpoint,
			{
				method: 'get',
				cache: 'no-cache',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + jsonWebToken?.Token
				}
			}
		);

		let parsedBody: AuthenticationTestPropellerResponse;
		try {
			const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error());
			parsedBody = await response.json() as AuthenticationTestPropellerResponse;
		} catch (e) {
			return false;
		}

		return parsedBody?.Result === 'ok';
	}

}
