import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DebugConsole } from 'lib/debug/DebugConsole';

import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import {
	WasteManagementReportEluateModelConverter as ModelConverter, WasteManagementReportEluateStoreModel,
	WasteManagementReportEluateStoreModel as StoreModel,
	WasteManagementReportEluateViewModel as ViewModel
} from '../../waste-management-report/domain/models/WasteManagementReportEluateModel';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { ExternalWasteManagementReportEluateHttpPersistence } from '../persistence/ExternalWasteManagementReportEluateHttpPersistence';

// Declare a eluate state type
export interface ExternalWasteManagementReportEluateState {
	externalWasteManagementReportEluates: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	externalWasteManagementReportEluates: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ExternalWasteManagementReportEluateState;

// Implementation of the async actions
export const fetchExternalWasteManagementReportEluates = createAsyncThunk(
	'externalWasteManagementReportEluates/fetch',
	async (params: { reportUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new ExternalWasteManagementReportEluateHttpPersistence(params.reportUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Silently abort the action
			const { externalWasteManagementReportEluates } = getState() as RootState;
			return checkFetchStatus(externalWasteManagementReportEluates.fetchStatus);
		}
	}
);

// Slice definition
export const externalWasteManagementReportEluatesSlice = createSlice({
	name: 'externalWasteManagementReportEluates',
	initialState,
	// Regular syncronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	extraReducers: {
		[String(fetchExternalWasteManagementReportEluates.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalWasteManagementReportEluates.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.externalWasteManagementReportEluates = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalWasteManagementReportEluates.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

export const { resetState, resetActionStatus } = externalWasteManagementReportEluatesSlice.actions;

// Export the reducer as default
export default externalWasteManagementReportEluatesSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectExternalWasteManagementReportEluates = (): (rootSTate: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.externalWasteManagementReportEluates.externalWasteManagementReportEluates.filter((storeModel):
		WasteManagementReportEluateStoreModel => {
			return storeModel;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalWasteManagementReportEluatesByGenerator = (generatorUuid: string): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.externalWasteManagementReportEluates.externalWasteManagementReportEluates.filter((storeModel): boolean => {
			return storeModel.Generator === generatorUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};
