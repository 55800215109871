import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';
import { selectGenerators } from '../../../../../nuclide/store/generatorSlice';
import { CreatableWasteManagementReportViewModel } from '../../../../domain/models/WasteManagementReportModel';
import { createWasteManagementReport } from '../../../../store/wasteManagementReportSlice';
import { WasteManagementGeneratorReportCreateFormStep1 } from './WasteManagementGeneratorReportCreateFormStep1';
import { WasteManagementGeneratorReportCreateFormStep2 } from './WasteManagementGeneratorReportCreateFormStep2';


/**
 * The report create form
 */
export const WasteManagementGeneratorReportCreateForm = (): JSX.Element => {

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const generators = useTypedSelector(selectGenerators(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const newWasteManagementReportViewModel = useRef<CreatableWasteManagementReportViewModel>({
		PeriodDateStart: new Date(),
		PeriodDateEnd: new Date(),
		ValidUntil: new Date(),
	} as CreatableWasteManagementReportViewModel);

	const onChangeReportProp = (prop: string, value: any) => {
		newWasteManagementReportViewModel.current[prop] = value;
	};

	const reportPeriodDateStartFormatted = new Date(newWasteManagementReportViewModel.current.PeriodDateStart);
	const reportPeriodDateEndFormatted = new Date(newWasteManagementReportViewModel.current.PeriodDateEnd);

	const filteredGeneratorsUuids: string[] = [];
	if (generators.length > 0) {
		generators.filter((generator) => {
			const generatorDeliveryDateFormatted = new Date(generator?.DeliveryDate);
			if (reportPeriodDateStartFormatted < generatorDeliveryDateFormatted &&
				reportPeriodDateEndFormatted > generatorDeliveryDateFormatted) {
				filteredGeneratorsUuids.push(generator.Uuid);
			}
			return filteredGeneratorsUuids;
		});
	}

	const handleCreate = (): void => {
		dispatch(createWasteManagementReport({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			viewModel: {
				Name: null,
				Password: null,
				PeriodDateStart: null,
				PeriodDateEnd: null,
				ValidUntil: null,
				WasteManagementReportGenerators: filteredGeneratorsUuids,
				WasteManagementReportCyclotronProducts: [],
				...newWasteManagementReportViewModel.current
			}
		}));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const requiredInputs = ['Name', 'PeriodDateStart', 'PeriodDateEnd', 'ValidUntil', 'Password'];

	const goToStep2 = () => {
		let newReportModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(newWasteManagementReportViewModel.current?.[requiredInput] ?? null) === null
				|| newWasteManagementReportViewModel.current?.[requiredInput] === ''
				|| String(newWasteManagementReportViewModel.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newReportModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newReportModelValid);
	};

	const showCreateModalStep = showStep1 === true ?
		<WasteManagementGeneratorReportCreateFormStep1
			report={newWasteManagementReportViewModel.current}
			onClickNext={goToStep2}
			onChangeReportProp={onChangeReportProp}
			invalidInputs={invalidInputs}
		/> :
		<WasteManagementGeneratorReportCreateFormStep2
			report={newWasteManagementReportViewModel.current as CreatableWasteManagementReportViewModel}
			onClickPrevious={goToStep1}
			onClickConfirm={handleCreate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
