import { ComponentPropsWithoutRef } from 'react';

import { ClassName } from 'lib/class-name/ClassName';

import { RootLayoutSectionSection } from 'presentation/ui/layouts/root-layout/root-layout-section/RootLayoutSectionSection';

import './root-layout-section.scss';

export interface RootLayoutSectionProps extends ComponentPropsWithoutRef<'section'> {
	section?: RootLayoutSectionSection;
}

export const RootLayoutSection = (props: RootLayoutSectionProps): JSX.Element => {
	const componentStyle = `root-layout-section${props.section ?
		` root-layout-section--${ClassName.fromEnumValue(String(props.section))}` :
		''}`;

	return (
		<section className={componentStyle}>
			{props.children}
		</section>
	);

};
