import { Optional } from '../misc/Optional';
import { Value } from './Value';

export class Undecay implements Value<number> {
	private readonly result: Optional<number>;

	public constructor(value: Value<number>, hours: Value<number>, halfTimeHours: Value<number>) {
		this.result = calculate(value.getSingle(), hours.getSingle(), halfTimeHours.getSingle());
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<number> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<number> {
		return this.result;
	}
}

function calculate(value: Optional<number>, hours: Optional<number>, halftimeHours: Optional<number>): Optional<number> {
	if (value !== null && hours !== null && halftimeHours !== null) {
		return value * Math.exp(Math.log(2) * (hours / halftimeHours));
	}
	return null;
}
