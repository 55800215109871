import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { SiteTitle } from 'presentation/ui/components/site-title/SiteTitle';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'services/core/context/AuthContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Permission, permissionGroupReport } from 'services/core/lib/auth/AuthService';
import { fetchReports, resetActionStatus, selectReports } from 'services/report/store/reportSlice';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { Route } from 'router/Route';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { ClientContext } from 'services/core/context/ClientContext';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { CardReportList } from 'presentation/ui/components/cards/card-report/card-report-list/CardReportList';
import { LoadingSpinner } from '../../../../presentation/ui/components/loading-spinner/LoadingSpinner';
import { ReportCreateModal } from '../ui/report-action/report-create-modal/ReportCreateModal';

export const ReportsView = (): JSX.Element => {

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

	// Use a custom selector function to provide type information for the state defined in the slice
	const storeFetchStatus = useTypedSelector(state => state.reports.fetchStatus);
	const storeActionStatus = useTypedSelector(state => state.reports.actionStatus);
	const storeLastActionError = useTypedSelector(state => state.reports.lastActionError);

	// Handle permissions
	if (!authContext.hasAnyPermission(permissionGroupReport)) {
		throw new Error('Permission denied');
	}

	// Read the reports from the store
	const reports = useSelector(selectReports());

	// Read reports from the server - reports only online available
	useEffect(() => {
		if (storeFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchReports({
				clientUuid: clientContext.selectedClientUuid,
				facilityUuid: facilityContext.selectedFacilityUuid
			}));
		}
	});

	const renderNotifications = (): Array<JSX.Element> => {
		const notifications = [];
		if (storeActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					key={Guid.generate()}
					message={storeLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}
		return notifications;
	};

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permissionCreate: authContext.hasPermission(Permission.REPORT_CREATE)
	};

	// Handle close modal for success and failure
	const handleDismiss = () => {
		setShowCreateModal(false);
	};

	if (storeFetchStatus === AsyncFetchStatus.IDLE || storeFetchStatus === AsyncFetchStatus.PENDING) {
		return (
			<LoadingSpinner />
		);
	}

	// Show report create modal
	const showModal = showCreateModal ?
		<ReportCreateModal onDismiss={handleDismiss} /> :
		null;

	const handleModalClick = () => {
		dispatch(resetActionStatus());
		setShowCreateModal(true);
	};

	const renderReports = (): Array<JSX.Element> => {
		return reports.map<JSX.Element>((reportModel) => {
			const formatedValidUntilTime = l10nContext.formatDate(reportModel.ValidUntil);

			return (
				<CardReportList
					key={reportModel.Uuid}
				>
					<CardItemHeader
						title={reportModel.Name}
						subtitle={`${l10nContext.translate('reports.validUntil', 'Gültig bis') + ': '}${formatedValidUntilTime}`}
					/>
					<CardItemSingleControl
						cardId={reportModel.Uuid}
						actionDetails={CardItemControlState.ENABLED}
						onClick={() => history.push(`${Route.REPORTS}/${reportModel.Uuid}`)}
					/>
				</CardReportList>
			);
		});
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>
					<ViewLayoutSection>
						{showModal}
						<SiteTitle
							title={l10nContext.translate('view.reports.title', 'Berichte')}
							button={{
								buttonText: l10nContext.translate('view.reports.modal.action.create.caption', 'Bericht hinzufügen'),
								disabled: !user.permissionCreate,
								icon: IconIdentifier.ADD,
								onClick: () => handleModalClick()
							}}
							isTransparent
						/>
					</ViewLayoutSection>
					<CardCollectionLayout>
						{renderReports()}
					</CardCollectionLayout>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>

		</MainLayout>
	);
};
