import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	RecordModelConverter as ModelConverter,
	RecordPersistenceModel as PersistenceModel,
	RecordStoreModel as StoreModel,
	RecordViewModel as ViewModel,
	CreatableRecordViewModel as CreatableViewModel
} from 'services/device/domain/model/RecordModel';

export class RecordHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'device/api/v1';
	protected collectionControllerUri = 'records';
	protected entityControllerUri = 'record';

	protected responseCollectionKey = 'RecordModelCollection';
	protected responseEntityKey = 'RecordModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

	async fetchCollectionBySequence(sequenceUuid: string): Promise<Array<StoreModel>> {
		return super.fetchCollection(() => {
			return process.env.REACT_APP_API_BASE_URL + this.serviceApiControllerUri + '/'
				+ this.getClientUuid() + '/' + this.getFacilityUuid() + '/sequence/' + sequenceUuid + '/records';
		});
	}

	async fetchByRecordId(recordUuid: string): Promise<Array<StoreModel>> {
		return super.fetchCollection(() => {
			return process.env.REACT_APP_API_BASE_URL + this.serviceApiControllerUri + '/'
				+ this.getClientUuid() + '/' + this.getFacilityUuid() + this.entityControllerUri + '/' + recordUuid ;
		});
	}

	async update<ResponseStoreModel extends StoreModel = StoreModel>(storeModel: StoreModel): Promise<ResponseStoreModel | null> {
		return await super.update(storeModel) as ResponseStoreModel;
	}

	async create<ResponseStoreModel extends StoreModel = StoreModel>(storeModel: StoreModel): Promise<ResponseStoreModel> {
		return await super.create(storeModel) as ResponseStoreModel;
	}

	async delete<ResponseStoreModel extends StoreModel = StoreModel>(storeModel: StoreModel): Promise<ResponseStoreModel | null> {
		return await super.delete(storeModel) as ResponseStoreModel;
	}

}
