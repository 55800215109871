import './card-empty.scss';

export interface CardEmptyProps {
	message?: string;
	children?: JSX.Element;
}

export const CardEmpty = (props: CardEmptyProps): JSX.Element => {
	return (
		<div className="card-empty">
			{
				props.message ?
					<p className="card-empty__message">
						{props.message}
					</p>
					: null
			}
			{props.children || null}
		</div>
	);
};

