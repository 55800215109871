import { memo, useContext } from 'react';
import { L10nContext } from '../../../../../context/L10nContext';
import { Guid } from '../../../../../lib/guid/Guid';
import {
	getCyclotronProductIsotopeDefinition
} from '../../../../nuclide/domain/model/isotopes/NuclideIsotopeDefinition';

import { NuclideModelActivityUnit } from '../../../../nuclide/domain/model/NuclideModelActivityUnit';
import { CyclotronProductsByNuclide, SumOfCyclotronProductsByNuclids } from '../../view/CyclotronProductsByNuclideType';
import './external-waste-management-report-cyclotron-product-annual-report.scss';

export interface ExternalWasteManagementReportCyclotronProductAnnualReportProps {
	cyclotronProductsByNuclides: Array<CyclotronProductsByNuclide>
}

const KILO_BECQUEREL: number = 1000;
const MEGA_BECQUEREL = KILO_BECQUEREL * 1000;
const GIGA_BECQUEREL = MEGA_BECQUEREL * 1000;

export const ExternalWasteManagementReportCyclotronProductAnnualReport
	= memo((props: ExternalWasteManagementReportCyclotronProductAnnualReportProps): JSX.Element => {
		const { cyclotronProductsByNuclides } = props;

		const l10nContext = useContext(L10nContext);

		const renderSumByNuclide = (): JSX.Element[] => {
			const sumsOfCyclotronProductsByNuclids: Array<SumOfCyclotronProductsByNuclids> = [];
			cyclotronProductsByNuclides.forEach((cyclotronProductByNuclide) => {

				let sumAllCyclotronProductActivities = 0;
				cyclotronProductByNuclide.CyclotronProducts.map((cyclotronProduct) => {
					if (cyclotronProduct.ActivityUnit === NuclideModelActivityUnit.KILO_BEQUEREL) {
						sumAllCyclotronProductActivities += cyclotronProduct.NominalActivity * KILO_BECQUEREL;
					}
					if (cyclotronProduct.ActivityUnit === NuclideModelActivityUnit.MEGA_BEQUEREL) {
						sumAllCyclotronProductActivities += cyclotronProduct.NominalActivity * MEGA_BECQUEREL;
					}
					if (cyclotronProduct.ActivityUnit === NuclideModelActivityUnit.GIGA_BEQUEREL) {
						sumAllCyclotronProductActivities += cyclotronProduct.NominalActivity * GIGA_BECQUEREL;
					}
					return sumAllCyclotronProductActivities;
				});

				const newSumOfCyclotronProductsByNuclids: SumOfCyclotronProductsByNuclids = {
					Nuclide: cyclotronProductByNuclide.Nuclide,
					Sum: sumAllCyclotronProductActivities
				};
				sumsOfCyclotronProductsByNuclids.push(newSumOfCyclotronProductsByNuclids);
			});

			return (
				sumsOfCyclotronProductsByNuclids.map((sumOfCyclotronProduct): JSX.Element => {

					const translations = {
						nuclide: { label: l10nContext.translate('externalWasteManagementView.cyclotronProducts.nuclide', 'Nuklid'),
							value: l10nContext.translate(getCyclotronProductIsotopeDefinition(sumOfCyclotronProduct.Nuclide).getLabelKey()) },
						sum: { label: l10nContext.translate('externalWasteManagementView.cyclotronProducts.sumNominalActivities', 'Summe aller Nominalaktivitäten des Nuklids'),
							value: l10nContext.formatNumber(sumOfCyclotronProduct.Sum / 1000000) + ' MBq' }

					};

					const attributes = () => {
						return Object.entries(translations).map((translation) => {
							const { label, value } = translation[1];

							const styleClass = 'attribute';

							return (
								<label
									key={Guid.generate()}
									className={styleClass}
								>
									<strong>{label}:&nbsp;</strong>
									<p>{value }</p>
								</label>
							);
						});
					};

					return (
						<div
							key={Guid.generate()}
							className='external-waste-management-report-cyclotron-product-annual-report__attributes'
						>
							{attributes()}
						</div>
					);
				})
			);
		};

		return (
			<div className='external-waste-management-report-cyclotron-product-annual-report'>
				{renderSumByNuclide()}
			</div>
		);
	});
