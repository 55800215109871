import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { GeneratorTableHead } from './generator-table-head/GeneratorTableHead';
import { GeneratorTableBody } from './generator-table-body/GeneratorTableBody';

import 'services/nuclide/presentation/ui/generator-table/generator-table.scss';

export interface GeneratorTableProps {
	generator?: GeneratorViewModel,
	eluatesDate: string,
	onEdit?: (eluate: EluateViewModel) => void,
	onToWastemanagement?: (eluate: EluateViewModel) => void,
	onDelete?: (eluate: EluateViewModel) => void
}

export const GeneratorTable = (props: GeneratorTableProps): JSX.Element => {
	const { generator, eluatesDate, onEdit, onToWastemanagement, onDelete } = props;

	return (
		<div className='generator-table-wrapper'>
			<div className='generator-table-wrapper__pane'>
				<table className='generator-table'>
					<GeneratorTableHead />
					<GeneratorTableBody
						generator={generator}
						eluatesDate={eluatesDate}
						onEdit={(eluate) => onEdit(eluate)}
						onToWastemanagement={(eluate) => onToWastemanagement(eluate)}
						onDelete={(eluate) => onDelete(eluate)}
					/>
				</table>
			</div>
		</div>
	);
};
