import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import { ReportRecordViewModel } from '../../../domain/model/ReportRecordModel';
import { ReportRecordDocumentsModalContent } from './ReportRecordDocumentsModalContent';

export interface ReportRecordDocumentsModalProps {
	record: ReportRecordViewModel;
	onDismiss?: () => void;
}

export const ReportRecordDocumentsModal = (props: ReportRecordDocumentsModalProps): JSX.Element => {
	const onDismiss = props.onDismiss;
	const reportRecordViewModel = props.record;

	const l10nContext = useContext(L10nContext);

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.documents.modal.action.addDocu.caption', 'Dokument(e)')}
				captionIcon={IconIdentifier.DOC}
			/>
			<ModalDialogueSection>
				<ReportRecordDocumentsModalContent record={reportRecordViewModel} />
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
