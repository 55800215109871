import { useContext, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { FacilityContext } from 'services/core/context/FacilityContext';

import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';
import { ClientContext } from 'services/core/context/ClientContext';

export const FacilitySelect = (): JSX.Element => {

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	const [selectedFacilityUuid, setSelectedFacilityUuid] = useState<string>(facilityContext.selectedFacilityUuid);

	const handleApply = (): void => {
		facilityContext.select(selectedFacilityUuid);
	};

	const facilitySelectOptions = clientContext.selectedClient().Facilities.map((facilityModel): SelectOption => {
		return {
			value: facilityModel.Uuid,
			label: facilityModel.Name,
			checked: selectedFacilityUuid === facilityModel.Uuid
		};
	});

	return (
		<FormLayout 
			align={FormLayoutSectionAlign.ALIGN_VERTICAL}
			onSubmit={handleApply}
		>
			<FormLayoutSection>
				<Label label={l10nContext.translate('view.clientFacilitySelect.facilitySelect', 'Standort Auswahl')}>
					<Select
						options={facilitySelectOptions}
						onchange={setSelectedFacilityUuid}
					/>
				</Label>
			</FormLayoutSection>
			<FormLayoutSection>
				<ButtonPrimary
					buttonText={l10nContext.translate('common.button.confirm', 'Bestätigen')}
					name='select-button'
					type="submit"
				/>
			</FormLayoutSection>
		</FormLayout>
	);
};
