import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import { RecordStoreData, RecordViewData } from 'services/device/domain/business/common/record/RecordData';
import { DeviceType } from 'services/device/domain/business/inventory/DeviceType';
import { SequenceType } from 'services/device/domain/business/inventory/SequenceType';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { Valuation } from 'services/device/domain/business/common/Valuation';
import { ViolationError } from 'services/device/domain/business/common/violation/ViolationError';
import {
	DocumentModelConverter,
	DocumentPersistenceModel,
	DocumentStoreModel,
	DocumentViewModel
} from '../../../documents/domain/model/DocumentModel';

export type RecordUuid = string;

export interface ReportRecordPersistenceModel extends PersistenceModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: string;
	Sequence: string;
	SequenceType: string;
	Supplemented: boolean;
	Replaced: boolean;
	Replaces?: string;
	RecordedAt: number;
	RecordedByName: string;
	UpdatedByName?: string;
	RecordConfiguration: RecordStoreData;
	Valuation: Valuation;
	Documents: Array<DocumentPersistenceModel>
}

export interface ReportRecordStoreModel extends StoreModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: string;
	Sequence: string;
	SequenceType: string;
	Supplemented: boolean;
	Replaced: boolean;
	Replaces?: string;
	RecordedAt: number;
	RecordedByName: string;
	UpdatedByName?: string;
	RecordConfiguration: RecordStoreData;
	Valuation: Valuation;
	Documents: Array<DocumentStoreModel>
}

export interface ReportRecordViewModel extends ViewModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: DeviceType;
	Sequence: string;
	SequenceType: SequenceType;
	Supplemented: boolean;
	Replaced: boolean;
	Replaces?: string;
	RecordedAt: Date;
	RecordedByName: string;
	UpdatedByName?: string;
	RecordConfiguration: RecordViewData;
	Documents: Array<DocumentViewModel>
}

export interface CreatableReportRecordViewModel extends CreatableViewModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: DeviceType;
	Sequence: string;
	SequenceType: SequenceType;
	RecordedByName: string;
	UpdatedByName?: string;
	RecordConfiguration: RecordViewData;
	Valuation?: Valuation;
	Documents: Array<DocumentViewModel>
}

// eslint-disable-next-line max-len
export class ReportRecordModelConverter extends ModelConverter<ReportRecordPersistenceModel, ReportRecordStoreModel, ReportRecordViewModel, CreatableReportRecordViewModel> {

	public fromStoreModel(storeModel?: ReportRecordStoreModel): this {
		super.fromStoreModel(storeModel, (sModel, model) => {
			model.RecordedAt = Math.floor(sModel.RecordedAt / 1000);
			model.Documents = sModel.Documents.map((relatedModel: DocumentStoreModel) => {
				return new DocumentModelConverter().fromStoreModel(relatedModel).toPersistenceModel();
			});
		});
		return this;
	}

	public fromViewModel(viewModel?: ReportRecordViewModel): this {
		super.fromViewModel(viewModel, (vModel, model) => {
			model.RecordConfiguration = getSequenceTypeDefinition(vModel.SequenceType).restoreRecordFromViewData(vModel.RecordConfiguration).toStoreData();
			model.RecordedAt = Math.floor(vModel.RecordConfiguration.values.recordedAt.getTime() / 1000);
			model.Valuation = vModel.RecordConfiguration.valuation;
			model.Documents = vModel.Documents.map((relatedModel) => {
				return new DocumentModelConverter().fromViewModel(relatedModel).toPersistenceModel();
			});
		});

		return this;
	}

	public toStoreModel(): ReportRecordStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.RecordedAt = model.RecordedAt * 1000;
			sModel.Documents = model.Documents.map((relatedModel: DocumentPersistenceModel) => {
				return new DocumentModelConverter().fromPersistenceModel(relatedModel).toStoreModel();
			});
		});
	}

	public toViewModel(): ReportRecordViewModel | null {
		throw new Error('Use toViewModelFromSequence instead');
	}

	public toViewModelFromSequence(sequenceViewModel: SequenceViewModel): ReportRecordViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.DeviceType = model?.DeviceType as DeviceType ?? null;
			vModel.SequenceType = model?.SequenceType as SequenceType ?? null;
			vModel.RecordedAt = new Date(model.RecordedAt * 1000);
			vModel.Documents = model.Documents.map((relatedModel: DocumentPersistenceModel) => {
				return new DocumentModelConverter().fromPersistenceModel(relatedModel).toViewModel();
			});

			try {
				const sequenceTypeDefinition = getSequenceTypeDefinition(vModel.SequenceType);
				const sequence = sequenceTypeDefinition.restoreSequenceFromViewData(sequenceViewModel.SequenceConfiguration);
				const recordDefinition = sequenceTypeDefinition.getRecordDefinition(sequence, sequenceViewModel.SequenceMemory);
				vModel.RecordConfiguration = recordDefinition.restoreRecordFromStoreData(model.RecordConfiguration).toViewData();
			} catch (e) {
				if (e instanceof ViolationError) {
					console.warn('Record violation', vModel.Uuid, e.getViolations());
				}
			}
		});
	}

}
