import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import {
	CreatableReportDocumentViewModel,
	ReportDocumentModelConverter,
	ReportDocumentPersistenceModel,
	ReportDocumentStoreModel,
	ReportDocumentViewModel
} from 'services/report/domain/model/ReportDocumentModel';
import {
	CreatableReportSequenceViewModel,
	ReportSequenceModelConverter,
	ReportSequencePersistenceModel,
	ReportSequenceStoreModel,
	ReportSequenceViewModel
} from 'services/report/domain/model/ReportSequenceModel';

export type ReportModelUuid = string;
export interface ReportPersistenceModel extends PersistenceModel {
	Name: string;
	Password?: string;
	PeriodDateStart: number;
	PeriodDateEnd: number;
	ValidUntil: number;
	ReportSequences: Array<ReportSequencePersistenceModel>;
	ReportDocuments: Array<ReportDocumentPersistenceModel>;
}

export interface ReportStoreModel extends StoreModel {
	Name: string;
	Password?: string;
	PeriodDateStart: number;
	PeriodDateEnd: number;
	ValidUntil: number;
	ReportSequences: Array<ReportSequenceStoreModel>;
	ReportDocuments: Array<ReportDocumentStoreModel>;
}

export interface ReportViewModel extends ViewModel {
	Name: string;
	Password?: string;
	PeriodDateStart: Date;
	PeriodDateEnd: Date;
	ValidUntil: Date;
	ReportSequences: Array<ReportSequenceViewModel>;
	ReportDocuments: Array<ReportDocumentViewModel>;
}

export interface CreatableReportViewModel extends CreatableViewModel {
	Name: string;
	Password: string;
	PeriodDateStart: Date;
	PeriodDateEnd: Date;
	ValidUntil: Date;
	ReportSequences: Array<CreatableReportSequenceViewModel>;
	ReportDocuments: Array<CreatableReportDocumentViewModel>;
}

export class ReportModelConverter
	extends ModelConverter<ReportPersistenceModel, ReportStoreModel, ReportViewModel, CreatableReportViewModel> {

	fromStoreModel(storeModel?: ReportStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			model.PeriodDateStart = Math.floor(sModel.PeriodDateStart / 1000);
			model.PeriodDateEnd = Math.floor(sModel.PeriodDateEnd / 1000);
			model.ValidUntil = Math.floor(sModel.ValidUntil / 1000);
			model.ReportSequences = sModel.ReportSequences.map((relatedModel) => {
				return new ReportSequenceModelConverter().fromStoreModel(relatedModel).toPersistenceModel();
			});
			model.ReportDocuments = sModel.ReportDocuments.map((relatedModel) => {
				return new ReportDocumentModelConverter().fromStoreModel(relatedModel).toPersistenceModel();
			});
		});
	}

	fromViewModel(viewModel?: ReportViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.PeriodDateStart = Math.floor(vModel.PeriodDateStart.getTime() / 1000);
			model.PeriodDateEnd = Math.floor(vModel.PeriodDateEnd.getTime() / 1000);
			model.ValidUntil = Math.floor(vModel.ValidUntil.getTime() / 1000);
			model.ReportSequences = vModel.ReportSequences.map((relatedModel) => {
				return new ReportSequenceModelConverter().fromViewModel(relatedModel).toPersistenceModel();
			});
			model.ReportDocuments = vModel.ReportDocuments.map((relatedModel) => {
				return new ReportDocumentModelConverter().fromViewModel(relatedModel).toPersistenceModel();
			});
		});
	}

	toStoreModel(): ReportStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.PeriodDateStart = model.PeriodDateStart * 1000;
			sModel.PeriodDateEnd = model.PeriodDateEnd * 1000;
			sModel.ValidUntil = model.ValidUntil * 1000;
			sModel.ReportSequences = model.ReportSequences.map((relatedModel: ReportSequencePersistenceModel) => {
				return new ReportSequenceModelConverter().fromPersistenceModel(relatedModel).toStoreModel();
			});
			sModel.ReportDocuments = model.ReportDocuments.map((relatedModel: ReportDocumentPersistenceModel) => {
				return new ReportDocumentModelConverter().fromPersistenceModel(relatedModel).toStoreModel();
			});
		});
	}

	toViewModel(): ReportViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.PeriodDateStart = new Date(model.PeriodDateStart * 1000);
			vModel.PeriodDateEnd = new Date(model.PeriodDateEnd * 1000);
			vModel.ValidUntil = new Date(model.ValidUntil * 1000);
			vModel.ReportSequences = model.ReportSequences.map((relatedModel: ReportSequencePersistenceModel) => {
				return new ReportSequenceModelConverter().fromPersistenceModel(relatedModel).toViewModel();
			});
			vModel.ReportDocuments = model.ReportDocuments.map((relatedModel: ReportDocumentPersistenceModel) => {
				return new ReportDocumentModelConverter().fromPersistenceModel(relatedModel).toViewModel();
			});
		});
	}

}
