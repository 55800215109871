import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import { RoleHttpPersistence as HttpPersistence } from 'services/core/persistence/RoleHttpPersistence';
import {
	RoleModelConverter as ModelConverter,
	RoleStoreModel as StoreModel,
	RoleViewModel as ViewModel,
} from 'services/core/domain/model/RoleModel';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { RootState } from 'store/store';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';

// Declare a device state type
export interface RoleState {
	roles: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	roles: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.IDLE,
	lastFetchError: null,
} as RoleState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
export const fetchRoles = createAsyncThunk(
	'roles/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { roles } = getState() as RootState;
			return checkFetchStatus(roles.fetchStatus);
		}
	}
);

// Slice definition
export const roleSlice = createSlice({
	name: 'roles',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s create slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchRoles.pending)]: (state) => {
			state.fetchStatus = AsyncFetchStatus.PENDING;
		},
		[String(fetchRoles.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.roles = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchRoles.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

export const { resetState } = roleSlice.actions;

// Export the reducer as default
export default roleSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectRoles = (): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const viewModels = rootState.roles.roles.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};
