import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { DateTimePicker } from 'presentation/ui/compositions/date-time-picker/DateTimePicker';
import { CreatableEluateViewModel } from 'services/nuclide/domain/model/EluateModel';
import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';
import { getEluateMeasurementUnitDefinitions } from 'services/nuclide/domain/model/NuclideMeasurementUnitDefinition';

export interface EluateCreateModalStep1Props {
	/**
	 * Eluate
	 */
	eluate: CreatableEluateViewModel;
	/**
	 * Button next action
	 */
	onClickNext: () => void;
	/**
	 * Invalid inputs
	 */
	invalidInputs?: string[];
	/**
	 * Change generator props
	 */
	onChangeEluateProp: (prop: string, value: string | Date | number) => void;
}

/**
 * The device create modal step 1
 */
export const EluateCreateFormStep1 = (props: EluateCreateModalStep1Props): JSX.Element => {
	const { eluate, invalidInputs = [], onChangeEluateProp, onClickNext } = props;

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	// Notification for invalid input fields
	const checkPlural = invalidInputs.length > 1 ? l10nContext.translate('common.modal.notificationErrorPlural', ' dürfen nicht leer sein') : l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein');
	let notification = null;
	if (invalidInputs.length > 0) {
		const invalidNotificationMessage = invalidInputs.join(l10nContext.translate('common.text.and', ' und '))
			+ checkPlural;
		notification = (
			<NotificationBar
				level={NotificationLevel.LEVEL_ERROR}
				message={invalidNotificationMessage}
			/>
		);
	}

	const renderExtractionDatetimeInput = (): JSX.Element | null => {
		if (authContext.hasPermission(Permission.GENERATOR_ELUAT_SUPPLEMENT)) {
			return (
				<FormLayoutSection>
					<Label label={`${l10nContext.translate('eluates.extractionDateTime', 'Extraktionszeitpunkt')}*`}>
						<DateTimePicker
							name="extractionDatetime"
							defaultValue={eluate?.ExtractionDatetime ?? new Date()}
							onChange={(event) => onChangeEluateProp('ExtractionDatetime', event)}
							required
						/>
					</Label>
				</FormLayoutSection>
			);
		}
		return null;
	};

	const eluateMeasurementUnitDefinitions = getEluateMeasurementUnitDefinitions();
	const eluateMeasurementUnits = eluateMeasurementUnitDefinitions.map((eluateMeasurementUnitDefinition): SelectOption => {

		return {
			label: l10nContext.translate(eluateMeasurementUnitDefinition.getLabelKey()),
			value: eluateMeasurementUnitDefinition.getMeasurementUnit(),
			checked: eluateMeasurementUnitDefinition.getMeasurementUnit() === eluate?.ActivityUnit ?? false,
		};
	});

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<NotificationBars>
				{notification}
			</NotificationBars>

			{renderExtractionDatetimeInput()}

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('eluates.activity', 'Aktivität')}*`}
				>
					<InputText
						type="number"
						defaultValue={eluate?.Activity ?? ''}
						onChange={(event) => onChangeEluateProp('Activity', Number(event.target.value))}
						step='any'
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.unit', 'Einheit')}*`}
				>
					<Select
						options={eluateMeasurementUnits}
						onchange={(optionValue: string) => onChangeEluateProp('ActivityUnit', optionValue)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
