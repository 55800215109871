import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { L10nContext } from 'context/L10nContext';

import { selectFilteredGenerators } from 'services/nuclide/store/generatorSlice';

import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { FilterChipSet } from 'presentation/ui/components/filter/filter-chip-set/FilterChipSet';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { GeneratorCollectionItem } from './GeneratorCollectionItem';


export const GeneratorCollection = (): JSX.Element => {

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Local state
	const [generatorsIncludeActive, setGeneratorsIncludeActive] = useState<boolean>(true);
	const [generatorsIncludeDispatched, setGeneratorsIncludeDispatched] = useState<boolean>(false);

	// Read the generators from the state store
	const generators = useSelector(selectFilteredGenerators(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		generatorsIncludeActive,
		generatorsIncludeDispatched
	));

	const renderFilters = (): JSX.Element => {
		return (
			<FilterChipSet
				label={l10nContext.translate('common.filter.status', 'Status')}
				chips={[
					{
						label: l10nContext.translate('common.filter.active', 'aktiv'),
						active: generatorsIncludeActive,
						onClick: (id, state) => {
							setGeneratorsIncludeActive(state);
						}
					},
					{
						label: l10nContext.translate('common.filter.dispatched', 'abgegegangen'),
						active: generatorsIncludeDispatched,
						onClick: (id, state) => {
							setGeneratorsIncludeDispatched(state);
						}
					},
				]}
			/>
		);
	};

	const renderGeneratorCards = (): JSX.Element => {
		if (generators.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.generators', 'Keine Generatoren')} />
			);
		}

		const cards = generators.map((generator) => (
			<GeneratorCollectionItem
				key={generator.Uuid}
				generator={generator}
			/>
		));

		return (
			<>
				{cards}
			</>
		);
	};

	return (
		<CardCollectionLayout>
			{renderFilters()}
			{renderGeneratorCards()}
		</CardCollectionLayout>
	);
} ;
