import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { FolderViewModel } from 'services/documents/domain/model/FolderModel';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';

export interface FolderMoveFormStep1Props {
	folder: FolderViewModel;
	folders: ReadonlyArray<FolderViewModel>;
	onChange: (folder: FolderViewModel) => void;
	onClickNext: () => void;
}

export const FolderMoveFormStep1 = (props: FolderMoveFormStep1Props): JSX.Element => {
	const { folder, folders, onChange, onClickNext } = props;

	const l10nContext = useContext(L10nContext);

	const folderNameMap: Map<string, string> = new Map();

	const selectOptions: Array<SelectOption> = folders
		.filter((childFolder: FolderViewModel): boolean => {
			return childFolder.Uuid !== folder.Parent;
		})
		.map((childFolder: FolderViewModel): SelectOption => {
			let prefix = '';
			if (folderNameMap.has(childFolder.Parent)) {
				prefix = folderNameMap.get(childFolder.Parent) + ' / ';
			}

			const name = prefix + childFolder.Name;

			folderNameMap.set(childFolder.Uuid, name);

			return {
				label: name,
				value: childFolder.Uuid,
				checked: childFolder.Uuid === folder.Parent
			} as SelectOption;
		});

	const handleChange = (selectedFolderUuid: string): void => {
		const selectedFolder = folders.find((predicate): boolean => {
			return predicate.Uuid === selectedFolderUuid;
		});
		onChange(selectedFolder);
	};

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<FormLayoutSection>
				<Label label={`${l10nContext.translate('view.documents.modal.action.move.folderName', 'Ordner')}*`}>
					<Select
						onchange={handleChange}
						options={selectOptions}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
