import { Optional } from '../misc/Optional';
import { Value } from './Value';

export class Literal<Payload> implements Value<Payload> {
	private readonly value: Payload;

	constructor(value: Payload) {
		this.value = value;
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<Payload> {
		return [this.value];
	}

	public getSingle(): Optional<Payload> {
		return this.value;
	}
}
