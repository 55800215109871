import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { RecordViewModel } from 'services/device/domain/model/RecordModel';

import { ModalDialogueCaptionModifier, ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogueSectionName } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSectionName';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface RecordDeleteFormProps {
	record: RecordViewModel;
	onDelete: () => void;
	onDismiss: () => void;
}

export const RecordDeleteForm = (props: RecordDeleteFormProps): JSX.Element => {
	const { record, onDelete, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const modalMessage = {
		first: l10nContext.translate('view.sequence.modal.action.deleteRecord.message.first'),
		last: l10nContext.translate('view.sequence.modal.action.deleteRecord.message.last')
	};

	return (
		<>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.sequence.modal.action.deleteRecord.caption')}
				captionIcon={IconIdentifier.ALERT}
				captionModifier={ModalDialogueCaptionModifier.SMALL_CAPTION}
			/>
			<ModalDialogueSection>
				<>
					{modalMessage.first} <b>{l10nContext.formatDateTime(record.RecordedAt)}</b> {modalMessage.last}
				</>
			</ModalDialogueSection>

			<ModalDialogueSection section={ModalDialogueSectionName.SECTION_FOOTER}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonSecondary
							buttonText={l10nContext.translate('view.sequence.modal.action.deleteRecord.buttonCancel')}
							onClick={onDismiss}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonSecondary
							buttonText={l10nContext.translate('view.sequence.modal.action.deleteRecord.buttonConfirm')}
							status={ButtonSecondaryStatus.ATTENTION}
							icon={IconIdentifier.TRASH}
							onClick={onDelete}
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</ModalDialogueSection>
		</>
	);
};
