import { SerializedError } from '@reduxjs/toolkit';

export class PropellerError extends Error implements SerializedError {
	code?: string;

	constructor(message: string, code?: string) {
		super(message);
		this.name = 'PropellerError';
		this.code = code;
	}
}
