import { FunctionComponent } from 'react';

import 'presentation/ui/components/cards/card-document/card-document-folder/card-document-folder.scss';

export enum CardDocumentFolderBackgroundColor {
	BLUE = 'BLUE'
}

export interface CardDocumentFolderProps {
	/**
	 * Id of controlled card
	 */
	cardId: string;
	/**
	 * (Optional) Background color
	 */
	bgColor?: CardDocumentFolderBackgroundColor;
}

/**
 * The document folder card component
 */
export const CardDocumentFolder: FunctionComponent<CardDocumentFolderProps> = (props): JSX.Element => {
	const { bgColor, children } = props;

	const bgModifier = bgColor ?
		`card-document-folder--${bgColor.toLowerCase()}` :
		'';

	return (
		<div className={`card-document-folder ${bgModifier}`}>
			<div className='card-document-folder__content'>
				{children}
			</div>
		</div>
	);
};
