/* eslint-disable max-classes-per-file */

export enum ProductGroup {

	NUCLEAR_MEDICINE = 'NUCLEAR_MEDICINE',

	ROTOP = 'ROTOP',

	CURIUM = 'CURIUM',

	RADIOLOGY = 'RADIOLOGY',

	CLEARANCE = 'CLEARANCE'

}