import { useContext, useRef } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { FolderViewModel } from 'services/documents/domain/model/FolderModel';
import { FolderEditForm } from 'services/documents/presentation/ui/folder-action/folder-edit-form/FolderEditForm';
import { FolderEditFailedNotification } from 'services/documents/presentation/ui/folder-action/folder-edit-modal/FolderEditFailedNotification';
import { FolderEditSuccessNotification } from 'services/documents/presentation/ui/folder-action/folder-edit-modal/FolderEditSuccessNotification';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface FolderEditModalProps {
	folder: FolderViewModel;
	onDismiss?: () => void;
}

export const FolderEditModal = (props: FolderEditModalProps): JSX.Element => {
	const { folder, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const newFolderName = useRef<string>('');

	const folderActionStatus = useTypedSelector(state => state.folders.actionStatus);

	const setNewFolder = (folderName: string) => {
		newFolderName.current = folderName;
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (folderActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent = <FolderEditForm
					folder={folder}
					onChange={setNewFolder}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modalContent = <FolderEditSuccessNotification
					folder={newFolderName.current}
					onClickConfirm={onDismiss}
				/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <FolderEditFailedNotification onDismiss={onDismiss} />;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.documents.modal.action.edit.caption', 'Ordner umbenennen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
