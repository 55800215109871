import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';

import {
	GeneratorModelConverter as ModelConverter,
	GeneratorPersistenceModel as PersistenceModel,
	GeneratorStoreModel as StoreModel,
	GeneratorViewModel as ViewModel,
	CreatableGeneratorViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/GeneratorModel';
import { GeneratorHttpPersistence as HttpPersistence } from 'services/nuclide/persistence/GeneratorHttpPersistence';

export class GeneratorPersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {

	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

}
