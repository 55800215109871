import { useContext, useRef, useState } from 'react';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';

import { useDispatch, useSelector } from 'react-redux';

import { ContainerViewModel } from 'services/nuclide/domain/model/ContainerModel';
import { updateContainer } from '../../../../store/containerSlice';
import { selectInWastemanagementCyclotronProducts } from '../../../../store/cyclotronProductSlice';
import { selectInWastemanagementEluates } from '../../../../store/eluateSlice';
import { SingleNuclidesViewContext } from '../../../view/SingleNuclidesViewContext';
import { ContainerNuclidesAssignFormStep1 } from './ContainerNuclidesAssignFormStep1';
import { ContainerNuclidesAssignFormStep2 } from './ContainerNuclidesAssignFormStep2';

interface ContainerNuclidesAssignFormProps {
	selectedNuclides: Array<string>,
	onSetSelectedNuclides: () => void
}

export const ContainerNuclidesAssignForm = (props: ContainerNuclidesAssignFormProps): JSX.Element => {
	const { selectedNuclides, onSetSelectedNuclides } = props;

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const viewContext = useContext(SingleNuclidesViewContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);

	const updatedContainer = useRef<ContainerViewModel>({} as ContainerViewModel);

	const inWastemanagementCyclotronProducts = useSelector(selectInWastemanagementCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementEluates = useSelector(selectInWastemanagementEluates());

	const selectedCyclotronProducts = inWastemanagementCyclotronProducts.filter(cyclotronProduct => selectedNuclides.includes(cyclotronProduct.Uuid));
	const selectedEluates = inWastemanagementEluates.filter(eluate => selectedNuclides.includes(eluate.Uuid));

	const handleChangeContainerProps = (value: any) => {
		updatedContainer.current = value;
	};

	const handleUpdate = (): void => {

		updatedContainer.current.CyclotroneProductIds = updatedContainer.current.CyclotroneProductIds.concat(
			selectedCyclotronProducts.map(element => element.Uuid));
		updatedContainer.current.GeneratorEluatIds = updatedContainer.current.GeneratorEluatIds.concat(selectedEluates.map(element => element.Uuid));

		viewContext.setSelectedNuclides([]);
		dispatch(updateContainer(updatedContainer.current));
		onSetSelectedNuclides();
	};


	const goToStep1 = () => {
		setShowStep1(true);
	};

	const goToStep2 = () => {
		setShowStep1(false);
	};

	const showCreateModalStep = showStep1 === true ?
		<ContainerNuclidesAssignFormStep1
			onClickNext={goToStep2}
			updatedContainer={updatedContainer}
			onChangeContainerProps={handleChangeContainerProps}
		/> :
		<ContainerNuclidesAssignFormStep2
			updatedContainer={updatedContainer}
			onClickPrevious={goToStep1}
			onClickConfirm={handleUpdate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
