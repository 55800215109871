import { L10nContext } from 'context/L10nContext';

import { Guid } from 'lib/guid/Guid';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { CardMaintenanceLogDetail } from 'presentation/ui/components/cards/card-maintenance-log/card-maintenance-log-detail/CardMaintenanceLogDetail';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import {
	CardItemMaintenanceLogAttributes,
} from 'presentation/ui/partials/card/card-item-attributes/card-item-maintenance-log-attributes/CardItemMaintenanceLogAttributes';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemActionTypes';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { CardItemMaintenanceLogHeader } from 'presentation/ui/partials/card/card-item-header/card-item-maintenance-log-header/CardItemMaintenanceLogHeader';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import { TagType } from 'presentation/ui/partials/tag/Tag';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentModalType } from 'services/documents/domain/type/DocumentsModalType';
import { DocumentEntry } from 'services/documents/presentation/ui/document-entry/DocumentEntry';
import { DocumentModal } from 'services/documents/presentation/ui/document-modal/DocumentModal';
import {
	fetchDocumentsByMaintenanceLogEntry,
	resetActionStatus as documentResetActionStatus,
	selectDocumentsByMaintenanceLogEntry
} from 'services/documents/store/documentSlice';
import { MaintenanceLogEntryModelState } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModelState';
import { MaintenanceLogModalType } from 'services/maintenance-log/domain/type/MaintenanceLogModalModel';
import { MaintenanceLogModal } from 'services/maintenance-log/presentation/ui/maintenance-log-modal/MaintenanceLogModal';
import { resetActionStatus, selectMaintenanceLogEntryByUuid } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { LoadingSpinnerEmptyCard } from '../../../../presentation/ui/components/loading-spinner-empty-card/LoadingSpinnerEmptyCard.';
import { NotificationBar } from '../../../../presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from '../../../../presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from '../../../../presentation/ui/components/notification-bars/NotificationBars';
import { AsyncFetchStatus } from '../../../../store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from '../../../../store/common/AsyncReducerStatus';
import { useTypedSelector } from '../../../../store/common/TypedSelector';

interface MaintenanceLogEntryViewParams {
	maintenanceLogEntryUuid: string;
}

/**
 * The maintenance log entry view
 */
export const MaintenanceLogEntryView = (): JSX.Element => {

	// Locale state
	const [modalMaintenanceLogType, setModalMaintenanceLogType] = useState<MaintenanceLogModalType>(null);
	const [modalDocumentType, setModalDocumentType] = useState<DocumentModalType>(null);

	const [selectedDocument, setSelectedDocument] = useState<DocumentViewModel>(null);

	// HOOKS
	const dispatch = useDispatch();

	// Consume the context
	const authContext = useContext(AuthContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);

	const maintenanceLogEntryActionStatus = useTypedSelector(state => state.maintenanceLogEntries.actionStatus);
	const maintenanceLogEntryLastActionError = useTypedSelector(state => state.maintenanceLogEntries.lastActionError);

	// fetch documents
	const documentStoreFetchStatus = useTypedSelector(state => state.documents.fetchStatus);
	const documentStorePending = documentStoreFetchStatus === AsyncFetchStatus.INITIAL || documentStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const documentStoreLastActionError = useTypedSelector(state => state.documents.lastActionError);
	const documentStoreFailed = documentStoreFetchStatus === AsyncFetchStatus.FAILED;

	// Unwrap the required log entry uuid from the route
	const params = useParams<MaintenanceLogEntryViewParams>();
	const maintenanceLogEntryUuid = params?.maintenanceLogEntryUuid ?? null;
	if (maintenanceLogEntryUuid === null) {
		throw new Error('Log entry id is missing');
	}

	const maintenanceLogEntry = useSelector(selectMaintenanceLogEntryByUuid(maintenanceLogEntryUuid));

	if (maintenanceLogEntry === null) {
		throw new Error('Maintenance log entry not found');
	}

	useEffect(() => {
		dispatch(fetchDocumentsByMaintenanceLogEntry({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			maintenanceLogEntryUuid: maintenanceLogEntry.Uuid
		}));
	}, [clientContext.selectedClientUuid, dispatch, facilityContext.selectedFacilityUuid, maintenanceLogEntry.Uuid]);

	// Provide data for secondary navigation
	const userName = authContext.getActor().Realname;
	const location = facilityContext.selectedFacility().Name;

	// Read the log entry from the state store
	const documents = useSelector(
		selectDocumentsByMaintenanceLogEntry(
			clientContext.selectedClientUuid,
			facilityContext.selectedFacilityUuid,
			maintenanceLogEntryUuid
		)
	);

	// CALLBACK
	const resetModal = (): void => {
		dispatch(resetActionStatus());
		dispatch(documentResetActionStatus());
		setModalMaintenanceLogType(null);
		setModalDocumentType(null);
		setSelectedDocument(null);
	};

	const headerTag = {
		label: maintenanceLogEntry.State === MaintenanceLogEntryModelState.ONGOING ? l10nContext.translate('maintenanceLog.status.open', 'offen') : l10nContext.translate('maintenanceLog.status.resolved', 'abgeschlossen'),
		type: maintenanceLogEntry.State === MaintenanceLogEntryModelState.ONGOING ? TagType.NEGATIVE : TagType.POSITIVE,
		icon: IconIdentifier.EDIT
	};

	const handleDocumentAction = (actionType: CardItemControlActionType, documentEntry: DocumentViewModel) => {
		resetModal();
		setSelectedDocument(documentEntry);

		switch (actionType.type) {
			case CardItemActionType.DELETE:
				setModalDocumentType(DocumentModalType.DELETE);
				break;
			case CardItemActionType.DOWNLOAD:
				setModalDocumentType(DocumentModalType.DOWNLOAD);
				break;
			case CardItemActionType.CHANGE:
				setModalDocumentType(DocumentModalType.EDIT_DOCUMENT_NAME);
		}
	};

	// RENDER
	 const renderDocuments = (): Array<JSX.Element> | JSX.Element => {
		if (documentStorePending) {
			return (
				<CardEmpty>
					<LoadingSpinnerEmptyCard />
				</CardEmpty>
			);
		}
		if (documents.length > 0) {
			return documents.map((document): JSX.Element => {
				return (
					<DocumentEntry
						key={Guid.generate()}
						documentEntry={document}
						onActionClick={(action, documentEntry) => handleDocumentAction(action, documentEntry)}
					/>
				);
			});
		}
		return <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.documents', 'kein Eintrag')} />;
	};

	const renderNotifications = (): Array<JSX.Element> => {
		const notifications = [];

		// Handle failed action
		if (maintenanceLogEntryActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					message={maintenanceLogEntryLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (documentStoreFailed) {
			notifications.push(
				<NotificationBar
					message={documentStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		return notifications;
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs mapURLFragments={[[maintenanceLogEntryUuid, maintenanceLogEntry.IncidentTitle]]} />
					<UserProfile
						userName={userName}
						location={location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>

					<ViewLayoutSection>

						<MaintenanceLogModal
							selectedMaintenanceLogEntry={maintenanceLogEntry}
							modalType={modalMaintenanceLogType}
							onDismiss={resetModal}
						/>

						<CardMaintenanceLogDetail>
							<CardItemMaintenanceLogHeader
								title={maintenanceLogEntry.IncidentTitle}
								subtitle={maintenanceLogEntry.DeviceName ?? null}
								illustration={IllustrationIdentifier.OPERATING_LOG_GAMKAMERA_PLANAR}
								tag={headerTag}
							/>
							<CardItemSingleControl
								cardId={maintenanceLogEntry.Uuid}
								actionEdit={
									authContext.hasPermission(Permission.MAINTENANCE_LOG_ENTRY_UPDATE) &&
									maintenanceLogEntry.State !== MaintenanceLogEntryModelState.SOLVED
										? CardItemControlState.ENABLED :
										CardItemControlState.DISABLED
								}
								onClick={() => {
									resetModal();
									setModalMaintenanceLogType(MaintenanceLogModalType.EDIT);
								}}
							/>
							<CardItemMaintenanceLogAttributes
								incidentCause={maintenanceLogEntry.IncidentCause}
								recordDate={maintenanceLogEntry.IncidentDateStart}
								recorder={maintenanceLogEntry.ReporterName}
								solvedDate={maintenanceLogEntry.IncidentDateEnd}
								recorderSolved={maintenanceLogEntry.SolverName}
								activities={maintenanceLogEntry.IncidentSolution}
							/>
						</CardMaintenanceLogDetail>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<DocumentModal
							modalType={modalDocumentType}
							selectedDocument={selectedDocument}
							onDismiss={resetModal}
							documentScope={DocumentModelScope.MAINTENANCE_LOG_ENTRY}
							documentScopeReference={maintenanceLogEntry.Uuid}
						/>

						<Expandable expanded={true} firstElement>
							<ExpandableHeader caption={l10nContext.translate('view.documents.collection.documents.headline', 'Dokument')}>
								<ButtonIcon
									weight={ButtonIconWeight.SECONDARY}
									shape={ButtonIconShape.ROUND}
									icon={IconIdentifier.ADD}
									disabled={!authContext.hasPermission(Permission.DOCUMENT_CREATE)}
									onClick={() => {
										resetModal();
										setModalDocumentType(DocumentModalType.ADD_DOCUMENT);
									}}
								/>
							</ExpandableHeader>
							<ExpandableBody>

								<CardCollectionLayout>
									{renderDocuments()}
								</CardCollectionLayout>

							</ExpandableBody>
						</Expandable>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ColumnLayout>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
								<ButtonSecondary
									buttonText={
										l10nContext.translate('view.maintenances.modal.action.resolve.caption', 'Betriebsbucheintrag beheben')
									}
									icon={IconIdentifier.ARCHIVE}
									onClick={() => {
										resetModal();
										setModalMaintenanceLogType(MaintenanceLogModalType.RESOLVE);
									}}
									disabled={!authContext.hasPermission(Permission.MAINTENANCE_LOG_ENTRY_UPDATE) ||
										maintenanceLogEntry.State === MaintenanceLogEntryModelState.SOLVED}
								/>
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								<ButtonSecondary
									disabled={!authContext.hasPermission(Permission.MAINTENANCE_LOG_ENTRY_DELETE)}
									buttonText={l10nContext.translate('common.button.delete', 'Löschen')}
									icon={IconIdentifier.TRASH}
									status={ButtonSecondaryStatus.ATTENTION}
									onClick={() => {
										resetModal();
										setModalMaintenanceLogType(MaintenanceLogModalType.DELETE);
									}}
								/>
							</ColumnLayoutSection>
						</ColumnLayout>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>

		</MainLayout>
	);
};
