import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Route } from 'router/Route';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { deleteDocument } from 'services/documents/store/documentSlice';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';

import { DocumentDeleteForm } from '../document-delete-form/DocumentDeleteForm';
import { DocumentDeleteModalFailed } from './DocumentDeleteModalFailed';

export interface DocumentsDeleteModalProps {
	document: DocumentViewModel;
	onDismiss?: () => void;
}

export const DocumentDeleteModal = (props: DocumentsDeleteModalProps): JSX.Element => {
	const { document, onDismiss } = props;

	const documentActionStatus = useTypedSelector(state => state.documents.actionStatus);

	const history = useHistory();

	const dispatch = useDispatch();

	useEffect(() => {
		if (documentActionStatus === AsyncReducerStatus.DELETED) {
			onDismiss();

			history.location.pathname.includes(Route.MAINTENANCE_LOG) ?
				history.push(Route.MAINTENANCE_LOG) :
				history.push(history.location.pathname);
		}
	}, [onDismiss, documentActionStatus, history]);

	if (documentActionStatus === AsyncReducerStatus.DELETED) {
		return null;
	}

	const handleDelete = (): void => {
		dispatch(deleteDocument(document));
	};

	const renderModalContent = () => {
		switch (documentActionStatus) {
			case AsyncReducerStatus.IDLE:
				return <DocumentDeleteForm
					document={document}
					onDelete={handleDelete}
					onDismiss={onDismiss}
				/>;

			case AsyncReducerStatus.FAILED:
				return <DocumentDeleteModalFailed
					onDismiss={onDismiss}
				/>;
			default: return null;
		}
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
