import { MouseEvent } from 'react';

import './input-file-card.scss';

export interface InputFileCardProps {
	/**
	 * File name
	 */
	fileName: string;
	/**
	 * (Optional) onChange-handler
	 */
	onClick?: (event: MouseEvent) => void;
}

/**
 * The input file's file card
 */
export const InputFileCard = (props: InputFileCardProps): JSX.Element => {
	const { fileName, onClick } = props;

	return (
		<div
			className='input-file__file input-file__file--success'
			onClick={onClick}
			role='button'
			tabIndex={0}
		>
			{fileName}
		</div>
	);
};
