import './card-item-file-size.scss';

export interface CardItemFileSizeProps {
	/**
	 * Label
	 */
	label: string;
}

/**
 * A card item file size component
 */
export const CardItemFileSize = ({ label }: CardItemFileSizeProps): JSX.Element => (
	<div className='card-item-file-size'>
		{label}
	</div>
);
