import { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { FolderViewModel } from 'services/documents/domain/model/FolderModel';
import { FolderMoveFormStep1 } from 'services/documents/presentation/ui/folder-action/folder-move-form/FolderMoveFormStep1';
import { FolderMoveFormStep2 } from 'services/documents/presentation/ui/folder-action/folder-move-form/FolderMoveFormStep2';
import { selectFolderTreeWithoutBranch, updateFolder } from 'services/documents/store/folderSlice';

export interface FolderMoveFormProps {
	folder: FolderViewModel;
	onChange: (selectedFolder: FolderViewModel) => void;
}

export const FolderMoveForm = (props: FolderMoveFormProps): JSX.Element => {
	const { folder, onChange } = props;

	const [showStep2, setShowStep2] = useState<boolean>(false);

	const targetFolder = useRef<FolderViewModel>(folder);

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const folders = useSelector(selectFolderTreeWithoutBranch(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		folder
	));

	const dispatch = useDispatch();

	const handleMoveAction = (): void => {
		dispatch(updateFolder({
			...folder,
			Parent: targetFolder.current.Uuid
		}));
	};

	const onUserSelection = (selectedFolder: FolderViewModel) => {
		targetFolder.current = selectedFolder;
	};

	const gotoStep1 = () => {
		setShowStep2(false);
	};

	const gotoStep2 = () => {
		onChange(targetFolder.current);

		setShowStep2(true);
	};

	const formStep = !showStep2 ?
		<FolderMoveFormStep1
			folder={folder}
			folders={folders}
			onChange={onUserSelection}
			onClickNext={gotoStep2}
		/> :
		<FolderMoveFormStep2
			onClickBack={gotoStep1}
			onClickNext={handleMoveAction}
			folder={targetFolder.current}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
