import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

import './card-item-external-report-sequence-attributes.scss';
import {
	ExternalReportOutputListRenderer
} from '../../../../../../services/device/presentation/ui/widget/widget-renderer/ExternalReportOutputListRenderer';

export interface CardItemExternalReportSequenceAttributesProps {
	sequence: SequenceViewModel;
	reportUuid: string;
}

/**
 * A card item sequence attributes component
 */
export const CardItemExternalReportSequenceAttributes = (props: CardItemExternalReportSequenceAttributesProps): JSX.Element => {
	const { sequence, reportUuid } = props;

	return (
		<div className="card-item-external-report-sequence-attributes">
			<ExternalReportOutputListRenderer sequenceViewModel={sequence} reportUuid={reportUuid} />
		</div>
	);
};
