export enum DocumentModalType {
	ADD_FOLDER = 'ADD_FOLDER',
	ADD_DOCUMENT = 'ADD_DOCUMENT',
	ARCHIVE = 'ARCHIVE',
	DELETE = 'DELETE',
	EDIT_FOLDER_NAME = 'EDIT_FOLDER_NAME',
	MOVE_DOCUMENT = 'MOVE_DOCUMENT',
	MOVE_FOLDER = 'MOVE_FOLDER',
	UNARCHIVE = 'UNARCHIVE',
	DOWNLOAD = 'DOWNLOAD',
	EXTERNAL_DOWNLOAD = 'EXTERNAL_DOWNLOAD',
	EDIT_DOCUMENT_NAME = 'EDIT_DOCUMENT_NAME'
}
