import { Timeout } from 'lib/timeout/Timeout';

export class Network {

	private static fetchEndpoint: string = process.env.REACT_APP_API_BASE_URL + 'core/api/v1/service/head/';

	private static cachedResult = false;
	private static lastRequestMade = 0;

	public static async isOnline(): Promise<boolean> {

		if (!navigator.onLine) {
			return false;
		}

		if (this.lastRequestMade < Date.now() - 10000) {
			this.lastRequestMade = Date.now();
			try {
				const request = new Request(
					Network.fetchEndpoint,
					{
						method: 'head',
						mode: 'no-cors',
						cache: 'no-cache',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						}
					}
				);
				const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error());
				this.cachedResult = response && (response.ok || response.type === 'opaque');
			} catch (e) {
				this.cachedResult = false;
			}
		}

		return this.cachedResult;
	}

}
