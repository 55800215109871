import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';
import { CyclotronProductIsotope } from './isotopes/CyclotronProductIsotope';
import { NuclideModelActivityUnit } from './NuclideModelActivityUnit';

import { CyclotronProductModelState } from './CyclotronProductModelState';

export interface CyclotronProductPersistenceModel extends PersistenceModel {
	Client: string;
	Facility: string;
	Name: string;
	State: string;
	Manufacturer: string;
	BatchNumber: string;
	Isotope: string;
	Radiopharmaceutical: string;
	DeliveryDate: number;
	CalibrationDatetime: number;
	NominalActivity: number;
	ActivityUnit: string;
	AcceptedBy: string;
	AcceptedByName: string;
	CurrentActivity: number;
}

export interface CyclotronProductStoreModel extends StoreModel {
	Client: string;
	Facility: string;
	Name: string;
	State: string;
	Manufacturer: string;
	BatchNumber: string;
	Isotope: string;
	Radiopharmaceutical: string;
	DeliveryDate: number;
	CalibrationDatetime: number;
	NominalActivity: number;
	ActivityUnit: string;
	AcceptedBy: string;
	AcceptedByName: string;
	CurrentActivity: number;
}

export interface CyclotronProductViewModel extends ViewModel {
	Client: string;
	Facility: string;
	Name: string;
	State: CyclotronProductModelState;
	Manufacturer: string;
	BatchNumber: string;
	Isotope: CyclotronProductIsotope;
	Radiopharmaceutical: string;
	DeliveryDate: Date;
	CalibrationDatetime: Date;
	NominalActivity: number;
	ActivityUnit: NuclideModelActivityUnit;
	AcceptedBy: string;
	AcceptedByName: string;
	CurrentActivity: number;
}

export interface CreatableCyclotronProductViewModel extends CreatableViewModel {
	Client: string;
	Facility: string;
	Name: string;
	State: CyclotronProductModelState;
	Manufacturer: string;
	BatchNumber: string;
	Isotope: CyclotronProductIsotope;
	Radiopharmaceutical: string;
	DeliveryDate: Date;
	CalibrationDatetime: Date;
	NominalActivity: number;
	ActivityUnit: NuclideModelActivityUnit;
	AcceptedBy: string;
	AcceptedByName: string;
	CurrentActivity: number;
}

export class CyclotronProductModelConverter
	extends ModelConverter<CyclotronProductPersistenceModel, CyclotronProductStoreModel, CyclotronProductViewModel, CreatableCyclotronProductViewModel> {

	fromStoreModel(storeModel?: CyclotronProductStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			model.DeliveryDate = Math.floor(sModel.DeliveryDate / 1000);
			model.CalibrationDatetime = Math.floor(sModel.CalibrationDatetime / 1000);
		});
	}

	fromViewModel(viewModel?: CyclotronProductViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.DeliveryDate = Math.floor(vModel.DeliveryDate.getTime() / 1000);
			model.CalibrationDatetime = Math.floor(vModel.CalibrationDatetime.getTime() / 1000);
			model.State = vModel?.State as string ?? null;
			model.ActivityUnit = vModel?.ActivityUnit as string ?? null;
		});
	}

	fromCreatableViewModel(creatableViewModel?: CreatableCyclotronProductViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			model.State = CyclotronProductModelState.ACTIVE;
			model.CurrentActivity = null;
			model.AcceptedByName = null;
		});
	}

	toStoreModel(): CyclotronProductStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.DeliveryDate = model.DeliveryDate * 1000;
			sModel.CalibrationDatetime = model.CalibrationDatetime * 1000;
		});
	}

	toViewModel(): CyclotronProductViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.DeliveryDate = new Date(model.DeliveryDate * 1000);
			vModel.CalibrationDatetime = new Date(model.CalibrationDatetime * 1000);
			vModel.State = model?.State as CyclotronProductModelState ?? null;
			vModel.ActivityUnit = model?.ActivityUnit as NuclideModelActivityUnit ?? null;
		});
	}
}
