import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import { ActorModelState } from 'services/core/domain/model/ActorModelState';
import { MembershipHttpPersistence as HttpPersistence } from 'services/core/persistence/MembershipHttpPersistence';
import {
	CreatableMembershipViewModel as CreatableViewModel,
	MembershipModelConverter as ModelConverter,
	MembershipStoreModel as StoreModel,
	MembershipViewModel as ViewModel,
} from 'services/core/domain/model/MembershipModel';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';

// Declare a device state type
export interface MembershipState {
	memberships: Array<StoreModel>;
	createdMembership: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	memberships: [] as Array<StoreModel>,
	createdMembership: null,
	fetchStatus: AsyncFetchStatus.IDLE,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as MembershipState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
export const fetchMemberships = createAsyncThunk(
	'memberships/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { memberships } = getState() as RootState;
			return checkFetchStatus(memberships.fetchStatus);
		}
	}
);

export const updateMembership = createAsyncThunk(
	'membership/update',
	async (params: { clientUuid: string; facilityUuid: string, viewModel: ViewModel }): Promise<StoreModel> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.update(new ModelConverter().fromViewModel(params.viewModel).toStoreModel());
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { memberships } = getState() as RootState;
			if (memberships.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const createMembership = createAsyncThunk(
	'membership/create',
	async (params: { clientUuid: string; facilityUuid: string, viewModel: CreatableViewModel }): Promise<StoreModel> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.create(new ModelConverter().fromCreatableViewModel(params.viewModel).toStoreModel());
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { memberships } = getState() as RootState;
			if (memberships.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteMembership = createAsyncThunk(
	'membership/delete',
	async (params: { clientUuid: string; facilityUuid: string, viewModel: ViewModel }): Promise<StoreModel> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.delete(new ModelConverter().fromViewModel(params.viewModel).toStoreModel());
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { memberships } = getState() as RootState;
			if (memberships.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const membershipSlice = createSlice({
	name: 'memberships',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s create slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchMemberships.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchMemberships.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.memberships = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchMemberships.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(updateMembership.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateMembership.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.memberships.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.memberships[index] = action.payload;
			}
			state.memberships = state.memberships.sort((left: StoreModel, right: StoreModel): number => {
				if (left.Actor?.MailAddress === right.Actor?.MailAddress) {
					if (left.Actor?.Username === right.Actor?.Username) {
						return 0;
					}
					return left.Actor?.Username > right.Actor?.Username ? 1 : -1;
				}
				return left.Actor?.MailAddress > right.Actor?.MailAddress ? 1 : -1;
			});
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateMembership.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(createMembership.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdMembership = null;
		},
		[String(createMembership.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.memberships.push(action.payload);
			state.memberships = state.memberships.sort((left: StoreModel, right: StoreModel): number => {
				if (left.Actor?.MailAddress === right.Actor?.MailAddress) {
					if (left.Actor?.Username === right.Actor?.Username) {
						return 0;
					}
					return left.Actor?.Username > right.Actor?.Username ? 1 : -1;
				}
				return left.Actor?.MailAddress > right.Actor?.MailAddress ? 1 : -1;
			});
			state.createdMembership = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createMembership.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(deleteMembership.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteMembership.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.memberships.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.memberships.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteMembership.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		}
	}
});

export const { resetState, resetActionStatus } = membershipSlice.actions;

// Export the reducer as default
export default membershipSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectMemberships = (): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const viewModels = rootState.memberships.memberships.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectMembershipByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.memberships.memberships.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedMembership = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.memberships.createdMembership;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectFilteredMemberships = (
	includeActive: boolean,
	includeInactive: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeActive && includeInactive || !includeActive && !includeInactive) {
		return selectMemberships();
	}
	const filterModelState = includeActive ? ActorModelState.ACTIVE : ActorModelState.LOCKED;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.memberships.memberships.filter((storeModel): boolean => {
			return storeModel.Actor.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};
