import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	ReportModelConverter as ModelConverter,
	ReportPersistenceModel as PersistenceModel,
	ReportStoreModel as StoreModel,
	ReportViewModel as ViewModel,
	CreatableReportViewModel as CreatableViewModel
} from 'services/report/domain/model/ReportModel';

export class ReportHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'report/api/v1';
	protected collectionControllerUri = 'reports';
	protected entityControllerUri = 'report';

	protected responseCollectionKey = 'ReportModelCollection';
	protected responseEntityKey = 'ReportModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
