import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { CyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';
import { CyclotronProductModelState } from 'services/nuclide/domain/model/CyclotronProductModelState';
import { updateCyclotronProduct } from 'services/nuclide/store/cyclotronProductSlice';
import { CyclotronProductToWasteModuleForm } from '../cyclotron-product-to-waste-module-form/CyclotronProductToWasteModuleForm';
import { CyclotronProductToWasteModuleFailedNotification } from './CyclotronProductToWasteModuleFailedNotification';


export interface CyclotronProductToWasteModuleModalProps {
	/**
	 * Cyclotron product
	 */
	cyclotronProduct: CyclotronProductViewModel
	/**
	 * Handle dismiss
	 */
	onDismiss?: () => void;
}

export const CyclotronProductToWasteModuleModal = (props: CyclotronProductToWasteModuleModalProps): JSX.Element => {
	const { cyclotronProduct, onDismiss } = props;

	const cyclotronProductActionStatus = useTypedSelector(state => state.cyclotronProducts.actionStatus);

	const dispatch = useDispatch();

	useEffect(() => {
		if (cyclotronProductActionStatus === AsyncReducerStatus.UPDATED) {
			onDismiss();
		}
	}, [cyclotronProductActionStatus, onDismiss]);

	const handleUpdate = (): void => {
		cyclotronProduct.State = CyclotronProductModelState.IN_WASTE_MANAGEMENT;
		dispatch(updateCyclotronProduct(cyclotronProduct));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (cyclotronProductActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<CyclotronProductToWasteModuleForm
						cyclotronProduct={cyclotronProduct}
						onUpdate={handleUpdate}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <CyclotronProductToWasteModuleFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
