import { StringOutput } from 'services/device/domain/business/common/Output';

export interface StringOutputValueProps {
	output: StringOutput;
}

export const StringOutputValueRenderer = (props: StringOutputValueProps): JSX.Element => {
	const { output } = props;

	return (
		<span>
			{output.getSingle() ?? ''}
		</span>
	);

};
