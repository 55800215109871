import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DebugConsole } from 'lib/debug/DebugConsole';

import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { ModelQueryOrderClosureBuilder } from 'lib/persistence/idb/query/ModelQueryOrderClosureBuilder';
import {
	CreatableGeneratorViewModel as CreatableViewModel,
	GeneratorModelConverter as ModelConverter,
	GeneratorStoreModel as StoreModel,
	GeneratorViewModel as ViewModel
} from 'services/nuclide/domain/model/GeneratorModel';
import { GeneratorModelState } from 'services/nuclide/domain/model/GeneratorModelState';
import { GeneratorPersistence as Persistence } from 'services/nuclide/persistence/GeneratorPersistence';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { GeneratorHttpPersistence as HttpPersistence } from '../persistence/GeneratorHttpPersistence';

// Declare a generator state type
export interface GeneratorState {
	generators: Array<StoreModel>;
	createdGenerator: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	generators: [] as Array<StoreModel>,
	createdGenerator: null,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as GeneratorState;

// Implementation of the async actions
export const fetchGenerators = createAsyncThunk(
	'generators/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Silently abort the action
			const { generators } = getState() as RootState;
			return checkFetchStatus(generators.fetchStatus);
		}
	}
);

export const resetGenerators = createAsyncThunk(
	'generators/reset',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { generators } = getState() as RootState;
			return checkFetchStatus(generators.fetchStatus);
		}
	}
);

export const createGenerator = createAsyncThunk(
	'generator/create',
	async (viewModel: CreatableViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.createEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { generators } = getState() as RootState;
			if (generators.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const updateGenerator = createAsyncThunk(
	'generator/update',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.updateEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { generators } = getState() as RootState;
			if (generators.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteGenerator = createAsyncThunk(
	'generator/delete',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.deleteEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {

			// Abort the action with an error
			const { generators } = getState() as RootState;
			if (generators.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const generatorSlice = createSlice({
	name: 'generators',
	initialState,
	// Regular syncronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	extraReducers: {
		[String(fetchGenerators.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchGenerators.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.generators = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchGenerators.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(resetGenerators.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(resetGenerators.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.generators = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(resetGenerators.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(createGenerator.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdGenerator = null;
		},
		[String(createGenerator.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.generators.push(action.payload);
			state.generators = state.generators.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.createdGenerator = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createGenerator.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(updateGenerator.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateGenerator.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.generators.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.generators[index] = action.payload;
			}
			state.generators = state.generators.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateGenerator.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(deleteGenerator.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteGenerator.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.generators.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.generators.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteGenerator.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		}
	}
});

export const { resetState, resetActionStatus } = generatorSlice.actions;

// Export the reducer as default
export default generatorSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectGenerators = (clientUuid: string, facilityUuid: string): (rootSTate: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.generators.generators.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectGeneratorByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.generators.generators.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedGenerator = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.generators.createdGenerator;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectFilteredGenerators = (
	clientUuid: string,
	facilityUuid: string,
	includeActive: boolean,
	includeDispatched: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeActive && includeDispatched || !includeActive && !includeDispatched) {

		const filterArray: Array<string> = [GeneratorModelState.ACTIVE, GeneratorModelState.DISPATCHED];

		return (rootState: RootState): ReadonlyArray<ViewModel> => {
			let storeModels: Array<StoreModel> = [];
			filterArray.forEach((generatorFilter) => {
				const filteredRootStore = rootState.generators.generators.filter((storeModel): boolean => {
					return storeModel.Client === clientUuid
						&& storeModel.Facility === facilityUuid
						&& storeModel.State === String(generatorFilter);
				}) ?? [];
				storeModels = storeModels.concat(filteredRootStore);
			});
			const viewModels = storeModels.map((storeModel): ViewModel | null => {
				try {
					return new ModelConverter().fromStoreModel(storeModel).toViewModel();
				} catch (error) {
					DebugConsole.error(error);
					return null;
				}
			});
			return viewModels.filter((viewModel) => {
				return viewModel !== null;
			});
		};
	}

	const filterModelState = includeActive ? GeneratorModelState.ACTIVE : GeneratorModelState.DISPATCHED;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.generators.generators.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};


// Multiple options filter
/* export const selectFilteredGenerators = (
	clientUuid: string,
	facilityUuid: string,
	includeActive: boolean,
	includeDecay: boolean,
	includeDispatched: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeActive && includeDispatched && includeDecay || !includeActive && !includeDispatched && !includeDecay) {
		return selectGenerators(clientUuid, facilityUuid);
	}

	const filterArray: Array<string> = [];
	if (includeActive) {
		filterArray.push(String(GeneratorModelState.ACTIVE));
	} if (includeDecay) {
		filterArray.push(String(GeneratorModelState.DECAY));
	} if (includeDispatched) {
		filterArray.push(String(GeneratorModelState.DISPATCHED));
	}

	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		let storeModels: Array<StoreModel> = [];
		filterArray.forEach((generatorFilter) => {
			const filteredRootStore = rootState.generators.generators.filter((storeModel): boolean => {
				return storeModel.Client === clientUuid
					&& storeModel.Facility === facilityUuid
					&& storeModel.State === String(generatorFilter);
			}) ?? [];
			storeModels = storeModels.concat(filteredRootStore);
		});
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
}; */
