import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';

import {
	ContainerDispatchModelConverter as ModelConverter,
	ContainerDispatchPersistenceModel as PersistenceModel,
	ContainerDispatchStoreModel as StoreModel,
	ContainerDispatchViewModel as ViewModel,
	CreatableContainerDispatchViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/ContainerDispatchModel';
import { ContainerDispatchHttpPersistence as HttpPersistence } from 'services/nuclide/persistence/ContainerDispatchHttpPersistence';

export class ContainerDispatchPersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {

	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

}
