export enum DashboardViewModalType {
	CREATE_RECORD = 'CREATE_RECORD',
	RESOLVE_MAINTENANCE_LOG = 'RESOLVE_MAINTENANCE_LOG',
	EDIT_CLEARANCE = 'EDIT_CLEARANCE',
	DELETE_DOCUMENT = 'DELETE_DOCUMENT',
	ARCHIVE_DOCUMENT = 'ARCHIVE_DOCUMENT',
	DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
	UNARCHIVE_DOCUMENT = 'UNARCHIVE_DOCUMENT',
	MOVE_DOCUMENT = 'MOVE_DOCUMENT'
}
