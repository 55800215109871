import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';

import {
	DeviceModelConverter as ModelConverter,
	DevicePersistenceModel as PersistenceModel,
	DeviceStoreModel as StoreModel,
	DeviceViewModel as ViewModel,
	CreatableDeviceViewModel as CreatableViewModel
} from 'services/device/domain/model/DeviceModel';
import { ExternalHttpPersistence } from '../../../lib/persistence/http/ExternalHttpPersistence';

export class ExternalReportDeviceHttpPersistence extends ExternalHttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'device/report-api/v1';
	protected collectionControllerUri = 'devices';
	protected entityControllerUri = 'device';

	protected responseCollectionKey = 'DeviceModelCollection';
	protected responseEntityKey = 'DeviceModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
