import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';
import { DocumentAddModalContent } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddModalContent';

import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface DocumentAddModalProps {
	onDismiss?: () => void;
	documentScope: DocumentModelScope;
	documentScopeReference: string;
}

export const DocumentAddModal = (props: DocumentAddModalProps): JSX.Element => {
	const { onDismiss, documentScope, documentScopeReference } = props;

	const l10nContext = useContext(L10nContext);

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.documents.modal.action.addDocument.caption', 'Dokument(e) hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				<DocumentAddModalContent
					documentScope={documentScope}
					documentScopeReference={documentScopeReference}
					onDismiss={onDismiss}
				/>
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
