import { FunctionComponent } from 'react';

import 'presentation/ui/components/cards/card-device/card-device-list/card-device-list.scss';

export interface CardDeviceListProps {
	/**
	 * (Optional) Archived state
	 */
	archived?: boolean;
}

/**
 * The card device list card component
 */
export const CardDeviceList: FunctionComponent<CardDeviceListProps> = (props): JSX.Element => {
	const { archived, children } = props;

	const componentStyle = `card-device-list${archived ? ' card-device-list--archived' : ''}`;

	return (
		<div className={componentStyle}>
			<div className='card-device-list__content'>
				{children}
			</div>
		</div>
	);
};
