import { useContext, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { FilterChipSet } from 'presentation/ui/components/filter/filter-chip-set/FilterChipSet';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { CardCollectionLayoutHeader } from 'presentation/ui/layouts/card-collection-layout/card-collection-layout-header/CardCollectionLayoutHeader';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Route } from 'router/Route';
import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

import { SequenceCollectionItem } from 'services/device/presentation/ui/card-collections/sequence-collection/SequenceCollectionItem';
import { selectFilteredSequencesByDevice } from 'services/device/store/sequenceSlice';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';

export interface ClearanceSequenceCollectionProps {
	device: DeviceViewModel;
	onAddSequenceClick: () => void;
	onAddRecordClick: (sequence: SequenceViewModel) => void;
}

export const ClearanceSequenceCollection = (props: ClearanceSequenceCollectionProps): JSX.Element => {
	const { device, onAddSequenceClick, onAddRecordClick } = props;

	// Consume the history object
	const history = useHistory();

	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);

	// Local state
	const [sequencesIncludeActive, setSequencesIncludeActive] = useState<boolean>(true);
	const [sequencesIncludeArchived, setSequencesIncludeArchived] = useState<boolean>(false);

	// Read the sequences from the state store
	const sequences = useSelector(selectFilteredSequencesByDevice(device, sequencesIncludeActive, sequencesIncludeArchived));

	const renderFilter = (): JSX.Element | null => {
		return (
			<FilterChipSet
				label={l10nContext.translate('common.filter.status', 'Status')}
				chips={[
					{
						label: l10nContext.translate('common.filter.active', 'aktiv'),
						active: sequencesIncludeActive,
						onClick: (id, state) => {
							setSequencesIncludeActive(state);
						}
					},
					{
						label: l10nContext.translate('common.filter.archived', 'archiviert'),
						active: sequencesIncludeArchived,
						onClick: (id, state) => {
							setSequencesIncludeArchived(state);
						}
					}
				]}
			/>
		);
	};

	const rendSequenceCards = (): JSX.Element => {
		if (sequences.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.clearanceSequence', 'keine Mitarbeiter/Messziele')} />
			);
		}

		const cards = sequences.map((sequence) => (
			<SequenceCollectionItem
				device={device}
				sequence={sequence}
				onClick={() => history.push(`${Route.CLEARANCES}/${device.Uuid}${Route.MEASUREMENT_GOALS}/${sequence.Uuid}`)}
				onAddRecord={() => onAddRecordClick(sequence)}
				key={sequence.Uuid}
			/>
		));

		return (
			<>
				{cards}
			</>
		);
	};

	return (
		<Expandable expanded={true} firstElement>
			<ExpandableHeader caption={l10nContext.translate('view.clearances.header.sequences', 'Mitarbeiter/Messziele')}>
				<ButtonIcon
					weight={ButtonIconWeight.SECONDARY}
					shape={ButtonIconShape.ROUND}
					icon={IconIdentifier.ADD}
					onClick={onAddSequenceClick}
					disabled={(device.State === DeviceModelState.ARCHIVED) || !authContext.hasPermission(Permission.SEQUENCE_CREATE)}
				/>
			</ExpandableHeader>

			<ExpandableBody>
				<CardCollectionLayout>
					<CardCollectionLayoutHeader>
						{renderFilter()}
					</CardCollectionLayoutHeader>

					{rendSequenceCards()}
				</CardCollectionLayout>
			</ExpandableBody>
		</Expandable>
	);
};
