import { FunctionComponent } from 'react';

import './card-maintenance-log-detail.scss';

/**
 * The operational log detail card component
 */
export const CardMaintenanceLogDetail: FunctionComponent = (props): JSX.Element => {
	const { children } = props;

	return (
		<div className='card-maintenance-log-detail'>
			<div className='card-maintenance-log-detail__content'>
				{children}
			</div>
		</div>
	);
};
