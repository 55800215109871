import { PersistenceModel } from 'lib/domain/model/Model';
import { L10n } from 'lib/l10n/L10n';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { Timeout } from 'lib/timeout/Timeout';

import { ExternalAuthService } from 'services/core/lib/auth/ExternalAuthService';

export interface PropellerHttpExpectionResponse {
	Exception: {
		Message: string,
		LocalizedMessage: string,
		Level: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7,
		LevelAsString: 'Debug' | 'Info' | 'Notice' | 'Warning' | 'Error' | 'Critical' | 'Alert' | 'Emergency',
		Details: Array<any>,
		Code: number,
		Stacktrace?: string,
		Backtrace?: Array<any>
	},
	Notifications: Array<any>
}

export class ExternalReportHttpConnector {

	private readonly authService: ExternalAuthService;
	private readonly jsonWebToken?: string = null;

	constructor(reportUuid: string) {
		this.authService = new ExternalAuthService(reportUuid);
		this.jsonWebToken = this.authService.getJsonWebToken()?.Token ?? null;
		if (this.jsonWebToken === null) {
			throw new PropellerError('Invalid API response', '401');
		}
	}

	public async get(uri: string): Promise<PersistenceModel | null> {
		const request = new Request(
			uri,
			this.buildRequestOptions('get')
		);
		const response = await this.perform(request, true);
		return response === null ? null : await response.json() as PersistenceModel;
	}

	public async put(uri: string, data: PersistenceModel): Promise<PersistenceModel | null> {
		const request = new Request(
			uri,
			this.buildRequestOptionsWithBody('put', data)
		);
		const response = await this.perform(request, true);
		return response === null ? null : await response.json() as PersistenceModel;
	}

	public async post(uri: string, data: PersistenceModel): Promise<PersistenceModel> {
		const request = new Request(
			uri,
			this.buildRequestOptionsWithBody('post', data)
		);
		const response = await this.perform(request, false);
		return await response.json() as PersistenceModel;
	}

	public async delete(uri: string): Promise<PersistenceModel | null> {
		const request = new Request(
			uri,
			this.buildRequestOptions('delete')
		);
		const response = await this.perform(request, true);
		return response === null ? null : await response.json() as PersistenceModel;
	}

	private buildRequestOptions(method: string): RequestInit {
		return {
			method,
			cache: 'no-cache',
			headers: {
				'Authorization': 'Bearer ' + this.jsonWebToken,
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Accept-Language': L10n.effectiveLocale()
			}
		};
	}

	private buildRequestOptionsWithBody(method: string, body: Record<string, any>): RequestInit {
		return {
			...this.buildRequestOptions(method),
			body: JSON.stringify(body)
		};
	}

	private async perform(request: Request, allowNotFound: boolean): Promise<Response | null> {
		const response = await Timeout.wrap<Response>(fetch(request), 60000, new Error('Request timeout'));

		if (allowNotFound && response.status === 404) {
			return null;
		}
		if (response.status !== 200 && response.status !== 201) {
			const responseBody = await response.json() as PropellerHttpExpectionResponse;
			const errorMessage = responseBody?.Exception?.LocalizedMessage ?? 'Invalid API response';
			throw new PropellerError(errorMessage, String(response.status));
		}
		return response;
	}

}
