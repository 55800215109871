import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { EluateModelState } from '../../../../domain/model/EluateModelState';
import { updateEluate } from '../../../../store/eluateSlice';
import { EluateToActiveStateForm } from '../eluate-to-active-state-form/EluateToActiveStateForm';
import { EluateToActiveStateFailedNotification } from './EluateToActiveStateFailedNotification';

export interface EluateToActiveStateModalProps {
	eluate: EluateViewModel
	onDismiss?: () => void;
}

export const EluateToActiveStateModal = (props: EluateToActiveStateModalProps): JSX.Element => {
	const { eluate, onDismiss } = props;

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const eluateActionStatus = useTypedSelector(state => state.eluates.actionStatus);

	const dispatch = useDispatch();

	useEffect(() => {
		if (eluateActionStatus === AsyncReducerStatus.UPDATED) {
			onDismiss();
		}
	}, [eluateActionStatus, onDismiss]);

	const handleToActiveState = (): void => {

		eluate.State = EluateModelState.ACTIVE;
		eluate.Client = clientContext.selectedClientUuid;
		eluate.Facility = facilityContext.selectedFacilityUuid;
		dispatch(updateEluate(eluate));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (eluateActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<EluateToActiveStateForm
						eluate={eluate}
						onToActiveState={handleToActiveState}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <EluateToActiveStateFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
