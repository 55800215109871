import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';

import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { ModelQueryOrderClosureBuilder } from 'lib/persistence/idb/query/ModelQueryOrderClosureBuilder';

import {
	CreatableFolderViewModel as CreatableViewModel,
	FolderModelConverter as ModelConverter,
	FolderStoreModel as StoreModel,
	FolderViewModel as ViewModel,
} from 'services/documents/domain/model/FolderModel';
import { FolderModelState } from 'services/documents/domain/model/FolderModelState';
import { FolderPersistence as Persistence } from 'services/documents/persistence/FolderPersistence';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { FolderHttpPersistence as HttpPersistence } from '../persistence/FolderHttpPersistence';


// Declare a device state type
export interface FolderState {
	folders: Array<StoreModel>;
	createdFolder: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	folders: [] as Array<StoreModel>,
	createdFolder: null,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as FolderState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
export const fetchFolders = createAsyncThunk(
	'folders/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		const collection = await persistence.fetchCollection();
		return collection;
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { folders } = getState() as RootState;
			return checkFetchStatus(folders.fetchStatus);
		}
	}
);

export const resetFolders = createAsyncThunk(
	'folders/reset',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { folders } = getState() as RootState;
			return checkFetchStatus(folders.fetchStatus);
		}
	}
);

export const updateFolder = createAsyncThunk(
	'folder/update',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.updateEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { folders } = getState() as RootState;
			if (folders.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const createFolder = createAsyncThunk(
	'folder/create',
	async (viewModel: CreatableViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.createEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { folders } = getState() as RootState;
			if (folders.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteFolder = createAsyncThunk(
	'folder/delete',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.deleteEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { folders } = getState() as RootState;
			if (folders.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const folderSlice = createSlice({
	name: 'folders',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s create slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchFolders.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchFolders.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.folders = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchFolders.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(resetFolders.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(resetFolders.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.folders = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(resetFolders.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(updateFolder.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateFolder.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.folders.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.folders[index] = action.payload;
			}
			state.folders = state.folders.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateFolder.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(createFolder.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdFolder = null;
		},
		[String(createFolder.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.folders.push(action.payload);
			state.folders = state.folders.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.createdFolder = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createFolder.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(deleteFolder.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteFolder.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.folders.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.folders.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteFolder.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		}
	}
});

export const { resetState, resetActionStatus } = folderSlice.actions;

// Export the reducer as default
export default folderSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectRootFolder = (clientUuid: string, facilityUuid: string): (rootState: RootState) => Readonly<ViewModel> | null => {
	return (rootState: RootState): Readonly<ViewModel> | null => {
		const storeModels = rootState.folders.folders.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& (storeModel?.Parent ?? null) === null;
		}) ?? [];
		let viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		viewModels = viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
		return viewModels.length > 0 ? viewModels[0] : null;
	};
};

export const selectFoldersByParentFolder = (
	clientUuid: string,
	facilityUuid: string,
	parentFolderUuid: string
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.folders.folders.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.Parent === parentFolderUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectFilteredFoldersByParentFolder = (
	clientUuid: string,
	facilityUuid: string,
	parentFolderUuid: string,
	includeActive?: boolean,
	includeArchived?: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeActive && includeArchived || !includeActive && !includeArchived) {
		return selectFoldersByParentFolder(clientUuid, facilityUuid, parentFolderUuid);
	}

	const filterModelState = includeActive ? FolderModelState.ACTIVE : FolderModelState.ARCHIVED;

	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.folders.folders.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.State === String(filterModelState)
				&& storeModel.Parent === parentFolderUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectFolderByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.folders.folders.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedFolder = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.folders.createdFolder;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectParentFolders = (folder: ViewModel): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		let storeModel = rootState.folders.folders.find((sModel): boolean => {
			return sModel.Uuid === folder?.Uuid;
		}) ?? null;

		if (storeModel === null) {
			return [];
		}

		const parentFolders: Array<ViewModel> = [];
		while ((storeModel?.Parent ?? null) !== null) {
			// eslint-disable-next-line no-loop-func, @typescript-eslint/no-loop-func
			storeModel = rootState.folders.folders.find((sModel): boolean => {
				return sModel.Uuid === storeModel.Parent;
			}) ?? null;

			if (storeModel === null) {
				break;
			}

			try {
				const viewModel = new ModelConverter().fromStoreModel(storeModel).toViewModel();
				parentFolders.push(viewModel);
			} catch (error) {
				DebugConsole.error(error);
			}

		}
		return parentFolders;
	};
};

export const selectParentFolder = (folder: ViewModel): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.folders.folders.find((sModel): boolean => {
			return sModel.Uuid === folder.Parent;
		}) ?? null;

		if (storeModel === null) {
			return null;
		}

		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
		}
		return null;
	};
};

export const selectFolderTree = (
	clientUuid: string,
	facilityUuid: string
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const rootFolder = rootState.folders.folders.find((sModel): boolean => {
			return sModel.Client === clientUuid
				&& sModel.Facility === facilityUuid
				&& (sModel.Parent === null || sModel.Parent === '');
		}) ?? null;

		if (rootFolder === null) {
			return [];
		}

		const folderTree: Array<ViewModel> = [];

		try {
			const viewModel = new ModelConverter().fromStoreModel(rootFolder).toViewModel();
			folderTree.push(viewModel);
		} catch (error) {
			DebugConsole.error(error);
		}

		folderTree.push(...getDescendants(rootState, rootFolder));

		return folderTree;
	};
};

export const selectFolderTreeWithoutBranch = (
	clientUuid: string,
	facilityUuid: string,
	excludeBranch: ViewModel
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const rootFolder = rootState.folders.folders.find((sModel): boolean => {
			return sModel.Client === clientUuid
				&& sModel.Facility === facilityUuid
				&& (sModel.Parent === null || sModel.Parent === '');
		}) ?? null;

		if (rootFolder === null) {
			return [];
		}

		const folderTree: Array<ViewModel> = [];

		try {
			const viewModel = new ModelConverter().fromStoreModel(rootFolder).toViewModel();
			folderTree.push(viewModel);
		} catch (error) {
			DebugConsole.error(error);
		}

		folderTree.push(...getDescendants(rootState, rootFolder, excludeBranch));

		return folderTree;
	};
};

const getDescendants = (rootState: RootState, folder: StoreModel, excludeBranch: ViewModel = null): Array<ViewModel> => {
	const children = rootState.folders.folders.filter((sModel): boolean => {
		return sModel.Parent === folder.Uuid;
	}) ?? [];
	const result: Array<ViewModel> = [];
	for (const child of children) {
		if (excludeBranch !== null && excludeBranch.Uuid === child.Uuid) {
			// eslint-disable-next-line no-continue
			continue;
		}
		try {
			const viewModel = new ModelConverter().fromStoreModel(child).toViewModel();
			result.push(viewModel);
		} catch (error) {
			DebugConsole.error(error);
		}
		result.push(...getDescendants(rootState, child, excludeBranch));
	}
	return result;
};
