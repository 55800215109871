export enum NuclideHalfLifePerHour {

	CESIUM_137_CS = 2.628E+05,

	COBALT_58_CO = 1.7016E+03,

	ERBIUM_169_ER = 2.256E+02,

	FLUORINE_18_F = 1.82833333333333E+00,

	GALLIUM_68_GA = 1.12833333333333E+00,

	GERMANIUM_68_GE = 6.504E+03,

	INDIUM_111_IN = 6.72E+01,

	IODINE_123_I = 1.32E+01,

	IODINE_131_I = 1.92E+02,

	LUTETIUM_177_LU = 1.584E+02,

	MOLYBDENUM_99_MO = 6.6E+01,

	NITROGEN_13_N = 1.66666666666667E-01,

	RHENIUM_186_RE = 9E+01,

	TECHNETIUM_99M_TC = 6E+00,

	YTTRIUM_90_Y = 6.41E+01
}
