import { memo, MouseEvent, useContext, useEffect, useRef, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { useOnClickOutside } from 'presentation/hooks/click-outside';
import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './select.scss';

export enum SelectStatus {
	ERROR = 'ERROR',
	READONLY = 'READONLY',
	SUMMARY = 'SUMMARY'
}

export enum SelectOpenDirection {
	TOP = 'TOP'
}

/**
 * Select types
 */
export type SelectOption = {
	label: string;
	value: string;
	checked?: boolean;
};

type SelectState = {
	label: string;
	value: string;
};

export interface SelectProps {
	/**
	 * Select options (choose empty array for disabled select)
	 */
	options: SelectOption[];
	/**
	 * (Optional) Status
	 */
	status?: SelectStatus;
	/**
	 * (Optional) Open Direction
	 */
	openDirection?: SelectOpenDirection;
	/**
	 * (Optional) onChange-handler
	 */
	onchange?: (selectedValue: string) => void;
	/**
	 * (Optional) Error message
	 */
	errorMessage?: string;
	/**
	 * (Optional) Disabled state
	 */
	disabled?: boolean;
	/**
	 * (Optional) Permanent open state
	 */
	alwaysOpen?: boolean;
}

/**
 * A custom select partial
 */
export const Select = memo((props: SelectProps): JSX.Element => {
	const { options, status, openDirection, onchange, errorMessage, disabled, alwaysOpen = false } = props;

	const l10nContext = useContext(L10nContext);

	if (options.length === 1 && !options[0].checked) {
		options[0].checked = true;
		onchange(options[0].value);
	}

	const initialOption = options.find(option => option.checked === true);
	const initialValue = initialOption?.value ?? null;
	const initialLabel = initialOption?.label ??
		l10nContext.translate('common.inputs.select.label', 'Bitte wählen');

	const [openState, setOpenstate] = useState<boolean>(alwaysOpen ?? false);
	const [selectState, setSelectState] = useState<SelectState>({
		label: initialLabel,
		value: initialValue
	});

	const userAction = useRef<boolean>(false);
	const selectInstance = useRef(null);

	const stateClass = status ?
		`select--${status.toLowerCase()}` :
		'';

	useOnClickOutside(selectInstance, null, () => {
		if (!alwaysOpen) {
			setOpenstate(false);
		}
	});

	useEffect(() => {
		if (userAction.current && onchange) {
			userAction.current = false;

			onchange(selectState.value);
		}
	}, [onchange, selectState.value]);

	const selectOptions = options.map(option => (
		<li
			key={option.value.toLowerCase()}
			className="select__option"
			data-value={option.value}
		>
			<p className="select__option__label">
				{option.label}
			</p>

			<Icon name={IconIdentifier.CHECK} />
		</li>
	));

	const errMessage = errorMessage ?
		<span className="select__error-message">
			{errorMessage}
		</span> :
		null;

	const onClick = (event: MouseEvent) => {
		userAction.current = true;

		const selectedElement = (event.target as HTMLUListElement);
		const selectedOption: SelectOption = options.filter(option => option.value === selectedElement.dataset.value)[0];

		if (!alwaysOpen) {
			setOpenstate(!openState);
		}

		if (!selectedOption) {
			return;
		}

		setSelectState({
			...selectState, ...{
				label: selectedOption.label,
				value: selectedOption.value
			}
		});
	};

	const componentStyle = `
		select${openState ? ' select--open' : ''}
		${stateClass}
		${!disabled ? '' : ' select--disabled'}
		${openDirection ? ' select--open-to-top' : ''}
	`;
	const titleStyle = `select__title${alwaysOpen ? ' select__title--hidden' : ''}`;

	return (
		<div ref={selectInstance} className={componentStyle}>
			<div
				className="select__wrapper"
				onClick={onClick}
				role="button"
				tabIndex={0}
			>
				<span className={titleStyle}>
					<p>{selectState.label}</p>

					<Icon
						name={IconIdentifier.DROPDOWN_OPEN}
						className="icon--open"
					/>
					<Icon
						name={IconIdentifier.DROPDOWN_CLOSE}
						className="icon--close"
					/>
				</span>

				<ul className="select__options">
					{selectOptions}
				</ul>

				{errMessage}
			</div>
		</div>
	);
});
