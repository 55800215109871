import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	CyclotronProductModelConverter as ModelConverter,
	CyclotronProductPersistenceModel as PersistenceModel,
	CyclotronProductStoreModel as StoreModel,
	CyclotronProductViewModel as ViewModel,
	CreatableCyclotronProductViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/CyclotronProductModel';

export class CyclotronProductHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'cyclotron-product/api/v1';
	protected collectionControllerUri = 'cyclotron-products';
	protected entityControllerUri = 'cyclotron-product';

	protected responseCollectionKey = 'CyclotronProductModelCollection';
	protected responseEntityKey = 'CyclotronProductModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
