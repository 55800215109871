import { Widget } from 'services/device/domain/business/common/widget/Widget';
import { WidgetsSelection } from 'services/device/presentation/ui/widget/WidgetsSelection';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { Sequence } from 'services/device/domain/business/common/sequence/Sequence';

export interface WidgetsProps {
	widgets: ReadonlyArray<Widget>;
	sequenceViewModel?: SequenceViewModel;
}

export const WidgetsRenderer = (props: WidgetsProps): JSX.Element => {
	const { widgets, sequenceViewModel } = props;

	let sequence: Sequence;
	if ((sequenceViewModel ?? null) !== null) {
		const sequenceTypeDefinition = getSequenceTypeDefinition(sequenceViewModel.Type);
		sequence = sequenceTypeDefinition.restoreSequenceFromViewData(sequenceViewModel.SequenceConfiguration);
	}

	const widgetNodes: Array<JSX.Element> = [];

	for (const widget of widgets) {
		widget.selectWidget(
			new WidgetsSelection((element): void => {
				widgetNodes.push(element);
			}, sequence)
		);
	}

	return (
		<>
			{widgetNodes}
		</>
	);

};
