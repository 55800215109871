import { RecordFieldSet } from '../field-set/RecordFieldSet';
import { ContextFactory } from '../misc/ContextFactory';
import { Environment } from '../misc/Environment';
import { Step } from '../misc/Step';
import { Record } from '../record/Record';
import { RecordStoreData, RecordViewData } from '../record/RecordData';
import { Valuation } from '../Valuation';
import { AbstractInteraction } from './AbstractInteraction';

export class RecordInteraction<Context, Fields, FieldSet extends RecordFieldSet<Fields>> extends AbstractInteraction<Context, Fields, FieldSet, Record> {
	private readonly environment: Environment;

	private readonly contextFactory: ContextFactory<Context, Fields>;

	public constructor(
		environment: Environment,
		contextFactory: ContextFactory<Context, Fields>,
		fieldSet: FieldSet,
		steps: ReadonlyArray<Step<Context, Fields>>,
		step: number = 0
	) {
		super(contextFactory(environment, fieldSet.getRawFields()), environment, fieldSet, steps, step);
		this.environment = environment;
		this.contextFactory = contextFactory;
	}

	protected createSibling(fieldSet: FieldSet, steps: ReadonlyArray<Step<Context, Fields>>, newStep: number): this {
		return new RecordInteraction<Context, Fields, FieldSet>(this.environment, this.contextFactory, fieldSet, steps, newStep) as this;
	}

	protected createResult(fieldSet: FieldSet, valuation: Valuation): Record {
		return new Record(this.environment.deviceType, this.environment.sequenceType, fieldSet, valuation);
	}

	public toStoreData(): RecordStoreData {
		return this.getFieldSet().toStoreData(false);
	}

	public toViewData(): RecordViewData {
		return this.getFieldSet().toViewData(this.environment.deviceType, this.environment.sequenceType, false);
	}
}
