import { useContext } from 'react';
import { L10nContext } from '../../../../../../context/L10nContext';
import { ExpandableSlimBody } from '../../../../../../presentation/ui/compositions/expandable-slim/expandable-slim-body/ExpandableSlimBody';
import {
	ExpandableSlimHeader
} from '../../../../../../presentation/ui/compositions/expandable-slim/expandable-slim-header/ExpandableSlimHeader';
import { ExpandableSlim } from '../../../../../../presentation/ui/compositions/expandable-slim/ExpandableSlim';
import { CardCollectionLayout } from '../../../../../../presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { ColumnLayoutSection } from '../../../../../../presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import {
	ColumnLayoutSectionMode
} from '../../../../../../presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from '../../../../../../presentation/ui/layouts/column-layout/ColumnLayout';
import { ViewLayoutSection } from '../../../../../../presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from '../../../../../../presentation/ui/layouts/view-layout/ViewLayout';
import { ButtonPrimary } from '../../../../../../presentation/ui/partials/button/button-primary/ButtonPrimary';
import { IconIdentifier } from '../../../../../../presentation/ui/partials/icon/IconIdentifier';
import { ParagraphElement } from '../../../../../../presentation/ui/partials/paragraph-element/ParagraphElement';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';
import { AuthContext } from '../../../../../core/context/AuthContext';
import { Permission } from '../../../../../core/lib/auth/AuthService';
import { selectWasteManagementReports } from '../../../../store/wasteManagementReportSlice';
import { WasteManagementReportCollectionItem } from './WasteManagementReportCollectionItem';

export interface WasteManagementReportCollectionProps {
	isGeneratorReport: boolean;
	onModalClick: () => void;
}

export const WasteManagementReportCollection = (props: WasteManagementReportCollectionProps): JSX.Element => {

	const { isGeneratorReport, onModalClick } = props;

	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);

	// Read the reports from the store
	const wasteManagementReports = useTypedSelector(selectWasteManagementReports());

	const renderWasteManagementReportCollectionItems = (): Array<JSX.Element> => {

		return wasteManagementReports.map<JSX.Element>((wasteManagementReport) => {

			if (isGeneratorReport && wasteManagementReport.WasteManagementReportGenerators.length > 0) {
				return (
					<WasteManagementReportCollectionItem
						key={wasteManagementReport.Uuid}
						wasteManagementReport={wasteManagementReport}
					/>
				);
			}
			if (!isGeneratorReport && wasteManagementReport.WasteManagementReportCyclotronProducts.length > 0) {
				return (
					<WasteManagementReportCollectionItem
						key={wasteManagementReport.Uuid}
						wasteManagementReport={wasteManagementReport}
					/>
				);
			}
			return null;
		});
	};

	return (
		<CardCollectionLayout>
			<ExpandableSlim mode='slim' expanded>
				<ExpandableSlimHeader
					caption={isGeneratorReport ?
						l10nContext.translate('view.wastemanagementReports.wastemanagementReportsGenerators.title', 'Generatoren und Eluate') :
						l10nContext.translate('view.wastemanagementReports.wastemanagementReportsCyclotronProducts.title', 'Andere Radionuklide')}
					isSlimMode={true}
				/>
				<ExpandableSlimBody>
					<ViewLayout>
						<ParagraphElement text={isGeneratorReport ?
							l10nContext.translate('view.wastemanagementReports.wastemanagementReportsGenerators.description', 'Buchführung nach § 85 StrlSchV über den Bezug und die Gewinnung von radioaktiven Stoffen') :
							l10nContext.translate('view.wastemanagementReports.wastemanagementReportsCyclotronProducts.description', 'Buchführung nach § 85 StrlSchV über den Bezug von radioaktiven Stoffen')}
						/>
						<ViewLayoutSection>
							<ColumnLayout>
								<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
									<ButtonPrimary
										buttonText='Meldung erstellen'
										icon={IconIdentifier.ADD}
										disabled={!authContext.hasPermission(Permission.WASTE_MANAGEMENT_CREATE)}
										onClick={onModalClick}
									/>
								</ColumnLayoutSection>
							</ColumnLayout>
						</ViewLayoutSection>

						<ViewLayoutSection>
							<CardCollectionLayout>
								{renderWasteManagementReportCollectionItems()}
							</CardCollectionLayout>
						</ViewLayoutSection>
					</ViewLayout>


				</ExpandableSlimBody>
			</ExpandableSlim>
		</CardCollectionLayout>
	);
};
