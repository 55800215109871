import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';

export interface ClearanceUpdateFormStep1Props {
	device: DeviceViewModel;
	onClickNext: () => void;
	onChange?: (identifier: string, value: string) => void;
	invalidInputs?: string[];
}

export const ClearanceUpdateFormStep1 = (props: ClearanceUpdateFormStep1Props): JSX.Element => {
	const { device, onClickNext, invalidInputs = [], onChange } = props;

	const l10nContext = useContext(L10nContext);

	const notification = !invalidInputs.length ?
		null :
		<NotificationBar
			level={NotificationLevel.LEVEL_ERROR}
			message={`${invalidInputs.join(` ${l10nContext.translate('common.text.and', 'und')} `)} ${l10nContext.translate('common.modal.notificationError', 'darf nicht leer sein')}`}
		/>;

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<NotificationBars>
				{notification}
			</NotificationBars>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('view.clearances.modal.update.formName', 'Name')}*`}>
					<InputText
						type="text"
						defaultValue={device.Name ?? ''}
						onChange={(event) => onChange('Name', event.target.value)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={l10nContext.translate('view.clearances.modal.update.formIdentifier', 'Anmerkung')}>
					<InputText
						type="text"
						defaultValue={device.Identifier ?? ''}
						onChange={(event) => onChange('Identifier', event.target.value)}
						maxLength={128}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
