import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { getSequenceDefinition, getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { OutputListSelection } from 'services/device/presentation/ui/widget/widget-renderer/OutputListSelection';
import { selectInitialRecordBySequence, fetchRecordsBySequence } from 'services/device/store/recordSlice';

import { useTypedSelector } from 'store/common/TypedSelector';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { ClientContext } from '../../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../../core/context/FacilityContext';

export interface OutputListProps {
	sequenceViewModel: SequenceViewModel;
}

export const OutputListRenderer = (props: OutputListProps): JSX.Element => {
	const { sequenceViewModel } = props;

	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Use a custom selector function to provide type information for the state defined in the slice
	const recordsStoreFetchStatus = useTypedSelector(state => state.records.fetchStatus);

	// Read records from the server
	useEffect(() => {
		if (recordsStoreFetchStatus === AsyncFetchStatus.INITIAL || recordsStoreFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchRecordsBySequence({
				clientUuid: clientContext.selectedClientUuid,
				facilityUuid: facilityContext.selectedFacilityUuid,
				sequenceUuid: props.sequenceViewModel.Uuid
			}));
		}
	});

	const sequenceTypeDefinition = getSequenceTypeDefinition(sequenceViewModel.Type);

	// We don't get a sequence here, an error occurs
	const sequenceDefinition = getSequenceDefinition(sequenceViewModel.DeviceType, sequenceViewModel.Type);
	const sequence = sequenceDefinition.restoreSequenceFromViewData(sequenceViewModel.SequenceConfiguration);

	const initialRecordViewModel = useSelector(selectInitialRecordBySequence(sequenceViewModel));

	let initialRecord = null;
	if (initialRecordViewModel !== null) {
		initialRecord = sequenceTypeDefinition.restoreRecordFromViewData(initialRecordViewModel.RecordConfiguration);
	}

	const outputNodes: Array<JSX.Element> = [];

	for (const outputDescription of sequenceTypeDefinition.getDetailsDescription().entries) {
		sequence.getOutput(outputDescription).selectOutput(
			new OutputListSelection((element): void => {
				outputNodes.push(
					<li key={outputDescription.name}>
						<strong>{l10nContext.translate(outputDescription.labelKey)}:&nbsp;</strong>
						<p>{element}</p>
					</li>
				);
			})
		);
	}

	if (
		sequenceTypeDefinition.getDetailsDescription().showInitialValue
		&& initialRecord !== null
	) {
		outputNodes.push(
			<li key="initialValueRecordedAt">
				<strong>{l10nContext.translate('sequence.attributes.initialValueRecordedAt')}:&nbsp;</strong>
				<p>{l10nContext.formatDateTime(initialRecordViewModel?.RecordConfiguration.values.recordedAt)}</p>
			</li>
		);

		const valueOutputDescription = sequenceTypeDefinition.getValueDescription();
		initialRecord.getOutput(valueOutputDescription).selectOutput(
			new OutputListSelection((element): void => {
				outputNodes.push(
					<li key={valueOutputDescription.name}>
						<strong>{l10nContext.translate(valueOutputDescription.labelKey)}:&nbsp;</strong>
						<p>{element}</p>
					</li>
				);
			})
		);
	}

	return (
		<ul>
			{outputNodes}
		</ul>
	);

};
