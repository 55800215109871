import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { DeviceCreateForm } from 'services/device/presentation/ui/device-action/device-create-form/DeviceCreateForm';
import { DeviceCreateFailedNotification } from 'services/device/presentation/ui/device-action/device-create-modal/DeviceCreateFailedNotification';
import { DeviceCreateSuccessNotification } from 'services/device/presentation/ui/device-action/device-create-modal/DeviceCreateSuccessNotification';
import { selectCreatedDevice } from 'services/device/store/devicesSlice';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

interface DeviceCreateModalProps {
	/**
	 * onDismiss
	 */
	onDismiss: () => void;
}

/**
 * The device create modal
 */
export const DeviceCreateModal = (props: DeviceCreateModalProps): JSX.Element => {
	const { onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const createdDevice = useSelector(selectCreatedDevice());
	const deviceActionStatus = useTypedSelector(state => state.devices.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (deviceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <DeviceCreateForm />;
				break;

			case AsyncReducerStatus.CREATED:
				modal =
					<DeviceCreateSuccessNotification
						onClickConfirm={onDismiss}
						deviceCreated={createdDevice}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<DeviceCreateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.devices.modal.caption', 'Gerät hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
