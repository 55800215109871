export interface TooltipItem {
	datasetIndex: number;
	index: number;
	label: string;
	value: string;
	x: number;
	xLabel: string;
	y: number;
	yLabel: number;
}

export interface ChartJsMouseEvent extends MouseEvent {
	layerX: number;
}

export const SequenceChartOptionsPrototype = {
	animation: false,
	legend: {
		display: false
	},
	hover: {
		mode: 'index',
		intersect: false
	},
	onHover: (event: ChartJsMouseEvent, data: Array<{ [key: string]: any }>): void => {
		if (data.length === 0) {
			return;
		}

		const chart = data[0]._chart;
		const xScale = data[0]._xScale;
		const yScale = data[0]._yScale;

		let x = event.layerX;
		x = Math.max(x, xScale.getPixelForValue(new Date(xScale.min)));
		x = Math.min(x, xScale.getPixelForValue(new Date(xScale.max)));

		chart.clear();
		chart.draw();
		chart.ctx.beginPath();
		chart.ctx.moveTo(x, yScale.getPixelForValue(yScale.max));
		chart.ctx.strokeStyle = 'rgba(166,166,166,0.5)';
		chart.ctx.lineWidth = 1;
		chart.ctx.lineTo(x, yScale.getPixelForValue(yScale.min));
		chart.ctx.stroke();
	},
	onClick: (event: ChartJsMouseEvent, data: Array<{ [key: string]: any }>): void => {
		if (data.length === 0) {
			return;
		}
		const chart = data[0]._chart;
		if (chart.options.onSelect === null) {
			return;
		}
		chart.options.onSelect(event, data);
	},
	onSelect: null as (event: ChartJsMouseEvent, data: Array<{ [key: string]: any }>) => void,
	tooltips: {
		enabled: false,
		mode: 'index',
		intersect: false,
		backgroundColor: '#ffffff',
		borderColor: '#b9c9d9',
		borderWidth: 1,
		displayColors: false,
		titleFontFamily: "'Nunito', sans-serif",
		titleFontSize: 14,
		titleFontStyle: 'bold',
		titleFontColor: '#8394a6',
		titleMarginBottom: 16,
		bodyFontFamily: "'Nunito', sans-serif",
		bodyFontSize: 12,
		bodyFontStyle: 'normal',
		bodyFontColor: '#8394a6',
		bodySpacing: 4,
		xPadding: 16,
		yPadding: 16,
		caretPadding: 30,
		callbacks: {
			title: null as (tooltipItems: Array<TooltipItem>) => string,
			label: null as (tooltipItem: TooltipItem, data: any) => string
		}
	},
	scales: {
		xAxes: [
			{
				type: 'time',
				display: true,
				adapters: {
					date: {
						locale: 'de'
					}
				},
				time: {
					unit: 'day',
					tooltipFormat: 'yyyy-MM-dd HH:mm:ss',
					displayFormats: {
						'day': 'yy-MM-dd'
					},
					parser: (datetime: Date): string => {
						return datetime.toISOString();
					}
				},
				scaleLabel: {
					display: false
				},
				gridLines: {
					drawBorder: false,
					color: '#e9eff6',
					zeroLineColor: '#e9eff6'
				},
				ticks: {
					fontColor: '#8394a6',
					fontSize: 12
				}
			}
		],
		yAxes: [
			{
				display: true,
				scaleLabel: {
					display: false
				},
				gridLines: {
					drawBorder: false,
					color: 'rgba(166,166,166,0.15)',
					zeroLineColor: 'rgba(166,166,166,0.25)'
				},
				ticks: {
					fontColor: '#8394a6',
					fontSize: 12,
					min: 0,
					max: 10
				}
			}
		]
	}
};
