import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './button-secondary.scss';

export enum ButtonSecondaryHeight {
	NORMAL = 'height-normal',
	SMALL = 'height-small'
}

export enum ButtonSecondaryStatus {
	NORMAL = 'status-normal',
	ATTENTION = 'status-attention'
}

export interface ButtonSecondaryProps extends React.ComponentPropsWithoutRef<'button'> {
	/**
	* Button text
	*/
	buttonText: string;
	/**
	 * (Optional) Icon
	 */
	icon?: IconIdentifier;
	/**
	 * Status of Button
	 */
	status?: ButtonSecondaryStatus;
	/**
	* Height of button without icon
	*/
	height?: ButtonSecondaryHeight;
}

export const ButtonSecondary = (props: ButtonSecondaryProps): JSX.Element => {
	const { buttonText, height = ButtonSecondaryHeight.NORMAL, icon, status, ...restProps } = props;

	const buttontext =
		<span className='button-secondary__text'>
			{buttonText}
		</span>;

	const buttonContent = icon && (height && height !== ButtonSecondaryHeight.SMALL) ?
		<>
			<Icon name={icon} />
			{buttontext}
		</>
		:
		<>
			{buttontext}
		</>;

	const componentStyle = `button-secondary ${status ?? ''} ${height ?? ''}`;

	return (
		<button
			className={componentStyle}
			type='button'
			{...restProps}
		>
			<div className='button-secondary__button'>
				{buttonContent}
			</div>
		</button>
	);
};
