import { InputNumber } from 'presentation/ui/partials/input/input-number/InputNumber';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { NumberInput } from 'services/device/domain/business/common/Input';

import { Label } from 'presentation/ui/partials/input/label/Label';

export interface NumberInputProps {
	input: NumberInput;
}

export const NumberInputRenderer = (props: NumberInputProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const { input } = props;

	let label = l10nContext.translate(input.getLabelKey());
	if (input.getDescription()?.mandatory === true) {
		label += '*';
	}

	return (
		<Label label={label}>
			<InputNumber
				name={input.getName()}
				defaultValue={input.getSingle() ?? ''}
				required={!input.isOptional()}
				onChange={(event) => {
					input.setSingle(event.target.valueAsNumber);
				}}
				step={1 / (Math.pow(10, input.getNumberOfDecimals()) * 10)}
			/>
		</Label>
	);

};
