import { memo, useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import './card-item-clearance-detail-attributes.scss';

export interface CardItemClearanceDetailAttributesProps {
	/**
	 * (Optional) location value
	 */
	note?: string

}

/**
 * A card item device detail attributes component
 */
export const CardItemClearanceDetailAttributes = memo((props: CardItemClearanceDetailAttributesProps): JSX.Element => {
	const { note } = props;

	const l10nContext = useContext(L10nContext);

	const checkEmptyValue = (val: string): string => {
		return val !== '' && val !== null ? val : '-';
	};

	const translations = {
		note: { label: l10nContext.translate('view.clearances.detail.note', 'Anmerkung'), value: checkEmptyValue(note) }
	};

	const gridItems = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const key = Math.random() * (1000 - 1) + 1;
			const styleClass = 'grid-item';

			return (
				<li
					key={key}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value ?? '-'}</p>
				</li>
			);
		});
	};

	return (
		<div className='card-item-clearance-detail-attributes'>
			<ul className='card-item-clearance-detail-attributes__grid'>
				{gridItems()}
			</ul>
		</div>
	);
});
