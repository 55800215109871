import { Optional } from '../misc/Optional';
import { Value } from './Value';

export class Null<Payload> implements Value<Payload> {
	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<Payload> {
		return [];
	}

	public getSingle(): Optional<Payload> {
		return null;
	}
}
