import { createContext } from 'react';

import { JsonWebToken } from 'services/core/lib/auth/AuthService';

interface ExternalWasteManagementAuthContextValue {
	authenticate(password: string): Promise<boolean>;

	jsonWebToken(): JsonWebToken | null;
}

export const ExternalWasteManagementReportAuthContext = createContext<ExternalWasteManagementAuthContextValue>({
	authenticate: async (): Promise<boolean> => {
		return false;
	},

	jsonWebToken(): JsonWebToken | null {
		return null;
	}
});
