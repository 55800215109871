import React, { useContext, useState } from 'react';
import { L10nContext } from '../../../../../../context/L10nContext';
import {
	ModalDialogueHeader
} from '../../../../../../presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import {
	ModalDialogueSection
} from '../../../../../../presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from '../../../../../../presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ColumnLayoutSection } from '../../../../../../presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import {
	ColumnLayoutSectionMode
} from '../../../../../../presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from '../../../../../../presentation/ui/layouts/column-layout/ColumnLayout';
import { ViewLayoutSection } from '../../../../../../presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ButtonPrimary } from '../../../../../../presentation/ui/partials/button/button-primary/ButtonPrimary';
import { IconIdentifier } from '../../../../../../presentation/ui/partials/icon/IconIdentifier';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';
import { ClientContext } from '../../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../../core/context/FacilityContext';
import { selectOpenContainers } from '../../../../store/containerSlice';
import { ContainerViewModalType } from '../../../view/ContainerViewModalType';
import { ContainerCreateModal } from '../../container-action/container-create-modal/ContainerCreateModal';
import { ContainerNuclidesAssignModal } from '../../container-action/container-nuclides-assign-modal/ContainerNuclidesAssignModal';

interface SingleNuclidesAssignToContainerModalProps {
	selectedNuclides: Array<string>,
	onDismiss: () => void,
	onSetSelectedNuclides: () => void
}

export const SingleNuclidesAssignToContainerModal = (props: SingleNuclidesAssignToContainerModalProps): JSX.Element => {
	const { selectedNuclides, onSetSelectedNuclides, onDismiss } = props;

	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const openContainers = useTypedSelector(selectOpenContainers(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const [modalType, setModalType] = useState<ContainerViewModalType >(null);

	const handleModalClick = (
		type: ContainerViewModalType
	) => {
		setModalType(type);
	};

	// Show modal
	const showModal = (): JSX.Element => {
		let modal: JSX.Element = null;

		if (modalType === ContainerViewModalType.ASSIGN_NUCLIDES) {
			modal =
				<ContainerNuclidesAssignModal
					selectedNuclides={selectedNuclides}
					onSetSelectedNuclides={onSetSelectedNuclides}
					onDismiss={onDismiss}
				/>;
		} else if (modalType === ContainerViewModalType.CREATE) {
			modal =
				<ContainerCreateModal
					selectedNuclides={selectedNuclides}
					onDismiss={onDismiss}
				/>;
		}
		return modal;
	};

	const showSelectOpenContainerButton = openContainers.length > 0 ?
		<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
			<ButtonPrimary
				disabled={selectedNuclides.length === 0}
				buttonText={l10nContext.translate('view.singleNuclides.modal.assignNuclidesToContainers.selectOpenContainer', 'Offenes Gebinde auswählen')}
				onClick={() => handleModalClick(ContainerViewModalType.ASSIGN_NUCLIDES)}
			/>
		</ColumnLayoutSection> : null;

	return (
		<ModalDialogue onDismiss={onDismiss}>
			{showModal()}
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.singleNuclides.modal.assignNuclidesToContainers.caption', 'Nuklid(e) zu Gebinde zuweisen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				<ViewLayoutSection>
					<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
						<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
							<ButtonPrimary
								disabled={selectedNuclides.length === 0}
								buttonText={l10nContext.translate('view.singleNuclides.modal.assignNuclidesToContainers.createNewContainer', 'Neues Gebinde erstellen')}
								onClick={() => handleModalClick(ContainerViewModalType.CREATE)}
							/>
						</ColumnLayoutSection>
						{showSelectOpenContainerButton}
					</ColumnLayout>
				</ViewLayoutSection>
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
