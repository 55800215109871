import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';

import { ValuedStringInput } from 'services/device/domain/business/common/Input';

export interface ValuedStringSingleInputProps {
	input: ValuedStringInput;
}

export const ValuedStringSingleInputRenderer = (props: ValuedStringSingleInputProps): JSX.Element => {

	const { input } = props;

	const options = input.getOptions().map((value): SelectOption => {
		return {
			label:value.value,
			value:value.value,
			checked: value.value === input.getSingle()?.value
		} as SelectOption;
	});

	const onSelect = (selectedValue: string): void => {
		const selectedOption = input.getOptions().find((value): boolean => {
			return value.value === selectedValue;
		});
		input.setSingle(selectedOption);
	};

	return (
		<Select
			options={options}
			onchange={onSelect}
		/>
	);

};
