import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { selectCreatedReport } from 'services/report/store/reportSlice';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ReportCreateSuccessNotification } from './ReportCreateSuccessNotification';
import { ReportCreateFailedNotification } from './ReportCreateFailedNotification';
import { ReportCreateForm } from '../report-create-form/ReportCreateForm';

interface ReportCreateModalProps {
	/**
	 * onDismiss
	 */
	onDismiss: () => void;
}

/**
 * The report create modal
 */
export const ReportCreateModal = (props: ReportCreateModalProps): JSX.Element => {
	const { onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const createdReport = useSelector(selectCreatedReport());
	const reportActionStatus = useTypedSelector(state => state.reports.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (reportActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <ReportCreateForm />;
				break;

			case AsyncReducerStatus.CREATED:
				modal =
					<ReportCreateSuccessNotification
						onClickConfirm={onDismiss}
						reportCreated={createdReport}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<ReportCreateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.reports.modal.action.create.caption', 'Bericht hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
