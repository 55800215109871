import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	ReportDocumentModelConverter as ModelConverter,
	ReportDocumentPersistenceModel as PersistenceModel,
	ReportDocumentStoreModel as StoreModel,
	ReportDocumentViewModel as ViewModel,
	CreatableReportDocumentViewModel as CreatableViewModel
} from 'services/report/domain/model/ReportDocumentModel';

export class ReportDocumentHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'report/api/v1';
	protected collectionControllerUri = 'report-documents';
	protected entityControllerUri = 'report-document';

	protected responseCollectionKey = 'ReportDocumentModelCollection';
	protected responseEntityKey = 'ReportDocumentModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
