import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';

import {
	WasteManagementReportEluateModelConverter as ModelConverter,
	WasteManagementReportEluatePersistenceModel as PersistenceModel,
	WasteManagementReportEluateStoreModel as StoreModel,
	WasteManagementReportEluateViewModel as ViewModel,
	CreatableWasteManagementReportEluateViewModel as CreatableViewModel
} from 'services/waste-management-report/domain/models/WasteManagementReportEluateModel';
import { ExternalWasteReportHttpPersistence } from '../../../lib/persistence/http/ExternalWasteReportHttpPersistence';

export class ExternalWasteManagementReportEluateHttpPersistence
	extends ExternalWasteReportHttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'generator/waste-management-report-api/v1';
	protected collectionControllerUri = 'generator-eluats';
	protected entityControllerUri = 'generator-eluat';

	protected responseCollectionKey = 'GeneratorEluatModelCollection';
	protected responseEntityKey = 'GeneratorEluatModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
