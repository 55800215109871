import { FunctionComponent } from 'react';

import './card-container-detail.scss';

export interface CardContainerDetailProps {
	/**
	 * (Optional) Archived state
	 */
	archived?: boolean;
	/**
	 * (Optional) Shadow
	 */
	shadow?: boolean;
	/**
	 * (Optional) On nuclide overview
	 */
	nuclideOverview?: boolean;
	/**
	 * (Optional) Disabled
	 */
	isDisabled?: boolean;
}

/**
 * The card container detail component
 */

export const CardContainerDetail: FunctionComponent<CardContainerDetailProps> = (props): JSX.Element => {
	const { children, shadow, nuclideOverview, isDisabled } = props;

	return (
		<div className={`card-container-detail ${shadow ? 'card-container-detail--shadow' : ''} ${nuclideOverview ? 'card-container-detail--nuclide' : ''} ${isDisabled ? 'card-container-detail--disabled' : ''}`}>
			<div className='card-container-detail__content'>
				{children}
			</div>
		</div>
	);
};
