import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { CreatableDocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';

import { DocumentAddFormStep1 } from 'services/documents/presentation/ui/document-action/document-add-form/DocumentAddFormStep1';
import { DocumentAddFormStep2 } from 'services/documents/presentation/ui/document-action/document-add-form/DocumentAddFormStep2';

import { createDocuments } from 'services/documents/store/documentSlice';

export interface DocumentAddFormProps {
	onChange: (selectedFiles: File[]) => void;
	documentScope: DocumentModelScope;
	documentScopeReference: string;
	onClickPrevious?: () => void;
}

export const DocumentAddForm = (props: DocumentAddFormProps): JSX.Element => {
	const { onChange, onClickPrevious, documentScope, documentScopeReference } = props;

	const [showStep2, setShowStep2] = useState<boolean>(false);
	const [invalidStatus, setInvalidStatus] = useState<boolean>(false);

	const selectedFiles = useRef<Array<File>>([]);

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const dispatch = useDispatch();

	const handleFileUpload = (): void => {
		const creatableDocuments = selectedFiles.current.map((file): CreatableDocumentViewModel => {
			return {
				Client: clientContext.selectedClientUuid,
				Facility: facilityContext.selectedFacilityUuid,
				Scope: documentScope,
				ScopeReference: documentScopeReference,
				File: file,
				Filename: file.name
			} as CreatableDocumentViewModel;
		});
		dispatch(createDocuments({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			viewModels: creatableDocuments
		}));
	};

	const handleFiles = (files: File[]): void => {
		selectedFiles.current = files;
	};

	const gotoStep1 = () => {
		setShowStep2(false);
	};

	const gotoStep2 = () => {
		if (selectedFiles.current && selectedFiles.current.length) {
			onChange(selectedFiles.current);
			setShowStep2(true);
		} else {
			setInvalidStatus(true);
		}
	};

	const formStep = !showStep2 ?
		<DocumentAddFormStep1
			files={selectedFiles.current}
			onChange={handleFiles}
			onClickNext={gotoStep2}
			invalid={invalidStatus}
			onClickPrevious={onClickPrevious}
		/> :
		<DocumentAddFormStep2
			files={selectedFiles.current}
			onClickBack={gotoStep1}
			onClickNext={handleFileUpload}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
