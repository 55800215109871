import './loading-spinner.scss';

export const LoadingSpinner = (): JSX.Element => (
	<div className="loading-spinner">
		<div className="loading-spinner__orbit-wrapper">
			<div className="loading-spinner__orbit" />
			<div className="loading-spinner__orbit" />
			<div className="loading-spinner__orbit" />
		</div>
	</div>
);
