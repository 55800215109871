import { StringOutput } from 'services/device/domain/business/common/Output';
import { LabelRenderer } from 'services/device/presentation/ui/widget/label-renderer/LabelRenderer';
import { StringOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/StringOutputValueRenderer';

export interface StringOutputProps {
	output: StringOutput;
}

export const StringOutputRenderer = (props: StringOutputProps): JSX.Element => {
	const { output } = props;

	return (
		<li key={output.getName()}>
			<strong>
				<LabelRenderer output={output} />
			</strong>
			<p>
				<StringOutputValueRenderer output={output} />
			</p>
		</li>
	);

};
