import { LogoHeader } from 'services/core/presentation/ui/logo-header/LogoHeader';

import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayoutSectionWidth } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionWidth';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ExternalWasteManagementReportLogin } from '../ui/login/ExternalWasteManagementReportLogin';

export const ExternalWasteManagementReportLoginView = (): JSX.Element => {
	return (
		<MainLayout>
			<TopbarLayoutSection fluid>
				<LogoHeader />
			</TopbarLayoutSection>

			<MainLayoutSection width={MainLayoutSectionWidth.WIDTH_NARROW}>
				<ViewLayoutSection>
					<ExternalWasteManagementReportLogin />
				</ViewLayoutSection>
			</MainLayoutSection>
			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
