import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { FilterChipSet } from 'presentation/ui/components/filter/filter-chip-set/FilterChipSet';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { CardCollectionLayoutHeader } from 'presentation/ui/layouts/card-collection-layout/card-collection-layout-header/CardCollectionLayoutHeader';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentEntry } from 'services/documents/presentation/ui/document-entry/DocumentEntry';
import { LoadingSpinnerEmptyCard } from '../../../../../../presentation/ui/components/loading-spinner-empty-card/LoadingSpinnerEmptyCard.';

export interface DocumentCollectionProps {
	documents: ReadonlyArray<DocumentViewModel>;
	onAddDocument: () => void;
	onAction: (payload: any, document: any) => void;
	addDocumentEnabled?: boolean;
	onFilterClick?: (id: string, state: boolean) => void;
	fetchStatus?: boolean;
}

export const DocumentsCollection = (props: DocumentCollectionProps): JSX.Element => {
	const { documents, onAddDocument, onFilterClick, onAction, addDocumentEnabled = true, fetchStatus } = props;

	const l10nContext = useContext(L10nContext);

	const handleActionClick = (payload: any, document: any) => {
		onAction(payload, document);
	};

	const filter =
		<FilterChipSet
			label={l10nContext.translate('common.filter.status', 'Status')}
			chips={[
				{
					label: l10nContext.translate('common.filter.active', 'aktiv'),
					id: 'active',
					active: true,
					onClick: onFilterClick ?? null
				},
				{
					label: l10nContext.translate('common.filter.archived', 'archiviert'),
					id: 'archived',
					onClick: onFilterClick ?? null
				}
			]}
		/>;

	const renderDocumentCards = () => {
		let cards;

		if (fetchStatus) {
			return (
				<CardEmpty>
					<LoadingSpinnerEmptyCard />
				</CardEmpty>
			);
		}

		if (documents.length > 0) {
			cards = documents.map((document: DocumentViewModel) => (
				<DocumentEntry
					key={Guid.generate()}
					documentEntry={document}
					onActionClick={(action) => handleActionClick(action, document)}
				/>
			));
		} else {
			cards = <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.documents', 'kein Dokument')} />;
		}

		return cards;
	};

	return (
		<div className="documents-collection">
			<Expandable>
				<ExpandableHeader caption={l10nContext.translate('view.device.headline.documents', 'Dokumente')}>
					<ButtonIcon
						weight={ButtonIconWeight.SECONDARY}
						shape={ButtonIconShape.ROUND}
						icon={IconIdentifier.ADD}
						onClick={onAddDocument}
						disabled={!addDocumentEnabled}
					/>
				</ExpandableHeader>
				<ExpandableBody>
					<CardCollectionLayout>
						<CardCollectionLayoutHeader>
							{filter}
						</CardCollectionLayoutHeader>
						{renderDocumentCards()}
					</CardCollectionLayout>
				</ExpandableBody>
			</Expandable>
		</div>
	);
};
