import { ClassName } from 'lib/class-name/ClassName';

import './badge.scss';

export enum BadgeColor {
	RED = 'RED',
	LIGHT_BLUE = 'LIGHT_BLUE',
	PRIMARY = 'PRIMARY'
}

export interface BadgeProps {
	/**
	 * Label
	 */
	label: string;
	/**
	 * Color
	 */
	color?: BadgeColor;
}

export const Badge = (props: BadgeProps): JSX.Element => {
	const { label, color = BadgeColor.PRIMARY } = props;

	const colorModifier = `badge--${ClassName.fromEnumValue(color)}`;

	return (
		<div
			className={`badge ${colorModifier}`}
		>
			{label}
		</div>
	);
};
