import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { selectCreatedWasteManagementReport } from '../../../../store/wasteManagementReportSlice';
import { WasteManagementGeneratorReportCreateForm } from '../waste-management-generator-report-create-form/WasteManagementGeneratorReportCreateForm';
import { WasteManagementGeneratorReportCreateFailedNotification } from './WasteManagementGeneratorReportCreateFailedNotification';
import { WasteManagementGeneratorReportCreateSuccessNotification } from './WasteManagementGeneratorReportCreateSuccessNotification';

interface WasteManagementReportCreateModalProps {
	/**
	 * onDismiss
	 */
	onDismiss: () => void;
}

/**
 * The report create modal
 */
export const WasteManagementGeneratorReportCreateModal = (props: WasteManagementReportCreateModalProps): JSX.Element => {
	const { onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const createdWasteManagementReport = useSelector(selectCreatedWasteManagementReport());
	const wasteManagementReportActionStatus = useTypedSelector(state => state.wasteManagementReports.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (wasteManagementReportActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <WasteManagementGeneratorReportCreateForm />;
				break;

			case AsyncReducerStatus.CREATED:
				modal =
					<WasteManagementGeneratorReportCreateSuccessNotification
						onClickConfirm={onDismiss}
						wasteManagementReportCreated={createdWasteManagementReport}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<WasteManagementGeneratorReportCreateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.wastemanagementReports.modal.action.create.caption', 'Jahresmeldung hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
