import { ClassName } from 'lib/class-name/ClassName';
import { deregisterFocusTrap, registerFocusTrap, useOnClickOutside, useScrollBlock } from 'presentation/hooks';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ComponentPropsWithoutRef, useEffect, useRef } from 'react';

import './modal-dialogue.scss';

export interface ModalDialogueProps extends ComponentPropsWithoutRef<'article'> {
	onDismiss?: () => void;
	height?: ModalDialogueHeight;
}

export const ModalDialogue = (props: ModalDialogueProps): JSX.Element => {
	const {
		height = ModalDialogueHeight.HEIGHT_FIXED,
		onDismiss = null
	} = props;

	const windowInstance = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		registerFocusTrap(windowInstance.current);
		return deregisterFocusTrap;
	}, []);

	const [blockScroll, allowScroll] = useScrollBlock();

	// Dialogue visibility handling
	useEffect(() => {
		blockScroll();
		document.addEventListener('keydown', handleEscapeKeyDown);
		return () => {
			allowScroll();
			document.removeEventListener('keydown', handleEscapeKeyDown);
		};
	});

	useOnClickOutside(windowInstance, null, () => {
		if (onDismiss !== null) {
			onDismiss();
		}
	});

	const handleDismiss = (): void => {
		if (onDismiss) {
			onDismiss();
		}
	};

	const handleEscapeKeyDown = (event: KeyboardEvent): void => {
		event.stopPropagation();
		if (event.key === 'Escape' && onDismiss !== null) {
			onDismiss();
		}
	};

	const dismissElement = onDismiss ?
		// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
		<div className="modal-dialogue__window__dismiss">
			<ButtonIcon
				icon={IconIdentifier.CLOSE}
				weight={ButtonIconWeight.GHOST}
				shape={ButtonIconShape.ROUND}
				onClick={handleDismiss}
			/>
		</div> :
		null;

	return (
		<div className="modal-dialogue">
			<div role="dialog" className="modal-dialogue__background" />
			<article
				className={`modal-dialogue__window modal-dialogue__window--${ClassName.fromEnumValue(height)}`}
				ref={windowInstance}
			>
				{dismissElement}
				{props.children}
			</article>
		</div>
	);
};
