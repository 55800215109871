import { useContext, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';
import { AuthContext } from '../../../../../core/context/AuthContext';
import { ClientContext } from '../../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../../core/context/FacilityContext';

import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { ContainerModelState } from '../../../../domain/model/ContainerModelState';
import { CyclotronProductViewModel } from '../../../../domain/model/CyclotronProductModel';
import { GeneratorEluateIsotope } from '../../../../domain/model/isotopes/GeneratorEluateIsotope';
import { GeneratorTypeIsotope } from '../../../../domain/model/isotopes/GeneratorTypeIsotope';
import { NuclideHalfLifePerHour } from '../../../../domain/model/isotopes/NuclideHalfLifePerHour';
import { NuclideIsotope } from '../../../../domain/model/isotopes/NuclideIsotope';
import { calculateRemainingTime } from '../../../../domain/model/isotopes/NuclideIsotopeDefinition';
import { NuclideSpecificActivity } from '../../../../domain/model/isotopes/NuclideSpecificActivity';
import { NuclideModelActivityUnit } from '../../../../domain/model/NuclideModelActivityUnit';
import { updateContainer } from '../../../../store/containerSlice';
import { selectInWastemanagementCyclotronProducts } from '../../../../store/cyclotronProductSlice';
import { selectInWastemanagementEluates } from '../../../../store/eluateSlice';
import { selectGenerators } from '../../../../store/generatorSlice';
import { EluatePayloadType } from '../../../view/SingleNuclidesView';
import { ContainerFactorMeasurementFormStep1 } from './ContainerFactorMeasurementFormStep1';
import { ContainerFactorMeasurementFormStep2 } from './ContainerFactorMeasurementFormStep2';

export interface ContainerFactorMeasurementFormProps {
	container?: ContainerViewModel;
}

export interface NuclideHalflife {
	Nuclide: NuclideIsotope,
	Halflife: number
}

export const ContainerFactorMeasurementForm = (props: ContainerFactorMeasurementFormProps): JSX.Element => {
	const { container } = props;

	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const facilityRadiationFactor = facilityContext.selectedFacility().RadiationFactor ?? 10;
	const radiationFactor = (facilityRadiationFactor).toString() + '%';

	const updatedContainer = useRef<ContainerViewModel>({} as ContainerViewModel);

	const inWastemanagementCyclotronProducts = useTypedSelector(selectInWastemanagementCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementEluates = useTypedSelector(selectInWastemanagementEluates());

	const generators = useTypedSelector(selectGenerators(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	inWastemanagementEluates.forEach(eluate => {

		const inWastemanagementEluateGenerator = generators.find((generator) => {
			return generator.Uuid === eluate.Generator;
		});
		if (inWastemanagementEluateGenerator.Isotope === GeneratorTypeIsotope.MOLYBDENUM_99_MO) {
			eluate.Isotope = GeneratorEluateIsotope.TECHNETIUM_99M_TC;
		} else {
			eluate.Isotope = GeneratorEluateIsotope.GALLIUM_68_GA;
		}
	});

	const inWastemanagementNuclides: Array<EluatePayloadType | CyclotronProductViewModel> =
		[].concat(inWastemanagementEluates, inWastemanagementCyclotronProducts);

	let containerNuclideIds: string[] = [];
	containerNuclideIds = [].concat(container.GeneratorEluatIds, container.CyclotroneProductIds);

	const containerNuclides = inWastemanagementNuclides.filter(nuclideModel => containerNuclideIds.includes(nuclideModel.Uuid));

	const KILO_BECQUEREL = 1000;
	const MEGA_BECQUEREL = KILO_BECQUEREL * 1000;
	const GIGA_BECQUEREL = MEGA_BECQUEREL * 1000;

	let sumNominalActivity: number = 0;
	containerNuclides.map((nuclide) => {
		const hasNominalActivityProperty = Object.prototype.hasOwnProperty.call(nuclide, 'NominalActivity');
		let activity: number;
		if (nuclide.ActivityUnit === NuclideModelActivityUnit.KILO_BEQUEREL) {
			hasNominalActivityProperty ?
				activity = nuclide.NominalActivity * KILO_BECQUEREL :
				activity = nuclide.Activity * KILO_BECQUEREL;
			sumNominalActivity += activity;
		}
		if (nuclide.ActivityUnit === NuclideModelActivityUnit.MEGA_BEQUEREL) {
			hasNominalActivityProperty ?
				activity = nuclide.NominalActivity * MEGA_BECQUEREL :
				activity = nuclide.Activity * MEGA_BECQUEREL;
			sumNominalActivity += activity;
		}
		if (nuclide.ActivityUnit === NuclideModelActivityUnit.GIGA_BEQUEREL) {
			hasNominalActivityProperty ?
				activity = nuclide.NominalActivity * GIGA_BECQUEREL :
				activity = nuclide.Activity * GIGA_BECQUEREL;
			sumNominalActivity += activity;
		}

		return activity;
	});

	const sumWasteActivity = sumNominalActivity * (facilityRadiationFactor / 100);

	let currentSpecificActivity:number = 0;
	currentSpecificActivity = (sumNominalActivity * facilityRadiationFactor / 100 ) / updatedContainer.current.Weight;

	let specificActivity: any;
	let halflife: number;
	const checkContainerNuclides = containerNuclides.every((nuclide) => {
		return nuclide.Isotope === containerNuclides[0].Isotope;
	});

	if (checkContainerNuclides && containerNuclides[0].Isotope === GeneratorEluateIsotope.TECHNETIUM_99M_TC) {
		specificActivity = NuclideSpecificActivity.TECHNETIUM_99M_TC;
		halflife = NuclideHalfLifePerHour.TECHNETIUM_99M_TC;
	} if (checkContainerNuclides && containerNuclides[0].Isotope !== GeneratorEluateIsotope.TECHNETIUM_99M_TC) {
		specificActivity = NuclideSpecificActivity[containerNuclides[0].Isotope];
		halflife = NuclideHalfLifePerHour[containerNuclides[0].Isotope];
	}  if (!checkContainerNuclides) {
		const nuclidesHalfLifes: NuclideHalflife[] = [];
		containerNuclides.map((item) => {
			const nuclideHalflifeObject: NuclideHalflife = {
				Nuclide: item.Isotope,
				Halflife: NuclideHalfLifePerHour[item.Isotope]
			};
			nuclidesHalfLifes.push(nuclideHalflifeObject);
			const usedNuclideMixedContainer = nuclidesHalfLifes.sort((a, b) => a.Halflife - b.Halflife).reverse()[0].Nuclide;
			specificActivity = NuclideSpecificActivity[usedNuclideMixedContainer];
			halflife = NuclideHalfLifePerHour[usedNuclideMixedContainer];
			return specificActivity;
		});
	}

	const requiredInputs = ['MeasurementDate', 'Weight'];

	const remainingTime = calculateRemainingTime(halflife, currentSpecificActivity, specificActivity);

	const addHours = (date: Date, hours: number): Date => {
		const result = new Date(date);
		result.setHours(result.getHours() + hours);
		return result;
	};

	const releaseDate = addHours(updatedContainer.current.MeasurementDate, remainingTime);

	const onChangeContainerProp = (prop: string, value: number | Date) => {
		updatedContainer.current[prop] = value;
	};

	if ((updatedContainer.current?.MeasurementDate ?? null) === null) {
		updatedContainer.current.MeasurementDate = new Date();
	}

	const handleUpdate = (): void => {
		updatedContainer.current.Client = clientContext.selectedClientUuid;
		updatedContainer.current.Facility = facilityContext.selectedFacilityUuid;
		updatedContainer.current.Uuid = container.Uuid;
		updatedContainer.current.State = ContainerModelState.DECAY;
		updatedContainer.current.ReleaseDateTime = releaseDate;
		updatedContainer.current.MeasurementActivity = currentSpecificActivity;
		updatedContainer.current.MeasuredBy = authContext.getActor().Uuid;
		dispatch(updateContainer(updatedContainer.current));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newContainerModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedContainer.current?.[requiredInput] ?? null) === null
				|| updatedContainer.current?.[requiredInput] === ''
				|| String(updatedContainer.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newContainerModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newContainerModelValid);
	};

	const showCreateModalStep = showStep1 === true ?
		<ContainerFactorMeasurementFormStep1
			container={updatedContainer.current}
			radiationFactor={radiationFactor}
			sumWasteActivity={sumWasteActivity}
			onClickNext={goToStep2}
			invalidInputs={invalidInputs}
			onChangeContainerProp={onChangeContainerProp}
		/> :
		<ContainerFactorMeasurementFormStep2
			container={updatedContainer.current}
			radiationFactor={radiationFactor}
			sumWasteActivity={sumWasteActivity}
			currentSpecificActivity={currentSpecificActivity}
			specificActivity={specificActivity}
			halflife={halflife}
			releaseDateTime={releaseDate}
			onClickPrevious={goToStep1}
			onClickNext={handleUpdate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
