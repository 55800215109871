import { FunctionComponent } from 'react';

import './card-cyclotron-product-detail.scss';

export interface CardCyclotronProductDetailProps {
	/**
	 * (Optional) Archived state
	 */
	archived?: boolean;
	/**
	 * (Optional) Shadow
	 */
	shadow?: boolean;
	/**
	 * (Optional) On nuclide overview
	 */
	nuclideOverview?: boolean;
	/**
	 * (Optional) Disabled
	 */
	isDisabled?: boolean;
}

/**
 * The card cyclotron product detail component
 */

export const CardCyclotronProductDetail: FunctionComponent<CardCyclotronProductDetailProps> = (props): JSX.Element => {
	const { children, shadow, nuclideOverview, isDisabled } = props;

	return (
		<div className={`card-cyclotron-product-detail ${shadow ? 'card-cyclotron-product-detail--shadow' : ''} ${nuclideOverview ? 'card-cyclotron-product-detail--nuclide' : ''} ${isDisabled ? 'card-cyclotron-product-detail--disabled' : ''}`}>
			<div className='card-cyclotron-product-detail__content'>
				{children}
			</div>
		</div>
	);
};
