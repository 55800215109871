import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentAddModalContent } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddModalContent';
import { resetActionStatus as resetDocumentActionStatus } from 'services/documents/store/documentSlice';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { MaintenanceLogCreateForm } from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-create-form/MaintenanceLogCreateForm';
import {
	MaintenanceLogCreateModalFailed,
} from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-create-modal/MaintenanceLogCreateModalFailed';
import {
	MaintenanceLogCreateModalSuccess,
} from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-create-modal/MaintenanceLogCreateModalSuccess';
import { selectCreatedMaintenanceLogEntry } from 'services/maintenance-log/store/maintenanceLogEntrySlice';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface MaintenanceLogModalCreateProps {
	onDismiss: () => void,
	device?: DeviceViewModel
}

export const MaintenanceLogCreateModal = (props: MaintenanceLogModalCreateProps): JSX.Element => {
	const { onDismiss, device } = props;

	const l10nContext = useContext(L10nContext);

	const [showDocument, setShowDocument] = useState<boolean>(false);

	// Use selector to provide information from store
	const createdMaintenanceLogEntry = useSelector(selectCreatedMaintenanceLogEntry());
	const maintenenceLogEntryActionStatus = useTypedSelector(state => state.maintenanceLogEntries.actionStatus);

	const dispatch = useDispatch();

	const renderModal = () => {
		if (showDocument && createdMaintenanceLogEntry !== null) {
			return (
				<DocumentAddModalContent
					onDismiss={onDismiss}
					documentScope={DocumentModelScope.MAINTENANCE_LOG_ENTRY}
					documentScopeReference={createdMaintenanceLogEntry.Uuid}
				/>
			);
		}

		switch (maintenenceLogEntryActionStatus) {
			case AsyncReducerStatus.IDLE:
				return <MaintenanceLogCreateForm
					device={device ?? null}
				/>;

			case AsyncReducerStatus.CREATED:

				return <MaintenanceLogCreateModalSuccess
					maintenanceLogEntryCreated={createdMaintenanceLogEntry}
					onClick={onDismiss}
					onClickAddDocuments={(): void => {
						dispatch(resetDocumentActionStatus());
						setShowDocument(true);
					}}
				/>;

			case AsyncReducerStatus.FAILED:
				return <MaintenanceLogCreateModalFailed onClick={onDismiss} />;

			default:
				return null;
		}
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.maintenances.modal.action.create.caption', 'Eintrag hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
