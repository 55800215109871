import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';

import {
	ContainerDisposeModelConverter as ModelConverter,
	ContainerDisposePersistenceModel as PersistenceModel,
	ContainerDisposeStoreModel as StoreModel,
	ContainerDisposeViewModel as ViewModel,
	CreatableContainerDisposeViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/ContainerDisposeModel';
import { ContainerDisposeHttpPersistence as HttpPersistence } from 'services/nuclide/persistence/ContainerDisposeHttpPersistence';

export class ContainerDisposePersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {

	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

}
