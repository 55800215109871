import { useContext, useRef, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';

import { useOnClickOutside } from 'presentation/hooks';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './user-profile.scss';

export interface UserProfileProps {
	/**
	 * User name
	 */
	userName: string;
	/**
	 * Location
	 */
	location: string;
}

/**
 * User profile partial
 */

export const UserProfile = (props: UserProfileProps): JSX.Element => {
	const { userName, location } = props;

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const [openState, setOpenState] = useState<boolean>(false);

	const selectInstance = useRef(null);

	const translations = {
		'clientLocationChange': l10nContext.translate('userProfile.location', 'Mandanten/Standort wechseln'),
		'logout': l10nContext.translate('greeting.logout', 'Logout'),
	};

	useOnClickOutside(selectInstance, null, () => {
		setOpenState(false);
	});

	const handleLogout = async () => {
		await authContext.invalidate();
	};

	const clientLocationChange = (clientContext.availableClients().length === 1 && facilityContext.availableFacilities().length === 1)
		|| facilityContext.availableFacilities().length === 1 ?
		(
			<div
				className='label-location'
				role='button'
				tabIndex={0}
				onClick={() => clientContext.deselect()}
			>
				{translations.clientLocationChange}
			</div>
		)
		:
		(
			<div
				className='label-location'
				role='button'
				tabIndex={0}
				onClick={() => facilityContext.deselect()}
			>
				{translations.clientLocationChange}
			</div>
		);

	const boxOpenedDecoratorClass = clientLocationChange === null ?
		'user-profile__box-opened--without-facility-selection'
		:
		'user-profile__box-opened--with-facility-selection';

	const showUserProfileOpen = openState === true ?
		<div
			className={`user-profile__box-opened ${boxOpenedDecoratorClass}`}
		>
			{clientLocationChange}
			<div
				className='label-logout'
				role='button'
				tabIndex={0}
				onClick={(event) => {
					event.preventDefault();

					void handleLogout();
				}}
			>
				{translations.logout}
			</div>
		</div>
		:
		null;

	const iconButton =
		<ButtonIcon
			icon={openState === false ? IconIdentifier.DROPDOWN_OPEN : IconIdentifier.DROPDOWN_CLOSE}
			weight={ButtonIconWeight.GHOST}
			shape={ButtonIconShape.ROUND}
			onClick={() => setOpenState(!openState)}
		/>;

	return (
		<div
			className={`user-profile ${openState === false ? '' : 'userProfile--open'}`}
			ref={selectInstance}
		>
			<div className='user-profile__box-closed'>
				<div
					className='user-profile__texts'
					onClick={() => iconButton.props.onClick()}
					role='button'
					tabIndex={0}
				>
					<p className='user'>
						{userName}
					</p>
					<p className='location'>
						{location}
					</p>
				</div>

				{iconButton}
			</div>

			{showUserProfileOpen}
		</div>
	);
};
