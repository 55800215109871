export enum AsyncReducerStatus {
	IDLE,
	UPDATE_PENDING,
	CREATE_PENDING,
	DELETE_PENDING,
	UPDATED,
	CREATED,
	DELETED,
	FAILED
}
