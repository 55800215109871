import { memo, useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import './card-item-maintenance-log-attributes.scss';

export interface CardItemMaintenanceLogAttributesProps {
	/**
	 * (Optional) recorded at date
	 */
	recordDate: Date
	/**
	 * (Optional) recorder name
	 */
	recorder: string
	/**
	* (Optional) solved at date
	*/
	solvedDate?: Date | null
	/**
	 * (Optional) recorder solved name
	 */
	recorderSolved?: string | null
	/**
	* (Optional) activities values
	*/
	activities?: string | null
	incidentCause?: string | null
}

/**
 * A card item operation log attributes component
 */
export const CardItemMaintenanceLogAttributes = memo((props: CardItemMaintenanceLogAttributesProps): JSX.Element => {
	const { recordDate, recorder, solvedDate, recorderSolved, activities, incidentCause } = props;

	const l10nContext = useContext(L10nContext);

	const checkEmptyValue = (val: string): string => {
		return val !== '' && val !== null ? val : '-';
	};

	const translations = {
		recordDate: { label: l10nContext.translate('view.maintenances.logEntry.recordDate', 'Erfasst am'), value: checkEmptyValue(l10nContext.formatDateTime(recordDate)) },
		recorder: { label: l10nContext.translate('view.maintenances.logEntry.recorder', 'Erfasser'), value: checkEmptyValue(recorder) },
		incidentCause: { label: l10nContext.translate('view.maintenances.logEntry.incident', 'Vorfall'), value: checkEmptyValue(incidentCause) },
		solvedDate: { label: l10nContext.translate('view.maintenances.logEntry.solvedDate', 'Behoben am'), value: solvedDate ? l10nContext.formatDateTime(solvedDate) : checkEmptyValue(l10nContext.formatDate(solvedDate)) },
		recorderSolved: { label: l10nContext.translate('view.maintenances.logEntry.recoderSolved', 'Erfasser der Behebung'), value: checkEmptyValue(recorderSolved) },
		activities: { label: l10nContext.translate('view.maintenances.logEntry.activities', 'Maßnahmen'), value: checkEmptyValue(activities) }
	};

	const gridItems = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const key = Math.random() * (1000 - 1) + 1;
			const styleClass = 'grid-item';

			return (
				<li
					key={key}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value}</p>
				</li>
			);
		});
	};

	return (
		<div className='card-item-maintenance-log-attributes'>
			<ul className='card-item-maintenance-log-attributes__grid'>
				{gridItems()}
			</ul>
		</div>
	);
});
