import { L10nContext } from 'context/L10nContext';
import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { selectCreatedContainer } from '../../../../store/containerSlice';
import { ContainerCreateForm } from '../container-create-form/ContainerCreateForm';
import { ContainerCreateFailedNotification } from './ContainerCreateFailedNotification';
import { ContainerCreateSuccessNotification } from './ContainerCreateSuccessNotification';

interface ContainerCreateModalProps {
	selectedNuclides: Array<string>,
	onDismiss: () => void;
}

export const ContainerCreateModal = (props: ContainerCreateModalProps): JSX.Element => {
	const { selectedNuclides, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const createdContainer = useSelector(selectCreatedContainer());
	const containerActionStatus = useTypedSelector(state => state.containers.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (containerActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <ContainerCreateForm selectedNuclides={selectedNuclides} />;
				break;

			case AsyncReducerStatus.CREATED:
				modal =
					<ContainerCreateSuccessNotification
						onClickConfirm={onDismiss}
						containerCreated={createdContainer}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<ContainerCreateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;

	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.containers.modal.action.create.caption', 'Gebinde hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
