import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import './footer.scss';

export const Footer = (): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const renderNavigationItems = (): JSX.Element => {
		return (
			<>
				<a href={l10nContext.translate('footer.legalTerms.url', '#')} target="_blank" rel="noreferrer" className="footer__navigation__item">
					{l10nContext.translate('footer.legalTerms.label', 'Impressum')}
				</a>
				<a href={l10nContext.translate('footer.termsOfPrivacy.url', '#')} target="_blank" rel="noreferrer" className="footer__navigation__item">
					{l10nContext.translate('footer.termsOfPrivacy.label', 'Datenschutz')}
				</a>
				<a href={l10nContext.translate('footer.termsOfUse.url', '#')} target="_blank" rel="noreferrer" className="footer__navigation__item">
					{l10nContext.translate('footer.termsOfUse.label', 'AGB')}
				</a>
			</>
		);
	};

	return (
		<footer className="footer">
			<nav className="footer__navigation">
				{renderNavigationItems()}
			</nav>
		</footer>
	);

};
