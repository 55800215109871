import { createContext } from 'react';

interface ExpandableSlimContextValue {
	expanded: boolean | null;

	setExpanded(expanded: boolean): void;
}

export const ExpandableSlimContext = createContext<ExpandableSlimContextValue>({
	expanded: null,
	setExpanded(): void {
	}
});
