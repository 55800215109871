import { CardGeneratorListItem } from 'presentation/ui/components/cards/card-generator/card-generator-list-item/CardGeneratorListItem';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import {
	CardGeneratorListItemHeader
} from 'presentation/ui/partials/card/card-item-header/card-generator-list-item-header/CardGeneratorListItemHeader';
import { useHistory } from 'react-router-dom';
import { Route } from 'router/Route';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { GeneratorModelState } from 'services/nuclide/domain/model/GeneratorModelState';
import { IllustrationIdentifier } from '../../../../../../presentation/ui/partials/illustration/IllustrationIdentifier';
import { GeneratorTypeIsotope } from '../../../../domain/model/isotopes/GeneratorTypeIsotope';

export interface GeneratorCollectionItemProps {
	/**
	 * Generator
	 */
	generator: Readonly<GeneratorViewModel>;
}

export const GeneratorCollectionItem = (props: GeneratorCollectionItemProps): JSX.Element => {
	const { generator } = props;

	// Consume the history object
	const history = useHistory();

	return (
		<CardGeneratorListItem
			key={generator.Uuid}
			archived={generator.State === GeneratorModelState.DISPATCHED}
		>
			<CardGeneratorListItemHeader
				title={generator.Name}
				subtitle={generator.SerialNumber}
				illustration={generator.Isotope === GeneratorTypeIsotope.MOLYBDENUM_99_MO ?
					IllustrationIdentifier.GENERATOR_MO :
					IllustrationIdentifier.GENERATOR_GE}
				state={generator.State}
			/>
			<CardItemSingleControl
				cardId={generator.Uuid}
				actionDetails={CardItemControlState.ENABLED}
				onClick={() => history.push(`${Route.NUCLIDE_MANAGEMENT}${Route.GENERATORS}/${generator.Uuid}`)}
			/>
		</CardGeneratorListItem>
	);
};
