import { HttpApiConnector } from 'services/core/lib/auth/api-connector/HttpApiConnector';
import { LocalStorageApiConnector } from 'services/core/lib/auth/api-connector/LocalStorageApiConnector';
import { AuthService } from 'services/core/lib/auth/AuthService';

export class AuthServiceBuilder {

	private static onlineAuthService?: AuthService = null;
	private static localAuthService?: AuthService = null;

	public static build(online: boolean): AuthService {
		return (online) ? this.buildOnline() : this.buildLocal();
	}

	private static buildOnline(): AuthService {
		if (this.onlineAuthService === null) {
			this.onlineAuthService = new AuthService(new HttpApiConnector());
		}
		return this.onlineAuthService;
	}

	private static buildLocal(): AuthService {
		if (this.localAuthService === null) {
			this.localAuthService = new AuthService(new LocalStorageApiConnector());
		}
		return this.localAuthService;
	}

}
