import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ContainerModelConverter as ModelConverter,
	ContainerStoreModel as StoreModel,
	ContainerViewModel as ViewModel,
	CreatableContainerViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/ContainerModel';
import { DebugConsole } from '../../../lib/debug/DebugConsole';

import { PropellerError } from '../../../lib/persistence/http/error/PropellerError';
import { ModelQueryOrderClosureBuilder } from '../../../lib/persistence/idb/query/ModelQueryOrderClosureBuilder';
import { AsyncFetchStatus } from '../../../store/common/AsyncFetchStatus';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { AsyncReducerStatus } from '../../../store/common/AsyncReducerStatus';
import { RootState } from '../../../store/store';
import { ContainerModelState } from '../domain/model/ContainerModelState';
import { ContainerHttpPersistence as HttpPersistence } from '../persistence/ContainerHttpPersistence';
import { ContainerPersistence as Persistence } from '../persistence/ContainerPersistence';

// Declare a container state type
export interface ContainerState {
	containers: Array<StoreModel>;
	createdContainer: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState  = {
	containers: [] as Array<StoreModel>,
	createdContainer: null,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ContainerState;

// Implementation of the async actions
export const fetchContainers = createAsyncThunk(
	'containers/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Silently abort the action
			const { containers } = getState() as RootState;
			return checkFetchStatus(containers.fetchStatus);
		}
	}
);

export const resetContainers = createAsyncThunk(
	'containers/reset',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.resetCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { containers } = getState() as RootState;
			return checkFetchStatus(containers.fetchStatus);
		}
	}
);

export const createContainer = createAsyncThunk(
	'container/create',
	async (viewModel: CreatableViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.createEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { containers } = getState() as RootState;
			if (containers.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const updateContainer = createAsyncThunk(
	'container/update',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.updateEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {
			// Abort the action with an error
			const { containers } = getState() as RootState;
			if (containers.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteContainer = createAsyncThunk(
	'container/delete',
	async (viewModel: ViewModel): Promise<StoreModel> => {
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		return persistence.deleteEntity(viewModel);
	},
	{
		condition: (params, { getState }): boolean => {

			// Abort the action with an error
			const { containers } = getState() as RootState;
			if (containers.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const containerSlice = createSlice({
	name: 'containers',
	initialState,
	// Regular syncronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	extraReducers: {
		[String(fetchContainers.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchContainers.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.containers = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchContainers.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(resetContainers.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(resetContainers.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.containers = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(resetContainers.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(createContainer.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdContainer = null;
		},
		[String(createContainer.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.containers.push(action.payload);
			state.containers = state.containers.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.createdContainer = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createContainer.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(updateContainer.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateContainer.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.containers.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.containers[index] = action.payload;
			}
			state.containers = state.containers.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateContainer.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(deleteContainer.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteContainer.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.containers.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.containers.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteContainer.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		}
	}
});

export const { resetState, resetActionStatus } = containerSlice.actions;

// Export the reducer as default
export default containerSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectContainers = (clientUuid: string, facilityUuid: string): (rootSTate: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.containers.containers.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectContainerByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.containers.containers.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedContainer = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.containers.createdContainer;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectOpenContainers = (
	clientUuid: string,
	facilityUuid: string
): (rootState: RootState) => ReadonlyArray<ViewModel> => {

	const filterModelState = ContainerModelState.OPEN;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.containers.containers.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectDecayContainers = (
	clientUuid: string,
	facilityUuid: string
): (rootState: RootState) => ReadonlyArray<ViewModel> => {

	const filterModelState = ContainerModelState.DECAY;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.containers.containers.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectDispatchedContainers = (
	clientUuid: string,
	facilityUuid: string
): (rootState: RootState) => ReadonlyArray<ViewModel> => {

	const filterArray: Array<string> = [ContainerModelState.DISPATCHED, ContainerModelState.DISPOSED];

	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		let storeModels: Array<StoreModel> = [];
		filterArray.forEach((containerFilter) => {
			const filterRootStore = rootState.containers.containers.filter((storeModel): boolean => {
				return storeModel.Client === clientUuid
					&& storeModel.Facility === facilityUuid
					&& storeModel.State === String(containerFilter);
			}) ?? [];
			storeModels = storeModels.concat(filterRootStore);
		});
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectApprovedContainers = (
	clientUuid: string,
	facilityUuid: string
): (rootState: RootState) => ReadonlyArray<ViewModel> => {

	const filterModelState = ContainerModelState.RELEASE_DATE_REACHED;
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.containers.containers.filter((storeModel): boolean => {
			return storeModel.Client === clientUuid
				&& storeModel.Facility === facilityUuid
				&& storeModel.State === String(filterModelState);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

/* export const selectFilteredContainers = (
	clientUuid: string,
	facilityUuid: string,
	includeActive: boolean,
	includeDecay: boolean,
	includeDispatched: boolean
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	if (includeActive && includeDispatched && includeDecay || !includeActive && !includeDispatched && !includeDecay) {
		return selectContainers(clientUuid, facilityUuid);
	}

	const filterArray: Array<string> = [];
	if (includeActive) {
		filterArray.push(String(GeneratorModelState.ACTIVE));
	} if (includeDecay) {
		filterArray.push(String(GeneratorModelState.DECAY));
	} if (includeDispatched) {
		filterArray.push(String(GeneratorModelState.DISPATCHED));
	}

	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		let storeModels: Array<StoreModel> = [];
		filterArray.forEach((generatorFilter) => {
			const filteredRootStore = rootState.generators.generators.filter((storeModel): boolean => {
				return storeModel.Client === clientUuid
					&& storeModel.Facility === facilityUuid
					&& storeModel.State === String(generatorFilter);
			}) ?? [];
			storeModels = storeModels.concat(filteredRootStore);
		});
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
}; */
