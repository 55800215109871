import { L10nContext } from 'context/L10nContext';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Route } from 'router/Route';
import { AuthContext } from 'services/core/context/AuthContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Permission, permissionGroupGenerator } from 'services/core/lib/auth/AuthService';
import {
	fetchDocumentsByGenerator,
	resetActionStatus as resetDocumentsActionStatus,
	selectFilteredDocumentsByGenerator
} from 'services/documents/store/documentSlice';
import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { resetActionStatus as resetEluateActionStatus, selectEluatesByGenerator } from 'services/nuclide/store/eluateSlice';
import { resetActionStatus as resetGeneratorActionStatus, selectGeneratorByUuid } from 'services/nuclide/store/generatorSlice';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { CardDocumentAction } from '../../../../presentation/ui/components/cards/card-document/CardDocumentActions';
import { NotificationBar } from '../../../../presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from '../../../../presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from '../../../../presentation/ui/components/notification-bars/NotificationBars';
import { Separator } from '../../../../presentation/ui/partials/separator/Separator';
import { AsyncFetchStatus } from '../../../../store/common/AsyncFetchStatus';
import { ClientContext } from '../../../core/context/ClientContext';
import { DocumentsCollection } from '../../../device/presentation/ui/card-collections/documents-collection/DocumentsCollection';
import { DocumentViewModel } from '../../../documents/domain/model/DocumentModel';
import { DocumentModelScope } from '../../../documents/domain/model/DocumentModelScope';
import { DocumentAddModal } from '../../../documents/presentation/ui/document-action/document-add-modal/DocumentAddModal';
import { DocumentArchiveModal } from '../../../documents/presentation/ui/document-action/document-archive-modal/DocumentArchiveModal';
import { DocumentDeleteModal } from '../../../documents/presentation/ui/document-action/document-delete-modal/DocumentDeleteModal';
import { DocumentDownloadModal } from '../../../documents/presentation/ui/document-action/document-download-modal/DocumentDownloadModal';
import { DocumentEditModal } from '../../../documents/presentation/ui/document-action/document-edit-modal/DocumentEditModal';
import { DocumentUnarchiveModal } from '../../../documents/presentation/ui/document-action/document-unarchive-modal/DocumentUnarchiveModal';
import { GeneratorModelState } from '../../domain/model/GeneratorModelState';
import { EluateCollection } from '../ui/card-collections/eluate-collection/EluateCollection';
import { EluateCreateModal } from '../ui/eluate-action/eluate-create-modal/EluateCreateModal';
import { EluateDeleteModal } from '../ui/eluate-action/eluate-delete-modal/EluateDeleteModal';
import { EluateToWasteModal } from '../ui/eluate-action/eluate-to-waste-modal/EluateToWasteModal';
import { EluateUpdateModal } from '../ui/eluate-action/eluate-update-modal/EluateUpdateModal';
import { GeneratorDeleteModal } from '../ui/generator-action/generator-delete-modal/GeneratorDeleteModal';
import { GeneratorDispatchModal } from '../ui/generator-action/generator-dispatch-modal/GeneratorDispatchModal';
import { GeneratorUpdateModal } from '../ui/generator-action/generator-update-modal/GeneratorUpdateModal';
import { GeneratorCard } from '../ui/generator-card/GeneratorCard';
import { GeneratorViewModalType } from './GeneratorViewModalType';

interface GeneratorViewParams {
	generatorUuid: string;
}

export const GeneratorView = (): JSX.Element => {

	// Unwrap the required generator uuid from the route
	const params = useParams<GeneratorViewParams>();
	const generatorUuid = params?.generatorUuid ?? null;
	if (generatorUuid === null) {
		throw new Error('Generator id is missing');
	}

	// Read the generator from the state store
	const generator = useSelector(selectGeneratorByUuid(generatorUuid));

	// Read eluates from the state store
	const generatorEluates = useSelector(selectEluatesByGenerator(generator));

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const facilityContext = useContext(FacilityContext);
	const clientContext = useContext(ClientContext);
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	// Handle permissions
	if (!authContext.hasAnyPermission(permissionGroupGenerator)) {
		throw new Error('Permission denied');
	}

	// Local state
	const [modalType, setModalType] = useState<GeneratorViewModalType>(null);
	const [modalPayload, setModalPayload] =
		useState<
		GeneratorViewModel |
		EluateViewModel |
		DocumentViewModel |
		Array<EluateViewModel
		>>(null);

	const [documentFilter, setDocumentFilter] = useState<{ [key: string]: boolean }>({
		active: true,
		archived: false
	});

	// Use a custom selector function to provide type information for the state defined in the slice
	const generatorsActionStatus = useTypedSelector(state => state.generators.actionStatus);
	const generatorLastActionError = useTypedSelector(state => state.generators.lastActionError);
	const eluatesActionStatus = useTypedSelector(state => state.eluates.actionStatus);
	const eluatesLastActionError = useTypedSelector(state => state.eluates.lastActionError);

	// fetch documents
	const documentStoreFetchStatus = useTypedSelector(state => state.documents.fetchStatus);
	const documentStorePending = documentStoreFetchStatus === AsyncFetchStatus.INITIAL || documentStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const documentStoreLastActionError = useTypedSelector(state => state.documents.lastActionError);
	const documentStoreFailed = documentStoreFetchStatus === AsyncFetchStatus.FAILED;

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Handle close modal for success and failure
	const handleDismiss = () => {
		setModalType(null);
	};

	useEffect(() => {
		if (generatorsActionStatus === AsyncReducerStatus.DELETED) {
			dispatch(resetGeneratorActionStatus());
			history.replace(Route.NUCLIDE_MANAGEMENT + Route.GENERATORS);
		}
	}, [generator, generatorsActionStatus, dispatch, history]);

	useEffect(() => {
		if (eluatesActionStatus === AsyncReducerStatus.DELETED || eluatesActionStatus === AsyncReducerStatus.UPDATED) {
			dispatch(resetEluateActionStatus());
			handleDismiss();
		}
	}, [eluatesActionStatus, dispatch, history]);

	useEffect(() => {
		dispatch(fetchDocumentsByGenerator({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			generatorUuid: generator.Uuid
		}));
	}, [clientContext.selectedClientUuid, dispatch, facilityContext.selectedFacilityUuid, generator.Uuid]);

	const documents = useSelector(
		selectFilteredDocumentsByGenerator(
			clientContext.selectedClientUuid,
			facilityContext.selectedFacilityUuid,
			generatorUuid,
			documentFilter.active,
			documentFilter.archived
		)
	);

	// Show up a loading indicator while the generator fetching is pending
	if (
		generatorsActionStatus === AsyncReducerStatus.DELETE_PENDING
		|| generatorsActionStatus === AsyncReducerStatus.DELETED
	) {
		return (
			<LoadingSpinner />
		);
	}

	if (generator === null ) {
		// Show up an error if the generator is not available
		throw new Error('Generator not found');
	}

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permission: {
			update: authContext.hasPermission(Permission.GENERATOR_UPDATE),
			archive: authContext.hasPermission(Permission.GENERATOR_UPDATE),
			delete: authContext.hasPermission(Permission.GENERATOR_DELETE),
			addEluate: authContext.hasPermission(Permission.GENERATOR_ELUAT_CREATE),
			editEluates:authContext.hasPermission(Permission.GENERATOR_ELUAT_UPDATE),
			supplementEluates:authContext.hasPermission(Permission.GENERATOR_ELUAT_SUPPLEMENT),
			deleteEluates:authContext.hasPermission(Permission.GENERATOR_ELUAT_DELETE),
		}
	};

	const handleModalClick = (
		type: GeneratorViewModalType,
		payload?: GeneratorViewModel | EluateViewModel | DocumentViewModel | Array<EluateViewModel>
	) => {
		dispatch(resetGeneratorActionStatus());
		dispatch(resetEluateActionStatus());
		dispatch(resetDocumentsActionStatus());

		setModalType(type);
		setModalPayload(payload);
	};

	const handleDocumentCollectionFiltering = (id: string, state: boolean) => {
		setDocumentFilter({
			...documentFilter,
			[id]: state
		});
	};

	// Show modal
	const showModal = (): JSX.Element => {
		let modal: JSX.Element = null;

		switch (modalType) {
			case GeneratorViewModalType.EDIT:
				modal =
					<GeneratorUpdateModal
						generator={generator}
						onDismiss={handleDismiss}
					/>;
				break;

			case GeneratorViewModalType.DISPATCH:
				modal =
					<GeneratorDispatchModal
						generator={generator}
						onDismiss={handleDismiss}
					/>;
				break;

			case GeneratorViewModalType.DELETE:
				modal =
					<GeneratorDeleteModal
						generator={generator}
						onDismiss={handleDismiss}
					/>;
				break;

			case GeneratorViewModalType.CREATE_ELUATE:
				modal =
					<EluateCreateModal
						generator={generator}
						onDismiss={handleDismiss}
					/>;
				break;

			case GeneratorViewModalType.UPDATE_ELUATE:
				modal =
					<EluateUpdateModal
						eluate={modalPayload as EluateViewModel}
						onDismiss={handleDismiss}
					/>;
				break;

			case GeneratorViewModalType.TO_WASTE_ELUATE:
				modal =
					<EluateToWasteModal
						eluate={modalPayload as EluateViewModel}
						onDismiss={handleDismiss}
					/>;
				break;

			case GeneratorViewModalType.DELETE_ELUATE:
				modal =
					<EluateDeleteModal
						eluate={modalPayload as EluateViewModel}
						onDismiss={handleDismiss}
					/>;
				break;

			case GeneratorViewModalType.CREATE_DOCUMENT:
				modal =
					<DocumentAddModal
						onDismiss={handleDismiss}
						documentScope={DocumentModelScope.GENERATOR}
						documentScopeReference={generator.Uuid}
					/>;
				break;

			case GeneratorViewModalType.EDIT_DOCUMENT_NAME:
				modal =
					<DocumentEditModal
						onDismiss={handleDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;

			case GeneratorViewModalType.DOWNLOAD_DOCUMENT:
				modal =
					<DocumentDownloadModal
						onDismiss={handleDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;

			case GeneratorViewModalType.DELETE_DOCUMENT:
				modal =
					<DocumentDeleteModal
						onDismiss={handleDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;

			case GeneratorViewModalType.ARCHIVE_DOCUMENT:
				modal =
					<DocumentArchiveModal
						onDismiss={handleDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;

			case GeneratorViewModalType.UNARCHIVE_DOCUMENT:
				modal =
					<DocumentUnarchiveModal
						onDismiss={handleDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
		}
		return modal;
	};

	const generatorCard =
		<GeneratorCard
			generator={generator}
			userPermissionEdit={user.permission.update}
			onClick={() => handleModalClick(GeneratorViewModalType.EDIT, generator)}
		/>;

	const dispatchButton = generator.State === GeneratorModelState.ACTIVE ?
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.dispatch', 'Abgang erfassen')}
			icon={IconIdentifier.DISPATCH}
			onClick={() => {handleModalClick(GeneratorViewModalType.DISPATCH);}}
			disabled={!user.permission.update}
		/> : null;

	const deleteButton =
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.delete', 'Löschen')}
			icon={IconIdentifier.TRASH}
			status={ButtonSecondaryStatus.ATTENTION}
			disabled={!user.permission.delete || generatorEluates.length !== 0}
			onClick={() => handleModalClick(GeneratorViewModalType.DELETE)}
		/>;

	const renderNotifications = (): Array<JSX.Element> => {
		const notifications = [];

		// Handle failed action
		if (generatorsActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					message={generatorLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (eluatesActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					message={eluatesLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (documentStoreFailed) {
			notifications.push(
				<NotificationBar
					message={documentStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		return notifications;
	};

	const renderDocuments = () => {
		return (
			<DocumentsCollection
				documents={documents}
				fetchStatus={documentStorePending}
				onAddDocument={() => handleModalClick(GeneratorViewModalType.CREATE_DOCUMENT)}
				addDocumentEnabled={generator.State !== GeneratorModelState.DISPATCHED &&
					authContext.hasPermission(Permission.DOCUMENT_CREATE)}
				onAction={(payload, document) => {
					switch (payload.type) {
						case CardDocumentAction.ARCHIVE:
							return handleModalClick(GeneratorViewModalType.ARCHIVE_DOCUMENT, document);

						case CardDocumentAction.UNARCHIVE:
							return handleModalClick(GeneratorViewModalType.UNARCHIVE_DOCUMENT, document);

						case CardDocumentAction.DELETE:
							return handleModalClick(GeneratorViewModalType.DELETE_DOCUMENT, document);

						case CardDocumentAction.DOWNLOAD:
							return handleModalClick(GeneratorViewModalType.DOWNLOAD_DOCUMENT, document);

						case CardDocumentAction.CHANGE:
							return handleModalClick(GeneratorViewModalType.EDIT_DOCUMENT_NAME, document);
						default:
							return null;
					}
				}}
				onFilterClick={handleDocumentCollectionFiltering}
			/>
		);
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs
						mapURLFragments={[[generatorUuid, generator.Name]]}
					/>
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection
				section={MainLayoutSectionSection.SECTION_MAIN}
			>
				<ViewLayout>

					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{showModal()}
						{generatorCard}
					</ViewLayoutSection>

					<ViewLayoutSection>
						<EluateCollection
							generator={generator}
							onAddEluate={() => handleModalClick(GeneratorViewModalType.CREATE_ELUATE)}
							onEditEluate={(eluate) => handleModalClick(GeneratorViewModalType.UPDATE_ELUATE, eluate)}
							onDeleteEluate={(eluate) => handleModalClick(GeneratorViewModalType.DELETE_ELUATE, eluate)}
							onToWastemanagement={(eluate) => handleModalClick(GeneratorViewModalType.TO_WASTE_ELUATE, eluate)}
							onToWasteEluate={(eluates) => handleModalClick(GeneratorViewModalType.TO_WASTE_ELUATE, eluates)}
						/>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{renderDocuments()}
						<Separator />
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ColumnLayout>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{dispatchButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{deleteButton}
							</ColumnLayoutSection>
						</ColumnLayout>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>

		</MainLayout>
	);
};
