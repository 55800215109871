import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';
import { selectGenerators } from '../../../../../nuclide/store/generatorSlice';

import { WasteManagementReportViewModel } from '../../../../domain/models/WasteManagementReportModel';
import { updateWasteManagementReport } from '../../../../store/wasteManagementReportSlice';
import { WasteManagementGeneratorReportUpdateFormStep1 } from './WasteManagementGeneratorReportUpdateFormStep1';
import { WasteManagementGeneratorReportUpdateFormStep2 } from './WasteManagementGeneratorReportUpdateFormStep2';

export interface WasteManagementGeneratorReportUpdateFormProps {
	report?: WasteManagementReportViewModel;
}

export const WasteManagementGeneratorReportUpdateForm = (props: WasteManagementGeneratorReportUpdateFormProps): JSX.Element => {
	const { report } = props;

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const [showStep2, setShowStep2] = useState<boolean>(false);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const dispatch = useDispatch();

	const updatedReport: WasteManagementReportViewModel = report;

	const requiredInputs = ['Name', 'PeriodDateStart', 'PeriodDateEnd', 'ValidUntil'];

	const generators = useTypedSelector(selectGenerators(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const onChangeReportProp = (prop: string, value: any) => {
		updatedReport[prop] = value;
	};

	const reportPeriodDateStartFormatted = new Date(updatedReport.PeriodDateStart);
	const reportPeriodDateEndFormatted = new Date(updatedReport.PeriodDateEnd);

	const handleUpdate = (): void => {

		const filteredGeneratorsUuids: string[] = [];
		if (generators.length > 0) {
			generators.filter((generator) => {
				const generatorDeliveryDateFormatted = new Date(generator?.DeliveryDate);
				if (reportPeriodDateStartFormatted < generatorDeliveryDateFormatted &&
					reportPeriodDateEndFormatted > generatorDeliveryDateFormatted) {
					filteredGeneratorsUuids.push(generator.Uuid);
				}
				return filteredGeneratorsUuids;
			});
		}

		dispatch(updateWasteManagementReport({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			viewModel: {
				...updatedReport,
				WasteManagementReportGenerators: filteredGeneratorsUuids

			}
		}));
	};

	const gotoStep1 = () => {
		setShowStep2(false);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newReportModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedReport?.[requiredInput] ?? null) === null
				|| updatedReport?.[requiredInput] === ''
				|| String(updatedReport.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newReportModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep2(newReportModelValid);
	};

	const formStep = !showStep2 ?
		<WasteManagementGeneratorReportUpdateFormStep1
			report={updatedReport}
			onClickNext={goToStep2}
			onChangeReportProp={onChangeReportProp}
			invalidInputs={invalidInputs}
		/> :
		<WasteManagementGeneratorReportUpdateFormStep2
			report={updatedReport}
			onClickBack={gotoStep1}
			onClickNext={handleUpdate}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
