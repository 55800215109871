import { MouseEvent, useContext, useEffect, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { FileDownloadTokenModalFailed } from 'services/documents/presentation/ui/document-action/document-download-modal/FileDownloadTokenModalFailed';

import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogueSectionName } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSectionName';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { AuthContext } from '../../../../../core/context/AuthContext';
import { FileDownloadToken } from '../../../../../core/lib/auth/FileDownloadToken';

export interface DocumentDownloadModalContentProps {
	document: DocumentViewModel;
	onClickPrevious?: () => void;
	onDismiss: () => void;
}

export const DocumentDownloadModalContent = (props: DocumentDownloadModalContentProps): JSX.Element => {
	const { document, onClickPrevious, onDismiss } = props;

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	const [token, setToken] = useState<string>(null);
	const [error, setError] = useState<Error>(null);

	useEffect(() => {
		const requestToken = async (): Promise<void> => {
			try {
				const fileDownloadToken = new FileDownloadToken();
				setToken(await fileDownloadToken.requestFileDownloadToken(authContext.jsonWebToken()));
			} catch (e) {
				setError(e);
			}
		};
		if (token === null) {
			void requestToken();
		}
	}, [authContext, token]);

	const handleDownload = (event: MouseEvent): void => {
		event.stopPropagation();
		event.preventDefault();
		window.open(document.Url + '?token=' + token);
		onDismiss();
	};

	const renderModalContent = () => {
		if (error !== null) {
			return <FileDownloadTokenModalFailed onDismiss={onDismiss} />;
		}
		if (token !== null) {
			return (
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<CardCollectionLayout>
							<p>{l10nContext.translate('view.documents.modal.action.downloadDocument.message')}</p>
							<ButtonPrimary
								buttonText={l10nContext.translate('common.modal.captionDownload')}
								icon={IconIdentifier.DOWNLOAD}
								onClick={(event) => {
									handleDownload(event);
								}}
							/>
						</CardCollectionLayout>
					</ColumnLayoutSection>
				</ColumnLayout>

			);
		}
		return (
			<LoadingSpinner />
		);
	};

	const renderButtonPrevious = onClickPrevious ?
		<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
			<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
				<ButtonSecondary
					buttonText={l10nContext.translate('common.button.back', 'Zurück')}
					onClick={onClickPrevious}
				/>
			</ColumnLayoutSection>
		</ColumnLayout> : null;

	return (
		<>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
			<ModalDialogueSection section={ModalDialogueSectionName.SECTION_FOOTER}>
				{renderButtonPrevious}
			</ModalDialogueSection>
		</>
	);
};
