import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchExternalReportDevices } from 'services/device/store/externalReportDevicesSlice';
import { fetchExternalReportSequences } from 'services/device/store/externalReportSequenceSlice';
import { fetchExternalReportDocuments } from 'services/documents/store/externalReportDocumentSlice';
import { fetchExternalReportMaintenanceLogEntries } from 'services/maintenance-log/store/externalReportMaintenanceLogEntrySlice';

import { fetchExternalReport } from 'services/report/store/externalReportSlice';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { Error } from '../../components/error/Error';
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner';

interface ReportViewParams {
	reportUuid: string;
}

export const StoreWarmupExternalReport = (props: any): JSX.Element => {

	const params = useParams<ReportViewParams>();
	const reportUuid = params?.reportUuid ?? null;
	if (reportUuid === null) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		throw new Error('Report id is missing');
	}

	const externalReportStoreFetchStatus = useTypedSelector(state => state.externalReport.fetchStatus);
	const externalReportStoreLastFetchError = useTypedSelector(state => state.externalReport.lastFetchError);
	const externalReportStorePending =
		externalReportStoreFetchStatus === AsyncFetchStatus.INITIAL ||
		externalReportStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const externalReportStoreFailed = externalReportStoreFetchStatus === AsyncFetchStatus.FAILED;

	const externalReportDevicesStoreFetchStatus = useTypedSelector(state => state.externalReportDevices.fetchStatus);
	const externalReportDevicesStoreLastFetchError = useTypedSelector(state => state.externalReportDevices.lastFetchError);
	const externalReportDevicesStorePending =
		externalReportDevicesStoreFetchStatus === AsyncFetchStatus.INITIAL ||
		externalReportDevicesStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const externalReportDevicesStoreFailed = externalReportDevicesStoreFetchStatus === AsyncFetchStatus.FAILED;

	const externalReportSequencesStoreFetchStatus = useTypedSelector(state => state.externalReportSequences.fetchStatus);
	const externalReportSequencesStoreLastFetchError = useTypedSelector(state => state.externalReportSequences.lastFetchError);
	const externalReportSequencesStorePending =
		externalReportSequencesStoreFetchStatus === AsyncFetchStatus.INITIAL ||
		externalReportSequencesStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const externalReportSequencesStoreFailed = externalReportSequencesStoreFetchStatus === AsyncFetchStatus.FAILED;

	const externalReportDocumentsStoreFetchStatus = useTypedSelector(state => state.externalReportDocuments.fetchStatus);
	const externalReportDocumentsStoreLastFetchError = useTypedSelector(state => state.externalReportDocuments.lastFetchError);
	const externalReportDocumentsStorePending =
		externalReportDocumentsStoreFetchStatus === AsyncFetchStatus.INITIAL ||
		externalReportDocumentsStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const externalReportDocumentsStoreFailed = externalReportDocumentsStoreFetchStatus === AsyncFetchStatus.FAILED;

	const externalReportMaintenanceLogEntriesStoreFetchStatus = useTypedSelector(state => state.externalReportMaintenanceLogEntries.fetchStatus);
	const externalReportMaintenanceLogEntriesStoreLastFetchError =
		useTypedSelector(state => state.externalReportMaintenanceLogEntries.lastFetchError);
	const externalReportMaintenanceLogEntriesStorePending =
		externalReportMaintenanceLogEntriesStoreFetchStatus === AsyncFetchStatus.INITIAL ||
		externalReportMaintenanceLogEntriesStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const externalReportMaintenanceLogEntriesStoreFailed = externalReportMaintenanceLogEntriesStoreFetchStatus === AsyncFetchStatus.FAILED;

	const pending = externalReportStorePending ||
		externalReportDevicesStorePending ||
		externalReportSequencesStorePending ||
		externalReportDocumentsStorePending ||
		externalReportMaintenanceLogEntriesStorePending;

	const failed = externalReportStoreFailed ||
		externalReportDevicesStoreFailed ||
		externalReportSequencesStoreFailed ||
		externalReportDocumentsStoreFailed ||
		externalReportMaintenanceLogEntriesStoreFailed;

	const startedFetchExternalReportStore = useRef<boolean>(false);
	const startedFetchExternalReportDevicesStore = useRef<boolean>(false);
	const startedFetchExternalReportSequencesStore = useRef<boolean>(false);
	const startedFetchExternalReportDocumentsStore = useRef<boolean>(false);
	const startedFetchExternalReportMaintenanceLogEntriesStore = useRef<boolean>(false);

	const dispatch = useDispatch();

	if (!startedFetchExternalReportStore.current &&
		(externalReportStoreFetchStatus === AsyncFetchStatus.INITIAL || externalReportStoreFetchStatus === AsyncFetchStatus.IDLE) &&
		!failed) {
		startedFetchExternalReportStore.current = true;
		dispatch(fetchExternalReport({ reportUuid }));
	}

	if (!startedFetchExternalReportDevicesStore.current && externalReportDevicesStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchExternalReportDevicesStore.current = true;
		dispatch(fetchExternalReportDevices({ reportUuid }));
	}

	if (!startedFetchExternalReportSequencesStore.current && externalReportSequencesStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchExternalReportSequencesStore.current = true;
		dispatch(fetchExternalReportSequences({ reportUuid }));
	}

	if (!startedFetchExternalReportDocumentsStore.current && externalReportDocumentsStoreFetchStatus === AsyncFetchStatus.INITIAL && !failed) {
		startedFetchExternalReportDocumentsStore.current = true;
		dispatch(fetchExternalReportDocuments({ reportUuid }));
	}

	if (!startedFetchExternalReportMaintenanceLogEntriesStore.current
		&& externalReportMaintenanceLogEntriesStoreFetchStatus === AsyncFetchStatus.INITIAL
		&& !failed) {
		startedFetchExternalReportMaintenanceLogEntriesStore.current = true;
		dispatch(fetchExternalReportMaintenanceLogEntries({ reportUuid }));
	}

	if (pending) {
		return (
			<LoadingSpinner />
		);
	}

	if (failed) {

		const errors = [
			externalReportStoreLastFetchError,
			externalReportDevicesStoreLastFetchError,
			externalReportSequencesStoreLastFetchError,
			externalReportDocumentsStoreLastFetchError,
			externalReportMaintenanceLogEntriesStoreLastFetchError
		].filter((error: Error | null): boolean => {
			return error !== null;
		});

		const messages = errors.map((error: Error): string => {
			return error.message;
		});

		return (
			<div>
				<Error error={messages} />
			</div>
		);
	}

	return (
		<>
			{props.children}
		</>
	);

};
