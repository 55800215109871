import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { CreatableReportViewModel } from 'services/report/domain/model/ReportModel';
import { createReport } from 'services/report/store/reportSlice';
import { ReportCreateFormStep1 } from './ReportCreateFormStep1';
import { ReportCreateFormStep2 } from './ReportCreateFormStep2';

/**
 * The report create form
 */
export const ReportCreateForm = (): JSX.Element => {

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const newReportViewModel = useRef<CreatableReportViewModel>({
		PeriodDateStart: new Date(),
		PeriodDateEnd: new Date(),
		ValidUntil: new Date(),
	} as CreatableReportViewModel);

	const onChangeReportProp = (prop: string, value: any) => {
		newReportViewModel.current[prop] = value;
	};

	const handleCreate = (): void => {
		dispatch(createReport({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			viewModel: {
				Name: null,
				Password: null,
				PeriodDateStart: null,
				PeriodDateEnd: null,
				ValidUntil: null,
				ReportSequences: [],
				ReportDocuments: [],
				...newReportViewModel.current
			}
		}));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const requiredInputs = ['Name', 'PeriodDateStart', 'PeriodDateEnd', 'ValidUntil', 'Password'];

	const goToStep2 = () => {
		let newReportModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(newReportViewModel.current?.[requiredInput] ?? null) === null
				|| newReportViewModel.current?.[requiredInput] === ''
				|| String(newReportViewModel.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newReportModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newReportModelValid);
	};

	const showCreateModalStep = showStep1 === true ?
		<ReportCreateFormStep1
			report={newReportViewModel.current}
			onClickNext={goToStep2}
			onChangeReportProp={onChangeReportProp}
			invalidInputs={invalidInputs}
		/> :
		<ReportCreateFormStep2
			report={newReportViewModel.current as CreatableReportViewModel}
			onClickPrevious={goToStep1}
			onClickConfirm={handleCreate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
