import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';
import { BooleanOutput } from 'services/device/domain/business/common/Output';

export interface BooleanOutputValueProps {
	output: BooleanOutput;
}

export const BooleanOutputValueRenderer = (props: BooleanOutputValueProps): JSX.Element => {
	const l10nContext = useContext(L10nContext);

	const { output } = props;

	const value = output.get()[0]
		? l10nContext.translate('common.inputs.boolean.true')
		: l10nContext.translate('common.inputs.boolean.false');

	return (
		<span>
			{value}
		</span>
	);

};
