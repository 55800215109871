import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { FolderViewModel } from 'services/documents/domain/model/FolderModel';
import { selectFolderTree } from 'services/documents/store/folderSlice';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';

export interface DocumentMoveFormStep1Props {
	onChange: (folderUuid: string) => void;
	onClickNext: () => void;
}

export const DocumentMoveFormStep1 = (props: DocumentMoveFormStep1Props): JSX.Element => {
	const { onChange, onClickNext } = props;

	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const folders = useSelector(selectFolderTree(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const folderNameMap: Map<string, string> = new Map();
	const selectOptions: Array<SelectOption> = folders.map((childFolder: FolderViewModel) => {
		const prefix = folderNameMap.has(childFolder.Parent) ? folderNameMap.get(childFolder.Parent) + ' / ' : '';
		const name = prefix + childFolder.Name;
		folderNameMap.set(childFolder.Uuid, name);

		return {
			label: name,
			value: childFolder.Uuid,
		};
	});

	return (
		<FormLayout align={FormLayoutSectionAlign.ALIGN_HORIZONTAL} onSubmit={onClickNext}>
			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('view.documents.modal.action.moveDocument.folderName', 'Dokument')}*`}
				>
					<Select
						onchange={(selectedValue) => {
							onChange(selectedValue);
						}}
						options={selectOptions}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
