import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { updateDevice } from 'services/device/store/devicesSlice';

import { ClearanceUpdateFormStep1 } from 'services/device/presentation/ui/clearance-action/clearance-update-form/ClearanceUpdateFormStep1';
import { ClearanceUpdateFormStep2 } from 'services/device/presentation/ui/clearance-action/clearance-update-form/ClearanceUpdateFormStep2';

export interface ClearanceUpdateFormProps {
	device?: DeviceViewModel;
}

export const ClearanceUpdateForm = (props: ClearanceUpdateFormProps): JSX.Element => {
	const { device } = props;

	const [showStep2, setShowStep2] = useState<boolean>(false);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const dispatch = useDispatch();

	const updatedDevice: DeviceViewModel = device;
	const requiredInputs = ['Name'];

	const handleUpdate = (): void => {
		dispatch(updateDevice(updatedDevice));
	};

	const onChangeDeviceProp = (prop: string, value: string) => {
		updatedDevice[prop] = value;
	};

	const gotoStep1 = () => {
		setShowStep2(false);
		setInvalidInputs([]);
	};

	const gotoStep2 = () => {
		const invalidInputFields = requiredInputs
			.map((requiredInput) => {
				return updatedDevice[requiredInput]?.trim() !== '' ?
					null :
					requiredInput;
			}).
			filter((invalidInput: string | null) => invalidInput !== null);

		invalidInputFields.length ?
			setInvalidInputs(invalidInputFields) :
			setShowStep2(true);
	};

	const formStep = !showStep2 ?
		<ClearanceUpdateFormStep1
			device={updatedDevice}
			onClickNext={gotoStep2}
			onChange={onChangeDeviceProp}
			invalidInputs={invalidInputs}
		/> :
		<ClearanceUpdateFormStep2
			device={updatedDevice}
			onClickBack={gotoStep1}
			onClickNext={handleUpdate}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
