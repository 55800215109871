import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { DateTimeInput } from 'services/device/domain/business/common/Input';

import { DateTimePicker } from 'presentation/ui/compositions/date-time-picker/DateTimePicker';
import { Label } from 'presentation/ui/partials/input/label/Label';

export interface DateTimeInputProps {
	input: DateTimeInput;
}

export const DateTimeInputRenderer = (props: DateTimeInputProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const { input } = props;

	let label = l10nContext.translate(input.getLabelKey());
	if (input.getDescription()?.mandatory === true) {
		label += '*';
	}

	if (input.getSingle() === null && input.getDescription()?.mandatory === true) {
		input.setSingle(new Date());
	}

	return (
		<Label label={label}>
			<DateTimePicker
				name={input.getName()}
				defaultValue={input.getSingle()}
				required={!input.isOptional()}
				max={new Date()}
				onChange={(selectedDate) => {
					input.setSingle(selectedDate);
				}}

			/>
		</Label>
	);

};
