import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';

import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import {
	DocumentModelConverter as ModelConverter,
	DocumentStoreModel as StoreModel,
	DocumentViewModel as ViewModel,
} from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { RecordUuid, RecordViewModel } from '../../device/domain/model/RecordModel';
import { ExternalReportDocumentHttpPersistence } from '../../report/persistence/ExternalReportDocumentHttpPersistence';

// Declare a device state type
export interface ExternalReportDocumentState {
	externalReportDocuments: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	externalReportDocuments: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ExternalReportDocumentState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
export const fetchExternalReportDocuments = createAsyncThunk(
	'external-report-documents/fetch',
	async (params: { reportUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new ExternalReportDocumentHttpPersistence(params.reportUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Sliently abort the action
			const { externalReportDocuments } = getState() as RootState;
			return checkFetchStatus(externalReportDocuments.fetchStatus);
		}
	}
);

// Slice definition
export const externalReportDocumentSlice = createSlice({
	name: 'external-report-documents',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchExternalReportDocuments.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalReportDocuments.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.externalReportDocuments = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalReportDocuments.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

export const { resetState, resetActionStatus } = externalReportDocumentSlice.actions;

// Export the reducer as default
export default externalReportDocumentSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectExternalReportDocumentsByFacility = (): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.externalReportDocuments.externalReportDocuments.filter((storeModel): boolean => {
			return storeModel.Scope === DocumentModelScope.DOCUMENT_FOLDER;
		}) ?? [];

		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalReportDocumentsByDevice = (deviceUuid: string): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.externalReportDocuments.externalReportDocuments.filter((storeModel): boolean => {
			return storeModel.Scope === DocumentModelScope.DEVICE
				&& storeModel.ScopeReference === deviceUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalReportDocumentsByRecord = (recordUuid: string): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.externalReportDocuments.externalReportDocuments.filter((storeModel): boolean => {
			return storeModel.Scope === DocumentModelScope.RECORD
				&& storeModel.ScopeReference === recordUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export type ExternalReportDocumentsByRecordMap = Map<RecordUuid, Array<ViewModel>>;

export const selectExternalReportDocumentsByRecordsMap = (records: ReadonlyArray<RecordViewModel>): (rootState: RootState) =>
ExternalReportDocumentsByRecordMap => {
	return (rootState: RootState): ExternalReportDocumentsByRecordMap => {
		const recordUuids = records.map((value): RecordUuid => {
			return value.Uuid;
		});
		const storeModels = rootState.externalReportDocuments.externalReportDocuments.filter((storeModel): boolean => {
			return storeModel.Scope === DocumentModelScope.RECORD
				&& recordUuids.includes(storeModel.ScopeReference);
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		}).filter((viewModel) => {
			return viewModel !== null;
		});

		const documentsByRecordMap: ExternalReportDocumentsByRecordMap = new Map();
		for (const viewModel of viewModels) {
			if (!documentsByRecordMap.has(viewModel.ScopeReference)) {
				documentsByRecordMap.set(viewModel.ScopeReference, []);
			}
			documentsByRecordMap.get(viewModel.ScopeReference).push(viewModel);
		}
		return documentsByRecordMap;
	};
};
