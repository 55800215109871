import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { RootView } from 'presentation/view/RootView';

import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './store/store';

import './presentation/sass/global-styles/defaults.scss';
import './presentation/sass/global-styles/font-definitions.scss';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<RootView />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
	onUpdate: (registration): void => {
		// eslint-disable-next-line no-alert
		window.alert('Eine neue Version der Anwendung wird installiert.');
		window.location.reload();
	}
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
