import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { deleteGenerator } from 'services/nuclide/store/generatorSlice';
import { useContext } from 'react';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { GeneratorDeleteForm } from '../generator-delete-form/GeneratorDeleteForm';
import { GeneratorDeleteFailedNotification } from './GeneratorDeleteFailedNotification';

export interface GeneratorDeleteModalProps {
	generator: GeneratorViewModel
	onDismiss?: () => void;
}

export const GeneratorDeleteModal = (props: GeneratorDeleteModalProps): JSX.Element => {
	const { generator, onDismiss } = props;

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const generatorActionStatus = useTypedSelector(state => state.generators.actionStatus);

	const dispatch = useDispatch();

	const handleDelete = (): void => {
		generator.Client = clientContext.selectedClientUuid;
		generator.Facility = facilityContext.selectedFacilityUuid;
		dispatch(deleteGenerator(generator));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (generatorActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<GeneratorDeleteForm
						generator={generator}
						onDelete={handleDelete}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <GeneratorDeleteFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
