import { Badge, BadgeColor } from 'presentation/ui/partials/badge/Badge';
import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './button-icon.scss';

export enum ButtonIconShape {
	ROUND = 'button-icon--round',
	RECTANGLE = 'button-icon--rectangle'
}

export enum ButtonIconWeight {
	PRIMARY = 'button-icon--primary',
	SECONDARY = 'button-icon--secondary',
	GHOST = 'button-icon--ghost'
}

export interface ButtonIconProps extends React.ComponentPropsWithoutRef<'button'> {
	/**
	 * icon
	 */
	icon: IconIdentifier;
	/**
	 * Weight of button with icon
	 */
	weight: ButtonIconWeight;
	/**
	 * Shape of button with icon
	 */
	shape: ButtonIconShape;

	badge?: number;

}

export const ButtonIcon = (props: ButtonIconProps): JSX.Element => {
	const { shape, weight, icon, badge = null, ...restProps } = props;

	const renderBadge = (): JSX.Element | null => {
		if (badge === null) {
			return null;
		}
		const badgeLabel = badge < 10 ? String(badge) : '9+';
		return (
			<Badge
				label={badgeLabel}
				color={BadgeColor.LIGHT_BLUE}
			/>
		);
	};

	return (
		<div className="button-icon">
			<button
				className={`button-icon__button ${shape} ${icon} ${weight}`}
				type="button"
				{...restProps}
			>
				<Icon name={icon} />
			</button>
			{renderBadge()}
		</div>
	);
};
