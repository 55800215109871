import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';
import { DocumentModelUuid } from 'services/documents/domain/model/DocumentModel';
import { ReportModelUuid } from 'services/report/domain/model/ReportModel';

export type ReportDocumentModelUuid = string;

export interface ReportDocumentPersistenceModel extends PersistenceModel {
	Report: string;
	Document: string;
}

export interface ReportDocumentStoreModel extends StoreModel {
	Report: string;
	Document: string;
}

export interface ReportDocumentViewModel extends ViewModel {
	Report: ReportModelUuid;
	Document: DocumentModelUuid;
}

export interface CreatableReportDocumentViewModel extends CreatableViewModel {
	Document: DocumentModelUuid;
}

export class ReportDocumentModelConverter
	extends ModelConverter<ReportDocumentPersistenceModel, ReportDocumentStoreModel, ReportDocumentViewModel, CreatableReportDocumentViewModel> {

	public fromViewModel(viewModel?: ReportDocumentViewModel): this {
		super.fromViewModel(viewModel, (vModel, model) => {
			model.Report = vModel.Report as string;
			model.Document = vModel.Document as string;
		});

		return this;
	}

	public toViewModel(): ReportDocumentViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.Report = model.Report as ReportModelUuid;
			vModel.Document = model.Document as DocumentModelUuid;
		});
	}

}
