import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';

import {
	WasteManagementReportModelConverter as ModelConverter,
	WasteManagementReportStoreModel as StoreModel,
	WasteManagementReportViewModel as ViewModel,
} from 'services/waste-management-report/domain/models/WasteManagementReportModel';
import {
	ExternalWasteManagementReportHttpPersistence
} from 'services/waste-management-report/persistence/ExternalWasteManagementReportHttpPersistence';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';

// Declare a waste management report state type
export interface ExternalWasteManagementReportState {
	externalWasteManagementReport: StoreModel;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	externalWasteManagementReport: null,
	fetchStatus: AsyncFetchStatus.IDLE,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ExternalWasteManagementReportState;

export const fetchExternalWasteManagementReport = createAsyncThunk(
	'external-waste-management-report/fetch',
	async (params: { reportUuid: string }): Promise<StoreModel> => {

		const persistence = new ExternalWasteManagementReportHttpPersistence(params.reportUuid);
		return persistence.fetch(params.reportUuid);
	},
	{
		condition: (_params, { getState }): boolean => {
			// Silently abort the action
			const { externalWasteManagementReport } = getState() as RootState;
			return checkFetchStatus(externalWasteManagementReport.fetchStatus);
		}
	}
);

export const externalWasteManagementReportSlice = createSlice({
	name: 'externalWasteManagementReport',
	initialState,
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	extraReducers: {
		[String(fetchExternalWasteManagementReport.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalWasteManagementReport.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.externalWasteManagementReport = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalWasteManagementReport.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

export const { resetState, resetActionStatus } = externalWasteManagementReportSlice.actions;

export default externalWasteManagementReportSlice.reducer;

export const selectExternalWasteManagementReport = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.externalWasteManagementReport?.externalWasteManagementReport?.Uuid === uuid ?
			rootState.externalWasteManagementReport.externalWasteManagementReport : null;

		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

