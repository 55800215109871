import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';
import { User } from 'services/device/presentation/view/UserModel';

import { CardDeviceDetail } from 'presentation/ui/components/cards/card-device/card-device-detail/CardDeviceDetail';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { DeviceViewModalType } from 'services/device/presentation/view/DeviceViewModalType';
import { CardItemClearanceDetailAttributes } from 'presentation/ui/partials/card/card-item-attributes/card-item-clearance-detail-attributes/CardItemClearanceDetailAttributes';

interface ClearanceCardProps {
	device: DeviceViewModel;
	user: User;
	onClick: (modalType: DeviceViewModalType) => void;
}

export const ClearanceCard = (props: ClearanceCardProps): JSX.Element => {
	const { device, user, onClick } = props;

	return (
		<CardDeviceDetail>
			<CardItemHeader
				title={device.Name}
				archived={device.State === DeviceModelState.ARCHIVED}
			/>
			<CardItemSingleControl
				cardId={device.Uuid}
				actionEdit={user.permission.edit ?
					CardItemControlState.ENABLED :
					CardItemControlState.DISABLED}
				onClick={() => onClick(DeviceViewModalType.EDIT_CLEARANCE)}
			/>
			<CardItemClearanceDetailAttributes
				note={device.Identifier}
			/>
		</CardDeviceDetail>
	);
};
