import { L10nContext } from 'context/L10nContext';
import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import React, { useContext, useState } from 'react';
import { ColumnLayoutSection } from '../../../../../../presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import {
	ColumnLayoutSectionMode
} from '../../../../../../presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from '../../../../../../presentation/ui/layouts/column-layout/ColumnLayout';
import { ViewLayoutSection } from '../../../../../../presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ButtonPrimary } from '../../../../../../presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { ContainerViewModalType } from '../../../view/ContainerViewModalType';
import { ContainerFactorMeasurementModal } from '../container-factor-measurement-modal/ContainerFactorMeasurementModal';
import { ContainerStationMeasurementModal } from '../container-station-measurement-modal/ContainerStationMeasurementModal';

interface ContainerSelectMeasurementProps {
	container: ContainerViewModel;
	onDismiss: () => void;
}

export const ContainerSelectMeasurementModal = (props: ContainerSelectMeasurementProps): JSX.Element => {
	const { container, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const [modalType, setModalType] = useState<ContainerViewModalType >(null);

	const handleModalClick = (
		type: ContainerViewModalType
	) => {
		setModalType(type);
	};


	const showModal = () => {
		let modal: JSX.Element = null;

		if (modalType === ContainerViewModalType.FACTOR_MEASUREMENT) {
			modal =
				<ContainerFactorMeasurementModal
					container={container}
					onDismiss={onDismiss}
				/>;
		} else if (modalType === ContainerViewModalType.STATION_MEASUREMENT) {
			modal =
				<ContainerStationMeasurementModal
					container={container}
					onDismiss={onDismiss}
				/>;
		}
		return modal;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			{showModal()}
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.containers.modal.action.measure.caption', 'Gebinde berechnen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				<ViewLayoutSection>
					<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
						<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
							<ButtonPrimary
								buttonText={l10nContext.translate('common.button.factorMeasurement', 'Standortspezifischer Faktor')}
								onClick={() => handleModalClick(ContainerViewModalType.FACTOR_MEASUREMENT)}
							/>
						</ColumnLayoutSection>
						<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
							<ButtonPrimary
								buttonText={l10nContext.translate('common.button.stationMeasurement', 'Messplatz')}
								onClick={() => handleModalClick(ContainerViewModalType.STATION_MEASUREMENT)}
							/>
						</ColumnLayoutSection>
					</ColumnLayout>
				</ViewLayoutSection>
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
