export class DebugConsole {

	public static error(...messages: Array<any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			console.error(...messages);
		}
	}

	public static warn(...messages: Array<any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			console.warn(...messages);
		}
	}

	public static log(...messages: Array<any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line no-console
			console.log(...messages);
		}
	}

	public static debug(...messages: Array<any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line no-console
			console.debug(...messages);
		}
	}

}
