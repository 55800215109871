import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { OutputListRenderer } from 'services/device/presentation/ui/widget/widget-renderer/OutputListRenderer';

import './card-item-sequence-attributes.scss';

export interface CardItemSequenceAttributesProps {
	sequence: SequenceViewModel;
}

/**
 * A card item sequence attributes component
 */
export const CardItemSequenceAttributes = (props: CardItemSequenceAttributesProps): JSX.Element => {
	const { sequence } = props;

	return (
		<div className="card-item-sequence-attributes">
			<OutputListRenderer sequenceViewModel={sequence} />
		</div>
	);
};
