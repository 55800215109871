import { useEffect, useState } from 'react';

export enum MediaQuery {
	XL = '(min-width: 1200px)'
}

export const useMediaQuery = (query: MediaQuery): boolean => {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const media = window.matchMedia(query);

		if (media.matches !== matches) {
			setMatches(media.matches);
		}

		const listener = () => {
			setMatches(media.matches);
		};

		media.addEventListener('change', listener);

		return () => media.removeEventListener('change', listener);
	}, [matches, query]);

	return matches;
};
