import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';

import {
	WasteManagementReportModelConverter as ModelConverter,
	WasteManagementReportPersistenceModel as PersistenceModel,
	WasteManagementReportStoreModel as StoreModel,
	WasteManagementReportViewModel as ViewModel,
	CreatableWasteManagementReportViewModel as CreatableViewModel
} from 'services/waste-management-report/domain/models/WasteManagementReportModel';
import { ExternalWasteReportHttpPersistence } from 'lib/persistence/http/ExternalWasteReportHttpPersistence';

export class ExternalWasteManagementReportHttpPersistence
	extends ExternalWasteReportHttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'waste-management-report/report-api/v1';
	protected collectionControllerUri = 'waste-management-reports';
	protected entityControllerUri = 'waste-management-report';

	protected responseCollectionKey = 'WasteManagementReportModelCollection';
	protected responseEntityKey = 'WasteManagementReportModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
