import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { Guid } from 'lib/guid/Guid';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Interaction } from 'services/device/domain/business/common/interaction/Interaction';
import { Sequence } from 'services/device/domain/business/common/sequence/Sequence';
import { Violation } from 'services/device/domain/business/common/violation/Violation';
import { getDeviceTypeDefinition } from 'services/device/domain/business/inventory/DeviceTypeDefinition';
import { ProductGroup } from 'services/device/domain/business/inventory/ProductGroup';
import { SequenceType } from 'services/device/domain/business/inventory/SequenceType';
import { InventoryFactory } from 'services/device/domain/business/util/InventoryFactory';
import { getCreateSequenceInteraction } from 'services/device/domain/business/util/SequenceUtil';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { WidgetsRenderer } from 'services/device/presentation/ui/widget/WidgetsRenderer';
import { createSequence } from 'services/device/store/sequenceSlice';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';

export interface MeasurementGoalCreateFormProps {
	device: DeviceViewModel;
	onError: (error: Error) => void;
}

/**
 * The sequence create form
 */
export const MeasurementGoalCreateForm = (props: MeasurementGoalCreateFormProps): JSX.Element => {

	const { device, onError } = props;

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Create state for the sequence interactions
	const [sequenceType, setSequenceType] = useState<SequenceType>(null);
	const [sequenceInteraction, setSequenceInteraction] = useState<Interaction<Sequence>>(null);
	const [validationViolations, setValidationViolations] = useState<ReadonlyArray<Violation>>([]);

	// Create an interaction if device is available and no interaction was already created
	useEffect(() => {
		const prepareInteraction = async (): Promise<void> => {
			// Select a random device
			setSequenceInteraction(getCreateSequenceInteraction(device, sequenceType));
		};
		if (sequenceType !== null && sequenceInteraction === null) {
			void prepareInteraction();
		}
	}, [clientContext, device, sequenceType, sequenceInteraction]);

	const handleTypeSelect = (selectedSequenceType: string): void => {
		setSequenceType(selectedSequenceType as SequenceType);
	};

	const handlePrev = (): void => {
		try {
			setValidationViolations([]);
			setSequenceInteraction(sequenceInteraction.previous());
		} catch (error) {
			onError(error);
		}
	};

	const handleNext = (): void => {
		try {
			const validationErrors = sequenceInteraction.validate();
			if (validationErrors.length > 0) {
				setValidationViolations(validationErrors);
			} else {
				setValidationViolations([]);
				setSequenceInteraction(sequenceInteraction.next());
			}
		} catch (error) {
			onError(error);
		}
	};

	const handleSave = (): void => {
		const validationErrors = sequenceInteraction.validate();
		if (validationErrors.length > 0) {
			setValidationViolations(validationErrors);
		} else {
			setValidationViolations([]);
			try {
				const sequence = sequenceInteraction.finish();
				const sequenceViewData = sequence.toViewData();

				dispatch(createSequence({
					Client: clientContext.selectedClientUuid,
					Facility: facilityContext.selectedFacilityUuid,
					Device: device.Uuid,
					DeviceType: device.Type,
					Type: sequenceType,
					Name: sequenceViewData.values.name,
					NotificationMailAddresses: [],
					SequenceConfiguration: sequenceViewData
				}));
			} catch (error) {
				onError(error);
			}
		}
	};

	const handleFormSubmit = (): void => {
		if (sequenceInteraction === null) {
			return;
		}

		if (sequenceInteraction.isLast()) {
			handleSave();
		} else {
			handleNext();
		}
	};

	const renderValidationViolations = (): Array<JSX.Element> => {
		return validationViolations.map<JSX.Element>((violation) => {
			return (
				<NotificationBar key={violation.getMessageKey()} message={violation.getMessageKey()} level={NotificationLevel.LEVEL_ERROR} />
			);
		});
	};

	const renderSequenceTypeInteraction = (): JSX.Element => {
		if (sequenceType !== null) {
			return null;
		}

		const inventory = InventoryFactory.createInvetory([ProductGroup.CLEARANCE]);
		const deviceTypeDefinition = getDeviceTypeDefinition(device.Type);
		const sequenceTypeDefinitions = inventory.getSequenceTypeDefinitions(deviceTypeDefinition);
		const selectOptions = sequenceTypeDefinitions.map<SelectOption>((sequenceTypeDefinition) => {
			return {
				label: l10nContext.translate(sequenceTypeDefinition.getLabelKey()),
				value: sequenceTypeDefinition.getSequenceType()
			};
		});
		return (
			<Label label={l10nContext.translate('sequence.attributes.type', 'Typ')}>
				<Select options={selectOptions} onchange={handleTypeSelect} />
			</Label>
		);
	};

	const renderSequenceCreateInteraction = (): JSX.Element => {
		if (sequenceInteraction === null) {
			return null;
		}
		return (
			<WidgetsRenderer key="widgets" widgets={sequenceInteraction.getWidgets()} />
		);
	};

	const renderSequenceCreateButtons = (): JSX.Element => {
		if (sequenceInteraction === null) {
			return null;
		}

		let leftButton = null;
		if (!sequenceInteraction.isFirst()) {
			leftButton = (
				<ButtonSecondary
					buttonText={l10nContext.translate('common.button.back', 'Zurück')}
					onClick={handlePrev}
				/>
			);
		}

		let rightButton;
		if (sequenceInteraction.isLast()) {
			rightButton = (
				<ButtonPrimary
					buttonText={l10nContext.translate('common.button.save', 'Speichern')}
					type="submit"
				/>
			);
		} else {
			rightButton = (
				<ButtonPrimary
					buttonText={l10nContext.translate('common.button.next', 'Weiter')}
					type="submit"
				/>
			);
		}

		return (
			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						{leftButton}
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						{rightButton}
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		);
	};

	return (
		<>
			<FormLayout
				align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
				key={Guid.generate()}
				onSubmit={handleFormSubmit}
			>
				<NotificationBars>
					{renderValidationViolations()}
				</NotificationBars>
				{renderSequenceTypeInteraction()}
				{renderSequenceCreateInteraction()}
				{renderSequenceCreateButtons()}
			</FormLayout>
		</>
	);
};
