import './text-element.scss';

export interface TextElementProps {
	/**
	 * text
	 */
	text: string;
}

/**
 * A text component
 */
export const TextElement = ({ text }: TextElementProps): JSX.Element => (
	<span className='text-element'>
		{text}
	</span>
);
