import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonGhost, IconPosition } from 'presentation/ui/partials/button/button-ghost/ButtonGhost';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface DeviceDeleteFailedNotificationProps {
	onDismiss: () => void;
}

export const DeviceDeleteFailedNotification = (props: DeviceDeleteFailedNotificationProps): JSX.Element => {
	const { onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	return (
		<>
			<ModalDialogueSection>
				<ModalNotification
					caption={l10nContext.translate('common.modal.errorCaption', 'Etwas ist schief gelaufen...!')}
					level={ModalNotificationLevel.LEVEL_ERROR}
				/>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonGhost
							buttonText={l10nContext.translate('common.button.close', 'Schließen')}
							icon={IconIdentifier.CLOSE}
							iconPosition={IconPosition.LEFT}
							onClick={onDismiss}
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</ModalDialogueSection>
		</>
	);
};
