import { DeviceType } from '../../inventory/DeviceType';
import { SequenceType } from '../../inventory/SequenceType';
import { Optional } from '../misc/Optional';
import { RecordStoreData, RecordViewData } from '../record/RecordData';
import { AbstractFieldSet } from './AbstractFieldSet';

export class RecordFieldSet<Fields> extends AbstractFieldSet<Fields> {
	public constructor(fieldsFactory: () => Fields, valueKey: Optional<string>) {
		super(fieldsFactory, valueKey);
	}

	public toStoreData(strict: boolean): RecordStoreData {
		return super.toStoreData(strict) as RecordStoreData;
	}

	public toViewData(deviceType: DeviceType, sequenceType: SequenceType, strict: boolean): RecordViewData {
		return super.toViewData(deviceType, sequenceType, strict) as RecordViewData;
	}

	protected createClone(fieldsFactory: () => Fields, valueKey: Optional<string>): this {
		return new RecordFieldSet(fieldsFactory, valueKey) as this;
	}
}
