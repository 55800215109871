import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetState } from '../../waste-management-report/store/externalWasteManagementReportSlice';
import { JsonWebToken } from '../lib/auth/AuthService';
import { ExternalWasteManagementReportAuthService } from '../lib/auth/ExternalWasteManagementReportAuthService';
import { AuthErrorBoundary } from '../presentation/ui/auth-error-boundary/AuthErrorBoundray';
import { ExternalWasteManagementReportLoginView } from '../presentation/view/ExternalWasteManagementReportLoginView';
import { ExternalWasteManagementReportAuthContext } from './ExternalWasteManagementReportAuthContext';

interface UrlParams {
	reportUuid: string;
}

export const ExternalWasteManagementReportAuthContextProvider = (props: any): JSX.Element => {

	// Unwrap the required report uuid from the route
	const params = useParams<UrlParams>();
	const reportUuid = params?.reportUuid ?? null;
	if (reportUuid === null) {
		throw new Error('Report id is missing');
	}

	const authService = new ExternalWasteManagementReportAuthService(reportUuid);

	const dispatch = useDispatch();

	const [authed, setAuthed] = useState<boolean>(authService.getJsonWebToken() !== null);

	const jsonWebToken = (): JsonWebToken | null => {
		return authService.getJsonWebToken();
	};

	const authenticate = async (password: string): Promise<boolean> => {
		dispatch(resetState());
		const receivedJsonWebToken = await authService.authenticate(reportUuid, password);
		setAuthed(receivedJsonWebToken !== null);

		return receivedJsonWebToken !== null;
	};

	const renderLoginInterceptionView = (): JSX.Element => {
		return (
			<ExternalWasteManagementReportLoginView />
		);
	};

	const renderChildren = (): JSX.Element => {
		return (
			<AuthErrorBoundary>
				{props.children}
			</AuthErrorBoundary>
		);
	};

	return (
		<ExternalWasteManagementReportAuthContext.Provider
			value={{
				authenticate,
				jsonWebToken
			}}
		>
			{authed ? renderChildren() : renderLoginInterceptionView()}
		</ExternalWasteManagementReportAuthContext.Provider>
	);

};
