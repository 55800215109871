import { MutableRefObject, RefObject, useEffect } from 'react';

type ActionEvent = MouseEvent | TouchEvent;

export const useOnClickOutside = (
	ref: RefObject<HTMLElement> | MutableRefObject<HTMLElement>,
	wrapElementInside: RefObject<HTMLElement> | MutableRefObject<HTMLElement> | string,
	handler: (event: ActionEvent) => void
): void => {
	useEffect(
		() => {
			const listener = (event: any) => {
				const wrapElementCondition = () => {
					if (wrapElementInside && typeof wrapElementInside === 'string') {
						return event.target.classList.contains(wrapElementInside);
					}
					return wrapElementInside && (wrapElementInside as RefObject<HTMLElement>).current.contains(event.target);
				};

				if (!ref.current || ref.current.contains(event.target) || wrapElementCondition()) {
					return;
				}

				handler(event);
			};

			document.addEventListener('mousedown', listener);
			document.addEventListener('touchstart', listener);

			return () => {
				document.removeEventListener('mousedown', listener);
				document.removeEventListener('touchstart', listener);
			};
		},
		[ref, handler, wrapElementInside]
	);
};
