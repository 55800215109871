import { L10nContext } from 'context/L10nContext';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { GeneratorModelState } from 'services/nuclide/domain/model/GeneratorModelState';
import { selectEluatesByGenerator } from 'services/nuclide/store/eluateSlice';
import { ExpandableSlimBody } from '../../../../../../presentation/ui/compositions/expandable-slim/expandable-slim-body/ExpandableSlimBody';
import {
	ExpandableSlimHeader
} from '../../../../../../presentation/ui/compositions/expandable-slim/expandable-slim-header/ExpandableSlimHeader';
import { ExpandableSlim } from '../../../../../../presentation/ui/compositions/expandable-slim/ExpandableSlim';
import { CardCollectionLayout } from '../../../../../../presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { EluateModelState } from '../../../../domain/model/EluateModelState';
import { GeneratorTable } from '../../generator-table/GeneratorTable';

export interface EluateCollectionProps {
	generator: Readonly<GeneratorViewModel>,
	onAddEluate: () => void,
	onEditEluate: (eluate: EluateViewModel) => void,
	onDeleteEluate: (eluate: EluateViewModel) => void,
	onToWastemanagement: (eluate: EluateViewModel) => void,
	onToWasteEluate: (eluates: Array<EluateViewModel>) => void
}

export const EluateCollection = (props: EluateCollectionProps): JSX.Element => {
	const { generator, onAddEluate, onEditEluate, onDeleteEluate, onToWastemanagement } = props;

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	const eluates = useSelector(selectEluatesByGenerator(generator));

	const eluateExtractionDates: string[] = [];

	// eslint-disable-next-line array-callback-return
	eluates.map((eluate) => {
		if (!eluateExtractionDates.includes(l10nContext.formatDate(eluate.ExtractionDatetime)) && eluate.State === EluateModelState.ACTIVE) {
			eluateExtractionDates.push(l10nContext.formatDate(eluate.ExtractionDatetime));
		}
	});

	const renderEluatesByDate = () => {
		if (eluates.length === 0) {
			return <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.eluates', 'Es wurden noch keine Eluate entnommen')} />;
		}
		return (
			eluateExtractionDates.sort().reverse().map((date) => {
				const eluatesByDate: Array<EluateViewModel> = eluates.filter((eluate: EluateViewModel) => {
					if (eluate.State === EluateModelState.ACTIVE) {
						return date === l10nContext.formatDate(eluate.ExtractionDatetime);
					}
					return null;
				});

				const headerCaption = eluatesByDate.length > 1 ?
					(l10nContext.translate('view.eluates.expandableHeaderTitleMultiple', 'Eluat-Entnahmen vom ' ) + date) :
					(l10nContext.translate('view.eluates.expandableHeaderTitleSingle', 'Eluat-Entnahme vom ' ) + date);

				return (
					<CardCollectionLayout key={date}>
						<ExpandableSlim mode="slim" expanded>
							<ExpandableSlimHeader
								caption={headerCaption}
								isSlimMode={true}
							/>
							<ExpandableSlimBody>
								<CardCollectionLayout>
									<GeneratorTable
										generator={generator}
										eluatesDate={date}
										onEdit={onEditEluate}
										onToWastemanagement={onToWastemanagement}
										onDelete={onDeleteEluate}
									/>
								</CardCollectionLayout>
							</ExpandableSlimBody>
						</ExpandableSlim>
					</CardCollectionLayout>
				);
			}
			)
		);
	};

	const expanded: boolean = true;

	return (
		<Expandable expanded={expanded} firstElement>
			<ExpandableHeader
				caption={l10nContext.translate('view.eluates.title', 'Eluat-Entnahmen')}
				showExpandIcon={false}
			>
				<ButtonIcon
					weight={ButtonIconWeight.PRIMARY}
					shape={ButtonIconShape.ROUND}
					icon={IconIdentifier.ADD}
					onClick={onAddEluate}
					disabled={
						(generator.State === GeneratorModelState.DISPATCHED
						|| !authContext.hasPermission(Permission.GENERATOR_ELUAT_CREATE))
					}
				/>
			</ExpandableHeader>
			<ExpandableBody>
				{renderEluatesByDate()}
			</ExpandableBody>
		</Expandable>
	);
};
