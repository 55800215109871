import { ComponentPropsWithoutRef } from 'react';

import { ClassName } from 'lib/class-name/ClassName';

import { ModalDialogueSectionName } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSectionName';

import './modal-dialogue-section.scss';

export interface ModalDialogueSectionProps extends ComponentPropsWithoutRef<'section'> {
	section?: ModalDialogueSectionName;
}

export const ModalDialogueSection = (props: ModalDialogueSectionProps): JSX.Element => {

	const { section = ModalDialogueSectionName.SECTION_MAIN } = props;

	const sectionModifier = ClassName.fromEnumValue(String(section));

	return (
		<section className={`modal-dialogue-section modal-dialogue-section--${sectionModifier}`}>
			{props.children}
		</section>
	);

};
