import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';

import {
	SequenceModelConverter as ModelConverter,
	SequencePersistenceModel as PersistenceModel,
	SequenceStoreModel as StoreModel,
	SequenceViewModel as ViewModel,
	CreatableSequenceViewModel as CreatableViewModel
} from 'services/device/domain/model/SequenceModel';
import { SequenceHttpPersistence as HttpPersistence } from 'services/device/persistence/SequenceHttpPersistence';

export class SequencePersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {

	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

}
