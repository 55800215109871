import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './tag.scss';

export enum TagType {
	NEUTRAL = 'NEUTRAL',
	NEGATIVE = 'NEGATIVE',
	POSITIVE = 'POSITIVE',
	WARN = 'WARN',
	INFO = 'INFO'
}

export interface TagProps {
	/**
	 * Label
	 */
	label: string;
	/**
	 * Tag type
	 */
	type: TagType;
	/**
	 * (Optional) icon
	 */
	icon?: IconIdentifier;
}

/**
 * The tag partial
 */
export const Tag = (props: TagProps): JSX.Element => {
	const { label, type, icon } = props;

	const iconEl = icon && type === TagType.INFO ?
		<Icon name={icon} /> :
		null;

	return (
		<div className={`tag tag--${type.toLowerCase()}`}>
			{iconEl}
			{label}
		</div>
	);
};
