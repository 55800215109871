import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';

import {
	ExternalReportHttpPersistence as ExternalHttpPersistence
} from 'services/report/persistence/ExternalReportHttpPersistence';
import {
	ExternalReportModelConverter as ModelConverter,
	ExternalReportStoreModel as StoreModel,
	ExternalReportViewModel as ViewModel,
} from 'services/report/domain/model/ExternalReportModel';
import { DebugConsole } from '../../../lib/debug/DebugConsole';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';

// Declare a report state type
export interface ExternalReportState {
	externalReport: StoreModel;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	externalReport: null,
	fetchStatus: AsyncFetchStatus.IDLE,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ExternalReportState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
export const fetchExternalReport = createAsyncThunk(
	'externalReport/fetch',
	async (params: { reportUuid: string }): Promise<StoreModel> => {
		const persistence = new ExternalHttpPersistence(params.reportUuid);
		return persistence.fetch(params.reportUuid);
	},
	{
		condition: (_params, { getState }): boolean => {
			// Silently abort the action
			const { externalReport } = getState() as RootState;
			return checkFetchStatus(externalReport.fetchStatus);
		}
	}
);

// Slice definition
export const externalReportSlice = createSlice({
	name: 'externalReports',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creating slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchExternalReport.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalReport.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.externalReport = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalReport.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

export const { resetState, resetActionStatus } = externalReportSlice.actions;

// Export the reducer as default
export default externalReportSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectExternalReport = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.externalReport?.externalReport?.Uuid === uuid ? rootState.externalReport.externalReport : null;

		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};
