import { FunctionComponent } from 'react';

import './card-report-detail.scss';

export interface CardReportDetailProps {
	/**
	 * (Optional) Archived state
	 */
	archived?: boolean;
}

/**
 * The card report detail component
 */

export const CardReportDetail: FunctionComponent<CardReportDetailProps> = (props): JSX.Element => {
	const { children } = props;

	return (
		<div className='card-report-detail'>
			<div className='card-report-detail__content'>
				{children}
			</div>
		</div>
	);
};
