import { ComponentPropsWithoutRef } from 'react';

import 'presentation/ui/layouts/error-page-layout/error-page-layout.scss';

export type ErrorPageLayoutProps = ComponentPropsWithoutRef<'div'>;

export const ErrorPageLayout = (props: ErrorPageLayoutProps): JSX.Element => {

	return (
		<div className={`error-page-layout ${props.className ?? ''}`}>
			{props.children}
		</div>
	);
};
