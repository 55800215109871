import { createContext } from 'react';

interface L10nContextValue {
	language?: string;

	translate(literal: string, defaultValue?: string): string;

	formatDate(date?: Date, defaultLocale?: string, defaultValue?: string): string;

	formatTime(date?: Date, defaultLocale?: string, defaultValue?: string): string;

	formatDateTime(date?: Date, defaultLocale?: string, defaultValue?: string): string;

	formatNumber(number?: number, decimals?: number, defaultLocale?: string, defaultValue?: string): string;

	formatExponentialNumber(number?: number, defaultLocale?: string): string;

	formatBoolean(boolean?: boolean): string;

	changeLanguage(locale: string): void;
}

export const L10nContext = createContext<L10nContextValue>({
	language: null,
	translate(literal: string, defaultValue?: string): string {
		return defaultValue ?? literal;
	},
	formatDate(date?: Date, defaultLocale?: string, defaultValue = ''): string {
		return date
			? date.toLocaleDateString(defaultLocale ?? undefined)
			: defaultValue;
	},
	formatTime(date?: Date, defaultLocale?: string, defaultValue = ''): string {
		return date
			? date.toLocaleTimeString(defaultLocale ?? undefined)
			: defaultValue;
	},
	formatDateTime(date?: Date, defaultLocale?: string, defaultValue = ''): string {
		return date
			? date.toLocaleDateString(defaultLocale ?? undefined) + ' ' + date.toLocaleTimeString(defaultLocale ?? undefined)
			: defaultValue;
	},
	formatNumber(number?: number, decimals = 2, defaultLocale?: string, defaultValue = ''): string {
		return number
			? number.toLocaleString(defaultLocale ?? undefined, {
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals
			})
			: defaultValue;
	},
	formatExponentialNumber(number?: number, defaultLocale?: string): string {
		if (defaultLocale === 'de') {
			return number.toExponential(4).replace('.', ',');
		}
		return number.toExponential(4);
	},
	formatBoolean(boolean?: boolean): string {
		return boolean === true ? 'yes' : 'no';
	},
	changeLanguage(): void {
	}
});
