import { ValuedStringOutput } from 'services/device/domain/business/common/Output';
import { LabelRenderer } from 'services/device/presentation/ui/widget/label-renderer/LabelRenderer';
import { ValuedStringOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/ValuedStringOutputValueRenderer';

export interface ValuedStringOutputProps {
	output: ValuedStringOutput;
}

export const ValuedStringOutputRenderer = (props: ValuedStringOutputProps): JSX.Element => {
	const { output } = props;

	return (
		<li key={output.getName()}>
			<strong>
				<LabelRenderer output={output} />
			</strong>
			<p>
				<ValuedStringOutputValueRenderer output={output} />
			</p>
		</li>
	);

};
