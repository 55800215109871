import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import { DeviceType } from 'services/device/domain/business/inventory/DeviceType';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';

export type DeviceUuid = string;

export interface DevicePersistenceModel extends PersistenceModel {
	Client: string;
	Facility: string;
	Deletable: boolean;
	State: string;
	Type: string;
	Name: string;
	Identifier?: string;
	SerialNumber?: string;
	Location?: string;
}

export interface DeviceStoreModel extends StoreModel {
	Client: string;
	Facility: string;
	Deletable: boolean;
	State: string;
	Type: string;
	Name: string;
	Identifier?: string;
	SerialNumber?: string;
	Location?: string;
}

export interface DeviceViewModel extends ViewModel {
	Client: string;
	Facility: string;
	Deletable: boolean;
	State: DeviceModelState;
	Type: DeviceType;
	Name: string;
	Identifier?: string;
	SerialNumber?: string;
	Location?: string;
}

export interface CreatableDeviceViewModel extends CreatableViewModel {
	Client: string;
	Facility: string;
	Type: DeviceType;
	Name: string;
	Identifier?: string;
	SerialNumber?: string;
	Location?: string;
}

export class DeviceModelConverter extends ModelConverter<DevicePersistenceModel, DeviceStoreModel, DeviceViewModel, CreatableDeviceViewModel> {

	public fromCreatableViewModel(creatableViewModel?: CreatableDeviceViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			model.Deletable = true;
			model.State = DeviceModelState.ACTIVE;
		});
	}

	public toViewModel(): DeviceViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.State = model?.State as DeviceModelState ?? null;
			vModel.Type = model?.Type as DeviceType ?? null;
		});
	}

}
