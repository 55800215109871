import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';

import { Valuation } from 'services/device/domain/business/common/Valuation';
import { Interval } from 'services/device/domain/business/inventory/Interval';
import { getIntervalDefinition } from 'services/device/domain/business/inventory/IntervalDefinition';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { getSequenceDue, getSequenceDueDate, getSequenceValuation } from 'services/device/domain/business/util/SequenceUtil';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { SequenceModelState } from 'services/device/domain/model/SequenceModelState';
import { ValuationConverter } from 'services/device/lib/record/ValuationConverter';

import { CardSequenceListItem } from 'presentation/ui/components/cards/card-sequence/card-sequence-list-item/CardSequenceListItem';
import {
	CardSequenceListItemHeader
} from 'presentation/ui/partials/card/card-item-header/card-sequence-list-item-header/CardSequenceListItemHeader';
import { CardItemSequence } from 'presentation/ui/partials/card/card-item-sequence/CardItemSequence';
import { CardItemState } from 'presentation/ui/partials/card/card-item-state/CardItemState';
import { CardItemTagType } from 'presentation/ui/partials/card/CardItemTagType';
import { AuthContext } from '../../../../../core/context/AuthContext';
import { Permission } from '../../../../../core/lib/auth/AuthService';

export interface SequenceCollectionItemProps {
	device: Readonly<DeviceViewModel>;
	sequence: Readonly<SequenceViewModel>;
	onClick: () => void;
	onAddRecord?: () => void;
}

export const SequenceCollectionItem = (props: SequenceCollectionItemProps): JSX.Element => {
	const { device, onClick, onAddRecord } = props;

	const sequenceViewModel = props.sequence;

	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);

	const renderRecordState = (): JSX.Element | null => {
		const showLatestRecordDisplayDate = sequenceViewModel.SequenceMemory.latestRecordRecordedAt ?
			l10nContext.formatDateTime(new Date(sequenceViewModel.SequenceMemory.latestRecordRecordedAt)) : '-';

		const latestRecordDisplayString = l10nContext.translate('view.device.sequences.card.latestRecordPrefix') + showLatestRecordDisplayDate;

		const tag = (getSequenceValuation(sequenceViewModel) !== Valuation.UNKNOWN)
			? {
				label: l10nContext.translate(ValuationConverter.toL10nKey(getSequenceValuation(sequenceViewModel))),
				type: ValuationConverter.toTagType(getSequenceValuation(sequenceViewModel))
			} as CardItemTagType
			: null;

		return (
			<CardItemState date={latestRecordDisplayString}>
				<CardItemSequence
					sequence={sequenceViewModel}
					tag={tag}
				/>
			</CardItemState>
		);
	};

	// TODO: Find a better way to determine the reminder interval
	const reminderInterval = sequenceViewModel.SequenceConfiguration.values?.reminderInterval ?? null;

	let reminedIntervalDisplay = null;
	if ((reminderInterval ?? null) !== null && reminderInterval !== Interval.NONE) {
		reminedIntervalDisplay = l10nContext.translate(getIntervalDefinition(reminderInterval).getLabelKey());
		if (getSequenceDueDate(sequenceViewModel) !== null) {
			reminedIntervalDisplay += ' (' + l10nContext.formatDate(getSequenceDueDate(sequenceViewModel)) + ')';
		}
	}

	return (
		<CardSequenceListItem
			key={sequenceViewModel.Uuid}
			cardId="myCardSequenceListItemActiveCardId"
			onClick={onClick}
			archived={sequenceViewModel.State === SequenceModelState.ARCHIVED}
			button={{
				disabled: device.State === DeviceModelState.ARCHIVED
					|| sequenceViewModel.State === SequenceModelState.ARCHIVED
					|| !authContext.hasPermission(Permission.RECORD_CREATE),
				onClick: onAddRecord
			}}
		>
			<CardSequenceListItemHeader
				title={sequenceViewModel.Name}
				subtitle={l10nContext.translate(getSequenceTypeDefinition(sequenceViewModel.Type).getLabelKey())}
				interval={reminedIntervalDisplay}
				due={getSequenceDue(sequenceViewModel)}
				archived={sequenceViewModel.State === SequenceModelState.ARCHIVED}
			/>
			{renderRecordState()}
		</CardSequenceListItem>
	);
};
