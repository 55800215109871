import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentModalType } from 'services/documents/domain/type/DocumentsModalType';
import { DocumentAddModal } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddModal';
import { DocumentArchiveModal } from 'services/documents/presentation/ui/document-action/document-archive-modal/DocumentArchiveModal';
import { DocumentDeleteModal } from 'services/documents/presentation/ui/document-action/document-delete-modal/DocumentDeleteModal';
import { DocumentDownloadModal } from 'services/documents/presentation/ui/document-action/document-download-modal/DocumentDownloadModal';
import { DocumentMoveModal } from 'services/documents/presentation/ui/document-action/document-move-modal/DocumentMoveModal';
import { DocumentUnarchiveModal } from 'services/documents/presentation/ui/document-action/document-unarchive-modal/DocumentUnarchiveModal';
import { DocumentEditModal } from '../document-action/document-edit-modal/DocumentEditModal';
import {
	ExternalReportDocumentDownloadModal
} from '../document-action/external-report-document-download-modal/ExternalReportDocumentDownloadModal';

export interface DocumentModalProps {
	selectedDocument?: DocumentViewModel
	modalType: DocumentModalType,
	onDismiss: () => void,
	documentScope: DocumentModelScope,
	documentScopeReference?: string;
}

export const DocumentModal = (props: DocumentModalProps): JSX.Element => {
	const { modalType, onDismiss, selectedDocument, documentScope, documentScopeReference } = props;

	// RENDER ELEMENTS
	const modal = (): JSX.Element => {
		switch (modalType) {
			case DocumentModalType.ADD_DOCUMENT:
				return <DocumentAddModal
					onDismiss={onDismiss}
					documentScope={documentScope}
					documentScopeReference={documentScopeReference}
				/>;

			case DocumentModalType.DELETE:
				return <DocumentDeleteModal document={selectedDocument} onDismiss={onDismiss} />;

			case DocumentModalType.ARCHIVE:
				return <DocumentArchiveModal document={selectedDocument} onDismiss={onDismiss} />;

			case DocumentModalType.UNARCHIVE:
				return <DocumentUnarchiveModal document={selectedDocument} onDismiss={onDismiss} />;

			case DocumentModalType.EDIT_DOCUMENT_NAME:
				return <DocumentEditModal document={selectedDocument} onDismiss={onDismiss} />;

			case DocumentModalType.DOWNLOAD:
				return <DocumentDownloadModal document={selectedDocument} onDismiss={onDismiss} />;

			case DocumentModalType.EXTERNAL_DOWNLOAD:
				return <ExternalReportDocumentDownloadModal document={selectedDocument} onDismiss={onDismiss} />;

			case DocumentModalType.MOVE_DOCUMENT:
				return <DocumentMoveModal
					onDismiss={onDismiss}
					document={selectedDocument as DocumentViewModel}
				/>;

			default:
				return null;
		}
	};

	return (
		<>
			{modalType !== null && modal()}
		</>
	);
};
