import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';

import { Output } from 'services/device/domain/business/common/Output';

export interface LabelProps {
	output: Output<any>;
}

export const LabelRenderer = (props: LabelProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);
	const { output } = props;

	return (
		<span className="widget-label">
			{l10nContext.translate(output.getLabelKey())}
		</span>
	);

};
