import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentModelState } from 'services/documents/domain/model/DocumentModelState';

export type DocumentModelUuid = string;

export interface DocumentFileInfoPersistenceModel {
	CreatedDatetime: number,
	ModifiedDatetime: number,
	Filesize: number,
	Mimetype: string
}

export interface DocumentFileInfoStoreModel {
	CreatedDatetime: number,
	ModifiedDatetime: number,
	Filesize: number,
	Mimetype: string
}

export interface DocumentFileInfoViewModel {
	CreatedDatetime: Date,
	ModifiedDatetime: Date,
	Filesize: number,
	Mimetype: string
}

export interface DocumentPersistenceModel extends PersistenceModel {
	Client: string;
	Facility: string;
	Scope: string,
	ScopeReference: string,
	State: string;
	Filename: string,
	Url: string,
	FileInfo: DocumentFileInfoPersistenceModel
}

export interface DocumentStoreModel extends StoreModel {
	Client: string;
	Facility: string;
	Scope: string,
	ScopeReference: string,
	State: string;
	Filename: string,
	Url: string,
	FileInfo: DocumentFileInfoStoreModel
}

export interface DocumentViewModel extends ViewModel {
	Client: string;
	Facility: string;
	Scope: DocumentModelScope,
	ScopeReference: string,
	State: DocumentModelState;
	Filename: string,
	Url: string,
	FileInfo: DocumentFileInfoViewModel
}

export interface CreatableDocumentViewModel extends CreatableViewModel {
	Client: string;
	Facility: string;
	Scope: DocumentModelScope,
	ScopeReference: string,
	File: File,
	Filename: string,
}

export class DocumentModelConverter extends ModelConverter<DocumentPersistenceModel, DocumentStoreModel, DocumentViewModel, CreatableDocumentViewModel> {

	public fromCreatableViewModel(creatableViewModel?: CreatableDocumentViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			model.State = DocumentModelState.ACTIVE;
		});
	}

	public fromStoreModel(storeModel?: DocumentStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			if (Object.prototype.hasOwnProperty.call(sModel, 'FileInfo') && sModel.FileInfo !== null) {
				const fileInfo = {
					CreatedDatetime: null,
					ModifiedDatetime: null,
					Filesize: null,
					Mimetype: null,
					...sModel.FileInfo
				} as DocumentFileInfoPersistenceModel;
				if (Object.prototype.hasOwnProperty.call(sModel.FileInfo, 'CreatedDatetime')) {
					fileInfo.CreatedDatetime = Math.floor(sModel.FileInfo.CreatedDatetime / 1000);
				}
				if (Object.prototype.hasOwnProperty.call(sModel.FileInfo, 'ModifiedDatetime')) {
					fileInfo.CreatedDatetime = Math.floor(sModel.FileInfo.ModifiedDatetime / 1000);
				}
				model.FileInfo = fileInfo;
			}
		});
	}

	public fromViewModel(viewModel?: DocumentViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.Scope = vModel?.Scope as string ?? null;
			model.State = vModel?.State as string ?? null;
			if (Object.prototype.hasOwnProperty.call(vModel, 'FileInfo') && vModel.FileInfo !== null) {
				const fileInfo = {
					CreatedDatetime: null as number,
					ModifiedDatetime: null as number,
					Filesize: vModel.FileInfo?.Filesize ?? null,
					Mimetype: vModel.FileInfo?.Mimetype ?? null,
				};
				if (Object.prototype.hasOwnProperty.call(vModel.FileInfo, 'CreatedDatetime')) {
					fileInfo.CreatedDatetime = Math.floor(vModel.FileInfo.CreatedDatetime.getTime() / 1000);
				}
				if (Object.prototype.hasOwnProperty.call(vModel.FileInfo, 'ModifiedDatetime')) {
					fileInfo.CreatedDatetime = Math.floor(vModel.FileInfo.ModifiedDatetime.getTime() / 1000);
				}
				model.FileInfo = fileInfo;
			}
		});
	}

	public toStoreModel(): DocumentStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.Scope = model?.Scope as string ?? null;
			sModel.State = model?.State as string ?? null;
			if (Object.prototype.hasOwnProperty.call(model, 'FileInfo') && model.FileInfo !== null) {
				const fileInfo = {
					CreatedDatetime: null,
					ModifiedDatetime: null,
					Filesize: null,
					Mimetype: null,
					...model.FileInfo
				} as DocumentFileInfoStoreModel;
				if (Object.prototype.hasOwnProperty.call(model.FileInfo, 'CreatedDatetime')) {
					fileInfo.CreatedDatetime = model.FileInfo.CreatedDatetime * 1000;
				}
				if (Object.prototype.hasOwnProperty.call(model.FileInfo, 'ModifiedDatetime')) {
					fileInfo.ModifiedDatetime = model.FileInfo.ModifiedDatetime * 1000;
				}
				sModel.FileInfo = fileInfo;
			}
		});
	}

	public toViewModel(): DocumentViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.Scope = model?.Scope as DocumentModelScope ?? null;
			vModel.State = model?.State as DocumentModelState ?? null;

			if (Object.prototype.hasOwnProperty.call(model, 'FileInfo') && model.FileInfo !== null) {
				if (!Object.prototype.hasOwnProperty.call(vModel, 'FileInfo')) {
					vModel.FileInfo = {
						CreatedDatetime: null,
						ModifiedDatetime: null,
						Filesize: null,
						Mimetype: null,
						...model.FileInfo
					} as DocumentFileInfoViewModel;
				}
				if (Object.prototype.hasOwnProperty.call(model.FileInfo, 'CreatedDatetime')) {
					vModel.FileInfo.CreatedDatetime = new Date(model.FileInfo.CreatedDatetime * 1000);
				}
				if (Object.prototype.hasOwnProperty.call(model.FileInfo, 'ModifiedDatetime')) {
					vModel.FileInfo.ModifiedDatetime = new Date(model.FileInfo.ModifiedDatetime * 1000);
				}
			}
		});
	}

}
