import { ComponentPropsWithoutRef } from 'react';
import './checkbox.scss';

export interface CheckboxProps extends ComponentPropsWithoutRef<'input'> {
	/**
	 * ID of checkbox
	 */
	id?: string,
	/**
	 * Status of checkbox
	 */
	isSummary?: boolean,
	/**
	 * Label
	 */
	labelName?: string,
	isReportSelection?: boolean
}

export const Checkbox = (props: CheckboxProps): JSX.Element => {
	const { id, isSummary, labelName, isReportSelection, ...restProps } = props;

	return (
		<div className={`checkbox ${isSummary ? 'checkbox--summary' : ''}`}>
			<input
				className={`checkbox__input ${id} `}
				type='checkbox'
				id={id}
				{...restProps}
			/>
			<label
				className={`checkbox__label ${isReportSelection ? 'checkbox__label__report-selection' : ''}`}
				htmlFor={id}
			>
				{ labelName }
			</label>
		</div>
	);
};
