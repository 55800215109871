import { useCallback } from 'react';

import { CardItemActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemActionTypes';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { Icon, IconColor } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './card-item-simple-controls.scss';

export interface CardItemSimpleControlsProps {
	/**
	 * Id of controlled card
	 */
	cardId: string;
	/**
	 * (Optional) Click handler
	 */
	onClick?: (action: CardItemControlActionType) => void;
	/**
	 * (Optional) Edit action state
	 */
	actionEdit?: CardItemControlState;
	/**
	 * (Optional) Delete action state
	 */
	actionDelete?: CardItemControlState;
}

/**
 * A card item controls component
 */
export const CardItemSimpleControls = (props: CardItemSimpleControlsProps): JSX.Element => {
	const { cardId, onClick, actionEdit, actionDelete } = props;

	const createIcon = (name: IconIdentifier, state: CardItemControlState, action: string) => {
		const stateClass = state ?
			`control-icon--${state.toLowerCase()}` :
			'';

		return (
			<Icon
				name={name}
				color={IconColor.PRIMARY_50}
				className={stateClass}
				onClick={() => onClickHandler(action)}
			/>
		);
	};

	const onClickHandler = useCallback((action: string) => {
		if (!onClick) return;

		onClick({
			type: action,
			cardId
		});
	}, [onClick, cardId]);

	const editAction = actionEdit ?
		createIcon(IconIdentifier.EDIT, actionEdit, CardItemActionType.CHANGE) :
		null;

	const deleteAction = actionDelete ?
		createIcon(IconIdentifier.TRASH, actionDelete, CardItemActionType.DELETE) :
		null;

	return (
		<div className='card-item-controls'>
			{editAction}
			{deleteAction}
		</div>
	);
};
