import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { JsonWebToken } from 'services/core/lib/auth/AuthService';
import { AuthErrorBoundary } from 'services/core/presentation/ui/auth-error-boundary/AuthErrorBoundray';
import { ExternalLoginView } from 'services/core/presentation/view/ExternalLoginView';
import { resetState as resetDeviceState } from 'services/device/store/devicesSlice';
import { resetState as resetRecordState } from 'services/device/store/recordSlice';
import { resetState as resetSequenceState } from 'services/device/store/sequenceSlice';
import { resetState as resetDocumentState } from 'services/documents/store/documentSlice';
import { resetState as resetFolderState } from 'services/documents/store/folderSlice';
import { resetState as resetMaintenanceLogEntryState } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { resetState as resetRoleState } from 'services/core/store/roleSlice';
import { resetState as resetMembershipState } from 'services/core/store/membershipSlice';
import { resetState as resetReportState } from 'services/report/store/reportSlice';
import { resetState as resetGeneratorState } from 'services/nuclide/store/generatorSlice';
import { resetState as resetEluateState } from 'services/nuclide/store/eluateSlice';
import { resetState as resetCyclotronProductState } from 'services/nuclide/store/cyclotronProductSlice';
import { ExternalAuthService } from '../lib/auth/ExternalAuthService';
import { ExternalAuthContext } from './ExternalAuthContext';

interface UrlParams {
	reportUuid: string;
}

export const ExternalAuthContextProvider = (props: any): JSX.Element => {

	// Unwrap the required report uuid from the route
	const params = useParams<UrlParams>();
	const reportUuid = params?.reportUuid ?? null;
	if (reportUuid === null) {
		throw new Error('Report id is missing');
	}

	const authService = new ExternalAuthService(reportUuid);

	const dispatch = useDispatch();

	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const [authed, setAuthed] = useState<boolean>(authService.getJsonWebToken() !== null);

	const jsonWebToken = (): JsonWebToken | null => {
		return authService.getJsonWebToken();
	};

	const authenticate = async (password: string): Promise<boolean> => {
		dispatch(resetDeviceState());
		dispatch(resetSequenceState());
		dispatch(resetRecordState());
		dispatch(resetMaintenanceLogEntryState());
		dispatch(resetDocumentState());
		dispatch(resetFolderState());
		dispatch(resetRoleState());
		dispatch(resetMembershipState());
		dispatch(resetReportState());
		dispatch(resetGeneratorState());
		dispatch(resetEluateState());
		dispatch(resetCyclotronProductState());
		const receivedJsonWebToken = await authService.authenticate(reportUuid, password);
		setAuthed(receivedJsonWebToken !== null);

		return receivedJsonWebToken !== null;
	};

	const renderLoginInterceptionView = (): JSX.Element => {
		return (
			<ExternalLoginView />
		);
	};

	const renderChildren = (): JSX.Element => {
		return (
			<AuthErrorBoundary>
				{props.children}
			</AuthErrorBoundary>
		);
	};

	return (
		<ExternalAuthContext.Provider
			value={{
				authenticate,
				jsonWebToken
			}}
		>
			{authed ? renderChildren() : renderLoginInterceptionView()}
		</ExternalAuthContext.Provider>
	);

};
