import { useContext } from 'react';
import { L10nContext } from 'context/L10nContext';

import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ErrorPageLayout } from 'presentation/ui/layouts/error-page-layout/ErrorPageLayout';

import { SimpleViewSubtitle } from '../../services/core/presentation/ui/simple-view-subtitle/SimpleViewSubtitle';
import { SimpleViewTitle } from '../../services/core/presentation/ui/simple-view-title/SimpleViewTitle';


export const ExternalPageErrorView = (): JSX.Element => {

	// Consume the context;
	const l10nContext = useContext(L10nContext);

	return (
		<MainLayout>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ErrorPageLayout>
					<ViewLayoutSection>
						<SimpleViewTitle label={l10nContext.translate('view.pageErrorView.title', 'Diese Seite existiert nicht')} />
						<SimpleViewSubtitle label={l10nContext.translate('view.pageErrorView.subtitle', 'Bitte geben Sie die korrekte URL ein')} />
					</ViewLayoutSection>

				</ErrorPageLayout>

			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
