export enum CyclotronProductIsotope {

	CESIUM_137_CS = 'CESIUM_137_CS',

	COBALT_58_CO = 'COBALT_58_CO',

	ERBIUM_169_ER = 'ERBIUM_169_ER',

	FLUORINE_18_F = 'FLUORINE_18_F',

	INDIUM_111_IN = 'INDIUM_111_IN',

	IODINE_123_I = 'IODINE_123_I',

	IODINE_131_I = 'IODINE_131_I',

	LUTETIUM_177_LU = 'LUTETIUM_177_LU',

	NITROGEN_13_N = 'NITROGEN_13_N',

	RHENIUM_186_RE = 'RHENIUM_186_RE',

	YTTRIUM_90_Y = 'YTTRIUM_90_Y'
}
