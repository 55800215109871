import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	ReportSequenceModelConverter as ModelConverter,
	ReportSequencePersistenceModel as PersistenceModel,
	ReportSequenceStoreModel as StoreModel,
	ReportSequenceViewModel as ViewModel,
	CreatableReportSequenceViewModel as CreatableViewModel
} from 'services/report/domain/model/ReportSequenceModel';

export class ReportSequenceHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'report/api/v1';
	protected collectionControllerUri = 'report-sequences';
	protected entityControllerUri = 'report-sequence';

	protected responseCollectionKey = 'ReportSequenceModelCollection';
	protected responseEntityKey = 'ReportSequenceModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
