import { JsonWebToken } from 'services/core/lib/auth/AuthService';
import { ExternalHttpApiConnector } from './api-connector/ExternalHttpApiConnector';

export class ExtenalReportFileDownloadToken {

	private externalReportFileDownloadToken: string | null = null;

	public getExternalReportFileDownloadToken(): string | null {
		return this.externalReportFileDownloadToken;
	}

	private setExternalReportFileDownloadToken(value: string | null) {
		this.externalReportFileDownloadToken = value;
	}

	public async requestExternalReportFileDownloadToken(jsonWebToken: JsonWebToken): Promise<string> {
		try {
			const externalHttpApiConnector = new ExternalHttpApiConnector();
			const externalReportFileDownloadToken = await externalHttpApiConnector.fileDownloadToken(jsonWebToken);
			this.setExternalReportFileDownloadToken(externalReportFileDownloadToken);
		} catch (e) {
			this.setExternalReportFileDownloadToken(null);
			throw e;
		}
		return this.getExternalReportFileDownloadToken();
	}

}
