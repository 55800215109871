import { L10nContext } from 'context/L10nContext';

import { CardSequenceDetail } from 'presentation/ui/components/cards/card-sequence/card-sequence-detail/CardSequenceDetail';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import {
	CardItemAttributesAction
} from 'presentation/ui/partials/card/card-item-attributes/card-item-attributes-action/CardItemAttributesAction';
import {
	CardItemSequenceAttributes
} from 'presentation/ui/partials/card/card-item-attributes/card-item-sequence-attributes/CardItemSequenceAttributes';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemSequenceHeader } from 'presentation/ui/partials/card/card-item-header/card-item-sequence-header/CardItemSequenceHeader';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Route } from 'router/Route';

import { AuthContext } from 'services/core/context/AuthContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Permission, permissionGroupSequence } from 'services/core/lib/auth/AuthService';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';
import { RecordViewModel } from 'services/device/domain/model/RecordModel';
import { SequenceModelState } from 'services/device/domain/model/SequenceModelState';
import { RecordCommentModal } from 'services/device/presentation/ui/record-action/record-comment-modal/RecordCommentModal';
import { RecordCreateModal } from 'services/device/presentation/ui/record-action/record-create-modal/RecordCreateModal';
import { RecordDeleteModal } from 'services/device/presentation/ui/record-action/record-delete-modal/RecordDeleteModal';
import { RecordDocumentsModal } from 'services/device/presentation/ui/record-action/record-documents-modal/RecordDocumentsModal';
import { RecordUpdateModal } from 'services/device/presentation/ui/record-action/record-update-modal/RecordUpdateModal';
import { SequenceArchiveModal } from 'services/device/presentation/ui/sequence-action/sequence-archive-modal/SequenceArchiveModal';
import { SequenceCloneModal } from 'services/device/presentation/ui/sequence-action/sequence-clone-modal/SequenceCloneModal';
import { SequenceDeleteModal } from 'services/device/presentation/ui/sequence-action/sequence-delete-modal/SequenceDeleteModal';
import { SequenceUnarchiveModal } from 'services/device/presentation/ui/sequence-action/sequence-unarchive-modal/SequenceUnarchiveModal';
import { SequenceUpdateModal } from 'services/device/presentation/ui/sequence-action/sequence-update-modal/SequenceUpdateModal';
import { SequenceRecords } from 'services/device/presentation/ui/sequence-records/SequenceRecords';
import { selectDeviceByUuid } from 'services/device/store/devicesSlice';
import { resetActionStatus as resetRecordActionStatus } from 'services/device/store/recordSlice';
import { resetActionStatus, selectSequenceByUuid } from 'services/device/store/sequenceSlice';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { RecordCreateModalLocation } from '../ui/record-action/record-create-modal/RecordCreateModalLocation';

interface SequenceViewParams {
	deviceUuid: string;
	sequenceUuid: string;
}

enum ModalType {
	ARCHIVE = 'ARCHIVE',
	DELETE = 'DELETE',
	EDIT = 'EDIT',
	PENDING = 'PENDING',
	UNARCHIVE = 'UNARCHIVE',
	CLONE = 'CLONE',
	CREATE_RECORD = 'CREATE_RECORD',
	UPDATE_RECORD = 'UPDATE_RECORD',
	DELETE_RECORD = 'DELETE_RECORD',
	RECORD_COMMENT = 'RECORD_COMMENT',
	RECORD_DOCUMENTS = 'RECORD_DOCUMENTS'
}

export const MeasurementGoalsView = (): JSX.Element => {
	// Unwrap the required device uuid from the route
	const params = useParams<SequenceViewParams>();

	const deviceUuid = params?.deviceUuid ?? null;
	if (deviceUuid === null) {
		throw new Error('Device id is missing');
	}

	const sequenceUuid = params?.sequenceUuid ?? null;
	if (sequenceUuid === null) {
		throw new Error('Sequence id is missing');
	}

	// Handle permissions
	const authContext = useContext(AuthContext);
	if (!authContext.hasAnyPermission(permissionGroupSequence)) {
		throw new Error('Permission denied');
	}

	// Consume the contexts
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	const dispatch = useDispatch();

	// Read the sequence from the state store
	const device = useSelector(selectDeviceByUuid(deviceUuid));
	const sequence = useSelector(selectSequenceByUuid(sequenceUuid));

	const sequenceActionStatus = useTypedSelector(state => state.sequences.actionStatus);
	useEffect(() => {
		if (sequenceActionStatus === AsyncReducerStatus.DELETED) {
			dispatch(resetActionStatus());
			history.replace(Route.DEVICES + '/' + device.Uuid);
		}
	}, [device, sequenceActionStatus, dispatch, history]);

	const recordActionStatus = useTypedSelector(state => state.records.actionStatus);
	useEffect(() => {
		if (recordActionStatus === AsyncReducerStatus.DELETED) {
			dispatch(resetActionStatus());
			onModalDismiss();
		}
	}, [recordActionStatus, dispatch, history]);

	const [modalType, setModalType] = useState<ModalType>(null);
	const [modalPayload, setModalPayload] = useState<any>(null);

	// Show up a loading indicator while the devices fetching is pending
	if (
		sequenceActionStatus === AsyncReducerStatus.DELETE_PENDING
		|| sequenceActionStatus === AsyncReducerStatus.DELETED
	) {
		return (
			<LoadingSpinner />
		);
	}

	if (device === null || sequence === null) {
		// Show up an error if the device is not available although the devices were fetched from the persistence
		throw new Error('Sequence not found');
	}

	const onModalDismiss = (): void => {
		setModalType(null);
	};

	const onModalTriggerClick = (type: ModalType, payload: any = null): void => {
		dispatch(resetActionStatus());
		dispatch(resetRecordActionStatus());
		setModalType(type);
		setModalPayload(payload);
	};

	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permission: {
			create: authContext.hasPermission(Permission.SEQUENCE_CREATE),
			edit: authContext.hasPermission(Permission.SEQUENCE_UPDATE) && sequence.State === SequenceModelState.ACTIVE,
			delete: authContext.hasPermission(Permission.SEQUENCE_DELETE) && sequence.Deletable,
			archive: authContext.hasPermission(Permission.SEQUENCE_UPDATE_STATE)
		}
	};

	const archiveButton =
		<ButtonSecondary
			buttonText={
				sequence.State === SequenceModelState.ACTIVE ?
					l10nContext.translate('common.button.archive', 'Archivieren') :
					l10nContext.translate('common.button.unArchive', 'Archivierung aufheben')
			}
			icon={IconIdentifier.ARCHIVE}
			onClick={() => {
				sequence.State === SequenceModelState.ACTIVE ?
					onModalTriggerClick(ModalType.ARCHIVE) :
					onModalTriggerClick(ModalType.UNARCHIVE);
			}}
			disabled={!user.permission.archive}
		/>;

	const duplicateButton =
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.clone', 'Duplizieren')}
			icon={IconIdentifier.DUPLICATE}
			disabled={!user.permission.create}
			onClick={() => onModalTriggerClick(ModalType.CLONE)}
		/>;

	const deleteButton =
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.delete', 'Löschen')}
			icon={IconIdentifier.TRASH}
			status={ButtonSecondaryStatus.ATTENTION}
			disabled={!user.permission.delete}
			onClick={() => onModalTriggerClick(ModalType.DELETE)}
		/>;

	const modal = (): JSX.Element => {
		let renderModel: JSX.Element = null;

		switch (modalType) {
			case ModalType.EDIT:
				renderModel =
					<SequenceUpdateModal
						sequence={sequence}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ModalType.DELETE:
				renderModel =
					<SequenceDeleteModal
						sequence={sequence}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ModalType.ARCHIVE:
				renderModel =
					<SequenceArchiveModal
						sequence={sequence}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ModalType.UNARCHIVE:
				renderModel =
					<SequenceUnarchiveModal
						sequence={sequence}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ModalType.CLONE:
				renderModel =
					<SequenceCloneModal
						sequence={sequence}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ModalType.CREATE_RECORD:
				renderModel =
					<RecordCreateModal
						sequence={sequence}
						location={RecordCreateModalLocation.MEASUREMENT_GOALS}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ModalType.UPDATE_RECORD:
				renderModel =
					<RecordUpdateModal
						sequence={sequence}
						record={modalPayload as RecordViewModel}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ModalType.DELETE_RECORD:
				renderModel =
					<RecordDeleteModal
						record={modalPayload as RecordViewModel}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ModalType.RECORD_COMMENT:
				renderModel =
					<RecordCommentModal
						record={modalPayload as RecordViewModel}
						onDismiss={onModalDismiss}
					/>;
				break;

			case ModalType.RECORD_DOCUMENTS:
				renderModel =
					<RecordDocumentsModal
						record={modalPayload as RecordViewModel}
						onDismiss={onModalDismiss}
					/>;
				break;

		}

		return renderModel;
	};

	const instructionUrl = sequence.SequenceConfiguration.values.instructionUrl ?? null;

	let actionEditControlState = CardItemControlState.ENABLED;
	if (!user.permission.edit || device.State === DeviceModelState.ARCHIVED || sequence.State === SequenceModelState.ARCHIVED) {
		actionEditControlState = CardItemControlState.DISABLED;
	}

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs mapURLFragments={[[sequenceUuid, sequence.Name], [deviceUuid, device.Name]]} />
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>

						{modal()}

						<CardSequenceDetail>

							<CardItemSequenceHeader
								sequence={sequence}
							/>
							<CardItemSingleControl
								cardId={sequence.Uuid}
								actionEdit={actionEditControlState}
								onClick={() => onModalTriggerClick(ModalType.EDIT)}
							/>
							<CardItemSequenceAttributes sequence={sequence} />
							<CardItemAttributesAction
								title={l10nContext.translate('view.sequence.headline.instruction', 'Anleitung')}
								buttonLabel={l10nContext.translate('view.sequence.button.openInstruction', 'öffnen')}
								onClick={() => {
									if (instructionUrl !== null && instructionUrl !== '') {
										window.open(instructionUrl, '_blank');
									}
								}}
								disabled={instructionUrl === null || instructionUrl === ''}
							/>
						</CardSequenceDetail>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<Expandable expanded={true} firstElement>
							<ExpandableHeader caption={l10nContext.translate('view.sequence.headline.records', 'Messungen')}>
								<ButtonIcon
									weight={ButtonIconWeight.PRIMARY}
									shape={ButtonIconShape.ROUND}
									icon={IconIdentifier.ADD}
									onClick={() => onModalTriggerClick(ModalType.CREATE_RECORD)}
									disabled={device.State === DeviceModelState.ARCHIVED ||
										sequence.State === SequenceModelState.ARCHIVED ||
									!authContext.hasPermission(Permission.RECORD_CREATE)}
								/>
							</ExpandableHeader>

							<ExpandableBody>
								<SequenceRecords
									device={device}
									sequence={sequence}
									onShowComment={(record) => {
										onModalTriggerClick(ModalType.RECORD_COMMENT, record);
									}}
									onAttachments={(record) => {
										onModalTriggerClick(ModalType.RECORD_DOCUMENTS, record);
									}}
									onEdit={(record) => {
										onModalTriggerClick(ModalType.UPDATE_RECORD, record);
									}}
									onDelete={(record) => {
										onModalTriggerClick(ModalType.DELETE_RECORD, record);
									}}
								/>
							</ExpandableBody>
						</Expandable>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ColumnLayout>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{archiveButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
								{duplicateButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{deleteButton}
							</ColumnLayoutSection>
						</ColumnLayout>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
