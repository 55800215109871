import { Route as RouteComponent, Switch } from 'react-router-dom';

import { Route } from 'router/Route';
import { ScrollToTop } from 'router/ScrollToTop';
import { ExternalPageErrorView } from '../presentation/view/ExternalPageErrorView';
import {
	ExternalWasteManagementReportView
} from '../services/waste-management-report/presentation/view/ExternalWasteManagementReportView';

export const RouterExternalWasteManagementConfig = (): JSX.Element => {
	return (
		<>
			<ScrollToTop />
			<Switch>
				<RouteComponent exact path={`${Route.EXTERNAL_WASTE_MANAGEMENT_REPORT}/:wasteManagamentReportUuid`}>
					<ExternalWasteManagementReportView />
				</RouteComponent>
				<RouteComponent exact path={`${Route.EXTERNAL_WASTE_MANAGEMENT_REPORT}/*`}>
					<ExternalPageErrorView />
				</RouteComponent>
			</Switch>
		</>
	);
};
