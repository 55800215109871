// Unit: Bq/cm²

export enum NuclideSurfaceContamination {

	CESIUM_137_CS = 0,

	COBALT_58_CO = 1E+00,

	ERBIUM_169_ER = 1E+02,

	FLUORINE_18_F = 1E+00,

	GALLIUM_68_GA = 0,

	GERMANIUM_68_GE = 1E+00,

	INDIUM_111_IN = 1E+01,

	IODINE_123_I = 1E+01,

	IODINE_131_I = 1E+01,

	LUTETIUM_177_LU = 1E+02,

	MOLYBDENUM_99_MO = 1E+01,

	NITROGEN_13_N = 0,

	RHENIUM_186_RE = 1E+02,

	TECHNETIUM_99M_TC = 1E+01,

	YTTRIUM_90_Y = 1E+02
}
