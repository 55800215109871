import { CardGeneratorDetail } from 'presentation/ui/components/cards/card-generator/card-generator-detail/CardGeneratorDetail';
import { CardItemGeneratorAttributes } from 'presentation/ui/partials/card/card-item-attributes/card-item-generator-attributes/CardItemGeneratorAttributes';
import { CardItemGeneratorDispatchAttributes } from 'presentation/ui/partials/card/card-item-attributes/card-item-generator-dispatch-attributes/CardItemGeneratorDispatchAttributes';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemGeneratorHeader } from 'presentation/ui/partials/card/card-item-header/card-item-generator-header/CardItemGeneratorHeader';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { GeneratorModelState } from 'services/nuclide/domain/model/GeneratorModelState';
import { GeneratorViewModalType } from '../../view/GeneratorViewModalType';

interface GeneratorCardProps {
	generator: GeneratorViewModel;
	userPermissionEdit: boolean, 
	onClick: (modalType: GeneratorViewModalType) => void;
}

export const GeneratorCard = (props: GeneratorCardProps): JSX.Element => {
	const { generator, userPermissionEdit, onClick } = props;

	return (
		<CardGeneratorDetail>
			<CardItemGeneratorHeader
				title={generator.Name}
				subtitle={generator.SerialNumber}
				state={generator.State}
			/>
			<CardItemSingleControl
				cardId={generator.Uuid}
				actionEdit={userPermissionEdit && generator.State === GeneratorModelState.ACTIVE ?
					CardItemControlState.ENABLED :
					CardItemControlState.DISABLED}
				onClick={() => onClick(GeneratorViewModalType.EDIT)}
			/>
			<CardItemGeneratorAttributes
				generator={generator}
			/>
			<CardItemGeneratorDispatchAttributes
				generator={generator}
			/>
		</CardGeneratorDetail>
	);
};
