import { memo, useContext } from 'react';
import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';
import { CyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';
import { calculateCurrentActivity, getCyclotronProductIsotopeDefinition } from 'services/nuclide/domain/model/isotopes/NuclideIsotopeDefinition';
import { getCyclotronProductMeasurementUnitDefinition } from 'services/nuclide/domain/model/NuclideMeasurementUnitDefinition';

import './card-item-cyclotron-product-attributes.scss';

export interface CardItemCyclotronProductAttributesProps {
	/**
	 * Cyclotron product
	 */
	cyclotronProduct: CyclotronProductViewModel;
}

/**
 * A card item cyclotron product detail attributes component
 */
export const CardItemCyclotronProductAttributes = memo((props: CardItemCyclotronProductAttributesProps): JSX.Element => {
	const { cyclotronProduct } = props;

	const l10nContext = useContext(L10nContext);

	const deliveryDateFormatted = l10nContext.formatDate(cyclotronProduct.DeliveryDate);
	const calibrationDateTimeFormatted = l10nContext.formatDateTime(cyclotronProduct.CalibrationDatetime);
	const activityUnit = l10nContext.translate(getCyclotronProductMeasurementUnitDefinition(cyclotronProduct.ActivityUnit).getLabelKey());
	const currentActivity = calculateCurrentActivity(cyclotronProduct.CalibrationDatetime, cyclotronProduct.NominalActivity, cyclotronProduct.Isotope);

	const translations = {

		name: { label: l10nContext.translate('cyclotronProducts.name', 'Name'), value: cyclotronProduct.Name },
		manufacturer: { label: l10nContext.translate('cyclotronProducts.manufacturer', 'Hersteller'), value: cyclotronProduct.Manufacturer },
		batchNumber: { label: l10nContext.translate('cyclotronProducts.batchNumber', 'Charge'), value: cyclotronProduct.BatchNumber },
		isotope: { label: l10nContext.translate('cyclotronProducts.isotope', 'Isotop'), value: l10nContext.translate(getCyclotronProductIsotopeDefinition(cyclotronProduct.Isotope).getLabelKey()) },
		radiopharmaceutical: { label: l10nContext.translate('cyclotronProducts.radiopharmaceutical', 'Radiopharmakon'), value: cyclotronProduct.Radiopharmaceutical },
		deliveryDate: { label: l10nContext.translate('cyclotronProducts.deliveryDate', 'Lieferdatum'), value: deliveryDateFormatted },
		nominalActivity: { label: l10nContext.translate('cyclotronProducts.nominalActivity', 'Nominalaktivität'), value: l10nContext.formatNumber(cyclotronProduct.NominalActivity) + ' ' + activityUnit },
		calibrationTime: { label: l10nContext.translate('cyclotronProducts.calibrationDateTime', 'Kalibrierzeitpunkt'), value: calibrationDateTimeFormatted },
		acceptedByName: { label: l10nContext.translate('cyclotronProducts.acceptedBy', 'Angenommen von'), value: cyclotronProduct.AcceptedByName },
		currentActivity: { label: l10nContext.translate('cyclotronProducts.currentActivity', 'Aktuelle Aktivität'), value: l10nContext.formatNumber(currentActivity) + ' ' + activityUnit }
	};

	const attributes = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const styleClass = 'attribute';

			return (
				<label
					key={Guid.generate()}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value }</p>
				</label>
			);
		});
	};

	return (
		<div className='card-item-cyclotron-product-attributes'>
			<ul className='card-item-cyclotron-product-attributes__attribute'>
				{attributes()}
			</ul>
		</div>
	);
});

