import { useRef } from 'react';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentAddForm } from 'services/documents/presentation/ui/document-action/document-add-form/DocumentAddForm';
import { DocumentAddFailedNotification } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddFailedNotification';
import { DocumentAddSuccessNotification } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddSuccessNotification';

import { DocumentAddPendingNotification } from './DocumentAddPendingNotification';

export interface DocumentAddModalContentProps {
	onDismiss?: () => void;
	documentScope: DocumentModelScope;
	documentScopeReference: string;
}

export const DocumentAddModalContent = (props: DocumentAddModalContentProps): JSX.Element => {
	const { onDismiss, documentScope, documentScopeReference } = props;

	const uploadedFiles = useRef<File[]>([]);

	const documentActionStatus = useTypedSelector(state => state.documents.actionStatus);

	const setFiles = (files: File[]) => {
		uploadedFiles.current = files;
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (documentActionStatus) {
			case AsyncReducerStatus.CREATED:
				modalContent = <DocumentAddSuccessNotification
					files={uploadedFiles.current}
					documentScope={documentScope}
					documentScopeReference={documentScopeReference}
					onClickConfirm={onDismiss}
				/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <DocumentAddFailedNotification onDismiss={onDismiss} />;
				break;

			case AsyncReducerStatus.IDLE:
				modalContent = <DocumentAddForm
					onChange={setFiles}
					documentScope={documentScope}
					documentScopeReference={documentScopeReference}
				/>;
				break;

			case AsyncReducerStatus.CREATE_PENDING:
				modalContent = <DocumentAddPendingNotification />;
				break;
		}

		return modalContent;
	};

	return (
		<>
			{renderModalContent()}
		</>
	);
};
