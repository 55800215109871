import { ComponentPropsWithoutRef } from 'react';
import './radiobutton.scss';

export interface RadiobuttonProps extends ComponentPropsWithoutRef<'input'> {
	/**
	 * Id of radiobutton
	 */
	id?: string,
	/**
	 * Status of radiobutton
	 */
	isSummary?: boolean;
	/**
	 * onChange for radiobutton
	 */
	labelName: string;
}

export const Radiobutton = (props: RadiobuttonProps): JSX.Element => {
	const { id, isSummary, labelName, ...restProps } = props;

	return (
		<div className={`radiobutton ${isSummary ? 'radiobutton--summary' : ''}`}>
			<input
				className={`radiobutton__input ${id} `}
				type='radio'
				id={id}
				{...restProps}
			/>
			<label
				className='radiobutton__label'
				htmlFor={id}
			>
				{ labelName }
			</label>
		</div>
	);
};
