import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentDownloadModalContent } from 'services/documents/presentation/ui/document-action/document-download-modal/DocumentDownloadModalContent';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface DocumentDownloadModalProps {
	document: DocumentViewModel;
	onClickPrevious?: () => void;
	onDismiss: () => void;
}

export const DocumentDownloadModal = (props: DocumentDownloadModalProps): JSX.Element => {
	const { document, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	return (
		<ModalDialogue
			height={ModalDialogueHeight.HEIGHT_FLEX}
			onDismiss={onDismiss}
		>
			<ModalDialogueHeader
				captionText={l10nContext.translate('common.modal.captionDownload')}
				captionIcon={IconIdentifier.DOWNLOAD}
			/>
			<DocumentDownloadModalContent document={document} onDismiss={onDismiss} />
		</ModalDialogue>
	);
};
