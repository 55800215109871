import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Tag, TagType } from 'presentation/ui/partials/tag/Tag';

import './card-report-sequence-item-header.scss';

export interface CardItemHeaderProps {
	/**
	 * Title
	 */
	title: string;
	/**
	 * (Optional) Sub-Title
	 */
	subtitle?: string | Date;
	/**
	 * (Optional) Tag
	 */
	archived?: boolean
}

/**
 * A card item header component
 */
export const CardReportSequenceItemHeader = (props: CardItemHeaderProps): JSX.Element => {
	const { title, subtitle, archived } = props;

	const l10nContext = useContext(L10nContext);

	const subTitle = subtitle ?
		<p className='card-report-sequence-item-header__content__subtitle'>
			{subtitle}
		</p> :
		null;

	const archivedTagElement = (): JSX.Element => {
		if (!archived) return null;

		const tagLabel = l10nContext.translate('common.tags.archived', 'Archiviert');

		return (
			<Tag
				label={tagLabel}
				type={TagType.INFO}
				icon={IconIdentifier.ARCHIVE}
			/>
		);
	};

	return (
		<div className='card-report-sequence-item-header'>
			<div className='card-report-sequence-item-header__content'>
				<h1 className='card-report-sequence-item-header__content__title'>
					{title}
				</h1>
				{subTitle}
			</div>
			{archivedTagElement()}
		</div>
	);
};
