import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DebugConsole } from 'lib/debug/DebugConsole';
import { ModelSyncState } from 'lib/domain/model/Model';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import { ModelQueryOrderDirection, ModelQueryOrderSortAlgo } from 'lib/persistence/idb/query/ModelQueryOrder';
import { ModelQueryOrderClosureBuilder } from 'lib/persistence/idb/query/ModelQueryOrderClosureBuilder';
import { ViolationError } from 'services/device/domain/business/common/violation/ViolationError';

import {
	CreatableRecordViewModel as CreatableViewModel,
	RecordModelConverter as ModelConverter,
	RecordStoreModel as StoreModel,
	RecordViewModel as ViewModel
} from 'services/device/domain/model/RecordModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { RecordHttpPersistence as Persistence } from 'services/device/persistence/RecordHttpPersistence';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import { checkFetchStatus, checkFetchStatusPending } from '../../../store/common/AsyncFetchStatus.util';
import { SequenceMemory } from '../domain/model/SequenceMemory';
import { regenerateSequenceMemory } from './sequenceSlice';

// Declare a device state type
export interface RecordState {
	records: Array<StoreModel>;
	createdRecord: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	records: [] as Array<StoreModel>,
	createdRecord: null,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as RecordState;

type StateChangeRecordResponse = StoreModel & {
	SequenceMemory: SequenceMemory
};

// Update record action payload
interface CreateRecordInfo {
	createdRecord: StoreModel;
	updatedRecords: Array<StoreModel>;
}

// Delete record action payload
interface DeletedRecordInfo {
	deletedRecord: StoreModel;
	updatedRecords: Array<StoreModel>;
}

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
export const fetchRecords = createAsyncThunk(
	'records/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Sliently abort the action
			const { records } = getState() as RootState;
			return checkFetchStatus(records.fetchStatus);
		}
	}
);

export const fetchRecordsBySequence = createAsyncThunk(
	'records/fetchBySequence',
	async (params: { clientUuid: string; facilityUuid: string; sequenceUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new Persistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollectionBySequence(params.sequenceUuid);
	},
	{
		condition: (_params, { getState }): boolean => {
			// Sliently abort the action
			const { records } = getState() as RootState;
			return !checkFetchStatusPending(records.fetchStatus);
		}
	}
);

export const updateRecord = createAsyncThunk(
	'record/update',
	async (viewModel: ViewModel, thunkAPI): Promise<StoreModel> => {
		const storeModel = new ModelConverter().fromViewModel(viewModel).toStoreModel();
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		const updatedRecord = await persistence.update<StateChangeRecordResponse>(storeModel);

		thunkAPI.dispatch(regenerateSequenceMemory({ sequenceUuid: updatedRecord.Sequence, memory: updatedRecord.SequenceMemory }));

		return updatedRecord;
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { records } = getState() as RootState;
			if (records.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const createRecord = createAsyncThunk(
	'record/create',
	async (viewModel: CreatableViewModel, thunkAPI): Promise<CreateRecordInfo> => {
		const storeModel = new ModelConverter().fromCreatableViewModel(viewModel).toStoreModel();
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		const createdRecord = await persistence.create<StateChangeRecordResponse>(storeModel);

		thunkAPI.dispatch(regenerateSequenceMemory({ sequenceUuid: createdRecord.Sequence, memory: createdRecord.SequenceMemory }));

		const response = {
			createdRecord,
			updatedRecords: []
		} as CreateRecordInfo;

		if ((createdRecord?.Replaces ?? null) !== null) {
			response.updatedRecords.push(await persistence.fetch(createdRecord.Replaces));
		}
		return response;
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { records } = getState() as RootState;
			if (records.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteRecord = createAsyncThunk(
	'record/delete',
	async (viewModel: ViewModel, thunkAPI): Promise<DeletedRecordInfo> => {
		const storeModel = new ModelConverter().fromViewModel(viewModel).toStoreModel();
		const persistence = new Persistence(viewModel.Client, viewModel.Facility);
		const deletedRecord = await persistence.delete<StateChangeRecordResponse>(storeModel);

		thunkAPI.dispatch(regenerateSequenceMemory({ sequenceUuid: deletedRecord.Sequence, memory: deletedRecord.SequenceMemory }));

		const response = {
			deletedRecord,
			updatedRecords: []
		} as DeletedRecordInfo;

		if ((deletedRecord?.Replaces ?? null) !== null) {
			response.updatedRecords.push(await persistence.fetch(deletedRecord.Replaces));
		}
		return response;
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { records } = getState() as RootState;
			if (records.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const recordsSlice = createSlice({
	name: 'records',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchRecords.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchRecords.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.records = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchRecords.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(fetchRecordsBySequence.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchRecordsBySequence.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.records = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchRecordsBySequence.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(updateRecord.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateRecord.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.records.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.records[index] = action.payload;
			}
			state.records = state.records.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build(
				'RecordedAt',
				ModelQueryOrderDirection.DESC,
				ModelQueryOrderSortAlgo.NUMERIC
			));
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateRecord.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error;
		},
		[String(createRecord.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdRecord = null;
		},
		[String(createRecord.fulfilled)]: (state, action: PayloadAction<CreateRecordInfo>) => {
			state.records.push(action.payload.createdRecord);

			for (const updatedRecord of action.payload.updatedRecords) {
				const updatedIndex = state.records.findIndex((entry): boolean => {
					return entry.Uuid === updatedRecord.Uuid;
				}) ?? null;
				if (updatedIndex !== null) {
					state.records[updatedIndex] = updatedRecord;
				}
			}

			state.records = state.records.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build(
				'RecordedAt',
				ModelQueryOrderDirection.DESC,
				ModelQueryOrderSortAlgo.NUMERIC
			));

			state.createdRecord = action.payload.createdRecord;
			state.actionStatus = AsyncReducerStatus.CREATED;

		},
		[String(createRecord.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error;
		},
		[String(deleteRecord.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteRecord.fulfilled)]: (state, action: PayloadAction<DeletedRecordInfo>) => {
			const deletedIndex = state.records.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.deletedRecord.Uuid;
			}) ?? null;
			if (deletedIndex !== null) {
				state.records.splice(deletedIndex, 1);
			}

			for (const updatedRecord of action.payload.updatedRecords) {
				const updatedIndex = state.records.findIndex((entry): boolean => {
					return entry.Uuid === updatedRecord.Uuid;
				}) ?? null;
				if (updatedIndex !== null) {
					state.records[updatedIndex] = updatedRecord;
				}
			}

			state.records = state.records.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build(
				'RecordedAt',
				ModelQueryOrderDirection.DESC,
				ModelQueryOrderSortAlgo.NUMERIC
			));

			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteRecord.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error;
		}
	}
});

// Export the reducer as default
export const { resetState, resetActionStatus } = recordsSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectRecordsBySequence = (
	sequenceViewModel: SequenceViewModel,
	excludeReplacedRecords = true
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		if ((sequenceViewModel ?? null) === null) {
			return [];
		}
		const storeModels = rootState.records.records.filter((storeModel): boolean => {
			// noinspection RedundantIfStatementJS
			if (
				storeModel?.Sequence !== sequenceViewModel.Uuid
				|| excludeReplacedRecords && storeModel.Replaced
			) {
				return false;
			}
			return true;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModelFromSequence(sequenceViewModel);
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectRecordsBySequenceWithPeriod = (
	sequenceViewModel: SequenceViewModel,
	dateStart?: Date,
	dateEnd?: Date,
	excludeReplacedRecords = true
): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	dateStart = dateStart ?? null;
	dateEnd = dateEnd ?? null;
	if (dateStart === null && dateEnd === null) {
		return selectRecordsBySequence(sequenceViewModel, excludeReplacedRecords);
	}
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		if ((sequenceViewModel ?? null) === null) {
			return [];
		}
		const dateStartTimestamp = dateStart?.getTime() ?? null;
		const dateEndTimestamp = dateEnd?.getTime() ?? null;
		const storeModels = rootState.records.records.filter((storeModel): boolean => {
			if (
				storeModel?.Sequence !== sequenceViewModel.Uuid
				|| excludeReplacedRecords && storeModel.Replaced
			) {
				return false;
			}
			// noinspection RedundantIfStatementJS
			if (
				dateStartTimestamp !== null && storeModel.RecordedAt < dateStartTimestamp
				|| dateEndTimestamp !== null && storeModel.RecordedAt > dateEndTimestamp
			) {
				return false;
			}
			return true;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModelFromSequence(sequenceViewModel);
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectLatestRecordBySequence = (
	sequenceViewModel: SequenceViewModel,
	excludeReplacedRecords = true
): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		if ((sequenceViewModel ?? null) === null) {
			return null;
		}
		const storeModels = rootState.records.records.filter((storeModel): boolean => {
			return storeModel?.Sequence === sequenceViewModel.Uuid
				&& storeModel.ModelSyncState !== ModelSyncState.DELETED;
		}) ?? [];
		let viewModels = storeModels.map((storeModel): ViewModel | null => {
			if (excludeReplacedRecords && storeModel.Replaced) {
				return null;
			}
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModelFromSequence(sequenceViewModel);
			} catch (error) {
				if (error instanceof ViolationError) {
					DebugConsole.debug(error.getViolations());
				}
				return null;
			}
		});
		viewModels = viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
		return viewModels.length > 0 ? viewModels[0] : null;
	};
};

export const selectInitialRecordBySequence = (
	sequenceViewModel: SequenceViewModel,
	excludeReplacedRecords = true
): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		if ((sequenceViewModel ?? null) === null) {
			return null;
		}
		const storeModels = rootState.records.records.filter((storeModel): boolean => {
			return storeModel?.Sequence === sequenceViewModel.Uuid
				&& storeModel.ModelSyncState !== ModelSyncState.DELETED;
		}) ?? [];

		let viewModels = storeModels.map((storeModel): ViewModel | null => {
			if (excludeReplacedRecords && storeModel.Replaced) {
				return null;
			}
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModelFromSequence(sequenceViewModel);
			} catch (error) {
				if (error instanceof ViolationError) {
					DebugConsole.debug(error.getViolations());
				}
				return null;
			}
		});
		viewModels = viewModels.filter((viewModel) => {
			return viewModel !== null;
		});

		return viewModels.length > 0 ? viewModels[viewModels.length - 1] : null;
	};
};

export const selectRecordByUuid = (sequenceViewModel: SequenceViewModel, uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.records.records.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModelFromSequence(sequenceViewModel);
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedRecord = (sequenceViewModel: SequenceViewModel): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.records.createdRecord;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModelFromSequence(sequenceViewModel);
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};
