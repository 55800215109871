import { TagType } from 'presentation/ui/partials/tag/Tag';
import { Valuation } from 'services/device/domain/business/common/Valuation';

export class ValuationConverter {

	public static toTagType(valuation: Valuation): TagType {
		switch (valuation) {
			case Valuation.ACCEPTABLE:
				return TagType.POSITIVE;
			case Valuation.NOTICEABLE:
				return TagType.WARN;
			case Valuation.UNACCEPTABLE:
				return TagType.NEGATIVE;
			case Valuation.UNKNOWN:
				return TagType.INFO;
			default:
				return TagType.NEUTRAL;
		}
	}

	public static toL10nKey(valuation: Valuation): string {
		return 'inventory.valuation.' + String(valuation).toLowerCase();
	}

}
