import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { DeviceUpdateForm } from 'services/device/presentation/ui/device-action/device-update-form/DeviceUpdateForm';
import { DeviceUpdateFailedNotification } from 'services/device/presentation/ui/device-action/device-update-modal/DeviceUpdateFailedNotification';
import { DeviceUpdateSuccessNotification } from 'services/device/presentation/ui/device-action/device-update-modal/DeviceUpdateSuccessNotification';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface DeviceUpdateModalProps {
	device: DeviceViewModel
	onDismiss?: () => void;
}

export const DeviceUpdateModal = (props: DeviceUpdateModalProps): JSX.Element => {
	const { device, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const deviceActionStatus = useTypedSelector(state => state.devices.actionStatus);

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (deviceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent = <DeviceUpdateForm device={device} />;
				break;

			case AsyncReducerStatus.UPDATED:
				modalContent = <DeviceUpdateSuccessNotification onClickConfirm={onDismiss} />;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <DeviceUpdateFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.device.modal.action.edit.caption', 'Gerät bearbeiten')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
