import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutHeader } from 'presentation/ui/layouts/form-layout/form-layout-header/FormLayoutHeader';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutModifier } from 'presentation/ui/layouts/form-layout/FormLayoutModifier';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { InputText, InputTextStatus } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { CreatableCyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';
import { getCyclotronProductIsotopeDefinition } from 'services/nuclide/domain/model/isotopes/NuclideIsotopeDefinition';
import { getCyclotronProductMeasurementUnitDefinition } from 'services/nuclide/domain/model/NuclideMeasurementUnitDefinition';

export interface CyclotronProductCreateFormStep2Props {
	/**
	 * Cyclotron product
	 */
	cyclotronProduct: CreatableCyclotronProductViewModel;
	/**
	 * Button back action
	 */
	onClickPrevious: () => void;
	/**
	 * Button confirm
	 */
	onClickConfirm?: () => void;
}

/**
 * The cyclotron product create modal step 2 component
 */
export const CyclotronProductCreateFormStep2 = (props: CyclotronProductCreateFormStep2Props): JSX.Element => {
	const { cyclotronProduct, onClickPrevious, onClickConfirm } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	const calibrationDatetimeFormatted = l10nContext.formatDateTime(cyclotronProduct?.CalibrationDatetime);
	const deliveryDateFormatted = l10nContext.formatDateTime(cyclotronProduct?.DeliveryDate);

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			modifier={FormLayoutModifier.BLUE_BACKGROUND}
			onSubmit={onClickConfirm}
		>
			<FormLayoutHeader
				text={l10nContext.translate('common.modal.checkDetails', 'Überprüfen Sie bitte Ihre Angaben')}
				icon={IconIdentifier.WARNING}
			/>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.name', 'Name')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={cyclotronProduct?.Name ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.manufacturer', 'Hersteller')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={cyclotronProduct?.Manufacturer ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.batchNumber', 'Chargennummer')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={cyclotronProduct?.BatchNumber ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.isotope', 'Isotop')}*`}
					thin
				>
					<InputText
						type="text"
						value={l10nContext.translate(getCyclotronProductIsotopeDefinition(cyclotronProduct.Isotope).getLabelKey())}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.radiopharmaceutical', 'Radiopharmakon')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={cyclotronProduct?.Radiopharmaceutical ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.deliveryDate', 'Lieferdatum')}*`}
					thin
				>
					<InputText
						type='text'
						value={deliveryDateFormatted}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.nominalActivity', 'Nominalaktivität')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={l10nContext.formatNumber(cyclotronProduct?.NominalActivity) ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.activityUnit', 'Einheit')}*`}
					thin
				>
					<InputText
						type='text'
						value={l10nContext.translate(getCyclotronProductMeasurementUnitDefinition(cyclotronProduct.ActivityUnit).getLabelKey())}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('cyclotronProducts.calibrationDateTime', 'Kalibrierzeitpunkt')}*`}
					thin
				>
					<InputText
						type='text'
						value={calibrationDatetimeFormatted}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.back', 'Zurück')}
							onClick={onClickPrevious}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('view.cyclotronProducts.modal.action.create.caption', 'Radionuklid hinzufügen')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
