import {
	BooleanOutput, CommentOutput,
	DateTimeOutput,
	IntervalOutput,
	MailAddressOutput,
	NumberOutput,
	OutputSelection,
	StringOutput,
	TextOutput,
	UnitOutput,
	UrlOutput, ValuedStringOutput,
} from 'services/device/domain/business/common/Output';
import { BooleanOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/BooleanOutputRenderer';
import { CommentOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/CommentOutputRenderer';
import { DateTimeOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/DateTimeOutputRenderer';
import { IntervalOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/IntervalOutputRenderer';
import { MailAddressesOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/MailAddressesOutputRenderer';
import { NumberOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/NumberOutputRenderer';
import { StringOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/StringOutputRenderer';
import { TextOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/TextOutputRenderer';
import { UnitOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/UnitOutputRenderer';
import { UrlOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/UrlOutputRenderer';
import { ValuedStringOutputRenderer } from 'services/device/presentation/ui/widget/output-renderer/ValuedStringOutputRenderer';

export class OutputWidgetSelection implements OutputSelection<void> {
	private readonly onElement: (element: JSX.Element) => void;

	public constructor(onElement: (element: JSX.Element) => void) {
		this.onElement = onElement;
	}

	onUnitOutput(output: UnitOutput): void {
		this.onElement(
			<UnitOutputRenderer key={output.getName()} output={output} />
		);
	}

	onDateTimeOutput(output: DateTimeOutput): void {
		this.onElement(
			<DateTimeOutputRenderer key={output.getName()} output={output} />
		);
	}

	onStringOutput(output: StringOutput): void {
		this.onElement(
			<StringOutputRenderer key={output.getName()} output={output} />
		);
	}

	onNumberOutput(output: NumberOutput): void {
		this.onElement(
			<NumberOutputRenderer key={output.getName()} output={output} />
		);
	}

	onBooleanOutput(output: BooleanOutput): void {
		this.onElement(
			<BooleanOutputRenderer key={output.getName()} output={output} />
		);
	}

	onMailAddressOutput(output: MailAddressOutput): void {
		this.onElement(
			<MailAddressesOutputRenderer key={output.getName()} output={output} />
		);
	}

	onIntervalOutput(output: IntervalOutput): void {
		this.onElement(
			<IntervalOutputRenderer key={output.getName()} output={output} />
		);
	}

	onTextOutput(output: TextOutput): void {
		this.onElement(
			<TextOutputRenderer key={output.getName()} output={output} />
		);
	}

	onUrlOutput(output: UrlOutput): void {
		this.onElement(
			<UrlOutputRenderer key={output.getName()} output={output} />
		);
	}

	onCommentOutput(output: CommentOutput): void {
		this.onElement(
			<CommentOutputRenderer key={output.getName()} output={output} />
		);
	}

	onValuedStringOutput(output: ValuedStringOutput): void {
		this.onElement(
			<ValuedStringOutputRenderer key={output.getName()} output={output} />
		);
	}

}
