import merge from 'lodash/merge';

export type LiteralStruct = Record<string, any>;

export class Locale {

	public readonly identifier: string;
	private readonly literals: Record<string, string>;

	constructor(identifier: string, literals: Array<LiteralStruct>) {
		this.identifier = identifier;
		const mergedLiterals = {};
		merge(mergedLiterals, ...literals);
		this.literals = this.flatten(mergedLiterals);
	}

	public translate(literal: string, defaultValue: string = null): string | null {
		if (!(literal in this.literals)) {
			return defaultValue;
		}
		return this.literals[literal];
	}

	private flatten(literals: { [key: string]: any }, keyChain: Array<string> = []): Record<string, string> {
		let flattened: Record<string, string> = {};
		Object.keys(literals).forEach((key: string) => {
			if (typeof literals[key] === 'object' && literals[key] !== null) {
				flattened = { ...flattened, ...this.flatten(literals[key], [...keyChain, key]) };
			} else {
				flattened[[...keyChain, key].join('.')] = String(literals[key]);
			}
		});
		return flattened;
	}

}
