import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { FolderViewModel } from 'services/documents/domain/model/FolderModel';
import { FolderModelState } from 'services/documents/domain/model/FolderModelState';

import { CardDocumentFolder } from 'presentation/ui/components/cards/card-document/card-document-folder/CardDocumentFolder';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { FilterChipSet } from 'presentation/ui/components/filter/filter-chip-set/FilterChipSet';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { CardCollectionLayoutHeader } from 'presentation/ui/layouts/card-collection-layout/card-collection-layout-header/CardCollectionLayoutHeader';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { CardItemFolderControls } from 'presentation/ui/partials/card/card-item-controls/card-item-folder-controls/CardItemFolderControls';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';
import { LoadingSpinnerEmptyCard } from '../../../../../../presentation/ui/components/loading-spinner-empty-card/LoadingSpinnerEmptyCard.';

export interface FolderCollectionProps {
	folders: ReadonlyArray<FolderViewModel>;
	onAddClick?: () => void;
	onFilterClick?: (id: string, state: boolean) => void;
	fetchStatus?: boolean;
}

export const FolderCollection = (props: FolderCollectionProps): JSX.Element => {
	const { folders, onAddClick, onFilterClick, fetchStatus } = props;

	const l10nContext = useContext(L10nContext);

	const history = useHistory();

	const onFolderCardClick = (payload: CardItemControlActionType) => {
		history.push({
			pathname: `${Route.DOCUMENTS}/${payload.cardId}`,
			state: {
				folderID: payload.cardId
			}
		});
	};

	const filterToggle =
		<FilterChipSet
			label={l10nContext.translate('common.filter.status', 'Status')}
			chips={[
				{
					label: l10nContext.translate('common.filter.active', 'aktiv'),
					id: 'active',
					active: true,
					onClick: onFilterClick ?? null
				},
				{
					label: l10nContext.translate('common.filter.archived', 'archiviert'),
					id: 'archived',
					onClick: onFilterClick ?? null
				}
			]}
		/>;

	const expandableHeader =
		<ExpandableHeader caption={l10nContext.translate('view.documents.collection.folder.headline', 'Ordner')}>
			{
				onAddClick ?
					<ButtonPrimary
						buttonText={l10nContext.translate('view.documents.collection.folder.addButton', 'Ordner hinzufügen')}
						icon={IconIdentifier.ADD}
						onClick={onAddClick ?? null}
					/> :
					null
			}
		</ExpandableHeader>;

	const renderFolderCards = (): JSX.Element => {
		if (fetchStatus) {
			return (
				<CardEmpty>
					<LoadingSpinnerEmptyCard />
				</CardEmpty>
			);
		}

		if (folders?.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.folder', 'kein Ordner wurde hinzugefügt')} />
			);
		}

		return (
			<>
				{
					folders?.map((folder: FolderViewModel) => (
						<CardDocumentFolder
							key={folder.Uuid}
							cardId={folder.Uuid}
						>
							<CardItemHeader
								title={folder.Name}
								illustration={IllustrationIdentifier.ORDNER_PLACE}
								archived={folder.State === FolderModelState.ARCHIVED}
							/>
							<CardItemFolderControls
								cardId={folder.Uuid}
								actionDetails={CardItemControlState.ENABLED}
								onClick={onFolderCardClick}
							/>
						</CardDocumentFolder>
					))
				}
			</>
		);
	};

	return (
		<Expandable firstElement={true} expanded={folders?.length > 0}>

			{expandableHeader}

			<ExpandableBody>
				<CardCollectionLayout>
					<CardCollectionLayoutHeader>

						{filterToggle}

					</CardCollectionLayoutHeader>

					{renderFolderCards()}
				</CardCollectionLayout>
			</ExpandableBody>
		</Expandable>
	);
};
