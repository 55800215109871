import { useContext, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';

import { RecordViewModel } from 'services/device/domain/model/RecordModel';

import { RecordDocumentCollection } from 'services/device/presentation/ui/card-collections/record-document-collection/RecordDocumentCollection';
import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentAddForm } from 'services/documents/presentation/ui/document-action/document-add-form/DocumentAddForm';
import { DocumentAddFailedNotification } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddFailedNotification';
import { DocumentAddPendingNotification } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddPendingNotification';
import { DocumentAddSuccessNotification } from 'services/documents/presentation/ui/document-action/document-add-modal/DocumentAddSuccessNotification';

import { DocumentDownloadModalContent } from 'services/documents/presentation/ui/document-action/document-download-modal/DocumentDownloadModalContent';
import { deleteDocument, fetchDocumentsByRecord, resetActionStatus, selectDocumentsByRecord } from 'services/documents/store/documentSlice';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

export interface RecordDocumentsModalContentProps {
	record: RecordViewModel;
	onDismiss?: () => void;
}

enum ModalContent {
	DOCUMENTS,
	UPLOAD,
	DOWNLOAD
}

export const RecordDocumentsModalContent = (props: RecordDocumentsModalContentProps): JSX.Element => {
	const recordViewModel = props.record;

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const dispatch = useDispatch();

	// fetch documents
	useEffect(() => {
		dispatch(fetchDocumentsByRecord({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			recordUuid: recordViewModel.Uuid
		}));
	}, [clientContext.selectedClientUuid, dispatch, facilityContext.selectedFacilityUuid, recordViewModel.Uuid]);

	const documentViewModels = useSelector(
		selectDocumentsByRecord(clientContext.selectedClientUuid, facilityContext.selectedFacilityUuid, recordViewModel.Uuid)
	);

	const [modalContent, setModalContent] = useState<ModalContent>(ModalContent.DOCUMENTS);
	const [modalPayload, setModalPayload] = useState<DocumentViewModel>(null);
	const uploadedFiles = useRef<Array<File>>([]);

	const documentActionStatus = useTypedSelector(state => state.documents.actionStatus);

	const handleDocumentDelete = (documentViewModel: DocumentViewModel): void => {
		if (documentActionStatus !== AsyncReducerStatus.DELETE_PENDING) {
			dispatch(resetActionStatus());
		}
		dispatch(deleteDocument(documentViewModel));
	};

	const handleDocumentDownload = (documentViewModel: DocumentViewModel): void => {
		setModalPayload(documentViewModel);
		setModalContent(ModalContent.DOWNLOAD);
	};

	const renderModalContent = (): JSX.Element | null => {
		switch (modalContent) {
			case ModalContent.DOCUMENTS:
				return (
					<RecordDocumentCollection
						recordDocuments={documentViewModels}
						onAddDocument={() => {
							dispatch(resetActionStatus());
							setModalContent(ModalContent.UPLOAD);
						}}
						onAction={(payload, document) => {
							switch (payload.type) {
								case 'DELETE':
									handleDocumentDelete(document);
									break;
								case 'DOWNLOAD':
									handleDocumentDownload(document);
									break;
							}
						}}
					/>
				);

			case ModalContent.DOWNLOAD:
				return (
					<DocumentDownloadModalContent
						document={modalPayload as DocumentViewModel}
						onDismiss={() => {
							setModalContent(ModalContent.DOCUMENTS);
						}}
						onClickPrevious={() => {
							setModalContent(ModalContent.DOCUMENTS);
						}}
					/>
				);

			case ModalContent.UPLOAD:

				switch (documentActionStatus) {
					case AsyncReducerStatus.CREATED:
						return (
							<DocumentAddSuccessNotification
								files={uploadedFiles.current}
								documentScope={DocumentModelScope.RECORD}
								documentScopeReference={recordViewModel?.Uuid}
								onClickConfirm={() => {
									setModalContent(ModalContent.DOCUMENTS);
								}}
							/>
						);
					case AsyncReducerStatus.FAILED:
						return (
							<DocumentAddFailedNotification
								onDismiss={() => {
									setModalContent(ModalContent.DOCUMENTS);
								}}
							/>
						);
					case AsyncReducerStatus.IDLE:
						return (
							<DocumentAddForm
								onChange={(selectedFiles) => {
									uploadedFiles.current = selectedFiles;
								}}
								documentScope={DocumentModelScope.RECORD}
								documentScopeReference={recordViewModel.Uuid}
								onClickPrevious={() => {
									setModalContent(ModalContent.DOCUMENTS);
								}}
							/>
						);
					case AsyncReducerStatus.CREATE_PENDING:
						return <DocumentAddPendingNotification />;
				}
		}
		return null;
	};

	return (
		<>
			{renderModalContent()}
		</>
	);
};
