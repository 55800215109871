import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DebugConsole } from 'lib/debug/DebugConsole';

import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import {
	WasteManagementReportCyclotronProductModelConverter as ModelConverter,
	WasteManagementReportCyclotronProductStoreModel as StoreModel,
	WasteManagementReportCyclotronProductViewModel as ViewModel
} from '../../waste-management-report/domain/models/WasteManagementReportCyclotronProductModel';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import {
	ExternalWasteManagementReportCyclotronProductHttpPersistence
} from '../persistence/ExternalWasteManagementReportCyclotronProductHttpPersistence';

export interface ExternalWasteManagementReportCyclotronProductState {
	externalWasteManagementReportCyclotronProducts: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	externalWasteManagementReportCyclotronProducts: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ExternalWasteManagementReportCyclotronProductState;

// Implementation of the async actions
export const fetchExternalWasteManagementReportCyclotronProducts = createAsyncThunk(
	'externalWasteManagementReportCyclotronProducts/fetch',
	async (params: { reportUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new ExternalWasteManagementReportCyclotronProductHttpPersistence(params.reportUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Silently abort the action
			const { externalWasteManagementReportCyclotronProducts } = getState() as RootState;
			return checkFetchStatus(externalWasteManagementReportCyclotronProducts.fetchStatus);
		}
	}
);

// Slice definition
export const externalWasteManagementReportCyclotronProductsSlice = createSlice({
	name: 'externalWasteManagementReportCyclotronProducts',
	initialState,
	// Regular syncronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	extraReducers: {
		[String(fetchExternalWasteManagementReportCyclotronProducts.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalWasteManagementReportCyclotronProducts.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.externalWasteManagementReportCyclotronProducts = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalWasteManagementReportCyclotronProducts.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

export const { resetState, resetActionStatus } = externalWasteManagementReportCyclotronProductsSlice.actions;

// Export the reducer as default
export default externalWasteManagementReportCyclotronProductsSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectExternalWasteManagementReportCyclotronProducts = (): (rootSTate: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels =
			rootState.externalWasteManagementReportCyclotronProducts.externalWasteManagementReportCyclotronProducts.filter((storeModel):
			StoreModel => {
				return storeModel;
			}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalWasteManagementReportCyclotronProductByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.externalWasteManagementReportCyclotronProducts.externalWasteManagementReportCyclotronProducts.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};
