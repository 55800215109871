import { MessageLevel } from './MessageLevel';

export class Message {
	private readonly level: MessageLevel;

	private readonly messageKey: string;

	constructor(level: MessageLevel, messageKey: string) {
		this.level = level;
		this.messageKey = messageKey;
	}

	public getLevel(): MessageLevel {
		return this.level;
	}

	public getMessageKey(): string {
		return this.messageKey;
	}
}
