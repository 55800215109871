import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Guid } from '../../../../lib/guid/Guid';
import { LoadingSpinner } from '../../../../presentation/ui/components/loading-spinner/LoadingSpinner';
import { NotificationBar } from '../../../../presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from '../../../../presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from '../../../../presentation/ui/components/notification-bars/NotificationBars';
import { MainLayout } from '../../../../presentation/ui/layouts/main-layout/MainLayout';
import { ViewLayoutSection } from '../../../../presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { AsyncFetchStatus } from '../../../../store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from '../../../../store/common/AsyncReducerStatus';
import { useTypedSelector } from '../../../../store/common/TypedSelector';
import { AuthContext } from '../../../core/context/AuthContext';
import { permissionGroupWasteManagementReport } from '../../../core/lib/auth/AuthService';
import { resetActionStatus } from '../../store/wasteManagementReportSlice';
import { WasteManagementReportCollection } from '../ui/card-collections/waste-management-report-collection/WasteManagementReportCollection';
import {
	WasteManagementCyclotronReportCreateModal
} from '../ui/waste-management-report-action/waste-management-cyclotron-report-create-modal/WasteManagementCyclotronReportCreateModal';
import {
	WasteManagementGeneratorReportCreateModal
} from '../ui/waste-management-report-action/waste-management-generator-report-create-modal/WasteManagementGeneratorReportCreateModal';
import { WasteManagementReportViewModalType } from './WasteManagementReportViewModalType';

/**
 * The waste reports view
 */

export const WasteManagementReportsView = (): JSX.Element => {

	// Consume the dispatch object
	const dispatch = useDispatch();

	const authContext = useContext(AuthContext);

	// Local state
	const [modalType, setModalType] = useState<WasteManagementReportViewModalType>(null);

	// Use a custom selector function to provide type information for the state defined in the slice
	const storeFetchStatus = useTypedSelector(state => state.wasteManagementReports.fetchStatus);
	const storeActionStatus = useTypedSelector(state => state.wasteManagementReports.actionStatus);
	const storeLastActionError = useTypedSelector(state => state.wasteManagementReports.lastActionError);

	if (!authContext.hasAnyPermission(permissionGroupWasteManagementReport)) {
		throw new Error('Permission denied');
	}

	const renderNotifications = (): Array<JSX.Element> => {
		const notifications = [];
		if (storeActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					key={Guid.generate()}
					message={storeLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}
		return notifications;
	};

	// Handle close modal for success and failure
	const handleDismiss = () => {
		setModalType(null);
	};

	if (storeFetchStatus === AsyncFetchStatus.IDLE || storeFetchStatus === AsyncFetchStatus.PENDING) {
		return (
			<LoadingSpinner />
		);
	}

	const showModal = (): JSX.Element => {
		let reportModal: JSX.Element = null;

		switch (modalType) {
			case WasteManagementReportViewModalType.CREATE_GENERATOR_REPORT:
				reportModal =
					<WasteManagementGeneratorReportCreateModal
						onDismiss={handleDismiss}
					/>;
				break;

			case WasteManagementReportViewModalType.CREATE_CYCLOTRON_PRODUCT_REPORT:
				reportModal =
					<WasteManagementCyclotronReportCreateModal
						onDismiss={handleDismiss}
					/>;
				break;
		}

		return reportModal;
	};

	const handleModalClick = (type: WasteManagementReportViewModalType) => {
		dispatch(resetActionStatus());

		setModalType(type);
	};

	return (
		<MainLayout>
			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>
					<ViewLayoutSection>
						{showModal()}
					</ViewLayoutSection>
					<ViewLayoutSection>
						<WasteManagementReportCollection
							isGeneratorReport={true}
							onModalClick={() => handleModalClick(WasteManagementReportViewModalType.CREATE_GENERATOR_REPORT)}
						/>
					</ViewLayoutSection>
					<ViewLayoutSection>
						<WasteManagementReportCollection
							isGeneratorReport={false}
							onModalClick={() => handleModalClick(WasteManagementReportViewModalType.CREATE_CYCLOTRON_PRODUCT_REPORT)}
						/>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>
		</MainLayout>
	);

};
