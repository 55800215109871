import { ReactElement } from 'react';

import './Tabbar.scss';

export type TabBarProps = {
	children: ReactElement | ReactElement[];
};

export function TabBar(props: TabBarProps): JSX.Element {
	const { children } = props;

	return (
		<div className="tab-bar">
			{children}
		</div>
	);
}
