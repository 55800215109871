import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutHeader } from 'presentation/ui/layouts/form-layout/form-layout-header/FormLayoutHeader';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutModifier } from 'presentation/ui/layouts/form-layout/FormLayoutModifier';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { InputText, InputTextStatus } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { CreatableGeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { getGeneratorMeasurementUnitDefinition } from 'services/nuclide/domain/model/NuclideMeasurementUnitDefinition';
import { getGeneratorTypeIsotopeDefinition } from '../../../../domain/model/isotopes/NuclideIsotopeDefinition';

export interface GeneratorCreateFormStep2Props {
	/**
	 * Generator
	 */
	generator: CreatableGeneratorViewModel;
	/**
	 * Button back action
	 */
	onClickPrevious: () => void;
	/**
	 * Button confirm
	 */
	onClickConfirm?: () => void;
}

/**
 * The generator create modal step 2 component
 */
export const GeneratorCreateFormStep2 = (props: GeneratorCreateFormStep2Props): JSX.Element => {
	const { generator, onClickPrevious, onClickConfirm } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	const calibrationDatetimeFormatted = l10nContext.formatDateTime(generator?.CalibrationDatetime);
	const deliveryDateFormatted = l10nContext.formatDateTime(generator?.DeliveryDate);

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			modifier={FormLayoutModifier.BLUE_BACKGROUND}
			onSubmit={onClickConfirm}
		>
			<FormLayoutHeader
				text={l10nContext.translate('common.modal.checkDetails', 'Überprüfen Sie bitte Ihre Angaben')}
				icon={IconIdentifier.WARNING}
			/>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.name', 'Name')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={generator?.Name ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.isotope', 'Isotop')}*`}
					thin
				>
					<InputText
						type="text"
						value={l10nContext.translate(getGeneratorTypeIsotopeDefinition(generator.Isotope).getLabelKey())}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.manufacturer', 'Hersteller')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={generator?.Manufacturer ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.batchNumber', 'Chargennummer')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={generator?.BatchNumber ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.serialNumber', 'Seriennummer')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={generator?.SerialNumber ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.deliveryDate', 'Lieferdatum')}*`}
					thin
				>
					<InputText
						type='text'
						value={deliveryDateFormatted}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.nominalActivity', 'Nominalaktivität')}*`}
					thin
				>
					<InputText
						type="text"
						defaultValue={l10nContext.formatNumber(generator?.NominalActivity) ?? ''}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.unit', 'Einheit')}*`}
					thin
				>
					<InputText
						type='text'
						value={l10nContext.translate(getGeneratorMeasurementUnitDefinition(generator.ActivityUnit).getLabelKey())}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label
					label={`${l10nContext.translate('generators.calibrationDateTime', 'Kalibrierzeitpunkt')}*`}
					thin
				>
					<InputText
						type='text'
						value={calibrationDatetimeFormatted}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.back', 'Zurück')}
							onClick={onClickPrevious}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('view.generators.modal.action.create.caption', 'Generator hinzufügen')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
