import { useContext, useRef, useState } from 'react';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';

import { useDispatch, useSelector } from 'react-redux';

import { createContainer } from 'services/nuclide/store/containerSlice';
import { CreatableContainerViewModel } from 'services/nuclide/domain/model/ContainerModel';
import { selectInWastemanagementCyclotronProducts } from '../../../../store/cyclotronProductSlice';
import { selectInWastemanagementEluates } from '../../../../store/eluateSlice';
import { SingleNuclidesViewContext } from '../../../view/SingleNuclidesViewContext';
import { ContainerCreateFormStep1 } from './ContainerCreateFormStep1';
import { ContainerCreateFormStep2 } from './ContainerCreateFormStep2';

interface ContainerCreateFormProps {
	selectedNuclides: Array<string>
}

export const ContainerCreateForm = (props: ContainerCreateFormProps): JSX.Element => {
	const { selectedNuclides } = props;

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const viewContext = useContext(SingleNuclidesViewContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const newContainerViewModel = useRef<CreatableContainerViewModel>({} as CreatableContainerViewModel);

	const inWastemanagementCyclotronProducts = useSelector(selectInWastemanagementCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementEluates = useSelector(selectInWastemanagementEluates());

	const selectedCyclotronProducts = inWastemanagementCyclotronProducts.filter(cyclotronProduct => selectedNuclides.indexOf(cyclotronProduct.Uuid) !== -1);
	const selectedEluates = inWastemanagementEluates.filter(eluate => selectedNuclides.indexOf(eluate.Uuid) !== -1);

	const onChangeContainerProp = (prop: string, value: any) => {
		newContainerViewModel.current[prop] = value;
	};

	// ToDo: Add CyclotronProductIds and GeneratorEluatIds
	const handleCreate = (): void => {

		newContainerViewModel.current.Client = clientContext.selectedClientUuid;
		newContainerViewModel.current.Facility = facilityContext.selectedFacilityUuid;
		newContainerViewModel.current.CyclotroneProductIds = selectedCyclotronProducts.map(element => element.Uuid);
		newContainerViewModel.current.GeneratorEluatIds = selectedEluates.map(element => element.Uuid);

		viewContext.setSelectedNuclides([]);
		dispatch(createContainer(newContainerViewModel.current));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const requiredInputs = ['Name'];

	const goToStep2 = () => {
		let newContainerModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(newContainerViewModel.current?.[requiredInput] ?? null) === null
				|| newContainerViewModel.current?.[requiredInput] === ''
				|| String(newContainerViewModel.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newContainerModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newContainerModelValid);
	};

	const showCreateModalStep = showStep1 === true ?
		<ContainerCreateFormStep1
			container={newContainerViewModel.current}
			onClickNext={goToStep2}
			onChangeContainerProp={onChangeContainerProp}
			invalidInputs={invalidInputs}
		/> :
		<ContainerCreateFormStep2
			container={newContainerViewModel.current}
			onClickPrevious={goToStep1}
			onClickConfirm={handleCreate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
