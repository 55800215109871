import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';
import { ExternalWasteManagementReportHttpConnector } from './ExternalWasteManagementReportHttpConnector';

export abstract class ExternalWasteReportHttpPersistence<PModel extends PersistenceModel,
	SModel extends StoreModel,
	VModel extends ViewModel,
	CVModel extends CreatableViewModel> {

	protected abstract serviceApiControllerUri: string;
	protected abstract collectionControllerUri: string;
	protected abstract entityControllerUri: string;

	protected abstract responseCollectionKey: string;
	protected abstract responseEntityKey: string;

	protected abstract modelConverter: ModelConverter<PModel, SModel, VModel, CVModel>;

	private readonly connector: ExternalWasteManagementReportHttpConnector;

	public constructor(reportUuid: string) {
		this.connector = new ExternalWasteManagementReportHttpConnector(reportUuid);
	}

	public async fetchCollection(endpointBuilder?: () => string): Promise<Array<SModel>> {
		let endpoint: string;
		if (endpointBuilder !== undefined) {
			endpoint = endpointBuilder();
		} else {
			endpoint = process.env.REACT_APP_API_BASE_URL + this.serviceApiControllerUri + '/'
				+ this.collectionControllerUri;
		}
		const parsedBody = await this.connector.get(endpoint);
		const persistenceModelCollection = parsedBody[this.responseCollectionKey] as Array<PModel>;
		return persistenceModelCollection.map((persistenceModel): SModel => {
			return this.modelConverter.fromPersistenceModel(persistenceModel).toStoreModel();
		});
	}

	public async fetch(uuid: string): Promise<SModel | null> {
		const endpoint = process.env.REACT_APP_API_BASE_URL + this.serviceApiControllerUri + '/'
			+ this.entityControllerUri + '/' + uuid;
		const parsedBody = await this.connector.get(endpoint);
		const persistenceModel = parsedBody[this.responseEntityKey] as PModel;
		return this.modelConverter.fromPersistenceModel(persistenceModel).toStoreModel();
	}

}
