import { L10nContext } from 'context/L10nContext';
import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { EluateUpdateForm } from '../eluate-update-form/EluateUpdateForm';
import { EluateUpdateFailedNotification } from './EluateUpdateFailedNotification';
import { EluateUpdateSuccessNotification } from './EluateUpdateSuccessNotification';

interface EluateUpdateModalProps {
	/**
	 * Eluate
	 */
	eluate: EluateViewModel;
	/**
	 * onDismiss
	 */
	onDismiss: () => void;
}

/**
 * The eluate create modal
 */
export const EluateUpdateModal = (props: EluateUpdateModalProps): JSX.Element => {
	const { eluate, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const eluateActionStatus = useTypedSelector(state => state.eluates.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (eluateActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = 
					<EluateUpdateForm
						eluate={eluate}
					/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modal =
					<EluateUpdateSuccessNotification
						onClickConfirm={onDismiss}
						eluateUpdated={eluate}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal = 
					<EluateUpdateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.eluates.modal.action.update.caption', 'Eluat-Entnahme bearbeiten')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
