import { PublicLocalStorageKey } from 'lib/browser-storage/PublicLocalStorageKey';
import { LocalStorage } from 'lib/browser-storage/LocalStorage';

export class PublicLocalStorage extends LocalStorage<PublicLocalStorageKey> {

	private static instance: PublicLocalStorage = null;

	// eslint-disable-next-line no-useless-constructor
	private constructor() {
		super();
	}

	public static get(): PublicLocalStorage {
		if (this.instance === null) {
			this.instance = new PublicLocalStorage();
		}
		return this.instance;
	}

	protected buildKey(key: PublicLocalStorageKey): string {
		return 'public_' + (key as string);
	}

}
