import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { FolderViewModel } from 'services/documents/domain/model/FolderModel';
import { FolderModelState } from 'services/documents/domain/model/FolderModelState';
import { FolderArchiveForm } from 'services/documents/presentation/ui/folder-action/folder-archive-form/FolderArchiveForm';
import { FolderArchiveFailedNotification } from 'services/documents/presentation/ui/folder-action/folder-archive-modal/FolderArchiveFailedNotification';
import { updateFolder } from 'services/documents/store/folderSlice';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';

export interface FolderArchiveModalProps {
	folder: FolderViewModel;
	onDismiss?: () => void;
}

export const FolderArchiveModal = (props: FolderArchiveModalProps): JSX.Element => {
	const { folder, onDismiss } = props;

	const folderActionStatus = useTypedSelector(state => state.folders.actionStatus);

	const dispatch = useDispatch();

	useEffect(() => {
		if (folderActionStatus === AsyncReducerStatus.UPDATED) {
			onDismiss();
		}
	}, [folderActionStatus, onDismiss]);

	const handleUpdate = (): void => {
		dispatch(updateFolder({
			...folder,
			State: FolderModelState.ARCHIVED
		}));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (folderActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<FolderArchiveForm
						folder={folder}
						onUpdate={handleUpdate}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <FolderArchiveFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
