/* eslint-disable max-classes-per-file */
import { Interval } from '../../inventory/Interval';
import { Unit } from '../../inventory/Unit';
import { Viewables, Storable, Viewable } from '../Data';
import {
	BooleanFieldDescription,
	CommentFieldDescription,
	DateTimeFieldDescription,
	FieldDescription,
	IntervalFieldDescription,
	MailAddressFieldDescription,
	NumberFieldDescription,
	StringFieldDescription,
	TextFieldDescription,
	UnitFieldDescription,
	UrlFieldDescription,
	ValuedStringFieldDescription
} from '../description/FieldDescription';
import {
	BooleanInput,
	CommentInput,
	DateTimeInput,
	Input,
	InputSelection,
	IntervalInput,
	MailAddressInput,
	NumberInput,
	StringInput,
	TextInput,
	UnitInput,
	UrlInput,
	ValuedStringInput
} from '../Input';
import { Action } from '../misc/Action';
import { Environment } from '../misc/Environment';
import { Optional } from '../misc/Optional';
import { isEnumValue } from '../misc/Util';
import { Validator } from '../misc/Validator';
import { ValuedString } from '../misc/ValuedString';
import { OutputSelection } from '../Output';
import { Valuation } from '../Valuation';
import { Calculation } from '../value/Calculation';
import { Violation } from '../violation/Violation';

export interface Field<Context, Payload> extends Input<Payload> {
	preCalculateOrEnter(context: Context, environment: Environment): void;

	calculate(context: Context): void;

	postCalculateOrEnter(context: Context): void;

	toStorable(strict: boolean): Storable;

	fromStoreable(storable: Storable): void;

	toViewable(strict: boolean): { value: Viewable; valuation: Valuation; extras: Viewables<Viewable> };

	fromViewable(value: Viewable, valuation: Valuation, extras: Viewables<Viewable>): void;

	validate(): ReadonlyArray<Violation>;

	copy(other: this): void;
}

// eslint-disable-next-line max-len
export abstract class AbstractField<Context, Payload, Description extends FieldDescription, Calculation extends FieldCalculation<Context, Payload, Description>>
	implements Field<Context, Payload>
{
	private readonly description: Description;

	private readonly calculation: Calculation;

	private readonly validator: Validator<Payload>;

	private default: Optional<Payload>;

	private options: Optional<ReadonlyArray<Payload>>;

	private mandatory: boolean;

	private values: ReadonlyArray<Payload>;

	private valuation: Valuation;

	protected constructor(calculation: Calculation, validator: Validator<Payload>) {
		this.description = calculation.description;
		this.calculation = calculation;
		this.validator = validator;
		this.default = null;
		this.options = null;
		this.mandatory = this.description.mandatory ?? false;
		this.values = [];
		this.valuation = Valuation.UNKNOWN;
	}

	public getName(): string {
		return this.description.name;
	}

	public getLabelKey(): string {
		return this.description.labelKey;
	}

	public getDescription(): Description {
		return this.description;
	}

	public getDefault(): Optional<Payload> {
		return this.default;
	}

	public setDefault(value: Optional<Payload>): void {
		this.default = value;
	}

	public getOptions(): Optional<ReadonlyArray<Payload>> {
		return this.options;
	}

	public setOptions(values: Optional<ReadonlyArray<Payload>>): void {
		this.options = values === null || values.length === 0 ? null : values;
	}

	public isMandatory(): boolean {
		return this.mandatory;
	}

	public isOptional(): boolean {
		return !this.isMandatory();
	}

	public setMandatory(mandatory: boolean): void {
		this.mandatory = mandatory;
	}

	public isRepeatable(): boolean {
		return this.description.repeatable ?? false;
	}

	public isValued(): boolean {
		return this.description.valued ?? false;
	}

	public isStored(): boolean {
		return this.description.stored ?? false;
	}

	public get(): ReadonlyArray<Payload> {
		return this.values;
	}

	public set(values: ReadonlyArray<Payload>): void {
		this.values = values.filter((value) => !this.isEmpty(value));
	}

	public getSingle(): Optional<Payload> {
		if (this.isRepeatable()) {
			throw new Error('Field ' + this.getName() + " is repeatable, can't return single value");
		}
		const values: ReadonlyArray<Payload> = this.get();
		return values.length === 0 ? null : values[0];
	}

	public setSingle(value: Optional<Payload>): void {
		this.set(value === null ? [] : [value]);
	}

	public getValuation(): Valuation {
		return this.valuation;
	}

	public setValuation(valuation: Valuation): void {
		this.valuation = valuation;
	}

	public preCalculateOrEnter(context: Context, environment: Environment): void {
		this.doPreCalculateOrEnter(this.calculation, context, environment);
	}

	protected doPreCalculateOrEnter(calculation: Calculation, context: Context, environment: Environment): void {
		if (calculation.default) {
			this.setDefault(calculation.default(context).getSingle());
		}
		if (calculation.options) {
			this.setOptions(calculation.options(context).get());
		}
		if (calculation.mandatory && environment.action !== Action.RESTORE) {
			this.setMandatory(calculation.mandatory(context).getSingle() ?? true);
		}
	}

	public calculate(context: Context): void {
		this.doCalculate(this.calculation, context);
	}

	protected doCalculate(calculation: Calculation, context: Context): void {
		if (calculation.value) {
			this.set(calculation.value(context).get());
		}
	}

	public postCalculateOrEnter(context: Context): void {
		this.doPostCalculateOrEnter(this.calculation, context);
	}

	protected doPostCalculateOrEnter(calculation: Calculation, context: Context): void {
		if (calculation.valuation) {
			this.setValuation(calculation.valuation(context).getSingle() ?? Valuation.UNKNOWN);
		}
	}

	public toStorable(strict: boolean): Storable {
		const storable: Storable = this.getAsStorable();
		if (strict === true && storable === null && !this.isOptional()) {
			throw new Error('Mandatory field ' + this.getName() + ' has no value');
		}
		return storable;
	}

	private getAsStorable(): Storable {
		const values: ReadonlyArray<Payload> = this.get();
		if (this.isRepeatable()) {
			return values.map((value) => this.mapToStorable(value));
		}
		return values.length !== 0 ? this.mapToStorable(values[0]) : null;
	}

	protected abstract mapToStorable(value: Payload): Storable;

	public fromStoreable(value: Storable): void {
		if (value !== null) {
			if (this.isRepeatable()) {
				if (!Array.isArray(value)) {
					throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not an array)');
				}
				this.set(value.map((storable) => this.mapFromStorable(storable)));
			} else {
				this.setSingle(this.mapFromStorable(value));
			}
		} else {
			this.setSingle(null);
		}
	}

	protected abstract mapFromStorable(value: Storable): Payload;

	public toViewable(strict: boolean): { value: Viewable; valuation: Valuation; extras: Viewables<Viewable> } {
		const value: Viewable = this.getAsViewable();
		if (strict === true && value === null && !this.isOptional()) {
			throw new Error('Mandatory field ' + this.getName() + ' has no value');
		}
		const valuation: Valuation = this.getValuation();
		if (strict === true && this.isValued() && valuation === Valuation.UNKNOWN) {
			throw new Error('Valuable field ' + this.getName() + ' has no valuation');
		}
		const extras: Viewables<Viewable> = this.getExtras();
		return { value, valuation, extras };
	}

	private getAsViewable(): Viewable {
		const values: ReadonlyArray<Payload> = this.get();
		if (this.isRepeatable()) {
			return values.map((value) => this.mapToViewable(value));
		}
		return values.length !== 0 ? this.mapToViewable(values[0]) : null;
	}

	protected abstract mapToViewable(value: Payload): Viewable;

	protected getExtras(): Viewables<Viewable> {
		return {};
	}

	public fromViewable(value: Viewable, valuation: Valuation, extras: Viewables<Viewable>): void {
		if (value !== null) {
			if (this.isRepeatable()) {
				if (!Array.isArray(value)) {
					throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not an array)');
				}
				this.set(value.map((v) => this.mapFromViewable(v)));
				this.setValuation(valuation);
				this.setExtras(extras);
			} else {
				this.setSingle(this.mapFromViewable(value));
				this.setValuation(valuation);
				this.setExtras(extras);
			}
		} else {
			this.setSingle(null);
			this.setValuation(Valuation.UNKNOWN);
			this.setExtras({});
		}
	}

	protected abstract mapFromViewable(value: Viewable): Payload;

	protected setExtras(extras: Viewables<Viewable>): void {}

	public validate(): ReadonlyArray<Violation> {
		const violations: Array<Violation> = [];
		if (this.values.length === 0 && !this.isOptional()) {
			violations.push(new Violation([this], 'inventory.violation.' + this.getName() + 'IsNotOptional'));
		}
		if (this.values.length > 1 && !this.isRepeatable()) {
			violations.push(new Violation([this], 'inventory.violation.' + this.getName() + 'IsNotRepeatable'));
		}
		this.values.forEach((value) => {
			if (this.isEmpty(value)) {
				violations.push(new Violation([this], 'inventory.violation.' + this.getName() + 'IsEmpty'));
			} else if (!this.validator(value)) {
				violations.push(new Violation([this], 'inventory.violation.' + this.getName() + 'IsInvalid'));
			} else if (this.options !== null && this.options.filter((option) => this.equals(option, value)).length === 0) {
				violations.push(new Violation([this], 'inventory.violation.' + this.getName() + 'IsNotOneOfTheOptions'));
			}
		});
		return violations;
	}

	protected equals(left: Payload, right: Payload): boolean {
		return left === right;
	}

	protected isEmpty(value: Payload): boolean {
		return false;
	}

	public copy(other: this): void {
		this.setDefault(other.getDefault());
		this.setOptions(other.getOptions());
		this.setMandatory(other.isMandatory());
		this.set(other.get());
		this.setValuation(other.getValuation());
	}

	public abstract selectInput<Result>(selection: InputSelection<Result>): Result;

	public abstract selectOutput<Result>(selection: OutputSelection<Result>): Result;
}

const BOOLEAN_VALIDATOR: Validator<boolean> = (_: boolean) => true;

export class BooleanField<Context> extends AbstractField<Context, boolean, BooleanFieldDescription, BooleanFieldCalculation<Context>> implements BooleanInput {
	public constructor(calculation: BooleanFieldCalculation<Context>) {
		super(calculation, BOOLEAN_VALIDATOR);
	}

	protected mapToStorable(value: boolean): Storable {
		return value;
	}

	protected mapFromStorable(value: Storable): boolean {
		if (typeof value !== 'boolean') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a boolean)');
		}
		return value;
	}

	protected mapToViewable(value: boolean): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): boolean {
		if (typeof value !== 'boolean') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a boolean)');
		}
		return value;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onBooleanOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onBooleanInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onBooleanField(this);
	}
}

const COMMENT_VALIDATOR: Validator<string> = (_: string) => true;

export class CommentField<Context> extends AbstractField<Context, string, CommentFieldDescription, CommentFieldCalculation<Context>> implements CommentInput {
	public constructor(calculation: CommentFieldCalculation<Context>) {
		super(calculation, COMMENT_VALIDATOR);
	}

	protected mapToStorable(value: string): Storable {
		return value;
	}

	protected mapFromStorable(value: Storable): string {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		return value;
	}

	protected mapToViewable(value: string): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): string {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		return value;
	}

	protected isEmpty(value: string): boolean {
		return value.trim().length === 0;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onCommentOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onCommentInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onCommentField(this);
	}
}

const DATE_TIME_VALIDATOR: Validator<Date> = (date: Date) => Number.isFinite(date.getTime());

export class DateTimeField<Context> extends AbstractField<Context, Date, DateTimeFieldDescription, DateTimeFieldCalculation<Context>> implements DateTimeInput {
	public constructor(calculation: DateTimeFieldCalculation<Context>) {
		super(calculation, DATE_TIME_VALIDATOR);
	}

	protected mapToStorable(value: Date): Storable {
		return value.getTime();
	}

	protected mapFromStorable(value: Storable): Date {
		if (typeof value !== 'number') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a number)');
		}
		return new Date(value);
	}

	protected mapToViewable(value: Date): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): Date {
		if (typeof value !== 'object') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not an object)');
		}
		if (!(value instanceof Date)) {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a date)');
		}
		return value;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onDateTimeOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onDateTimeInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onDateTimeField(this);
	}
}

const INTERVAL_VALIDATOR: Validator<Interval> = (interval: Interval) => isEnumValue(Interval, interval);

export class IntervalField<Context>
	extends AbstractField<Context, Interval, IntervalFieldDescription, IntervalFieldCalculation<Context>>
	implements IntervalInput
{
	public constructor(calculation: IntervalFieldCalculation<Context>) {
		super(calculation, INTERVAL_VALIDATOR);
	}

	protected mapToStorable(value: Interval): Storable {
		return value as string;
	}

	protected mapFromStorable(value: Storable): Interval {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		if (!isEnumValue(Interval, value)) {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not an interval)');
		}
		return value as Interval;
	}

	protected mapToViewable(value: Interval): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): Interval {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		if (!isEnumValue(Interval, value)) {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not an interval)');
		}
		return value as Interval;
	}

	protected isEmpty(value: Interval): boolean {
		return value.trim().length === 0;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onIntervalOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onIntervalInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onIntervalField(this);
	}
}

const MAIL_ADDRESS_VALIDATOR: Validator<string> = (_: string) => true;

export class MailAddressField<Context>
	extends AbstractField<Context, string, MailAddressFieldDescription, MailAddressFieldCalculation<Context>>
	implements MailAddressInput
{
	public constructor(calculation: MailAddressFieldCalculation<Context>) {
		super(calculation, MAIL_ADDRESS_VALIDATOR);
	}

	protected mapToStorable(value: string): Storable {
		return value;
	}

	protected mapFromStorable(value: Storable): string {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		return value;
	}

	protected mapToViewable(value: string): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): string {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		return value;
	}

	protected isEmpty(value: string): boolean {
		return value.trim().length === 0;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onMailAddressOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onMailAddressInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onMailAddressField(this);
	}
}

const NUMBER_VALIDATOR: Validator<number> = (value: number) => Number.isFinite(value);

export class NumberField<Context> extends AbstractField<Context, number, NumberFieldDescription, NumberFieldCalculation<Context>> implements NumberInput {
	public constructor(calculation: NumberFieldCalculation<Context>) {
		super(calculation, NUMBER_VALIDATOR);
	}

	public getNumberOfDecimals(): number {
		return this.getDescription().numberOfDecimals ?? 3;
	}

	protected mapToStorable(value: number): Storable {
		return value;
	}

	protected mapFromStorable(value: Storable): number {
		if (typeof value !== 'number') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a number)');
		}
		return value;
	}

	protected mapToViewable(value: number): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): number {
		if (typeof value !== 'number') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a number)');
		}
		return value;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onNumberOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onNumberInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onNumberField(this);
	}
}

const STRING_VALIDATOR: Validator<string> = (_: string) => true;

export class StringField<Context> extends AbstractField<Context, string, StringFieldDescription, StringFieldCalculation<Context>> implements StringInput {
	public constructor(calculation: StringFieldCalculation<Context>) {
		super(calculation, STRING_VALIDATOR);
	}

	public getPlaceholderKey(): Optional<string> {
		return this.getDescription().placeholderKey ?? null;
	}

	protected mapToStorable(value: string): Storable {
		return value;
	}

	protected mapFromStorable(value: Storable): string {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		return value;
	}

	protected mapToViewable(value: string): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): string {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		return value;
	}

	protected isEmpty(value: string): boolean {
		return value.trim().length === 0;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onStringOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onStringInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onStringField(this);
	}
}

const TEXT_VALIDATOR: Validator<string> = (_: string) => true;

export class TextField<Context> extends AbstractField<Context, string, TextFieldDescription, TextFieldCalculation<Context>> implements TextInput {
	public constructor(calculation: TextFieldCalculation<Context>) {
		super(calculation, TEXT_VALIDATOR);
	}

	public getPlaceholderKey(): Optional<string> {
		return this.getDescription().placeholderKey ?? null;
	}

	protected mapToStorable(value: string): Storable {
		return value;
	}

	protected mapFromStorable(value: Storable): string {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		return value;
	}

	protected mapToViewable(value: string): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): string {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		return value;
	}

	protected isEmpty(value: string): boolean {
		return value.trim().length === 0;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onTextOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onTextInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onTextField(this);
	}
}

const UNIT_VALIDATOR: Validator<Unit> = (unit: Unit) => isEnumValue(Unit, unit);

export class UnitField<Context> extends AbstractField<Context, Unit, UnitFieldDescription, UnitFieldCalculation<Context>> implements UnitInput {
	public constructor(calculation: UnitFieldCalculation<Context>) {
		super(calculation, UNIT_VALIDATOR);
	}

	protected mapToStorable(value: Unit): Storable {
		return value as string;
	}

	protected mapFromStorable(value: Storable): Unit {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		if (!isEnumValue(Unit, value)) {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not an unit)');
		}
		return value as Unit;
	}

	protected mapToViewable(value: Unit): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): Unit {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		if (!isEnumValue(Unit, value)) {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not an unit)');
		}
		return value as Unit;
	}

	protected isEmpty(value: string): boolean {
		return value.trim().length === 0;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onUnitOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onUnitInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onUnitField(this);
	}
}

const URL_VALIDATOR: Validator<string> = (_: string) => true;

export class UrlField<Context> extends AbstractField<Context, string, UrlFieldDescription, UrlFieldCalculation<Context>> implements UrlInput {
	public constructor(calculation: UrlFieldCalculation<Context>) {
		super(calculation, URL_VALIDATOR);
	}

	protected mapToStorable(value: string): Storable {
		return value;
	}

	protected mapFromStorable(value: Storable): string {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		return value;
	}

	protected mapToViewable(value: string): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): string {
		if (typeof value !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a string)');
		}
		return value;
	}

	protected isEmpty(value: string): boolean {
		return value.trim().length === 0;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onUrlOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onUrlInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onUrlField(this);
	}
}

const VALUED_STRING_VALIDATOR: Validator<ValuedString> = (_: ValuedString) => true;

export class ValuedStringField<Context>
	extends AbstractField<Context, ValuedString, ValuedStringFieldDescription, ValuedStringFieldCalculation<Context>>
	implements ValuedStringInput
{
	public constructor(calculation: ValuedStringFieldCalculation<Context>) {
		super(calculation, VALUED_STRING_VALIDATOR);
	}

	public getPlaceholderKey(): Optional<string> {
		return this.getDescription().placeholderKey ?? null;
	}

	protected mapToStorable(value: ValuedString): Storable {
		return value;
	}

	protected mapFromStorable(value: Storable): ValuedString {
		if (typeof value !== 'object') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a object)');
		}
		const valueValue = (value as ValuedString).value;
		if (typeof valueValue !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + '.value: ' + value + ' (not a string)');
		}
		const valuationValue = (value as ValuedString).valuation;
		if (typeof valuationValue !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + '.valuation: ' + value + ' (not a string)');
		}
		if (!isEnumValue(Valuation, valuationValue)) {
			throw new Error('Unexpected value for field ' + this.getName() + '.valuation: ' + value + ' (not a valuation)');
		}
		return { value: valueValue, valuation: valuationValue };
	}

	protected mapToViewable(value: ValuedString): Viewable {
		return value;
	}

	protected mapFromViewable(value: Viewable): ValuedString {
		if (typeof value !== 'object') {
			throw new Error('Unexpected value for field ' + this.getName() + ': ' + value + ' (not a object)');
		}
		const valueValue = (value as ValuedString).value;
		if (typeof valueValue !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + '.value: ' + value + ' (not a string)');
		}
		const valuationValue = (value as ValuedString).valuation;
		if (typeof valuationValue !== 'string') {
			throw new Error('Unexpected value for field ' + this.getName() + '.valuation: ' + value + ' (not a string)');
		}
		if (!isEnumValue(Valuation, valuationValue)) {
			throw new Error('Unexpected value for field ' + this.getName() + '.valuation: ' + value + ' (not a valuation)');
		}
		return { value: valueValue, valuation: valuationValue };
	}

	protected equals(left: ValuedString, right: ValuedString): boolean {
		return left.value === right.value && left.valuation === right.valuation;
	}

	protected isEmpty(value: ValuedString): boolean {
		return value.value.trim().length === 0;
	}

	public selectOutput<Result>(selection: OutputSelection<Result>): Result {
		return selection.onValuedStringOutput(this);
	}

	public selectInput<Result>(selection: InputSelection<Result>): Result {
		return selection.onValuedStringInput(this);
	}

	public selectField<Result>(selection: FieldSelection<Result>): Result {
		return selection.onValuedStringField(this);
	}
}

export interface FieldSelection<Result> {
	onBooleanField(field: BooleanField<any>): Result;

	onCommentField(field: CommentField<any>): Result;

	onDateTimeField(field: DateTimeField<any>): Result;

	onIntervalField(field: IntervalField<any>): Result;

	onMailAddressField(field: MailAddressField<any>): Result;

	onNumberField(field: NumberField<any>): Result;

	onStringField(field: StringField<any>): Result;

	onTextField(field: TextField<any>): Result;

	onUnitField(field: UnitField<any>): Result;

	onUrlField(field: UrlField<any>): Result;

	onValuedStringField(field: ValuedStringField<any>): Result;
}

export type FieldCalculation<Context, Payload, Description extends FieldDescription> = {
	description: Description;

	default?: Calculation<Context, Payload>;

	options?: Calculation<Context, Payload>;

	mandatory?: Calculation<Context, boolean>;

	value?: Calculation<Context, Payload>;

	valuation?: Calculation<Context, Valuation>;
};

export type BooleanFieldCalculation<Context> = FieldCalculation<Context, boolean, BooleanFieldDescription>;

export type CommentFieldCalculation<Context> = FieldCalculation<Context, string, CommentFieldDescription>;

export type DateTimeFieldCalculation<Context> = FieldCalculation<Context, Date, DateTimeFieldDescription>;

export type IntervalFieldCalculation<Context> = FieldCalculation<Context, Interval, IntervalFieldDescription>;

export type MailAddressFieldCalculation<Context> = FieldCalculation<Context, string, MailAddressFieldDescription>;

export type NumberFieldCalculation<Context> = FieldCalculation<Context, number, NumberFieldDescription>;

export type StringFieldCalculation<Context> = FieldCalculation<Context, string, StringFieldDescription>;

export type TextFieldCalculation<Context> = FieldCalculation<Context, string, TextFieldDescription>;

export type UnitFieldCalculation<Context> = FieldCalculation<Context, Unit, UnitFieldDescription>;

export type UrlFieldCalculation<Context> = FieldCalculation<Context, string, UrlFieldDescription>;

export type ValuedStringFieldCalculation<Context> = FieldCalculation<Context, ValuedString, ValuedStringFieldDescription>;
