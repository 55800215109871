import { FunctionComponent } from 'react';

import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './card-membership.scss';

type CardMembershipButton = {
	icon: IconIdentifier,
	disabled: boolean;
	onClick: (cardId: string) => void;
};

export interface CardMembershipProps {
	/**
	 * Id of controlled card
	 */
	cardId: string;
	/**
	 * (Optional) Button props
	 */
	button?: CardMembershipButton;
}

/**
 * The membership card component
 */
export const CardMembership: FunctionComponent<CardMembershipProps> = (props): JSX.Element => {
	const { button, cardId, children } = props;

	const buttonEl = button ?
		<ButtonIcon
			icon={button.icon}
			shape={ButtonIconShape.RECTANGLE}
			weight={ButtonIconWeight.PRIMARY}
			disabled={button.disabled}
			onClick={() => button.onClick(cardId)}
		/> :
		null;

	return (
		<div className='card-membership'>
			<div className='card-membership__content'>
				{children}
			</div>

			{buttonEl}
		</div>
	);
};
