import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { CreatableMembershipViewModel } from 'services/core/domain/model/MembershipModel';
import { RoleViewModel } from 'services/core/domain/model/RoleModel';
import { createMembership } from 'services/core/store/membershipSlice';
import { MembershipCreateFormStep1 } from './MembershipCreateFormStep1';
import { MembershipCreateFormStep2 } from './MembershipCreateFormStep2';
import { MembershipCreateFormStep3 } from './MembershipCreateFormStep3';

/**
 * The membership create form
 */
export const MembershipCreateForm = (): JSX.Element => {

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [modalStep, setmodalStep] = useState<number>(1);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const newMembershipViewModel = useRef<CreatableMembershipViewModel>({ Actor:{}, Roles:[] } as CreatableMembershipViewModel);

	const requiredInputs = ['Realname', 'Password'];

	const handleChangeMembershipActorProp = (prop: string, value: any) => {
		newMembershipViewModel.current.Actor[prop] = value;
	};

	const handleChangeMembershipRoles = (roles: Array<RoleViewModel>) => {
		newMembershipViewModel.current.Roles = roles;
	};

	const handleCreate = (): void => {
		newMembershipViewModel.current.Client = clientContext.selectedClientUuid;
		newMembershipViewModel.current.Facility = facilityContext.selectedFacilityUuid;
		dispatch(createMembership({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			viewModel: newMembershipViewModel.current
		}));
	};

	const previousStep = () => {
		setmodalStep(modalStep - 1);
		setInvalidInputs([]);
	};

	const nextStep = () => {
		let newMembershipModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(newMembershipViewModel.current.Actor?.[requiredInput] ?? null) === null
				|| newMembershipViewModel.current.Actor?.[requiredInput] === ''
				|| String(newMembershipViewModel.current.Actor.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newMembershipModelValid = false;
			}
		}
		if (
			((newMembershipViewModel.current.Actor?.MailAddress ?? null) === null || newMembershipViewModel.current.Actor?.MailAddress === '') 
			&& ((newMembershipViewModel.current.Actor?.Username ?? null) === null || newMembershipViewModel.current.Actor?.Username === '')
		) {
			invalidInputFields.push('E-Mail-Adresse oder Benutzername');
			newMembershipModelValid = false;
		}
		setInvalidInputs(invalidInputFields);
		if (newMembershipModelValid) {
			setmodalStep(modalStep + 1);
		} else {
			setmodalStep(modalStep);
		}
	};

	const formStep = () => {
		let currentStep: JSX.Element = null;

		switch (modalStep) {
			case 1:
				currentStep =
					<MembershipCreateFormStep1
						membership={newMembershipViewModel.current}
						onClickNext={nextStep}
						onChangeActorData={handleChangeMembershipActorProp}
						invalidInputs={invalidInputs}
					/>;
				break;

			case 2:
				currentStep =
					<MembershipCreateFormStep2
						membership={newMembershipViewModel.current}
						onClickPrevious={previousStep}
						onClickNext={nextStep}
						onChangeRoleSelection={handleChangeMembershipRoles}
					/>;
				break;

			case 3:
				currentStep =
					<MembershipCreateFormStep3
						membership={newMembershipViewModel.current}
						onClickPrevious={previousStep}
						onClickConfirm={handleCreate}
					/>;
				break;
		}
		return currentStep;
	};

	return (
		<>
			{formStep()}
		</>
	);
};
