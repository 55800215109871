import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { useEffect, useState } from 'react';

import { Network } from 'lib/network/Network';

import { NetworkContext } from './NetworkContext';

export const NetworkContextProvider = (props: any): JSX.Element => {

	const [online, setOnline] = useState<boolean | null>(null);

	useEffect(() => {
		async function detectOnline() {
			setOnline(await Network.isOnline());
		}

		window.addEventListener('offline', () => {
			setOnline(false);
		});
		window.addEventListener('online', () => {
			void detectOnline();
		});

		void detectOnline();

		return () => {
			// Clean up the event registration
			window.removeEventListener('offline', () => {
				setOnline(false);
			});
			window.removeEventListener('online', () => {
				void detectOnline();
			});
		};
	});

	if (online === null) {
		return (
			<LoadingSpinner />
		);
	}

	return (
		<NetworkContext.Provider value={{ online }}>
			{props.children}
		</NetworkContext.Provider>
	);

};
