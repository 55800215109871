export enum ModelSyncState {
	PRISTINE = 'PRISTINE',
	CREATED = 'CREATED',
	UPDATED = 'UPDATED',
	DELETED = 'DELETED'
}

export interface Model extends Record<string, any> {
	Uuid: string;
}

export interface PersistenceModel extends Model {
	CreatedAt: number;
	UpdatedAt: number;
}

export interface StoreModel extends Model {
	ModelSyncState: string;
	CreatedAt: number;
	UpdatedAt: number;
}

export interface ViewModel extends Model {
	ModelSyncState: ModelSyncState;
	CreatedAt: Date;
	UpdatedAt: Date;
}

export interface CreatableViewModel extends Record<string, any> {
}
