import { useCallback } from 'react';

import { CardItemActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemActionTypes';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { Icon, IconColor } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './card-item-membership-controls.scss';

export interface CardItemMembershipControlsProps {
	/**
	 * Id of controlled card
	 */
	cardId: string;
	/**
	 * (Optional) Click handler
	 */
	onClick?: (action: CardItemControlActionType) => void;
	/**
	 * (Optional) Delete action state
	 */
	actionDelete?: CardItemControlState;
	/**
	 * (Optional) Change membership state
	 */
	actionChangeMembership?: CardItemControlState;
}

/**
 * A card item controls component
 */
export const CardItemMembershipControls = (props: CardItemMembershipControlsProps): JSX.Element => {
	const { cardId, onClick, actionDelete, actionChangeMembership } = props;

	const createIcon = (name: IconIdentifier, state: CardItemControlState, action: string) => {
		const stateClass = state ?
			`control-icon--${state.toLowerCase()}` :
			'';

		return (
			<>
				<Icon
					name={name}
					color={IconColor.PRIMARY_50}
					className={stateClass}
					onClick={() => onClickHandler(action)}
				/>
			</>
		);
	};

	const onClickHandler = useCallback((action: string) => {
		if (!onClick) return;

		onClick({
			type: action,
			cardId
		});
	}, [onClick, cardId]);


	const deleteAction = actionDelete ?
		createIcon(IconIdentifier.DEACTIVATE_USER, actionDelete, CardItemActionType.DELETE) :
		null;

	const changeMembershipAction = actionChangeMembership ?
		createIcon(IconIdentifier.EDIT, actionChangeMembership, CardItemActionType.CHANGE) :
		null;

	return (
		<div className='card-item-controls'>
			{changeMembershipAction}
			{deleteAction}
		</div>
	);
};
