import { useContext, useRef, useState } from 'react';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';

import { useDispatch } from 'react-redux';

import { createGenerator } from 'services/nuclide/store/generatorSlice';
import { CreatableGeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { AuthContext } from 'services/core/context/AuthContext';
import { GeneratorCreateFormStep1 } from './GeneratorCreateFormStep1';
import { GeneratorCreateFormStep2 } from './GeneratorCreateFormStep2';

/**
 * The generator create form
 */
export const GeneratorCreateForm = (): JSX.Element => {

	// Consume the context
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const newGeneratorViewModel = useRef<CreatableGeneratorViewModel>({
		CalibrationDatetime: new Date(),
		DeliveryDate: new Date()
	} as CreatableGeneratorViewModel);

	const onChangeGeneratorProp = (prop: string, value: any) => {
		newGeneratorViewModel.current[prop] = value;
	};

	const handleCreate = (): void => {
		newGeneratorViewModel.current.Client = clientContext.selectedClientUuid;
		newGeneratorViewModel.current.Facility = facilityContext.selectedFacilityUuid;
		newGeneratorViewModel.current.AcceptedBy = authContext.getActor().Uuid;
		dispatch(createGenerator(newGeneratorViewModel.current));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const requiredInputs = ['Name', 'Isotope', 'NominalActivity', 'BatchNumber', 'Manufacturer', 'SerialNumber', 'DeliveryDate', 'CalibrationDatetime', 'ActivityUnit'];

	const goToStep2 = () => {
		let newGeneratorModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(newGeneratorViewModel.current?.[requiredInput] ?? null) === null
				|| newGeneratorViewModel.current?.[requiredInput] === ''
				|| String(newGeneratorViewModel.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newGeneratorModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newGeneratorModelValid);
	};

	const showCreateModalStep = showStep1 === true ?
		<GeneratorCreateFormStep1
			generator={newGeneratorViewModel.current}
			onClickNext={goToStep2}
			onChangeGeneratorProp={onChangeGeneratorProp}
			invalidInputs={invalidInputs}
		/> :
		<GeneratorCreateFormStep2
			generator={newGeneratorViewModel.current}
			onClickPrevious={goToStep1}
			onClickConfirm={handleCreate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
