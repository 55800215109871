import { Violation } from './Violation';

export class ViolationError extends Error {
	private readonly violations: ReadonlyArray<Violation>;

	constructor(violations: ReadonlyArray<Violation>) {
		super();
		this.violations = violations;
	}

	public getViolations(): ReadonlyArray<Violation> {
		return this.violations;
	}
}
