import { useContext, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { CreatableEluateViewModel } from 'services/nuclide/domain/model/EluateModel';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { createEluate } from 'services/nuclide/store/eluateSlice';
import { Permission } from 'services/core/lib/auth/AuthService';
import { EluateCreateFormStep1 } from './EluateCreateFormStep1';
import { EluateCreateFormStep2 } from './EluateCreateFormStep2';

export interface EluateCreateFormProps {
	/**
	 * Generator
	 */
	generator: Readonly<GeneratorViewModel>;
}

/**
 * The eluate create form
 */
export const EluateCreateForm = (props: EluateCreateFormProps): JSX.Element => {

	const { generator } = props;

	// Consume the context
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	// ToDo: Change to CreatableGeneratorViewModel
	const newEluateViewModel = useRef<CreatableEluateViewModel>({} as CreatableEluateViewModel);

	const onChangeEluateProp = (prop: string, value: any) => {
		newEluateViewModel.current[prop] = value;
	};

	if ((newEluateViewModel.current?.ExtractionDatetime ?? null) === null) {
		newEluateViewModel.current.ExtractionDatetime = new Date();
	}

	const handleCreate = (): void => {
		newEluateViewModel.current.Client = clientContext.selectedClientUuid;
		newEluateViewModel.current.Facility = facilityContext.selectedFacilityUuid;
		newEluateViewModel.current.ExtractedBy = authContext.getActor().Uuid;
		newEluateViewModel.current.Generator = generator.Uuid;
		dispatch(createEluate(newEluateViewModel.current));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const requiredInputs = ['Activity', 'ActivityUnit'];
	if (authContext.hasPermission(Permission.GENERATOR_ELUAT_SUPPLEMENT)) {
		requiredInputs.push('ExtractionDatetime');
	}

	const goToStep2 = () => {
		let newGeneratorModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(newEluateViewModel.current?.[requiredInput] ?? null) === null
				|| newEluateViewModel.current?.[requiredInput] === ''
				|| String(newEluateViewModel.current.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newGeneratorModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newGeneratorModelValid);
	};

	const showCreateModalStep = showStep1 === true ?
		<EluateCreateFormStep1
			eluate={newEluateViewModel.current}
			onClickNext={goToStep2}
			onChangeEluateProp={onChangeEluateProp}
			invalidInputs={invalidInputs}
		/> :
		<EluateCreateFormStep2
			eluate={newEluateViewModel.current}
			onClickPrevious={goToStep1}
			onClickConfirm={handleCreate}
		/>;

	return (
		<>
			{showCreateModalStep}
		</>
	);
};
