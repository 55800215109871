import { L10nContext } from 'context/L10nContext';
import { Route } from 'router/Route';
import { CardReportDetail } from 'presentation/ui/components/cards/card-report/card-report-detail/CardReportDetail';
import { CardItemReportAttributes } from 'presentation/ui/partials/card/card-item-attributes/card-item-report-attributes/CardItemReportAttributes';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { useContext } from 'react';
import { ReportViewModel } from 'services/report/domain/model/ReportModel';
import { ReportViewModalType } from '../../view/ReportViewModalType';

export interface ReportCardProps {
	report: ReportViewModel,
	onClick?: (modalType: ReportViewModalType) => void,
	isOnlyView: boolean,
	userPermissionEdit?: boolean
}

export const ReportCard = (props: ReportCardProps): JSX.Element => {
	const { report, isOnlyView, userPermissionEdit, onClick } = props;

	const l10nContext = useContext(L10nContext);

	const periodDateStartDateFormatted = l10nContext.formatDate(report?.PeriodDateStart);
	const periodDateEndDateFormatted = l10nContext.formatDate(report?.PeriodDateEnd);
	const validUntilDateFormatted = l10nContext.formatDate(report?.ValidUntil);

	const url = `${Route.EXTERNAL_REPORT}/${report?.Uuid}`;
	const showUrl = isOnlyView ? '' : url;

	return (
		<CardReportDetail>
			<CardItemHeader
				title={report?.Name}
			/>
			<CardItemSingleControl
				cardId={report?.Uuid}
				actionEdit={userPermissionEdit ?
					CardItemControlState.ENABLED :
					CardItemControlState.DISABLED}
				onClick={() => onClick(ReportViewModalType.EDIT)}
			/>
			<CardItemReportAttributes
				periodDateStart={periodDateStartDateFormatted}
				periodDateEnd={periodDateEndDateFormatted}
				validUntil={validUntilDateFormatted}
				url={showUrl}
			/>
		</CardReportDetail>
	);
};
