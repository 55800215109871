import { PublicLocalStorage } from 'lib/browser-storage/PublicLocalStorage';
import { PublicLocalStorageKey } from 'lib/browser-storage/PublicLocalStorageKey';

import { ActorModel, ClientModel } from 'services/core/lib/auth/AuthService';
import { PasswordHash } from 'services/core/lib/password-hash/PasswordHash';

export interface LocalActorModel extends ActorModel {
	readonly PasswordHash: string;
	readonly ExpiresAt: Date;
	readonly Clients: Array<ClientModel>;
}

// SUGGESTION: Think about integrating WebAuth; see https://developer.mozilla.org/en-US/docs/Web/API/Web_Authentication_API
export class LocalActorRepository {

	private static instance?: LocalActorRepository = null;

	public static get(): LocalActorRepository {
		if (this.instance === null) {
			this.instance = new LocalActorRepository();
		}
		return this.instance;
	}

	public async addActor(actorModel: ActorModel, password: string, availableClientModelCollection: Array<ClientModel>): Promise<void> {
		const passwordHash = await PasswordHash.hash(password, actorModel.Uuid);
		const localActor = {
			...actorModel,
			PasswordHash: passwordHash,
			ExpiresAt: new Date(Date.now() + 12096e5),
			Clients: availableClientModelCollection
		} as LocalActorModel;
		const actorModelCollection = PublicLocalStorage.get().read<Array<LocalActorModel>>(PublicLocalStorageKey.LOCAL_ACTORS) ?? [];
		for (let i = 0; i < actorModelCollection.length; i++) {
			if (actorModelCollection[i].Uuid === actorModel.Uuid) {
				actorModelCollection[i] = localActor;
				PublicLocalStorage.get().write<Array<LocalActorModel>>(PublicLocalStorageKey.LOCAL_ACTORS, actorModelCollection);
				return;
			}
		}
		actorModelCollection.push(localActor);
		PublicLocalStorage.get().write<Array<LocalActorModel>>(PublicLocalStorageKey.LOCAL_ACTORS, actorModelCollection);
	}

	public async getActor(user: string, password: string): Promise<[ActorModel, Array<ClientModel>] | null> {
		const actorModelCollection = PublicLocalStorage.get().read<Array<LocalActorModel>>(PublicLocalStorageKey.LOCAL_ACTORS) ?? [];
		for (let i = 0; i < actorModelCollection.length; i++) {
			const actorModel = actorModelCollection[i];
			if (actorModel.MailAddress === user || actorModel.Username === user) {
				const expiryDate = new Date(actorModel.ExpiresAt);
				if (expiryDate.getTime() < Date.now()) {
					actorModelCollection.splice(i, 1);
					return null;
				}
				// eslint-disable-next-line no-await-in-loop
				if (await PasswordHash.check(actorModel.PasswordHash, actorModel.Uuid, password)) {
					return [actorModel as ActorModel, actorModel.Clients as Array<ClientModel> ?? []];
				}
				return null;
			}
		}
		return null;
	}

}
