export enum GeneratorViewModalType {
	EDIT = 'EDIT',
	CREATE = 'CREATE',
	DELETE = 'DELETE',
	DECAY = 'DECAY',
	END_DECAY = 'END_DECAY',
	DISPATCH = 'DISPATCH',
	CREATE_ELUATE = 'CREATE_ELUATE',
	UPDATE_ELUATE = 'UPDATE_ELUATE',
	TO_WASTE_ELUATE = 'TO_WASTE_ELUATE',
	TO_ACTIVE_STATE_ELUATE = 'TO_ACTIVE_STATE_ELUATE',
	DELETE_ELUATE = 'DELETE_ELUATE',
	CREATE_DOCUMENT = 'CREATE_DOCUMENT',
	DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
	DELETE_DOCUMENT = 'DELETE_DOCUMENT',
	EDIT_DOCUMENT_NAME = 'EDIT_DOCUMENT_NAME',
	ARCHIVE_DOCUMENT = 'ARCHIVE_DOCUMENT',
	UNARCHIVE_DOCUMENT = 'UNARCHIVE_DOCUMENT'
}
