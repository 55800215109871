import { FunctionComponent } from 'react';

import './card-generator-list-item.scss';

export interface CardGeneratorListItemProps {
	/**
	 * (Optional) Disabled state
	 */
	disabled?: boolean;
	/**
	 * (Optional) Archived state
	 */
	archived?: boolean;
}

/**
 * The generator list item card component
 */
export const CardGeneratorListItem: FunctionComponent<CardGeneratorListItemProps> = (props): JSX.Element => {
	const { disabled, children, archived = false } = props;


	const componentDisabledStyle = disabled ?
		' card-generator-list-item--disabled' :
		'';

	const componentArchivedStyle = archived ?
		' card-generator-list-item--archived' :
		'';

	return (
		<div className={`card-generator-list-item${componentDisabledStyle}${componentArchivedStyle}`}>
			<div
				className='card-generator-list-item__content'
				role='button'
				tabIndex={0}
			>
				{children}
			</div>
		</div>
	);
};
