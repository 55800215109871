import { L10n } from 'lib/l10n/L10n';

import { TooltipItem } from 'services/device/presentation/ui/sequence-chart/SequenceChartOptions';

export class SequenceChartTooltipTitleFormatterBuilder {

	public static build(): (tooltipItems: Array<TooltipItem>) => string {
		return (tooltipItems: Array<TooltipItem>): string => {
			const datetimeValue = tooltipItems[0].xLabel;
			const titlePrefix = L10n.translate('sequence.chart.tooltip.title.prefix', 'Record from');
			return titlePrefix + ' ' + datetimeValue;
		};
	}

}
