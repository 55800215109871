import { useContext, useState } from 'react';

import { Route } from 'router/Route';
import { useHistory } from 'react-router-dom';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { L10nContext } from 'context/L10nContext';

import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { FilterChipSet } from 'presentation/ui/components/filter/filter-chip-set/FilterChipSet';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import {
	selectActiveAndCompleteAppliedFilteredCyclotronProducts
} from 'services/nuclide/store/cyclotronProductSlice';
import { useSelector } from 'react-redux';
import { ExpandableSlimBody } from '../../../../../../presentation/ui/compositions/expandable-slim/expandable-slim-body/ExpandableSlimBody';
import {
	ExpandableSlimHeader
} from '../../../../../../presentation/ui/compositions/expandable-slim/expandable-slim-header/ExpandableSlimHeader';
import { ExpandableSlim } from '../../../../../../presentation/ui/compositions/expandable-slim/ExpandableSlim';
import { CyclotronProductViewModel } from '../../../../domain/model/CyclotronProductModel';
import { CyclotronProductCollectionItem } from './CyclotronProductCollectionItem';

export const CyclotronProductCollection = (): JSX.Element => {

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	// Local state
	const [cyclotronProductsIncludeActive, setCyclotronProductsIncludeActive] = useState<boolean>(true);
	const [cyclotronProductsIncludeCompletelyApplied, setCyclotronProductsIncludeCompletelyApplied] = useState<boolean>(false);


	const cyclotronProducts = useSelector(selectActiveAndCompleteAppliedFilteredCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		cyclotronProductsIncludeActive,
		cyclotronProductsIncludeCompletelyApplied
	));

	const cyclotronProductsCreationDates: string[] = [];

	// eslint-disable-next-line array-callback-return
	cyclotronProducts.map((cyclotronProduct: CyclotronProductViewModel) => {
		if (!cyclotronProductsCreationDates.includes(l10nContext.formatDate(cyclotronProduct.DeliveryDate))) {
			cyclotronProductsCreationDates.push(l10nContext.formatDate(cyclotronProduct.DeliveryDate));
		}
	});

	const renderFilters = (): JSX.Element => {
		return (
			<FilterChipSet
				label={l10nContext.translate('common.filter.status', 'Status')}
				chips={[
					{
						label: l10nContext.translate('common.filter.active', 'aktiv'),
						active: cyclotronProductsIncludeActive,
						onClick: (id, state) => {
							setCyclotronProductsIncludeActive(state);
						}
					},
					{
						label: l10nContext.translate('common.filter.completelyApplied', 'vollständig appliziert'),
						active: cyclotronProductsIncludeCompletelyApplied,
						onClick: (id, state) => {
							setCyclotronProductsIncludeCompletelyApplied(state);
						}
					}
				]}
			/>
		);
	};

	const renderCyclotronProductsByDate = () => {

		if (cyclotronProducts.length === 0) {
			return <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.cyclotronProducts', 'Keine anderen Radionuklide')} />;
		}

		return (
			cyclotronProductsCreationDates.sort().reverse().map((date) => {
				const cyclotronProductsByCreationDate: Array<CyclotronProductViewModel> = cyclotronProducts.filter((cyclotronProduct) => {
					return date === l10nContext.formatDate(cyclotronProduct.DeliveryDate);
				});

				const cards = cyclotronProductsByCreationDate.map((cyclotronProduct) => (
					<CyclotronProductCollectionItem
						key={cyclotronProduct.Uuid}
						cyclotronProduct={cyclotronProduct}
						onClick={() => history.push(`${Route.NUCLIDE_MANAGEMENT}${Route.CYCLOTRON_PRODUCTS}/${cyclotronProduct.Uuid}`)}
					/>
				));

				return (
					<CardCollectionLayout key={date}>
						<ExpandableSlim mode="slim" expanded>
							<ExpandableSlimHeader
								caption={l10nContext.translate('cyclotronProducts.deliveryDate', 'Lieferdatum ') + ' ' + date}
								isSlimMode={true}
							/>
							<ExpandableSlimBody>
								<CardCollectionLayout>
									{cards}
								</CardCollectionLayout>
							</ExpandableSlimBody>
						</ExpandableSlim>
					</CardCollectionLayout>
				);
			})
		);
	};

	return (
		<CardCollectionLayout>
			{renderFilters()}
			{renderCyclotronProductsByDate()}
		</CardCollectionLayout>
	);
} ;
