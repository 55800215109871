import { ComponentPropsWithoutRef } from 'react';

import './main-layout.scss';

export type MainLayoutProps = ComponentPropsWithoutRef<'main'>;

export const MainLayout = (props: MainLayoutProps): JSX.Element => {

	return (
		<main className='main-layout'>
			{props.children}
		</main>
	);

};
