import { ComponentPropsWithoutRef } from 'react';

export type ViewLayoutSectionProps = ComponentPropsWithoutRef<'section'>;

export const ViewLayoutSection = (props: ViewLayoutSectionProps): JSX.Element => {

	return (
		<section className='view-layout-section'>
			{props.children}
		</section>
	);
};
