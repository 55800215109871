import { L10nContext } from 'context/L10nContext';
import { CardReportDetail } from 'presentation/ui/components/cards/card-report/card-report-detail/CardReportDetail';
import {
	CardItemReportAttributes
} from 'presentation/ui/partials/card/card-item-attributes/card-item-report-attributes/CardItemReportAttributes';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { useContext } from 'react';
import { Route } from 'router/Route';
import { WasteManagementReportViewModel } from '../../../domain/models/WasteManagementReportModel';
import { WasteManagementReportViewModalType } from '../../view/WasteManagementReportViewModalType';

export interface WasteManagementReportCardProps {
	report: WasteManagementReportViewModel,
	onClick?: (modalType: WasteManagementReportViewModalType) => void,
	isOnlyView: boolean,
	userPermissionEdit?: boolean
}

export const WasteManagementReportCard = (props: WasteManagementReportCardProps): JSX.Element => {
	const { report, isOnlyView, userPermissionEdit, onClick } = props;

	const l10nContext = useContext(L10nContext);

	const periodDateStartDateFormatted = l10nContext.formatDate(report?.PeriodDateStart);
	const periodDateEndDateFormatted = l10nContext.formatDate(report?.PeriodDateEnd);
	const validUntilDateFormatted = l10nContext.formatDate(report?.ValidUntil);

	const url = `${Route.EXTERNAL_WASTE_MANAGEMENT_REPORT}/${report?.Uuid}`;
	const showUrl = isOnlyView ? '' : url;

	return (
		<CardReportDetail>
			<CardItemHeader
				title={report?.Name}
			/>
			{!isOnlyView ?
				<CardItemSingleControl
					cardId={report?.Uuid}
					actionEdit={userPermissionEdit ?
						CardItemControlState.ENABLED :
						CardItemControlState.DISABLED}
					onClick={() => onClick(report?.WasteManagementReportGenerators.length > 0 ?
						WasteManagementReportViewModalType.EDIT_GENERATOR_REPORT :
						WasteManagementReportViewModalType.EDIT_CYCLOTRON_PRODUCT_REPORT)}
				/> : null }
			<CardItemReportAttributes
				periodDateStart={periodDateStartDateFormatted}
				periodDateEnd={periodDateEndDateFormatted}
				validUntil={validUntilDateFormatted}
				url={showUrl}
			/>
		</CardReportDetail>
	);
};
