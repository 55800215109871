import { useContext, useState } from 'react';

import { AuthContext } from 'services/core/context/AuthContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { L10nContext } from 'context/L10nContext';

import { resetActionStatus as resetGeneratorActionStatus } from 'services/nuclide/store/generatorSlice';
import { resetActionStatus as resetCyclotronProductActionStatus } from 'services/nuclide/store/cyclotronProductSlice';

import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { SiteTitle, SiteTitleButton } from 'presentation/ui/components/site-title/SiteTitle';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { Permission } from 'services/core/lib/auth/AuthService';
import { TabBar } from 'presentation/ui/partials/tabbar/Tabbar';
import { TabBarItem } from 'presentation/ui/partials/tabbar/tabbar-item/TabbarItem';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Route } from 'router/Route';
import { WasteManagementReportsView } from '../../../waste-management-report/presentation/view/WasteManagementReportsView';
import { GeneratorsView } from './GeneratorsView';
import { CyclotronProductsView } from './CyclotronProductsView';
import { NuclideManagementViewModalType } from './NuclidManagementViewModalType';
import { GeneratorCreateModal } from '../ui/generator-action/generator-create-modal/GeneratorCreateModal';
import { CyclotronProductCreateModal } from '../ui/cyclotron-product-action/cyclotron-product-create-modal/CyclotronProductCreateModal';
import { WasteManagementView } from './WasteManagementView';

/**
 * The nuclide management view
 */
export const NuclideManagementView = (): JSX.Element => {

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	const dispatch = useDispatch();

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permissionCreateGenerator: authContext.hasPermission(Permission.DEVICE_CREATE),
		permissionCreateCyclotronProduct: authContext.hasPermission(Permission.CYCLOTRON_PRODUCT_CREATE)
	};

	const history = useHistory();

	const pathname = history.location.pathname.split('/');

	const [tabId, setTabId] = useState('/' + pathname[pathname.length - 1]);
	const [modalType, setModalType] = useState<NuclideManagementViewModalType>(null);

	const handleDismiss = () => {
		setModalType(null);
	};

	const handleModalClick = (type: NuclideManagementViewModalType) => {
		dispatch(resetGeneratorActionStatus());
		dispatch(resetCyclotronProductActionStatus());

		setModalType(type);
	};

	const showAddButton = (): SiteTitleButton => {
		if (tabId ===  Route.GENERATORS) {
			return (
				{
					buttonText: l10nContext.translate('view.generators.modal.action.create.caption', 'Generator hinzufügen'),
					disabled: !user.permissionCreateGenerator,
					icon: IconIdentifier.ADD,
					onClick: () => handleModalClick(NuclideManagementViewModalType.GENERATOR_CREATE)
				}
			);
		}
		if (tabId === Route.CYCLOTRON_PRODUCTS) {
			return (
				{
					buttonText: l10nContext.translate('view.cyclotronProducts.modal.action.create.caption', 'Radionuklid hinzufügen'),
					disabled: !user.permissionCreateCyclotronProduct,
					icon: IconIdentifier.ADD,
					onClick: () => handleModalClick(NuclideManagementViewModalType.CYCLOTRON_PRODUCT_CREATE)
				}
			);
		}
		return null;
	};

	// Show generator or cyclotron product create modal
	const showModal = (): JSX.Element => {
		let modal: JSX.Element = null;

		if (modalType === NuclideManagementViewModalType.GENERATOR_CREATE) {
			modal =
				<GeneratorCreateModal
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === NuclideManagementViewModalType.CYCLOTRON_PRODUCT_CREATE) {
			modal =
				<CyclotronProductCreateModal
					onDismiss={handleDismiss}
				/>;
		}

		return modal;
	};

	const showTabContent = (): JSX.Element => {

		let tabContent: JSX.Element;

		switch (tabId) {
			case  Route.GENERATORS:
				tabContent =  <GeneratorsView />;
				break;
			case Route.CYCLOTRON_PRODUCTS:
				tabContent =  <CyclotronProductsView />;
				break;
			case Route.WASTE_MANAGEMENT:
				tabContent =  <WasteManagementView />;
				break;
			case Route.CONTAINERS:
				tabContent =  <WasteManagementView />;
				break;
			  case Route.WASTE_REPORTS:
				tabContent =  <WasteManagementReportsView />;
				break;
			default:
				tabContent =  <GeneratorsView />;
				setTabId(Route.GENERATORS);
				break;
		}
		return tabContent;
	};

	const changeTab = (selectedTabId: Route) => {
		setTabId(selectedTabId);
		history.push(`${Route.NUCLIDE_MANAGEMENT}${selectedTabId}`);
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						<SiteTitle
							title={l10nContext.translate('navigation.nuclideManagement', 'Nuklidverwaltung')}
							button={showAddButton()}
							isTransparent={true}
						/>
						{showModal()}
					</ViewLayoutSection>

					<TabBar>
						<TabBarItem
							label={l10nContext.translate('view.generators.title', 'Generatoren')}
							isActive={tabId === Route.GENERATORS}
							onClick={() => changeTab( Route.GENERATORS)}
						/>
						<TabBarItem
							label={l10nContext.translate('view.cyclotronProducts.title', 'Andere Radionuklide')}
							isActive={tabId === Route.CYCLOTRON_PRODUCTS}
							onClick={() => changeTab(Route.CYCLOTRON_PRODUCTS)}

						/>
						<TabBarItem
							label={l10nContext.translate('view.wastemanagement.title', 'Abfallmanagement')}
							isActive={tabId === Route.WASTE_MANAGEMENT || tabId === Route.CONTAINERS}
							onClick={() => changeTab(Route.WASTE_MANAGEMENT)}
						/>
						<TabBarItem
							label={l10nContext.translate('view.wastemanagementReports.title', 'Jahresmeldungen')}
							isActive={tabId === Route.WASTE_REPORTS}
							onClick={() => changeTab(Route.WASTE_REPORTS)}
						/>
					</TabBar>
					{showTabContent()}

				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>

		</MainLayout>
	);
};
