import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import { SequenceStoreData, SequenceViewData } from 'services/device/domain/business/common/sequence/SequenceData';
import { DeviceType } from 'services/device/domain/business/inventory/DeviceType';
import { SequenceType } from 'services/device/domain/business/inventory/SequenceType';
import { SequenceModelState } from 'services/device/domain/model/SequenceModelState';
import { ViolationError } from '../business/common/violation/ViolationError';
import { getSequenceDefinition } from '../business/inventory/SequenceTypeDefinition';
import { SequenceMemory } from './SequenceMemory';

export type SequenceModelUuid = string;

export interface SequencePersistenceModel extends PersistenceModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: string;
	Deletable: boolean;
	State: string;
	Type: string;
	Name: string;
	NotificationMailAddresses: Array<string>;
	DownloadUrl: string;
	SequenceConfiguration: SequenceStoreData;
}

export interface SequenceStoreModel extends StoreModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: string;
	Deletable: boolean;
	State: string;
	Type: string;
	Name: string;
	NotificationMailAddresses: Array<string>;
	DownloadUrl: string;
	SequenceConfiguration: SequenceStoreData;
	SequenceMemory: SequenceMemory;
}

export interface SequenceViewModel extends ViewModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: DeviceType;
	Deletable: boolean;
	State: SequenceModelState;
	Type: SequenceType;
	Name: string;
	NotificationMailAddresses: Array<string>;
	DownloadUrl: string;
	SequenceConfiguration: SequenceViewData;
	SequenceMemory: SequenceMemory;
}

export interface CreatableSequenceViewModel extends CreatableViewModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: DeviceType;
	Type: SequenceType;
	Name: string;
	NotificationMailAddresses: Array<string>;
	SequenceConfiguration: SequenceViewData;
}

export class SequenceModelConverter extends ModelConverter<SequencePersistenceModel, SequenceStoreModel, SequenceViewModel, CreatableSequenceViewModel> {

	public fromViewModel(viewModel?: SequenceViewModel): this {
		super.fromViewModel(viewModel, (vModel, model) => {
			model.Name = vModel.SequenceConfiguration.values.name;
			model.NotificationMailAddresses = vModel.SequenceConfiguration.values.notificationMailAddresses;
			const sequenceDefinition = getSequenceDefinition(vModel.DeviceType, vModel.Type);
			model.SequenceConfiguration = sequenceDefinition.restoreSequenceFromViewData(vModel.SequenceConfiguration).toStoreData();
		});

		return this;
	}

	public fromCreatableViewModel(creatableViewModel?: CreatableSequenceViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			model.Deletable = true;
			model.State = SequenceModelState.ACTIVE;
		});
	}

	public toViewModel(): SequenceViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.State = model?.State as SequenceModelState ?? null;
			vModel.Type = model?.Type as SequenceType ?? null;
			vModel.DeviceType = model?.DeviceType as DeviceType ?? null;

			const sequenceDefinition = getSequenceDefinition(vModel.DeviceType, vModel.Type);
			try {
				vModel.SequenceConfiguration = sequenceDefinition.restoreSequenceFromStoreData(model.SequenceConfiguration).toViewData();
			} catch (e) {
				if (e instanceof ViolationError) {
					console.warn('Sequence violation', vModel.Uuid, vModel.Name, e.getViolations());
				}
			}
		});
	}

}
