export enum ContainerViewModalType {
	EDIT = 'EDIT',
	CREATE = 'CREATE',
	MEASURE = 'MEASURE',
	DISPATCH = 'DISPATCH',
	DELETE = 'DELETE',
	ASSIGN_NUCLIDES = 'ASSIGN_NUCLIDES',
	CHOOSE_CONTAINER = 'CHOOSE_CONTAINER',
	STATION_MEASUREMENT = 'STATION_MEASUREMENT',
	FACTOR_MEASUREMENT = 'FACTOR_MEASUREMENT',
	CONTAMINATION_MEASUREMENT = 'CONTAMINATION_MEASUREMENT',
	RELEASE = 'RELEASE',
	DISPOSE = 'DISPOSE'
}
