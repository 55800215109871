import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ClassName } from 'lib/class-name/ClassName';

import { NumberFieldDescription } from 'services/device/domain/business/common/description/FieldDescription';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { RecordViewModel } from 'services/device/domain/model/RecordModel';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';

import './record-comment-modal.scss';
import { FieldType } from 'services/device/domain/business/common/field/FieldType';
import { ValuedString } from 'services/device/domain/business/common/misc/ValuedString';
import { ReportRecordViewModel } from '../../../../../report/domain/model/ReportRecordModel';

export interface RecordCommentModalProps {
	record: RecordViewModel | ReportRecordViewModel;
	onDismiss?: () => void;
}

export const RecordCommentModal = (props: RecordCommentModalProps): JSX.Element => {
	const onDismiss = props.onDismiss;
	const recordViewModel = props.record;

	const l10nContext = useContext(L10nContext);

	const sequenceTypeDfinition = getSequenceTypeDefinition(recordViewModel.SequenceType);

	const tableDescriptions = sequenceTypeDfinition.getTableDescriptions();
	if (tableDescriptions.length === 0) {
		return null;
	}
	const tableDescription = tableDescriptions[0];

	const record = sequenceTypeDfinition.restoreRecordFromViewData(recordViewModel.RecordConfiguration);

	const valueDescription = tableDescription.value as NumberFieldDescription;
	const valueNumberOfDeciamsl = valueDescription?.numberOfDecimals ?? 2;

	const output = record.getOutput(valueDescription);

	let formattedValue: string;
	switch (output.getDescription().type) {
		case FieldType.NUMBER:
			formattedValue = l10nContext.formatNumber(output.getSingle() as number, valueNumberOfDeciamsl);
			break;
		case FieldType.BOOLEAN:
			formattedValue = l10nContext.formatBoolean(output.getSingle() as boolean);
			break;
		case FieldType.DATE_TIME:
			formattedValue = l10nContext.formatDateTime(output.getSingle() as Date);
			break;
		case FieldType.VALUED_STRING:
			formattedValue = (output.getSingle() as ValuedString).value;
			break;
		default:
			formattedValue = output.getSingle() as string;
			break;
	}

	const recordedByLabel = recordViewModel.Supplemented
		? 'view.sequence.modal.action.recordComment.supplementedBy'
		: 'view.sequence.modal.action.recordComment.recordedBy';

	const renderUpdatedBy = (): JSX.Element | null => {
		if ((recordViewModel?.UpdatedByName ?? null) === null) {
			return null;
		}
		return (
			<div className="record-comment-modal__header__column">
				<span className="record-comment-modal__label">
					{l10nContext.translate('view.sequence.modal.action.recordComment.updatedBy')}
				</span>
				<span className="record-comment-modal__value">
					{l10nContext.translate(recordViewModel.UpdatedByName)}
				</span>
			</div>
		);
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX} onDismiss={onDismiss}>
			<div className="record-comment-modal">
				<div className="record-comment-modal__header">

					<div className="record-comment-modal__header__column">
						<span className="record-comment-modal__label">
							{l10nContext.translate(tableDescription.value.labelKey)}
						</span>
						<span className={`record-comment-modal__value record-comment-modal__value--record-${ClassName.fromEnumValue(record.toValuation())}`}>
							{formattedValue}
						</span>
					</div>

					<div className="record-comment-modal__header__column">
						<span className="record-comment-modal__label">
							{l10nContext.translate('view.sequence.modal.action.recordComment.recordedAt')}
						</span>
						<span className="record-comment-modal__value">
							{l10nContext.formatDateTime(recordViewModel.RecordedAt)}
						</span>
					</div>
				</div>
				<div className="record-comment-modal__header">
					<div className="record-comment-modal__header__column">
						<span className="record-comment-modal__label">
							{l10nContext.translate(recordedByLabel)}
						</span>
						<span className="record-comment-modal__value">
							{l10nContext.translate(recordViewModel.RecordedByName)}
						</span>
					</div>
					{renderUpdatedBy()}
				</div>
				<div className="record-comment-modal__body">
					<span className="record-comment-modal__label">
						{l10nContext.translate('view.sequence.modal.action.recordComment.comment')}
					</span>
					<span className="record-comment-modal__value">
						{recordViewModel.RecordConfiguration.values.comment}
					</span>
				</div>
			</div>
		</ModalDialogue>
	);
};
