import { useContext, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { SequenceUpdateForm } from 'services/device/presentation/ui/sequence-action/sequence-update-form/SequenceUpdateForm';
import { SequenceUpdateFailedNotification } from 'services/device/presentation/ui/sequence-action/sequence-update-modal/SequenceUpdateFailedNotification';
import { SequenceUpdateSuccessNotification } from 'services/device/presentation/ui/sequence-action/sequence-update-modal/SequenceUpdateSuccessNotification';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

interface SequenceUpdateModalProps {
	sequence: SequenceViewModel;
	onDismiss: () => void;
}

/**
 * The device create modal
 */
export const SequenceUpdateModal = (props: SequenceUpdateModalProps): JSX.Element => {
	const { sequence, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use state to hold error objects
	const [caughtError, setCaughtError] = useState<Error>(null);

	// Use selector to provide information from store
	const sequenceActionStatus = useTypedSelector(state => state.sequences.actionStatus);

	const renderModal = () => {
		if (caughtError !== null) {
			return (
				<SequenceUpdateFailedNotification
					onClickConfirm={onDismiss}
				/>
			);
		}

		let modal: JSX.Element = null;

		switch (sequenceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <SequenceUpdateForm
					sequence={sequence}
					onError={(error) => {
						setCaughtError(error);
					}}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modal =
					<SequenceUpdateSuccessNotification
						onClickConfirm={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<SequenceUpdateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.sequence.modal.action.edit.caption', 'Messreihe bearbeiten')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
