import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	WasteManagementReportModelConverter as ModelConverter,
	WasteManagementReportPersistenceModel as PersistenceModel,
	WasteManagementReportStoreModel as StoreModel,
	WasteManagementReportViewModel as ViewModel,
	CreatableWasteManagementReportViewModel as CreatableViewModel
} from 'services/waste-management-report/domain/models/WasteManagementReportModel';

export class WasteManagementReportHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'waste-management-report/api/v1';
	protected collectionControllerUri = 'waste-management-reports';
	protected entityControllerUri = 'waste-management-report';

	protected responseCollectionKey = 'WasteManagementReportModelCollection';
	protected responseEntityKey = 'WasteManagementReportModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
