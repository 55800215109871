import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ModalDialogueCaptionModifier, ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogueSectionName } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSectionName';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { FolderViewModel } from 'services/documents/domain/model/FolderModel';

export interface FolderArchiveFormProps {
	folder: FolderViewModel;
	onUpdate: () => void;
	onDismiss: () => void;
}

export const FolderArchiveForm = (props: FolderArchiveFormProps): JSX.Element => {
	const { folder, onUpdate, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const modalMessage = {
		first: l10nContext.translate('view.documents.modal.action.archive.message.first', 'Wollen Sie den Ordner'),
		last: l10nContext.translate('view.documents.modal.action.archive.message.last', 'wirklich archivieren?')
	};

	return (
		<>
			<ModalDialogueHeader
				captionText={l10nContext.translate('common.modal.captionAttention', 'Achtung')}
				captionIcon={IconIdentifier.ALERT}
				captionModifier={ModalDialogueCaptionModifier.SMALL_CAPTION}
			/>
			<ModalDialogueSection>
				<>
					{modalMessage.first} <b>{folder.Name}</b> {modalMessage.last}
				</>
			</ModalDialogueSection>

			<ModalDialogueSection section={ModalDialogueSectionName.SECTION_FOOTER}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.abort', 'Abbrechen')}
							onClick={onDismiss}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.modal.archiveConfirm', 'Ja, archivieren')}
							status={ButtonSecondaryStatus.ATTENTION}
							icon={IconIdentifier.ARCHIVE}
							onClick={onUpdate}
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</ModalDialogueSection>
		</>
	);
};
