import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

import { CardItemTagType } from 'presentation/ui/partials/card/CardItemTagType';
import { Tag } from 'presentation/ui/partials/tag/Tag';

import './card-item-sequence.scss';

export interface CardItemSequenceProps {
	/**
	 * Tag
	 */
	tag: CardItemTagType;
	sequence:SequenceViewModel;
}

/**
 * A card item sequence component
 */
export const CardItemSequence = (props: CardItemSequenceProps): JSX.Element => {
	const { tag } = props;

	const tagElement = tag ?
		<Tag
			label={tag.label}
			type={tag.type}
		/> :
		null;

	return (
		<div className='card-item-sequence'>
			{tagElement}
		</div>
	);
};
