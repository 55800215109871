import { Optional } from '../misc/Optional';
import { Value } from './Value';

const MILLISECONDS_PER_HOUR = 60 * 60 * 1000;

export class Hours implements Value<number> {
	private readonly result: Optional<number>;

	public constructor(fromValue: Value<Date>, toValue: Value<Date>) {
		this.result = calculate(fromValue.getSingle(), toValue.getSingle());
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<number> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<number> {
		return this.result;
	}
}

function calculate(fromValue: Optional<Date>, toValue: Optional<Date>): Optional<number> {
	if (fromValue !== null && toValue !== null) {
		return (toValue.getTime() - fromValue.getTime()) / MILLISECONDS_PER_HOUR;
	}
	return null;
}
