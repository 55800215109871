import { InputWidget } from 'services/device/domain/business/common/widget/Widget';
import { InputWidgetSelection } from 'services/device/presentation/ui/widget/widget-renderer/InputWidgetSelection';

import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { Sequence } from '../../../../domain/business/common/sequence/Sequence';

export interface InputWidgetProps {
	widget: InputWidget;
	sequence?: Sequence;
}

export const InputWidgetRenderer = (props: InputWidgetProps): JSX.Element => {
	const { widget, sequence } = props;

	const inputNodes: Array<JSX.Element> = [];

	for (const inputField of widget.getInputs()) {
		inputField.selectInput(
			new InputWidgetSelection((element): void => {
				inputNodes.push(
					<FormLayoutSection key={inputField.getName()}>
						{element}
					</FormLayoutSection>
				);
			}, sequence)
		);
	}

	return (
		<>
			{inputNodes}
		</>
	);

};
