import { ComponentPropsWithoutRef } from 'react';

import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './input-text.scss';

export enum InputTextStatus {
	ERROR = 'ERROR',
	NEGATIVE = 'NEGATIVE',
	POSITIVE = 'POSITIVE',
	READONLY = 'READONLY',
	SUMMARY = 'SUMMARY',
	WARN = 'WARN'
}

/**
 * Input text type
 */
type InputTextType = 'text' | 'password' | 'url'| 'email' | 'number' ;
export interface InputTextProps extends ComponentPropsWithoutRef<'input'> {
	/**
	 * (Predefined) Type of input
	 */
	type?: InputTextType,
	/**
	 * Status of input
	 */
	status?: InputTextStatus;
	/**
	 * (Optional) icon
	 */
	icon?: IconIdentifier;
	/**
	 * (Optional) id
	 */
	id?: string;
}

/**
 * A nearby native input text component.
 * You can add all additional attributes you know from native HTML input text element,
 * like f.e. maxlength, form, disabled, onChange-handler etc.
 */
export const InputText = (props: InputTextProps): JSX.Element => {
	const { status, icon, type = 'text', id, ...restProps } = props;

	const inputIcon = icon ? <Icon name={icon} /> : null;

	const stateClass = status ?
		`input-text--${status.toLowerCase()}` :
		'';

	return (
		<div className={`input-text ${stateClass} ${props.disabled ? 'input-text--disabled' : ''}`}>
			<div className='input-text__wrapper'>
				<input
					className={`input-text__input ${icon ? 'input-text__input--cropped' : ''} `}
					type={type}
					{...restProps}
					id={id}
				/>
				{inputIcon}
			</div>
		</div>
	);
};
