import { L10nContext } from 'context/L10nContext';
import { CardCyclotronProductDetail } from 'presentation/ui/components/cards/card-cyclotron-product/card-cyclotron-product-detail/CardCyclotronProductDetail';
import { CardItemCyclotronProductAttributes } from 'presentation/ui/partials/card/card-item-attributes/card-item-cyclotron-product-attributes/CardItemCyclotronProductAttributes';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardCyclotronProductListItemHeader } from 'presentation/ui/partials/card/card-item-header/card-cyclotron-product-list-item-header/CardCyclotronProductListItemHeader';
import { useContext } from 'react';
import { getCyclotronProductIsotopeDefinition } from 'services/nuclide/domain/model/isotopes/NuclideIsotopeDefinition';
import { CyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';
import { CyclotronProductModelState } from 'services/nuclide/domain/model/CyclotronProductModelState';
import { CyclotronProductViewModalType } from '../../view/CyclotronProductViewModalType';

interface CyclotronProductCardProps {
	cyclotronProduct: CyclotronProductViewModel;
	userPermissionEdit: boolean, 
	onClick: (modalType: CyclotronProductViewModalType) => void;
}

export const CyclotronProductCard = (props: CyclotronProductCardProps): JSX.Element => {
	const { cyclotronProduct, userPermissionEdit, onClick } = props;

	const l10nContext = useContext(L10nContext);

	return (
		<CardCyclotronProductDetail>
			<CardCyclotronProductListItemHeader
				title={cyclotronProduct.Name}
				subtitle={l10nContext.translate(getCyclotronProductIsotopeDefinition(cyclotronProduct.Isotope).getLabelKey())}
				state={cyclotronProduct.State}
			/>
			<CardItemSingleControl
				cardId={cyclotronProduct.Uuid}
				actionEdit={userPermissionEdit && cyclotronProduct.State === CyclotronProductModelState.ACTIVE ?
					CardItemControlState.ENABLED :
					CardItemControlState.DISABLED}
				onClick={() => onClick(CyclotronProductViewModalType.EDIT)}
			/>
			<CardItemCyclotronProductAttributes
				cyclotronProduct={cyclotronProduct}
			/>
		</CardCyclotronProductDetail>
	);
};
