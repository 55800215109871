import { ComponentPropsWithoutRef } from 'react';

import { ClassName } from 'lib/class-name/ClassName';

import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayoutSectionWidth } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionWidth';

import './main-layout-section.scss';

export interface MainLayoutSectionProps extends ComponentPropsWithoutRef<'section'> {
	section?: MainLayoutSectionSection;
	width?: MainLayoutSectionWidth;
}

export const MainLayoutSection = (props: MainLayoutSectionProps): JSX.Element => {

	const { section = MainLayoutSectionSection.SECTION_MAIN, width = MainLayoutSectionWidth.WIDTH_WIDE } = props;

	const sectionModifier = ClassName.fromEnumValue(String(section));
	const widthModifier = ClassName.fromEnumValue(String(width));

	return (
		<section className={`main-layout-section main-layout-section--${sectionModifier} main-layout-section--${widthModifier}`}>
			{props.children}
		</section>
	);

};
