import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { L10nContext } from 'context/L10nContext';

import { Guid } from 'lib/guid/Guid';

import './breadcrumbs.scss';

export type BreadcrumbFragment = {
	label: string,
	url: string
};

export interface BreadcrumbsProps {
	fragments?: BreadcrumbFragment[];
	mapURLFragments?: Array<[string, string]>;
}

export const Breadcrumbs = (props: BreadcrumbsProps): JSX.Element => {
	const { fragments = [], mapURLFragments = [] } = props;

	const l10nContext = useContext(L10nContext);
	const { pathname } = useLocation();

	const pathItems = pathname.split('/').filter((pathItem): boolean => {
		return pathItem.length > 0;
	});

	const createFragments = (): Array<JSX.Element> => (
		fragments.map(fragment => (
			<Link
				key={Guid.generate()}
				to={{ pathname: fragment.url }}
			>
				{fragment.label}
			</Link>
		))
	);

	const breadcrumbItems = (): Array<JSX.Element> => {
		if (pathItems.length <= 1) {
			return [];
		}

		return pathItems.map((pathItem, index): JSX.Element => {
			const url = '/' + pathItems.slice(0, index + 1).join('/');

			let label = l10nContext.translate(`breadcrumbs.${pathItem}`, pathItem);
			for (const mapURLFragment of mapURLFragments) {
				if (pathItem === mapURLFragment[0]) {
					// eslint-disable-next-line prefer-destructuring
					label = mapURLFragment[1];
					break;
				}
			}

			return (
				<Link key={pathItem} to={{ pathname: url }}>
					{label}
				</Link>
			);
		});
	};

	return (
		<div className='breadcrumbs'>
			{
				fragments && fragments?.length !== 0 ?
					createFragments() :
					breadcrumbItems()
			}
		</div>
	);
};
