import { memo, useContext } from 'react';
import { L10nContext } from '../../../../../context/L10nContext';
import { Guid } from '../../../../../lib/guid/Guid';
import {
	getCyclotronProductIsotopeDefinition, getNuclideIsotopeDefinition
} from '../../../../nuclide/domain/model/isotopes/NuclideIsotopeDefinition';
import {
	getCyclotronProductMeasurementUnitDefinition
} from '../../../../nuclide/domain/model/NuclideMeasurementUnitDefinition';

import { WasteManagementReportCyclotronProductViewModel } from '../../../domain/models/WasteManagementReportCyclotronProductModel';
import { CyclotronProductsByNuclide } from '../../view/CyclotronProductsByNuclideType';
import './external-waste-management-report-cyclotron-product-detail.scss';

export interface ExternalWasteManagementReportCyclotronProductAnnualReportProps {
	cyclotronProductsByNuclides: Array<CyclotronProductsByNuclide>
}

export const ExternalWasteManagementReportCyclotronProductDetail
	= memo((props: ExternalWasteManagementReportCyclotronProductAnnualReportProps): JSX.Element => {
		const { cyclotronProductsByNuclides } = props;

		const l10nContext = useContext(L10nContext);

		const renderNuclideDetails = (): JSX.Element[] => {

			return (
				cyclotronProductsByNuclides.map((cyclotronProductsByNuclide): JSX.Element => {

					const translations = {
						nuclide: { label: l10nContext.translate('externalWasteManagementView.cyclotronProducts.nuclide', 'Nuklid'),
							value: l10nContext.translate(getCyclotronProductIsotopeDefinition(cyclotronProductsByNuclide.Nuclide).getLabelKey()) }
					};

					const attributes = () => {
						return Object.entries(translations).map((translation) => {
							const { label, value } = translation[1];

							const styleClass = 'attribute';

							return (
								<label
									key={Guid.generate()}
									className={styleClass}
								>
									<strong>{label}:&nbsp;</strong>
									<p>{value }</p>
								</label>
							);
						});
					};

					const listOfCyclotronProducts = () => {
						return cyclotronProductsByNuclide.CyclotronProducts.map((cyclotronProduct: WasteManagementReportCyclotronProductViewModel) => {
							return (
								<p key={cyclotronProduct.Uuid}>
									{ cyclotronProduct.Name
										+ ', '
										+ l10nContext.translate('view.singleNuclides.cyclotronProductFrom', 'Radionuklid vom ')
										+ l10nContext.formatDateTime(cyclotronProduct.DeliveryDate)
										+ ', '
										+ l10nContext.translate(getNuclideIsotopeDefinition(cyclotronProduct.Isotope).getLabelKey())
										+ l10nContext.translate('view.singleNuclides.nominalActivity', ', Nominalaktivität: ')
										+ l10nContext.formatNumber(cyclotronProduct.NominalActivity)
										+ ' '
										+ l10nContext.translate(getCyclotronProductMeasurementUnitDefinition(cyclotronProduct.ActivityUnit).getLabelKey())}
								</p>
							);
						});
					};

					return (
						<div
							key={Guid.generate()}
							className='external-waste-management-report-cyclotron-product-detail__information'
						>
							<div
								className='external-waste-management-report-cyclotron-product-detail__information__attributes'
							>
								{attributes()}
							</div>
							<div className='external-waste-management-report-cyclotron-product-detail__information__items'>
								{ listOfCyclotronProducts() }
							</div>
						</div>

					);
				})
			);
		};

		return (
			<div className='external-waste-management-report-cyclotron-product-detail'>
				{renderNuclideDetails()}
			</div>
		);
	});
