import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import { DeviceType } from 'services/device/domain/business/inventory/DeviceType';

import {
	DeviceModelConverter as ModelConverter,
	DeviceStoreModel as StoreModel,
	DeviceViewModel as ViewModel
} from 'services/device/domain/model/DeviceModel';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import { ExternalReportDeviceHttpPersistence } from '../../report/persistence/ExternalReportDeviceHttpPersistence';

// Declare a device state type
export interface ExternalReportDeviceState {
	externalReportDevices: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	externalReportDevices: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ExternalReportDeviceState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the

export const fetchExternalReportDevices = createAsyncThunk(
	'external-report-devices/fetch',
	async (params: { reportUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new ExternalReportDeviceHttpPersistence(params.reportUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Sliently abort the action
			const { externalReportDevices } = getState() as RootState;
			return checkFetchStatus(externalReportDevices.fetchStatus);
		}
	}
);

// Slice definition
export const externalReportDevicesSlice = createSlice({
	name: 'external-report-devices',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchExternalReportDevices.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalReportDevices.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.externalReportDevices = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalReportDevices.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
	}
});

export const { resetState, resetActionStatus } = externalReportDevicesSlice.actions;

// Export the reducer as default
export default externalReportDevicesSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectExternalReportDevices = (): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.externalReportDevices.externalReportDevices.filter((storeModel): boolean => {
			return storeModel.Type !== DeviceType.CLEARANCE;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalReportClearances = (): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.externalReportDevices.externalReportDevices.filter((storeModel): boolean => {
			return storeModel.Type === DeviceType.CLEARANCE;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalReportDeviceByUuid = (deviceUuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.externalReportDevices.externalReportDevices.find((sModel): boolean => {
			return sModel.Uuid === deviceUuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};
