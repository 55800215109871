import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { updateContainer } from '../../../../store/containerSlice';
import { ContainerUpdateFormStep1 } from './ContainerUpdateFormStep1';
import { ContainerUpdateFormStep2 } from './ContainerUpdateFormStep2';

export interface ContainerUpdateFormProps {
	container?: ContainerViewModel;
}

export const ContainerUpdateForm = (props: ContainerUpdateFormProps): JSX.Element => {
	const { container } = props;

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const updatedContainer: ContainerViewModel = container;

	const requiredInputs = ['Name'];

	const onChangeContainerProp = (prop: string, value: any) => {
		updatedContainer[prop] = value;
	};

	const handleUpdate = (): void => {
		dispatch(updateContainer(updatedContainer));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newContainerModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedContainer?.[requiredInput] ?? null) === null
				|| updatedContainer?.[requiredInput] === ''
				|| String(updatedContainer.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newContainerModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newContainerModelValid);
	};

	const showUpdateModalStep = showStep1 === true ?
		<ContainerUpdateFormStep1
			container={updatedContainer}
			onClickNext={goToStep2}
			onChangeContainerProp={onChangeContainerProp}
			invalidInputs={invalidInputs}
		/> :
		<ContainerUpdateFormStep2
			container={updatedContainer}
			onClickPrevious={goToStep1}
			onClickConfirm={handleUpdate}
		/>;

	return (
		<>
			{showUpdateModalStep}
		</>
	);
};
