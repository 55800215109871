import { L10nContext } from 'context/L10nContext';
import { ClassName } from 'lib/class-name/ClassName';
import { Guid } from 'lib/guid/Guid';

import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ComponentPropsWithRef, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { NumberFieldDescription } from 'services/device/domain/business/common/description/FieldDescription';
import { FieldType } from 'services/device/domain/business/common/field/FieldType';
import { ValuedString } from 'services/device/domain/business/common/misc/ValuedString';
import { Record } from 'services/device/domain/business/common/record/Record';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';
import { RecordViewModel } from 'services/device/domain/model/RecordModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { SequenceModelState } from 'services/device/domain/model/SequenceModelState';
import { SequencePeriodContext } from 'services/device/presentation/ui/sequence-records/context/SequencePeriodContext';
import { SequenceTableHead } from 'services/device/presentation/ui/sequence-table/sequence-table-head/SequenceTableHead';
import { TableCellValueRenderer } from 'services/device/presentation/ui/widget/widget-renderer/TableCellValueRenderer';
import { fetchRecordsBySequence, selectRecordsBySequenceWithPeriod } from 'services/device/store/recordSlice';
import { fetchDocuments } from 'services/documents/store/documentSlice';
import { DebugConsole } from '../../../../../lib/debug/DebugConsole';
import { AsyncFetchStatus } from '../../../../../store/common/AsyncFetchStatus';
import { useTypedSelector } from '../../../../../store/common/TypedSelector';
import { ClientContext } from '../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../core/context/FacilityContext';

import './sequence-table.scss';

export interface SequenceTableProps extends ComponentPropsWithRef<any> {
	device: DeviceViewModel;
	sequence: SequenceViewModel;
	highlightedRecord?: RecordViewModel;
	isReportView?: boolean;
	onShowComment: (record: RecordViewModel) => void;
	onAttachments: (record: RecordViewModel) => void;
	onEdit: (record: RecordViewModel) => void;
	onDelete: (record: RecordViewModel) => void;
}

export const SequenceTable = (props: SequenceTableProps): JSX.Element | null => {

	const { highlightedRecord = null, onShowComment, onAttachments, onEdit, onDelete } = props;
	const deviceViewModel = props.device;
	const sequenceViewModel = props.sequence;

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Use a custom selector function to provide type information for the state defined in the slice
	const recordsStoreFetchStatus = useTypedSelector(state => state.records.fetchStatus);
	const documentsStoreFetchStatus = useTypedSelector(state => state.documents.fetchStatus);

	// Read records from the server
	useEffect(() => {
		if (recordsStoreFetchStatus === AsyncFetchStatus.INITIAL || recordsStoreFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchRecordsBySequence({
				clientUuid: clientContext.selectedClientUuid,
				facilityUuid: facilityContext.selectedFacilityUuid,
				sequenceUuid: props.sequence.Uuid
			}));
		}
	});

	useEffect(() => {
		if (documentsStoreFetchStatus === AsyncFetchStatus.INITIAL || documentsStoreFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchDocuments({
				clientUuid: clientContext.selectedClientUuid,
				facilityUuid: facilityContext.selectedFacilityUuid
			}));
		}
	});

	const onHighlightChange = useCallback(tableRow => {
		if (tableRow !== null) {
			tableRow.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	const sequencePeriodContext = useContext(SequencePeriodContext);

	// Select the records from the store
	const records = useSelector(selectRecordsBySequenceWithPeriod(sequenceViewModel, sequencePeriodContext.dateStart, sequencePeriodContext.dateEnd, false));

	DebugConsole.log('records', records);

	if (records.length === 0) {
		return null;
	}

	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permission: {
			viewRecord: authContext.hasPermission(Permission.RECORD_VIEW),
			editRecord: authContext.hasPermission(Permission.RECORD_UPDATE),
			supplementRecord: authContext.hasPermission(Permission.RECORD_SUPPLEMENT),
			addDocumentRecord: authContext.hasPermission(Permission.RECORD_UPDATE_ADD_DOCUMENT),
			deleteRecord: authContext.hasPermission(Permission.RECORD_DELETE)
		}
	};

	// Prepare the sequence type definition
	const sequenceTypeDefinition = getSequenceTypeDefinition(sequenceViewModel.Type);
	const tableDescriptions = sequenceTypeDefinition.getTableDescriptions();
	if (tableDescriptions.length === 0) {
		return null;
	}
	const tableDescription = tableDescriptions[0];

	const valueDescription = tableDescription.value as NumberFieldDescription;
	const deviationDescription = tableDescription.deviation as NumberFieldDescription;
	const expectedValueDescription = tableDescription.expectedValue as NumberFieldDescription;

	const valueNumberOfDecimals = valueDescription?.numberOfDecimals ?? 2;
	const expectedValueNumberOfDecimals = expectedValueDescription?.numberOfDecimals ?? 2;
	const deviationNumberOfDecimals = deviationDescription?.numberOfDecimals ?? 2;

	const hasDeviation = (tableDescription.deviation ?? null) !== null;
	const hasExpectedValue = (tableDescription.expectedValue ?? null) !== null;
	const hasSoftThreshold = (tableDescription.lowerReactionValue ?? null) !== null || (tableDescription.upperReactionValue ?? null) !== null;
	const hasHardThreshold = (tableDescription.lowerToleranceValue ?? null) !== null || (tableDescription.upperToleranceValue ?? null) !== null;

	const renderDeviation = (record: Record): JSX.Element | null => {
		if (!hasDeviation) {
			return null;
		}

		const expectedValue = record.getOutput(expectedValueDescription).getSingle() as number;

		let formattedAbsoluteDeviation: string = null;
		let formattedRelativeDeviation: string = null;

		if (deviationDescription !== null) {
			const deviationValue = record.getOutput(deviationDescription).getSingle() as number;
			formattedAbsoluteDeviation = l10nContext.formatNumber(deviationValue, deviationNumberOfDecimals);
			if (deviationValue > 0) {
				formattedAbsoluteDeviation = '+' + formattedAbsoluteDeviation;
			}
			const relativeDeviation = (deviationValue / expectedValue) * 100;
			formattedRelativeDeviation = l10nContext.formatNumber(relativeDeviation, 2);
			if (deviationValue > 0) {
				formattedRelativeDeviation = '+' + formattedRelativeDeviation;
			}
		}

		return (
			<td className="sequence-table__body__row__cell sequence-table__body__row__cell--align-right">
				{formattedAbsoluteDeviation}
				<br />
				<small>
					{expectedValue !== 0 ? formattedRelativeDeviation + '%' : null}
				</small>
			</td>
		);
	};

	const renderExpectedValue = (record: Record): JSX.Element | null => {
		if (!hasExpectedValue) {
			return null;
		}

		let formattedExpectedValue: string = null;
		if (expectedValueDescription !== null) {
			const expectedValue = record.getOutput(expectedValueDescription).getSingle() as number;
			formattedExpectedValue = l10nContext.formatNumber(expectedValue, expectedValueNumberOfDecimals);
		}

		return (
			<td className="sequence-table__body__row__cell sequence-table__body__row__cell--align-right">
				{formattedExpectedValue}
			</td>
		);
	};

	const renderThresholdValue = (record: Record, fieldDescription: NumberFieldDescription): string => {
		const thresholdValue = record.getOutput(fieldDescription).getSingle() as number;
		const numberOfDecimals = fieldDescription?.numberOfDecimals ?? 2;
		return l10nContext.formatNumber(thresholdValue, numberOfDecimals);
	};

	const renderSoftThreshold = (record: Record): JSX.Element | null => {
		if (!hasSoftThreshold) {
			return null;
		}
		const formattedThresholds = [];
		if ((tableDescription?.lowerReactionValue ?? null) !== null) {
			formattedThresholds.push(renderThresholdValue(record, tableDescription.lowerReactionValue));
		}
		if ((tableDescription?.upperReactionValue ?? null) !== null) {
			formattedThresholds.push(renderThresholdValue(record, tableDescription.upperReactionValue));
		}
		if (formattedThresholds.length === 0) {
			return (
				<td className="sequence-table__body__row__cell">
					&nbsp;
				</td>
			);
		}
		const formattedThresholdElements = formattedThresholds.map((formattedThreshold: string): JSX.Element => {
			return (<li key={Guid.generate()}>{formattedThreshold}</li>);
		});
		return (
			<td className="sequence-table__body__row__cell sequence-table__body__row__cell--align-right">
				<ul className="sequence-table__body__row__cell__value--soft-threshold">
					{formattedThresholdElements}
				</ul>
			</td>
		);
	};

	const renderHardThreshold = (record: Record): JSX.Element | null => {
		if (!hasHardThreshold) {
			return null;
		}
		const formattedThresholds = [];
		if ((tableDescription?.lowerToleranceValue ?? null) !== null) {
			formattedThresholds.push(renderThresholdValue(record, tableDescription.lowerToleranceValue));
		}
		if ((tableDescription?.upperToleranceValue ?? null) !== null) {
			formattedThresholds.push(renderThresholdValue(record, tableDescription.upperToleranceValue));
		}
		if (formattedThresholds.length === 0) {
			return (
				<td className="sequence-table__body__row__cell">
					&nbsp;
				</td>
			);
		}
		const formattedThresholdElements = formattedThresholds.map((formattedThreshold: string): JSX.Element => {
			return (<li key={Guid.generate()}>{formattedThreshold}</li>);
		});
		return (
			<td className="sequence-table__body__row__cell sequence-table__body__row__cell--align-right">
				<ul className="sequence-table__body__row__cell__value--hard-threshold">
					{formattedThresholdElements}
				</ul>
			</td>
		);
	};

	const renderAdditionalColumns = (record: Record): JSX.Element | null => {
		const additionalColumns = tableDescription?.additionalColumns ?? null;
		if (additionalColumns === null || additionalColumns.length === 0) {
			return null;
		}
		return (
			<TableCellValueRenderer record={record} fieldDescriptions={additionalColumns} />
		);
	};

	const renderActionCell = (record: RecordViewModel): JSX.Element | null => {
		if (
			!authContext.hasPermission(Permission.RECORD_UPDATE)
			&& !authContext.hasPermission(Permission.RECORD_DELETE)
			|| props.isReportView
		) {
			return null;
		}

		const editButton = (): JSX.Element | null => {
			if (!authContext.hasPermission(Permission.RECORD_UPDATE)) {
				return null;
			}
			return (
				<ButtonIcon
					icon={IconIdentifier.EDIT}
					weight={ButtonIconWeight.GHOST}
					shape={ButtonIconShape.ROUND}
					onClick={(event) => {
						event.stopPropagation();
						onEdit(record);
					}}
					disabled={
						sequenceViewModel.State === SequenceModelState.ARCHIVED
						|| deviceViewModel.State === DeviceModelState.ARCHIVED
						|| record.Replaced || !user.permission.editRecord
					}
				/>
			);
		};

		const deleteButton = (): JSX.Element | null => {
			if (!authContext.hasPermission(Permission.RECORD_DELETE)) {
				return null;
			}
			return (
				<ButtonIcon
					icon={IconIdentifier.TRASH}
					weight={ButtonIconWeight.GHOST}
					shape={ButtonIconShape.ROUND}
					onClick={(event) => {
						event.stopPropagation();
						onDelete(record);
					}}
					disabled={
						sequenceViewModel.State === SequenceModelState.ARCHIVED
						|| deviceViewModel.State === DeviceModelState.ARCHIVED
						|| record.Replaced || !user.permission.deleteRecord
					}
				/>
			);
		};

		return (
			<td className="sequence-table__body__row__cell sequence-table__row__cell--sticky-end">
				<span className="sequence-table__body__row__cell__actions">
					{editButton()}
					{deleteButton()}
				</span>
			</td>
		);
	};

	const renderRecordedBy = (record: RecordViewModel): JSX.Element => {
		const recordedByValues: Array<JSX.Element> = [];

		if (record.Supplemented) {
			recordedByValues.push(
				<li key="recordedBy">
					<span className="sequence-table__body__row__cell__note sequence-table__body__row__cell__note--prefix">
						{l10nContext.translate('sequence.table.body.supplementedByPrefix')}
					</span>
					{record.RecordedByName}
					<span className="sequence-table__body__row__cell__note sequence-table__body__row__cell__note--suffix">
						{l10nContext.translate('sequence.table.body.supplementedDatePrefix')} {l10nContext.formatDateTime(record.CreatedAt)}
					</span>
				</li>
			);
		} else {
			recordedByValues.push(
				<li key="recordedBy">
					<span className="sequence-table__body__row__cell__note sequence-table__body__row__cell__note--prefix">
						{l10nContext.translate('sequence.table.body.recordedByPrefix')}
					</span>
					{record.RecordedByName}
				</li>
			);
		}

		if ((record?.UpdatedByName ?? null) !== null) {
			recordedByValues.push(
				<li key="updatedBy">
					<span className="sequence-table__body__row__cell__note sequence-table__body__row__cell__note--prefix">
						{l10nContext.translate('sequence.table.body.updatedByPrefix')}
					</span>
					{record.UpdatedByName}
					<span className="sequence-table__body__row__cell__note sequence-table__body__row__cell__note--suffix">
						{l10nContext.translate('sequence.table.body.supplementedDatePrefix')} {l10nContext.formatDateTime(record.UpdatedAt)}
					</span>
				</li>
			);
		}

		return (
			<ul>
				{recordedByValues}
			</ul>
		);
	};

	const renderDocumentsButton = (recordViewModel: RecordViewModel): JSX.Element => {
		// const badgeCount = documentsByRecordsMap.get(recordViewModel.Uuid)?.length ?? 0;

		return (
			<ButtonIcon
				icon={IconIdentifier.DOC}
				weight={ButtonIconWeight.GHOST}
				shape={ButtonIconShape.ROUND}
				onClick={(event) => {
					event.stopPropagation();
					onAttachments(recordViewModel);
				}}
				badge={recordViewModel.Documents.length}
				disabled={!user.permission.addDocumentRecord && !user.permission.viewRecord}
			/>
		);
	};

	const rows = records.map((recordViewModel): JSX.Element => {

		let record: Record;

		try {
			record = sequenceTypeDefinition.restoreRecordFromViewData(recordViewModel.RecordConfiguration);
		} catch (e) {
			DebugConsole.error(e);
			return null;
		}

		const output = record.getOutput(valueDescription);

		DebugConsole.log('output', output);

		let formattedValue: string;
		switch (output.getDescription().type) {
			case FieldType.NUMBER:
				formattedValue = l10nContext.formatNumber(output.getSingle() as number, valueNumberOfDecimals);
				break;
			case FieldType.BOOLEAN:
				formattedValue = l10nContext.formatBoolean(output.getSingle() as boolean);
				break;
			case FieldType.DATE_TIME:
				formattedValue = l10nContext.formatDateTime(output.getSingle() as Date);
				break;
			case FieldType.VALUED_STRING:
				formattedValue = (output.getSingle() as ValuedString).value;
				break;
			default:
				formattedValue = output.getSingle() as string;
				break;
		}
		DebugConsole.log('record', record);

		const comment = recordViewModel.RecordConfiguration.values.comment ?? null;
		const hasComment = comment !== null && comment.length > 0;

		const highlighted = highlightedRecord?.Uuid === recordViewModel.Uuid;
		const highlightedCssClass = highlighted ? 'sequence-table__body__row--highlighted' : '';

		const replacedCssClass = recordViewModel.Replaced ? 'sequence-table__body__row--replaced' : '';

		return (
			<tr
				ref={(tableRow) => {
					if (!highlighted) {
						return;
					}
					onHighlightChange(tableRow);
				}}
				id={`sequence-table-row-${recordViewModel.Uuid}`}
				key={recordViewModel.Uuid}
				className={`sequence-table__body__row ${highlightedCssClass} ${replacedCssClass}`}
			>
				<td className="sequence-table__body__row__cell sequence-table__row__cell--sticky-start">
					<div className="sequence-table__body__row__cell__actions">
						<div className="sequence-table__body__row__cell__actions__value">
							<span className={`sequence-table__body__row__cell__value sequence-table__body__row__cell__value--record-${ClassName.fromEnumValue(record.toValuation())}`}>
								{formattedValue}
							</span>
						</div>
						<ButtonIcon
							icon={IconIdentifier.COMMENT}
							weight={ButtonIconWeight.GHOST}
							shape={ButtonIconShape.ROUND}
							onClick={(event) => {
								event.stopPropagation();
								onShowComment(recordViewModel);
							}}
							disabled={!hasComment}
						/>
						{renderDocumentsButton(recordViewModel)}
					</div>
				</td>
				{renderDeviation(record)}
				{renderExpectedValue(record)}
				{renderSoftThreshold(record)}
				{renderHardThreshold(record)}
				{renderAdditionalColumns(record)}
				<td className="sequence-table__body__row__cell">
					{l10nContext.formatDate(recordViewModel.RecordedAt)}
					<br />
					{l10nContext.formatTime(recordViewModel.RecordedAt)}
				</td>
				<td className="sequence-table__body__row__cell">
					{renderRecordedBy(recordViewModel)}
				</td>
				{renderActionCell(recordViewModel)}
			</tr>
		);
	});

	return (
		<div className="sequence-table-wrapper">
			<div className="sequence-table-wrapper__pane">
				<table className="sequence-table">
					<SequenceTableHead sequence={sequenceViewModel} />
					<tbody className="sequence-table__body">
						{rows}
					</tbody>
				</table>
			</div>
		</div>
	);

};
