import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { CyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';

import { updateCyclotronProduct } from 'services/nuclide/store/cyclotronProductSlice';
import { CyclotronProductUpdateFormStep1 } from './CyclotronProductUpdateFormStep1';
import { CyclotronProductUpdateFormStep2 } from './CyclotronProductUpdateFormStep2';

export interface CyclotronProductUpdateFormProps {
	/**
	 * Cyclotron product 
	 */
	cyclotronProduct: CyclotronProductViewModel;
}

/**
 * The cyclotron product update form
 */
export const CyclotronProductUpdateForm = (props: CyclotronProductUpdateFormProps): JSX.Element => {
	const { cyclotronProduct } = props;

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Local state
	const [showStep1, setShowStep1] = useState<boolean>(true);
	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const updatedCyclotronProduct: CyclotronProductViewModel = cyclotronProduct;

	const requiredInputs = ['Name', 'Manufacturer', 'BatchNumber', 'Isotope', 'Radiopharmaceutical', 'DeliveryDate', 'NominalActivity', 'ActivityUnit', 'CalibrationDatetime'];

	const onChangeCyclotronProductProp = (prop: string, value: any) => {
		updatedCyclotronProduct[prop] = value;
	};

	const handleUpdate = (): void => {
		dispatch(updateCyclotronProduct(updatedCyclotronProduct));
	};

	const goToStep1 = () => {
		setShowStep1(true);
		setInvalidInputs([]);
	};

	const goToStep2 = () => {
		let newCyclotronProductModelValid = true;
		const invalidInputFields: Array<string> = [];
		for (const requiredInput of requiredInputs) {
			if (
				(updatedCyclotronProduct?.[requiredInput] ?? null) === null
				|| updatedCyclotronProduct?.[requiredInput] === ''
				|| String(updatedCyclotronProduct.requiredInput).trim().length === 0
			) {
				invalidInputFields.push(requiredInput);
				newCyclotronProductModelValid = false;
			}
		}
		setInvalidInputs(invalidInputFields);
		setShowStep1(!newCyclotronProductModelValid);
	};

	const showUpdateModalStep = showStep1 === true ?
		<CyclotronProductUpdateFormStep1
			cyclotronProduct={updatedCyclotronProduct}
			onClickNext={goToStep2}
			onChangeCyclotronProductProp={onChangeCyclotronProductProp}
			invalidInputs={invalidInputs}
		/> :
		<CyclotronProductUpdateFormStep2
			cyclotronProduct={updatedCyclotronProduct}
			onClickPrevious={goToStep1}
			onClickConfirm={handleUpdate}
		/>;

	return (
		<>
			{showUpdateModalStep}
		</>
	);
};
