import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';

import {
	ContainerModelConverter as ModelConverter,
	ContainerPersistenceModel as PersistenceModel,
	ContainerStoreModel as StoreModel,
	ContainerViewModel as ViewModel,
	CreatableContainerViewModel as CreatableViewModel
} from 'services/nuclide/domain/model/ContainerModel';
import { ContainerHttpPersistence as HttpPersistence } from 'services/nuclide/persistence/ContainerHttpPersistence';

export class ContainerPersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {

	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

}
