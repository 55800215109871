export class PasswordHash {

	public static async hash(password: string, salt: string): Promise<string> {
		const text = password + ':' + salt;
		const msgUint8 = new TextEncoder().encode(text);
		const hashBuffer = await crypto.subtle.digest('SHA-512', msgUint8);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
		return hashHex;
	}

	public static async check(hash: string, salt: string, password: string): Promise<boolean> {
		const comparisonHash = await this.hash(password, salt);

		const comparisonHashLength = comparisonHash.length;
		let result = 0;

		if (comparisonHashLength !== hash.length) {
			hash = comparisonHash;
			result = 1;
		}

		for (let i = 0; i < comparisonHashLength; i++) {
			// eslint-disable-next-line no-bitwise
			result |= (comparisonHash.charCodeAt(i) ^ hash.charCodeAt(i));
		}

		return result === 0;
	}

}
