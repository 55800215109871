import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';
import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';

import 'services/nuclide/presentation/ui/generator-table/generator-table-head/generator-table-head.scss';

export const GeneratorTableHead = (): JSX.Element | null => {

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	const rederHeadRowActionCell = (type: 'MAJOR' | 'MINOR'): JSX.Element | null => {
		if (
			// ToDo: Check the right permissions
			!authContext.hasPermission(Permission.GENERATOR_UPDATE)
			&& !authContext.hasPermission(Permission.GENERATOR_ELUAT_CREATE)
		) {
			return null;
		}
		return (
			<td className={`generator-table-head__row__cell generator-table-head__row__cell--${type.toLowerCase()} generator-table__row__cell--sticky-end`}>
				&nbsp;
			</td>
		);
	}; 

	return (
		<thead className='generator-table-head'>
			<tr className='generator-table-head__row'>
				<th className='generator-table-head__row__cell generator-table-head__row__cell--major generator-table__row__cell--sticky-start'>
					{l10nContext.translate('generators.table.value', 'Wert')}	
				</th>
				<th className='generator-table-head__row__cell generator-table-head__row__cell--major'>
					{l10nContext.translate('generators.table.date', 'Zeitpunktt')}
				</th>
				<th className='generator-table-head__row__cell generator-table-head__row__cell--major'>
					{l10nContext.translate('generators.table.extractedBy', 'Erfasser')}
				</th>
				{rederHeadRowActionCell('MAJOR')}
			</tr>
		</thead>
	);
};
