import { L10nContext } from 'context/L10nContext';

import { Guid } from 'lib/guid/Guid';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { FilterChipSet } from 'presentation/ui/components/filter/filter-chip-set/FilterChipSet';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { CardCollectionLayoutHeader } from 'presentation/ui/layouts/card-collection-layout/card-collection-layout-header/CardCollectionLayoutHeader';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';

import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogEntry } from 'services/maintenance-log/presentation/ui/maintenance-log-entry/MaintenanceLogEntry';

export interface MaintenanceLogCollectionProps {
	maintenanceLogEntries: readonly MaintenanceLogEntryViewModel[];
	// user: User;
	onAddMaintenanceLogClick: () => void;
	addMaintenanceLogEnabled?: boolean;
	resolveMaintenanceLogEnabled?: boolean;
	onMaintenanceLogEntryClick: (maintenanceLogEntry: MaintenanceLogEntryViewModel) => void;
	onFilterClick?: (id: string, state: boolean) => void;
}

export const MaintenanceLogCollection = (props: MaintenanceLogCollectionProps): JSX.Element => {
	const {
		maintenanceLogEntries = [],
		// user = null,
		onAddMaintenanceLogClick,
		addMaintenanceLogEnabled = true,
		resolveMaintenanceLogEnabled = true,
		onMaintenanceLogEntryClick,
		onFilterClick
	} = props;

	const l10nContext = useContext(L10nContext);

	const filter =
		<FilterChipSet
			label={l10nContext.translate('view.maintenances.filter1.headline', 'Eintragsstatus')}
			chips={[
				{
					label: l10nContext.translate('common.filter.open', 'offen'),
					id: 'ongoing',
					active: true,
					onClick: onFilterClick ?? null
				},
				{
					label: l10nContext.translate('common.filter.done', 'abgeschlossen'),
					id: 'done',
					onClick: onFilterClick ?? null
				}
			]}
		/>;

	const renderMaintenanceLogEntries = (): Array<JSX.Element> => {
		let cards = [];

		if (maintenanceLogEntries && maintenanceLogEntries.length) {
			cards = maintenanceLogEntries.map((maintenanceLogEntry) => (
				<MaintenanceLogEntry
					key={maintenanceLogEntry.Uuid}
					maintenanceLogEntry={maintenanceLogEntry}
					onSelect={onMaintenanceLogEntryClick}
					resolveMaintenanceLogEnabled={resolveMaintenanceLogEnabled}
				/>
			));
		} else {
			cards.push(
				<CardEmpty
					key={Guid.generate()}
					message={l10nContext.translate('common.cards.emptyDefault.maintenanceLog', 'kein Betriebsbuch-Eintrag')}
				/>
			);
		}

		return cards;
	};

	return (
		<Expandable>
			<ExpandableHeader caption={l10nContext.translate('view.device.headline.maintenanceLog', 'Betriebsbucheinträge')}>
				<ButtonIcon
					weight={ButtonIconWeight.SECONDARY}
					shape={ButtonIconShape.ROUND}
					icon={IconIdentifier.ADD}
					onClick={onAddMaintenanceLogClick}
					disabled={!addMaintenanceLogEnabled}
				/>
			</ExpandableHeader>
			<ExpandableBody>
				<CardCollectionLayout>
					<CardCollectionLayoutHeader>
						{filter}
					</CardCollectionLayoutHeader>

					{renderMaintenanceLogEntries()}
				</CardCollectionLayout>
			</ExpandableBody>
		</Expandable>
	);
};
