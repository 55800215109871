import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentMoveFormStep1 } from 'services/documents/presentation/ui/document-action/document-move-form/DocumentMoveFormStep1';
import { DocumentMoveFormStep2 } from 'services/documents/presentation/ui/document-action/document-move-form/DocumentMoveFormStep2';
import { updateDocument } from 'services/documents/store/documentSlice';

export interface DocumentMoveFormProps {
	document: DocumentViewModel;
}

export const DocumentMoveForm = (props: DocumentMoveFormProps): JSX.Element => {
	const { document } = props;

	const [showStep2, setShowStep2] = useState<boolean>(false);
	const [selectedFolder, setSelectedFolder] = useState<string>(null);

	const dispatch = useDispatch();

	const handleMoveAction = (): void => {
		dispatch(updateDocument({
			...document,
			ScopeReference: selectedFolder
		}));
	};

	const gotoStep1 = () => {
		setShowStep2(false);
	};

	const gotoStep2 = () => {
		setShowStep2(true);
	};

	const formStep = !showStep2 ?
		<DocumentMoveFormStep1
			onChange={(folderUuid) => {
				setSelectedFolder(folderUuid);
			}}
			onClickNext={gotoStep2}
		/> :
		<DocumentMoveFormStep2
			onClickBack={gotoStep1}
			onClickNext={handleMoveAction}
			selectedFolder={selectedFolder}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
