import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { L10nContext } from 'context/L10nContext';
import { Route } from 'router/Route';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { SequenceCloneForm } from 'services/device/presentation/ui/sequence-action/sequence-clone-form/SequenceCloneForm';
import { SequenceCloneFailedNotification } from 'services/device/presentation/ui/sequence-action/sequence-clone-modal/SequenceCloneFailedNotification';
import { SequenceCloneSuccessNotification } from 'services/device/presentation/ui/sequence-action/sequence-clone-modal/SequenceCloneSuccessNotification';
import { createSequence, selectCreatedSequence } from 'services/device/store/sequenceSlice';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface SequenceCloneModalProps {
	sequence: SequenceViewModel
	onDismiss?: () => void;
}

export const SequenceCloneModal = (props: SequenceCloneModalProps): JSX.Element => {
	const { sequence, onDismiss } = props;

	const createdSequence = useSelector(selectCreatedSequence());
	const deviceActionStatus = useTypedSelector(state => state.sequences.actionStatus);

	const l10nContext = useContext(L10nContext);

	const history = useHistory();

	const dispatch = useDispatch();

	const handleClone = (): void => {
		const clonedSequence = { ...sequence };
		clonedSequence.Name = 'Copy of ' + clonedSequence.Name;
		dispatch(createSequence(clonedSequence));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (deviceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<SequenceCloneForm
						sequence={sequence}
						onUpdate={handleClone}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.CREATED:
				modalContent =
					<SequenceCloneSuccessNotification
						onOverview={() => {
							history.push(Route.DEVICES + '/' + createdSequence.Device);
							onDismiss();
						}}
						onDetails={() => {
							history.push(Route.DEVICES + '/' + createdSequence.Device + Route.SEQUENCES + '/' + createdSequence.Uuid);
							onDismiss();
						}}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <SequenceCloneFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.sequence.modal.action.clone.caption', 'Messreihe duplizieren')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
