import { ClassName } from 'lib/class-name/ClassName';

import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './notification-bar.scss';

export interface NotificationBarProps {
	message: string;
	level?: NotificationLevel;
	onDismiss?: () => void;
}

export const NotificationBar = (props: NotificationBarProps): JSX.Element => {

	const { message, level = NotificationLevel.LEVEL_NOTIFICATION, onDismiss = null } = props;

	const renderDismiss = (): JSX.Element | null => {
		if (onDismiss === null) {
			return null;
		}
		return (
			// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
			<div
				role="dialog"
				className="notification-bar__dismiss"
				onClick={(event) => {
					event.stopPropagation();
					onDismiss();
				}}
			>
				<Icon name={IconIdentifier.CLOSE} />
			</div>
		);
	};

	const renderIcon = (): JSX.Element => {
		let iconIdentifier: IconIdentifier;
		switch (level) {
			case NotificationLevel.LEVEL_ERROR:
				iconIdentifier = IconIdentifier.CLOSE;
				break;
			case NotificationLevel.LEVEL_WARNING:
				iconIdentifier = IconIdentifier.ALERT;
				break;
			case NotificationLevel.LEVEL_SUCCESS:
				iconIdentifier = IconIdentifier.CHECK;
				break;
			default:
				iconIdentifier = IconIdentifier.WARNING;
				break;
		}
		return (
			<div className="notification-bar__icon">
				<Icon name={iconIdentifier} />
			</div>
		);
	};

	const renderMessage = (): Array<JSX.Element> => {
		return message.split('\n').map((item, key) => {
			return (
				// eslint-disable-next-line react/no-array-index-key
				<span key={key}>{item}</span>
			);
		});
	};

	return (
		<div className={`notification-bar notification-bar--${ClassName.fromEnumValue(String(level))}`}>
			{renderDismiss()}
			{renderIcon()}
			<h1 className="notification-bar__message">{renderMessage()}</h1>
		</div>
	);

}
	;
