import { Permission } from 'services/core/lib/auth/AuthService';
import { AuthServiceBuilder } from 'services/core/lib/auth/AuthServiceBuilder';
import { Interaction } from 'services/device/domain/business/common/interaction/Interaction';
import { Mode } from 'services/device/domain/business/common/misc/Mode';
import { Record } from 'services/device/domain/business/common/record/Record';
import { Sequence } from 'services/device/domain/business/common/sequence/Sequence';
import { Valuation } from 'services/device/domain/business/common/Valuation';
import { Interval } from 'services/device/domain/business/inventory/Interval';
import { SequenceType } from 'services/device/domain/business/inventory/SequenceType';
import { getSequenceDefinition, getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { RecordViewModel } from 'services/device/domain/model/RecordModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';

export const getCreateSequenceInteraction = (deviceViewModel: DeviceViewModel, sequenceType: SequenceType): Interaction<Sequence> => {
	const sequenceDefinition = getSequenceDefinition(deviceViewModel.Type, sequenceType);
	return sequenceDefinition.createSequence(Mode.USER);
};

export const getUpdateSequenceInteraction = (sequenceViewModel: SequenceViewModel): Interaction<Sequence> => {
	const sequenceDefinition = getSequenceDefinition(sequenceViewModel.DeviceType, sequenceViewModel.Type);
	const sequence = sequenceDefinition.restoreSequenceFromViewData(sequenceViewModel.SequenceConfiguration);
	const updateMode = AuthServiceBuilder.build(true).hasPermission(Permission.SEQUENCE_UPDATE_ADMIN) ? Mode.ADMIN : Mode.USER;
	return sequenceDefinition.updateSequence(sequence, updateMode);
};

export const getCreateRecordInteraction = (sequenceViewModel: SequenceViewModel): Interaction<Record> => {
	const sequenceTypeDefinition = getSequenceTypeDefinition(sequenceViewModel.Type);
	const sequence = sequenceTypeDefinition.restoreSequenceFromViewData(sequenceViewModel.SequenceConfiguration);

	const createMode = AuthServiceBuilder.build(true).hasPermission(Permission.RECORD_SUPPLEMENT) ? Mode.ADMIN : Mode.USER;
	const recordDefinition = sequenceTypeDefinition.getRecordDefinition(sequence, sequenceViewModel.SequenceMemory);
	if (createMode === Mode.USER) {
		return recordDefinition.createRecord(createMode, new Date());
	}
	return recordDefinition.createRecord(createMode);
};

export const getUpdateRecordInteraction = (sequenceViewModel: SequenceViewModel, recordViewModel: RecordViewModel): Interaction<Record> => {
	const sequenceTypeDefinition = getSequenceTypeDefinition(sequenceViewModel.Type);
	const sequence = sequenceTypeDefinition.restoreSequenceFromViewData(sequenceViewModel.SequenceConfiguration);

	const recordDefinition = sequenceTypeDefinition.getRecordDefinition(sequence, sequenceViewModel.SequenceMemory);
	const record = recordDefinition.restoreRecordFromViewData(recordViewModel.RecordConfiguration);
	return recordDefinition.updateRecord(record, Mode.USER);
};

export const getSequenceValuation = (sequenceViewModel: SequenceViewModel): Valuation => {
	const latestRecordValuation = sequenceViewModel.SequenceMemory.latestRecordValuation;

	if (latestRecordValuation === null) {
		return Valuation.UNKNOWN;
	}
	return latestRecordValuation;
};

export const getSequenceDue = (sequenceViewModel: SequenceViewModel): boolean => {
	const dueDate = getSequenceDueDate(sequenceViewModel);
	if (dueDate === null) {
		return false;
	}
	dueDate.setHours(0, 0, 0);
	return dueDate.getTime() < Date.now();
};

export const getSequenceDueDate = (sequenceViewModel: SequenceViewModel): Date | null => {
	const reminderInterval = sequenceViewModel.SequenceConfiguration?.values?.reminderInterval ?? Interval.NONE;

	if (reminderInterval === Interval.NONE) {
		return null;
	}

	let latestRecordedAtDate: Date | null = null;
	if (sequenceViewModel.SequenceMemory.latestRecordRecordedAt !== null) {
		latestRecordedAtDate = new Date(sequenceViewModel.SequenceMemory.latestRecordRecordedAt);
	}

	if (latestRecordedAtDate === null) {
		return new Date();
	}

	switch (reminderInterval) {
		case Interval.YEARLY:
			latestRecordedAtDate.setFullYear(latestRecordedAtDate.getFullYear() + 1);
			return latestRecordedAtDate;
		case Interval.HALF_YEARLY:
			latestRecordedAtDate.setMonth(latestRecordedAtDate.getMonth() + 6);
			return latestRecordedAtDate;
		case Interval.QUARTER_YEARLY:
			latestRecordedAtDate.setMonth(latestRecordedAtDate.getMonth() + 3);
			return latestRecordedAtDate;
		case Interval.MONTHLY:
			latestRecordedAtDate.setMonth(latestRecordedAtDate.getMonth() + 1);
			return latestRecordedAtDate;
		case Interval.TWO_WEEKLY:
			latestRecordedAtDate.setDate(latestRecordedAtDate.getDate() + 14);
			return latestRecordedAtDate;
		case Interval.WEEKLY:
			latestRecordedAtDate.setDate(latestRecordedAtDate.getDate() + 7);
			return latestRecordedAtDate;
		case Interval.DAILY:
			latestRecordedAtDate.setDate(latestRecordedAtDate.getDate() + 1);
			return latestRecordedAtDate;
		case Interval.LATCHED_YEARLY: {
			const dueDate = new Date(latestRecordedAtDate.getFullYear() + 1, 0);
			return dueDate;
		}
		case Interval.LATCHED_HALF_YEARLY: {
			const dueHalfYear = Math.floor(latestRecordedAtDate.getMonth() / 6);
			const dueMonth = (dueHalfYear + 1) * 6;
			const dueDate = new Date(latestRecordedAtDate.getFullYear(), dueMonth);
			return dueDate;
		}
		case Interval.LATCHED_QUARTER_YEARLY: {
			const dueQuarter = Math.floor(latestRecordedAtDate.getMonth() / 3);
			const dueMonth = (dueQuarter + 1) * 3;
			const dueDate = new Date(latestRecordedAtDate.getFullYear(), dueMonth);
			return dueDate;
		}
		case Interval.LATCHED_MONTHLY: {
			const dueDate = new Date(latestRecordedAtDate.getFullYear(), latestRecordedAtDate.getMonth() + 1);
			return dueDate;
		}
		default:
			return null;
	}
};
