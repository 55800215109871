import { useState } from 'react';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import {
	ReportRecordDocumentCollection
} from '../../../../device/presentation/ui/card-collections/report-record-document-collection/ReportRecordDocumentCollection';

import {
	ExternalReportDocumentDownloadModalContent
} from '../../../../documents/presentation/ui/document-action/document-download-modal/ExternalReportDocumentDownloadModalContent';
import { ReportRecordViewModel } from '../../../domain/model/ReportRecordModel';

export interface ReportRecordDocumentsModalContentProps {
	record: ReportRecordViewModel;
	onDismiss?: () => void;
}

enum ModalContent {
	DOCUMENTS,
	DOWNLOAD
}

export const ReportRecordDocumentsModalContent = (props: ReportRecordDocumentsModalContentProps): JSX.Element => {
	const reportRecordViewModel = props.record;

	const [modalContent, setModalContent] = useState<ModalContent>(ModalContent.DOCUMENTS);
	const [modalPayload, setModalPayload] = useState<DocumentViewModel>(null);

	const handleDocumentDownload = (documentViewModel: DocumentViewModel): void => {
		setModalPayload(documentViewModel);
		setModalContent(ModalContent.DOWNLOAD);
	};

	const renderModalContent = (): JSX.Element | null => {
		switch (modalContent) {
			case ModalContent.DOCUMENTS:
				return (
					<ReportRecordDocumentCollection
						reportRecordDocuments={reportRecordViewModel.Documents}
						isReportView
						onAction={(payload, document) => {
							switch (payload.type) {
								case 'DOWNLOAD':
									handleDocumentDownload(document);
									break;
							}
						}}
					/>
				);

			case ModalContent.DOWNLOAD:
				return (
					<ExternalReportDocumentDownloadModalContent
						document={modalPayload as DocumentViewModel}
						onDismiss={() => {
							setModalContent(ModalContent.DOCUMENTS);
						}}
						onClickPrevious={() => {
							setModalContent(ModalContent.DOCUMENTS);
						}}
					/>
				);
		}
		return null;
	};

	return (
		<>
			{renderModalContent()}
		</>
	);
};
