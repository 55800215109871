import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';

import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';

export interface DeviceCreateSuccessNotificationProps {
	maintenanceLogEntryCreated?: MaintenanceLogEntryViewModel | null;
	onClick: () => void;
	onClickAddDocuments: () => void;
}

export const MaintenanceLogCreateModalSuccess = (props: DeviceCreateSuccessNotificationProps): JSX.Element => {
	const { maintenanceLogEntryCreated, onClick, onClickAddDocuments } = props;

	const l10nContext = useContext(L10nContext);
	const history = useHistory();

	const successBtnTranslation = !history.location.pathname.includes(Route.DEVICES) ?
		l10nContext.translate('view.maintenances.modal.btnEntryOverview', 'Weiter zur Eintragsübersicht') :
		l10nContext.translate('view.maintenances.modal.btnDeviceDetails', 'Weiter zur Geräte Detailansicht');

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.maintenances.modal.action.create.successMessage', 'Der Eintrag wurde erfolgreich angelegt')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonSecondary
						buttonText={l10nContext.translate('view.maintenances.modal.btnAddDocuments')}
						onClick={onClickAddDocuments}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.maintenances.modal.btnEntryDetails', 'Weiter zu den Eintragsdetails')}
						onClick={() => {
							history.push(Route.MAINTENANCE_LOG + '/' + maintenanceLogEntryCreated.Uuid);
						}}
					/>
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={successBtnTranslation}
						onClick={onClick}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
