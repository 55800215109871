import { L10nContext } from 'context/L10nContext';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { FilterChipSet } from 'presentation/ui/components/filter/filter-chip-set/FilterChipSet';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { CardCollectionLayoutHeader } from 'presentation/ui/layouts/card-collection-layout/card-collection-layout-header/CardCollectionLayoutHeader';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { CardItemActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemActionTypes';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentModalType } from 'services/documents/domain/type/DocumentsModalType';
import { DocumentEntry } from 'services/documents/presentation/ui/document-entry/DocumentEntry';
import { DocumentModal } from 'services/documents/presentation/ui/document-modal/DocumentModal';
import { resetActionStatus as documentResetActionStatus } from 'services/documents/store/documentSlice';
import { LoadingSpinnerEmptyCard } from '../../../../../../presentation/ui/components/loading-spinner-empty-card/LoadingSpinnerEmptyCard.';

export interface DocumentCollectionProps {
	documents: ReadonlyArray<DocumentViewModel>;
	onAddClick?: () => void;
	onFilterClick?: (id: string, state: boolean) => void;
	fetchStatus?: boolean;
}

export const DocumentCollection = (props: DocumentCollectionProps): JSX.Element => {
	const { documents, onAddClick, onFilterClick, fetchStatus } = props;

	const [cardItemAction, setCardItemAction] = useState<CardItemControlActionType>(null);
	const [modalPayload, setModalPayload] = useState<DocumentViewModel>(null);

	const l10nContext = useContext(L10nContext);

	const dispatch = useDispatch();

	const onModalDismiss = () => {
		setCardItemAction(null);
	};

	const handleCardAction = (type: CardItemControlActionType, payload: DocumentViewModel) => {
		dispatch(documentResetActionStatus());

		setCardItemAction(type);
		setModalPayload(payload);
	};

	const filterToggle =
		<FilterChipSet
			label={l10nContext.translate('common.filter.status', 'Status')}
			chips={[
				{
					label: l10nContext.translate('common.filter.active', 'aktiv'),
					id: 'active',
					active: true,
					onClick: onFilterClick ?? null
				},
				{
					label: l10nContext.translate('common.filter.archived', 'archiviert'),
					id: 'archived',
					onClick: onFilterClick ?? null
				}
			]}
		/>;

	const expandableHeader =
		<ExpandableHeader caption={l10nContext.translate('view.documents.collection.documents.headline', 'Dokument')}>
			{
				onAddClick ?
					<ButtonPrimary
						buttonText={l10nContext.translate('view.documents.collection.documents.addButton', 'Dokument hinzufügen')}
						icon={IconIdentifier.ADD}
						onClick={onAddClick ?? null}
					/> :
					null
			}
		</ExpandableHeader>;

	const renderDocumentCards = (): JSX.Element => {
		if (fetchStatus) {
			return (
				<CardEmpty>
					<LoadingSpinnerEmptyCard />
				</CardEmpty>
			);
		}

		if (documents?.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.document', 'kein Dokument wurde hinzugefügt')} />
			);
		}
		return (
			<>
				{
					documents?.map((document: DocumentViewModel) => (
						<DocumentEntry
							key={document.Uuid}
							documentEntry={document}
							onActionClick={(actionPayload, documentEntry) => handleCardAction(actionPayload, documentEntry)}
						/>
					))
				}
			</>
		);
	};

	const renderModal = (): JSX.Element => {
		let modalType: DocumentModalType;

		switch (cardItemAction?.type) {
			case CardItemActionType.ARCHIVE:
				modalType = DocumentModalType.ARCHIVE;
				break;

			case CardItemActionType.DELETE:
				modalType = DocumentModalType.DELETE;
				break;

			case CardItemActionType.DOWNLOAD:
				modalType = DocumentModalType.DOWNLOAD;
				break;

			case CardItemActionType.MOVE:
				modalType = DocumentModalType.MOVE_DOCUMENT;
				break;

			case CardItemActionType.UNARCHIVE:
				modalType = DocumentModalType.UNARCHIVE;
				break;

			case CardItemActionType.CHANGE:
				modalType = DocumentModalType.EDIT_DOCUMENT_NAME;
				break;
		}

		return (
			modalType ?
				<DocumentModal
					modalType={modalType}
					selectedDocument={modalPayload}
					onDismiss={onModalDismiss}
					documentScope={DocumentModelScope.DOCUMENT_FOLDER}
					documentScopeReference={modalPayload.Uuid}
				/> :
				null
		);
	};

	return (
		<Expandable expanded={documents?.length > 0}>

			{expandableHeader}

			{renderModal()}

			<ExpandableBody>
				<CardCollectionLayout>
					<CardCollectionLayoutHeader>

						{filterToggle}

					</CardCollectionLayoutHeader>

					{renderDocumentCards()}

				</CardCollectionLayout>
			</ExpandableBody>
		</Expandable>
	);
};
