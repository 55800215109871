import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';
import { ContainerModelState } from './ContainerModelState';

export interface ContainerPersistenceModel extends PersistenceModel {
	Client: string,
	Facility: string,
	Name: string,
	State: string,
	Weight?: number,
	MeasurementActivity?: number,
	MeasurementFactor?: number,
	MeasurementDate?: number,
	MeasuredBy?: string,
	MeasuredByName?: string,
	MeasurementContamination?: number,
	MeasurementContaminationDateTime?: number,
	MeasurementContaminationBy?: string,
	MeasurementContaminationByName?: string,
	IsReleasedByRadiationProtectionOfficer?: boolean,
	ReleasedByRadiationProtectionOfficerDateTime?: number,
	ReleasedByRadiationProtectionOfficer?: string,
	ReleasedByRadiationProtectionOfficerName?: string,
	CurrentSpecificActivity?: number,
	ReleaseDateTime?: number,
	DispatchId?: string,
	DisposeId?: string,
	CyclotroneProductIds?: Array<string>,
	GeneratorEluatIds?: Array<string>
}

export interface ContainerStoreModel extends StoreModel {
	Client: string,
	Facility: string,
	Name: string,
	State: string,
	Weight?: number,
	MeasurementActivity?: number,
	MeasurementFactor?: number,
	MeasurementDate?: number,
	MeasuredBy?: string,
	MeasuredByName?: string,
	MeasurementContamination?: number,
	MeasurementContaminationDateTime?: number,
	MeasurementContaminationBy?: string,
	MeasurementContaminationByName?: string,
	IsReleasedByRadiationProtectionOfficer?: boolean,
	ReleasedByRadiationProtectionOfficerDateTime?: number,
	ReleasedByRadiationProtectionOfficer?: string,
	ReleasedByRadiationProtectionOfficerName?: string,
	CurrentSpecificActivity?: number,
	ReleaseDateTime?: number,
	DispatchId?: string,
	DisposeId?: string,
	CyclotroneProductIds?: Array<string>,
	GeneratorEluatIds?: Array<string>
}

export interface ContainerViewModel extends ViewModel {
	Client: string,
	Facility: string,
	Name: string,
	State: ContainerModelState,
	Weight?: number,
	MeasurementActivity?: number,
	MeasurementFactor?: number,
	MeasurementDate?: Date,
	MeasuredBy?: string,
	MeasuredByName?: string,
	MeasurementContamination?: number,
	MeasurementContaminationDateTime?: Date,
	MeasurementContaminationBy?: string,
	MeasurementContaminationByName?: string,
	IsReleasedByRadiationProtectionOfficer?: boolean,
	ReleasedByRadiationProtectionOfficerDateTime?: Date,
	ReleasedByRadiationProtectionOfficer?: string,
	ReleasedByRadiationProtectionOfficerName?: string,
	CurrentSpecificActivity?: number,
	ReleaseDateTime?: Date,
	DispatchId?: string,
	DisposeId?: string,
	CyclotroneProductIds?: Array<string>,
	GeneratorEluatIds?: Array<string>
}

export interface CreatableContainerViewModel extends CreatableViewModel {
	Client: string,
	Facility: string,
	Name: string
	CyclotroneProductIds?: Array<string>,
	GeneratorEluatIds?: Array<string>
}

export class ContainerModelConverter
	extends ModelConverter<ContainerPersistenceModel, ContainerStoreModel, ContainerViewModel, CreatableContainerViewModel> {

	fromStoreModel(storeModel?: ContainerStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			if (Object.prototype.hasOwnProperty.call(sModel, 'ReleaseDateTime') && sModel.ReleaseDateTime !== null) {
				model.ReleaseDateTime = Math.floor(sModel.ReleaseDateTime / 1000);
			}
			if (Object.prototype.hasOwnProperty.call(sModel, 'MeasurementDate') && sModel.MeasurementDate !== null) {
				model.MeasurementDate = Math.floor(sModel.MeasurementDate / 1000);
			}
			if (Object.prototype.hasOwnProperty.call(sModel, 'MeasurementContaminationDateTime') && sModel.MeasurementContaminationDateTime !== null) {
				model.MeasurementContaminationDateTime = Math.floor(sModel.MeasurementContaminationDateTime / 1000);
			}
			if (Object.prototype.hasOwnProperty.call(sModel, 'ReleasedByRadiationProtectionOfficerDateTime') && sModel.ReleasedByRadiationProtectionOfficerDateTime !== null) {
				model.ReleasedByRadiationProtectionOfficerDateTime = Math.floor(sModel.ReleasedByRadiationProtectionOfficerDateTime / 1000);
			}
		});
	}

	fromViewModel(viewModel?: ContainerViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			if (Object.prototype.hasOwnProperty.call(vModel, 'ReleaseDateTime') && vModel.ReleaseDateTime !== null) {
				model.ReleaseDateTime = Math.floor(vModel.ReleaseDateTime.getTime() / 1000);
			}
			if (Object.prototype.hasOwnProperty.call(vModel, 'MeasurementDate') && vModel.MeasurementDate !== null) {
				model.MeasurementDate = Math.floor(vModel.MeasurementDate.getTime() / 1000);
			}
			if (Object.prototype.hasOwnProperty.call(vModel, 'MeasurementContaminationDateTime') && vModel.MeasurementContaminationDateTime !== null) {
				model.MeasurementContaminationDateTime = Math.floor(vModel.MeasurementContaminationDateTime.getTime() / 1000);
			}
			if (Object.prototype.hasOwnProperty.call(vModel, 'ReleasedByRadiationProtectionOfficerDateTime') && vModel.ReleasedByRadiationProtectionOfficerDateTime !== null) {
				model.ReleasedByRadiationProtectionOfficerDateTime = Math.floor(vModel.ReleasedByRadiationProtectionOfficerDateTime.getTime() / 1000);
			}
			model.State = vModel?.State as string ?? null;
		});
	}

	fromCreatableViewModel(creatableViewModel?: CreatableContainerViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			model.State = ContainerModelState.OPEN;
			model.Weight = null;
			model.MeasurementActivity = null;
			model.MeasurementFactor = null;
			model.MeasurementDate = null;
			model.MeasuredBy = null;
			model.MeasuredByName = null;
			model.MeasurementContamination = null;
			model.MeasurementContaminationDateTime = null;
			model.MeasurementContaminationBy = null;
			model.MeasurementContaminationByName = null;
			model.IsReleasedByRadiationProtectionOfficer = null;
			model.ReleasedByRadiationProtectionOfficerDateTime = null;
			model.ReleasedByRadiationProtectionOfficer = null;
			model.ReleasedByRadiationProtectionOfficerName = null;
			model.CurrentSpecificActivity = null;
			// model.ReleaseDateTime = null;
		});
	}

	toStoreModel(): ContainerStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			if (Object.prototype.hasOwnProperty.call(model, 'ReleaseDateTime') && model.ReleaseDateTime !== null) {
				sModel.ReleaseDateTime = model.ReleaseDateTime * 1000;
			}
			if (Object.prototype.hasOwnProperty.call(model, 'MeasurementDate') && model.MeasurementDate !== null) {
				sModel.MeasurementDate = model.MeasurementDate * 1000;
			}
			if (Object.prototype.hasOwnProperty.call(model, 'MeasurementContaminationDateTime') && model.MeasurementContaminationDateTime !== null) {
				sModel.MeasurementContaminationDateTime = model.MeasurementContaminationDateTime * 1000;
			}
			if (Object.prototype.hasOwnProperty.call(model, 'ReleasedByRadiationProtectionOfficerDateTime') && model.ReleasedByRadiationProtectionOfficerDateTime !== null) {
				sModel.ReleasedByRadiationProtectionOfficerDateTime = model.ReleasedByRadiationProtectionOfficerDateTime * 1000;
			}
		});
	}

	toViewModel(): ContainerViewModel | null {
		return super.toViewModel((model, vModel) => {
			if (Object.prototype.hasOwnProperty.call(model, 'ReleaseDateTime') && model.ReleaseDateTime !== null) {
				vModel.ReleaseDateTime = new Date(model.ReleaseDateTime * 1000);
			}
			if (Object.prototype.hasOwnProperty.call(model, 'MeasurementDate') && model.MeasurementDate !== null) {
				vModel.MeasurementDate = new Date(model.MeasurementDate * 1000);
			}
			if (Object.prototype.hasOwnProperty.call(model, 'MeasurementContaminationDateTime') && model.MeasurementContaminationDateTime !== null) {
				vModel.MeasurementContaminationDateTime = new Date(model.MeasurementContaminationDateTime * 1000);
			}
			if (Object.prototype.hasOwnProperty.call(model, 'ReleasedByRadiationProtectionOfficerDateTime') && model.ReleasedByRadiationProtectionOfficerDateTime !== null) {
				vModel.ReleasedByRadiationProtectionOfficerDateTime = new Date(model.ReleasedByRadiationProtectionOfficerDateTime * 1000);
			}
			vModel.State = model?.State as ContainerModelState ?? null;
		});
	}
}
