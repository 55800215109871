export enum ReportViewModalType {
	DELETE = 'DELETE',
	EDIT = 'EDIT',
	DOCUMENT_FACILITY_ADD = 'DOCUMENT_FACILITY_ADD',
	DOCUMENT_DEVICE_ADD = 'DOCUMENT_DEVICE_ADD',
	DOCUMENT_DOWNLOAD = 'DOCUMENT_DOWNLOAD',
	DOCUMENT_DELETE = 'DOCUMENT_DELETE',
	SEQUENCE_ADD = 'SEQUENCE_ADD',
	SEQUENCE_UPDATE = 'SEQUENCE_UPDATE', 
	SEQUENCE_DELETE = 'SEQUENCE_DELETE'
}