import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';

import {
	MaintenanceLogEntryModelConverter as ModelConverter,
	MaintenanceLogEntryPersistenceModel as PersistenceModel,
	MaintenanceLogEntryStoreModel as StoreModel,
	MaintenanceLogEntryViewModel as ViewModel,
	CreatableMaintenanceLogEntryViewModel as CreatableViewModel
} from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogHttpPersistence as HttpPersistence } from 'services/maintenance-log/persistence/MaintenanceLogHttpPersistence';

export class MaintenanceLogEntryPersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {

	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

}
