import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { SequenceModelState } from 'services/device/domain/model/SequenceModelState';
import { updateSequence } from 'services/device/store/sequenceSlice';
import { SequenceUnarchiveForm } from 'services/device/presentation/ui/sequence-action/sequence-unarchive-form/SequenceUnarchiveForm';
import { SequenceUnarchiveFailedNotification } from 'services/device/presentation/ui/sequence-action/sequence-unarchive-modal/SequenceUnarchiveFailedNotification';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';

export interface SequenceUnarchiveModalProps {
	sequence: SequenceViewModel;
	onDismiss?: () => void;
}

export const SequenceUnarchiveModal = (props: SequenceUnarchiveModalProps): JSX.Element => {
	const { sequence, onDismiss } = props;

	const deviceActionStatus = useTypedSelector(state => state.sequences.actionStatus);

	const dispatch = useDispatch();

	useEffect(() => {
		if (deviceActionStatus === AsyncReducerStatus.UPDATED) {
			onDismiss();
		}
	}, [deviceActionStatus, onDismiss]);

	const handleUpdate = (): void => {
		sequence.State = SequenceModelState.ACTIVE;
		dispatch(updateSequence(sequence));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (deviceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<SequenceUnarchiveForm
						sequence={sequence}
						onUpdate={handleUpdate}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <SequenceUnarchiveFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
