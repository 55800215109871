import { Locale } from './Locale';

export class L10n {

	private static locales: Record<string, Locale> = {};

	private static defaultLocaleIdentifier: string;

	private static selectedLocaleIdentifier: string;

	public static addLocale(locale: Locale): void {
		this.locales[locale.identifier.toLowerCase()] = locale;
	}

	public static hasLocale(localeIdentifier: string): boolean {
		return localeIdentifier.toLowerCase() in this.locales;
	}

	public static setDefaultLocale(localeIdentifier: string): void {
		if (!this.hasLocale(localeIdentifier)) {
			throw new Error('Locale with identifier ' + localeIdentifier + ' unknown');
		}
		this.defaultLocaleIdentifier = localeIdentifier.toLowerCase();
	}

	public static selectLocale(localeIdentifier: string): void {
		if (!this.hasLocale(localeIdentifier)) {
			throw new Error('Locale with identifier ' + localeIdentifier + ' unknown');
		}
		this.selectedLocaleIdentifier = localeIdentifier.toLowerCase();
	}

	public static detectLocale(): boolean {
		const fallbackLanguages: Array<string> = [];
		for (let language of this.getNavigatorPreferredLanguages()) {
			language = language.toLowerCase();
			if (language in this.locales) {
				this.selectLocale(language);
				return true;
			}
			if (language.includes('-')) {
				fallbackLanguages.push(language.substring(0, language.indexOf('-')));
			}
		}

		for (let language of fallbackLanguages) {
			language = language.toLowerCase();
			if (language in this.locales) {
				this.selectLocale(language);
				return true;
			}
		}

		return false;
	}

	public static selectedLocale(): string | null {
		return this.selectedLocaleIdentifier ?? null;
	}

	public static effectiveLocale(): string | null {
		return this.selectedLocaleIdentifier ?? this.defaultLocaleIdentifier ?? null;
	}

	public static translate(literal: string, defaultValue: string = null): string {
		const effectiveLocale = this.getEffectiveLocale();
		if (effectiveLocale === null) {
			return defaultValue ?? literal;
		}
		const translated = effectiveLocale.translate(literal, defaultValue) ?? literal;
		if (translated === literal) {
			console.warn('"' + literal.split('.').pop() + '": "' + literal.split('.').pop() + '",');
		}
		return translated;
	}

	public static formatDate(date?: Date, defaultLocale: string = null, defaultValue?: string): string {
		if ((date ?? null) === null) {
			return defaultValue ?? '';
		}
		let effectiveLocale = this.effectiveLocale();
		if (effectiveLocale === null) {
			effectiveLocale = defaultLocale;
		}
		return date.toLocaleDateString(effectiveLocale ?? undefined, {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric'
		});
	}

	public static formatTime(date?: Date, defaultLocale: string = null, defaultValue?: string): string {
		if ((date ?? null) === null) {
			return defaultValue ?? '';
		}
		let effectiveLocale = this.effectiveLocale();
		if (effectiveLocale === null) {
			effectiveLocale = defaultLocale;
		}
		return date.toLocaleTimeString(effectiveLocale ?? undefined, {
			hour: '2-digit',
			minute: '2-digit',
			second: 'numeric'
		});
	}

	public static formatDateTime(date?: Date, defaultLocale: string = null, defaultValue?: string): string {
		if ((date ?? null) === null) {
			return defaultValue ?? '';
		}
		return this.formatDate(date, defaultLocale) + ' ' + this.formatTime(date, defaultLocale);
	}

	public static formatNumber(number?: number, decimals = 2, defaultLocale: string = null, defaultValue?: string): string {
		if ((number ?? null) === null) {
			return defaultValue ?? '';
		}
		let effectiveLocale = this.effectiveLocale();
		if (effectiveLocale === null) {
			effectiveLocale = defaultLocale;
		}
		return number.toLocaleString(effectiveLocale ?? undefined, {
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals
		});
	}

	public static formatExponentialNumber(number?: number, defaultLocale?: string): string {
		if (defaultLocale === 'de') {
			return number.toExponential(4).replace('.', ',');
		}
		return number.toExponential(4);
	}

	private static getNavigatorPreferredLanguages(): Array<string> {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return navigator.languages ? navigator.languages : ([navigator.language] || [navigator.userLanguage]);
	}

	private static getEffectiveLocale(): Locale | null {
		let effectiveLocale = this.locales[this.selectedLocaleIdentifier] ?? null;
		if (effectiveLocale === null) {
			effectiveLocale = this.locales[this.defaultLocaleIdentifier] ?? null;
		}
		return effectiveLocale;
	}

}
