import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { Line } from 'react-chartjs-2';

import { L10nContext } from 'context/L10nContext';
import { ChartDescription } from 'services/device/domain/business/common/description/ChartDescription';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { UnitUtil } from 'services/device/domain/business/util/UnitUtil';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import {
	createBestValueDataset,
	createHardThresholdBand,
	createRecordDataset,
	createSoftThresholdBand,
	hideDataset,
	SequenceChartDataset,
	SequenceChartDatasetRecord,
} from 'services/device/presentation/ui/sequence-chart/SequenceChartDataset';
import { ChartJsMouseEvent, SequenceChartOptionsPrototype } from 'services/device/presentation/ui/sequence-chart/SequenceChartOptions';
import {
	SequenceChartTooltipLabelFormatterBuilder
} from 'services/device/presentation/ui/sequence-chart/SequenceChartTooltipLabelFormatterBuilder';
import {
	SequenceChartTooltipTitleFormatterBuilder
} from 'services/device/presentation/ui/sequence-chart/SequenceChartTooltipTitleFormatterBuilder';

import 'chartjs-adapter-luxon';

import './sequence-chart.scss';
import { DeviceType } from 'services/device/domain/business/inventory/DeviceType';
import { AsyncFetchStatus } from '../../../../../store/common/AsyncFetchStatus';
import { useTypedSelector } from '../../../../../store/common/TypedSelector';
import { ReportRecordViewModel } from '../../../../report/domain/model/ReportRecordModel';
import {
	fetchExternalReportRecordsBySequence, selectExternalReportRecordsBySequence
} from '../../../store/externalReportRecordSlice';
import { ExternalReportSequenceChartOverlay } from './sequence-chart-overlay/ExternalReportSequenceChartOverlay';

export interface ExternalReportSequenceChartProps {
	reportUuid: string;
	sequence: SequenceViewModel;
	chartDescription: ChartDescription;
	showTooltip?: boolean;
	showOverlay?: boolean;
	onSelect?: (record: ReportRecordViewModel) => void;
	onDeselect?: (record: ReportRecordViewModel) => void;
	onDetails?: (record: ReportRecordViewModel) => void;
}

export const ExternalReportSequenceChart = (props: ExternalReportSequenceChartProps): JSX.Element | null => {

	const {
		chartDescription,
		onSelect = null,
		onDeselect = null,
		onDetails = null,
		showOverlay = false,
		showTooltip = false
	} = props;

	const sequenceViewModel = props.sequence;

	// Consume the dispatch object
	const dispatch = useDispatch();

	const [selectedRecord, setSelectedRecord] = useState<ReportRecordViewModel>(null);

	const recordsStoreFetchStatus = useTypedSelector(state => state.externalReportRecords.fetchStatus);

	// Read records from the server
	useEffect(() => {
		if (recordsStoreFetchStatus === AsyncFetchStatus.INITIAL || recordsStoreFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchExternalReportRecordsBySequence({
				sequenceUuid: sequenceViewModel.Uuid,
				reportUuid: props.reportUuid }));
		}
	});

	// Select the records from the store
	const records = useSelector(selectExternalReportRecordsBySequence(sequenceViewModel));

	// Prepare the sequence type defninition
	const sequenceTypeDfinition = getSequenceTypeDefinition(sequenceViewModel.Type);

	// Create the basic options
	const chartOptions = cloneDeep(SequenceChartOptionsPrototype);

	// Setup l10n properties
	const l10nContext = useContext(L10nContext);
	const lang = l10nContext.language ?? 'de';
	const tooltipDateTimeFormat = l10nContext.translate('sequence.chart.tooltip.title.dateTimeFormat', 'dd.MM.yyyy, HH:mm:ss');
	const axisLabelDateFormat = l10nContext.translate('sequence.chart.axisLabel.dateFormat', 'dd.MM.yy');

	const unitUtil = new UnitUtil(sequenceViewModel);
	const valueDisplayUnit = unitUtil.getDisplayUnitFromFieldDescription(chartDescription.value?.unit);

	chartOptions.scales.xAxes[0].adapters.date.locale = lang;
	chartOptions.scales.xAxes[0].time.tooltipFormat = tooltipDateTimeFormat;
	chartOptions.scales.xAxes[0].time.displayFormats.day = axisLabelDateFormat;
	chartOptions.tooltips.callbacks.label = SequenceChartTooltipLabelFormatterBuilder.build(
		lang,
		chartDescription.value.numberOfDecimals,
		valueDisplayUnit
	);
	chartOptions.tooltips.callbacks.title = SequenceChartTooltipTitleFormatterBuilder.build();

	if (showOverlay) {
		chartOptions.onSelect = (event: ChartJsMouseEvent, data: Array<{ [key: string]: any }>): void => {
			const selectedIndex = data[0]._index;
			const selectedRecordModel = records[selectedIndex];
			setSelectedRecord(selectedRecordModel);
			if (onSelect !== null) {
				onSelect(selectedRecordModel);
			}
		};
	}

	if (showTooltip) {
		chartOptions.tooltips.enabled = true;
	}

	const recordDataset = createRecordDataset(l10nContext.translate('sequence.chart.sequenceLabel.record', 'Messwert'));
	const expectedValueDataset = createBestValueDataset(l10nContext.translate('sequence.chart.sequenceLabel.bestValue', 'Idealwert'));
	const softThresholdBand = createSoftThresholdBand(
		l10nContext.translate('sequence.chart.sequenceLabel.softThreshold.minimum', 'Reaktionsschwelle Minimum'),
		l10nContext.translate('sequence.chart.sequenceLabel.softThreshold.maximum', 'Reaktionsschwelle Maximum')
	);
	const hardThresholdBand = createHardThresholdBand(
		l10nContext.translate('sequence.chart.sequenceLabel.hardThreshold.minimum', 'Toleranzgrenze Minimum'),
		l10nContext.translate('sequence.chart.sequenceLabel.hardThreshold.maximum', 'Toleranzgrenze Maximum')
	);

	let chartTicksMin = null;
	let chartTicksMax = null;

	let hideSoftThresholdMinimum = true;
	let hideSoftThresholdMaximum = true;

	let hideHardThresholdMinimum = true;
	let hideHardThresholdMaximum = true;

	for (const recordViewModel of records) {
		const record = sequenceTypeDfinition.restoreRecordFromViewData(recordViewModel.RecordConfiguration);

		const recordedAt = recordViewModel.RecordedAt;

		const value = record.getOutput(chartDescription.value).getSingle();

		let expectedValue = null;
		if (chartDescription.expectedValue !== null) {
			expectedValue = record.getOutput(chartDescription.expectedValue).getSingle();
		}

		let lowerReactionValue = null;
		if ((chartDescription?.lowerReactionValue ?? null) !== null) {
			lowerReactionValue = record.getOutput(chartDescription.lowerReactionValue).getSingle();
		}

		let upperReactionValue = null;
		if ((chartDescription?.upperReactionValue ?? null) !== null) {
			upperReactionValue = record.getOutput(chartDescription.upperReactionValue).getSingle();
		}

		let lowerToleranceValue = null;
		if ((chartDescription?.lowerToleranceValue ?? null) !== null) {
			lowerToleranceValue = record.getOutput(chartDescription.lowerToleranceValue).getSingle();
		}

		let upperToleranceValue = null;
		if ((chartDescription?.upperToleranceValue ?? null) !== null) {
			upperToleranceValue = record.getOutput(chartDescription.upperToleranceValue).getSingle();
		}

		if (value !== null) {
			recordDataset.data.push({
				x: recordedAt,
				y: value
			} as SequenceChartDatasetRecord);
			if (chartTicksMin === null) {
				chartTicksMin = value;
				chartTicksMax = value;
			} else {
				chartTicksMin = Math.min(chartTicksMin, value);
				chartTicksMax = Math.max(chartTicksMax, value);
			}
		}

		if (expectedValue !== null) {
			expectedValueDataset.data.push({
				x: recordedAt,
				y: expectedValue
			} as SequenceChartDatasetRecord);
			chartTicksMin = Math.min(chartTicksMin, expectedValue);
			chartTicksMax = Math.max(chartTicksMax, expectedValue);
		}

		if (lowerReactionValue !== null) {
			softThresholdBand.min.data.push({
				x: recordedAt,
				y: lowerReactionValue
			} as SequenceChartDatasetRecord);
			chartTicksMin = Math.min(chartTicksMin, lowerReactionValue);
			hideSoftThresholdMinimum = false;
		}

		if (upperReactionValue !== null) {
			softThresholdBand.max.data.push({
				x: recordedAt,
				y: upperReactionValue
			} as SequenceChartDatasetRecord);
			chartTicksMax = Math.max(chartTicksMax, upperReactionValue);
			hideSoftThresholdMaximum = false;
		}

		if (lowerToleranceValue !== null) {
			hardThresholdBand.min.data.push({
				x: recordedAt,
				y: lowerToleranceValue
			} as SequenceChartDatasetRecord);
			chartTicksMin = Math.min(chartTicksMin, lowerToleranceValue);
			hideHardThresholdMinimum = false;
		}

		if (upperToleranceValue !== null) {
			hardThresholdBand.max.data.push({
				x: recordedAt,
				y: upperToleranceValue
			} as SequenceChartDatasetRecord);
			chartTicksMax = Math.max(chartTicksMax, upperToleranceValue);
			hideHardThresholdMaximum = false;
		}
	}

	const arrayDeviceTypeDefinitions = sequenceTypeDfinition.getDeviceTypeDefinitions();

	if (arrayDeviceTypeDefinitions[0].getDeviceType() === DeviceType.RADIOCHEMICAL_PURITY) {
		const upperBoundaryValue = chartDescription?.upperBoundary ?? 0;
		const lowerBoundaryValue = chartDescription?.lowerBoundary ?? 0;

		chartOptions.scales.yAxes[0].ticks.min = lowerBoundaryValue;
		chartOptions.scales.yAxes[0].ticks.max = upperBoundaryValue;
	} else {
		chartOptions.scales.yAxes[0].ticks.min = chartTicksMin - Math.abs(chartTicksMin) * 0.15;
		chartOptions.scales.yAxes[0].ticks.max = chartTicksMax + Math.abs(chartTicksMax) * 0.15;
	}

	if (hideSoftThresholdMinimum) {
		hideDataset(softThresholdBand.min);
	}
	if (hideSoftThresholdMaximum) {
		hideDataset(softThresholdBand.max);
	}

	if (hideHardThresholdMinimum) {
		hideDataset(hardThresholdBand.min);
		softThresholdBand.min.fill = 'start';
	}
	if (hideHardThresholdMaximum) {
		hideDataset(hardThresholdBand.max);
		softThresholdBand.max.fill = 'end';
	}

	const chartDatasets: Array<SequenceChartDataset> = [];
	if (recordDataset.data.length > 0) {
		chartDatasets.push(recordDataset);
	}
	if (expectedValueDataset.data.length > 0) {
		chartDatasets.push(expectedValueDataset);
	}
	if (softThresholdBand.min.data.length > 0 || softThresholdBand.max.data.length > 0) {
		chartDatasets.push(softThresholdBand.min, softThresholdBand.max);
	}
	if (hardThresholdBand.min.data.length > 0 || hardThresholdBand.max.data.length > 0) {
		chartDatasets.push(hardThresholdBand.min, hardThresholdBand.max);
	}

	if (chartDatasets.length === 0) {
		return null;
	}

	const chartData = {
		datasets: chartDatasets
	};

	let softThresholdLegend = null;
	if (!hideSoftThresholdMinimum || !hideSoftThresholdMaximum) {
		softThresholdLegend = (
			<li className="sequence-chart__legend__terms__term sequence-chart__legend__terms__term--soft-threshold">
				{l10nContext.translate('sequence.chart.sequenceLabel.softThreshold.label', 'Reaktionsschwelle')}
			</li>
		);
	}

	let hardThresholdLegend = null;
	if (!hideHardThresholdMinimum || !hideHardThresholdMaximum) {
		hardThresholdLegend = (
			<li className="sequence-chart__legend__terms__term sequence-chart__legend__terms__term--hard-threshold">
				{l10nContext.translate('sequence.chart.sequenceLabel.hardThreshold.label', 'Toleranzgrenze')}
			</li>
		);
	}

	let selectedRecordOverlay = null;
	if (showOverlay && (selectedRecord ?? null) !== null) {
		selectedRecordOverlay = (
			<ExternalReportSequenceChartOverlay
				sequence={sequenceViewModel}
				record={selectedRecord}
				chartDescription={chartDescription}
				onDismiss={() => {
					if (onDeselect !== null) {
						onDeselect(selectedRecord);
					}
					setSelectedRecord(null);
				}}
				onDetails={(record) => {
					if (onDetails !== null) {
						onDetails(record);
					}
				}}
			/>
		);
	}

	return (
		<div className="sequence-chart">
			{selectedRecordOverlay}
			<div className="sequence-chart__visual">
				<Line redraw={false} data={chartData} options={chartOptions} />
			</div>
			<div className="sequence-chart__legend">
				{l10nContext.translate('sequence.chart.legend.title', 'Legende')}
				<ul className="sequence-chart__legend__terms">
					<li className="sequence-chart__legend__terms__term sequence-chart__legend__terms__term--value">
						{l10nContext.translate('sequence.chart.sequenceLabel.record', 'Messwert')}
					</li>
					{softThresholdLegend}
					{hardThresholdLegend}
				</ul>
			</div>
		</div>
	);

};
