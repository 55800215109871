import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Route } from 'router/Route';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { useContext } from 'react';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { WasteManagementReportViewModel } from '../../../../domain/models/WasteManagementReportModel';
import { deleteWasteManagementReport } from '../../../../store/wasteManagementReportSlice';
import { WasteManagementReportDeleteForm } from '../waste-management-report-delete-form/WasteManagementReportDeleteForm';
import { WasteManagementReportDeleteModalFailed } from './WasteManagementReportDeleteModalFailed';

export interface WasteManagementReportDeleteModalProps {
	report: WasteManagementReportViewModel
	onDismiss?: () => void;
}

export const WasteManagementReportDeleteModal = (props: WasteManagementReportDeleteModalProps): JSX.Element => {
	const { report, onDismiss } = props;

	// Consume the context
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const reportActionStatus = useTypedSelector(state => state.wasteManagementReports.actionStatus);

	const history = useHistory();
	const dispatch = useDispatch();

	const handleDelete = (): void => {
		dispatch(deleteWasteManagementReport({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			viewModel: report
		}));
		history.push(`${Route.NUCLIDE_MANAGEMENT}${Route.WASTE_REPORTS}`);
	};

	const renderModalContent = () => {
		switch (reportActionStatus) {
			case AsyncReducerStatus.IDLE:
				return <WasteManagementReportDeleteForm
					report={report}
					onDelete={handleDelete}
					onDismiss={onDismiss}
				/>;

			case AsyncReducerStatus.FAILED:
				return <WasteManagementReportDeleteModalFailed
					onDismiss={onDismiss}
				/>;
			default: return null;
		}
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
