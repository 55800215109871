import { useState } from 'react';
import { SingleNuclidesViewContext } from './SingleNuclidesViewContext';

export const SingleNuclidesViewProvider = (props: any): JSX.Element => {

	const [selectedNuclides, setSelectedNuclides] = useState<Array<string>>([]);

	return (
		<SingleNuclidesViewContext.Provider
			value={{
				selectedNuclides,
				setSelectedNuclides
			}}
		>
			<>
				{props.children}
			</>
		</SingleNuclidesViewContext.Provider>
	);
};
