import './TabbarItem.scss';

export type TabBarItemProps = {
	label: string,
	isActive: boolean,
	onClick: () => void
};

export function TabBarItem(props: TabBarItemProps): JSX.Element {

	const { isActive, label, onClick, ...rest } = props;
	
	return (
		<div
			className={`tab-bar-item ${isActive ? 'tab-bar-item--is-active' : '' } `}
			role='button'
			onClick={onClick}
			{...rest}
			tabIndex={0}
		>
			<span className='tab-bar-item__label'>{label}</span>
		</div>
	);
}
