import { ChangeEvent, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { L10nContext } from '../../../../context/L10nContext';
import { ColumnLayoutSection } from '../../../../presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from '../../../../presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from '../../../../presentation/ui/layouts/column-layout/ColumnLayout';
import { MainLayoutSection } from '../../../../presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from '../../../../presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { ViewLayoutSection } from '../../../../presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from '../../../../presentation/ui/layouts/view-layout/ViewLayout';
import { ButtonPrimary } from '../../../../presentation/ui/partials/button/button-primary/ButtonPrimary';
import { Separator } from '../../../../presentation/ui/partials/separator/Separator';
import { CreatableContainerViewModel } from '../../domain/model/ContainerModel';
import { CyclotronProductViewModel } from '../../domain/model/CyclotronProductModel';
import { EluateViewModel } from '../../domain/model/EluateModel';
import { NuclideIsotope } from '../../domain/model/isotopes/NuclideIsotope';
import { resetActionStatus as resetContainerActionStatus } from '../../store/containerSlice';
import { resetActionStatus as resetCyclotronProductActionStatus } from '../../store/cyclotronProductSlice';
import { resetActionStatus as resetEluateActionStatus } from '../../store/eluateSlice';

import { SingleNuclideCollection } from '../ui/card-collections/single-nuclide-collection/SingleNuclideCollection';
import { SingleNuclidesAssignToContainerModal } from '../ui/single-nuclides-action/single-nuclides-assign-to-container-modal/SingleNuclidesAssignToContainerModal';
import {
	CyclotronProductToActiveStateModal
} from '../ui/cyclotron-product-action/cyclotron-product-to-active-state-modal/CyclotronProductToActiveStateModal';
import { EluateToActiveStateModal } from '../ui/eluate-action/eluate-to-active-state-modal/EluateToActiveStateModal';
import { ContainerViewModalType } from './ContainerViewModalType';
import { CyclotronProductViewModalType } from './CyclotronProductViewModalType';
import { GeneratorViewModalType } from './GeneratorViewModalType';
import { SingleNuclidesViewContext } from './SingleNuclidesViewContext';

export interface EluatePayloadType extends EluateViewModel {
	Isotope: NuclideIsotope
}

export interface CyclotronProductPayloadType {
	cyclotronProductPayloadType: CyclotronProductViewModel
}

export type CyclotronProductOrEluatePayloadType = EluatePayloadType | CyclotronProductViewModel;


export const SingleNuclidesView = (): JSX.Element => {

	const l10nContext = useContext(L10nContext);
	const { selectedNuclides, setSelectedNuclides } = useContext(SingleNuclidesViewContext);

	const dispatch = useDispatch();

	const [modalType, setModalType] = useState<GeneratorViewModalType | CyclotronProductViewModalType | ContainerViewModalType >(null);
	const [modalPayload, setModalPayload] = useState<EluatePayloadType | CyclotronProductViewModel | CreatableContainerViewModel >(null);
	// const [selectedNuclides, setSelectedNuclides] = useState<Array<string>>([]);

	function isCyclotronProductPayloadType(obj: any): obj is CyclotronProductViewModel {
		return !!(obj as CyclotronProductViewModel).Radiopharmaceutical;
	}

	function payloadType(obj: any): GeneratorViewModalType | CyclotronProductViewModalType {
		if (isCyclotronProductPayloadType(obj)) {
			return CyclotronProductViewModalType.TO_ACTIVE_STATE;
		}
		return GeneratorViewModalType.TO_ACTIVE_STATE_ELUATE;
	}

	// Handle close modal for success and failure
	const handleDismiss = () => {
		setModalType(null);
	};

	const handleSetSelectedNuclides = () => {
		setSelectedNuclides([]);
	};

	const handleModalClick = (
		type: GeneratorViewModalType | CyclotronProductViewModalType | ContainerViewModalType,
		payload: EluatePayloadType | CyclotronProductViewModel | CreatableContainerViewModel = null
	) => {

		dispatch(resetCyclotronProductActionStatus());
		dispatch(resetEluateActionStatus());
		dispatch(resetContainerActionStatus());

		setModalType(type);
		setModalPayload(payload);
	};

	// Show modal
	const showModal = (): JSX.Element => {
		let modal: JSX.Element = null;

		if (modalType === GeneratorViewModalType.TO_ACTIVE_STATE_ELUATE) {
			modal =
				<EluateToActiveStateModal
					eluate={modalPayload as EluateViewModel}
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === CyclotronProductViewModalType.TO_ACTIVE_STATE) {
			modal =
				<CyclotronProductToActiveStateModal
					cyclotronProduct={modalPayload as CyclotronProductViewModel}
					onDismiss={handleDismiss}
				/>;
		} else if (modalType === ContainerViewModalType.CHOOSE_CONTAINER) {
			modal =
				<SingleNuclidesAssignToContainerModal
					selectedNuclides={selectedNuclides}
					onSetSelectedNuclides={handleSetSelectedNuclides}
					onDismiss={handleDismiss}
				/>;
		}

		return modal;
	};

	const handleNuclideSelect = (uuid: string) => {
		let updatedSelectedNuclides = [...selectedNuclides];
		if (!updatedSelectedNuclides.includes(uuid)) {
			updatedSelectedNuclides.push(uuid);
		} else {
			updatedSelectedNuclides = updatedSelectedNuclides.filter(element => element !== uuid);
		}
		setSelectedNuclides(updatedSelectedNuclides);
	};

	const handleSelectAllNuclids = (
		nuclide: string,
		filteredNuclides: Array<EluatePayloadType | CyclotronProductViewModel>,
		e: ChangeEvent<HTMLInputElement>
	) => {
		let updatedSelectedNuclides = [...selectedNuclides];
		filteredNuclides.forEach((item) => {
			if (nuclide === item.Isotope) {
				if (e.target.checked === true) {
					if (!updatedSelectedNuclides.includes(item.Uuid)) {
						updatedSelectedNuclides.push(item.Uuid);
					}
				} else {
					updatedSelectedNuclides = updatedSelectedNuclides.filter((selectedItem) => {
						return selectedItem !== item.Uuid;
					});
				}

			}
		});
		setSelectedNuclides(updatedSelectedNuclides);
	};

	const handleCheckAllNuclides = (nuclideLabel: string, filteredNuclides: Array<EluatePayloadType | CyclotronProductViewModel>) => {
		let isChecked;
		filteredNuclides.forEach((item) => {
			if (nuclideLabel === item.Isotope) {
				isChecked = selectedNuclides.includes(item.Uuid);
			}
		});
		return isChecked;
	};

	return (
		<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
			<ViewLayout>
				<ViewLayoutSection>
					{showModal()}
					<SingleNuclideCollection
						onActionClick={(_payload, item) => handleModalClick(payloadType(item), item)}
						selectedNuclides={selectedNuclides}
						checkNuclide={handleNuclideSelect}
						onHandleSelectAllNuclids={handleSelectAllNuclids}
						onHandleCheckAllNuclides={handleCheckAllNuclides}
					/>
				</ViewLayoutSection>
				<ViewLayoutSection>
					<Separator />
				</ViewLayoutSection>
				<ViewLayoutSection>
					<ColumnLayout>
						<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_ALIGN_RIGHT}>
							<ButtonPrimary
								disabled={selectedNuclides.length === 0}
								buttonText={selectedNuclides.length + l10nContext.translate('common.button.bindAllNuclides', ' ausgewählt zum Binden')}
								onClick={() => handleModalClick(ContainerViewModalType.CHOOSE_CONTAINER)}
							/>
						</ColumnLayoutSection>
					</ColumnLayout>
				</ViewLayoutSection>

			</ViewLayout>
		</MainLayoutSection>
	);
};
