import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { MailAddressInput } from 'services/device/domain/business/common/Input';

import { Label } from 'presentation/ui/partials/input/label/Label';
import { Textarea } from 'presentation/ui/partials/input/textarea/Textarea';

export interface MailAddressesInputProps {
	input: MailAddressInput;
}

// TODO: Replace by a repeatable text input
export const MailAddressesInputRenderer = (props: MailAddressesInputProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const { input } = props;

	let label = l10nContext.translate(input.getLabelKey());
	if (input.getDescription()?.mandatory === true) {
		label += '*';
	}

	return (
		<Label label={label}>
			<Textarea
				name={input.getName()}
				defaultValue={input.get()?.join('\n') ?? ''}
				required={!input.isOptional()}
				onChange={(event) => {
					input.set(event.target.value.split('\n'));
				}}
			/>
		</Label>
	);

};
