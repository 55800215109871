import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	MaintenanceLogEntryModelConverter as ModelConverter,
	MaintenanceLogEntryPersistenceModel as PersistenceModel,
	MaintenanceLogEntryStoreModel as StoreModel,
	MaintenanceLogEntryViewModel as ViewModel,
	CreatableMaintenanceLogEntryViewModel as CreatableViewModel
} from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';

export class MaintenanceLogHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'maintenance-log/api/v1';
	protected collectionControllerUri = 'maintenance-log-entries';
	protected entityControllerUri = 'maintenance-log-entry';

	protected responseCollectionKey = 'MaintenanceLogEntryModelCollection';
	protected responseEntityKey = 'MaintenanceLogEntryModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
