import { BrowserRouter } from 'react-router-dom';

import { L10nContextProvider } from 'context/L10nContextProvider';
import { NetworkContextProvider } from 'context/NetworkContextProvider';

import { RouterAccessGroupConfig } from '../../router/RouterAccessGroupConfig';

export const RootView = (): JSX.Element => {

	/*
	const getErrorBoundaryComponent = (error: Error): JSX.Element => {
		return (
			<Error error={error} />
		);
	};
	 */

	return (
		<>
			<NetworkContextProvider>
				<L10nContextProvider>
					<BrowserRouter>
						<RouterAccessGroupConfig />
					</BrowserRouter>
				</L10nContextProvider>
			</NetworkContextProvider>
		</>
	);

};
