import { L10nContext } from 'context/L10nContext';

import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Illustration } from 'presentation/ui/partials/illustration/Illustration';
import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';
import { Tag, TagType } from 'presentation/ui/partials/tag/Tag';
import { useContext } from 'react';
import { GeneratorModelState } from 'services/nuclide/domain/model/GeneratorModelState';

import './card-generator-list-item-header.scss';

export interface CardGeneratorListItemHeaderProps {
	/**
	 * Title
	 */
	title: string;
	/**
	 * (Optional) Sub-Title
	 */
	subtitle?: string | Date;
	/**
	 * (Optional) Illustration
	 */
	illustration?: IllustrationIdentifier
	/**
	 * (Optional) State
	 */
	state?: GeneratorModelState;
}

/**
 * A card sequence list item header component
 */
export const CardGeneratorListItemHeader = (props: CardGeneratorListItemHeaderProps): JSX.Element => {
	const { title, subtitle, illustration, state } = props;

	const l10nContext = useContext(L10nContext);

	const illuElement = illustration ?
		<Illustration name={illustration} /> :
		null;

	const subTitle = subtitle ?
		<p className="card-generator-list-item-header__subtitle">
			{subtitle}
		</p> :
		null;

	const tagElement = (): JSX.Element => {
		const tagLabelDispatched = l10nContext.translate('common.tags.dispatched', 'Abgegangen');

		if (state === GeneratorModelState.DISPATCHED) {
			return (
				<Tag
					label={tagLabelDispatched}
					type={TagType.INFO}
					icon={IconIdentifier.DISPATCH}
				/>
			);
		}
		return null;
	};

	return (
		<div className={`card-generator-list-item-header${ !illustration ? ' no-illustration' : ''}`}>
			{illuElement}
			<div className="card-generator-list-item-header__content">
				<span className="card-generator-list-item-header__title-wrapper">
					<h1 className="card-generator-list-item-header__title">
						{title}
					</h1>
				</span>

				{subTitle}
			</div>
			{tagElement()}
		</div>
	);
};
