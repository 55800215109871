import { memo, useContext } from 'react';
import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';
import { useDispatch } from 'react-redux';
import { ContainerViewModel } from 'services/nuclide/domain/model/ContainerModel';

import './card-item-container-dispatch-attributes.scss';
import { ClientContext } from '../../../../../../services/core/context/ClientContext';
import { FacilityContext } from '../../../../../../services/core/context/FacilityContext';
import { fetchContainerDispatch, selectContainerDispatchByUuid } from '../../../../../../services/nuclide/store/containerDispatchSlice';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';

export interface CardItemContainerDispatchAttributesProps {
	/**
	 * Container
	 */
	container: ContainerViewModel;
}

/**
 * A card item Container detail attributes component
 */
export const CardItemContainerDispatchAttributes = memo((props: CardItemContainerDispatchAttributesProps): JSX.Element => {
	const { container } = props;

	const dispatch = useDispatch();

	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const dispatchUuid = container?.DispatchId;

	dispatch(fetchContainerDispatch({
		clientUuid: clientContext.selectedClientUuid,
		facilityUuid: facilityContext.selectedFacilityUuid,
		dispatchUuid
	}));

	const containerDispatchModel = useTypedSelector(selectContainerDispatchByUuid(dispatchUuid));

	const dispatchedDateTimeFormatted = l10nContext.formatDate(containerDispatchModel?.DispatchedDatetime);

	const checkEmptyValue = (val: string): string => {
		return val !== '' && val !== null ? val : '-';
	};

	const translations = {

		dispatchedDateTime: { label: l10nContext.translate('containers.dispatchedDateTime', 'Erstellt am'), value: dispatchedDateTimeFormatted },
		dispatchedByName: { label: l10nContext.translate('containers.dispatchedBy', 'Abgang erfasst durch'), value: checkEmptyValue(containerDispatchModel?.DispatchedByName) },
		dispatchedComment: { label: l10nContext.translate('containers.dispatchedComment', 'Kommentar'), value: checkEmptyValue(containerDispatchModel?.DispatchedComment) }
	};

	const attributes = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const styleClass = 'attribute';

			return (
				<label
					key={Guid.generate()}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value }</p>
				</label>
			);
		});
	};

	return (
		<div className='card-item-container-dispatch-attributes'>
			<ul className='card-item-container-dispatch-attributes__attribute'>
				{attributes()}
			</ul>
		</div>
	);
});
