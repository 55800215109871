import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { Guid } from 'lib/guid/Guid';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';

import { SiteTitle } from '../site-title/SiteTitle';

import './error.scss';

type ErrorType = Error | string | string[];

export interface ErrorProps {
	/**
	 * Error message[s]
	 */
	error?: ErrorType;
}

export const Error = (props: ErrorProps): JSX.Element => {
	const { error } = props;

	const l10nContext = useContext(L10nContext);

	const errorList = Array.isArray(error) ?
		error.map((message: string) =>
			<NotificationBar
				key={Guid.generate()}
				message={message}
				level={NotificationLevel.LEVEL_ERROR}
			/>
		) :
		<NotificationBar
			message={typeof error === 'string' ? error : error.message}
			level={NotificationLevel.LEVEL_ERROR}
		/>;

	return (
		<div className="error">
			<SiteTitle
				title={l10nContext.translate('common.error.caption', 'Da ist etwas schief gelaufen:')}
			/>
			<NotificationBars>
				{errorList}
			</NotificationBars>
		</div>
	);
};
