import React, { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';
import { NetworkContext } from 'context/NetworkContext';

import { Guid } from 'lib/guid/Guid';

import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentModelState } from 'services/documents/domain/model/DocumentModelState';

import { CardDocument } from 'presentation/ui/components/cards/card-document/card-document/CardDocument';
import { CardDocumentAction } from 'presentation/ui/components/cards/card-document/CardDocumentActions';
import { CardItemControls } from 'presentation/ui/partials/card/card-item-controls/card-item-controls/CardItemControls';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { CardItemFileSize } from 'presentation/ui/partials/card/card-item-file-size/CardItemFileSize';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { CardItemState } from 'presentation/ui/partials/card/card-item-state/CardItemState';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';

import 'presentation/ui/components/cards/card-maintenance-log/card-maintenance-log-list/card-maintenance-log-list.scss';

export interface DocumentEntryDashboardProps {
	documentEntry: DocumentViewModel;
	onActionClick: (actionPayload: CardItemControlActionType, documentEntry: DocumentViewModel) => void;
}

export const DocumentEntryDashboard = (props: DocumentEntryDashboardProps): JSX.Element => {
	const { documentEntry, onActionClick } = props;

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);
	const networkContext = useContext(NetworkContext);

	const handleDocumentDownload = () => {
		onActionClick({
			type: CardDocumentAction.DOWNLOAD,
			cardId: ''
		}, documentEntry);
	};

	const formatedDocumentDate = l10nContext.formatDateTime(documentEntry.CreatedAt);

	const formatBytes = (number: number): string => {
		const k = 1024;
		const dm = 0;
		const sizes = ['Bytes', 'KB', 'MB', 'GB'];
		const i = Math.floor(Math.log(number) / Math.log(k));

		return parseFloat((number / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};

	const formatedFileExtension = documentEntry.Filename.split('.').pop().toUpperCase() + ' (' + formatBytes(documentEntry.FileInfo.Filesize) + ')';

	const renderCardItemControls = () => {
		if (documentEntry.Scope === DocumentModelScope.DOCUMENT_FOLDER) {
			return <CardItemControls
				cardId={`${Math.random()}${document}`}
				actionMove={authContext.hasPermission(Permission.DOCUMENT_UPDATE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				actionArchive={authContext.hasPermission(Permission.DOCUMENT_UPDATE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				actionDelete={authContext.hasPermission(Permission.DOCUMENT_DELETE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				onClick={(actionPayload) => onActionClick(actionPayload, documentEntry)}
			/>;
		}
		return <CardItemControls
			cardId={`${Math.random()}${document}`}
			actionArchive={authContext.hasPermission(Permission.DOCUMENT_UPDATE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
			actionDelete={authContext.hasPermission(Permission.DOCUMENT_DELETE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
			onClick={(actionPayload) => onActionClick(actionPayload, documentEntry)}
		/>;

	};

	return (
		<>
			<CardDocument
				key={Guid.generate()}
				cardId={`${Math.random()}${document}`}
				button={{
					icon: IconIdentifier.DOWNLOAD,
					disabled: !networkContext.online,
					onClick: handleDocumentDownload
				}}
			>
				<CardItemHeader
					title={documentEntry.Filename}
					archived={documentEntry.State === DocumentModelState.ARCHIVED}
					illustration={IllustrationIdentifier.DOCUMENT_PLACE}
				/>

				{renderCardItemControls()}

				<CardItemState date={formatedDocumentDate}>
					<CardItemFileSize label={formatedFileExtension} />
				</CardItemState>
			</CardDocument>
		</>
	);
};
