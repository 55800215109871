import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { MembershipViewModel } from 'services/core/domain/model/MembershipModel';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { MembershipUpdateSuccessNotification } from './MembershipUpdateSuccessNotification';
import { MembershipUpdateForm } from '../membership-update-form/MembershipUpdateForm';
import { MembershipUpdateFailedNotification } from './MembershipUpdateFailedNotification';

export interface MembershipUpdateModalProps {
	membership: MembershipViewModel
	onDismiss?: () => void;
}

export const MembershipUpdateModal = (props: MembershipUpdateModalProps): JSX.Element => {
	const { membership, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const membershipActionStatus = useTypedSelector(state => state.memberships.actionStatus);

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (membershipActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent = <MembershipUpdateForm membership={membership} />;
				break;

			case AsyncReducerStatus.UPDATED:
				modalContent = <MembershipUpdateSuccessNotification onClickConfirm={onDismiss} />;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <MembershipUpdateFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.memberships.modal.action.edit.caption', 'Benutzer-Rolle(n) bearbeiten')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
