import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';

import {
	WasteManagementReportCyclotronProductModelConverter as ModelConverter,
	WasteManagementReportCyclotronProductPesistenceModel as PersistenceModel,
	WasteManagementReportCyclotronProductStoreModel as StoreModel,
	WasteManagementReportCyclotronProductViewModel as ViewModel,
	CreatableWasteManagementReportCyclotronProductViewModel as CreatableViewModel
} from 'services/waste-management-report/domain/models/WasteManagementReportCyclotronProductModel';
import { ExternalWasteReportHttpPersistence } from '../../../lib/persistence/http/ExternalWasteReportHttpPersistence';

export class ExternalWasteManagementReportCyclotronProductHttpPersistence
	extends ExternalWasteReportHttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'cyclotron-product/waste-management-report-api/v1';
	protected collectionControllerUri = 'cyclotron-products';
	protected entityControllerUri = 'cyclotron-product';

	protected responseCollectionKey = 'CyclotronProductModelCollection';
	protected responseEntityKey = 'CyclotronProductModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
