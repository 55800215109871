import { configureStore } from '@reduxjs/toolkit';

import { membershipSlice, MembershipState } from 'services/core/store/membershipSlice';
import { roleSlice, RoleState } from 'services/core/store/roleSlice';
import { devicesSlice, DeviceState } from 'services/device/store/devicesSlice';
import { recordsSlice, RecordState } from 'services/device/store/recordSlice';
import { sequencesSlice, SeqeunceState } from 'services/device/store/sequenceSlice';
import { documentSlice, DocumentState } from 'services/documents/store/documentSlice';
import { folderSlice, FolderState } from 'services/documents/store/folderSlice';
import { eluateSlice, EluateState } from 'services/nuclide/store/eluateSlice';
import { generatorSlice, GeneratorState } from 'services/nuclide/store/generatorSlice';
import { maintenanceLogEntrySlice, MaintenanceLogEntryState } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { reportSlice, ReportState } from 'services/report/store/reportSlice';
import { cyclotronProductSlice, CyclotronProductState } from 'services/nuclide/store/cyclotronProductSlice';
import { externalReportSlice, ExternalReportState } from 'services/report/store/externalReportSlice';
import { externalReportDevicesSlice, ExternalReportDeviceState } from 'services/device/store/externalReportDevicesSlice';
import { externalReportSequenceSlice, ExternalReportSequenceState } from 'services/device/store/externalReportSequenceSlice';
import { externalReportRecordSlice, ExternalReportRecordState } from 'services/device/store/externalReportRecordSlice';
import { externalReportDocumentSlice, ExternalReportDocumentState } from 'services/documents/store/externalReportDocumentSlice';
import { externalReportMaintenanceLogEntrySlice, ExternalReportMaintenanceLogEntryState } from 'services/maintenance-log/store/externalReportMaintenanceLogEntrySlice';
import { containerSlice, ContainerState } from 'services/nuclide/store/containerSlice';
import { containerDisposeSlice, ContainerDisposeState } from 'services/nuclide/store/containerDisposeSlice';
import { containerDispatchSlice, ContainerDispatchState } from 'services/nuclide/store/containerDispatchSlice';
import { documentsByDevicesSlice, DocumentsByDevicesState } from '../services/documents/store/documentsByDevicesSlice';
import {
	documentsCollectionByDevicesSlice,
	DocumentsCollectionByDevicesState
} from '../services/documents/store/documentsCollectionByDevicesSlice';
import {
	externalWasteManagementReportEluatesSlice,
	ExternalWasteManagementReportEluateState
} from '../services/nuclide/store/externalWasteManagementEluateReportSlice';
import {
	externalWasteManagementReportCyclotronProductsSlice,
	ExternalWasteManagementReportCyclotronProductState
} from '../services/nuclide/store/externalWasteManagementReportCyclotronProductSlice';
import {
	externalWasteManagementReportGeneratorsSlice,
	ExternalWasteManagementReportGeneratorState
} from '../services/nuclide/store/externalWasteManagementReportGeneratorSlice';
import {
	externalWasteManagementReportSlice,
	ExternalWasteManagementReportState
} from '../services/waste-management-report/store/externalWasteManagementReportSlice';
import {
	wasteManagementReportSlice,
	WasteManagementReportState
} from '../services/waste-management-report/store/wasteManagementReportSlice';

// Register all required reducers globally
export default configureStore({
	reducer: {
		devices: devicesSlice.reducer,
		sequences: sequencesSlice.reducer,
		records: recordsSlice.reducer,
		maintenanceLogEntries: maintenanceLogEntrySlice.reducer,
		documents: documentSlice.reducer,
		documentsByDevices:documentsByDevicesSlice.reducer,
		documentsCollectionByDevices:documentsCollectionByDevicesSlice.reducer,
		folders: folderSlice.reducer,
		memberships: membershipSlice.reducer,
		roles: roleSlice.reducer,
		reports: reportSlice.reducer,
		generators: generatorSlice.reducer,
		eluates: eluateSlice.reducer,
		cyclotronProducts: cyclotronProductSlice.reducer,
		containers: containerSlice.reducer,
		containerDispose: containerDisposeSlice.reducer,
		containerDispatch: containerDispatchSlice.reducer,
		externalReport: externalReportSlice.reducer,
		externalReportDevices: externalReportDevicesSlice.reducer,
		externalReportSequences: externalReportSequenceSlice.reducer,
		externalReportRecords: externalReportRecordSlice.reducer,
		externalReportDocuments: externalReportDocumentSlice.reducer,
		externalReportMaintenanceLogEntries: externalReportMaintenanceLogEntrySlice.reducer,
		wasteManagementReports: wasteManagementReportSlice.reducer,
		externalWasteManagementReport: externalWasteManagementReportSlice.reducer,
		externalWasteManagementReportGenerators: externalWasteManagementReportGeneratorsSlice.reducer,
		externalWasteManagementReportEluates: externalWasteManagementReportEluatesSlice.reducer,
		externalWasteManagementReportCyclotronProducts: externalWasteManagementReportCyclotronProductsSlice.reducer
	},
});

// Provide an interface defining the type of the root state
export interface RootState {
	devices: DeviceState;
	sequences: SeqeunceState;
	records: RecordState;
	maintenanceLogEntries: MaintenanceLogEntryState;
	documents: DocumentState;
	documentsByDevices: DocumentsByDevicesState;
	documentsCollectionByDevices: DocumentsCollectionByDevicesState;
	folders: FolderState;
	memberships: MembershipState;
	roles: RoleState;
	reports: ReportState;
	generators: GeneratorState;
	eluates: EluateState;
	cyclotronProducts: CyclotronProductState;
	containers: ContainerState;
	containerDispose: ContainerDisposeState;
	containerDispatch: ContainerDispatchState;
	externalReport: ExternalReportState;
	externalReportDevices: ExternalReportDeviceState;
	externalReportSequences: ExternalReportSequenceState;
	externalReportRecords: ExternalReportRecordState;
	externalReportDocuments: ExternalReportDocumentState;
	externalReportMaintenanceLogEntries: ExternalReportMaintenanceLogEntryState;
	wasteManagementReports: WasteManagementReportState;
	externalWasteManagementReport: ExternalWasteManagementReportState;
	externalWasteManagementReportGenerators: ExternalWasteManagementReportGeneratorState;
	externalWasteManagementReportEluates: ExternalWasteManagementReportEluateState;
	externalWasteManagementReportCyclotronProducts: ExternalWasteManagementReportCyclotronProductState;
}
