import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ExternalReportDocumentDownloadModalContent } from './ExternalReportDocumentDownloadModalContent';

export interface ExternalReportDocumentDownloadModalProps {
	document: DocumentViewModel;
	onClickPrevious?: () => void;
	onDismiss: () => void;
}

export const ExternalReportDocumentDownloadModal = (props: ExternalReportDocumentDownloadModalProps): JSX.Element => {
	const { document, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	return (
		<ModalDialogue
			height={ModalDialogueHeight.HEIGHT_FLEX}
			onDismiss={onDismiss}
		>
			<ModalDialogueHeader
				captionText={l10nContext.translate('common.modal.captionDownload')}
				captionIcon={IconIdentifier.DOWNLOAD}
			/>
			<ExternalReportDocumentDownloadModalContent document={document} onDismiss={onDismiss} />
		</ModalDialogue>
	);
};
