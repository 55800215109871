import { useContext } from 'react';
import { useTypedSelector } from 'store/common/TypedSelector';

import { AuthContext } from 'services/core/context/AuthContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { permissionGroupGenerator } from 'services/core/lib/auth/AuthService';
import { Guid } from 'lib/guid/Guid';

import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { GeneratorCollection } from '../ui/card-collections/generator-collection/GeneratorCollection';

/**
 * The generators view
 */
export const GeneratorsView = (): JSX.Element => {

	// Consume the contexts
	const authContext = useContext(AuthContext);

	// Use a custom selector function to provide type information for the state definde in the slice
	const storeActionStatus = useTypedSelector(state => state.generators.actionStatus);
	// const storeFetchStatus = useTypedSelector(state => state.generators.fetchStatus);
	const storeLastActionError = useTypedSelector(state => state.generators.lastActionError);

	// Handle permissions
	if (!authContext.hasAnyPermission(permissionGroupGenerator)) {
		throw new Error('Permission denied');
	}

	const renderNotifications = (): Array<JSX.Element> => {
		const notifications = [];
		if (storeActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					key={Guid.generate()}
					message={storeLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}
		return notifications;
	};

	return (
		<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
			<ViewLayout>
				<ViewLayoutSection>
					<NotificationBars>
						{renderNotifications()}
					</NotificationBars>
				</ViewLayoutSection>

				<ViewLayoutSection>
					<GeneratorCollection />
				</ViewLayoutSection>
			</ViewLayout>
		</MainLayoutSection>
	);
};
