import { ModelQueryOrderDirection, ModelQueryOrderDirective, ModelQueryOrderSortAlgo } from 'lib/persistence/idb/query/ModelQueryOrder';

export class ModelQueryOrderClosureBuilder<PersistenceModel> {

	public build(
		key: string,
		direction: ModelQueryOrderDirection = ModelQueryOrderDirection.ASC,
		sortAlgo: ModelQueryOrderSortAlgo = ModelQueryOrderSortAlgo.ALPHANUMERIC
	): (left: PersistenceModel, right: PersistenceModel) => number {
		return (left: PersistenceModel, right: PersistenceModel): number => {
			return new ModelQueryOrderDirective<PersistenceModel>(key, direction, sortAlgo).order(left, right);
		};
	}

}
