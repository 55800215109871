import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	DeviceModelConverter as ModelConverter,
	DevicePersistenceModel as PersistenceModel,
	DeviceStoreModel as StoreModel,
	DeviceViewModel as ViewModel,
	CreatableDeviceViewModel as CreatableViewModel
} from 'services/device/domain/model/DeviceModel';

export class DeviceHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'device/api/v1';
	protected collectionControllerUri = 'devices';
	protected entityControllerUri = 'device';

	protected responseCollectionKey = 'DeviceModelCollection';
	protected responseEntityKey = 'DeviceModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
