import 'presentation/ui/components/cards/card-maintenance-log/card-maintenance-log-list/card-maintenance-log-list.scss';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { Route } from 'router/Route';
import { CardItemState } from 'presentation/ui/partials/card/card-item-state/CardItemState';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { mapTypeToIlluIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifierMapper';
import { DeviceType } from 'services/device/domain/business/inventory/DeviceType';
import { MaintenanceLogEntryModelState } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModelState';
import { TagType } from 'presentation/ui/partials/tag/Tag';
import { L10nContext } from 'context/L10nContext';

export interface MaintenanceLogEntryProps {
	maintenanceLogEntry: MaintenanceLogEntryViewModel;
	onSelect: (maintenanceLogEntry: MaintenanceLogEntryViewModel) => void;
	resolveMaintenanceLogEnabled?: boolean;
}

export const MaintenanceLogEntry = (props: MaintenanceLogEntryProps): JSX.Element => {
	const { maintenanceLogEntry, onSelect, resolveMaintenanceLogEnabled = true } = props;

	const history = useHistory();

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	const maintenanceLogEntryStatus = {
		label: maintenanceLogEntry.State === MaintenanceLogEntryModelState.ONGOING ? l10nContext.translate('maintenanceLog.status.open', 'offen') : l10nContext.translate('maintenanceLog.status.resolved', 'abgeschlossen'),
		type: maintenanceLogEntry.State === MaintenanceLogEntryModelState.ONGOING ? TagType.NEGATIVE : TagType.POSITIVE,
		icon: IconIdentifier.EDIT
	};

	const formatedIncidentStart = l10nContext.formatDateTime(maintenanceLogEntry.IncidentDateStart);

	return (
		<>
			<div className="card-maintenance-log-list">
				<div className="card-maintenance-log-list__content">
					<CardItemHeader
						title={maintenanceLogEntry.IncidentTitle}
						subtitle={maintenanceLogEntry.DeviceName}
						illustration={mapTypeToIlluIdentifier(DeviceType.ACTIVIMETER)}
					/>
					<CardItemSingleControl
						cardId={maintenanceLogEntry.Uuid}
						actionDetails={CardItemControlState.ENABLED}
						onClick={() => history.push(`${Route.MAINTENANCE_LOG}/${maintenanceLogEntry.Uuid}`)}
					/>
					<CardItemState date={formatedIncidentStart} tag={maintenanceLogEntryStatus} />
				</div>
				<ButtonIcon
					icon={IconIdentifier.EDIT}
					shape={ButtonIconShape.RECTANGLE}
					weight={ButtonIconWeight.PRIMARY}
					disabled={!authContext.hasPermission(Permission.MAINTENANCE_LOG_ENTRY_UPDATE) || !resolveMaintenanceLogEnabled}
					onClick={() => {
						onSelect(maintenanceLogEntry);
					}}
				/>
			</div>
		</>
	);
};
