import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { RecordViewModel } from 'services/device/domain/model/RecordModel';

import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';

export interface RecordUpdateSuccessNotificationProps {
	onClickConfirm: () => void;
	record?: RecordViewModel | null;
}

export const RecordUpdateSuccessNotification = (props: RecordUpdateSuccessNotificationProps): JSX.Element => {
	const { onClickConfirm, record } = props;

	const l10nContext = useContext(L10nContext);
	const history = useHistory();

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('view.sequence.modal.action.updateRecord.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.sequence.modal.action.updateRecord.successMessage', 'Die Messreihe wurde erfolgreich geändert')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={
							history.location.pathname.includes(Route.DEVICES) ?
								l10nContext.translate('view.sequence.modal.action.updateRecord.btnDeviceDetails', 'Weiter zu den Messreihendetails') :
								l10nContext.translate('view.sequence.modal.action.updateRecord.btnSuccessMeasurementGoalsDetails', 'Weiter zu den Messziel-Details')
						}
						onClick={onClickConfirm}
					/>
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={
							history.location.pathname.includes(Route.DEVICES) ?
								l10nContext.translate('view.sequence.modal.action.updateRecord.btnSuccess', 'Weiter zur Messreihen-Übersicht') :
								l10nContext.translate('view.sequence.modal.action.updateRecord.btnSuccessMeasurementGoalsOverview', 'Weiter zur Messziel-Übersicht')
						}
						onClick={
							history.location.pathname.includes(Route.DEVICES) ?
								() => history.push(`${Route.DEVICES}/${record.Device}${Route.SEQUENCES}`) :
								() => history.push(`${Route.CLEARANCES}/${record.Device}${Route.SEQUENCES}`)
						}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
