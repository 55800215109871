import { useContext } from 'react';
import { L10nContext } from '../../../../../../context/L10nContext';
import { AuthContext } from '../../../../../../services/core/context/AuthContext';
import { Permission } from '../../../../../../services/core/lib/auth/AuthService';
import { getSequenceTypeDefinition } from '../../../../../../services/device/domain/business/inventory/SequenceTypeDefinition';
import { SequenceViewModel } from '../../../../../../services/device/domain/model/SequenceModel';
import { SequenceModelState } from '../../../../../../services/device/domain/model/SequenceModelState';
import { ReportViewModel } from '../../../../../../services/report/domain/model/ReportModel';
import { CardReportSequenceItemHeader } from '../../../../partials/card/card-item-header/card-report-sequence-item-header/CardReportSequenceItemHeader';
import { Icon } from '../../../../partials/icon/Icon';
import { IconIdentifier } from '../../../../partials/icon/IconIdentifier';
import { DatePicker } from '../../../../partials/input/date-picker/DatePicker';

import 'presentation/ui/components/cards/card-report/card-report-sequence-list/card-report-sequence-list.scss';

export interface CardReportSequenceListProps {
	cardId: string,
	sequence: SequenceViewModel,
	report: ReportViewModel,
	sequenceStartDate: Date,
	sequenceEndDate: Date,
	onSequenceStartDateChange: (uuid: string, date: Date) => void,
	onSequenceEndDateChange: (uuid: string, date: Date) => void,
	isSequenceSelected: boolean
}

export const CardReportSequenceList = (props: CardReportSequenceListProps): JSX.Element => {
	const {
		sequence,
		sequenceStartDate,
		sequenceEndDate,
		onSequenceStartDateChange,
		onSequenceEndDateChange,
		isSequenceSelected
	} = props;

	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);

	const isArchived = sequence.State === SequenceModelState.ARCHIVED ? 'card-report-sequence-list--is-archived' : '';

	return (
		<div className={`card-report-sequence-list ${isArchived}`}>
			<CardReportSequenceItemHeader
				title={sequence.Name}
				subtitle={l10nContext.translate(getSequenceTypeDefinition(sequence.Type).getLabelKey())}
				archived={sequence.State === SequenceModelState.ARCHIVED}
			/>
			<div className="card-report-sequence-list__content">

				<Icon name={IconIdentifier.CALENDER} />
				<div className="card-report-sequence-list__content__input">
					<label className="card-report-sequence-list__content__input__label">
						<span>Zeitraum anzeigen zwischen: </span>
						<DatePicker
							name={`${sequence.Uuid}_start-date`}
							defaultValue={sequenceStartDate}
							required={false}
							onChange={(event) => onSequenceStartDateChange(sequence.Uuid, event.target.valueAsDate)}
							disabled={!isSequenceSelected || !authContext.hasPermission(Permission.REPORT_UPDATE)}
						/>
					</label>
				</div>

				<div className="card-report-sequence-list__content__input">
					<label className="card-report-sequence-list__content__input__label">
						<span>bis</span>
						<DatePicker
							name={`${sequence.Uuid}_end-date`}
							defaultValue={sequenceEndDate}
							required={false}
							onChange={(event) => onSequenceEndDateChange(sequence.Uuid, event.target.valueAsDate)}
							disabled={!isSequenceSelected || !authContext.hasPermission(Permission.REPORT_UPDATE)}
						/>
					</label>
				</div>
			</div>

		</div>
	);
};
