import { useContext, useRef } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { FolderViewModel } from 'services/documents/domain/model/FolderModel';
import { FolderAddForm } from 'services/documents/presentation/ui/folder-action/folder-add-form/FolderAddForm';
import { FolderAddFailedNotification } from 'services/documents/presentation/ui/folder-action/folder-add-modal/FolderAddFailedNotification';
import { FolderAddSuccessNotification } from 'services/documents/presentation/ui/folder-action/folder-add-modal/FolderAddSuccessNotification';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface FolderAddModalProps {
	onDismiss?: () => void;
	parentFolder: FolderViewModel;
}

export const FolderAddModal = (props: FolderAddModalProps): JSX.Element => {
	const { onDismiss, parentFolder } = props;

	const l10nContext = useContext(L10nContext);

	const newFolder = useRef<string>('');

	const folderActionStatus = useTypedSelector(state => state.folders.actionStatus);

	const setNewFolder = (folderName: string) => {
		newFolder.current = folderName;
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (folderActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent = <FolderAddForm
					onChange={setNewFolder}
					parentFolder={parentFolder}
				/>;
				break;

			case AsyncReducerStatus.CREATED:
				modalContent = <FolderAddSuccessNotification
					folder={newFolder.current}
					parentFolder={parentFolder}
					onClickConfirm={onDismiss}

				/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <FolderAddFailedNotification onDismiss={onDismiss} />;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.documents.modal.action.add.caption', 'Ordner hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
