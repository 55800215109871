import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from '../../../../lib/domain/model/Model';
import { ModelConverter } from '../../../../lib/domain/model/ModelConverter';
import { GeneratorModelState } from '../../../nuclide/domain/model/GeneratorModelState';
import { GeneratorTypeIsotope } from '../../../nuclide/domain/model/isotopes/GeneratorTypeIsotope';
import { NuclideModelActivityUnit } from '../../../nuclide/domain/model/NuclideModelActivityUnit';

export interface WasteManagementReportGeneratorPersistenceModel extends PersistenceModel {
	Client: string;
	Facility: string;
	Name: string;
	State: string;
	Isotope: string;
	Manufacturer: string;
	BatchNumber: string;
	SerialNumber: string;
	DeliveryDate: number;
	CalibrationDatetime: number;
	NominalActivity: number;
	ActivityUnit: string;
	DispatchDatetime: number;
	DispatchedActivity: number;
	Uuid: string;
}

export interface WasteManagementReportGeneratorStoreModel extends StoreModel {
	Client: string;
	Facility: string;
	Name: string;
	State: string;
	Isotope: string;
	Manufacturer: string;
	BatchNumber: string;
	SerialNumber: string;
	DeliveryDate: number;
	CalibrationDatetime: number;
	NominalActivity: number;
	ActivityUnit: string;
	DispatchDatetime: number;
	DispatchedActivity: number;
	Uuid: string;
}

export interface WasteManagementReportGeneratorViewModel extends ViewModel {
	Client: string;
	Facility: string;
	Name: string;
	State: GeneratorModelState;
	Isotope: GeneratorTypeIsotope;
	Manufacturer: string;
	BatchNumber: string;
	SerialNumber: string;
	DeliveryDate: Date;
	CalibrationDatetime: Date;
	NominalActivity: number;
	ActivityUnit: NuclideModelActivityUnit;
	DispatchDatetime: Date;
	DispatchedActivity: number;
	Uuid: string;
}

export interface CreatableWasteManagementReportGeneratorViewModel extends CreatableViewModel {
	Client: string;
	Facility: string;
	Name: string;
	State: GeneratorModelState;
	Isotope: GeneratorTypeIsotope;
	Manufacturer: string;
	BatchNumber: string;
	SerialNumber: string;
	DeliveryDate: Date;
	CalibrationDatetime: Date;
	NominalActivity: number;
	ActivityUnit: NuclideModelActivityUnit;
	DispatchDatetime: Date;
	DispatchedActivity: number;
	Uuid: string;
}

export class WasteManagementReportGeneratorModelConverter
	extends ModelConverter<WasteManagementReportGeneratorPersistenceModel,
	WasteManagementReportGeneratorStoreModel,
	WasteManagementReportGeneratorViewModel,
	CreatableWasteManagementReportGeneratorViewModel> {

	fromStoreModel(storeModel?: WasteManagementReportGeneratorStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			model.DeliveryDate = Math.floor(sModel.DeliveryDate / 1000);
			model.CalibrationDatetime = Math.floor(sModel.CalibrationDatetime / 1000);
			if (Object.prototype.hasOwnProperty.call(sModel, 'DispatchDatetime') && sModel.DispatchDatetime !== null) {
				model.DispatchDatetime = Math.floor(sModel.DispatchDatetime / 1000);
			}
		});
	}

	fromViewModel(viewModel?: WasteManagementReportGeneratorViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.DeliveryDate = Math.floor(vModel.DeliveryDate.getTime() / 1000);
			model.CalibrationDatetime = Math.floor(vModel.CalibrationDatetime.getTime() / 1000);
			if (Object.prototype.hasOwnProperty.call(vModel, 'DispatchDatetime') && vModel.DispatchDatetime !== null) {
				model.DispatchDatetime = Math.floor(vModel.DispatchDatetime.getTime() / 1000);
			}
			model.State = vModel?.State as string ?? null;
			model.Isotope = vModel?.Isotope as string ?? null;
			model.ActivityUnit = vModel?.ActivityUnit as string ?? null;
		});
	}

	toStoreModel(): WasteManagementReportGeneratorStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.DeliveryDate = model.DeliveryDate * 1000;
			sModel.CalibrationDatetime = model.CalibrationDatetime * 1000;
			if (Object.prototype.hasOwnProperty.call(model, 'DispatchDatetime') && model.DispatchDatetime !== null) {
				sModel.DispatchDatetime = model.DispatchDatetime * 1000;
			}
		});
	}

	toViewModel(): WasteManagementReportGeneratorViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.DeliveryDate = new Date(model.DeliveryDate * 1000);
			vModel.CalibrationDatetime = new Date(model.CalibrationDatetime * 1000);
			if (Object.prototype.hasOwnProperty.call(model, 'DispatchDatetime') && model.DispatchDatetime !== null) {
				vModel.DispatchDatetime = new Date(model.DispatchDatetime * 1000);
			}
			vModel.State = model?.State as GeneratorModelState ?? null;
			vModel.ActivityUnit = model?.ActivityUnit as NuclideModelActivityUnit ?? null;
		});
	}
}
