import { ComponentPropsWithoutRef } from 'react';

import './card-collection-layout.scss';

export type CardCollectionLayoutProps = ComponentPropsWithoutRef<'div'>;

export const CardCollectionLayout = (props: CardCollectionLayoutProps): JSX.Element => {

	return (
		<div className='card-collection-layout'>
			{props.children}
		</div>
	);

};
