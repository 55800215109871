import { DeviceType } from '../../inventory/DeviceType';
import { SequenceType } from '../../inventory/SequenceType';
import { SequenceStoreData, SequenceViewData } from '../sequence/SequenceData';
import { AbstractFieldSet } from './AbstractFieldSet';

export class SequenceFieldSet<Fields> extends AbstractFieldSet<Fields> {
	public constructor(fieldsFactory: () => Fields) {
		super(fieldsFactory, null);
	}

	public toStoreData(strict: boolean): SequenceStoreData {
		return super.toStoreData(strict) as SequenceStoreData;
	}

	public toViewData(deviceType: DeviceType, sequenceType: SequenceType, strict: boolean): SequenceViewData {
		return super.toViewData(deviceType, sequenceType, strict) as SequenceViewData;
	}

	protected createClone(fieldsFactory: () => Fields): this {
		return new SequenceFieldSet(fieldsFactory) as this;
	}
}
