import {
	BooleanInput, CommentInput,
	DateTimeInput,
	InputSelection,
	IntervalInput,
	MailAddressInput,
	NumberInput,
	StringInput,
	TextInput,
	UnitInput,
	UrlInput, ValuedStringInput,
} from 'services/device/domain/business/common/Input';
import { BooleanInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/BooleanInputRenderer';
import { CommentInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/CommentInputRenderer';
import { DateTimeInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/DateTimeInputRenderer';
import { IntervalInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/IntervalInputRenderer';
import { MailAddressesInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/MailAddressesInputRenderer';
import { NumberInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/NumberInputRenderer';
import { StringInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/StringInputRenderer';
import { TextInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/TextInputRenderer';
import { UnitInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/UnitInputRenderer';
import { UrlInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/UrlInputRenderer';
import { ValuedStringInputRenderer } from 'services/device/presentation/ui/widget/input-renderer/ValuedStringInputRenderer';
import { Sequence } from '../../../../domain/business/common/sequence/Sequence';

export class InputWidgetSelection implements InputSelection<void> {

	private readonly onElement: (element: JSX.Element) => void;
	private readonly sequence?: Sequence;

	public constructor(onElement: (element: JSX.Element) => void, sequence?: Sequence) {
		this.onElement = onElement;
		this.sequence = sequence;
	}

	onUnitInput(input: UnitInput): void {
		this.onElement(
			<UnitInputRenderer key={input.getName()} input={input} />
		);
	}

	onDateTimeInput(input: DateTimeInput): void {
		this.onElement(
			<DateTimeInputRenderer key={input.getName()} input={input} />
		);
	}

	onStringInput(input: StringInput): void {
		this.onElement(
			<StringInputRenderer key={input.getName()} input={input} />
		);
	}

	onNumberInput(input: NumberInput): void {
		this.onElement(
			<NumberInputRenderer key={input.getName()} input={input} />
		);
	}

	onBooleanInput(input: BooleanInput): void {
		this.onElement(
			<BooleanInputRenderer key={input.getName()} input={input} />
		);
	}

	onMailAddressInput(input: MailAddressInput): void {
		this.onElement(
			<MailAddressesInputRenderer key={input.getName()} input={input} />
		);
	}

	onIntervalInput(input: IntervalInput): void {
		this.onElement(
			<IntervalInputRenderer key={input.getName()} input={input} />
		);
	}

	onTextInput(input: TextInput): void {
		this.onElement(
			<TextInputRenderer key={input.getName()} input={input} />
		);
	}

	onUrlInput(input: UrlInput): void {
		this.onElement(
			<UrlInputRenderer key={input.getName()} input={input} />
		);
	}

	onCommentInput(input: CommentInput): void {
		this.onElement(
			<CommentInputRenderer key={input.getName()} input={input} />
		);
	}

	onValuedStringInput(input: ValuedStringInput): void {
		this.onElement(
			<ValuedStringInputRenderer key={input.getName()} input={input} sequence={this.sequence} />
		);
	}

}
