import { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';
import { RecordDocumentsModalContent } from 'services/device/presentation/ui/record-action/record-documents-modal/RecordDocumentsModalContent';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { RecordCreateFailedNotification } from 'services/device/presentation/ui/record-action/record-create-modal/RecordCreateFailedNotification';
import { RecordCreateSuccessNotification } from 'services/device/presentation/ui/record-action/record-create-modal/RecordCreateSuccessNotification';
import { RecordCreateForm } from 'services/device/presentation/ui/record-action/record-create-form/RecordCreateForm';
import { selectCreatedRecord, fetchRecordsBySequence, resetActionStatus } from 'services/device/store/recordSlice';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { AsyncFetchStatus } from '../../../../../../store/common/AsyncFetchStatus';
import { ClientContext } from '../../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../../core/context/FacilityContext';
import { RecordCreateModalLocation } from './RecordCreateModalLocation';

interface RecordCreateModalProps {
	/**
	 * Sequence
	 */
	sequence: SequenceViewModel;
	/**
	 * Location of calling record create modal
	 */
	location: RecordCreateModalLocation;
	/**
	 * Handle click dismiss
	 */
	onDismiss: () => void;
}

/**
 * The record create modal
 */
export const RecordCreateModal = (props: RecordCreateModalProps): JSX.Element => {
	const { location, sequence, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Use a custom selector function to provide type information for the state defined in the slice
	const recordsStoreFetchStatus = useTypedSelector(state => state.records.fetchStatus);
	const recordActionStatus = useTypedSelector(state => state.records.actionStatus);

	// Read records from the server
	useEffect(() => {
		if (recordsStoreFetchStatus === AsyncFetchStatus.INITIAL || recordsStoreFetchStatus === AsyncFetchStatus.IDLE) {
			dispatch(fetchRecordsBySequence({
				clientUuid: clientContext.selectedClientUuid,
				facilityUuid: facilityContext.selectedFacilityUuid,
				sequenceUuid: props.sequence.Uuid
			}));
		}
	});

	// Use state to hold error objects
	const [caughtError, setCaughtError] = useState<Error>(null);
	const [showDocument, setShowDocument] = useState<boolean>(false);
	const [showAddRecord, setShowAddRecord] = useState<boolean>(false);

	// Use selector to provide information from store
	const createdRecord = useSelector(selectCreatedRecord(sequence));

	useEffect(() => {
		if (recordActionStatus === AsyncReducerStatus.CREATED && showAddRecord) {
			dispatch(resetActionStatus());
		}
	}, [dispatch, recordActionStatus, showAddRecord]);

	const renderModal = () => {
		if (caughtError !== null) {
			return (
				<RecordCreateFailedNotification
					onClickConfirm={onDismiss}
				/>
			);
		}

		if (showDocument && createdRecord !== null) {
			return (
				<RecordDocumentsModalContent record={createdRecord} />
			);
		}

		let modal: JSX.Element = null;

		switch (recordActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <RecordCreateForm
					sequence={sequence}
					setShowAddRecord={setShowAddRecord}
					showAddRecord={showAddRecord}
					onError={(error) => {
						setCaughtError(error);
					}}
				/>;
				break;

			case AsyncReducerStatus.CREATED:
				modal =
					<RecordCreateSuccessNotification
						recordCreated={createdRecord}
						location={location}
						sequence={sequence}
						onDismiss={onDismiss}
						showAddRecord={showAddRecord}
						onClickAddDocuments={(): void => {
							setShowDocument(true);
						}}
						onClickAddAnotherRecord={(): void => {
							setShowAddRecord(true);
						}}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<RecordCreateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.device.modal.action.createRecord.caption', 'Messwert hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
