import { BooleanOutput } from 'services/device/domain/business/common/Output';
import { LabelRenderer } from 'services/device/presentation/ui/widget/label-renderer/LabelRenderer';
import { BooleanOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/BooleanOutputValueRenderer';

export interface BooleanOutputProps {
	output: BooleanOutput;
}

export const BooleanOutputRenderer = (props: BooleanOutputProps): JSX.Element => {

	const { output } = props;

	return (
		<li key={output.getName()}>
			<strong>
				<LabelRenderer output={output} />
			</strong>
			<p>
				<BooleanOutputValueRenderer output={output} />
			</p>
		</li>
	);

};
