import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NetworkContext } from 'context/NetworkContext';

import { AuthContext } from 'services/core/context/AuthContext';
import { ActorModel, JsonWebToken, Permission } from 'services/core/lib/auth/AuthService';
import { AuthServiceBuilder } from 'services/core/lib/auth/AuthServiceBuilder';
import { AuthErrorBoundary } from 'services/core/presentation/ui/auth-error-boundary/AuthErrorBoundray';
import { LoginView } from 'services/core/presentation/view/LoginView';
import { resetState as resetDeviceState } from 'services/device/store/devicesSlice';
import { resetState as resetRecordState } from 'services/device/store/recordSlice';
import { resetState as resetSequenceState } from 'services/device/store/sequenceSlice';
import { resetState as resetDocumentState } from 'services/documents/store/documentSlice';
import { resetState as resetFolderState } from 'services/documents/store/folderSlice';
import { resetState as resetMaintenanceLogEntryState } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { resetState as resetRoleState } from 'services/core/store/roleSlice';
import { resetState as resetMembershipState } from 'services/core/store/membershipSlice';
import { resetState as resetReportState } from 'services/report/store/reportSlice';
import { resetState as resetGeneratorState } from 'services/nuclide/store/generatorSlice';
import { resetState as resetEluateState } from 'services/nuclide/store/eluateSlice';
import { resetState as resetCyclotronProductState } from 'services/nuclide/store/cyclotronProductSlice';

export const AuthContextProvider = (props: any): JSX.Element => {

	const networkContext = useContext(NetworkContext);
	const authService = AuthServiceBuilder.build(networkContext.online);

	const dispatch = useDispatch();

	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const [authed, setAuthed] = useState<boolean>(authService.hasActor());

	const latestActorUser = (): string | null => {
		return authService.getLatestActorUser();
	};

	const latestActorUsers = (): Array<string> => {
		return authService.getLatestActorUsers();
	};

	const hasActor = (): boolean => {
		return authService.hasActor();
	};

	const hasPermission = (permission: Permission): boolean => {
		return authService.hasPermission(permission);
	};

	const hasAnyPermission = (permissions: Array<Permission>): boolean => {
		return authService.hasAnyPermission(permissions);
	};

	const getActor = (): ActorModel | null => {
		return authService.getActor();
	};

	const jsonWebToken = (): JsonWebToken | null => {
		return authService.getJsonWebToken();
	};

	const authenticate = async (user: string, password: string): Promise<boolean> => {
		dispatch(resetDeviceState());
		dispatch(resetSequenceState());
		dispatch(resetRecordState());
		dispatch(resetMaintenanceLogEntryState());
		dispatch(resetDocumentState());
		dispatch(resetFolderState());
		dispatch(resetRoleState());
		dispatch(resetMembershipState());
		dispatch(resetReportState());
		dispatch(resetGeneratorState());
		dispatch(resetEluateState());
		dispatch(resetCyclotronProductState());
		const actorModel = await authService.authenticate(user, password);
		setAuthed(actorModel !== null);

		return actorModel !== null;
	};

	const invalidate = async (): Promise<void> => {
		dispatch(resetDeviceState());
		dispatch(resetSequenceState());
		dispatch(resetRecordState());
		dispatch(resetMaintenanceLogEntryState());
		dispatch(resetDocumentState());
		dispatch(resetFolderState());
		dispatch(resetRoleState());
		dispatch(resetMembershipState());
		dispatch(resetReportState());
		dispatch(resetGeneratorState());
		dispatch(resetEluateState());
		dispatch(resetCyclotronProductState());
		try {
			await authService.unauthenticate();
		} catch (e) {
			console.warn(e);
		}
		setAuthed(false);
	};

	const renderLoginInterceptionView = (): JSX.Element => {
		return (
			<LoginView />
		);
	};

	const renderChildren = (): JSX.Element => {
		return (
			<AuthErrorBoundary>
				{props.children}
			</AuthErrorBoundary>
		);
	};

	return (
		<AuthContext.Provider
			value={{
				latestActorUser,
				latestActorUsers,
				hasActor,
				hasPermission,
				hasAnyPermission,
				getActor,
				authenticate,
				invalidate,
				jsonWebToken
			}}
		>
			{hasActor() ? renderChildren() : renderLoginInterceptionView()}
		</AuthContext.Provider>
	);

};
