import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { CyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';
import { ClientContext } from '../../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../../core/context/FacilityContext';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { deleteContainer, updateContainer } from '../../../../store/containerSlice';
import { CyclotronProductToSingleNuclidesForm } from '../cyclotron-product-to-single-nuclides-form/CyclotronProductToSingleNuclidesForm';
import { CyclotronProductToSingleNuclidesFailedNotification } from './CyclotronProductToSingleNuclidesFailedNotification';

export interface CyclotronProductToSingleNuclidesModalProps {
	container: ContainerViewModel,
	cyclotronProduct: CyclotronProductViewModel,
	onDismiss?: () => void;
}

export const CyclotronProductToSingleNuclidesModal = (props: CyclotronProductToSingleNuclidesModalProps): JSX.Element => {
	const { container, cyclotronProduct, onDismiss } = props;

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const containerActionStatus = useTypedSelector(state => state.containers.actionStatus);

	const dispatch = useDispatch();

	useEffect(() => {
		if (containerActionStatus === AsyncReducerStatus.UPDATED) {
			onDismiss();
		}
	}, [containerActionStatus, onDismiss]);

	const handleCyclotronProductToSingleNuclide = (): void => {

		const filteredCyclotronProducts = container.CyclotroneProductIds.filter(item => item !== cyclotronProduct.Uuid);
		container.CyclotroneProductIds = filteredCyclotronProducts;
		container.Client = clientContext.selectedClientUuid;
		container.Facility = facilityContext.selectedFacilityUuid;

		if (filteredCyclotronProducts.length + container.GeneratorEluatIds?.length > 0) {
			dispatch(updateContainer(container));
		}
		if  (filteredCyclotronProducts.length + container.GeneratorEluatIds?.length === 0) {
			dispatch(deleteContainer(container));
		}
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (containerActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<CyclotronProductToSingleNuclidesForm
						cyclotronProduct={cyclotronProduct}
						onToSingleNuclides={handleCyclotronProductToSingleNuclide}
						onDismiss={onDismiss}
						container={container}
						isLastItem={(container.CyclotroneProductIds?.length + container.GeneratorEluatIds?.length) === 1}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <CyclotronProductToSingleNuclidesFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
