import { HttpApiConnector } from 'services/core/lib/auth/api-connector/HttpApiConnector';
import { JsonWebToken } from 'services/core/lib/auth/AuthService';

export class FileDownloadToken {

	private fileDownloadToken: string | null = null;

	public getFileDownloadToken(): string | null {
		return this.fileDownloadToken;
	}

	private setFileDownloadToken(value: string | null) {
		this.fileDownloadToken = value;
	}

	public async requestFileDownloadToken(jsonWebToken: JsonWebToken): Promise<string> {
		try {
			const httpApiConnector = new HttpApiConnector();
			const fileDownloadToken = await httpApiConnector.fileDownloadToken(jsonWebToken);
			this.setFileDownloadToken(fileDownloadToken);
		} catch (e) {
			this.setFileDownloadToken(null);
			throw e;
		}
		return this.getFileDownloadToken();
	}

}
