import React, { useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { L10n } from 'lib/l10n/L10n';
import { Locale } from 'lib/l10n/Locale';

import germanLiterals from 'locales/de/translation.json';
import germanBusinessLiterals from 'locales/de/business.json';
import englishLiterals from 'locales/en/translation.json';
import englishBusinessLiterals from 'locales/en/business.json';

export const L10nContextProvider = (props: any): JSX.Element => {

	// Prepare i18n properties
	if (L10n.selectedLocale() === null) {
		L10n.addLocale(new Locale('de', [germanLiterals, germanBusinessLiterals]));
		L10n.addLocale(new Locale('en', [englishLiterals, englishBusinessLiterals]));
		L10n.detectLocale();
	}

	const [locale, setLocale] = useState<string>(L10n.selectedLocale());

	const translate = (literal: string, defaultValue: string = null): string => {
		return L10n.translate(literal, defaultValue);
	};

	const formatDate = (date?: Date, defaultLocale: string = null, defaultValue?: string): string => {
		return L10n.formatDate(date, defaultLocale, defaultValue);
	};

	const formatTime = (date: Date, defaultLocale: string = null, defaultValue?: string): string => {
		return L10n.formatTime(date, defaultLocale, defaultValue);
	};

	const formatDateTime = (date: Date, defaultLocale: string = null, defaultValue?: string): string => {
		return L10n.formatDateTime(date, defaultLocale, defaultValue);
	};

	const formatNumber = (number?: number, decimals = 2, defaultLocale?: string, defaultValue = ''): string => {
		return L10n.formatNumber(number, decimals, defaultLocale, defaultValue);
	};

	const formatExponentialNumber = (number?: number): string => {
		return L10n.formatExponentialNumber(number);
	};

	const formatBoolean = (boolean?: boolean): string => {
		return boolean === true ? translate('common.inputs.boolean.true', 'true') : translate('common.inputs.boolean.false', 'false');
	};

	const changeLanguage = (language: string): void => {
		if (language.toLowerCase() === L10n.selectedLocale()) {
			return;
		}
		L10n.selectLocale(language);
		setLocale(L10n.selectedLocale());
	};

	const provider = {
		language: locale,
		translate,
		formatDate,
		formatTime,
		formatDateTime,
		formatNumber,
		formatExponentialNumber,
		formatBoolean,
		changeLanguage
	};

	return (
		<L10nContext.Provider value={provider}>
			<>
				{props.children}
			</>
		</L10nContext.Provider>
	);

};
