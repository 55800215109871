import { L10nContext } from 'context/L10nContext';

import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Tag, TagType } from 'presentation/ui/partials/tag/Tag';
import { useContext } from 'react';
import { CyclotronProductModelState } from 'services/nuclide/domain/model/CyclotronProductModelState';

import './card-cyclotron-product-list-item-header.scss';

export interface CardCyclotronProductListItemHeaderProps {
	/**
	 * Title
	 */
	title: string;
	/**
	 * (Optional) Sub-Title
	 */
	subtitle?: string;
	/**
	 * (Optional) Archived tag
	 */
	state?: CyclotronProductModelState;
}

/**
 * A card sequence list item header component
 */
export const CardCyclotronProductListItemHeader = (props: CardCyclotronProductListItemHeaderProps): JSX.Element => {
	const { title, subtitle, state } = props;

	const l10nContext = useContext(L10nContext);

	const subTitle = subtitle ?
		<p className="card-cyclotron-product-list-item-header__subtitle">
			{subtitle}
		</p> :
		null;

	const tagElement = (): JSX.Element => {
		const tagLabelCompletelyApplied = l10nContext.translate('common.tags.completelyApplied', 'Vollständig appliziert');
		const tagLabelInWastemodule = l10nContext.translate('common.tags.inWastemodule', 'Im Abfallmodul');

		if (state === CyclotronProductModelState.COMPLETELY_APPLIED) {
			return (
				<Tag
					label={tagLabelCompletelyApplied}
					type={TagType.INFO}
					icon={IconIdentifier.FULLY_APPLIED}
				/>
			);
		}

		if (state === CyclotronProductModelState.IN_WASTE_MANAGEMENT) {
			return (
				<Tag
					label={tagLabelInWastemodule}
					type={TagType.INFO}
					icon={IconIdentifier.IN_WASTE}
				/>
			);
		}

		return null;
	};

	return (
		<div className="card-cyclotron-product-list-item-header">
			<div className="card-ccyclotron-product-list-item-header__content">
				<span className="card-cyclotron-product-list-item-header__title-wrapper">
					<h1 className="card-cyclotron-product-list-item-header__title">
						{title}
					</h1>
				</span>

				{subTitle}
			</div>

			{tagElement()}
		</div>
	);
};
