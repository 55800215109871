import { DateTimeOutput } from 'services/device/domain/business/common/Output';
import { LabelRenderer } from 'services/device/presentation/ui/widget/label-renderer/LabelRenderer';

import { DateTimeOutputValueRenderer } from 'services/device/presentation/ui/widget/output-value-renderer/DateTimeOutputValueRenderer';

export interface DateTimeOutputProps {
	output: DateTimeOutput;
}

export const DateTimeOutputRenderer = (props: DateTimeOutputProps): JSX.Element => {
	const { output } = props;

	return (
		<li key={output.getName()}>
			<strong>
				<LabelRenderer output={output} />
			</strong>
			<p>
				<DateTimeOutputValueRenderer output={output} />
			</p>
		</li>
	);

};
