import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AuthContext } from 'services/core/context/AuthContext';
import { ForgotPasswordLink } from 'services/core/presentation/ui/login/ForgotPasswordLink/ForgotPasswordLink';
import { SimpleViewTitle } from 'services/core/presentation/ui/simple-view-title/SimpleViewTitle';

import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';

import './login.scss';

export const Login = (): JSX.Element => {

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	const initialUsername = authContext.latestActorUser() ?? '';
	const latestUsernames = authContext.latestActorUsers() ?? [];

	const [username, setUsername] = useState<string>(initialUsername);
	const [password, setPassword] = useState<string>('');
	const [notification, setNotification] = useState<string>('');

	const handleAuthentication = async (): Promise<void> => {

		const loginSuccess = await authContext.authenticate(username, password);
		if (!loginSuccess) {
			setNotification(l10nContext.translate('view.login.notificationError', 'Bitte überprüfen Sie Ihre Login-Daten'));
		}
	};

	const renderLatestUsersDatalist = (): JSX.Element => {
		const options = latestUsernames.map((latestUsername): JSX.Element => {
			return (<option value={latestUsername} key={latestUsername} />);
		});
		return (
			<datalist id="latestUsers">
				{options}
			</datalist>
		);
	};

	const notificationBar = notification ?
		<NotificationBar
			message={notification}
			level={NotificationLevel.LEVEL_ERROR}
		/> :
		null;

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_VERTICAL}
			onSubmit={handleAuthentication}
		>
			<FormLayoutSection>
				<NotificationBars>
					{notificationBar}
				</NotificationBars>
				<SimpleViewTitle
					label={l10nContext.translate('view.login.title', 'Einloggen')}
				/>
				<Label label={l10nContext.translate('view.login.username', 'Benutzername')}>
					<InputText
						type="text"
						value={username}
						list="latestUsers"
						onChange={(event) => setUsername(event.target.value)}
						required
						id="email"
					/>
					{renderLatestUsersDatalist()}
				</Label>
			</FormLayoutSection>
			<FormLayoutSection>
				<Label label={l10nContext.translate('view.login.password', 'Passwort')}>
					<InputText
						type="password"
						value={password}
						onChange={(event) => setPassword(event.target.value)}
						required
						id={password}
					/>

				</Label>
				<ForgotPasswordLink
					label={l10nContext.translate('view.login.forgotPassword', 'Passwort vergessen')}
					link="https://app.rapp-smartarchivo.de/core/app/account/recover/"
					
				/>
			</FormLayoutSection>
			<FormLayoutSection>
				<ButtonPrimary
					buttonText={l10nContext.translate('view.login.title', 'Einloggen')}
					type="submit"
					name="login-button"
				/>
			</FormLayoutSection>
		</FormLayout>
	);

};
