import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { selectCreatedDevice } from 'services/device/store/devicesSlice';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { ClearanceCreateForm } from 'services/device/presentation/ui/clearance-action/clearance-create-form/ClearanceCreateForm';
import { ClearanceCreateSuccessNotification } from 'services/device/presentation/ui/clearance-action/clearance-create-modal/ClearanceCreateSuccessNotification';
import { ClearanceCreateFailedNotification } from 'services/device/presentation/ui/clearance-action/clearance-create-modal/ClearanceCreateFailedNotification';

interface ClearanceCreateModalProps {
	/**
	 * onDismiss
	 */
	onDismiss: () => void;
}

/**
 * The clearance create modal
 */
export const ClearanceCreateModal = (props: ClearanceCreateModalProps): JSX.Element => {
	const { onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use selector to provide information from store
	const createdDevice = useSelector(selectCreatedDevice());
	const deviceActionStatus = useTypedSelector(state => state.devices.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (deviceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <ClearanceCreateForm />;
				break;

			case AsyncReducerStatus.CREATED:
				modal =
					<ClearanceCreateSuccessNotification
						onClickConfirm={onDismiss}
						deviceCreated={createdDevice}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<ClearanceCreateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.clearances.modal.create.title', 'Freimessung hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
