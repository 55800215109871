import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';

import {
	EluateModelConverter as ModelConverter,
	EluatePersistenceModel as PersistenceModel,
	EluateStoreModel as StoreModel,
	EluateViewModel as ViewModel,
	CreatableEluateViewModel as CreatableViewModel, CreatableEluateViewModel, EluateStoreModel
} from 'services/nuclide/domain/model/EluateModel';
import { EluateHttpPersistence as HttpPersistence } from 'services/nuclide/persistence/EluateHttpPersistence';

export class EluatePersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {
	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

	protected async preCreate(creatableViewModel: Readonly<CreatableEluateViewModel>, storeModel: EluateStoreModel, local: boolean): Promise<void> {
		const timeframeLowerLimit = new Date();
		timeframeLowerLimit.setSeconds(timeframeLowerLimit.getSeconds() - 300);
		const timeframeUpperLimit = new Date();
		timeframeUpperLimit.setSeconds(timeframeUpperLimit.getSeconds() + 300);

		if (
			creatableViewModel.ExtractionDatetime.getTime() < timeframeLowerLimit.getTime()
			|| creatableViewModel.ExtractionDatetime.getTime() > timeframeUpperLimit.getTime()
		) {
			storeModel.Supplemented = true;
		}
	}

}
