import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';
import { GeneratorModelState } from './GeneratorModelState';
import { GeneratorTypeIsotope } from './isotopes/GeneratorTypeIsotope';
import { NuclideModelActivityUnit } from './NuclideModelActivityUnit';

export interface GeneratorPersistenceModel extends PersistenceModel {
	Client: string,
	Facility: string,
	Name: string;
	Isotope: string;
	State: string;
	Manufacturer: string;
	BatchNumber: string;
	SerialNumber: string;
	DeliveryDate: number;
	CalibrationDatetime: number;
	ActivityUnit: string;
	NominalActivity: number;
	AcceptedBy: string;
	AcceptedByName: string;
	CurrentActivity: number;
	DispatchDatetime?: number;
	DispatchedBy?: string;
	DispatchedByName?: string;
	DispatchedActivity?: number;
	DispatchedSurfaceContamination?: number;
	DispatchedDoseRate?: number;
}

export interface GeneratorStoreModel extends StoreModel {
	Client: string,
	Facility: string,
	Name: string;
	Isotope: string;
	State: string;
	Manufacturer: string;
	BatchNumber: string;
	SerialNumber: string;
	DeliveryDate: number;
	CalibrationDatetime: number;
	ActivityUnit: string;
	NominalActivity: number;
	AcceptedBy: string;
	AcceptedByName: string;
	CurrentActivity: number;
	DispatchDatetime?: number;
	DispatchedBy?: string;
	DispatchedByName?: string;
	DispatchedActivity?: number;
	DispatchedSurfaceContamination?: number;
	DispatchedDoseRate?: number;
}

export interface GeneratorViewModel extends ViewModel {
	Client: string;
	Facility: string;
	Name: string;
	Isotope: GeneratorTypeIsotope;
	State: GeneratorModelState;
	Manufacturer: string;
	BatchNumber: string;
	SerialNumber: string;
	DeliveryDate: Date;
	CalibrationDatetime: Date;
	ActivityUnit: NuclideModelActivityUnit;
	NominalActivity: number;
	AcceptedBy: string;
	AcceptedByName: string;
	CurrentActivity: number;
	DispatchDatetime?: Date;
	DispatchedBy?: string;
	DispatchedByName?: string;
	DispatchedActivity?: number;
	DispatchedSurfaceContamination?: number;
	DispatchedDoseRate?: number;
}

export interface CreatableGeneratorViewModel extends CreatableViewModel {
	Client: string,
	Facility: string,
	Name: string;
	Isotope: GeneratorTypeIsotope;
	Manufacturer: string;
	BatchNumber: string;
	SerialNumber: string;
	DeliveryDate: Date;
	CalibrationDatetime: Date;
	ActivityUnit: NuclideModelActivityUnit;
	NominalActivity: number;
	AcceptedBy: string;
}

export class GeneratorModelConverter
	extends ModelConverter<GeneratorPersistenceModel, GeneratorStoreModel, GeneratorViewModel, CreatableGeneratorViewModel> {

	fromStoreModel(storeModel?: GeneratorStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			model.DeliveryDate = Math.floor(sModel.DeliveryDate / 1000);
			if (Object.prototype.hasOwnProperty.call(sModel, 'DispatchDatetime') && sModel.DispatchDatetime !== null) {
				model.DispatchDatetime = Math.floor(sModel.DispatchDatetime / 1000);
			}
			model.CalibrationDatetime = Math.floor(sModel.CalibrationDatetime / 1000);
		});
	}

	fromViewModel(viewModel?: GeneratorViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.DeliveryDate = Math.floor(vModel.DeliveryDate.getTime() / 1000);
			if (Object.prototype.hasOwnProperty.call(vModel, 'DispatchDatetime') && vModel.DispatchDatetime !== null) {
				model.DispatchDatetime = Math.floor(vModel.DispatchDatetime.getTime() / 1000);
			}
			model.CalibrationDatetime = Math.floor(vModel.CalibrationDatetime.getTime() / 1000);
			model.State = vModel?.State as string ?? null;
			model.ActivityUnit = vModel?.ActivityUnit as string ?? null;
		});
	}

	fromCreatableViewModel(creatableViewModel?: CreatableGeneratorViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			model.State = GeneratorModelState.ACTIVE;
			model.DispatchDatetime = null;
			model.DispatchedBy = null;
			model.DispatchedByName = null;
			model.DispachedActivity = null;
			model.CurrentActivity = null;
			model.AcceptedByName = null;
		});
	}

	toStoreModel(): GeneratorStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.DeliveryDate = model.DeliveryDate * 1000;
			if (Object.prototype.hasOwnProperty.call(model, 'DispatchDatetime') && model.DispatchDatetime !== null) {
				sModel.DispatchDatetime = model.DispatchDatetime * 1000;
			}
			sModel.CalibrationDatetime = model.CalibrationDatetime * 1000;
		});
	}

	toViewModel(): GeneratorViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.DeliveryDate = new Date(model.DeliveryDate * 1000);
			if (Object.prototype.hasOwnProperty.call(model, 'DispatchDatetime') && model.DispatchDatetime !== null) {
				vModel.DispatchDatetime = new Date(model.DispatchDatetime * 1000);
			}
			vModel.CalibrationDatetime = new Date(model.CalibrationDatetime * 1000);
			vModel.State = model?.State as GeneratorModelState ?? null;
			vModel.ActivityUnit = model?.ActivityUnit as NuclideModelActivityUnit ?? null;
		});
	}
}
