import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { updateMaintenanceLogEntry } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { MaintenanceLogCommentFormStep1 } from './MaintenanceLogCommentFormStep1';
import { MaintenanceLogCommentFormStep2 } from './MaintenanceLogCommentFormStep2';

export interface MaintenanceLogModalCommentFormProps {
	maintenanceLogEntry: MaintenanceLogEntryViewModel,
}

export const MaintenanceLogCommentForm = (props: MaintenanceLogModalCommentFormProps): JSX.Element => {
	const { maintenanceLogEntry } = props;

	const [modalStep, setmodalStep] = useState<number>(1);

	const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

	const dispatch = useDispatch();

	const updatedMaintenance: MaintenanceLogEntryViewModel = maintenanceLogEntry;

	const requiredInputs = [''];

	const handleUpdate = (): void => {

		dispatch(updateMaintenanceLogEntry(updatedMaintenance));
	};

	const onChangeMaintenanceLogEntryProp = (prop: string, value: string | Date) => {
		updatedMaintenance[prop] = value;
	};

	const previousStep = () => {
		setmodalStep(modalStep - 1);
		setInvalidInputs([]);
	};

	const nextStep = () => {
		const invalidInputFields = requiredInputs
			.map((requiredInput) => {
				return updatedMaintenance[requiredInput]?.trim() !== '' ?
					null :
					requiredInput;

			}).filter((invalidInput: string | null) => invalidInput !== null);

		invalidInputFields.length ?
			setInvalidInputs(invalidInputFields) :
			setmodalStep(modalStep + 1);

	};

	const formStep = () => {
		let currentStep: JSX.Element = null;

		switch (modalStep) {
			case 1:
				currentStep =
					<MaintenanceLogCommentFormStep1
						maintenanceLogEntry={updatedMaintenance}
						onClickNext={nextStep}
						onChange={onChangeMaintenanceLogEntryProp}
						invalidInputs={invalidInputs}
					/>;
				break;
			case 2:
				currentStep =
					<MaintenanceLogCommentFormStep2
						onClickPrevious={previousStep}
						onClickConfirm={handleUpdate}
						maintenanceLogEntry={updatedMaintenance}
					/>;
				break;
		}

		return currentStep;
	};

	return (
		<>
			{formStep()}
		</>
	);
};
