import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentEntry } from 'services/documents/presentation/ui/document-entry/DocumentEntry';
import { Guid } from 'lib/guid/Guid';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ModalDialogueSectionColumnListWrapper } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section-column-list-wrapper/ModalDialogueSectionColumnListWrapper';

export interface RecordDocumentCollectionProps {
	reportRecordDocuments?: ReadonlyArray<DocumentViewModel>;
	onAddDocument?: () => void;
	isReportView?: boolean;
	onAction: (payload: CardItemControlActionType, document: DocumentViewModel) => void;
}

export const ReportRecordDocumentCollection = (props: RecordDocumentCollectionProps): JSX.Element => {
	const { reportRecordDocuments, isReportView, onAddDocument, onAction } = props;

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	const renderDocumentCards = () => {
		let cards;

		if (reportRecordDocuments.length > 0) {
			cards = reportRecordDocuments.map((document: DocumentViewModel) => (
				<DocumentEntry
					isReportView={isReportView}
					key={Guid.generate()}
					documentEntry={document}
					onActionClick={onAction}
				/>
			));
		} else {
			cards = <CardEmpty message={l10nContext.translate('common.cards.emptyDefault.documents', 'kein Dokument')} />;
		}

		return cards;
	};

	return (
		<ModalDialogueSectionColumnListWrapper>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					{ !isReportView ? <ButtonSecondary
						buttonText={l10nContext.translate('view.documents.collection.documents.addButton', 'Dokument hinzufügen')}
						icon={IconIdentifier.ADD}
						onClick={onAddDocument}
						disabled={!authContext.hasPermission(Permission.RECORD_UPDATE_ADD_DOCUMENT)}
					/> : null }
				</ColumnLayoutSection>
			</ColumnLayout>

			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<CardCollectionLayout>
						{renderDocumentCards()}
					</CardCollectionLayout>
				</ColumnLayoutSection>
			</ColumnLayout>
		</ModalDialogueSectionColumnListWrapper>
	);
};
