import React from 'react';

import illustrationError from 'presentation/ui/components/modal-notification/assets/illustration-error.svg';
import illustrationSuccess from 'presentation/ui/components/modal-notification/assets/illustration-success.svg';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';

import './modal-notification.scss';

export interface ModalNotificationProps {
	caption: string;
	message?: string;
	level: ModalNotificationLevel;
}

export const ModalNotification = (props: ModalNotificationProps): JSX.Element => {

	const { caption, message = null, level } = props;

	const renderIllustration = (): JSX.Element => {
		let illustrationSrc: string;
		switch (level) {
			case ModalNotificationLevel.LEVEL_ERROR:
				illustrationSrc = illustrationError;
				break;
			case ModalNotificationLevel.LEVEL_SUCCESS:
				illustrationSrc = illustrationSuccess;
				break;
			case ModalNotificationLevel.LEVEL_PENDING:
				// SUGGESTION: Add illustration for loading status
				illustrationSrc = illustrationSuccess;
				break;
		}
		return (
			<div className="modal-notification__illustration">
				<img
					src={illustrationSrc}
					height="128"
					width="208"
					alt=""
				/>
			</div>
		);
	};

	return (
		<div className="modal-notification">
			{renderIllustration()}
			<h1 className="modal-notification__caption">{caption}</h1>
			<p className="modal-notification__message">{message}</p>
		</div>
	);

};
