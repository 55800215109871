import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { ReportViewModel } from 'services/report/domain/model/ReportModel';

import { ModalNotification } from 'presentation/ui/components/modal-notification/ModalNotification';
import { ModalNotificationLevel } from 'presentation/ui/components/modal-notification/ModalNotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';


export interface ReportCreateSuccessNotificationProps {
	onClickConfirm: () => void;
	reportCreated?: ReportViewModel | null;
}

export const ReportCreateSuccessNotification = (props: ReportCreateSuccessNotificationProps): JSX.Element => {
	const { onClickConfirm, reportCreated } = props;

	const l10nContext = useContext(L10nContext);
	const history = useHistory();

	return (
		<>
			<ModalNotification
				caption={l10nContext.translate('common.modal.successCaption', 'Abgeschlossen')}
				message={l10nContext.translate('view.reports.modal.action.create.successMessage', 'Der Bericht wurde erfolgreich angelegt')}
				level={ModalNotificationLevel.LEVEL_SUCCESS}
			/>
			<ColumnLayout mode={ColumnLayoutMode.ALIGN_CENTER}>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.reports.modal.action.create.btnReportDetails', 'Weiter zu den Berichtsdetails')}
						onClick={() => { history.push(Route.REPORTS + '/' + reportCreated.Uuid); }}
					/>
				</ColumnLayoutSection>
				<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
					<ButtonPrimary
						buttonText={l10nContext.translate('view.reports.modal.action.create.btnReportOverview', 'Weiter zur Berichte-Übersicht')}
						onClick={onClickConfirm}
					/>
				</ColumnLayoutSection>
			</ColumnLayout>
		</>
	);
};
