import { FunctionComponent } from 'react';

import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export enum ModalDialogueCaptionModifier {
	BIG_CAPTION = 'window__caption--big',
	SMALL_CAPTION = 'window__caption--small',
	GRID_CAPTION = 'window__caption--grid'
}

export interface ModalDialogueHeaderProps extends React.ComponentPropsWithoutRef<'header'> {
	/**
	 * (Optional) caption text
	*/
	captionText?: string;
	/**
	 * (Optional) caption icon
	*/
	captionIcon?: IconIdentifier;
	/**
	 * (Optional) caption modifier
	*/
	captionModifier?: ModalDialogueCaptionModifier;
}

export const ModalDialogueHeader: FunctionComponent<ModalDialogueHeaderProps> = (props): JSX.Element => {
	const { captionText, captionIcon, captionModifier = ModalDialogueCaptionModifier.BIG_CAPTION } = props;

	const iconElement = captionIcon ?
		<Icon name={captionIcon} /> :
		null;

	const HeaderContentWrapper: FunctionComponent<{ children: string, className: string }> = ({ children, className }): JSX.Element => {
		switch (captionModifier) {
			case ModalDialogueCaptionModifier.BIG_CAPTION:
			default:
				return (
					<h1 className={className}>
						{children}
					</h1>
				);

			case ModalDialogueCaptionModifier.SMALL_CAPTION:
				return (
					<p className={className}>
						{children}
					</p>
				);

			case ModalDialogueCaptionModifier.GRID_CAPTION:
				return (
					<p className={className}>
						{children}
					</p>
				);
		}
	};
	const headerContent = captionText ?
		<>
			{iconElement}
			<HeaderContentWrapper className='modal-dialogue__window__caption__text'>
				{captionText}
			</HeaderContentWrapper>
		</> :
		null;

	return (
		<header className={`modal-dialogue__window__caption ${captionModifier}`}>
			{headerContent}
		</header>
	);
};
