import { Chip, ChipProps } from 'presentation/ui/partials/chip/Chip';

import './filter-chip-set.scss';

export interface FilterChipSetProps {
	/**
	 * Label
	 */
	label: string;
	/**
	 * Chips
	 */
	chips: ChipProps[];
}

/**
 * The filter chip set component
 */
export const FilterChipSet = (props: FilterChipSetProps): JSX.Element => {
	const { label, chips } = props;

	const onChipClicked = (id: string, state: boolean) => {
	};

	const chipElements = chips.map(chip => (
		<Chip
			key={chip.id ?? chip.label.trim().toLowerCase()}
			onClick={onChipClicked}
			{...chip}
		/>
	));

	return (
		<div className='filter-chip-set'>
			<p className='filter-chip-set__label'>
				{label}
			</p>

			<div className="filter-chip-set__chips">
				{chipElements}
			</div>
		</div>
	);
};
