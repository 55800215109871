import { useTypedSelector } from 'store/common/TypedSelector';

// eslint-disable-next-line
export const useController = () => {
	const deviceActionStatus = useTypedSelector(state => state.devices.actionStatus);
	const storeActionStatus = useTypedSelector(state => state.maintenanceLogEntries.actionStatus);
	const storeLastActionError = useTypedSelector(state => state.maintenanceLogEntries.lastActionError);

	return {
		deviceActionStatus,
		storeActionStatus,
		storeLastActionError
	};
};
