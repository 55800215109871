import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FolderViewModel } from 'services/documents/domain/model/FolderModel';
import { FolderEditFormStep1 } from 'services/documents/presentation/ui/folder-action/folder-edit-form/FolderEditFormStep1';
import { FolderEditFormStep2 } from 'services/documents/presentation/ui/folder-action/folder-edit-form/FolderEditFormStep2';
import { updateFolder } from 'services/documents/store/folderSlice';

export interface FolderEditFormProps {
	folder: FolderViewModel;
	onChange: (input: string) => void;
}

export const FolderEditForm = (props: FolderEditFormProps): JSX.Element => {
	const { folder, onChange } = props;

	const [showStep2, setShowStep2] = useState<boolean>(false);
	const [invalidStatus, setInvalidStatus] = useState<boolean>(false);

	const newFolderName = useRef<string>(folder.Name);

	const dispatch = useDispatch();

	const handleSaveAction = (): void => {
		dispatch(updateFolder({
			...folder,
			Name: newFolderName.current
		}));
	};

	const onUserInput = (input: string) => {
		newFolderName.current = input;
	};

	const gotoStep1 = () => {
		setShowStep2(false);
	};

	const gotoStep2 = () => {
		if (newFolderName.current.trim() !== '') {
			onChange(newFolderName.current);
			setShowStep2(true);
		} else {
			setInvalidStatus(true);
		}
	};

	const formStep = !showStep2 ?
		<FolderEditFormStep1
			folder={newFolderName.current}
			onChange={onUserInput}
			onClickNext={gotoStep2}
			invalid={invalidStatus}
		/> :
		<FolderEditFormStep2
			folder={newFolderName.current}
			onClickBack={gotoStep1}
			onClickNext={handleSaveAction}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
