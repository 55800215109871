import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { TextInput } from 'services/device/domain/business/common/Input';

import { Label } from 'presentation/ui/partials/input/label/Label';
import { Textarea } from 'presentation/ui/partials/input/textarea/Textarea';

export interface TextInputProps {
	input: TextInput;
}

export const TextInputRenderer = (props: TextInputProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const { input } = props;

	let label = l10nContext.translate(input.getLabelKey());
	if (!input.isOptional()) {
		label += '*';
	}

	return (
		<Label label={label}>
			<Textarea
				name={input.getName()}
				defaultValue={input.getSingle() ?? ''}
				required={!input.isOptional()}
				onChange={(event) => {
					input.setSingle(event.target.value);
				}}
			/>
		</Label>
	);

};
