import { memo, useContext } from 'react';
import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';

import './card-item-report-attributes.scss';

export interface CardItemReportAttributesProps {
	/**
	 * Period date start
	 */
	periodDateStart: string
	/**
	 * Period date end
	 */
	periodDateEnd: string
	/**
	 * Valid until date
	 */
	validUntil: string
	/**
	 * Valid until date
	 */
	url?: string
}

/**
 * A card item report detail attributes component
 */
export const CardItemReportAttributes = memo((props: CardItemReportAttributesProps): JSX.Element => {
	const { periodDateStart, periodDateEnd, validUntil, url } = props;

	const l10nContext = useContext(L10nContext);

	const domain = window.location.host;

	const translations = {

		periodDateStart: { label: l10nContext.translate('reports.periodDateStart', 'Startdatum'), value: periodDateStart },
		periodDateEnd: { label: l10nContext.translate('reports.periodDateEnd', 'Enddatum'), value: periodDateEnd },
		validUntil: { label: l10nContext.translate('reports.validUntil', 'Gültig bis'), value: validUntil }
	};

	const attributes = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const styleClass = 'attribute';

			return (
				<label
					key={Guid.generate()}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{value }</p>
				</label>
			);
		});
	};

	return (
		<div className='card-item-report-attributes'>
			<ul className='card-item-report-attributes__attribute'>
				{attributes()}
				{ url ?
					<label
						key={Guid.generate()}
						className="attribute"
					>
						<strong>{l10nContext.translate('reports.url', 'URL')}:&nbsp;</strong>
						<a
							className="attribute"
							href={url}
							target="_blank"
							rel="noreferrer"
						>
							{`${domain}${url}`}
						</a>
					</label>
					: null }
			</ul>
		</div>
	);
});
