import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { L10nContext } from 'context/L10nContext';
import { Route } from 'router/Route';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { MaintenanceLogCollectionItem } from 'services/device/presentation/ui/card-collections/maintenance-log-collection/MaintenanceLogCollectionItem';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { mapTypeToIlluIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifierMapper';

import './card-device-dashboard.scss';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';

export interface CardDeviceWithMaintenanceLogEntriesProps {
	device: DeviceViewModel;
	maintenanceLogEntries: Array<MaintenanceLogEntryViewModel>;
	onEditLogEntryClick: (maintenanceLogEntry: MaintenanceLogEntryViewModel) => void;
	illustration?: boolean;
}

/**
 * The card device dashboard component
 */
export const CardDeviceWithMaintenanceLogEntries = (props: CardDeviceWithMaintenanceLogEntriesProps): JSX.Element => {
	const { device, maintenanceLogEntries, onEditLogEntryClick, illustration = true } = props;

	// Consume the contexts
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	const renderMaintenanceLogEntryCards = (): JSX.Element => {
		if (maintenanceLogEntries.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.maintenanceLog')} />
			);
		}

		return (
			<>
				{
					maintenanceLogEntries.map((maintenanceLogEntry) => (
						<MaintenanceLogCollectionItem
							key={maintenanceLogEntry.Uuid}
							device={device}
							maintenanceLogEntry={maintenanceLogEntry}
							onEdit={() => {
								onEditLogEntryClick(maintenanceLogEntry);
							}}
						/>
					))
				}
			</>
		);
	};

	return (
		<div className="card-device-dashboard">
			<div className="card-device-dashboard__content">
				<CardItemHeader
					title={device.Name}
					subtitle={device.Identifier}
					illustration={illustration ? mapTypeToIlluIdentifier(device.Type) : null}
				/>
				<CardItemSingleControl
					cardId={device.Uuid}
					actionDetails={CardItemControlState.ENABLED}
					onClick={() => history.push(Route.DEVICES + '/' + device.Uuid)}
				/>
				<CardCollectionLayout>
					{renderMaintenanceLogEntryCards()}
				</CardCollectionLayout>
			</div>
		</div>
	);
};
