import { L10nContext } from 'context/L10nContext';
import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { ContainerStationMeasurementForm } from '../container-station-measurement-form/ContainerStationMeasurementForm';
import { ContainerStationMeasurementFailedNotification } from './ContainerStationMeasurementFailedNotification';
import { ContainerStationMeasurementSuccessNotification } from './ContainerStationMeasurementSuccessNotification';

interface ContainerStationMeasurementModalProps {
	container: ContainerViewModel;
	onDismiss: () => void;
}

export const ContainerStationMeasurementModal = (props: ContainerStationMeasurementModalProps): JSX.Element => {
	const { container, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	const containerActionStatus = useTypedSelector(state => state.containers.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (containerActionStatus) {

			case AsyncReducerStatus.IDLE:
				modal = <ContainerStationMeasurementForm
					container={container}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modal =
					<ContainerStationMeasurementSuccessNotification
						onClickConfirm={onDismiss}
						containerUpdated={container}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<ContainerStationMeasurementFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;

	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.containers.modal.action.measure.caption', 'Gebinde berechnen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
