/* eslint-disable max-classes-per-file */

export enum SequenceType {

	BACKGROUND_COUNT_BARIUM = 'BACKGROUND_COUNT_BARIUM',

	BACKGROUND_COUNT_CAESIUM = 'BACKGROUND_COUNT_CAESIUM',

	BACKGROUND_COUNT_COBALT = 'BACKGROUND_COUNT_COBALT',

	BACKGROUND_COUNT_IODINE = 'BACKGROUND_COUNT_IODINE',

	BACKGROUND_COUNT_TECHNETIUM = 'BACKGROUND_COUNT_TECHNETIUM',

	BACKGROUND_COUNT_INTEGRAL = 'BACKGROUND_COUNT_INTEGRAL',

	BOREHOLE_FACTOR = 'BOREHOLE_FACTOR',

	BOREHOLE_FACTOR_PAYLOAD = 'BOREHOLE_FACTOR_PAYLOAD',

	LINEARITY_FLUORINE = 'LINEARITY_FLUORINE',

	LINEARITY_TECHNETIUM = 'LINEARITY_TECHNETIUM',

	LINEARITY_V2_FLUORINE = 'LINEARITY_V2_FLUORINE',

	LINEARITY_V2_TECHNETIUM = 'LINEARITY_V2_TECHNETIUM',

	NUCLIDE_POSITION_ZERO_EFFECT = 'NUCLIDE_POSITION_ZERO_EFFECT',

	NUCLIDE_POSITION_ZERO_EFFECT_802 = 'NUCLIDE_POSITION_ZERO_EFFECT_802',

	NUCLIDE_POSITION_RESPONSIVENESS = 'NUCLIDE_POSITION_RESPONSIVENESS',

	MOLYBDENUM_BREAKTHROUGH_MOLYBDENUM = 'MOLYBDENUM_BREAKTHROUGH_MOLYBDENUM',

	MOLYBDENUM_BREAKTHROUGH_TECHNETIUM = 'MOLYBDENUM_BREAKTHROUGH_TECHNETIUM',

	MOLYBDENUM_BREAKTHROUGH_PAYLOAD = 'MOLYBDENUM_BREAKTHROUGH_PAYLOAD',

	POWER_WINDOW = 'POWER_WINDOW',

	POWER_WINDOW_PERCENTAGE = 'POWER_WINDOW_PERCENTAGE',

	POWER_WINDOW_PERCENTAGE_V2 = 'POWER_WINDOW_PERCENTAGE_V2',

	POWER_WINDOW_SETTING = 'POWER_WINDOW_SETTING',

	YIELD = 'YIELD',

	YIELD_BARIUM = 'YIELD_BARIUM',

	YIELD_CAESIUM = 'YIELD_CAESIUM',

	YIELD_COBALT = 'YIELD_COBALT',

	YIELD_IODINE = 'YIELD_IODINE',

	YIELD_IODINE_V2 = 'YIELD_IODINE_V2',

	YIELD_STRONTIUM = 'YIELD_STRONTIUM',

	YIELD_TECHNETIUM = 'YIELD_TECHNETIUM',

	YIELD_PAYLOAD = 'YIELD_PAYLOAD',

	YIELD_PERCENT = 'YIELD_PERCENT',

	GEOMETRY_FACTOR_DETERMINATION = 'GEOMETRY_FACTOR_DETERMINATION',

	HOMOGENEITY = 'HOMOGENEITY',

	EXTRINSIC_HOMOGENEITY = 'EXTRINSIC_HOMOGENEITY',

	EXTRINSIC_HOMOGENEITY_V2 = 'EXTRINSIC_HOMOGENEITY_V2',

	EXTRINSIC_HOMOGENEITY_V3 = 'EXTRINSIC_HOMOGENEITY_V3',

	EXTRINSIC_HOMOGENEITY_V4 = 'EXTRINSIC_HOMOGENEITY_V4',

	INTRINSIC_HOMOGENEITY = 'INTRINSIC_HOMOGENEITY',

	INTRINSIC_HOMOGENEITY_V2 = 'INTRINSIC_HOMOGENEITY_V2',

	INTRINSIC_HOMOGENEITY_V3 = 'INTRINSIC_HOMOGENEITY_V3',

	HOMOGENEITY_WITHOUT_CORRECTION_MATRIX = 'HOMOGENEITY_WITHOUT_CORRECTION_MATRIX',

	RP_SINGLE_STRIPE = 'RP_SINGLE_STRIPE',

	RP_DOUBLE_STRIPE = 'RP_DOUBLE_STRIPE',

	RP_CARTRIDGE = 'RP_CARTRIDGE',

	RP_FILTER = 'RP_FILTER',

	RP_RINSED_FILTER = 'RP_RINSED_FILTER',

	RP_TLC_SCANNER = 'RP_TLC_SCANNER',

	RP_ROTOP_B20_SINGLE_STRIPE = 'RP_ROTOP_B20_SINGLE_STRIPE',

	RP_ROTOP_CARDIOTOP_DOUBLE_STRIPE = 'RP_ROTOP_CARDIOTOP_DOUBLE_STRIPE',

	RP_ROTOP_DMSA_SINGLE_STRIPE = 'RP_ROTOP_DMSA_SINGLE_STRIPE',

	RP_ROTOP_DTPA_DOUBLE_STRIPE = 'RP_ROTOP_DTPA_DOUBLE_STRIPE',

	RP_ROTOP_EHIDA_DOUBLE_STRIPE = 'RP_ROTOP_EHIDA_DOUBLE_STRIPE',

	RP_ROTOP_MAG3_DOUBLE_STRIPE = 'RP_ROTOP_MAG3_DOUBLE_STRIPE',

	RP_ROTOP_MDP_DOUBLE_STRIPE = 'RP_ROTOP_MDP_DOUBLE_STRIPE',

	RP_ROTOP_NANO_HSA_SINGLE_STRIPE = 'RP_ROTOP_NANO_HSA_SINGLE_STRIPE',

	RP_ROTOP_NEUROSPECT_DOUBLE_STRIPE = 'RP_ROTOP_NEUROSPECT_DOUBLE_STRIPE',

	RP_ROTOP_TEKTROTYD_DOUBLE_STRIPE = 'RP_ROTOP_TEKTROTYD_DOUBLE_STRIPE',

	RP_ROTOP_TETROFOSMIN_DOUBLE_STRIPE = 'RP_ROTOP_TETROFOSMIN_DOUBLE_STRIPE',

	RP_CURIUM_NANOCIS_SINGLE_STRIPE = 'RP_CURIUM_NANOCIS_SINGLE_STRIPE',

	RP_CURIUM_NEUROLITE_SINGLE_STRIPE = 'RP_CURIUM_NEUROLITE_SINGLE_STRIPE',

	RP_CURIUM_PULMOCIS_FILTER = 'RP_CURIUM_PULMOCIS_FILTER',

	RP_CURIUM_SCINTIMUN_SINGLE_STRIPE = 'RP_CURIUM_SCINTIMUN_SINGLE_STRIPE',

	RP_CURIUM_STAMICIS_SINGLE_STRIPE = 'RP_CURIUM_STAMICIS_SINGLE_STRIPE',

	RP_CURIUM_STAMICIS_CARTRIDGE = 'RP_CURIUM_STAMICIS_CARTRIDGE',

	RP_CURIUM_TECEOS_DOUBLE_STRIPE = 'RP_CURIUM_TECEOS_DOUBLE_STRIPE',

	RP_CURIUM_TECEOS_DOUBLE_STRIPE_2024 = 'RP_CURIUM_TECEOS_DOUBLE_STRIPE_2024',

	RP_CURIUM_TECHNESCAN_DSMA_SINGLE_STRIPE = 'RP_CURIUM_TECHNESCAN_DSMA_SINGLE_STRIPE',

	RP_CURIUM_TECHNESCAN_DTPA_DOUBLE_STRIPE = 'RP_CURIUM_TECHNESCAN_DTPA_DOUBLE_STRIPE',

	RP_CURIUM_TECHNESCAN_HDP_DOUBLE_STRIPE = 'RP_CURIUM_TECHNESCAN_HDP_DOUBLE_STRIPE',

	RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE = 'RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE',

	RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE_V2 = 'RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE_V2',

	RP_CURIUM_TECHNESCAN_SESTAMIBI_SINGLE_STRIPE = 'RP_CURIUM_TECHNESCAN_SESTAMIBI_SINGLE_STRIPE',

	RP_CURIUM_TECHNESCAN_SESTAMIBI_CARTRIDGE = 'RP_CURIUM_TECHNESCAN_SESTAMIBI_CARTRIDGE',

	CTDI = 'CTDI',

	CTDI_CUSTOM = 'CTDI_CUSTOM',

	PET_CT_PHILIPS_GEMINI_FWHM_AVERAGE = 'PET_CT_PHILIPS_GEMINI_FWHM_AVERAGE',

	PET_CT_PHILIPS_GEMINI_LARGEST_MAX_MIN_DELTA_TILT = 'PET_CT_PHILIPS_GEMINI_LARGEST_MAX_MIN_DELTA_TILT',

	PET_CT_PHILIPS_GEMINI_MAX_BASELINE_DIFFERENCE = 'PET_CT_PHILIPS_GEMINI_MAX_BASELINE_DIFFERENCE',

	PET_CT_PHILIPS_GEMINI_SUV_VALIDATION_AND_HOMOGENITY = 'PET_CT_PHILIPS_GEMINI_SUV_VALIDATION_AND_HOMOGENITY',

	PET_CT_PHILIPS_VEREOS_AXIAL_ABERRANCE = 'PET_CT_PHILIPS_VEREOS_AXIAL_ABERRANCE',

	PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_CENTROID = 'PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_CENTROID',

	PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_ENERGY_RESOLUTION = 'PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_ENERGY_RESOLUTION',

	PET_CT_PHILIPS_VEREOS_INTEGRAL_HOMOGENITY = 'PET_CT_PHILIPS_VEREOS_INTEGRAL_HOMOGENITY',

	PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY = 'PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY',

	PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY_BRAIN = 'PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY_BRAIN',

	PET_CT_PHILIPS_VEREOS_TIMING_RESOLUTION = 'PET_CT_PHILIPS_VEREOS_TIMING_RESOLUTION',

	PET_CT_PHILIPS_VEREOS_UNIFORMITY_TEST_BAD_PIXELS = 'PET_CT_PHILIPS_VEREOS_UNIFORMITY_TEST_BAD_PIXELS',

	PET_CT_SIEMENS_GENERIC_BLOCK_EFFICIENCY = 'PET_CT_SIEMENS_GENERIC_BLOCK_EFFICIENCY',

	PET_CT_SIEMENS_GENERIC_BLOCK_NOISE = 'PET_CT_SIEMENS_GENERIC_BLOCK_NOISE',

	PET_CT_SIEMENS_GENERIC_MEASURED_RANDOMS = 'PET_CT_SIEMENS_GENERIC_MEASURED_RANDOMS',

	PET_CT_SIEMENS_GENERIC_SCANNER_EFFICIENCY = 'PET_CT_SIEMENS_GENERIC_SCANNER_EFFICIENCY',

	PET_CT_SIEMENS_GENERIC_SCATTER_RATIO = 'PET_CT_SIEMENS_GENERIC_SCATTER_RATIO',

	BACKGROUND_ZERO_EFFECT = 'BACKGROUND_ZERO_EFFECT',

	BACKGROUND_ZERO_EFFECT_V2 = 'BACKGROUND_ZERO_EFFECT_V2',

	BACKGROUND_ZERO_EFFECT_FREE = 'BACKGROUND_ZERO_EFFECT_FREE',

	RESPONSIVENESS = 'RESPONSIVENESS',

	RESPONSIVENESS_CS_137 = 'RESPONSIVENESS_CS_137',

	RESPONSIVENESS_CS_137_V2 = 'RESPONSIVENESS_CS_137_V2',

	HELIUM_LEVEL = 'HELIUM_LEVEL',

	LOW_CONTRAST_OBJECTS = 'LOW_CONTRAST_OBJECTS',

	LINE_RESOLUTION = 'LINE_RESOLUTION',

	DOSE_SURFACE_PRODUCT_ABSOLUTE = 'DOSE_SURFACE_PRODUCT_ABSOLUTE',

	DOSE_SURFACE_PRODUCT_RELATIVE = 'DOSE_SURFACE_PRODUCT_RELATIVE',

	DOSE_INDICATOR = 'DOSE_INDICATOR',

	EDGE_DEVIATION = 'EDGE_DEVIATION',

	GENERIC_CUSTOM = 'GENERIC_CUSTOM',

	FREE_TEXT = 'FREE_TEXT',

	SINGLE_SELECT = 'SINGLE_SELECT',

	CHECK = 'CHECK',

	OBJECTIVE = 'OBJECTIVE'

}