import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import { SequenceModelUuid } from 'services/device/domain/model/SequenceModel';
import { ReportModelUuid } from 'services/report/domain/model/ReportModel';

export type ReportSequenceModelUuid = string;

export interface ReportSequencePersistenceModel extends PersistenceModel {
	Report: string;
	Sequence: string;
	PeriodDateStart: number;
	PeriodDateEnd: number;
}

export interface ReportSequenceStoreModel extends StoreModel {
	Report: string;
	Sequence: string;
	PeriodDateStart: number;
	PeriodDateEnd: number;
}

export interface ReportSequenceViewModel extends ViewModel {
	Report: ReportModelUuid;
	Sequence: SequenceModelUuid;
	PeriodDateStart: Date;
	PeriodDateEnd: Date;
}

export interface CreatableReportSequenceViewModel extends CreatableViewModel {
	Sequence: SequenceModelUuid;
	PeriodDateStart: Date;
	PeriodDateEnd: Date;
}

export class ReportSequenceModelConverter
	extends ModelConverter<ReportSequencePersistenceModel, ReportSequenceStoreModel, ReportSequenceViewModel, CreatableReportSequenceViewModel> {

	public fromStoreModel(storeModel?: ReportSequenceStoreModel): this {
		super.fromStoreModel(storeModel, (sModel, model) => {
			model.PeriodDateStart = Math.floor(sModel.PeriodDateStart / 1000);
			model.PeriodDateEnd = Math.floor(sModel.PeriodDateEnd / 1000);
		});

		return this;
	}

	public fromViewModel(viewModel?: ReportSequenceViewModel): this {
		super.fromViewModel(viewModel, (vModel, model) => {
			model.Report = vModel.Report as string;
			model.Sequence = vModel.Sequence as string;
			model.PeriodDateStart = Math.floor(vModel.PeriodDateStart.getTime() / 1000);
			model.PeriodDateEnd = Math.floor(vModel.PeriodDateEnd.getTime() / 1000);
		});

		return this;
	}

	public toStoreModel(): ReportSequenceStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.PeriodDateStart = model.PeriodDateStart * 1000;
			sModel.PeriodDateEnd = model.PeriodDateEnd * 1000;
		});
	}

	public toViewModel(): ReportSequenceViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.Report = model.Report as ReportModelUuid;
			vModel.Sequence = model.Sequence as SequenceModelUuid;
			vModel.PeriodDateStart = new Date(model.PeriodDateStart * 1000);
			vModel.PeriodDateEnd = new Date(model.PeriodDateEnd * 1000);
		});
	}

}
