import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { UrlInput } from 'services/device/domain/business/common/Input';

import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';

export interface UrlInputProps {
	input: UrlInput;
}

export const UrlInputRenderer = (props: UrlInputProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const { input } = props;

	let label = l10nContext.translate(input.getLabelKey());
	if (input.getDescription()?.mandatory === true) {
		label += '*';
	}

	const optionsAttributes: Record<string, string> = {};
	const optionsDatalistId = input.getName() + '_options';
	const inputOptions = input.getOptions();

	if (inputOptions !== null && inputOptions.length > 0) {
		optionsAttributes.list = optionsDatalistId;
	}

	const renderOptionsDatalist = (): JSX.Element | null => {
		if (inputOptions === null || inputOptions.length === 0) {
			return null;
		}
		const options = inputOptions.map((option): JSX.Element => {
			return (<option value={option} key={option} />);
		});
		return (
			<datalist id={optionsDatalistId}>
				{options}
			</datalist>
		);
	};

	return (
		<Label label={label}>
			<InputText
				type="url"
				pattern="https://.*"
				name={input.getName()}
				defaultValue={input.getSingle() ?? ''}
				required={!input.isOptional()}
				onChange={(event) => {
					input.setSingle(event.target.value);
				}}
				placeholder="https://"
				{...optionsAttributes}
			/>
			{renderOptionsDatalist()}
		</Label>
	);

};
