import './card-numeric-batch.scss';

export interface CardNumericBatchProps {
	/**
	 * Count of items
	 */
	count: string | number;
	/**
	 * Label
	 */
	label: string;
	/**
	 * (Optional) anchor tag
	 */
	linkTarget?: string;
}

/**
 * The numeric batch card component
 */
export const CardNumericBatch = ({ count, label, linkTarget }: CardNumericBatchProps): JSX.Element => {
	const itemsCount = String(count).trim();

	const AnchorTagWrapper: React.FC<{ children: React.ReactElement }> = ({ children }) =>
		linkTarget ?
			<a href={`#${linkTarget}`}>
				{children}
			</a> :
			children;

	return (
		<div className={`card-numeric-batch ${linkTarget ? 'card-numeric-batch--linked' : ''}`}>
			<AnchorTagWrapper>
				<>
					<span className='card-numeric-batch__count'>
						{itemsCount}
					</span>

					<p className='card-numeric-batch__label'>
						{label}
					</p>
				</>
			</AnchorTagWrapper>
		</div>
	);
};
