import { ComponentPropsWithoutRef } from 'react';

import { Icon } from 'presentation/ui/partials/icon/Icon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

import './time-picker.scss';

export enum TimePickerStatus {
	ERROR = 'ERROR',
	READONLY = 'READONLY'
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface TimePickerProps extends ComponentPropsWithoutRef<'input'> {
	/**
	 * Status of choosen time
	 */
	status?: TimePickerStatus;
	/**
	 * (Predefined) time
	 */
	defaultValue?: Date | null;
}

/**
 * A nearby native input time component.
 * You can add all additional attributes you know from native HTML input time element,
 * like f.e. max, min, step, onChange-handler etc.
 */
export const TimePicker = (props: TimePickerProps): JSX.Element => {
	const { status, defaultValue = null, ...restProps } = props;

	const timeValue = () => {
		if (defaultValue === null) {
			return null;
		}
		const hours = defaultValue.getHours().toString();
		const minutes = defaultValue.getMinutes().toString();
		return hours.padStart(2, '0') + ':' + minutes.padStart(2, '0');
	};

	const stateClass = status ?
		`time-picker--${status.toLowerCase()}` :
		'';

	return (
		<div className={`time-picker ${stateClass}${props.disabled ? ' time-picker--disabled' : ''}`}>
			<input
				type="time"
				defaultValue={timeValue()}
				{...restProps}
			/>

			<Icon name={IconIdentifier.CALENDER} />
		</div>
	);
};
