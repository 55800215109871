import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { deleteCyclotronProduct } from 'services/nuclide/store/cyclotronProductSlice';
import { useContext } from 'react';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { CyclotronProductViewModel } from 'services/nuclide/domain/model/CyclotronProductModel';
import { CyclotronProductDeleteForm } from '../cyclotron-product-delete-form/CyclotronProductDeleteForm';
import { CyclotronProductDeleteFailedNotification } from './CyclotronProductDeleteFailedNotification';

export interface CyclotronProductDeleteModalProps {
	cyclotronProduct: CyclotronProductViewModel
	onDismiss?: () => void;
}

export const CyclotronProductDeleteModal = (props: CyclotronProductDeleteModalProps): JSX.Element => {
	const { cyclotronProduct, onDismiss } = props;

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const cyclotronProductActionStatus = useTypedSelector(state => state.cyclotronProducts.actionStatus);

	const dispatch = useDispatch();

	const handleDelete = (): void => {
		cyclotronProduct.Client = clientContext.selectedClientUuid;
		cyclotronProduct.Facility = facilityContext.selectedFacilityUuid;
		dispatch(deleteCyclotronProduct(cyclotronProduct));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (cyclotronProductActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<CyclotronProductDeleteForm
						cyclotronProduct={cyclotronProduct}
						onDelete={handleDelete}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <CyclotronProductDeleteFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
