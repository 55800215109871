import { createContext } from 'react';

interface ExpandableContextValue {
	expanded: boolean | null;

	setExpanded(expanded: boolean): void;
}

export const ExpandableContext = createContext<ExpandableContextValue>({
	expanded: null,
	setExpanded(): void {
	}
});
