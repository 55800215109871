import { NotificationBars } from 'presentation/ui/components/notification-bars/NotificationBars';
import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { useKeyPress } from 'presentation/hooks';
import { NotificationBar } from 'presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from 'presentation/ui/components/notification-bar/NotificationLevel';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout, ColumnLayoutMode } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { InputText } from 'presentation/ui/partials/input/input-text/InputText';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { Textarea } from 'presentation/ui/partials/input/textarea/Textarea';
import { CreatableMaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { DateTimePicker } from 'presentation/ui/compositions/date-time-picker/DateTimePicker';

export interface MaintenanceLogCreateFormStep1Props {
	maintenanceLogEntry: CreatableMaintenanceLogEntryViewModel;
	onClickNext: () => void;
	onChange?: (identifier: string, value: string | Date) => void;
	invalidInputs?: string[];
}

export const MaintenanceLogCreateFormStep1 = (props: MaintenanceLogCreateFormStep1Props): JSX.Element => {
	const { maintenanceLogEntry, onClickNext, invalidInputs = [], onChange } = props;

	const l10nContext = useContext(L10nContext);

	useKeyPress('Enter', onClickNext);

	// Notification for invalid input fields
	const checkPlural = invalidInputs.length > 1 ? l10nContext.translate('common.modal.notificationErrorPlural', ' dürfen nicht leer sein') : l10nContext.translate('common.modal.notificationError', ' darf nicht leer sein');
	let notification = null;
	if (invalidInputs.length > 0) {

		const invalidNotificationMessage = invalidInputs.join(l10nContext.translate('common.text.and', ' und '))
			+ checkPlural;
		notification = (
			<NotificationBar
				level={NotificationLevel.LEVEL_ERROR}
				message={invalidNotificationMessage}
			/>
		);
	}

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			onSubmit={onClickNext}
		>
			<NotificationBars>
				{notification}
			</NotificationBars>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('view.maintenances.modal.action.create.title', 'Titel')}*`}>
					<InputText
						type="text"
						defaultValue={maintenanceLogEntry.IncidentTitle ?? ''}
						onChange={(event) => onChange('IncidentTitle', event.target.value)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('view.maintenances.modal.action.create.incident', 'Vorfall')}*`}>
					<Textarea
						defaultValue={maintenanceLogEntry.IncidentCause ?? ''}
						onChange={(event) => onChange('IncidentCause', event.target.value)}
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('view.maintenances.modal.action.create.createDate', 'Datum der Erstellung')}*`}>
					<DateTimePicker
						name='incidentDateStart'
						defaultValue={(maintenanceLogEntry?.IncidentDateStart) ?? new Date()}
						onChange={(event) => onChange('IncidentDateStart', event)}
						required
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout mode={ColumnLayoutMode.ALIGN_RIGHT}>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('common.button.next', 'Weiter')}
							type='submit'
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
