import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { MembershipViewModel } from 'services/core/domain/model/MembershipModel';
import { updateMembership } from 'services/core/store/membershipSlice';

import { MembershipUpdateFormStep1 } from './MembershipUpdateFormStep1';
import { MembershipUpdateFormStep2 } from './MembershipUpdateFormStep2';

export interface MembershipUpdateFormProps {
	membership?: MembershipViewModel;
}

export const MembershipUpdateForm = (props: MembershipUpdateFormProps): JSX.Element => {
	const { membership } = props;

	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const [showStep2, setShowStep2] = useState<boolean>(false);

	const dispatch = useDispatch();

	const updatedMembership: MembershipViewModel = membership;

	const handleChangeMembershipProp = (prop: string, value: any) => {
		updatedMembership[prop] = value;
	};

	const handleUpdate = (): void => {
		dispatch(updateMembership({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,

			viewModel: updatedMembership
		}));
	};

	const gotoStep1 = () => {
		setShowStep2(false);
	};

	const gotoStep2 = () => {
		setShowStep2(true);
	};

	const formStep = !showStep2 ?
		<MembershipUpdateFormStep1
			membership={updatedMembership}
			onClickNext={gotoStep2}
			onChangeRoleSelection={handleChangeMembershipProp}
		/> :
		<MembershipUpdateFormStep2
			membership={updatedMembership}
			onClickBack={gotoStep1}
			onClickNext={handleUpdate}
		/>;

	return (
		<>
			{formStep}
		</>
	);
};
