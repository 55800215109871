import { L10nContext } from 'context/L10nContext';
import { useContext } from 'react';
import { useTypedSelector } from '../../../../../store/common/TypedSelector';
import { NuclideModelActivityUnit } from '../../../../nuclide/domain/model/NuclideModelActivityUnit';
import { selectExternalWasteManagementReportEluates } from '../../../../nuclide/store/externalWasteManagementEluateReportSlice';
import { selectExternalWasteManagementReportGenerators } from '../../../../nuclide/store/externalWasteManagementReportGeneratorSlice';

import './external-waste-management-report-generators-card.scss';
import {
	ExternalWasteManagementReportGeneratorAnnualReport
} from '../external-waste-management-report-generator-annual-report/ExternalWasteManagementReportGeneratorAnnualReport';
import {
	ExternalWasteManagementReportGeneratorDetail
} from '../external-waste-management-report-generator-detail/ExternalWasteManagementReportGeneratorDetail';

export const ExternalWasteManagementReportGeneratorsCard = (): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const externalWasteManagementReportGenerators = useTypedSelector(selectExternalWasteManagementReportGenerators());
	const externalWasteManagementReportEluates = useTypedSelector(selectExternalWasteManagementReportEluates());

	const KILO_BECQUEREL: number = 1000;
	const MEGA_BECQUEREL = KILO_BECQUEREL * 1000;
	const GIGA_BECQUEREL = MEGA_BECQUEREL * 1000;

	let sumAllEluateActivities = 0;
	externalWasteManagementReportEluates.map((eluate) => {
		if (eluate.ActivityUnit === NuclideModelActivityUnit.KILO_BEQUEREL) {
			sumAllEluateActivities += eluate.Activity * KILO_BECQUEREL;
		}
		if (eluate.ActivityUnit === NuclideModelActivityUnit.MEGA_BEQUEREL) {
			sumAllEluateActivities += eluate.Activity * MEGA_BECQUEREL;
		}
		if (eluate.ActivityUnit === NuclideModelActivityUnit.GIGA_BEQUEREL) {
			sumAllEluateActivities += eluate.Activity * GIGA_BECQUEREL;
		}
		return sumAllEluateActivities;
	});

	let sumNominalActivitiesGenerators: number = 0;
	externalWasteManagementReportGenerators.map((generator) => {
		if (generator.ActivityUnit === NuclideModelActivityUnit.KILO_BEQUEREL) {
			sumNominalActivitiesGenerators += generator.NominalActivity * KILO_BECQUEREL;
		}
		if (generator.ActivityUnit === NuclideModelActivityUnit.MEGA_BEQUEREL) {
			sumNominalActivitiesGenerators += generator.NominalActivity * MEGA_BECQUEREL;
		}
		if (generator.ActivityUnit === NuclideModelActivityUnit.GIGA_BEQUEREL) {
			sumNominalActivitiesGenerators += generator.NominalActivity * GIGA_BECQUEREL;
		}
		return sumNominalActivitiesGenerators;
	});

	const eluateActivities = l10nContext.formatNumber(sumAllEluateActivities / 1000000) + ' MBq';
	const nominalActivitiesGenerators = l10nContext.formatNumber(sumNominalActivitiesGenerators / 1000000) + ' MBq';

	const renderGeneratorAnnualReportInformation =
		<ExternalWasteManagementReportGeneratorAnnualReport
			eluateActivities={eluateActivities}
			nominalActivitiesGenerators={nominalActivitiesGenerators}
		/>;

	const renderGeneratorInformation = externalWasteManagementReportGenerators.map((generator) => {
		return (
			<ExternalWasteManagementReportGeneratorDetail
				key={generator.Uuid}
				generator={generator}
				isDetail={false}
			/>
		);
	});

	const renderGeneratorInformationDetail = externalWasteManagementReportGenerators.map((generator) => {
		return (
			<ExternalWasteManagementReportGeneratorDetail
				key={generator.Uuid}
				generator={generator}
				isDetail={true}
			/>
		);
	});

	return (
		<article className='external-waste-management-report-generators-card'>

			<section className='external-waste-management-report-generators-card__section'>
				<header className='external-waste-management-report-generators-card__section__header'>
					{l10nContext.translate('externalWasteManagementView.generators.annualTotalReport', 'Jahresgesamtmeldung')}
				</header>
				{renderGeneratorAnnualReportInformation}
			</section>

			<section>
				<header className='external-waste-management-report-generators-card__section__header'>
					{l10nContext.translate('externalWasteManagementView.generators.generatorReport', 'Generatormeldung')}
				</header>
				{renderGeneratorInformation}
			</section>

			<section>
				<header className='external-waste-management-report-generators-card__section__header'>
					{l10nContext.translate('externalWasteManagementView.generators.detailReport', 'Detailmeldung')}
				</header>
				{renderGeneratorInformationDetail}
			</section>

		</article>
	);
};
