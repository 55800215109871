import { L10nContext } from 'context/L10nContext';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useParams } from 'react-router-dom';
import { Route } from 'router/Route';
import { AuthContext } from 'services/core/context/AuthContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Permission, permissionGroupCyclotronProduct } from 'services/core/lib/auth/AuthService';
import { CyclotronProductModelState } from 'services/nuclide/domain/model/CyclotronProductModelState';
import { CyclotronProductIsotope } from 'services/nuclide/domain/model/isotopes/CyclotronProductIsotope';
import {
	resetActionStatus as resetCyclotronProductActionStatus,
	selectCyclotronProductByUuid
} from 'services/nuclide/store/cyclotronProductSlice';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { CardDocumentAction } from '../../../../presentation/ui/components/cards/card-document/CardDocumentActions';
import { NotificationBar } from '../../../../presentation/ui/components/notification-bar/NotificationBar';
import { NotificationLevel } from '../../../../presentation/ui/components/notification-bar/NotificationLevel';
import { NotificationBars } from '../../../../presentation/ui/components/notification-bars/NotificationBars';
import { Separator } from '../../../../presentation/ui/partials/separator/Separator';
import { AsyncFetchStatus } from '../../../../store/common/AsyncFetchStatus';
import { ClientContext } from '../../../core/context/ClientContext';
import { DocumentsCollection } from '../../../device/presentation/ui/card-collections/documents-collection/DocumentsCollection';
import { DocumentViewModel } from '../../../documents/domain/model/DocumentModel';
import { DocumentModelScope } from '../../../documents/domain/model/DocumentModelScope';
import { DocumentAddModal } from '../../../documents/presentation/ui/document-action/document-add-modal/DocumentAddModal';
import { DocumentArchiveModal } from '../../../documents/presentation/ui/document-action/document-archive-modal/DocumentArchiveModal';
import { DocumentDeleteModal } from '../../../documents/presentation/ui/document-action/document-delete-modal/DocumentDeleteModal';
import { DocumentDownloadModal } from '../../../documents/presentation/ui/document-action/document-download-modal/DocumentDownloadModal';
import { DocumentEditModal } from '../../../documents/presentation/ui/document-action/document-edit-modal/DocumentEditModal';
import { DocumentUnarchiveModal } from '../../../documents/presentation/ui/document-action/document-unarchive-modal/DocumentUnarchiveModal';
import {
	fetchDocumentsByCyclotronProduct,
	resetActionStatus as resetDocumentsActionStatus, selectFilteredDocumentsByCyclotronProduct
} from '../../../documents/store/documentSlice';
import { CyclotronProductViewModel } from '../../domain/model/CyclotronProductModel';
import {
	CyclotronProductCompletelyAppliedModal
} from '../ui/cyclotron-product-action/cyclotron-product-completely-applied-modal/CyclotronProductCompletelyAppliedModal';
import { CyclotronProductDeleteModal } from '../ui/cyclotron-product-action/cyclotron-product-delete-modal/CyclotronProductDeleteModal';
import {
	CyclotronProductToWasteModuleModal
} from '../ui/cyclotron-product-action/cyclotron-product-to-waste-module-modal/CyclotronProductToWasteModuleModal';
import {
	CyclotronProductUndoCompletelyAppliedModal
} from '../ui/cyclotron-product-action/cyclotron-product-undo-completely-applied-modal/CyclotronProductUndoCompletelyAppliedModal';
import { CyclotronProductUpdateModal } from '../ui/cyclotron-product-action/cyclotron-product-update-modal/CyclotronProductUpdateModal';
import { CyclotronProductCard } from '../ui/cyclotron-product-card/CyclotronProductCard';
import { CyclotronProductViewModalType } from './CyclotronProductViewModalType';

interface CyclotronProductViewParams {
	cyclotronProductUuid: string;
}

export const CyclotronProductView = (): JSX.Element => {

	// // Unwrap the required cyclotron product uuid from the route
	const params = useParams<CyclotronProductViewParams>();
	const cyclotronProductUuid = params?.cyclotronProductUuid ?? null;
	if (cyclotronProductUuid === null) {
		throw new Error('Cyclotrone product id is missing');
	}

	// Read the cyclotron product from the state store
	const cyclotronProduct = useSelector(selectCyclotronProductByUuid(cyclotronProductUuid));

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	// Handle permissions
	if (!authContext.hasAnyPermission(permissionGroupCyclotronProduct)) {
		throw new Error('Permission denied');
	}

	// Local state
	const [modalType, setModalType] = useState<CyclotronProductViewModalType>(null);
	const [modalPayload, setModalPayload] = useState<CyclotronProductViewModel | DocumentViewModel>(null);

	const [documentFilter, setDocumentFilter] = useState<{ [key: string]: boolean }>({
		active: true,
		archived: false
	});

	// Use a custom selector function to provide type information for the state defined in the slice
	const cyclotronProductsActionStatus = useTypedSelector(state => state.cyclotronProducts.actionStatus);
	const cyclotronProductLastActionError = useTypedSelector(state => state.cyclotronProducts.lastActionError);

	// fetch documents
	const documentStoreFetchStatus = useTypedSelector(state => state.documents.fetchStatus);
	const documentStorePending = documentStoreFetchStatus === AsyncFetchStatus.INITIAL || documentStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const documentStoreLastActionError = useTypedSelector(state => state.documents.lastActionError);
	const documentStoreFailed = documentStoreFetchStatus === AsyncFetchStatus.FAILED;

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Handle close modal for success and failure
	const handleDismiss = () => {
		setModalType(null);
	};

	useEffect(() => {
		if (cyclotronProductsActionStatus === AsyncReducerStatus.DELETED) {
			dispatch(resetCyclotronProductActionStatus());
			history.replace(Route.NUCLIDE_MANAGEMENT + Route.CYCLOTRON_PRODUCTS);
		}
	}, [cyclotronProduct, cyclotronProductsActionStatus, dispatch, history]);

	useEffect(() => {
		dispatch(fetchDocumentsByCyclotronProduct({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid,
			cyclotronProductUuid: cyclotronProduct.Uuid
		}));
	}, [clientContext.selectedClientUuid, dispatch, facilityContext.selectedFacilityUuid, cyclotronProduct.Uuid]);

	const documents = useSelector(
		selectFilteredDocumentsByCyclotronProduct(
			clientContext.selectedClientUuid,
			facilityContext.selectedFacilityUuid,
			cyclotronProductUuid,
			documentFilter.active,
			documentFilter.archived
		)
	);

	// Show up a loading indicator while the cyclotron product fetching is pending
	if (
		cyclotronProductsActionStatus === AsyncReducerStatus.DELETE_PENDING
		|| cyclotronProductsActionStatus === AsyncReducerStatus.DELETED
	) {
		return (
			<LoadingSpinner />
		);
	}

	if (cyclotronProduct === null ) {
		// Show up an error if the cyclotron product is not available
		throw new Error('Cyclotron product not found');
	}

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permissionUpdate: authContext.hasPermission(Permission.CYCLOTRON_PRODUCT_UPDATE),
		permissionDelete: authContext.hasPermission(Permission.CYCLOTRON_PRODUCT_DELETE)
	};

	const handleModalClick = (
		type: CyclotronProductViewModalType,
		payload?: CyclotronProductViewModel | DocumentViewModel
	) => {
		dispatch(resetCyclotronProductActionStatus());
		dispatch(resetDocumentsActionStatus());

		setModalType(type);
		setModalPayload(payload);
	};

	const handleDocumentCollectionFiltering = (id: string, state: boolean) => {
		setDocumentFilter({
			...documentFilter,
			[id]: state
		});
	};

	// Show modal
	const showModal = (): JSX.Element => {
		let modal: JSX.Element = null;

		switch (modalType) {
			case CyclotronProductViewModalType.EDIT:
				modal =
					<CyclotronProductUpdateModal
						cyclotronProduct={cyclotronProduct}
						onDismiss={handleDismiss}
					/>;
				break;

			case CyclotronProductViewModalType.DELETE:
				modal =
					<CyclotronProductDeleteModal
						cyclotronProduct={cyclotronProduct}
						onDismiss={handleDismiss}
					/>;
				break;

			case CyclotronProductViewModalType.COMPLETELY_APPLIED:
				modal =
					<CyclotronProductCompletelyAppliedModal
						cyclotronProduct={cyclotronProduct}
						onDismiss={handleDismiss}
					/>;
				break;

			case CyclotronProductViewModalType.UNDO_COMPLETELY_APPLIED:
				modal =
					<CyclotronProductUndoCompletelyAppliedModal
						cyclotronProduct={cyclotronProduct}
						onDismiss={handleDismiss}
					/>;
				break;

			case CyclotronProductViewModalType.TO_WASTE_MODULE:
				modal =
					<CyclotronProductToWasteModuleModal
						cyclotronProduct={cyclotronProduct}
						onDismiss={handleDismiss}
					/>;
				break;

			case CyclotronProductViewModalType.CREATE_DOCUMENT:
				modal =
					<DocumentAddModal
						onDismiss={handleDismiss}
						documentScope={DocumentModelScope.CYCLOTRON_PRODUCT}
						documentScopeReference={cyclotronProduct.Uuid}
					/>;
				break;

			case CyclotronProductViewModalType.EDIT_DOCUMENT_NAME:
				modal =
					<DocumentEditModal
						onDismiss={handleDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;

			case CyclotronProductViewModalType.DOWNLOAD_DOCUMENT:
				modal =
					<DocumentDownloadModal
						onDismiss={handleDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;

			case CyclotronProductViewModalType.DELETE_DOCUMENT:
				modal =
					<DocumentDeleteModal
						onDismiss={handleDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;

			case CyclotronProductViewModalType.ARCHIVE_DOCUMENT:
				modal =
					<DocumentArchiveModal
						onDismiss={handleDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;

			case CyclotronProductViewModalType.UNARCHIVE_DOCUMENT:
				modal =
					<DocumentUnarchiveModal
						onDismiss={handleDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
		}
		return modal;
	};

	const cyclotronProductCard =
		<CyclotronProductCard
			cyclotronProduct={cyclotronProduct}
			userPermissionEdit={user.permissionUpdate}
			onClick={() => handleModalClick(CyclotronProductViewModalType.EDIT)}
		/>;

	const toWasteManagementButton = cyclotronProduct.State === CyclotronProductModelState.ACTIVE ?
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.toWasteModule', 'An Abfallmodul')}
			icon={IconIdentifier.DISPATCH}
			onClick={() => handleModalClick(CyclotronProductViewModalType.TO_WASTE_MODULE)}
			disabled={!user.permissionUpdate}
		/> :
		null;

	const appliedButton =
	cyclotronProduct.Isotope === CyclotronProductIsotope.IODINE_131_I ?
		<ButtonSecondary
			buttonText={
				cyclotronProduct.State !== CyclotronProductModelState.COMPLETELY_APPLIED ?
					l10nContext.translate('common.button.completelyApplied', 'Vollständig applizieren') :
					l10nContext.translate('common.button.undoCompletelyApplied', 'Vollständig appliziert aufheben')
			}
			icon={IconIdentifier.FULLY_APPLIED}
			onClick={() => {
				cyclotronProduct.State !== CyclotronProductModelState.COMPLETELY_APPLIED ?
					handleModalClick(CyclotronProductViewModalType.COMPLETELY_APPLIED) :
					handleModalClick(CyclotronProductViewModalType.UNDO_COMPLETELY_APPLIED);
			}}
			disabled={!user.permissionUpdate}
		/> :
		null;

	const deleteButton =
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.delete', 'Löschen')}
			icon={IconIdentifier.TRASH}
			status={ButtonSecondaryStatus.ATTENTION}
			disabled={!user.permissionDelete}
			onClick={() => handleModalClick(CyclotronProductViewModalType.DELETE)}
		/>;

	const renderNotifications = (): Array<JSX.Element> => {
		const notifications = [];

		// Handle failed action
		if (cyclotronProductsActionStatus === AsyncReducerStatus.FAILED) {
			notifications.push(
				<NotificationBar
					message={cyclotronProductLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		if (documentStoreFailed) {
			notifications.push(
				<NotificationBar
					message={documentStoreLastActionError.message}
					level={NotificationLevel.LEVEL_ERROR}
				/>
			);
		}

		return notifications;
	};

	const renderDocuments = () => {
		return (
			<DocumentsCollection
				documents={documents}
				fetchStatus={documentStorePending}
				onAddDocument={() => handleModalClick(CyclotronProductViewModalType.CREATE_DOCUMENT)}
				addDocumentEnabled={authContext.hasPermission(Permission.DOCUMENT_CREATE)}
				onAction={(payload, document) => {
					switch (payload.type) {
						case CardDocumentAction.ARCHIVE:
							return handleModalClick(CyclotronProductViewModalType.ARCHIVE_DOCUMENT, document);

						case CardDocumentAction.UNARCHIVE:
							return handleModalClick(CyclotronProductViewModalType.UNARCHIVE_DOCUMENT, document);

						case CardDocumentAction.DELETE:
							return handleModalClick(CyclotronProductViewModalType.DELETE_DOCUMENT, document);

						case CardDocumentAction.DOWNLOAD:
							return handleModalClick(CyclotronProductViewModalType.DOWNLOAD_DOCUMENT, document);

						case CardDocumentAction.CHANGE:
							return handleModalClick(CyclotronProductViewModalType.EDIT_DOCUMENT_NAME, document);
						default:
							return null;
					}
				}}
				onFilterClick={handleDocumentCollectionFiltering}
			/>
		);
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs
						mapURLFragments={[[cyclotronProductUuid, cyclotronProduct.Name]]}
					/>
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection
				section={MainLayoutSectionSection.SECTION_MAIN}
			>
				<ViewLayout>

					<ViewLayoutSection>
						<NotificationBars>
							{renderNotifications()}
						</NotificationBars>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{showModal()}
						{cyclotronProductCard}
					</ViewLayoutSection>

					<ViewLayoutSection>
						{renderDocuments()}
						<Separator />
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ColumnLayout>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{toWasteManagementButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
								{appliedButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{deleteButton}
							</ColumnLayoutSection>
						</ColumnLayout>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>

		</MainLayout>
	);
};
