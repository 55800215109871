import { MiddlewarePersistence } from 'lib/persistence/MiddlewarePersistence';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import {
	FolderModelConverter as ModelConverter,
	FolderPersistenceModel as PersistenceModel,
	FolderStoreModel as StoreModel,
	FolderViewModel as ViewModel,
	CreatableFolderViewModel as CreatableViewModel
} from 'services/documents/domain/model/FolderModel';
import { FolderHttpPersistence as HttpPersistence } from 'services/documents/persistence/FolderHttpPersistence';

export class FolderPersistence
	// eslint-disable-next-line max-len
	extends MiddlewarePersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel, ModelConverter, HttpPersistence> {

	protected getHttpPersistence(): HttpPersistence {
		return new HttpPersistence(this.clientUuid, this.facilityUuid);
	}

	protected getModelConverter(): ModelConverter {
		return new ModelConverter();
	}

	protected async preCreate(creatableViewModel: Readonly<CreatableViewModel>, storeModel: StoreModel, local: boolean): Promise<void> {
		if (storeModel.Parent === storeModel.Uuid) {
			throw new PropellerError('COUD_NOT_MOVE_FOLDER_TO_ITSELF', '200');
		}
		return super.preCreate(creatableViewModel, storeModel, local);
	}

	protected async preUpdate(viewModel: Readonly<ViewModel>, storeModel: StoreModel, local: boolean): Promise<void> {
		if (storeModel.Parent === storeModel.Uuid) {
			throw new PropellerError('COUD_NOT_MOVE_FOLDER_TO_ITSELF', '200');
		}
		return super.preUpdate(viewModel, storeModel, local);
	}
}
