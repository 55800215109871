
import React, { FunctionComponent, useContext } from 'react';
import { L10nContext } from '../../../../../../context/L10nContext';
import { Guid } from '../../../../../../lib/guid/Guid';
import { AuthContext } from '../../../../../../services/core/context/AuthContext';
import { Permission } from '../../../../../../services/core/lib/auth/AuthService';
import { ContainerViewModel } from '../../../../../../services/nuclide/domain/model/ContainerModel';
import { ContainerModelState } from '../../../../../../services/nuclide/domain/model/ContainerModelState';
import { CyclotronProductViewModel } from '../../../../../../services/nuclide/domain/model/CyclotronProductModel';
import { EluatePayloadType } from '../../../../../../services/nuclide/domain/model/EluateModel';
import { GeneratorEluateIsotope } from '../../../../../../services/nuclide/domain/model/isotopes/GeneratorEluateIsotope';
import { getNuclideIsotopeDefinition } from '../../../../../../services/nuclide/domain/model/isotopes/NuclideIsotopeDefinition';
import {
	getCyclotronProductMeasurementUnitDefinition,
	getEluateMeasurementUnitDefinition,
} from '../../../../../../services/nuclide/domain/model/NuclideMeasurementUnitDefinition';

import { CardItemControls } from '../../../../partials/card/card-item-controls/card-item-controls/CardItemControls';
import { CardItemActionType } from '../../../../partials/card/card-item-controls/CardItemActionTypes';
import { CardItemControlState } from '../../../../partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from '../../../../partials/card/card-item-controls/CardItemControlType';
import { Checkbox } from '../../../../partials/input/checkbox/Checkbox';

import './card-nuclide-list-item.scss';

export interface CardNuclideListItemProps {
	nuclide: string,
	container?: ContainerViewModel
	nuclides: Array<EluatePayloadType | CyclotronProductViewModel>,
	selectedNuclides?: Array<string>,
	checkNuclide?: (uuid: string) => void,
	onActionClick?: (actionPayload: CardItemControlActionType, item: EluatePayloadType | CyclotronProductViewModel) => void
}

/**
 * The nuclide list item card component
 */
export const CardNuclideListItem: FunctionComponent<CardNuclideListItemProps> = (props): JSX.Element => {
	const { nuclide, nuclides, selectedNuclides, container, checkNuclide, onActionClick } = props;

	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);

	const eluateToActiveStatus = authContext.hasPermission(Permission.GENERATOR_ELUAT_UPDATE);
	const cyclotronProductToActiveStatus = authContext.hasPermission(Permission.CYCLOTRON_PRODUCT_UPDATE);

	const onCardItemControlClick = (action: CardItemControlActionType, item: EluatePayloadType | CyclotronProductViewModel) => {
		action.type === CardItemActionType.TO_ACTIVE_STATE ?
			onActionClick({
				...action,
				type: CardItemActionType.ACTIVE
			}, item) :
			onActionClick(action, item);
	};

	const isNuclideChecked = (uuid: string): boolean => {
		return selectedNuclides?.includes(uuid);
	};

	const renderNuclidesByIsotope = nuclides.map((item) => {
		if (item.Isotope === nuclide) {
			if (item.Isotope === GeneratorEluateIsotope.TECHNETIUM_99M_TC || item.Isotope === GeneratorEluateIsotope.GALLIUM_68_GA) {
				return (
					<div className="card-nuclide-list-item" key={Guid.generate()}>
						{checkNuclide ? <Checkbox
							checked={isNuclideChecked(item.Uuid)}
							onChange={() => checkNuclide(item.Uuid)}
							isReportSelection
							disabled={!authContext.hasPermission(Permission.WASTE_MANAGEMENT_UPDATE)}
						/> : null}
						<div className="card-nuclide-list-item__card">
							<div
								className="card-nuclide-list-item__card__content"
								role="button"
								tabIndex={0}
							>
								{l10nContext.translate('view.singleNuclides.eluateFrom', 'Eluat vom ')
									+ l10nContext.formatDateTime(item.ExtractionDatetime)
									+ ', '
									+ l10nContext.translate(getNuclideIsotopeDefinition(nuclide).getLabelKey())
									+ ', '
									+ l10nContext.translate('view.singleNuclides.activity', 'Aktivität')
									+ ' '
									+ l10nContext.formatNumber(item.Activity)
									+ ' '
									+ l10nContext.translate(getEluateMeasurementUnitDefinition(item.ActivityUnit).getLabelKey())}
								{container?.State !== ContainerModelState.DECAY &&
								container?.State !== ContainerModelState.RELEASE_DATE_REACHED &&
								container?.State !== ContainerModelState.DISPOSED &&
								container?.State !== ContainerModelState.DISPATCHED
									? <CardItemControls
										cardId={item.Uuid}
										actionToActiveState={CardItemControlState.ENABLED}
										disabled={!eluateToActiveStatus && !cyclotronProductToActiveStatus}
										onClick={() => onCardItemControlClick({
											type: CardItemActionType.TO_ACTIVE_STATE,
											cardId: ''
										}, item)}
									/> : null}
							</div>
						</div>
					</div>

				);
			}
			return (
				<div className="card-nuclide-list-item" key={Guid.generate()}>
					{checkNuclide ? <Checkbox
						checked={isNuclideChecked(item.Uuid)}
						onChange={() => checkNuclide(item.Uuid)}
						isReportSelection
						disabled={!authContext.hasPermission(Permission.WASTE_MANAGEMENT_UPDATE)}
					/> : null}
					<div className="card-nuclide-list-item__card">
						<div
							className="card-nuclide-list-item__card__content"
							role="button"
							tabIndex={0}
						>
							{item.Name
									+ ', '
									+ l10nContext.translate('view.singleNuclides.cyclotronProductFrom', ', Radionuklid vom ')
									+ l10nContext.formatDateTime(item.DeliveryDate)
									+ ', '
									+ l10nContext.translate(getNuclideIsotopeDefinition(nuclide).getLabelKey())
									+ l10nContext.translate('view.singleNuclides.nominalActivity', ', Nominalaktivität: ')
									+ l10nContext.formatNumber(item.NominalActivity)
									+ ' '
									+ l10nContext.translate(getCyclotronProductMeasurementUnitDefinition(item.ActivityUnit).getLabelKey())}
							{container?.State !== ContainerModelState.DECAY &&
							container?.State !== ContainerModelState.RELEASE_DATE_REACHED &&
							container?.State !== ContainerModelState.DISPOSED &&
							container?.State !== ContainerModelState.DISPATCHED
								? <CardItemControls
									cardId={item.Uuid}
									actionToActiveState={CardItemControlState.ENABLED}
									disabled={!eluateToActiveStatus && !cyclotronProductToActiveStatus}
									onClick={() => onCardItemControlClick({
										type: CardItemActionType.TO_ACTIVE_STATE,
										cardId: ''
									}, item)}
								/> : null}
						</div>
					</div>
				</div>

			);

		}
		return null;
	});

	return (
		<>
			{renderNuclidesByIsotope}
		</>
	);
};
