import { ComponentPropsWithoutRef } from 'react';

import './label.scss';

export interface LabelProps extends ComponentPropsWithoutRef<'label'> {
	label?: string;
	thin?: boolean;
	alignTop?: boolean;
}

export const Label = (props: LabelProps): JSX.Element => {
	const { label = null, thin = false, alignTop = false, ...restProps } = props;

	const thinClass = thin ?
		' label--thin' :
		'';

	const alignTopClass = alignTop ? 
		' label--align-top' :
		'';

	return (
		<label className={`label${thinClass}${alignTopClass}`} {...restProps}>
			<span className="label__labeltext">
				{props.label}
			</span>
			{props.children}
		</label>
	);
};
