import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

import { RecordStoreData, RecordViewData } from 'services/device/domain/business/common/record/RecordData';
import { DeviceType } from 'services/device/domain/business/inventory/DeviceType';
import { SequenceType } from 'services/device/domain/business/inventory/SequenceType';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { Valuation } from '../business/common/Valuation';
import { ViolationError } from '../business/common/violation/ViolationError';

export type RecordUuid = string;

export interface RecordPersistenceModel extends PersistenceModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: string;
	Documents: Array<string>;
	Sequence: string;
	SequenceType: string;
	Supplemented: boolean;
	Replaced: boolean;
	Replaces?: string;
	RecordedAt: number;
	RecordedByName: string;
	UpdatedByName?: string;
	RecordConfiguration: RecordStoreData;
	Valuation: Valuation;
}

export interface RecordStoreModel extends StoreModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: string;
	Documents: Array<string>;
	Sequence: string;
	SequenceType: string;
	Supplemented: boolean;
	Replaced: boolean;
	Replaces?: string;
	RecordedAt: number;
	RecordedByName: string;
	UpdatedByName?: string;
	RecordConfiguration: RecordStoreData;
	Valuation: Valuation;
}

export interface RecordViewModel extends ViewModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: DeviceType;
	Documents: Array<string>;
	Sequence: string;
	SequenceType: SequenceType;
	Supplemented: boolean;
	Replaced: boolean;
	Replaces?: string;
	RecordedAt: Date;
	RecordedByName: string;
	UpdatedByName?: string;
	RecordConfiguration: RecordViewData;
}

export interface CreatableRecordViewModel extends CreatableViewModel {
	Client: string;
	Facility: string;
	Device: string;
	DeviceType: DeviceType;
	Sequence: string;
	SequenceType: SequenceType;
	RecordedByName: string;
	UpdatedByName?: string;
	RecordConfiguration: RecordViewData;
	Valuation?: Valuation;
}

export class RecordModelConverter extends ModelConverter<RecordPersistenceModel, RecordStoreModel, RecordViewModel, CreatableRecordViewModel> {

	public fromStoreModel(storeModel?: RecordStoreModel): this {
		super.fromStoreModel(storeModel, (sModel, model) => {
			model.RecordedAt = Math.floor(sModel.RecordedAt / 1000);
		});

		return this;
	}

	public fromViewModel(viewModel?: RecordViewModel): this {
		super.fromViewModel(viewModel, (vModel, model) => {
			model.RecordConfiguration = getSequenceTypeDefinition(vModel.SequenceType).restoreRecordFromViewData(vModel.RecordConfiguration).toStoreData();
			model.RecordedAt = Math.floor(vModel.RecordConfiguration.values.recordedAt.getTime() / 1000);
			model.Valuation = vModel.RecordConfiguration.valuation;
		});

		return this;
	}

	fromCreatableViewModel(creatableViewModel?: CreatableRecordViewModel): this {
		return super.fromCreatableViewModel(creatableViewModel, (model) => {
			// Supplemented will be changed by the persistence
			model.Supplemented = false;
			// Replaced will be changed by the HTTP persistence
			model.Replaced = false;
			model.Valuation = model.RecordConfiguration.valuation;
		});
	}

	public toStoreModel(): RecordStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.RecordedAt = model.RecordedAt * 1000;
		});
	}

	public toViewModel(): RecordViewModel | null {
		throw new Error('Use toViewModelFromSequence instead');
	}

	public toViewModelFromSequence(sequenceViewModel: SequenceViewModel): RecordViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.DeviceType = model?.DeviceType as DeviceType ?? null;
			vModel.SequenceType = model?.SequenceType as SequenceType ?? null;
			vModel.RecordedAt = new Date(model.RecordedAt * 1000);

			try {
				const sequenceTypeDefinition = getSequenceTypeDefinition(vModel.SequenceType);
				const sequence = sequenceTypeDefinition.restoreSequenceFromViewData(sequenceViewModel.SequenceConfiguration);
				const recordDefinition = sequenceTypeDefinition.getRecordDefinition(sequence, sequenceViewModel.SequenceMemory);
				vModel.RecordConfiguration = recordDefinition.restoreRecordFromStoreData(model.RecordConfiguration).toViewData();
			} catch (e) {
				if (e instanceof ViolationError) {
					console.warn('Record violation', vModel.Uuid, e.getViolations());
				}
			}
		});
	}

}
