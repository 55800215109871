/* eslint-disable max-classes-per-file */

export enum Interval {

	NONE = 'NONE',

	DAILY = 'DAILY',

	WEEKLY = 'WEEKLY',

	TWO_WEEKLY = 'TWO_WEEKLY',

	MONTHLY = 'MONTHLY',

	QUARTER_YEARLY = 'QUARTER_YEARLY',

	HALF_YEARLY = 'HALF_YEARLY',

	YEARLY = 'YEARLY',

	LATCHED_MONTHLY = 'LATCHED_MONTHLY',

	LATCHED_QUARTER_YEARLY = 'LATCHED_QUARTER_YEARLY',

	LATCHED_HALF_YEARLY = 'LATCHED_HALF_YEARLY',

	LATCHED_YEARLY = 'LATCHED_YEARLY'

}