import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { L10nContext } from '../../../../../../context/L10nContext';
import {
	CardWasteManagementReportListItem
} from '../../../../../../presentation/ui/components/cards/card-waste-management-report/card-waste-management-report-list-item/CardWasteManagementReportListItem';
import {
	CardItemSingleControl
} from '../../../../../../presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from '../../../../../../presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemHeader } from '../../../../../../presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { Route } from '../../../../../../router/Route';
import { WasteManagementReportViewModel } from '../../../../domain/models/WasteManagementReportModel';

export interface WasteManagementGeneratorReportCollectionItemProps {
	wasteManagementReport: WasteManagementReportViewModel
}

export const WasteManagementReportCollectionItem = (props: WasteManagementGeneratorReportCollectionItemProps): JSX.Element => {

	const { wasteManagementReport } = props;

	const l10nContext = useContext(L10nContext);

	const history = useHistory();

	const validUntilFormatted = l10nContext.translate('reports.validUntil', 'Gültig bis: ' ) + ': ' + l10nContext.formatDate(wasteManagementReport?.ValidUntil);

	return (
		<CardWasteManagementReportListItem
			key={wasteManagementReport.Uuid}
		>
			<CardItemHeader
				title={wasteManagementReport.Name}
				subtitle={validUntilFormatted}
			/>
			<CardItemSingleControl
				cardId={wasteManagementReport.Uuid}
				actionDetails={CardItemControlState.ENABLED}
				onClick={() => history.push(`${Route.NUCLIDE_MANAGEMENT}${Route.WASTE_REPORTS}/${wasteManagementReport.Uuid}`)}
			/>

		</CardWasteManagementReportListItem>
	);
};
