import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { SequenceCollectionItem } from 'services/device/presentation/ui/card-collections/sequence-collection/SequenceCollectionItem';

import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { mapTypeToIlluIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifierMapper';
import { getDeviceTypeDefinition } from 'services/device/domain/business/inventory/DeviceTypeDefinition';

import './card-clearance-dashboard.scss';

export interface CardClearanceWithMeasurementGoalsProps {
	device: DeviceViewModel;
	sequences: Array<SequenceViewModel>
	route: string;
	onAddRecordClick: (sequence: SequenceViewModel) => void;
	illustration?: boolean;
}

/**
 * The card device dashboard component
 */
export const CardClearanceWithMeasurementGoals = (props: CardClearanceWithMeasurementGoalsProps): JSX.Element => {
	const { device, sequences, route, onAddRecordClick, illustration } = props;

	// Consume the contexts
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	const renderSequenceCards = (): JSX.Element => {
		if (sequences.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.sequence')} />
			);
		}

		return (
			<>
				{
					sequences.map((sequence) => (
						<SequenceCollectionItem
							key={sequence.Uuid}
							device={device}
							sequence={sequence}
							onClick={() => history.push(`${route}/${device.Uuid}${Route.MEASUREMENT_GOALS}/${sequence.Uuid}`)}
							onAddRecord={() => onAddRecordClick(sequence)}
						/>
					))
				}
			</>
		);
	};

	const expanded: boolean = false;
	const deviceTypeDefinition = getDeviceTypeDefinition(device.Type);

	return (
		<div className='card-clearance-dashboard'>
			<div className='card-clearance-dashboard__content'>
				<CardItemHeader
					title={device.Name}
					subtitle={l10nContext.translate(deviceTypeDefinition.getLabelKey())}
					illustration={illustration ? mapTypeToIlluIdentifier(device.Type) : null}
				/>
				<CardItemSingleControl
					cardId={device.Uuid}
					actionDetails={CardItemControlState.ENABLED}
					onClick={() => history.push(`${route}/${device.Uuid}`)}
				/>
				<CardCollectionLayout>
					<Expandable expanded={expanded} mode="slim">
						<ExpandableHeader caption={l10nContext.translate('view.sequence.headline.records', 'Messungen')} />
						<ExpandableBody>
							<CardCollectionLayout>
								{renderSequenceCards()}
							</CardCollectionLayout>
						</ExpandableBody>
					</Expandable>
				</CardCollectionLayout>
			</div>
		</div>
	);
};
