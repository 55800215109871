import { FunctionComponent } from 'react';

import './navigation-secondary.scss';

/**
 * Secondary navigation component
 */

export const NavigationSecondary: FunctionComponent = ({ children }): JSX.Element => {
	return (
		<div className='navigation-secondary'>
			{children}
		</div>
	);
};
