import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentMoveForm } from 'services/documents/presentation/ui/document-action/document-move-form/DocumentMoveForm';
import { DocumentMoveFailedNotification } from 'services/documents/presentation/ui/document-action/document-move-modal/DocumentMoveFailedNotification';
import { DocumentMoveSuccessNotification } from 'services/documents/presentation/ui/document-action/document-move-modal/DocumentMoveSuccessNotification';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

export interface DocumentMoveModalProps {
	document: DocumentViewModel;
	onDismiss?: () => void;
}

export const DocumentMoveModal = (props: DocumentMoveModalProps): JSX.Element => {
	const { document, onDismiss } = props;

	const l10nContext = useContext(L10nContext);

	const documentsActionStatus = useTypedSelector(state => state.documents.actionStatus);

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (documentsActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent = <DocumentMoveForm
					document={document}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modalContent = <DocumentMoveSuccessNotification
					document={document}
					onClickConfirm={onDismiss}
				/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <DocumentMoveFailedNotification onDismiss={onDismiss} />;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue onDismiss={onDismiss ?? null}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.documents.modal.action.moveDocument.caption', 'Dokument verschieben')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModalContent()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
