import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop: FunctionComponent = (props): JSX.Element => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return <>{props.children}</>;
};
