import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';

import {
	MaintenanceLogEntryModelConverter as ModelConverter,
	MaintenanceLogEntryStoreModel as StoreModel,
	MaintenanceLogEntryViewModel as ViewModel
} from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';
import {
	ExternalReportMaintenanceLogEntryHttpPersistence
} from '../../report/persistence/ExternalReportMaintenanceLogEntryHttpPersistence';

export interface ExternalReportMaintenanceLogEntryState {
	externalReportMaintenanceLogEntries: Array<StoreModel>;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	externalReportMaintenanceLogEntries: [] as Array<StoreModel>,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as ExternalReportMaintenanceLogEntryState;

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
export const fetchExternalReportMaintenanceLogEntries = createAsyncThunk(
	'external-report-maintenance-log-entries/fetch',
	async (params: { reportUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new ExternalReportMaintenanceLogEntryHttpPersistence(params.reportUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (params, { getState }): boolean => {
			// Sliently abort the action
			const { externalReportMaintenanceLogEntries } = getState() as RootState;
			return checkFetchStatus(externalReportMaintenanceLogEntries.fetchStatus);
		}
	}
);

// Slice definition
export const externalReportMaintenanceLogEntrySlice = createSlice({
	name: 'externalReportMaintenanceLogEntries',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchExternalReportMaintenanceLogEntries.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchExternalReportMaintenanceLogEntries.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.externalReportMaintenanceLogEntries = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchExternalReportMaintenanceLogEntries.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		}
	}
});

export const { resetState, resetActionStatus } = externalReportMaintenanceLogEntrySlice.actions;

// Export the reducer as default
export default externalReportMaintenanceLogEntrySlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectExternalReportMaintenanceLogEntriesByFacility = (): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.externalReportMaintenanceLogEntries.externalReportMaintenanceLogEntries.filter((storeModel): boolean => {

			return storeModel.Device === null;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectExternalReportMaintenanceLogEntriesByDevice = (deviceUuid: string): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const storeModels = rootState.externalReportMaintenanceLogEntries.externalReportMaintenanceLogEntries.filter((storeModel): boolean => {
			return storeModel.Device === deviceUuid;
		}) ?? [];
		const viewModels = storeModels.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};
