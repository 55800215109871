import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DebugConsole } from 'lib/debug/DebugConsole';
import { PropellerError } from 'lib/persistence/http/error/PropellerError';
import { ModelQueryOrderClosureBuilder } from 'lib/persistence/idb/query/ModelQueryOrderClosureBuilder';
import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { RootState } from 'store/store';

import { WasteManagementReportHttpPersistence as HttpPersistence } from 'services/waste-management-report/persistence/WasteManagementReportHttpPersistence';
import {
	CreatableWasteManagementReportViewModel as CreatableViewModel,
	WasteManagementReportModelConverter as ModelConverter,
	WasteManagementReportStoreModel as StoreModel,
	WasteManagementReportViewModel as ViewModel,
} from 'services/waste-management-report/domain/models/WasteManagementReportModel';
import { checkFetchStatus } from '../../../store/common/AsyncFetchStatus.util';

// Declare a waste management report state type
export interface WasteManagementReportState {
	wasteManagementReports: Array<StoreModel>;
	createdWasteManagementReport: StoreModel | null;
	fetchStatus: AsyncFetchStatus;
	lastFetchError: Error | PropellerError | null;
	actionStatus: AsyncReducerStatus;
	lastActionError: Error | PropellerError | null;
}

// The initial state
const initialState = {
	wasteManagementReports: [] as Array<StoreModel>,
	createdWasteManagementReport: null,
	fetchStatus: AsyncFetchStatus.INITIAL,
	lastFetchError: null,
	actionStatus: AsyncReducerStatus.IDLE,
	lastActionError: null
} as WasteManagementReportState;

export const fetchWasteManagementReports = createAsyncThunk(
	'waste-management-reports/fetch',
	async (params: { clientUuid: string; facilityUuid: string }): Promise<Array<StoreModel>> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.fetchCollection();
	},
	{
		condition: (_params, { getState }): boolean => {
			// Sliently abort the action
			const { wasteManagementReports } = getState() as RootState;
			return checkFetchStatus(wasteManagementReports.fetchStatus);
		}
	}
);

export const updateWasteManagementReport = createAsyncThunk(
	'waste-management-report/update',
	async (params: { clientUuid: string; facilityUuid: string, viewModel: ViewModel }): Promise<StoreModel> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.update(new ModelConverter().fromViewModel(params.viewModel).toStoreModel());
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { wasteManagementReports } = getState() as RootState;
			if (wasteManagementReports.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const createWasteManagementReport = createAsyncThunk(
	'waste-management-report/create',
	async (params: { clientUuid: string; facilityUuid: string, viewModel: CreatableViewModel }): Promise<StoreModel> => {

		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.create(new ModelConverter().fromCreatableViewModel(params.viewModel).toStoreModel());
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { wasteManagementReports } = getState() as RootState;
			if (wasteManagementReports.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

export const deleteWasteManagementReport = createAsyncThunk(
	'waste-management-report/delete',
	async (params: { clientUuid: string; facilityUuid: string, viewModel: ViewModel }): Promise<StoreModel> => {
		const persistence = new HttpPersistence(params.clientUuid, params.facilityUuid);
		return persistence.delete(new ModelConverter().fromViewModel(params.viewModel).toStoreModel());
	},
	{
		condition: (_params, { getState }): boolean => {
			// Abort the action with an error
			const { wasteManagementReports } = getState() as RootState;
			if (wasteManagementReports.actionStatus !== AsyncReducerStatus.IDLE) {
				throw new Error('Action not available');
			}
			return true;
		}
	}
);

// Slice definition
export const wasteManagementReportSlice = createSlice({
	name: 'wasteManagementReports',
	initialState,
	// Regular synchronous reducers
	reducers: {
		resetState(state) {
			Object.assign(state, initialState);
		},
		resetActionStatus(state) {
			state.actionStatus = AsyncReducerStatus.IDLE;
		}
	},
	// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
	// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally handled by
	// Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and https://github.com/immerjs/immer.
	extraReducers: {
		[String(fetchWasteManagementReports.pending)]: (state) => {
			if (state.fetchStatus === AsyncFetchStatus.INITIAL) {
				state.fetchStatus = AsyncFetchStatus.INITIAL_PENDIG;
			} else {
				state.fetchStatus = AsyncFetchStatus.PENDING;
			}
		},
		[String(fetchWasteManagementReports.fulfilled)]: (state, action: PayloadAction<Array<StoreModel>>) => {
			state.wasteManagementReports = action.payload;
			state.fetchStatus = AsyncFetchStatus.SUCCESS;
		},
		[String(fetchWasteManagementReports.rejected)]: (state, action) => {
			state.fetchStatus = AsyncFetchStatus.FAILED;
			state.lastFetchError = action.error;
		},
		[String(updateWasteManagementReport.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.UPDATE_PENDING;
		},
		[String(updateWasteManagementReport.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.wasteManagementReports.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.wasteManagementReports[index] = action.payload;
			}
			state.wasteManagementReports = state.wasteManagementReports.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.actionStatus = AsyncReducerStatus.UPDATED;
		},
		[String(updateWasteManagementReport.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(createWasteManagementReport.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.CREATE_PENDING;
			state.createdWasteManagementReport = null;
		},
		[String(createWasteManagementReport.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			state.wasteManagementReports.push(action.payload);
			state.wasteManagementReports = state.wasteManagementReports.sort(new ModelQueryOrderClosureBuilder<StoreModel>().build('Name'));
			state.createdWasteManagementReport = action.payload;
			state.actionStatus = AsyncReducerStatus.CREATED;
		},
		[String(createWasteManagementReport.rejected)]: (state, action) => {
			state.lastActionError = action.error;
			state.actionStatus = AsyncReducerStatus.FAILED;
		},
		[String(deleteWasteManagementReport.pending)]: (state) => {
			state.actionStatus = AsyncReducerStatus.DELETE_PENDING;
		},
		[String(deleteWasteManagementReport.fulfilled)]: (state, action: PayloadAction<StoreModel>) => {
			const index = state.wasteManagementReports.findIndex((entry): boolean => {
				return entry.Uuid === action.payload.Uuid;
			}) ?? null;
			if (index !== null) {
				state.wasteManagementReports.splice(index, 1);
			}
			state.actionStatus = AsyncReducerStatus.DELETED;
		},
		[String(deleteWasteManagementReport.rejected)]: (state, action) => {
			state.actionStatus = AsyncReducerStatus.FAILED;
			state.lastActionError = action.error.message;
		},

	}
});

export const { resetState, resetActionStatus } = wasteManagementReportSlice.actions;

// Export the reducer as default
export default wasteManagementReportSlice.reducer;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
export const selectWasteManagementReports = (): (rootState: RootState) => ReadonlyArray<ViewModel> => {
	return (rootState: RootState): ReadonlyArray<ViewModel> => {
		const viewModels = rootState.wasteManagementReports.wasteManagementReports.map((storeModel): ViewModel | null => {
			try {
				return new ModelConverter().fromStoreModel(storeModel).toViewModel();
			} catch (error) {
				DebugConsole.error(error);
				return null;
			}
		});
		return viewModels.filter((viewModel) => {
			return viewModel !== null;
		});
	};
};

export const selectWasteManagementReportByUuid = (uuid: string): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.wasteManagementReports.wasteManagementReports.find((sModel): boolean => {
			return sModel.Uuid === uuid;
		}) ?? null;
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};

export const selectCreatedWasteManagementReport = (): (rootState: RootState) => ViewModel | null => {
	return (rootState: RootState): ViewModel | null => {
		const storeModel = rootState.wasteManagementReports.createdWasteManagementReport;
		if (storeModel === null) {
			return null;
		}
		try {
			return new ModelConverter().fromStoreModel(storeModel).toViewModel();
		} catch (error) {
			DebugConsole.error(error);
			return null;
		}
	};
};
