import { useContext } from 'react';
import { L10nContext } from 'context/L10nContext';
import { useHistory } from 'react-router-dom';

import { AuthContext } from 'services/core/context/AuthContext';
import { FacilityContext } from 'services/core/context/FacilityContext';

import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ErrorPageLayout } from 'presentation/ui/layouts/error-page-layout/ErrorPageLayout';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';

import { Route } from '../../router/Route';
import { SimpleViewTitle } from '../../services/core/presentation/ui/simple-view-title/SimpleViewTitle';
import { ButtonPrimary } from '../ui/partials/button/button-primary/ButtonPrimary';


export const PageErrorView = (): JSX.Element => {

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);
	const history = useHistory();

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs />
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ErrorPageLayout>
					<ViewLayoutSection>
						<SimpleViewTitle label={l10nContext.translate('view.pageErrorView.title', 'Diese Seite existiert nicht')} />
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ButtonPrimary
							buttonText={l10nContext.translate('view.pageErrorView.buttonLabel', 'Zum Dashboard')}
							onClick={() => { history.push(Route.DASHBOARD);}}
						/>
					</ViewLayoutSection>

				</ErrorPageLayout>

			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
