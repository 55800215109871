import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';

import {
	DocumentModelConverter as ModelConverter,
	DocumentPersistenceModel as PersistenceModel,
	DocumentStoreModel as StoreModel,
	DocumentViewModel as ViewModel,
	CreatableDocumentViewModel as CreatableViewModel
} from 'services/documents/domain/model/DocumentModel';
import { ExternalHttpPersistence } from '../../../lib/persistence/http/ExternalHttpPersistence';

export class ExternalReportDocumentHttpPersistence extends ExternalHttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {

	protected serviceApiControllerUri = 'document/report-api/v1';
	protected collectionControllerUri = 'documents';
	protected entityControllerUri = 'document';

	protected responseCollectionKey = 'DocumentModelCollection';
	protected responseEntityKey = 'DocumentModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

}
