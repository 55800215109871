import { L10nContext } from 'context/L10nContext';
import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { useContext } from 'react';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { ContainerNuclidesAssignForm } from '../container-nuclides-assign-form/ContainerNuclidesAssignForm';
import { ContainerNuclidesAssignFailedNotification } from './ContainerNuclidesAssignFailedNotification';
import { ContainerNuclidesAssignSuccessNotification } from './ContainerNuclidesAssignSuccessNotification';

interface ContainerNuclidesAssignModalProps {
	selectedNuclides: Array<string>,
	onSetSelectedNuclides: () => void,
	onDismiss: () => void;
}

export const ContainerNuclidesAssignModal = (props: ContainerNuclidesAssignModalProps): JSX.Element => {
	const { selectedNuclides, onSetSelectedNuclides, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	const containerActionStatus = useTypedSelector(state => state.containers.actionStatus);

	const renderModal = () => {
		let modal: JSX.Element = null;

		switch (containerActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <ContainerNuclidesAssignForm
					selectedNuclides={selectedNuclides}
					onSetSelectedNuclides={onSetSelectedNuclides}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modal =
					<ContainerNuclidesAssignSuccessNotification
						onClickConfirm={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<ContainerNuclidesAssignFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;

	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.singleNuclides.modal.assignNuclidesToContainers.selectOpenContainer', 'Offenes Gebinde auswählen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
