import { Route as RouteComponent, Switch } from 'react-router-dom';

import { Route } from 'router/Route';
import { Navigation } from '../presentation/ui/components/navigation/navigation-primary/Navigation';
import {
	StoreWarmupExternalWasteManagementReport
} from '../presentation/ui/compositions/store-warmup-external-waste-management-report/StoreWarmupExternalWasteManagementReport';
import { StoreWarmup } from '../presentation/ui/compositions/store-warmup/StoreWarmup';
import { StoreWarmupExternalReport } from '../presentation/ui/compositions/store-warmup-external-report/StoreWarmupExternalReport';
import { RootLayoutSection } from '../presentation/ui/layouts/root-layout/root-layout-section/RootLayoutSection';
import { RootLayout } from '../presentation/ui/layouts/root-layout/RootLayout';
import { AuthContextProvider } from '../services/core/context/AuthContextProvider';
import { ClientContextProvider } from '../services/core/context/ClientContextProvider';
import { ExternalAuthContextProvider } from '../services/core/context/ExternalAuthContextProvider';
import {
	ExternalWasteManagementReportAuthContextProvider
} from '../services/core/context/ExternalWasteManagementReportAuthContextProvider';
import { FacilityContextProvider } from '../services/core/context/FacilityContextProvider';
import { RouterConfig } from './RouterConfig';
import { RouterExternalConfig } from './RouterExternalConfig';
import { RouterExternalWasteManagementConfig } from './RouterExternalWasteManagementConfig';

export const RouterAccessGroupConfig = (): JSX.Element => {

	return (
		<Switch>
			<RouteComponent path={`${Route.EXTERNAL_REPORT}/:reportUuid`}>
				<ExternalAuthContextProvider>
					<StoreWarmupExternalReport>
						<RootLayout>
							<RootLayoutSection>
								<RouterExternalConfig />
							</RootLayoutSection>
						</RootLayout>
					</StoreWarmupExternalReport>
				</ExternalAuthContextProvider>
			</RouteComponent>

			<RouteComponent path={`${Route.EXTERNAL_WASTE_MANAGEMENT_REPORT}/:reportUuid`}>
				<ExternalWasteManagementReportAuthContextProvider>
					<StoreWarmupExternalWasteManagementReport>
						<RootLayout>
							<RootLayoutSection>
								<RouterExternalWasteManagementConfig />
							</RootLayoutSection>
						</RootLayout>
					</StoreWarmupExternalWasteManagementReport>
				</ExternalWasteManagementReportAuthContextProvider>
			</RouteComponent>

			<RouteComponent path="*">
				<AuthContextProvider>
					<ClientContextProvider>
						<FacilityContextProvider>
							<StoreWarmup>
								<RootLayout>
									<RootLayoutSection>
										<Navigation />
										<RouterConfig />
									</RootLayoutSection>
								</RootLayout>
							</StoreWarmup>
						</FacilityContextProvider>
					</ClientContextProvider>
				</AuthContextProvider>
			</RouteComponent>
		</Switch>
	);

};
