import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

export type RoleModelUuid = string;

export interface RolePersistenceModel extends PersistenceModel {
	Uuid: string;
	Name: string;
	Description?: string;
}

export interface RoleStoreModel extends StoreModel {
	Uuid: string;
	Name: string;
	Description?: string;
}

export interface RoleViewModel extends ViewModel {
	Uuid: string;
	Name: string;
	Description?: string;
}

export class RoleModelConverter extends ModelConverter<RolePersistenceModel, RoleStoreModel, RoleViewModel, CreatableViewModel> {
}
