import { L10n } from 'lib/l10n/L10n';
import {
	BooleanOutput,
	CommentOutput,
	DateTimeOutput,
	IntervalOutput,
	MailAddressOutput,
	NumberOutput,
	OutputSelection,
	StringOutput,
	TextOutput,
	UnitOutput,
	UrlOutput,
	ValuedStringOutput
} from 'services/device/domain/business/common/Output';
import { Valuation } from '../../../../domain/business/common/Valuation';
import { Interval } from '../../../../domain/business/inventory/Interval';
import { getIntervalDefinition } from '../../../../domain/business/inventory/IntervalDefinition';
import { getUnitDefinition } from '../../../../domain/business/inventory/UnitDefinition';

export class StringOutputListSelection implements OutputSelection<void> {

	private readonly onElement: (element: string) => void;

	public constructor(onElement: (element: string) => void) {
		this.onElement = onElement;
	}

	onUnitOutput(output: UnitOutput): void {
		const unit = output.getSingle() ?? null;
		const value = unit !== null ? getUnitDefinition(unit).getSymbol() : '';
		this.onElement(value);
	}

	onDateTimeOutput(output: DateTimeOutput): void {
		this.onElement(L10n.formatDateTime(output.getSingle()));
	}

	onStringOutput(output: StringOutput): void {
		this.onElement(output.getSingle());
	}

	onNumberOutput(output: NumberOutput): void {
		this.onElement(L10n.formatNumber(output.getSingle(), output.getNumberOfDecimals()));
	}

	onBooleanOutput(output: BooleanOutput): void {
		const value = output.get()[0]
			? L10n.translate('common.inputs.boolean.true')
			: L10n.translate('common.inputs.boolean.false');
		this.onElement(value);
	}

	onMailAddressOutput(output: MailAddressOutput): void {
		this.onElement(output.get()?.join(', ') ?? '');
	}

	onIntervalOutput(output: IntervalOutput): void {
		const interval = output.getSingle() ?? Interval.NONE;
		const intervalLabelKey = getIntervalDefinition(interval).getLabelKey();
		this.onElement(L10n.translate(intervalLabelKey, String(interval)));
	}

	onTextOutput(output: TextOutput): void {
		this.onElement(output.getSingle() ?? '');
	}

	onUrlOutput(output: UrlOutput): void {
		this.onElement(output.getSingle() ?? '');
	}

	onCommentOutput(output: CommentOutput): void {
		this.onElement(output.getSingle() ?? '');
	}

	onValuedStringOutput(output: ValuedStringOutput): void {
		const acceptableOptions = [];
		for (const item of output.get()) {
			if (item.valuation === Valuation.ACCEPTABLE)
				acceptableOptions.push(item.value);
		}

		const unacceptableOptions = [];
		for (const item of output.get()) {
			if (item.valuation === Valuation.UNACCEPTABLE)
				unacceptableOptions.push(item.value);
		}

		const repeatableOutput = L10n.translate('common.inputs.options.acceptable', 'gültig') + ': '
			+ acceptableOptions.join(', ') + '; '
			+ L10n.translate('common.inputs.options.unacceptable', 'ungültig') + ': '
			+ unacceptableOptions.join(', ');
		this.onElement(output.isRepeatable() ? repeatableOutput : output.getSingle()?.value);
	}

}
