import { Link } from 'react-router-dom';

import './text-link.scss';

export interface TextLinkProps {
	/**
	* Link text
	*/
	text: string;
	/**
	 * Link target
	 */
	target: string;
}

export const TextLink = (props: TextLinkProps): JSX.Element => (
	<Link
		to={props.target}
		className='text-link'
	>
		{props.text}
	</Link>
);
