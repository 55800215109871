import { CardContainerListItem } from 'presentation/ui/components/cards/card-container/card-container-list-item/CardContainerListItem';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ContainerViewModel } from 'services/nuclide/domain/model/ContainerModel';
import { L10nContext } from '../../../../../../context/L10nContext';
import {
	CardItemSingleControl
} from '../../../../../../presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from '../../../../../../presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemHeader } from '../../../../../../presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { CardItemState } from '../../../../../../presentation/ui/partials/card/card-item-state/CardItemState';
import { CardItemTagType } from '../../../../../../presentation/ui/partials/card/CardItemTagType';
import { IllustrationIdentifier } from '../../../../../../presentation/ui/partials/illustration/IllustrationIdentifier';
import { TagType } from '../../../../../../presentation/ui/partials/tag/Tag';
import { Route } from '../../../../../../router/Route';
import { useTypedSelector } from '../../../../../../store/common/TypedSelector';
import { ClientContext } from '../../../../../core/context/ClientContext';
import { FacilityContext } from '../../../../../core/context/FacilityContext';
import { ContainerModelState } from '../../../../domain/model/ContainerModelState';
import { CyclotronProductViewModel } from '../../../../domain/model/CyclotronProductModel';
import { GeneratorEluateIsotope } from '../../../../domain/model/isotopes/GeneratorEluateIsotope';
import { GeneratorTypeIsotope } from '../../../../domain/model/isotopes/GeneratorTypeIsotope';
import { getNuclideIsotopeDefinition } from '../../../../domain/model/isotopes/NuclideIsotopeDefinition';
import { selectInWastemanagementCyclotronProducts } from '../../../../store/cyclotronProductSlice';
import { selectInWastemanagementEluates } from '../../../../store/eluateSlice';
import { selectGenerators } from '../../../../store/generatorSlice';
import { EluatePayloadType } from '../../../view/SingleNuclidesView';

export interface ContainerCollectionItemProps {
	container: ContainerViewModel
}

export const ContainerCollectionItem = (props: ContainerCollectionItemProps): JSX.Element => {
	const { container } = props;

	const history = useHistory();
	const l10nContext = useContext(L10nContext);
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const inWastemanagementCyclotronProducts = useTypedSelector(selectInWastemanagementCyclotronProducts(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	const inWastemanagementEluates = useTypedSelector(selectInWastemanagementEluates());

	const generators = useTypedSelector(selectGenerators(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid
	));

	inWastemanagementEluates.forEach(eluate => {

		const inWastemanagementEluateGenerator = generators.find((generator) => {
			return generator.Uuid === eluate.Generator;
		});
		if (inWastemanagementEluateGenerator.Isotope === GeneratorTypeIsotope.MOLYBDENUM_99_MO) {
			eluate.Isotope = GeneratorEluateIsotope.TECHNETIUM_99M_TC;
		} else {
			eluate.Isotope = GeneratorEluateIsotope.GALLIUM_68_GA;
		}
	});

	const inWastemanagementNuclides: Array<EluatePayloadType | CyclotronProductViewModel> =
		[].concat(inWastemanagementEluates, inWastemanagementCyclotronProducts);

	let containerNuclideIds: string[] = [];
	containerNuclideIds = [].concat(container.GeneratorEluatIds, container.CyclotroneProductIds);

	const containerNuclides = inWastemanagementNuclides.filter(nuclideModel => containerNuclideIds.includes(nuclideModel.Uuid));

	const subtitleContainerNuclides: string[] = [];
	if (containerNuclides.length > 0) {
		containerNuclides.map((nuclide) => {
			if (!subtitleContainerNuclides.includes(l10nContext.translate(getNuclideIsotopeDefinition(nuclide.Isotope).getLabelKey()))) {
				subtitleContainerNuclides.push(l10nContext.translate(getNuclideIsotopeDefinition(nuclide.Isotope).getLabelKey()));
			}
			return subtitleContainerNuclides.sort();
		});
	}

	const showTag = (): CardItemTagType => {
		let tag: CardItemTagType = null;

		switch (container.State) {
			case ContainerModelState.OPEN:
				tag = {
					label: l10nContext.translate('common.tags.open', 'Offen'),
					type: TagType.NEGATIVE
				};
				break;
			case ContainerModelState.DECAY:
				tag = {
					label: l10nContext.translate('common.tags.decay', 'Abklingend'),
					type: TagType.NEUTRAL
				};
				break;
			case ContainerModelState.RELEASE_DATE_REACHED:
				tag = {
					label: l10nContext.translate('common.tags.approved', 'Freigegeben'),
					type: TagType.POSITIVE
				};
				break;
			case ContainerModelState.DISPATCHED:
				tag = {
					label: l10nContext.translate('common.tags.dispatched', 'Abgegeangen'),
					type: TagType.NEUTRAL
				};
				break;
			case ContainerModelState.DISPOSED:
				tag = {
					label: l10nContext.translate('common.tags.disposed', 'Entsorgt'),
					type: TagType.NEUTRAL
				};
				break;
		}
		return tag;
	};

	const formatedReleaseDate = l10nContext.translate('containers.releaseDateTime', 'Freigabedatum') + ' ' + l10nContext.formatDateTime(container.ReleaseDateTime);

	return (
		<CardContainerListItem
			key={container.Uuid}
		>
			<CardItemHeader
				title={container.Name + l10nContext.translate('common.filter.ac', ' vom ') + l10nContext.formatDateTime(container.CreatedAt)}
				subtitle={subtitleContainerNuclides.join(', ')}
				illustration={IllustrationIdentifier.WASTE_MANAGEMENT}
			/>
			<CardItemSingleControl
				cardId={container.Uuid}
				actionDetails={CardItemControlState.ENABLED}
				onClick={() => history.push(`${Route.NUCLIDE_MANAGEMENT}${Route.WASTE_MANAGEMENT}${Route.CONTAINERS}/${container.Uuid}`)}
			/>
			<CardItemState
				date={container.State !== ContainerModelState.OPEN ? formatedReleaseDate : null}
				tag={showTag()}
			/>
		</CardContainerListItem>
	);
};
