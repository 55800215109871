import { TextOutput } from 'services/device/domain/business/common/Output';

export interface TextOutputValueProps {
	output: TextOutput;
}

export const TextOutputValueRenderer = (props: TextOutputValueProps): JSX.Element => {
	const { output } = props;

	return (
		<span>
			{output.getSingle() ?? ''}
		</span>
	);

};
