import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { RecordViewModel } from 'services/device/domain/model/RecordModel';
import { RecordDeleteForm } from 'services/device/presentation/ui/record-action/record-delete-form/RecordDeleteForm';
import { RecordDeleteFailedNotification } from 'services/device/presentation/ui/record-action/record-delete-modal/RecordDeleteFailedNotification';
import { deleteRecord } from 'services/device/store/recordSlice';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';

export interface RecordDeleteModalProps {
	record: RecordViewModel;
	onDismiss?: () => void;
}

export const RecordDeleteModal = (props: RecordDeleteModalProps): JSX.Element => {
	const { record, onDismiss } = props;

	const deviceActionStatus = useTypedSelector(state => state.records.actionStatus);

	const dispatch = useDispatch();

	const handleDelete = (): void => {
		dispatch(deleteRecord(record));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (deviceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<RecordDeleteForm
						record={record}
						onDelete={handleDelete}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <RecordDeleteFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
