import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { MaintenanceLogModalType } from 'services/maintenance-log/domain/type/MaintenanceLogModalModel';
import {
	MaintenanceLogCreateModal,
} from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-create-modal/MaintenanceLogCreateModal';
import {
	MaintenanceLogDeleteModal,
} from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-delete-modal/MaintenanceLogDeleteModal';
import {
	MaintenanceLogResolveModal,
} from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-resolve-modal/MaintenanceLogResolveModal';
import { MaintenanceLogCommentModal } from '../maintenance-log-action/maintenance-log-comment-modal/MaintenanceLogCommentModal';

export interface MaintenanceLogModalProps {
	modalType: MaintenanceLogModalType,
	onDismiss: () => void
	selectedMaintenanceLogEntry?: MaintenanceLogEntryViewModel,
	device?: DeviceViewModel
}

export const MaintenanceLogModal = (props: MaintenanceLogModalProps): JSX.Element => {
	const { modalType, onDismiss, selectedMaintenanceLogEntry, device } = props;


	// RENDER ELEMENTS
	const modal = (): JSX.Element => {
		switch (modalType) {
			case MaintenanceLogModalType.CREATE:
				return <MaintenanceLogCreateModal
					onDismiss={onDismiss}
					device={device ?? null}
				/>;

			case MaintenanceLogModalType.EDIT:
				return <MaintenanceLogCommentModal selectedMaintenancenLogEntry={selectedMaintenanceLogEntry} onDismiss={onDismiss} />;

			case MaintenanceLogModalType.DELETE:
				return <MaintenanceLogDeleteModal maintenanceLogEntry={selectedMaintenanceLogEntry} onDismiss={onDismiss} />;

			case MaintenanceLogModalType.RESOLVE:
				return <MaintenanceLogResolveModal selectedMaintenanceLogEntry={selectedMaintenanceLogEntry} onDismiss={onDismiss} />;

			default: return null;
		}
	};

	return (
		<>
			{modalType !== null && modal()}
		</>
	);
};
