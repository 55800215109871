export enum FieldType {
	BOOLEAN = 'BOOLEAN',

	COMMENT = 'COMMENT',

	DATE_TIME = 'DATE_TIME',

	INTERVAL = 'INTERVAL',

	MAIL_ADDRESS = 'MAIL_ADDRESS',

	NUMBER = 'NUMBER',

	STRING = 'STRING',

	TEXT = 'TEXT',

	UNIT = 'UNIT',

	URL = 'URL',

	VALUED_STRING = 'VALUED_STRING'
}
