import './forgot-password-link.scss';

export interface ForgotPasswordLinkProps {
	/**
	 * Label
	 */
	label: string;
	/**
	 *  Link https://app.test.smartarchivo.chroma-x.dev/core/app/account/recover/client/
	 */
	link: string
}

/**
 * Forgot password link partial
 */
export const ForgotPasswordLink = (props: ForgotPasswordLinkProps): JSX.Element => {
	const { label, link } = props;

	return (
		<div className='forgot-password-link'>
			<a
				href={link}
				target="_blank"
				rel='noreferrer'
			>
				{label}
			</a>
		</div>
	);
};
