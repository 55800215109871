import React, { ChangeEvent, useContext, useState } from 'react';
import { L10nContext } from '../../../../../context/L10nContext';
import { ModelSyncState } from '../../../../../lib/domain/model/Model';
import { CardEmpty } from '../../../../../presentation/ui/components/cards/card-empty/CardEmpty';
import { CardCollectionLayout } from '../../../../../presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { Checkbox } from '../../../../../presentation/ui/partials/input/checkbox/Checkbox';
import { AuthContext } from '../../../../core/context/AuthContext';
import { Permission } from '../../../../core/lib/auth/AuthService';
import { DocumentViewModel } from '../../../../documents/domain/model/DocumentModel';
import { ReportDocumentViewModel } from '../../../domain/model/ReportDocumentModel';
import { ReportViewModel } from '../../../domain/model/ReportModel';
import { ReportDocumentsType } from '../report-card-device/ReportCardDeviceProps';
import { ReportDocumentEntry } from '../report-document-entry/ReportDocumentEntry';
import './report-facilitydocuments-selection.scss';

export interface ReportFacilityDocumentsSelectionProps {
	report: ReportViewModel,
	documentsByFacility: ReadonlyArray<DocumentViewModel>
	onHandleReportDocumentProps?: (reportDocumentModel: ReportDocumentViewModel, reportDocumentChecked: boolean) => void;
}

export const ReportFacilityDocumentsSelection = (props: ReportFacilityDocumentsSelectionProps): JSX.Element => {

	const { report, documentsByFacility, onHandleReportDocumentProps } = props;

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);

	const initialReportFacilityDocumentsArray = documentsByFacility?.map((document: DocumentViewModel) => {

		const excistingReportDocument = report?.ReportDocuments.find((reportDocument) => {
			return document.Uuid === reportDocument.Document;
		});

		const reportFacilityDocumentsModel = {
			isDocumentSelected: !!excistingReportDocument,
			Document: document
		};
		return reportFacilityDocumentsModel;
	});

	const [reportFacilityDocuments, setReportFacilityDocuments] = useState<Array<ReportDocumentsType>>(initialReportFacilityDocumentsArray);
	const isSelectAllDocumentsChecked = !!reportFacilityDocuments?.find((document) => document.isDocumentSelected === false );

	const handleDocumentSelect = (uuid: string) => {
		const updatedReportFacilityDocuments = [...reportFacilityDocuments];
		const findDocument = reportFacilityDocuments.find(document => document.Document.Uuid === uuid);
		findDocument.isDocumentSelected = !findDocument.isDocumentSelected;

		setReportFacilityDocuments(updatedReportFacilityDocuments);

		const reportDocumentViewModel: ReportDocumentViewModel = {
			Report: report.Uuid,
			Document: findDocument.Document.Uuid,
			CreatedAt: report.UpdatedAt,
			UpdatedAt: new Date(),
			ModelSyncState: ModelSyncState.PRISTINE,
			Uuid: null
		};

		onHandleReportDocumentProps(reportDocumentViewModel, findDocument.isDocumentSelected);
	};

	const handleAllDocumentsChecked = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked === true) {
			const updatedReportFacilityDocuments = [...reportFacilityDocuments];
			updatedReportFacilityDocuments.forEach((document: ReportDocumentsType) => {
				document.isDocumentSelected = true;
			});
			setReportFacilityDocuments(updatedReportFacilityDocuments);

			updatedReportFacilityDocuments.forEach((document: ReportDocumentsType) => {
				const reportDocumentViewModel: ReportDocumentViewModel = {
					Report: report.Uuid,
					Document: document.Document.Uuid,
					CreatedAt: report.UpdatedAt,
					UpdatedAt: new Date(),
					ModelSyncState: ModelSyncState.PRISTINE,
					Uuid: null
				};
				onHandleReportDocumentProps(reportDocumentViewModel, true);
			});
		}
		if (e.target.checked === false) {
			const updatedReportFacilityDocuments = [...reportFacilityDocuments];
			updatedReportFacilityDocuments.forEach((document: ReportDocumentsType) => {
				document.isDocumentSelected = false;
			});

			setReportFacilityDocuments(updatedReportFacilityDocuments);

			updatedReportFacilityDocuments.forEach((document: ReportDocumentsType) => {
				const reportDocumentViewModel: ReportDocumentViewModel = {
					Report: report.Uuid,
					Document: document.Document.Uuid,
					CreatedAt: report.UpdatedAt,
					UpdatedAt: new Date(),
					ModelSyncState: ModelSyncState.PRISTINE,
					Uuid: null
				};
				onHandleReportDocumentProps(reportDocumentViewModel, false);
			});
		}
	};

	const renderFacilityDocuments = (): Array<JSX.Element> | JSX.Element => {
		if (reportFacilityDocuments?.length > 0 ) {
			return (
				reportFacilityDocuments.map((document): JSX.Element => {
					return (
						<ReportDocumentEntry
							key={document.Document.Uuid}
							documentEntry={document.Document}
							checkDocument={handleDocumentSelect}
							isDocumentSelected={document.isDocumentSelected}
						/>
					);
				})
			);
		}
		return <CardEmpty message={l10nContext.translate('common.cards.emptyDefaul', 'Keine Dokumente für den Standort verfügbar')} />;
	};

	return (
		<div className="report-facility-documents-selection">
			<CardCollectionLayout>
				{ documentsByFacility?.length !== 0 &&
					<Checkbox
						labelName={l10nContext.translate('view.reports.reportDevicesC', 'Alle Dokumente auswählen')}
						checked={!isSelectAllDocumentsChecked}
						onChange={handleAllDocumentsChecked}
						isReportSelection
						disabled={!authContext.hasPermission(Permission.REPORT_UPDATE)}
					/>}
				{renderFacilityDocuments()}
			</CardCollectionLayout>
		</div>
	);
};
