import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';
import { memo, useContext } from 'react';

import './external-waste-management-report-generator-annual-report.scss';

export interface ExternalWasteManagementReportGeneratorAnnualReportProps {
	eluateActivities: string,
	nominalActivitiesGenerators: string,
}

export const ExternalWasteManagementReportGeneratorAnnualReport
	= memo((props: ExternalWasteManagementReportGeneratorAnnualReportProps): JSX.Element => {
		const { eluateActivities, nominalActivitiesGenerators  } = props;

		const l10nContext = useContext(L10nContext);

		const translations = {

			nominalActivitiesGenerators: { label: l10nContext.translate('externalWasteManagementView.generators.sumNominalActivities', 'Summe aller Nominalaktivitäten aller Generatoren'), value: nominalActivitiesGenerators },
			sumAllEluateActivities: { label: l10nContext.translate('externalWasteManagementView.generators.sumActivitiesEluates', 'Summe der Aktivitäten aller Eluate'), value: eluateActivities }
		};

		const attributes = () => {
			return Object.entries(translations).map((translation) => {
				const { label, value } = translation[1];

				const styleClass = 'attribute';

				return (
					<label
						key={Guid.generate()}
						className={styleClass}
					>
						<strong>{label}:&nbsp;</strong>
						<p>{value }</p>
					</label>
				);
			});
		};

		return (
			<div className='external-waste-management-report-generator-annual-report'>
				<div className='external-waste-management-report-generator-annual-report__attributes'>
					{attributes()}
				</div>
			</div>
		);
	});
