export enum IllustrationIdentifier {
	WASTE_MANAGEMENT = 'illustration-abfallmanagement',
	CYCLOTRON_PRODUCT = 'illustration-cyclotron-product',
	DEVICE_ACTIVIMETER = 'illustration-device-aktivimeter',
	DEVICE_BOHRLOCH = 'illustration-device-bohrloch',
	DEVICE_GAMKAMERA_PLANAR = 'illustration-device-gamkamera-planar',
	DEVICE_GAMKAMERA_SPECT = 'illustration-device-gamkamera-spect',
	DEVICE_GENERIC = 'illustration-device-generic',
	DEVICE_MONITOR = 'illustration-device-monitor',
	DEVICE_OP_SONDE = 'illustration-device-op-sonde',
	DEVICE_PET_CT = 'illustration-device-pet-ct',
	DEVICE_RADIOCHEMISCHE_REINHEIT = 'illustration-device-radiochemische-reinheit',
	DEVICE_X_RAY = 'illustration-device-x-ray',
	DOCUMENT_AKTIVIMETER = 'illustration-document-aktivimeter',
	DOCUMENT_GAMKAMERA_PLANAR = 'illustration-document-gamkamera-planar',
	DOCUMENT_GAMKAMERA_SPECT = 'illustration-document-gamkamera-spect',
	DOCUMENT_GENERIC = 'illustration-document-generic',
	DOCUMENT_MONITOR = 'illustration-document-monitor',
	DOCUMENT_OP_SONDE = 'illustration-document-op-sonde',
	DOCUMENT_PET_CT = 'illustration-document-pet-ct',
	DOCUMENT_PLACE = 'illustration-document-place',
	DOCUMENT_RADIOCHEMISCHE_REINHEIT_COPY = 'illustration-document-radiochemische-reinheit-copy',
	DOCUMENT_RADIOCHEMISCHE_REINHEIT = 'illustration-document-radiochemische-reinheit',
	DOCUMENT_X_RAY = 'illustration-document-x-ray',
	GENERATOR_GE = 'illustration-generator-ge',
	GENERATOR_MO = 'illustration-generator-mo',
	OPERATING_LOG_AKTIVIMETER = 'illustration-operating-log-aktivimeter',
	OPERATING_LOG_BOHRLOCH = 'illustration-operating-log-bohrloch',
	OPERATING_LOG_GAMKAMERA_PLANAR = 'illustration-operating-log-gamkamera-planar',
	OPERATING_LOG_GAMKAMERA_SPECT = 'illustration-operating-log-gamkamera-spect',
	OPERATING_LOG_GENERIC = 'illustration-operating-log-generic',
	OPERATING_LOG_MONITOR = 'illustration-operating-log-monitor',
	OPERATING_LOG_OP_SONDE = 'illustration-operating-log-op-sonde',
	OPERATING_LOG_PET_CT = 'illustration-operating-log-pet-ct',
	OPERATING_LOG_PLACE = 'illustration-operating-log-place',
	OPERATING_LOG_RADIOCHEMISCHE_REINHEIT = 'illustration-operating-log-radiochemische-reinheit',
	OPERATING_LOG_X_RAY = 'illustration-operating-log-x-ray',
	ORDNER_PLACE = 'illustration-ordner-place',
	PLACE = 'illustration-place'
}
