import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { FolderViewModel } from 'services/documents/domain/model/FolderModel';
import { FolderDeleteForm } from 'services/documents/presentation/ui/folder-action/folder-delete-form/FolderDeleteForm';
import { FolderDeleteFailedNotification } from 'services/documents/presentation/ui/folder-action/folder-delete-modal/FolderDeleteFailedNotification';
import { deleteFolder } from 'services/documents/store/folderSlice';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';

export interface FolderDeleteModalProps {
	folder: FolderViewModel;
	onDismiss?: () => void;
}

export const FolderDeleteModal = (props: FolderDeleteModalProps): JSX.Element => {
	const { folder, onDismiss } = props;

	const folderActionStatus = useTypedSelector(state => state.folders.actionStatus);

	const dispatch = useDispatch();

	const handleDelete = (): void => {
		dispatch(deleteFolder(folder));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (folderActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<FolderDeleteForm
						folder={folder}
						onDelete={handleDelete}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <FolderDeleteFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
