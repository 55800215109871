import { useContext, useState } from 'react';

import { L10nContext } from 'context/L10nContext';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { RecordViewModel } from 'services/device/domain/model/RecordModel';
import { RecordUpdateForm } from 'services/device/presentation/ui/record-action/record-update-form/RecordUpdateForm';
import { RecordUpdateFailedNotification } from 'services/device/presentation/ui/record-action/record-update-modal/RecordUpdateFailedNotification';
import { RecordUpdateSuccessNotification } from 'services/device/presentation/ui/record-action/record-update-modal/RecordUpdateSuccessNotification';

import { ModalDialogueHeader } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-header/ModalDialogueHeader';
import { ModalDialogueSection } from 'presentation/ui/compositions/modal-dialogue/modal-dialogue-section/ModalDialogueSection';
import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';

interface RecordUpdateModalProps {
	sequence: SequenceViewModel;
	record: RecordViewModel;
	onDismiss: () => void;
}

/**
 * The device create modal
 */
export const RecordUpdateModal = (props: RecordUpdateModalProps): JSX.Element => {
	const { sequence, record, onDismiss } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	// Use state to hold error objects
	const [caughtError, setCaughtError] = useState<Error>(null);

	// Use selector to provide information from store
	const recordActionStatus = useTypedSelector(state => state.records.actionStatus);

	const renderModal = () => {
		if (caughtError !== null) {
			return (
				<RecordUpdateFailedNotification
					onClickConfirm={onDismiss}
				/>
			);
		}

		let modal: JSX.Element = null;

		switch (recordActionStatus) {
			case AsyncReducerStatus.IDLE:
				modal = <RecordUpdateForm
					sequence={sequence}
					record={record}
					onError={(error) => {
						setCaughtError(error);
					}}
				/>;
				break;

			case AsyncReducerStatus.UPDATED:
				modal =
					<RecordUpdateSuccessNotification
						onClickConfirm={onDismiss}
						record={record}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modal =
					<RecordUpdateFailedNotification
						onClickConfirm={onDismiss}
					/>;
				break;
		}
		return modal;
	};

	return (
		<ModalDialogue onDismiss={onDismiss}>
			<ModalDialogueHeader
				captionText={l10nContext.translate('view.sequence.modal.action.updateRecord.caption', 'Messwert hinzufügen')}
				captionIcon={IconIdentifier.ADD}
			/>
			<ModalDialogueSection>
				{renderModal()}
			</ModalDialogueSection>
		</ModalDialogue>
	);
};
