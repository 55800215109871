import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { IntervalInput } from 'services/device/domain/business/common/Input';
import { Interval } from 'services/device/domain/business/inventory/Interval';
import { getAllIntervals, getIntervalDefinition } from 'services/device/domain/business/inventory/IntervalDefinition';

import { Label } from 'presentation/ui/partials/input/label/Label';
import { Select, SelectOption } from 'presentation/ui/partials/input/select/Select';

export interface IntervalInputProps {
	input: IntervalInput;
}

export const IntervalInputRenderer = (props: IntervalInputProps): JSX.Element => {

	const l10nContext = useContext(L10nContext);

	const { input } = props;

	let label = l10nContext.translate(input.getLabelKey());
	if (input.getDescription()?.mandatory === true) {
		label += '*';
	}

	let inputOptions = input.getOptions();
	if (inputOptions === null || inputOptions.length === 0) {
		inputOptions = getAllIntervals();
	}

	const options = inputOptions.map<SelectOption>((option) => {
		return {
			label: l10nContext.translate(getIntervalDefinition(option).getLabelKey()),
			value: String(option),
			checked: input.getSingle() === option
		} as SelectOption;
	});

	return (
		<Label label={label}>
			<Select
				options={options}
				onchange={(selectedInterval) => {
					input.setSingle(selectedInterval as Interval);
				}}
			/>
		</Label>
	);

};
