export const toFilesystemSafe = (string: string): string => {
	const replacementMap = new Map([
		['Ä', 'Ae'],
		['Ö', 'Oe'],
		['Ü', 'Ue'],
		['ä', 'ae'],
		['ö', 'oe'],
		['ü', 'ue'],
		['ß', 'ss']
	]);
	const pattern = new RegExp(Array.from(replacementMap.keys()).join('|'), 'gi');
	string = string.replace(pattern, (match) => {
		return replacementMap.get(match) ?? '-';
	});
	string = string.replace(/[^0-9a-z]/gi, '-');
	return string.trim();
};
