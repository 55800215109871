import { contains } from '../misc/Util';
import { Optional } from '../misc/Optional';
import { Value } from './Value';

export class Mean implements Value<number> {
	private readonly result: Optional<number>;

	public constructor(...values: ReadonlyArray<Value<number>>) {
		this.result = calculate(values.map((value) => value.getSingle()));
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<number> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<number> {
		return this.result;
	}
}

function calculate(values: ReadonlyArray<Optional<number>>): Optional<number> {
	if (!contains(values, null) && values.length !== 0) {
		return (values as ReadonlyArray<number>).reduce((a, b) => a + b, 0) / values.length;
	}
	return null;
}
