import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AsyncFetchStatus } from 'store/common/AsyncFetchStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { fetchExternalWasteManagementReportEluates } from '../../../../services/nuclide/store/externalWasteManagementEluateReportSlice';
import {
	fetchExternalWasteManagementReportCyclotronProducts
} from '../../../../services/nuclide/store/externalWasteManagementReportCyclotronProductSlice';
import {
	fetchExternalWasteManagementReportGenerators
} from '../../../../services/nuclide/store/externalWasteManagementReportGeneratorSlice';
import { fetchExternalWasteManagementReport } from '../../../../services/waste-management-report/store/externalWasteManagementReportSlice';
import { Error } from '../../components/error/Error';
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner';

interface ReportViewParams {
	reportUuid: string;
}

export const StoreWarmupExternalWasteManagementReport = (props: any): JSX.Element => {

	const params = useParams<ReportViewParams>();
	const reportUuid = params?.reportUuid ?? null;
	if (reportUuid === null) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		throw new Error('Report id is missing');
	}

	const externalWasteManagementReportStoreFetchStatus = useTypedSelector(state => state.externalWasteManagementReport.fetchStatus);
	const externalWasteManagementReportStoreLastFetchError = useTypedSelector(state => state.externalWasteManagementReport.lastFetchError);
	const externalWasteManagementReportStorePending =
		externalWasteManagementReportStoreFetchStatus === AsyncFetchStatus.INITIAL ||
		externalWasteManagementReportStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const externalWasteManagementReportStoreFailed = externalWasteManagementReportStoreFetchStatus === AsyncFetchStatus.FAILED;

	const externalWasteManagementReportGeneratorsStoreFetchStatus = useTypedSelector(state => state.externalWasteManagementReportGenerators.fetchStatus);
	const externalWasteManagementReportGeneratorsStoreLastFetchError = useTypedSelector(state => state.externalWasteManagementReportGenerators.lastFetchError);
	const externalWasteManagementReportGeneratorsStorePending =
		externalWasteManagementReportGeneratorsStoreFetchStatus === AsyncFetchStatus.INITIAL ||
		externalWasteManagementReportGeneratorsStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const externalWasteManagementReportGeneratorsStoreFailed = externalWasteManagementReportStoreFetchStatus === AsyncFetchStatus.FAILED;

	const externalWasteManagementReportEluatesStoreFetchStatus = useTypedSelector(state => state.externalWasteManagementReportEluates.fetchStatus);
	const externalWasteManagementReportEluatesStoreLastFetchError = useTypedSelector(state => state.externalWasteManagementReportEluates.lastFetchError);
	const externalWasteManagementReportEluatesStorePending =
		externalWasteManagementReportEluatesStoreFetchStatus === AsyncFetchStatus.INITIAL ||
		externalWasteManagementReportEluatesStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const externalWasteManagementReportEluatesStoreFailed = externalWasteManagementReportStoreFetchStatus === AsyncFetchStatus.FAILED;

	const externalWasteManagementReportCyclotronProductsStoreFetchStatus =
		useTypedSelector(state => state.externalWasteManagementReportCyclotronProducts.fetchStatus);
	const externalWasteManagementReportCyclotronProductsStoreLastFetchError =
		useTypedSelector(state => state.externalWasteManagementReportCyclotronProducts.lastFetchError);
	const externalWasteManagementReportCyclotronProductsStorePending =
		externalWasteManagementReportCyclotronProductsStoreFetchStatus === AsyncFetchStatus.INITIAL ||
		externalWasteManagementReportCyclotronProductsStoreFetchStatus === AsyncFetchStatus.INITIAL_PENDIG;
	const externalWasteManagementReportCyclotronProductsStoreFailed = externalWasteManagementReportStoreFetchStatus === AsyncFetchStatus.FAILED;

	const pending = externalWasteManagementReportStorePending ||
		externalWasteManagementReportGeneratorsStorePending ||
		externalWasteManagementReportEluatesStorePending ||
		externalWasteManagementReportCyclotronProductsStorePending;

	const failed = externalWasteManagementReportStoreFailed ||
		externalWasteManagementReportGeneratorsStoreFailed ||
		externalWasteManagementReportEluatesStoreFailed ||
		externalWasteManagementReportCyclotronProductsStoreFailed;

	const startedFetchExternalWasteManagementReportStore = useRef<boolean>(false);
	const startedFetchExternalWasteManagementGeneratorsReportStore = useRef<boolean>(false);
	const startedFetchExternalWasteManagementEluatesReportStore = useRef<boolean>(false);
	const startedFetchExternalWasteManagementCyclotronProductsReportStore = useRef<boolean>(false);

	const dispatch = useDispatch();

	if (!startedFetchExternalWasteManagementReportStore.current &&
		(externalWasteManagementReportStoreFetchStatus === AsyncFetchStatus.INITIAL ||
			externalWasteManagementReportStoreFetchStatus === AsyncFetchStatus.IDLE) &&
		!failed) {
		startedFetchExternalWasteManagementReportStore.current = true;
		dispatch(fetchExternalWasteManagementReport({ reportUuid }));
	}

	if (!startedFetchExternalWasteManagementGeneratorsReportStore.current &&
		(externalWasteManagementReportGeneratorsStoreFetchStatus === AsyncFetchStatus.INITIAL ||
			externalWasteManagementReportGeneratorsStoreFetchStatus === AsyncFetchStatus.IDLE) &&
		!failed) {
		startedFetchExternalWasteManagementGeneratorsReportStore.current = true;
		dispatch(fetchExternalWasteManagementReportGenerators({ reportUuid }));
	}

	if (!startedFetchExternalWasteManagementEluatesReportStore.current &&
		(externalWasteManagementReportEluatesStoreFetchStatus === AsyncFetchStatus.INITIAL ||
			externalWasteManagementReportEluatesStoreFetchStatus === AsyncFetchStatus.IDLE) &&
		!failed) {
		startedFetchExternalWasteManagementEluatesReportStore.current = true;
		dispatch(fetchExternalWasteManagementReportEluates({ reportUuid }));
	}

	if (!startedFetchExternalWasteManagementCyclotronProductsReportStore.current &&
		(externalWasteManagementReportCyclotronProductsStoreFetchStatus === AsyncFetchStatus.INITIAL ||
			externalWasteManagementReportCyclotronProductsStoreFetchStatus === AsyncFetchStatus.IDLE) &&
		!failed) {
		startedFetchExternalWasteManagementCyclotronProductsReportStore.current = true;
		dispatch(fetchExternalWasteManagementReportCyclotronProducts({ reportUuid }));
	}

	if (pending) {
		return (
			<LoadingSpinner />
		);
	}

	if (failed) {

		const errors = [
			externalWasteManagementReportStoreLastFetchError,
			externalWasteManagementReportGeneratorsStoreLastFetchError,
			externalWasteManagementReportEluatesStoreLastFetchError,
			externalWasteManagementReportCyclotronProductsStoreLastFetchError
		].filter((error: Error | null): boolean => {
			return error !== null;
		});

		const messages = errors.map((error: Error): string => {
			return error.message;
		});

		return (
			<div>
				<Error error={messages} />
			</div>
		);
	}

	return (
		<>
			{props.children}
		</>
	);

};
