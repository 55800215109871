import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { EluateViewModel } from 'services/nuclide/domain/model/EluateModel';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';
import { ContainerViewModel } from '../../../../domain/model/ContainerModel';
import { deleteContainer, updateContainer } from '../../../../store/containerSlice';
import { EluateToSingleNuclidesForm } from '../eluate-to-single-nuclides-form/EluateToSingleNuclidesForm';
import { EluateToSingleNuclidesFailedNotification } from './EluateToSingleNuclidesFailedNotification';

export interface EluateToSingleNuclidesModalProps {
	container: ContainerViewModel,
	eluate: EluateViewModel
	onDismiss?: () => void;
}

export const EluateToSingleNuclidesModal = (props: EluateToSingleNuclidesModalProps): JSX.Element => {
	const { container, eluate, onDismiss } = props;

	// Consume the contexts
	const clientContext = useContext(ClientContext);
	const facilityContext = useContext(FacilityContext);

	const containerActionStatus = useTypedSelector(state => state.containers.actionStatus);

	const dispatch = useDispatch();

	useEffect(() => {
		if (containerActionStatus === AsyncReducerStatus.UPDATED) {
			onDismiss();
		}
	}, [containerActionStatus, onDismiss]);

	const handleEluateToSingleNuclide = (): void => {

		const filteredEluates = container.GeneratorEluatIds.filter(item => item !== eluate.Uuid);
		container.GeneratorEluatIds = filteredEluates;
		container.Client = clientContext.selectedClientUuid;
		container.Facility = facilityContext.selectedFacilityUuid;

		if (filteredEluates.length + container.CyclotroneProductIds?.length > 0) {
			dispatch(updateContainer(container));
		}
		if (filteredEluates.length + container.CyclotroneProductIds?.length === 0) {
			dispatch(deleteContainer(container));
		}
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (containerActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<EluateToSingleNuclidesForm
						eluate={eluate}
						onToSingleNuclides={handleEluateToSingleNuclide}
						onDismiss={onDismiss}
						isLastItem={(container.CyclotroneProductIds?.length + container.GeneratorEluatIds?.length) === 1}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <EluateToSingleNuclidesFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
