export enum IconIdentifier {
	ADD = 'icon-add',
	ALERT = 'icon-alert',
	ARCHIVE = 'icon-archive',
	ARROW_RIGHT = 'icon-arrow-right',
	CALENDER = 'icon-calender',
	CHART = 'icon-chart',
	CHECK = 'icon-check',
	CLEARANCE_SEQUENCE = 'icon-clearance-sequence',
	CLOSE = 'icon-close',
	COMMENT = 'icon-comment',
	COMPRESS = 'icon-compress',
	DASHBOARD = 'icon-dashboard',
	DEACTIVATE_USER = 'icon-deactivate-user',
	DECAY = 'icon-decay',
	DEVICE = 'icon-device',
	DISPATCH = 'icon-dispatch',
	DOC = 'icon-doc',
	DOWNLOAD = 'icon-download',
	DROPDOWN_CLOSE = 'icon-dropdown-close',
	DROPDOWN_OPEN = 'icon-dropdown-open',
	DUPLICATE = 'icon-duplicate',
	EDIT = 'icon-edit',
	EXPAND = 'icon-expand',
	FOLDER = 'icon-folder',
	IN_WASTE = 'icon-in-waste',
	IN_WASTE_BACK = 'icon-in-waste-back',
	LOCATION = 'icon-location',
	FULLY_APPLIED = 'icon-fully-applied',
	MAIL = 'icon-mail',
	MENUE_CLOSE = 'icon-menue-close',
	MENUE_OPEN = 'icon-menue-open',
	MOVE = 'icon-move',
	NUCLIDE = 'icon-nuclide',
	OPERATING_LOG = 'icon-operating-log',
	REPORT = 'icon-report',
	SYN = 'icon-syn',
	TABLE = 'icon-table',
	TRASH = 'icon-trash',
	UPLOAD = 'icon-upload',
	USER = 'icon-user',
	WARNING = 'icon-warning'
}
