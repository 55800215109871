import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { useTypedSelector } from 'store/common/TypedSelector';

import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { DeviceModelState } from 'services/device/domain/model/DeviceModelState';
import { DeviceArchiveForm } from 'services/device/presentation/ui/device-action/device-archive-form/DeviceArchiveForm';
import { DeviceArchiveFailedNotification } from 'services/device/presentation/ui/device-action/device-archive-modal/DeviceArchiveFailedNotification';
import { updateDevice } from 'services/device/store/devicesSlice';

import { ModalDialogue } from 'presentation/ui/compositions/modal-dialogue/ModalDialogue';
import { ModalDialogueHeight } from 'presentation/ui/compositions/modal-dialogue/ModalDialogueHeight';

export interface DeviceArchiveModalProps {
	device: DeviceViewModel
	onDismiss?: () => void;
}

export const DeviceArchiveModal = (props: DeviceArchiveModalProps): JSX.Element => {
	const { device, onDismiss } = props;

	const deviceActionStatus = useTypedSelector(state => state.devices.actionStatus);

	const dispatch = useDispatch();

	useEffect(() => {
		if (deviceActionStatus === AsyncReducerStatus.UPDATED) {
			onDismiss();
		}
	}, [deviceActionStatus, onDismiss]);

	const handleUpdate = (): void => {
		dispatch(updateDevice({
			...device,
			...{
				State: DeviceModelState.ARCHIVED
			}
		}));
	};

	const renderModalContent = () => {
		let modalContent: JSX.Element = null;

		switch (deviceActionStatus) {
			case AsyncReducerStatus.IDLE:
				modalContent =
					<DeviceArchiveForm
						device={device}
						onUpdate={handleUpdate}
						onDismiss={onDismiss}
					/>;
				break;

			case AsyncReducerStatus.FAILED:
				modalContent = <DeviceArchiveFailedNotification
					onDismiss={onDismiss}
				/>;
				break;
		}

		return modalContent;
	};

	return (
		<ModalDialogue height={ModalDialogueHeight.HEIGHT_FLEX}>
			{renderModalContent()}
		</ModalDialogue>
	);
};
