import { Field } from '../field/Field';
import { Optional } from '../misc/Optional';
import { Valuation, worst } from '../Valuation';
import { Value } from './Value';

export class WorstValuation implements Value<Valuation> {
	private readonly result: Optional<Valuation>;

	public constructor(...fields: ReadonlyArray<Field<any, any>>) {
		this.result = worst(fields.map((field) => field.getValuation()));
	}

	public isRepeatable(): boolean {
		return false;
	}

	public get(): ReadonlyArray<Valuation> {
		return this.result !== null ? [this.result] : [];
	}

	public getSingle(): Optional<Valuation> {
		return this.result;
	}
}
