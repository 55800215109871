import { ChangeMode, ClientSelect } from 'services/core/presentation/ui/client-select/ClientSelect';
import { FacilitySelect } from 'services/core/presentation/ui/facility-select/FacilitySelect';
import { LogoHeader } from 'services/core/presentation/ui/logo-header/LogoHeader';

import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayoutSectionWidth } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionWidth';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';

export const FacilitySelectView = (): JSX.Element => {

	return (
		<MainLayout>
			<TopbarLayoutSection fluid>
				<LogoHeader />
			</TopbarLayoutSection>

			<MainLayoutSection width={MainLayoutSectionWidth.WIDTH_NARROW}>
				<ViewLayout>
					<ViewLayoutSection>
						<ClientSelect changeMode={ChangeMode.SELECTION_CHANGE} />
					</ViewLayoutSection>
					<ViewLayoutSection>
						<FacilitySelect />
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
